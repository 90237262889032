<template lang="pug">
  div(class="eds-iit-checkbox-sign-options")
    div(class="eds-iit-checkbox-sign-options__main-option")
      h3(class="eds-iit-checkbox-sign-options__main-option-title") {{ $t(mainOption.title) }}
      p(class="eds-iit-checkbox-sign-options__main-option-text") {{ $t(mainOption.text) }}
      v-btn(
        @click="mainOption.onClick(mainOption.key)"
        class="eds-iit-checkbox-sign-options__main-option-btn"
        dark
      ) {{ $t('Add') }}
    p(class="eds-iit-checkbox-sign-options__subtitle") {{ $t('Other options') }}:
    div(class="eds-iit-checkbox-sign-options__other-options")
      div(
        v-for="item in otherOptions"
        v-if="item.visible"
        class="eds-iit-checkbox-sign-options__other-options-card"
        @click="item.onClick(item.key)"
      )
        div(
          v-if="item.recommended"
          class="eds-iit-checkbox-sign-options__other-options-card-recommend"
        ) {{ $t('Recommended') }}
        p(class="eds-iit-checkbox-sign-options__other-options-card-title") {{ $t(item.title) }}
        v-btn(
          icon
          small
        )
          e-svg-icon(:size="xsDevice ? 'xsm' : 'sm'") arrow-next
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import edsIitCheckboxSteps from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxSteps'

export default {
  name: 'EdsIitCheckboxSignOptions',
  components: {
    ESvgIcon
  },
  mixins: [responsive, edsIitCheckboxSteps],
  computed: {
    mainOption () {
      return {
        key: this.steps.fileSign,
        title: 'With EDS file key or secure media',
        text: 'Select the EDS key file (usually files with the extension DAT, ZS2, JKS) on your device or use a secure media',
        onClick: key => this.handleClick(key),
        visible: true
      }
    },
    otherOptions () {
      return [
        {
          key: this.steps.diiaSign,
          title: 'With Diia.Signature',
          onClick: key => this.handleClick(key),
          visible: this?.$Organization ? this._.get(this.$Organization, 'personType') === 'individual' : true
        },
        {
          key: this.steps.smartIdRead,
          title: 'With SmartID',
          onClick: key => this.handleClick(key),
          visible: true
        }
      ]
    }
  },
  methods: {
    handleClick (key) {
      this.$emit('select', key)
    }
  }
}
</script>

<style scoped lang="scss">
.eds-iit-checkbox-sign-options {
  &__main-option {
    background: rgba(67, 204, 180, 0.08);
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 24px;

    @media (min-width: map-get($breakpoints, 'xs')) {
      margin-bottom: 32px;
    }
  }

  &__main-option-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
    line-height: 24px;
    color: $btn-secondary-color;
  }

  &__main-option-text {
    font-size: 14px;
    margin-bottom: 24px;
    color: rgba(22, 27, 37, 0.75);
  }

  &__main-option-btn {
    width: 100%;

    @media (min-width: map-get($breakpoints, 'xs')) {
      max-width: 180px;
    }
  }

  &__subtitle {
    font-size: 14px;
    color: $btn-secondary-color;
    line-height: 20px;
    margin-bottom: 16px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__other-options {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__other-options-card {
    width: 100%;
    border-radius: 12px;
    border: 2px solid $btn-secondary-color;
    padding: 15px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: .3s;
    position: relative;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }

    @media (min-width: map-get($breakpoints, 'md')) {
      width: calc(50% - 8px);
    }
  }

  &__other-options-card-title {
    font-size: 14px;
    margin: 0;
    color: #000;
  }

  &__other-options-card-recommend {
    background: $focus-color-2;
    padding: 4px 8px;
    border-radius: 7px;
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
  }
}
</style>
