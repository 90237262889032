<template lang="pug">
  div(
    v-if="allowedOrg"
    class="language-switcher"
    :class="{ 'language-switcher--light': light, 'language-switcher--gray': gray }"
  )
    v-btn(
      v-for="(item, index) in locales"
      :key="index"
      @click="changeLanguage(item.value)"
      class="language-switcher__btn"
      :class="{ 'language-switcher__btn--active': lang === item.value }"
      :ripple="lang !== item.value"
    ) {{ item.title }}
</template>

<script>
import i18n from '~/plugins/nuxt-i18n/i18n'

export default {
  props: {
    gray: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    lang: null
  }),
  computed: {
    allowedOrg () {
      const orgId = this._.get(this.$Organization, 'id')
      const allowedOrgs = [
        '7b1751eb-5278-451e-93ca-46fed33a9388', // Kulyk stage
        'c066123c-deff-4418-beb7-752c05ff0c07', // Bohdan stage
        '9962f238-42b9-43fc-aa0c-81ff148b19cc' // Test org prod
      ]
      return allowedOrgs.includes(orgId)
    },
    locales () {
      return this._.map(this.$i18n.locales, locale => ({
        value: locale.code,
        title: this._.capitalize(locale.code)
      }))
    }
  },
  created () {
    if (this.allowedOrg) {
      const langLS = localStorage.getItem('i18nLocale')
      this.setLocale(langLS || 'uk')
    } else {
      this.setLocale('uk')
    }
  },
  methods: {
    setLocale (val) {
      this.$i18n.setLocale(val)
      this.lang = val
      i18n.locale = val
      localStorage.setItem('i18nLocale', val)
    },
    changeLanguage (val) {
      if (this.lang === val) {
        return
      }
      localStorage.setItem('i18nLocale', val)
      location.reload()
    }
  }
}
</script>

<style scoped lang="scss">
.language-switcher {
  height: 40px;
  background: #F0F1F2;
  padding: 4px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;

  &--light {
    background: none;

    .language-switcher {
      &__btn {
        color: rgba(255, 255, 255, 1) !important;

        &--active {
          color: $btn-secondary-color !important;
          background-color: rgba(255, 255, 255, 1) !important;
        }
      }
    }
  }

  &--gray {
    background: rgba(240, 241, 242, 0.2);

    .language-switcher {
      &__btn {
        color: rgba(255, 255, 255, 0.5) !important;

        &--active {
          color: rgb(67 67 67) !important;
          background-color: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }
  }

  &__btn {
    padding: 0 !important;
    width: 40px !important;
    min-width: auto !important;
    color: rgba(0,0,0,0.5) !important;
    background: none !important;
    height: 32px !important;

    &--active {
      color: #fff !important;
      background-color: rgba(0, 0, 0, 0.5) !important;

      &::before {
        display: none;
      }
    }
  }
}
</style>
