<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('Creating a report') }}
    div(class="dialog__content")
      div(
        v-if="!availableReports.length"
        class="dialog__no-data"
      ) {{ $t('Sorry, but the reporting period has not yet come') }}
      div(v-else)
        v-select(
          v-model="reportType"
          :items="reportTypesItems"
          :label="$t('Select the document to create')"
          @change="getTaxInfo"
          :disabled="loading"
          outlined
        )
        div(
          v-if="loading"
          class="dialog__loader"
        )
          e-progress-circular(
            size="xl"
            width="5"
          )
        div(v-else-if="form")
          v-select(
            v-if="reportingPeriod"
            :value="reportingPeriod"
            :items="reportingPeriodItems"
            :label="$t('Period')"
            disabled
            outlined
          )
          v-form(@submit.prevent.stop="sendReport")
            ValidationObserver(
              ref="form"
              slim
            )
              div(class="dialog__sum-fields-wrap")
                t-orm-fields(
                  v-bind.sync="form"
                  :items="fieldsSum"
                )
                div(
                  v-if="!isSecond"
                  class="dialog__sum-text-wrap"
                )
                  div()
                    span {{ $t('The amount of the single tax') }}: {{ singleTaxSum }}
                  div(v-if="!this.isTemporary")
                    span {{ $t('Total amount') }}: {{ totalAmountSum }}
              t-orm-fields(
                v-bind.sync="form"
                :items="fields"
              )
            div(class="dialog__sum-text-wrap pb-3")
              div
                v-btn(
                  v-if="yearReport"
                  class="secondary-button"
                  @click="openAddition"
                  small
                  dark
                ) + {{ $t('Appendix on EUV') }}
              div(v-if="esvSum") {{ $t('The amount of a single contribution') }}: {{ esvSum }}
            v-btn(
              class="main-button mx-auto d-block mt-3"
              :loading="sendLoading"
              type="submit"
            ) {{ $t('Send report') }}
            e-link(
              @click="viewReport"
              class="mt-3 mb-6 d-block mx-auto"
            ) {{ $t('View the report') }}
</template>

<script>
import Base64js from 'base64-js'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import TaxReports from '~/modules/reports/models/TaxReports'
import TaxCodesDictionary from '~/models/directories/dictionaries/TaxCodesDictionary'
import ELink from '~/components/elements/links/e-link'
import taxReports from '~/modules/reports/mixins/taxReports'
import scrollToFailedValidation from '~/mixins/methods/scrollToFailedValidation'
import TaxInfo from '~/modules/reports/models/TaxInfo'
import responsive from '~/mixins/pages/responsive'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'

export default {
  name: 'BlockCreateTaxReport',
  components: {
    ELink,
    EProgressCircular,
    TOrmFields
  },
  mixins: [edsIitCheckboxDialog, taxReports, scrollToFailedValidation, responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    form: null,
    reportType: null,
    loading: false,
    sendLoading: false,
    viewLoading: false,
    yearReport: false,
    availableReports: [],
    kvedItems: [],
    additionFilled: false
  }),
  computed: {
    model () {
      return TaxReports
    },
    reportingPeriodItems () {
      return Object.values(this.model.PERIOD_TYPES)
    },
    reportingPeriod () {
      return this._.get(this.form, 'reportingPeriod', '')
    },
    reportTypesItems () {
      const items = [
        {
          text: 'F0103308 ' + this.$t('taxReports.thirdFive'),
          value: this.model.REPORT_TYPES.thirdFive,
          visible: this.availableReports.includes(this.model.REPORT_TYPES.thirdFive)
        },
        {
          text: 'F0103203 ' + this.$t('taxReports.thirdTwo'),
          value: this.model.REPORT_TYPES.thirdTwo,
          visible: this.availableReports.includes(this.model.REPORT_TYPES.thirdTwo)
        },
        {
          text: 'F0103406 ' + this.$t('taxReports.second'),
          value: this.model.REPORT_TYPES.second,
          visible: this.availableReports.includes(this.model.REPORT_TYPES.second)
        }
      ]
      return this._.filter(items, item => item.visible)
    },
    fieldsSumSecondGroup () {
      return [
        {
          model: 'R03G1',
          component: 'v-text-field',
          label: {
            text: 'Indicators of economic activity'
          },
          cols: {
            cols: 12,
            md: 6
          },
          classes: ['mt-3'],
          provider: {
            vid: 'R03G1',
            name: this.$t('_num quarter', { number: 1 }),
            rules: 'required|integer_or_decimal|min_value:0'
          },
          attrs: {
            label: this.$t('_num quarter', { number: 1 }),
            outlined: true
          },
          cast: val => ((parseFloat(val) || 0) * 100).toFixed(0)
        },
        {
          model: 'R03G2',
          component: 'v-text-field',
          cols: {
            cols: 12,
            md: 6
          },
          style: this.smDevice ? '' : 'margin-top: 34px',
          provider: {
            vid: 'R03G2',
            name: this.$t('_num quarter', { number: 2 }),
            rules: 'required|integer_or_decimal|min_value:0'
          },
          attrs: {
            label: this.$t('_num quarter', { number: 2 }),
            outlined: true
          },
          cast: val => ((parseFloat(val) || 0) * 100).toFixed(0)
        },
        {
          model: 'R03G3',
          component: 'v-text-field',
          cols: {
            cols: 12,
            md: 6
          },
          provider: {
            vid: 'R03G3',
            name: this.$t('_num quarter', { number: 3 }),
            rules: 'required|integer_or_decimal|min_value:0'
          },
          attrs: {
            label: this.$t('_num quarter', { number: 3 }),
            outlined: true
          },
          cast: val => ((parseFloat(val) || 0) * 100).toFixed(0)
        },
        {
          model: 'R03G4',
          component: 'v-text-field',
          cols: {
            cols: 12,
            md: 6
          },
          provider: {
            vid: 'R03G4',
            name: this.$t('_num quarter', { number: 4 }),
            rules: 'required|integer_or_decimal|min_value:0'
          },
          attrs: {
            label: this.$t('_num quarter', { number: 4 }),
            outlined: true
          },
          cast: val => ((parseFloat(val) || 0) * 100).toFixed(0)
        },
        {
          model: 'R003G3',
          component: 'v-text-field',
          cols: {
            cols: 12
          },
          provider: {
            vid: 'R003G3',
            name: 'Amount of income for the reporting period',
            rules: 'required|integer_or_decimal|min_value:0'
          },
          attrs: {
            label: 'Amount of income for the reporting period',
            outlined: true
          },
          cast: val => ((parseFloat(val) || 0) * 100).toFixed(0)
        },
        {
          model: 'R008G3',
          component: 'v-text-field',
          cols: {
            cols: 12
          },
          provider: {
            vid: 'R008G3',
            name: 'The total amount of income for the reporting period',
            rules: 'required|integer_or_decimal|min_value:0'
          },
          attrs: {
            label: 'The total amount of income for the reporting period',
            outlined: true
          },
          cast: val => ((parseFloat(val) || 0) * 100).toFixed(0)
        },
        {
          model: 'HNACTL',
          component: 'v-text-field',
          provider: {
            vid: 'HNACTL',
            name: 'The number of employees',
            rules: 'required|integer|min_value:0'
          },
          attrs: {
            label: 'The number of employees',
            outlined: true
          }
        }
      ]
    },
    fieldsSum () {
      if (this.reportType === this.model.REPORT_TYPES.second) {
        return this.fieldsSumSecondGroup
      }
      return [
        {
          component: 'v-text-field',
          cols: {
            cols: 12
          },
          provider: {
            name: 'The result from the beginning of the year'
          },
          attrs: {
            label: 'The result from the beginning of the year',
            outlined: true,
            disabled: true,
            visible: () => !this.isTemporary
          },
          cast: val => ((parseFloat(val) || 0) * 100).toFixed(2),
          fieldVal: () => ((parseFloat(this._.get(this.form, 'TAX.R006G3', 0)) / 100) || 0).toFixed(2)
        },
        {
          model: this.sumModelKey,
          component: 'v-text-field',
          cols: {
            cols: 12
          },
          provider: {
            vid: this.sumModelKey,
            name: this.sumFieldLabel,
            rules: 'required|integer_or_decimal|min_value:0'
          },
          attrs: {
            label: this.sumFieldLabel,
            outlined: true,
            type: 'number'
          },
          cast: val => ((parseFloat(val) || 0) * 100).toFixed(0)
        },
        {
          model: 'HNACTL',
          component: 'v-text-field',
          provider: {
            vid: 'HNACTL',
            name: 'The number of employees',
            rules: 'required|integer|min_value:0'
          },
          attrs: {
            label: 'The number of employees',
            outlined: true,
            visible: () => !this.isTemporary
          }
        }
      ]
    },
    fields () {
      return [
        {
          model: 'HSTI',
          label: {
            text: 'State Tax Service'
          },
          cols: {
            cols: 12
          },
          component: 'e-dialog-input',
          tooltip: 'Tax code',
          provider: {
            vid: 'HSTI',
            name: 'State Tax Service',
            rules: 'required'
          },
          asyncDefault: (items, response) => response,
          request: async () => {
            const taxRegion = this._.get(await TaxCodesDictionary.api().exists(['parent'], false).filter({ cReg: this._.get(this.$Organization, 'region') }).all(), 'entities.taxcodesdictionary[0]', null)
            if (taxRegion) {
              return this._.get(await TaxCodesDictionary.api().parent({ parent: taxRegion }).filter({ cRaj: this._.get(this.$Organization, 'district') }).all(), 'entities.taxcodesdictionary[0]', null)
            }
            return null
          },
          cast: val => this._.get(val, 'nameSti', val),
          attrs: {
            class: 'mb-4',
            valuePattern: '{nameSti}',
            requestItems: 'onlyRootLevel',
            modalAttrs: {
              component: 'm-orm-dialog-tree-select',
              title: 'Select dpi',
              model: TaxCodesDictionary
            }
          }
        },
        {
          model: 'reportingType',
          component: 'v-select',
          cols: {
            cols: 12
          },
          classes: ['mt-1'],
          provider: {
            vid: 'reportingType',
            name: 'Document status',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            disabled: true,
            appendIcon: '',
            label: 'Document status',
            items: [
              { text: this.$t('Reporting document'), value: 'HZ' },
              { text: this.$t('Reporting document new'), value: 'HZN' }
            ]
          }
        },
        {
          component: 'v-select',
          cols: {
            cols: 12,
            md: 6
          },
          provider: {
            name: 'Group',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            disabled: true,
            appendIcon: '',
            label: 'Group',
            items: [
              { text: this.$t('_number group', { number: '2' }), value: 2 },
              { text: this.$t('_number group', { number: '3' }), value: 3 }
            ]
          },
          fieldVal: () => this._.get(this.taxInfo, 'GRUP')
        },
        {
          component: 'v-select',
          cols: {
            cols: 12,
            md: 6
          },
          provider: {
            name: 'Tax rate',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            disabled: true,
            appendIcon: '',
            label: 'Tax rate',
            items: [
              { text: '2%', value: 2 },
              { text: '5%', value: 5 },
              { text: '20%', value: 20 }
            ]
          },
          fieldVal: () => this._.get(this.taxInfo, 'STAVKA')
        },
        {
          cols: {
            cols: 12
          },
          model: 'HTEL',
          cast: val => val && val.replace(/-/g, ''),
          component: 'e-input-mask',
          provider: {
            vid: 'HTEL',
            name: 'Phone',
            rules: 'required|phone'
          },
          attrs: {
            label: 'Phone',
            outlined: true,
            mask: 'mobile'
          }
        },
        {
          cols: {
            cols: 12
          },
          model: 'T1RXXXXG1S',
          component: 'v-select',
          provider: {
            vid: 'T1RXXXXG1S',
            name: 'KVED',
            rules: 'required'
          },
          classes: ['kved-select'],
          attrs: {
            label: 'KVED',
            outlined: true,
            chips: true,
            smallChips: true,
            multiple: true,
            visible: () => !this.isTemporary
          },
          items: () => this.kvedItems
        }
      ]
    },
    taxInfo () {
      return TaxInfo.query().first()
    },
    totalAmount () {
      return (parseFloat(this._.get(this.form, 'TAX.R006G3', 0)) || 0) + parseFloat(this.form[this.sumModelKey])
    },
    totalAmountSum () {
      const sum = parseFloat((this.totalAmount / 100).toFixed(2))
      return `${(sum || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')}`
    },
    sumModelKey () {
      return this.reportType === this.model.REPORT_TYPES.thirdTwo ? 'R001G3' : 'R006G3'
    },
    sumFieldLabel () {
      return this.$t('The amount of sales for the current _value', {
        value: this.reportType === this.model.REPORT_TYPES.thirdTwo ? this.$t('month') : this.$t('quarter')
      })
    },
    isTemporary () {
      return this.reportType === this.model.REPORT_TYPES.thirdTwo
    },
    isSecond () {
      return this.reportType === this.model.REPORT_TYPES.second
    },
    singleTaxSum () {
      const percent = this.isTemporary ? 2 : 5
      const value = parseFloat(this.form[this.sumModelKey])
      const sum = (value / 10000 * percent)
      return `${(sum || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')}`
    },
    esvSum () {
      const sum = parseFloat(this._.get(this.form, 'esvReportTaxOrder.taxSum.R09G4') || 0)
      if (!sum) {
        return null
      }
      return `${(sum || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ')}`
    }
  },
  watch: {
    'form.T1RXXXXG1S' (val) {
      const kvedText = []
      this._.each(val, (kved) => {
        const kvedItem = this._.find(this.kvedItems, item => item.value === kved)
        kvedText.push(kvedItem.kvedText)
      })
      this.$set(this.form, 'T1RXXXXG2S', kvedText)
    },
    reportType (val) {
      this.$emit('reportTypeChange', val)
    },
    form: {
      handler (val) {
        const payload = {
          ...(val || {}),
          [this.sumModelKey]: String(this._.get(val, this.sumModelKey)) || null
        }
        this.$emit('payloadChange', payload)
      },
      deep: true
    }
  },
  created () {
    this.filterAvailableReports()
  },
  methods: {
    async filterAvailableReports () {
      this.availableReports = this.generateAvailableReports()
      if (this.availableReports.length === 1) {
        this.reportType = this.availableReports[0]
        await this.getTaxInfo(this.availableReports[0], true)
      }
    },
    async openAddition () {
      await this.contentDialogTop.open({
        title: 'Addition',
        component: 'block-tax-report-addition-form',
        width: '1000px',
        contentFullHeight: true,
        componentProps: {
          item: this._.get(this.form, 'esvReportTaxOrder'),
          type: this.reportType,
          onSubmit: (data) => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.form.hasOwnProperty('R021G3')) {
              this.form.R021G3 = ((this._.get(data, 'taxSum.R09G4') || 0) * 100).toFixed(0)
            }
            this.$set(this.form, 'esvReportTaxOrder', data)
            this.additionFilled = true
          }
        }
      })
    },
    async viewReport () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.scrollToFailedValidation(this.$refs.form.$el)
        return
      }
      const payload = {
        ...this.form,
        [this.sumModelKey]: String(this._.get(this.form, this.sumModelKey)) || null
      }
      if (!this.additionFilled) {
        payload.esvReportTaxOrder = null
        payload.HD1 = null
        payload.R021G3 = null
      }
      await this.contentDialogTop.open({
        title: 'View the report',
        component: 'block-tax-report-view',
        width: '1000px',
        contentFullHeight: true,
        componentProps: {
          draft: true,
          reportType: this.reportType,
          payload
        }
      })
    },
    async getTaxInfo (val, onCreated) {
      try {
        this.loading = true
        let res
        const config = {}
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1

        if (val === this.model.REPORT_TYPES.thirdTwo) {
          const year = month === 1 && day >= 1 && day <= 20
          if (year) {
            config.params = { period: 'year' }
            this.yearReport = true
          }
          res = this._.get(await this.model.api().getTemporaryReportInfo(config), 'response.data')
        } else {
          await this.useEdsIitCheckboxDialog({
            hints: {
              options: this.$t('signPlugin.requestToSfs'),
              fileSign: this.$t('signPlugin.directorSign'),
              smartId: this.$t('signPlugin.smartId')
            },
            onConfirm: async ({ error, sign }) => {
              try {
                const edrpou = this._.get(this.$Organization, 'edrpou', '')
                const signedData = typeof sign === 'function' ? await sign(edrpou) : null

                if (error || !signedData) {
                  this.reportType = null
                  if (onCreated) {
                    await this.closeModal()
                  }
                  return null
                }

                if (val === this.model.REPORT_TYPES.thirdFive) {
                  const year = month === 1 || (month === 2 && day <= 9)
                  if (year) {
                    config.params = { period: 'year' }
                    this.yearReport = true
                  }
                  res = this._.get(await this.model.api().getReportInfo({ sign: signedData }, config), 'response.data')
                } else if (val === this.model.REPORT_TYPES.second) {
                  this.yearReport = true
                  res = this._.get(await this.model.api().getAnnualReportInfo({ sign: signedData }), 'response.data')
                }
              } catch (e) {
                this.$handlers.error(e, this)
              }
            }
          })
        }

        if (!res) {
          return
        }

        this.form = {
          ...res,
          HTEL: (this._.get(res, 'HTEL', '') || '').replace(/-|\(|\)/g, ''),
          [this.sumModelKey]: ((parseFloat(this._.get(res, this.sumModelKey, 0)) / 100) || 0).toFixed(2)
        }
        this.kvedItems = this._.map(this._.get(res, 'T1RXXXXG1S'), (kved, index) => {
          const kvedText = this._.get(res, 'T1RXXXXG2S', [])[index]
          return {
            value: kved,
            kvedText,
            text: `${kved} ${kvedText}`
          }
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async sendReport () {
      this.sendLoading = true
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.sendLoading = false
        this.scrollToFailedValidation(this.$refs.form.$el)
        return
      }
      await this.useEdsIitCheckboxDialog({
        fallbackMethod: this.sendReport,
        checkAuthService: true,
        createReportService: true,
        onConfirm: async ({ error, noReturn, reportService, fallbackMethod }) => {
          try {
            if (error) {
              return !noReturn ? this.sendReport() : null
            }

            const payload = {
              ...this.form,
              [this.sumModelKey]: String(this._.get(this.form, this.sumModelKey)) || null
            }

            if (this.additionFilled) {
              const R08G1D = this._.get(this.form, 'esvReportTaxOrder.R08G1D', null) ? this._.get(this.form, 'esvReportTaxOrder.R08G1D', null).split('-').reverse().join('') : null
              const R08G2D = this._.get(this.form, 'esvReportTaxOrder.R08G2D', null) ? this._.get(this.form, 'esvReportTaxOrder.R08G2D', null).split('-').reverse().join('') : null
              const taxSum = Object.assign({}, this._.get(this.form, 'esvReportTaxOrder.taxSum'))
              this._.each(taxSum, (val, key) => {
                if (val || val === 0 || val === '0') {
                  taxSum[key] = ((val || 0) * 100).toFixed(0)
                }
              })
              payload.esvReportTaxOrder = {
                ...this._.get(this.form, 'esvReportTaxOrder', {}),
                R08G1D,
                R08G2D,
                taxSum
              }
            } else {
              // eslint-disable-next-line no-prototype-builtins
              if (payload.hasOwnProperty('HD1')) {
                payload.HD1 = null
              }
              delete payload.esvReportTaxOrder
            }

            const methods = {
              create: 'createReport',
              xml: 'reportXmlDoc',
              sendToTax: 'reportSendToTax',
              signTaxId: 'reportTaxId'
            }

            if (this.reportType === this.model.REPORT_TYPES.thirdTwo) {
              methods.create = 'createTemporaryReport'
              methods.xml = 'temporaryReportXmlDoc'
              methods.sendToTax = 'temporaryReportSendToTax'
              methods.signTaxId = 'temporaryReportTaxId'
            } else if (this.reportType === this.model.REPORT_TYPES.second) {
              methods.create = 'createAnnualReport'
              methods.xml = 'annualReportXmlDoc'
              methods.sendToTax = 'annualReportSendToTax'
              methods.signTaxId = 'annualReportTaxId'
            }

            const createdEntity = this._.get(await this.model.api()[methods.create](payload), 'response.data')
            const xmlResponse = await this.model.api()[methods.xml](this._.get(createdEntity, 'id'))

            let taxPayload = null

            if (this.additionFilled) {
              taxPayload = []
              for (const item of this._.get(xmlResponse, 'response.data', [])) {
                const xml = Base64js.toByteArray(this._.get(item, 'xml', null))
                const fname = this._.get(item, 'fname', null)
                const signData = await reportService.getEnvelopedData({
                  dataToSign: xml,
                  verifyKey: true,
                  fallbackMethod
                })
                taxPayload.push({ signData, fname })

                if (!signData) {
                  return null
                }
              }
            } else {
              const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
              const fname = this._.get(xmlResponse, 'response.data.fname', null)
              const signData = await reportService.getEnvelopedData({
                dataToSign: xml,
                verifyKey: true,
                fallbackMethod
              })
              taxPayload = { signData, fname }

              if (!signData) {
                return null
              }
            }

            // SENDING TO THE TAX
            const taxId = this._.get(await this.model.api()[methods.sendToTax](this._.get(createdEntity, 'id'), taxPayload), 'response.data.response.taxId', null)
            const encodedTaxId = new TextEncoder().encode(String(taxId))
            const signedTaxId = await reportService.getEnvelopedData({
              dataToSign: encodedTaxId,
              isXml: false,
              fallbackMethod
            })
            if (!signedTaxId) {
              return null
            }
            await this.model.api()[methods.signTaxId](this._.get(createdEntity, 'id'), { tax_id: signedTaxId })

            this.$gtm.push({
              event: 'tax_reports_create_success',
              payload: JSON.stringify(payload),
              organization: this._.get(this.$Organization, 'owner.email')
            })

            this.$notification.success(this.$t('The report was successfully sent to the State Tax Service'))
            await this.closeModal(true)
          } catch (e) {
            this.$gtm.push({
              event: 'tax_reports_create_error',
              payload: JSON.stringify(e && e.message),
              organization: this._.get(this.$Organization, 'owner.email')
            })
            this.$handlers.error(e, this)
            this.scrollToFailedValidation(this.$refs.form.$el)
          }
        }
      })
      this.sendLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
  }

  &__no-data {
    text-align: center;
    padding: 15px 0 30px;
  }

  &__sum-fields-wrap {
    border-radius: 10px;
    border: 1px solid rgba(22, 27, 37, 0.2);
    box-shadow: 0 0 5px 1px rgba(22, 27, 37, 0.2);
    padding: 25px 15px 15px;
    margin: 0 -15px 15px;
  }

  &__sum-text-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
  }

  &__error {
    color: $color-error;
    font-size: 12px;
    line-height: 12px;
    margin-top: 5px;
  }

  &::v-deep {
    .kved-select {
      .v-select__slot {
        padding: 5px 0;
      }
      .v-select__selections {
        input {
          height: 0;
          padding: 0;
        }
      }
      .v-chip__content {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
