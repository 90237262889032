<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('We are creating new cash registers (PRRO)') }}
    div
      p(class="dialog__text") {{ $t('Please do not close this window until the process is complete') }}
      v-progress-linear(
        height="20"
        :value="sendItemsValue"
        class="mb-6"
      )
        span {{ sentItems }}/{{ totalItems }}
</template>

<script>
import Base64js from 'base64-js'
import promoCashRegisters from '~/modules/promotion/mixins/promoCashRegisters'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import PromoCashRegistersForSelection from '~/modules/promotion/models/PromoCashRegistersForSelection'
import CashRegisterOrder from '~/models/directories/Orders/CashRegisterOrder'

export default {
  name: 'BlockPromotionSendProgress',
  mixins: [promoCashRegisters, edsIitCheckboxDialog],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    onSuccess: {
      type: Function,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    sentItems: 0
  }),
  computed: {
    totalItems () {
      return this._.get(this.items, 'length') || 0
    },
    sendItemsValue () {
      return Math.round(this.sentItems / this.totalItems * 100)
    },
    model () {
      return PromoCashRegistersForSelection
    },
    cashRegisterOrderModel () {
      return CashRegisterOrder
    }
  },
  created () {
    this.sendItems()
  },
  methods: {
    async sendItems () {
      for (let i = 0; i < this.items?.length; i++) {
        const item = this.items[i]
        await this.sendItem(item)
      }
      if (this.sentItems) {
        this.sendGtmEvent(1)
        if (this._.isFunction(this.onSuccess)) {
          await this.onSuccess()
        }
        await this.closeModal()
        await this.contentDialog.open({
          component: 'block-promotion-success',
          width: '560px',
          componentProps: {
            cashRegistersSent: this.sentItems
          }
        })
      } else {
        this.closeModal()
      }
    },
    async sendItem (item) {
      await this.useEdsIitCheckboxDialog({
        fallbackMethod: this.sendItem,
        checkAuthService: true,
        createReportService: true,
        onlyFileSign: true,
        onConfirm: async ({ error, noReturn, reportService, fallbackMethod }) => {
          try {
            if (error) {
              return !noReturn ? this.sendItem() : null
            }

            const modelItem = this.model.query().whereId(item?.id).first()
            const additionalData = modelItem.getAdditionalData()
            const payload = {
              HKBOS: reportService.getHKBOSField({
                sentry: this.$sentry,
                organization: this.$Organization,
                entity: this._.get(this.model, 'entity'),
                type: 'creation'
              }),
              outletId: this._.get(additionalData, 'outlet.id', null),
              M04: this._.get(additionalData, 'prroType', null)
            }

            const re = /([0-9]{10}|[АБВГДЕЄЖЗИІКЛМНОПРСТУФХЦЧШЩЮЯ]{2}[0-9]{6}|[0-9]{9})/

            if (!re.test(payload.HKBOS)) {
              this.$handlers.error(this.$t('It is not possible to find the registration number of the director of the organization. Check the EDS key with which you sign documents'), this)
              return
            }

            if (!payload.HKBOS) {
              this.$handlers.error(this.$t('We could not retrieve data from your EDS key, please check whether it is correct or choose another EDS key'), this)
              return
            }

            const cashRegisterTaxOrders = this._.get(await this.model.api().createOrder(item?.id, payload), 'response.data.cashRegisterTaxOrders', [])
            const createdEntity = this._.find(cashRegisterTaxOrders, i => i?.status === 100 && i?.type === 100 && !i?.dateSend)
            const xmlResponse = await this.cashRegisterOrderModel.api().xmlDoc(createdEntity)
            const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
            const fname = this._.get(xmlResponse, 'response.data.fname', null)
            const signData = await reportService.getEnvelopedData({
              dataToSign: xml,
              verifyKey: true,
              fallbackMethod
            })

            if (!signData) {
              this.$handlers.error('An error occurred while signing the document. Please try again or choose a different key', this)
              return null
            }

            const taxId = this._.get(await this.cashRegisterOrderModel.api().taxRegister(createdEntity, { signData, fname }), 'response.data.response.taxId', null)
            const encodedTaxId = new TextEncoder().encode(String(taxId))
            const signedTaxId = await reportService.getEnvelopedData({
              dataToSign: encodedTaxId,
              isXml: false,
              fallbackMethod
            })

            if (!signedTaxId) {
              this.$handlers.error('An error occurred while signing the document. Please try again or choose a different key', this)
              return null
            }

            await this.cashRegisterOrderModel.api().signTaxId(createdEntity, { tax_id: signedTaxId })
            this.sentItems += 1
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__text {
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }
}
</style>
