<template lang="pug">
  div(class="pos-terminal-connecting-options")
    v-btn(
      class="pos-terminal-connecting-options__btn"
      @click="$emit('showTerminalSettingsForm', true)"
    )
      div(class="pos-terminal-connecting-options__btn-icon pos-terminal-connecting-options__btn-icon--terminal")
        e-svg-icon terminal
      div
       h4(class="pos-terminal-connecting-options__btn-title") {{ $t('Connect your terminal to the cash register') }}
       p(class="pos-terminal-connecting-options__btn-text") {{ $t('Connect your classic or Android terminal to the Checkbox system.') }}
    v-btn(
      class="pos-terminal-connecting-options__btn"
      @click="$emit('showBankSelectionForm', true)"
    )
      span(class="pos-terminal-connecting-options__beta") {{ $t('Beta')}}
      div(class="pos-terminal-connecting-options__btn-icon")
        e-svg-icon shopping-cart-4
      div
       h4(class="pos-terminal-connecting-options__btn-title") {{ $t('Order a new terminal') }}
       p(class="pos-terminal-connecting-options__btn-text") {{ $t('Order a new POS terminal from the bank and track the delivery status.') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'BlockPosTerminalConnectingOptions',
  components: {
    ESvgIcon
  }
}
</script>

<style scoped lang="scss">
.pos-terminal-connecting-options {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding-bottom: 28px;

  &__beta {
    font-size: 0.7rem;
    color: $color-warning;
    position: absolute;
    top: -11px;
    right: -8px;
  }

  &__btn {
    padding: 16px !important;
    border-radius: 12px;
    height: auto !important;
    text-align: left;
    width: calc(50% - 6px);
    justify-content: flex-start;
    letter-spacing: normal;
    white-space: normal;
    position: relative;

    @media (max-width: map-get($breakpoints, 'md')) {
      width: 100%;
    }

    &::v-deep {
      .v-btn__content {
        display: flex;
        justify-content: flex-start;
        gap: 16px;
        width: 100%;
        align-items: flex-start;
      }
    }
  }

  &__btn-icon {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    background: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--terminal {
      svg {
        margin-right: -3px;
      }
    }
  }

  &__btn-title {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #161B25;
  }

  &__btn-text {
    font-size: 12px;
    font-weight: 300;
    color: rgba(22, 27, 37, 0.75);
    margin: 0;
  }
}
</style>
