import Dialog from '~/models/system/Dialog'

const contentDialogOverTop = {
  computed: {
    contentDialogOverTop () {
      return Dialog.query().where('type', 'content_over_top').first()
    }
  }
}
export default contentDialogOverTop
