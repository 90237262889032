import { Model } from '@vuex-orm/core'
import User from '../administration/User'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import CashRegisters from '~/models/directories/CashRegisters'
import Organization from '~/models/directories/Organization'

export default class PromoCodeRroShare extends ChainInheritance(Directory, []) {
  static entity = 'promocodesrroshare'

  static ormTrans = {
    single: 'Promo code rro share',
    multy: 'Promo codes rro share'
  }

  static fields () {
    return {
      user_id: this.attr(null),
      organization_id: this.attr(null),
      cash_register_id: this.attr(null),

      id: this.attr(null),
      status: this.attr(null),
      description: this.attr(null),
      appliedAt: this.attr(null),
      monthsCount: this.attr(null),
      appliedBy: this.belongsTo(User, 'user_id'),
      cashRegister: this.belongsTo(CashRegisters, 'cash_register_id'),
      organization: this.belongsTo(Organization, 'organization_id')
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      return configActions
    }
  }
}
