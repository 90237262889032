<template lang="pug">
  e-autocomplete(
    v-bind="$attrs"
    :chipText="item => item.name"
    :customQuery="_ => true"
    :item="autocompleteItem"
    :label="$t('Organizations')"
    @change="handleChange"
    dense
    flat
    outlined
    multiple
    return-object
  )
</template>

<script>
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import ManagedOrganizations from '~/models/directories/ManagedOrganizations'

export default {
  name: 'EInputOrganizationsSelect',
  components: {
    EAutocomplete
  },
  computed: {
    autocompleteItem () {
      return {
        attrs: {
          multiple: true,
          itemClass: 'ws-pre',
          returnObject: true,
          itemsFilter: items => this.listOrganizations(items)
        },
        searchModel: ManagedOrganizations,
        query: (model, ctx, config) => model.api().getByUser(this.$User.id, config.params)
      }
    },
    ownedOrganization () {
      return {
        ...this._.get(this.$User, 'ownedOrganization', {}),
        toString () {
          return this.name
        }
      }
    },
    allOrganizationsItem () {
      return {
        name: this.$t('All organizations'),
        allOrganizationsItem: true,
        toString () {
          return this.name
        }
      }
    }
  },
  created () {
    this.$emit('input', [this.allOrganizationsItem])
  },
  methods: {
    listOrganizations (items) {
      const arr = [].concat(items)

      if (this._.get(this.$User, 'ownedOrganization')) {
        arr.unshift(this.ownedOrganization)
      }
      arr.unshift(this.allOrganizationsItem)

      return arr
    },
    handleChange (val) {
      const allOrgsItemSelected = this._.find(val, i => i?.allOrganizationsItem)
      if (allOrgsItemSelected) {
        if (val[0].allOrganizationsItem && val.length !== 1) {
          this.$emit('input', this._.filter(val, i => !i?.allOrganizationsItem))
        } else {
          this.$emit('input', [this.allOrganizationsItem])
        }
      } else if (!val.length || val.length === this.allOrganizationsItem.value.length) {
        this.$emit('input', [this.allOrganizationsItem])
      } else {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
