import { Model } from '@vuex-orm/core'
import Tags from '~/models/directories/Tags'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Organization from '~/models/directories/Organization'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import i18n from '~/plugins/nuxt-i18n/i18n'

export default class Billings extends ChainInheritance(Directory, []) {
  static entity = 'billings'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'status'

  static ormTrans = {
    single: 'Billing',
    multy: 'Billings'
  }

  static fields () {
    return {
      orgId: this.attr(null),

      id: this.attr(null),
      discount: this.attr(null),
      organization: this.belongsTo(Organization, 'orgId'),
      units: this.attr(null),
      verifCode: this.attr(null),
      oldVerifCode: this.attr(null),
      status: this.attr(null),
      autoPayment: this.attr(null),
      notBlock: this.attr(null),
      considerReceiptsCount: this.attr(null),
      considerNewCashRegisters: this.attr(null),
      cardToken: this.attr({}),
      includeAllCashRegistersInAct: this.attr(null),
      bonusUnits: this.attr(null),
      sendInvoiceReminder: this.attr(null),
      hideBilling: this.attr(false)
    }
  }

  get statusString () {
    return this._.get(Organization, `billingStatuses[${this.status}].text`, '—')
  }

  get autoPaymentString () {
    return this.autoPayment ? '+' : '-'
  }

  get notBlockString () {
    return this.notBlock ? '+' : '-'
  }

  get considerReceiptsCountString () {
    return this.considerReceiptsCount ? '+' : '-'
  }

  get considerNewCashRegistersString () {
    return this.considerNewCashRegisters ? '+' : '-'
  }

  get sendInvoiceReminderString () {
    return this.sendInvoiceReminder ? '+' : '-'
  }

  get verifCodeString () {
    return this.verifCode ? '#' + this.verifCode : ''
  }

  get oldVerifCodeString () {
    return this.oldVerifCode ? '#' + this.oldVerifCode : ''
  }

  static ormHeaders = [
    { text: 'Organization ', value: 'organization.name', sortable: true, filterable: false },
    { text: 'Status', value: 'statusString', sortable: true, filterable: false },
    { text: 'Prices/Discounts', value: 'id', sortable: false, filterable: false },
    { text: 'Do not block', value: 'notBlockString', sortable: true, filterable: false },
    { text: 'Take shifts into invoice', value: 'considerReceiptsCountString', sortable: true, filterable: false },
    { text: 'Add new cash register to invoice', value: 'considerNewCashRegistersString', sortable: true, filterable: false },
    { text: 'Auto payment', value: 'autoPaymentString', sortable: true, filterable: false },
    { text: 'Send invoice reminder', value: 'sendInvoiceReminderString', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    id: {
      component: 'm-block-billing-prices'
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    },
    {
      model: 'organization.tags',
      component: 'v-select',
      provider: {
        name: 'Tags',
        vid: 'tags',
        rules: ''
      },
      attrs: {
        label: 'Tags',
        outlined: true,
        clearable: true,
        'return-object': true,
        'item-text': value => value.name
      },
      async request () {
        Tags.deleteAll()
        await Tags.api().all()
      },
      items: () => Tags.all()
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'organization.tags'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    {
      model: 'notBlock',
      component: 'v-checkbox',
      provider: {
        vid: 'notBlock',
        name: 'Do not block'
      },
      attrs: {
        label: 'Do not block'
      }
    },
    {
      model: 'considerReceiptsCount',
      component: 'v-checkbox',
      provider: {
        vid: 'considerReceiptsCount',
        name: 'Take shifts into invoice'
      },
      attrs: {
        label: 'Take shifts into invoice'
      }
    },
    {
      model: 'considerNewCashRegisters',
      component: 'v-checkbox',
      provider: {
        vid: 'considerNewCashRegisters',
        name: 'Add new cash register to invoice'
      },
      attrs: {
        label: 'Add new cash register to invoice'
      }
    }
  ]

  static ormActions = [
    {
      name: 'read'
    },
    {
      name: 'edit',
      visible: (item, user) => {
        return user?.isBillingManager
      }
    },
    {
      name: 'set_payment_settings',
      text: i18n.t('Set payment settings'),
      visible: (item, user) => {
        return user?.isBillingManager
      },
      call: async (item) => {
        await Organization.api().read(item.organization.id)
        const organization = Organization.query().whereId(item.organization.id).first()
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          title: 'Payment settings',
          width: '600px',
          component: 'm-form-payment-settings',
          componentProps: {
            item: organization
          }
        })
      }
    },
    {
      name: 'setSSISetting',
      text: 'Set SSI setting',
      visible: (item, user) => {
        return user?.isPartnersManager
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: 'Are you sure you want to install SSI settings?',
          width: '500px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              const tagId = '92c59dba-1858-4d50-b141-8b638db90d48'
              await Billings.api().individualSetting(item.id, { tagId })
              AppNotifications.success('SSI setup successfully installed')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    },
    {
      name: 'setGoodTiresSetting',
      text: 'Set GoodTires setting',
      visible: (item, user) => {
        return user?.isPartnersManager
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: 'Are you sure you want to install GoodTires settings?',
          width: '500px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              const tagId = '49cf33b7-54e0-4bed-aad1-a0b33c6910b8'
              await Billings.api().individualSetting(item.id, { tagId })
              AppNotifications.success('GoodTires setup successfully installed')
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog',
    edit: 'm-orm-crud-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'organization.name',
            label: 'Organization '
          },
          {
            model: 'statusString',
            label: 'Status'
          },
          {
            model: 'notBlockString',
            label: 'Do not block'
          },
          {
            model: 'considerReceiptsCountString',
            label: 'Take shifts into invoice'
          },
          {
            model: 'verifCodeString',
            label: 'Payment code'
          },
          {
            model: 'oldVerifCodeString',
            label: 'Old payment code'
          }
        ]
      }
    },
    edit: {
      config: {
        context: 'update'
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.unsetAutoPayment = function (id) {
        return this.put(Model.$routes.billings.unsetAutoPayment(id), {}, { dataKey: null })
      }
      configActions.getReconciliationAct = function (id, params) {
        return this.get(Model.$routes.billings.reconciliationAct(id), {
          responseType: 'blob',
          save: false,
          params
        })
      }
      configActions.getReconciliationActOnAllOrganizations = function (params) {
        return this.get(Model.$routes.billings.reconciliationActOnAllOrganizations(), {
          responseType: 'blob',
          save: false,
          params
        })
      }
      configActions.organizationBilling = function (id) {
        Billings.deleteAll()
        return this.get(Model.$routes.billings.organizationBilling(id))
      }
      configActions.ccActive = function (id) {
        return this.get(Model.$routes.billings.ccActive(id))
      }
      configActions.activationCount = function (id) {
        return this.get(Model.$routes.billings.activationCount(id))
      }
      configActions.generateInvoice = function (id) {
        return this.get(Model.$routes.billings.generateInvoice(id))
      }
      configActions.changeBillingDate = function (id, payload) {
        return this.put(Model.$routes.billings.changeBillingDate(id), payload)
      }
      configActions.individualSetting = function (id, payload) {
        return this.put(Model.$routes.billings.individualSetting(id), payload)
      }
      configActions.getPrices = function (id) {
        return this.get(Model.$routes.billings.getPrices(id), { save: false })
      }
      configActions.updatePrice = function (payload) {
        return this.post(Model.$routes.billings.updatePrice(), payload, { save: false })
      }
      configActions.updateDiscount = function (payload) {
        return this.post(Model.$routes.billings.updateDiscount(), payload, { save: false })
      }
      configActions.editDiscount = function (id, payload) {
        return this.put(Model.$routes.billings.editDiscount(id), payload, { save: false })
      }
      return configActions
    }
  }
}
