<template lang="pug">
  ValidationObserver(
    :ref="model.entity"
    slim
    v-else
  )
    v-form(@submit.prevent.stop="handleSubmit")
      t-orm-fields(
        v-bind.sync="editedItem"
        :items="fields"
        :tabs="model.ormTabs"
        :key="fieldsKey"
      )
      t-orm-fields(
        v-bind.sync="depositSignRequest"
        v-if="isDepositSign"
        :items="depositSignRequestFields"
      )
      v-radio-group(
        v-if="isEdsKey && keyFile"
        v-model="uploadCashierEdsKey"
        class="radio-group mt-0 pt-0 mb-4"
        row
        hide-details
      )
        template(v-slot:label)
          span(class="hint") {{$t('Launch Checkbox Signature on a secure cloud service')}}?
        v-radio(
          :ripple="false"
          color="black"
          key="yes"
          :value="true"
          :label="$t('Yes (recommended)')"
        )
        v-radio(
          :ripple="false"
          color="black"
          key="no"
          :value="false"
          :label="$t('No')"
        )

      div(v-if="isManually")
        t-orm-fields(
          v-if="selectedKeyType === 'edsKey'"
          v-bind.sync="editedItem"
          :items="edsKeyFields"
        )
        m-form-depositsign-token(
          :show-button="false"
          ref="depositSignForm"
          v-else
        )
      div(class="d-flex justify-end mt-2")
        v-btn(
          class="main-button"
          :loading="taxEmployeeLoading"
          type="submit"
        ) {{ $t('Continue') }}
</template>

<script>
import Base64js from 'base64-js'
import { VRadio, VRadioGroup } from 'vuetify/lib'
import DOMPurify from 'dompurify'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import ELink from '~/components/elements/links/e-link'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import EInfoTooltip from '~/components/elements/tooltips/e-info-tooltip'
import informationDialog from '~/mixins/dialogs/informationDialog'
import MFormDepositsignToken from '~/components/modules/forms/concrete/m-form-depositsign-token'
import responsive from '~/mixins/pages/responsive'
import employeeCreating from '~/modules/employees/mixins/employeeCreating'

export default {
  name: 'FormEmployee',
  components: {
    EInfoTooltip,
    EInputFileContent,
    TOrmButtons,
    TOrmFields,
    ELink,
    VRadio,
    VRadioGroup,
    MFormDepositsignToken
  },
  mixins: [validate, filterOrmObject, informationDialog, responsive, employeeCreating],
  props: {
    choice: {
      type: String,
      default: ''
    },
    keyFile: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    model: EmployeeOrder,
    editedItem: {},
    depositSignRequest: {
      phoneNumber: null
    },
    taxEmployeeLoading: false,
    uploadCashierEdsKey: true,
    enterManuallyChoice: false,
    loading: false,
    selectedKeyType: 'edsKey',
    fieldsKey: 0
  }),
  computed: {
    isManually () {
      return this.choice === this.steps.manualEmployeeForm || this.choice === this.steps.existingDsKey
    },
    isDepositSign () {
      return this.choice === this.steps.creatingDsKey
    },
    isEdsKey () {
      return this.choice === this.steps.selectEdsKey
    },
    depositSignRequestFields () {
      return [
        {
          model: 'phoneNumber',
          cast: val => val && val.replace(/-/g, ''),
          component: 'e-input-mask',
          provider: {
            vid: 'phoneNumber',
            name: 'Phone',
            rules: 'required|phone'
          },
          attrs: {
            label: 'Phone',
            outlined: true,
            mask: 'mobile'
          },
          fieldVal: () => this._.get(this.$User, 'phone', '')
        }
      ]
    },
    edsKeyFields () {
      return [
        {
          label: {
            text: 'Enter certificate data manually'
          },
          cols: {
            cols: 12
          },
          classes: ['mt-3'],
          model: 'T1RXXXXG3S',
          component: 'v-text-field',
          provider: {
            vid: 'T1RXXXXG3S',
            name: 'Specify the EDS key ID',
            rules: 'required'
          },
          attrs: {
            label: 'Specify the EDS key ID',
            type: 'text',
            outlined: true
          }
        }
      ]
    },
    fields () {
      return this.model.ormFields
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
    if (this.item) {
      this.setFields(this.item)
    }
    if (this.choice === this.steps.existingDsKey) {
      this.selectedKeyType = 'depositSign'
    }
  },
  methods: {
    rerenderFields () {
      this.fieldsKey += 1
    },
    setFields (data) {
      if (!data) {
        return
      }
      const newItem = Object.assign({}, this.editedItem, data)
      this.$set(this.$data, 'editedItem', newItem)
      this.rerenderFields()
    },
    setKeyFields () {
      const data = {
        keyFile: Base64js.fromByteArray(new Uint8Array(this.keyFile.keyFile)),
        keyFileCa: this.keyFile.ca,
        keyPassword: this.keyFile.keyPassword
      }
      this.setFields(data)
    },
    async handleSubmit () {
      if (!await this.validate()) {
        return false
      }
      try {
        this.taxEmployeeLoading = true

        if (this.isManually && this.selectedKeyType === 'depositSign') {
          const dsTokenSet = await this.setDepositSignData()
          if (!dsTokenSet) {
            return
          }
        }

        if (this.keyFile && this.uploadCashierEdsKey) {
          this.setKeyFields()
        }
        const payload = this.filterOrmObject(this.editedItem)
        const login = payload.login
        const checkLoginResponse = await this.model.api().checkLogin({ login })
        const freeLogin = this._.get(checkLoginResponse, 'response.data.freeLogin', null)
        if (freeLogin) {
          await this.informationDialog.open({ text: this.$t('dialogs.information.loginOccupiedByAnotherUser', { login: DOMPurify.sanitize(login), freeLogin }), width: '600px' })
          this.editedItem.login = freeLogin
          await this.rerenderFields()
          return
        }

        this.$emit('signing', {
          payload,
          depositSignRequest: this.depositSignRequest
        })
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
      } finally {
        this.taxEmployeeLoading = false
      }
    },
    async setDepositSignData () {
      const data = await this.$refs.depositSignForm.setToken()
      if (!data.token || !data.password || !data.publicKeyId) { return false }

      if (data.fullName) {
        this.editedItem.T1RXXXXG1S = data.fullName
      }
      this.editedItem.T1RXXXXG3S = data.publicKeyId.toLowerCase()
      this.editedItem.T1RXXXXG2S = data.DRFOCode
      this.editedItem.dsToken = data.token
      this.editedItem.dsPass = data.password
      this.editedItem.certificateEnd = data.certificateEnd
      this.editedItem.certificateStart = data.certificateStart
      this.editedItem.certificateInfo = data.certificateInfo
      this.editedItem.ca = data.ca
      this.editedItem.signatureType = 'DEPOSITSIGN'
      return true
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem!important;
  line-height: 1.2!important;
}
.choice-text {
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 20px 0 40px;
  flex-grow: 1;
}
.enter-manually-btn {
  margin-bottom: 38px;
}
.radio-group {
  &::v-deep {
    legend {
      width: 100%;
      margin-bottom: 12px;
      height: auto;
    }
  }
}

.manually-buttons-wrap {
  border: 2px solid black;
  border-radius: 13px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 24px;

  &::v-deep {
    .v-btn {
      width: 50%;
      border-radius: 10px;

      &:not(.theme--dark) {
        background-color: transparent !important;
      }
    }
  }
}

.main-button {
  width: 100%;

  @media (min-width: map-get($breakpoints, 'xs')) {
    max-width: 180px;
  }
}
</style>
