<template lang="pug">
  div(v-if="permittedItems.length" class="t-orm-menu-actions")
    div(class="py-0 d-flex justify-center")
      v-menu(
        v-bind="menuOptions"
        content-class="action-icons"
        v-model="menu"
      )
        template(v-slot:activator="{ on }")
          v-btn(
            v-if="mobileActivatorButton"
            v-on="on"
            :outlined="!menu"
            class="w-100 mt-6"
            :class="{ 'secondary-button': !menu, 'black': menu }"
            @click="menu = !menu"
          ) {{ $t('Actions') }}
          v-btn(
            v-on="on"
            @click="menu = !menu"
            :max-width="xsDevice && !smallButton ? 20 : 'auto'"
            :max-height="xsDevice && !smallButton ? 20 : 'auto'"
            :small="smallButton"
            icon
            v-else
          )
            v-icon(:size="xsDevice ? 20 : 'auto'") mdi-dots-horizontal
        v-list(
          v-if="menu"
          dense
        )
          template(v-for="(item, i) in permittedItems")
            v-list-item(
              v-bind="attrs(item)"
              :key="i"
              @click="itemCall(item)"
            )
              v-list-item-icon(v-if="item.icon")
                component(
                  :is="item.icon.type"
                  v-bind="item.icon.attrs ? item.icon.attrs : {}"
                  :name="iconName(item)"
                  :class="iconClasses(item)"
                  :text="iconName(item)"
                )

              v-list-item-content
                v-list-item-title {{ _.upperFirst($t(text(item))) }}

            hr(v-if="item.length > 1" class="align-self mx-auto")
</template>

<script>
import { VIcon } from 'vuetify/lib'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'

export default {
  components: {
    VIcon,
    EFlatIcon,
    ESvgIcon
  },
  mixins: [responsive, processingApiRequest],
  props: {
    target: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    mobileActivatorButton: {
      type: Boolean,
      default: false
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
    smallButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      permittedItems: []
    }
  },
  computed: {
    menuOptions () {
      return {
        left: !this.xsDevice,
        bottom: !this.xsDevice,
        nudgeLeft: !this.xsDevice ? 30 : 0,
        top: this.xsDevice,
        offsetY: this.xsDevice,
        ...this.menuProps
      }
    }
  },
  watch: {
    // TODO: rework target update
    target (val, prevVal) {
      if (val?.mode !== prevVal?.mode) {
        this.setPermittedItems()
      }
    }
  },
  created () {
    this.setPermittedItems()
  },
  methods: {
    setPermittedItems () {
      setTimeout(() => {
        this.permittedItems = this._.filter(this.items, this.isVisible)
      }, 0)
    },
    isVisible (item) {
      return typeof item.visible === 'function' ? item.visible(this.target, this.$User || {}, item.passOrganization ? this.$Organization : null) : true
    },

    isDisabled (item) {
      const rules = [
        this._.isFunction(item.disabled) ? item.disabled(this.target) : false
      ]

      return this._.compact(rules).length === rules.length
    },

    attrs (item) {
      const attrs = Object.assign({}, item.attrs)

      if (this.isDisabled(item)) {
        delete attrs.color
      }

      return attrs
    },

    text (item) {
      return this._.isFunction(item.text) ? item.text(this.target) : item.text
    },
    iconName (item) {
      return this._.isFunction(item.icon.text) ? item.icon.text(this.target) : item.icon.text
    },

    iconClasses (item) {
      const color = this._.get(this.attrs(item), 'color', null)

      return color ? `${color}--text` : null
    },
    async itemCall (item) {
      if (this._.isFunction(item.call)) {
        const isVisible = this.isVisible(item)
        await item.call(this.target, item.data, this)

        if (isVisible !== this.isVisible(item)) {
          this.setPermittedItems()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.t-orm-menu-actions {
  //
  padding: 4px 0;
}

.action-icons.v-menu {
  &__content {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    overflow: visible;
    contain: none;

    .v-list {
      hr {
        width: 90%;
        border: none;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.12);

        &:last-child {
          display: none;
        }
      }
    }
  }
}
</style>
