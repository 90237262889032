<template lang="pug">
  v-simple-table()
    tbody()
      t-orm-simple-data-item(:item="item" v-if="(item.val && item.hideEmpty) || !item.hideEmpty" v-for="(item, index) in items" :key="index")
</template>

<script>
import TOrmSimpleDataItem from '~/components/templates/orm/t-orm-simple-data-item'
export default {
  components: { TOrmSimpleDataItem },
  props: {
    items: {
      type: Array,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
