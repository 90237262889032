<template lang="pug">
  div(class="dialog")
    div(class="dialog__title mb-2") {{ $t(title) }}
    ValidationObserver(ref="form" slim)
      v-form(@submit.prevent.stop="addData")
        t-orm-fields(
          v-bind.sync="form"
          :items="fields"
        )
        div(class="dialog__actions")
          v-btn(
            class="main-button d-block mx-auto"
            type="submit"
          ) {{ $t(btnText) }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'

export default {
  name: 'BlockEpzDataForm',
  components: {
    TOrmFields
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: 'EPZ data'
    },
    btnText: {
      type: String,
      default: 'Save'
    }
  },
  data: () => ({
    form: {
      card_mask: null,
      auth_code: null,
      rrn: null,
      payment_system: null,
      owner_name: null,
      terminal: null,
      bank_name: null,
      receipt_no: null
    },
    cardMaskChanged: false
  }),
  computed: {
    fields () {
      return [
        {
          model: 'card_mask',
          component: 'e-input-mask',
          provider: {
            vid: 'card_mask',
            name: 'Card mask',
            rules: 'min:16|max:16'
          },
          attrs: {
            label: 'Card mask',
            outlined: true,
            mask: this.cardMaskChanged ? 'cardMaskVisible' : 'cardMask'
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'auth_code',
          component: 'v-text-field',
          provider: {
            vid: 'auth_code',
            name: 'Auth code',
            rules: 'min:6|max:6|latin_number'
          },
          attrs: {
            label: 'Auth code',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'rrn',
          component: 'v-text-field',
          provider: {
            vid: 'rrn',
            name: 'RRN',
            rules: 'min:12|max:12|latin_number'
          },
          attrs: {
            label: 'RRN',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'payment_system',
          component: 'v-combobox',
          provider: {
            vid: 'payment_system',
            name: 'Payment system'
          },
          attrs: {
            label: 'Payment system',
            outlined: true
          },
          items: () => ['Visa', 'Mastercard'],
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'owner_name',
          component: 'v-text-field',
          provider: {
            vid: 'owner_name',
            name: 'Owner name',
            rules: 'latin_letters_spaces'
          },
          attrs: {
            label: 'Owner name',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'terminal',
          component: 'v-text-field',
          provider: {
            vid: 'terminal',
            name: 'Terminal'
          },
          attrs: {
            label: 'Terminal',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'bank_name',
          component: 'v-text-field',
          provider: {
            vid: 'bank_name',
            name: 'Bank'
          },
          attrs: {
            label: 'Bank',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'receipt_no',
          component: 'v-text-field',
          provider: {
            vid: 'receipt_no',
            name: 'Receipt number'
          },
          attrs: {
            label: 'Receipt number',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        }
      ]
    }
  },
  watch: {
    'form.card_mask' (val, prevVal) {
      if (val && prevVal && val.length < prevVal.length && val.length <= 16 && prevVal.length <= 16) {
        this.cardMaskChanged = true
      }
    }
  },
  created () {
    if (this.item) {
      this.$set(this.$data, 'form', Object.assign(this.form, this.item))
      this.$set(this.form, 'terminal', this._.get(this.$localStorageClientData(), 'epzData.terminal', null))
    }
  },
  methods: {
    async addData () {
      const valid = await this.$refs.form.validate()
      if (valid) {
        this.$setLocalStorageClientData({
          epzData: {
            ...this._.get(this.$localStorageClientData(), 'epzData', {}),
            terminal: this._.get(this.form, 'terminal')
          }
        })
        const data = this._.pickBy(this.form, v => v === 0 || Boolean(v))
        this.closeModal(data)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
