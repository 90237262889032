<template lang="pug">
  div(class="dialog")
    div(class="dialog__content")
      div(
        class="dialog__preview"
        ref="preview-wrap"
      )
        iframe(
          ref="receiptDoc"
          class="d-none"
        )
        pre(ref="preview") {{ _.get(preview, 'receipt', null) || preview }}
        img(
          v-if="preview.qrCode"
          class="dialog__qr-code"
          :src="preview.qrCode"
          width="250px"
        )
    div(class="dialog__actions")
      v-col(cols="12" class="receipt__buttons")
        e-link(v-if="isReceipt" @click="copyToClipboard")
          e-svg-icon(class="mr-2") receipt-link
          | {{ $t('Copy link') }}
        e-link(v-if="actionButton" @click="dialogAction" :loading="loading")
          e-svg-icon(class="mr-2") receipt-download
          | {{ $t(actionText) }}
        e-link(v-if="printButton" @click="print" :loading="loading")
          e-svg-icon(class="mr-2") printer-3
          | {{$t('Print')}}
        e-link(v-if="posTerminalSettings" @click="posTerminalBtnClick('print')" :loading="printOnPosTerminalLoading")
          e-svg-icon(class="mr-2") printer-3
          | {{$t('Print on the Android terminal')}}
        e-link(v-if="posTerminalSettings" @click="posTerminalBtnClick('display')" :loading="displayOnPosTerminalLoading")
          e-svg-icon(class="mr-2") terminal-2
          | {{$t('Display on the Android terminal')}}
</template>

<script>
import html2pdf from 'html2pdf.js'
import TDialog from '~/components/templates/t-dialog'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import ELink from '~/components/elements/links/e-link'
import clipboard from '~/mixins/global/_clipboard'
import converters from '~/mixins/methods/converters'
import contentDialog from '~/mixins/dialogs/contentDialog'
import posTerminalProcesses from '~/modules/receipt/mixins/posTerminalProcesses'
import Receipts from '~/modules/receipt/models/Receipts'
import retailExpo from '~/modules/receipt/mixins/retailExpo'

export default {
  name: 'BlockReceiptTextView',
  components: {
    TDialog,
    ESvgIcon,
    ELink
  },
  mixins: [
    clipboard,
    converters,
    contentDialog,
    posTerminalProcesses,
    retailExpo
  ],
  props: {
    preview: {
      type: [Object, String],
      default: () => {}
    },
    action: {
      type: String,
      default: 'download',
      validate: value => ['print', 'download', 'close'].includes(value)
    },
    actionText: {
      type: String,
      default: 'Download'
    },
    isReceipt: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: 'file'
    },
    printButton: {
      type: Boolean,
      default: true
    },
    actionButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    posTerminalSettings () {
      return this._.get(this.preview, 'posTerminalSettings')
    }
  },
  methods: {
    posTerminalBtnClick (type) {
      const params = {
        posTerminalSettings: this.posTerminalSettings,
        payload: {
          receipt: this._.get(this.preview, 'receipt', null),
          qr_url: this._.get(this.preview, 'qrCodeUrl', null)
        }
      }
      if (type === 'display') {
        this.displayOnPosTerminal(params)
      } else if (type === 'print') {
        this.printOnPosTerminal(params)
      }
    },
    close () {
      const closeModal = this._.get(this.$attrs, 'closeModal')
      if (this._.isFunction(closeModal)) {
        closeModal()
      }
    },
    dialogAction () {
      switch (this.action) {
        case 'print' : {
          return this.print()
        }
        case 'download' : {
          return this.downloadReceipt()
        }
        case 'close' : {
          return this.close()
        }
      }
    },
    async print () {
      if (this.isReceipt) {
        if (this.isRetailExpoOrg) {
          await this.printHtml(this.preview?.receiptId)
        } else {
          await this.printImg()
        }
      } else {
        this.printText()
      }
    },
    printText () {
      const cw = this.$refs.receiptDoc.contentWindow
      cw.document.open()
      cw.document.write('<pre>')
      cw.document.write(this._.get(this.preview, 'receipt', null) || this.preview)
      cw.document.write('</pre>')

      if (this._.get(this.preview, 'qrCode', null)) {
        const wrapperWidth = this._.get(this.$Organization, 'receiptSetting.width', 32) * 8
        cw.document.write('<div style="width:' + wrapperWidth + 'px; text-align: center; margin: 0; padding: 0;">')
        cw.document.write(`<img src="${this.preview.qrCode}" style="width: 100%; max-width: 250px; display: block; margin: 0 auto;">`)
        cw.document.write('</div>')
      }

      cw.document.close()
      setTimeout(() => {
        cw.print()
      })
    },
    async printImg () {
      try {
        this.loading = true
        const res = this._.get(await Receipts.api().processingReadPng(this._.get(this.preview, 'receiptId', null)), 'response.data', '')
        const receiptUrl = await this.blobToBase64(res)
        const cw = this.$refs.receiptDoc.contentWindow
        const paperWidth = this._.get(this.$Organization, 'receiptSetting.paperWidth') || 58
        const imgWidth = (paperWidth * 3.78).toFixed(2) + 'px'
        cw.document.open()
        cw.document.write(`<img src="${receiptUrl}" alt="Receipt" style="width: ${imgWidth};">`)
        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No check view was found, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.loading = false
      }
    },
    async downloadReceipt () {
      try {
        const name = `${this.fileName}.pdf`
        this.loading = true

        await html2pdf().set({
          margin: 3,
          filename: name
        }).from(this.$refs.preview).save()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    copyToClipboard () {
      try {
        this.copy('https://check.checkbox.ua/' + this.preview.receiptId)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 15px 0 0;
  margin: 0 -15px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    margin: 0 -20px;
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    margin: 0 -32px;
  }

  &__content {
    overflow-y: auto;
    padding: 0 15px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 0 20px;
    }

    @media (min-width: map-get($breakpoints, 'md')) {
      padding: 0 32px;
    }
  }

  &__preview {
    height: 400px;
    width: 100%;
    max-width: 370px;
    margin: 0 auto;

    pre {
      display: flex;
      justify-content: center;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: justify;
    }
  }

  &__qr-code {
    display: block;
    margin: 0 auto;
  }

  &__actions {
    padding: 0 0 10px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 0 5px 10px;
    }

    @media (min-width: map-get($breakpoints, 'md')) {
      padding: 0 15px 10px;
    }
  }
}
</style>
