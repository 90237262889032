<template lang="pug">
  div
    p(class="mb-3") {{ $t('This means that you are not signing the application in the DPS with the main key of the director.') }}
    p(class="mb-1") {{ $t('To avoid this error, there are two options') }}:
    ol(class="list mb-3")
      li {{ $t('Refresh the browser page, and then re-sign the application with a valid director\'s key, if available. This may be your previous, older key that you used to report to the DPS.') }}
      li {{ $t('If the old key is not available, it is necessary to recall it by contacting the ATSSK in which you received the key and wait from 4 to 24 hours (in most cases, synchronization occurs after 00:00) and try to submit the application again, specifying the new key.') }}

    p(class="mb-1")
      span {{ $t('In order to revoke certificates, you need to use') }}{{ showInstructions ? ' ' : '...' }}
      e-link(
        href="https://wiki.checkbox.ua/uk/instructions/error/privat-bank-keys"
      ) {{ $t('instruction ') }}
      e-link(
        v-if="!showInstructions"
        class="ml-1"
        @click="showInstructions = true"
      ) {{ $t('Read more') }}
    div(v-if="showInstructions")
</template>

<script>
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'BlockPrivatBankFirstDocumentError',
  components: {
    ELink
  },
  data: () => ({
    showInstructions: true
  })
}
</script>

<style scoped lang="scss">
.list {
  li:not(:last-child) {
    margin-bottom: 7px;
  }
}
</style>
