<template lang="pug">
  div
    e-link(@click="show") {{$t('View')}}
</template>

<script>

import ELink from '~/components/elements/links/e-link'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  components: { ELink },
  mixins: [contentDialog],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  methods: {
    show () {
      this.contentDialog.open({
        component: 'm-form-billing-price-set',
        width: '800px',
        componentProps: {
          billingId: this.value
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
