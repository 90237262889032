<template lang="pug">
  div(class="dialog")
    h3(
      v-if="!isOnBoardingDialogPart"
      class="dialog__title"
    ) {{ $t('Application for the use of acquiring') }}
    div(
      v-if="docLoading"
      class="dialog__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    div(v-else)
      embed(
        :src="`data:application/pdf;base64,${base64Pdf}#toolbar=0&navpanes=0&scrollbar=0`"
        class="dialog__iframe"
      )
      div(
        class="dialog__actions mt-2 text-right"
        :class="{ 'pb-0': isOnBoardingDialogPart }"
      )
        v-btn(
          class="main-button dialog__action"
          :class="{ 'w-100': isResponsive }"
          @click="signAndSend"
          :loading="loading"
        ) {{ $t('Sign the application') }}
</template>

<script>
import Base64js from 'base64-js'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import AcquiringOrder from '~/modules/acquiring/models/AcquiringOrder'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  name: 'BlockABankAcquiringOrderPdf',
  components: {
    EProgressCircular
  },
  mixins: [edsIitCheckboxDialog],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    },
    acquiringOrderBase64Pdf: {
      type: String,
      default: ''
    },
    acquiringOrder: {
      type: Object,
      default: () => {}
    },
    item: {
      type: null,
      default: null
    },
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    changeStep: {
      type: Function,
      default: () => {}
    },
    changeAcquiringOrderInfo: {
      type: Function,
      default: () => {}
    },
    steps: {
      type: Object,
      default: () => {}
    },
    isOnBoardingDialogPart: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    docLoading: false,
    base64Pdf: null
  }),
  computed: {
    model () {
      return AcquiringOrder
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  created () {
    if (this.isOnBoardingDialogPart) {
      this.changeTitle(this.$t('Application for the use of acquiring'))
      this.toggleCloseBtn(false)
      this.$set(this.$data, 'base64Pdf', this.acquiringOrderBase64Pdf)
    } else if (this.item) {
      this.getDoc()
    }
  },
  methods: {
    async getDoc () {
      try {
        this.docLoading = true
        const id = this._.get(this.item, 'acquiringOrder.id')
        this.base64Pdf = this._.get(await this.model.api().getDoc(id), 'response.data.pdf')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.docLoading = false
      }
    },
    checkStatus (id) {
      const request = new IntervalRequest(() => this.model.api().read(id), {
        interval: 1e3,
        count: 300,
        maxDelay: 3e3 // ~3 seconds
      })
      return request.startExponential((response) => {
        const status = this._.get(response, 'response.data.status', null)
        return status === this.model.STATUSES.success || status === this.model.STATUSES.error
      }, () => {}, false)
    },
    async signAndSend () {
      this.loading = true
      await this.useEdsIitCheckboxDialog({
        fallbackMethod: this.signAndSend,
        checkAuthService: true,
        pluginTitles: {
          options: this.$t('Signing the application with a digital electronic key')
        },
        hints: {
          options: this.$t('signPlugin.request'),
          fileSign: this.$t('signPlugin.directorSign'),
          smartId: this.$t('signPlugin.smartId')
        },
        onConfirm: async ({ error, noReturn, sign }) => {
          try {
            if (error) {
              return !noReturn ? this.signAndSend() : null
            }

            const pdf = Base64js.toByteArray(this.base64Pdf)
            const signedData = await sign(pdf, {
              fallbackMethod: this.signAndSend
            })

            if (!signedData) {
              return null
            }

            const id = this.isOnBoardingDialogPart ? this._.get(this.acquiringOrder, 'id') : this._.get(this.item, 'acquiringOrder.id')

            await this.model.api().sendSigned(id, {
              pdf: signedData
            })

            const status = this._.get(await this.checkStatus(id), 'response.data.status', null)
            await this.tableRequest()
            const messages = {
              error: 'An error occurred while applying for acquiring',
              success: 'The application for the use of acquiring has been successfully approved by the bank',
              sent: 'The application for the use of acquiring has been successfully signed and sent'
            }

            if (this.isOnBoardingDialogPart) {
              this.changeAcquiringOrderInfo({
                error: status === this.model.STATUSES.error,
                text: messages[status] || messages.sent
              })
              this.$gtm.push({
                event: 'success_sign_a_bank_acquiring_order_pdf',
                organization: this._.get(this.$Organization, 'owner.email')
              })
              this.changeStep(this.steps.acquiringOrderInfo)
            } else {
              if (status === this.model.STATUSES.error) {
                this.$handlers.error(this.$t(messages[status]), this)
              } else {
                this.$gtm.push({
                  event: 'success_sign_a_bank_acquiring_order_pdf',
                  organization: this._.get(this.$Organization, 'owner.email')
                })
                this.$notification.success(this.$t(messages[status] || messages.sent))
              }

              this.closeModal()
            }
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
      })
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
  }

  &__iframe {
    border: 2px solid #F0F1F2;
    width: 100%;
    height: 600px;
  }
}
</style>
