<template lang="pug">
  div(class="block-tips-employee-modal")
    div(class="dialog__title mb-4") {{ $t('Select an employee to tip') }}
    v-form
      t-orm-fields(v-bind.sync="form" :items="fields")
    div(class="block-tips-employee-modal__actions")
      v-btn(
        class="main-button"
        @click="confirm"
        :loading="loading"
        :disabled="!form.selectedTipsEmployee"
      ) {{ $t('Continue') }}
      v-btn(
        class="secondary-button"
        @click="skip"
      ) {{ $t('Skip') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import AcquiringSettings from '~/modules/acquiring/models/AcquiringSettings'
export default {
  name: 'BlockTipsEmployeeModal',
  components: {
    TOrmFields
  },
  props: {
    closeModal: {
      type: Function,
      required: true
    },
    cashRegisterId: {
      type: String,
      required: true
    },
    useQr: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      form: {
        selectedTipsEmployee: null
      },
      employees: [],
      acquiringId: null
    }
  },
  computed: {
    fields () {
      return [
        {
          model: 'selectedTipsEmployee',
          component: 'v-select',
          provider: {
            vid: 'selectedTipsEmployee',
            name: 'Select an employees',
            rules: 'required'
          },
          attrs: {
            label: 'Select an employees',
            outlined: true,
            itemText: 'name',
            itemValue: 'id'
          },
          items: () => this.employees
        }
      ]
    }
  },
  mounted () {
    this.getTipsEmployees()
  },
  methods: {
    getAcquiringSettingId () {
      const cashRegister = CashRegistersSearch.query().whereId(this.cashRegisterId).first()
      if (cashRegister && cashRegister.acquiringSettings) {
        const acquiringSetting = cashRegister.acquiringSettings.find(setting =>
          this.useQr ? setting.type === 'qr' : setting.type === 'internet'
        )
        if (acquiringSetting) {
          this.acquiringId = this._.get(acquiringSetting, 'id')
        }
      }
    },
    async getTipsEmployees () {
      this.loading = true
      this.getAcquiringSettingId()
      try {
        const response = await AcquiringSettings.api().employeeList(this.acquiringId)
        this.employees = response.response.data.list
      } catch (error) {
        this.employees = []
        this.$handlers.error(error, this)
      } finally {
        this.loading = false
      }
    },
    confirm () {
      this.closeModal(this.form.selectedTipsEmployee)
    },
    skip () {
      this.closeModal(null)
    }
  }
}
</script>

<style scoped lang="scss">
.block-tips-employee-modal {
  &__actions {
    justify-content: center;
    gap: 16px;
    display: flex;
    margin-top: 20px;
  }
}
</style>
