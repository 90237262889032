<template lang="pug">
  div(class="m-dialog-goods-scanner")
    div(
      v-if="noGoodsFound"
      class="text-center"
    )
      p {{ $t('No product found with this barcode') }}
      v-btn(
        class="main-button mt-3"
        @click="scanAgain"
      ) {{ $t('Scan again') }}
      e-link(
        class="m-dialog-goods-scanner__link"
        @click="addGoods"
      ) {{ $t('Add goods') }}
    m-block-barcode-scanner(
      @scan.once="onBarcodeScan"
      class="mb-6"
      v-else
    )
</template>

<script>
import MBlockBarcodeScanner from '~/components/modules/blocks/concrete/m-block-barcode-scanner'
import ELink from '~/components/elements/links/e-link'
import barcodeScan from '~/modules/goods/mixins/barcodeScan'

export default {
  components: {
    ELink,
    MBlockBarcodeScanner
  },
  mixins: [barcodeScan],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    useLeftovers: {
      type: Boolean,
      default: true
    },
    useBranches: {
      type: Boolean,
      default: true
    },
    outletId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    noGoodsFound: false
  }),
  methods: {
    addGoods () {
      this.closeModal()
      this.$router.push({
        path: '/dashboard/goods',
        hash: '#createDialogOpen'
      })
    },
    scanAgain () {
      this.noGoodsFound = false
    },
    async onBarcodeScan (barcode) {
      try {
        this.noGoodsFound = false
        if (!this._.replace(barcode, /\s/g, '')) {
          this.noGoodsFound = true
          return
        }

        const goods = await this.onBarcodeScanRequest({
          barcode,
          useLeftovers: this.useLeftovers,
          useBranches: this.useBranches,
          outletId: this.outletId
        })

        if (goods.length) {
          this.closeModal(goods)
        } else {
          this.noGoodsFound = true
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.m-dialog-goods-scanner {
  &__link {
    font-size: .75rem !important;
    margin: 10px auto 30px;
    display: block;
  }
}
</style>
