<template lang="pug">
  div(v-if="item")
    span() {{ item.name }}
    v-tooltip(top v-if="item.promoCodeOrganizations && item.promoCodeOrganizations.length")
      template(#activator="{on}")
        v-btn(icon v-on="on")
          e-svg-icon(size="sm")
            | {{ iconName }}
      | {{ $t(tooltipText) }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: { ESvgIcon },
  props: {
    value: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    iconName () {
      return 'voucher'
    },
    tooltipText () {
      return 'This organization has a promotional code'
    }
  }
}
</script>

<style scoped>

</style>
