import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class AccountOrder extends PersistAble(OrmModel) {
  static entity = 'accountorder'
  static paginated = true
  static defaultSortOrder = true

  static STATUSES = {
    new: 'new',
    created: 'created',
    signaturePending: 'signature_pending',
    signed: 'signed',
    onApproval: 'on_approval',
    success: 'success',
    error: 'error'
  }

  static fields () {
    return {
      id: this.attr(null),
      callbackRef: this.attr(null),
      documentUrl: this.attr(null),
      requestId: this.attr(null),
      status: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getDoc = function (id) {
        return this.get(Model.$routes.accountorder.getDoc(id), {
          save: false
        })
      }
      configActions.sendSigned = function (id, payload) {
        return this.post(Model.$routes.accountorder.sendSigned(id), payload, {
          save: false
        })
      }
      return configActions
    }
  }
}

export default AccountOrder
