<template lang="pug">
  div(class="filters")
    div(class="filters__content")
      div(
        v-for="item in filters"
        :key="item.key"
        v-if="item.visible"
        :class="{ 'filters__item': true, 'filters__item--closed': !openFilters[item.key] }"
      )
        div(
          class="filters__item-header"
          @click="switchItem(item.key)"
        )
          p(class="filters__item-title") {{ item.title }}
          div(
            v-if="selectedFilters[item.key].length"
            class="filters__counter"
          ) {{ selectedFilters[item.key].length }}
          e-svg-icon(class="filters__arrow") chevron-down
        input-multiple-select(
          v-show="openFilters[item.key]"
          v-bind="item.attrs"
          @change="onChange(item.key, $event)"
          :selected-items="selectedFilters[item.key]"
        )
    div(class="filters__actions")
      v-btn(
        v-if="filtersSelected"
        class="secondary-button color-error"
        @click="clearFilters"
      ) {{ $t('Clear filter') }}
      v-btn(
        class="main-button"
        :disabled="!filtersSelected"
        @click="applyFilters"
      ) {{ $t('Apply') }}
</template>

<script>
import InputMultipleSelect from '~/modules/dashboard/views/components/input-multiple-select'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import Outlets from '~/models/directories/Outlets'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import CashRegisters from '~/models/directories/CashRegisters'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
import Employees from '~/modules/employees/models/Employees'

export default {
  name: 'BlockEntitiesFilter',
  components: {
    ESvgIcon,
    InputMultipleSelect
  },
  props: {
    selectedItems: {
      type: Object,
      default: () => {}
    },
    onApply: {
      type: Function,
      default: () => {}
    },
    showOutlets: {
      type: Boolean,
      default: true
    },
    showCashRegisters: {
      type: Boolean,
      default: true
    },
    showEmployees: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selectedFilters: {
      outlets: [],
      cashRegisters: [],
      employees: []
    },
    openFilters: {
      outlets: true,
      cashRegisters: false,
      employees: false
    }
  }),
  computed: {
    filtersSelected () {
      return this._.get(this.selectedFilters, 'outlets', []).length ||
        this._.get(this.selectedFilters, 'cashRegisters', []).length ||
        this._.get(this.selectedFilters, 'employees', []).length ||
        this._.get(this.selectedItems, 'outlets', []).length ||
        this._.get(this.selectedItems, 'cashRegisters', []).length ||
        this._.get(this.selectedItems, 'employees', []).length
    },
    filters () {
      return [
        {
          key: 'outlets',
          title: this.$t('Outlets'),
          attrs: {
            model: OutletsSearch,
            queryFilter: {
              mode: Outlets.itemModes.checkbox.mode
            }
          },
          visible: this.showOutlets
        },
        {
          key: 'cashRegisters',
          title: this.$t('Cash registers'),
          attrs: {
            model: CashRegistersSearch,
            queryFilter: {
              mode: CashRegisters.itemModes.checkbox.mode
            }
          },
          visible: this.showCashRegisters
        },
        {
          key: 'employees',
          title: this.$t('Employees'),
          attrs: {
            model: EmployeesSearch,
            queryFilter: {
              mode: Employees.itemModes.checkbox.mode
            }
          },
          visible: this.showEmployees
        }
      ]
    }
  },
  created () {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...this.selectedItems
    }
  },
  methods: {
    switchItem (itemKey) {
      for (const key in this.openFilters) {
        this.openFilters[key] = key === itemKey ? !this.openFilters[key] : false
      }
    },
    onChange (key, data) {
      this.selectedFilters[key] = data
    },
    async clearFilters () {
      this.selectedFilters = {
        outlets: [],
        cashRegisters: [],
        employees: []
      }
      await this.applyFilters()
    },
    async applyFilters () {
      if (this._.isFunction(this.onApply)) {
        await this.onApply(this.selectedFilters)
      }
      this.$emit('apply', this.selectedFilters)
      this.$emit('closeSidebar')
    }
  }
}
</script>

<style scoped lang="scss">
.filters {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    gap: 16px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__item-header {
    display: flex;
    margin-bottom: 16px;
    padding: 4px 0;
    cursor: pointer;
    align-items: center;
    min-height: 32px;
    gap: 8px;
  }

  &__item-title {
    flex-grow: 1;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
  }

  &__item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 20px;

    &--closed {
      padding-bottom: 15px;

      &:last-child {
        border-bottom: none;
      }

      .filters {
        &__item-header {
          margin-bottom: 0;
        }

        &__arrow {
          transform: none;
          opacity: .5;
        }
      }
    }
  }

  &__arrow {
    transform: rotate(180deg);
    transition: .3s;
  }

  &__counter {
    background: rgba(4, 174, 212, 0.08);
    color: #04AED5;
    font-size: 12px;
    padding: 4px 9px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
}
</style>
