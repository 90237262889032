import contentDialog from '~/mixins/dialogs/contentDialog'
import signPluginService from '~/modules/eds-iit-checkbox/mixins/signPluginService'
import Organization from '~/models/directories/Organization'

const promoCashRegisters = {
  mixins: [contentDialog, signPluginService],
  data: () => ({
    syncLoading: false,
    pageLoading: false,
    isV2PromotionDone: false
  }),
  computed: {
    hasNoOrganization () {
      return this._.get(this.$Organization, 'isDraft', false) && !this._.get(this.$User, 'isSuperAdmin')
    }
  },
  methods: {
    sendGtmEvent (type) {
      this.$gtm.push({
        event: 'action_rro',
        organization_name: this._.get(this.$Organization, 'name'),
        email: this._.get(this.$Organization, 'owner.email'),
        actionflow: type
      })
    },
    async checkIfV2PromotionDone () {
      if (this.hasNoOrganization || this.isV2Promotion()) {
        try {
          this.pageLoading = true
          await Organization.api().getClientRroPromotionDetails()
          this.isV2PromotionDone = true
        } catch (e) {
          this.isV2PromotionDone = false
          this.$handlers.error(e, this)
        } finally {
          this.pageLoading = false
        }
      }
    },
    isV2Promotion (organization) {
      // const org = organization || this.$Organization
      // const edrpou = this._.get(org, 'edrpou') || this._.get(org, 'taxNumber')
      // if (edrpou) {
      //   const lastChar = String(edrpou).slice(-1)
      //   return parseInt(lastChar) % 2 === 0
      // }
      return false
    },
    promoCashRegistersDone (set) {
      if (set) {
        this.$setLocalStorageClientData({ promoCashRegistersDone: true })
      } else {
        return this._.get(this.$localStorageClientData(), 'promoCashRegistersDone')
      }
    },
    async openPromoFormDialog (onSuccess) {
      await this.contentDialog.open({
        component: 'block-promotion-cash-registers',
        width: '1100px',
        componentProps: {
          onSuccess
        }
      })
    },
    async openPromoDialog () {
      await this.contentDialog.open({
        component: 'block-promotion-dialog',
        width: '560px'
      })
    },
    async openPromoForm ({ onSuccess } = {}) {
      if (!await this.$checkOrganization()) {
        return
      }
      if (!this._.get(this.$Organization, 'synchronized')) {
        try {
          this.loading = true
          const afterSyncData = await this.synchronizeOrganization(this.$Organization)

          if (afterSyncData) {
            this.$notification.success(this.$t('Organization successfully synchronized', {
              outletsDeactivated: this._.get(afterSyncData, 'data.outlets.deactivated', {}),
              outletsNew: this._.get(afterSyncData, 'data.outlets.new', {}),
              outletsSync: this._.get(afterSyncData, 'data.outlets.sync', {}),
              cashRegisters: this._.get(afterSyncData, 'data.cashRegisters.sync', ''),
              employees: this._.get(afterSyncData, 'data.employees.sync', '')
            }))
            await this.openPromoFormDialog(onSuccess)
          }
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      } else {
        await this.openPromoFormDialog(onSuccess)
      }
    }
  }
}

export default promoCashRegisters
