<template lang="pug">
  t-dialog(
    v-bind="$attrs"
    v-on="listeners"
    :max-width="cardWidth"
    :loading="isLoading"
  )
    template(#title) {{ $t(title) }}
    template(#content)
      div(
        v-if="isLoading"
        class="preload"
      )
      form-tax-rates(
        v-else-if="formIsVisible"
        :context="context"
        :selected-item="editedItem"
        :item="item"
        @dialog:close="close"
        :createdTaxRates="createdTaxRates"
        is-dialog-part
      )
      form-tax-rates-groups(
        @showForm="showForm = true"
        @dialog:close="close"
        :createdTaxRates="createdTaxRates"
        v-else
      )
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import fill from '~/mixins/modules/dialogs/fill'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import FormTaxRates from '~/modules/tax-rates/views/components/form-tax-rates'
import FormTaxRatesGroups from '~/modules/tax-rates/views/components/form-tax-rates-groups'

export default {
  components: {
    FormTaxRates,
    TDialog,
    FormTaxRatesGroups
  },
  mixins: [checkPropCtx, fill],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    itemLoading: false,
    editedItem: {},
    item: {},
    showForm: false,
    createdTaxRates: []
  }),
  computed: {
    isCreate () {
      return this.context === this.$config.contexts.create
    },
    formIsVisible () {
      return !this.isCreate || this.showForm
    },
    dialogCtx () {
      return Object.assign({}, {
        parent: this.selectedItem,
        dialogRefs: this.$refs,
        model: this.model.entity
      }, { ...this.ctx })
    },
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    cardWidth () {
      return [710, 'px'].join('')
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    title () {
      if (this.formIsVisible) {
        return this.isCreate ? 'Create a tax rate' : 'Editing the tax rate'
      }
      return 'Add a tax group'
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    clear () {
      setTimeout(() => {
        this.showForm = false
        this.parentContext = this.$config.contexts.create
        this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
      }, 300)
    },
    close () {
      this.$emit('input', false)
      this.clear()
    },
    async afterFill (context, item) {
      if (!this.$isCreateCtx(context)) {
        try {
          this.itemLoading = true
          const itemId = this._.get(item, 'id')
          await this.model.api().read(itemId)
          this.item = this.model.query().withAll().whereId(itemId).first() || {}
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.itemLoading = false
        }
      }
      this.createdTaxRates = this.model.query().all()
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ from: item, ctx: this.context }))
    }
  }
}
</script>

<style scoped lang="scss">
.preload {
  height: 400px;
}
</style>
