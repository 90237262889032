<template lang="pug">
  div(class="a-bank-information-check")
    div(class="a-bank-information-check__banner")
      p(class="a-bank-information-check__banner-icon") {{ icon }}
      h3(class="a-bank-information-check__banner-title") {{ $t(title) }}
      div(v-if="!error" class="a-bank-information-check__banner-progress-wrapper")
        v-progress-linear(
          class="a-bank-information-check__banner-progress"
          indeterminate
        )
    div(v-if="error" class="a-bank-information-check__actions")
      v-btn(
        class="a-bank-information-check__action"
        :class="{ 'mr-4': !isResponsive, 'mb-3 w-100': isResponsive }"
        outlined
        color="#161B25"
        @click="changeStep(previousStep)"
      ) {{ $t('Come back') }}
      v-btn(
        class="secondary-button a-bank-information-check__action"
        :class="{ 'w-100': isResponsive }"
        dark
        @click="checkInformation"
      ) {{ $t('Repeat') }}
</template>

<script>
export default {
  name: 'BlockABankInformationCheck',
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    changeStep: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    steps: {
      type: Object,
      default: () => {}
    },
    model: {
      type: null,
      required: true
    },
    previousStep: {
      type: String,
      default: null
    }
  },
  data: () => ({
    error: false
  }),
  computed: {
    icon () {
      if (this.error) {
        return '😔'
      }
      return '🕵'
    },
    title () {
      if (this.error) {
        return 'We could not get confirmation from the bank about the availability of the card'
      }
      return 'Checking the information'
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  created () {
    this.changeTitle('Install mobile app and go through registration')
    this.toggleCloseBtn(false)
    this.checkInformation()
  },
  methods: {
    showError () {
      this.error = true
      this.toggleCloseBtn(true)
    },
    async checkInformation () {
      try {
        this.error = false
        this.toggleCloseBtn(false)

        const clientStatus = this._.get(await this.model.api().getClientStatus(), 'response.data.clientType')

        if (clientStatus === this.model.CLIENT_STATUSES.notClient || clientStatus === this.model.CLIENT_STATUSES.redPhysClient) {
          this.showError()
        } else {
          this.changeStep(this.steps.accountOpening)
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.showError()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-information-check {
  text-align: center;

  &__banner {
    border-radius: 12px;
    background: #F7F7F7;
    padding: 45px 0;

    &-icon {
      font-size: 48px;
      margin-bottom: 40px;
      color: #000;

      @media (max-width: map-get($breakpoints, 'sm')) {
        font-size: 40px;
        margin-bottom: 30px;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin: 0 auto;
      color: #000;
      max-width: 400px;

      @media (max-width: map-get($breakpoints, 'sm')) {
        font-size: 18px;
      }
    }

    &-progress {
      margin-top: 40px;

      @media (max-width: map-get($breakpoints, 'sm')) {
        margin-top: 30px;
      }

      &-wrapper {
        max-width: 400px;
        margin: 0 auto;

        @media (max-width: map-get($breakpoints, 'sm')) {
          max-width: 240px;
        }
      }
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-direction: column;
      margin-top: 16px;
    }
  }
}
</style>
