<template lang="pug">
  div(
    class="barcode-scanner"
    :class="{ 'text-center pt-3 pb-9': !hidden }"
  )
    e-progress-circular(
      v-if="loading && !hidden"
      width="4"
      size="xl"
      color="#161b25"
    )
    input(
      v-model="barcode"
      ref="input"
      class="barcode-scanner__input"
    )
</template>

<script>
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import barcodeScan from '~/modules/goods/mixins/barcodeScan'

export default {
  components: {
    EProgressCircular,
    EOverlayBlock
  },
  mixins: [barcodeScan],
  props: {
    focusOnMounted: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    keyDownEvents: {
      type: Boolean,
      default: false
    },
    onScan: {
      type: Function,
      default: null
    },
    useLeftovers: {
      type: Boolean,
      default: true
    },
    useBranches: {
      type: Boolean,
      default: true
    },
    outletId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    barcode: null,
    loading: true,
    keyWasPressed: false
  }),
  watch: {
    barcode () {
      if (!this.keyDownEvents) {
        setTimeout(async () => {
          if (this.onScan && typeof this.onScan === 'function') {
            await this.onScan(this.barcode)
          } else {
            this.$emit('scan', this.barcode)
          }
        }, 300)
      }
    }
  },
  mounted () {
    if (this.focusOnMounted) {
      this.focusInput()
      document.addEventListener('click', this.focusInput)
    }

    if (this.keyDownEvents) {
      document.addEventListener('keydown', this.onKeyDown)
    }
  },
  beforeDestroy () {
    if (this.focusOnMounted) {
      document.removeEventListener('click', this.focusInput)
    }

    if (this.keyDownEvents) {
      document.removeEventListener('keydown', this.onKeyDown)
    }
  },
  methods: {
    async handleGoodsScan (barcode) {
      try {
        if (!this._.replace(barcode, /\s/g, '')) {
          this.$notification.info(this.$t('No product found with this barcode'))
          return
        }

        const goods = await this.onBarcodeScanRequest({
          barcode,
          useLeftovers: this.useLeftovers,
          useBranches: this.useBranches,
          outletId: this.outletId
        })

        if (goods?.length) {
          this.$emit('scanGoods', goods)
        } else {
          this.$notification.info(this.$t('No product found with this barcode'))
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    onKeyDown (e) {
      // F7 key press
      if (e?.keyCode === 118) {
        this.keyWasPressed = true
        this.focusInput()
      } else if (e?.keyCode === 13 && this.barcode && this.keyWasPressed) {
        this.keyWasPressed = false
        this.handleGoodsScan(this.barcode)
        this.barcode = null
      }
    },
    focusInput () {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.barcode-scanner {
  &__input {
    margin: 0;
    padding: 0;
    height: 0;
    width: 0;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    display: block;
  }
}
</style>
