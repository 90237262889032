<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ title }}
    v-tabs(
      v-model="tab"
    )
      v-tab(
        v-for="(item, index) in tabItems"
        :key="index"
        v-if="item.visible"
      ) {{ $t(item.label) }}
    v-tabs-items(
      v-model="tab"
      :show-arrows="false"
      class="pb-8"
    )
      v-tab-item(
        v-for="(item, index) in tabItems"
        :key="index"
        v-if="item.visible"
      )
        component(
          :is="item.component"
          v-bind="item.props"
        )
</template>

<script>
import BlockMallReportApiUrlSettings from '~/modules/reports/views/components/mall-report/block-mall-report-api-url-settings'
import BlockMallReportEmailSettings from '~/modules/reports/views/components/mall-report/block-mall-report-email-settings'
import BlockMallReportFtpSettings from '~/modules/reports/views/components/mall-report/block-mall-report-ftp-settings'

export default {
  name: 'BlockMallReportSettings',
  components: {
    BlockMallReportApiUrlSettings,
    BlockMallReportEmailSettings,
    BlockMallReportFtpSettings
  },
  props: {
    sendFile: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    tab: 0
  }),
  computed: {
    title () {
      if (this.sendFile) {
        return this.$t('Send report ')
      }
      return this.$t('Report settings')
    },
    tabItems () {
      return [
        {
          label: 'API URL',
          component: 'block-mall-report-api-url-settings',
          visible: !this.sendFile,
          props: {
            item: this._.get(this.item, 'mallReportApiSetting')
          }
        },
        {
          label: 'E-mail',
          component: 'block-mall-report-email-settings',
          visible: true,
          props: {
            item: this.item,
            sendFile: this.sendFile,
            closeModal: this.closeModal
          }
        },
        {
          label: 'FTP',
          component: 'block-mall-report-ftp-settings',
          visible: true,
          props: {
            item: this.item,
            sendFile: this.sendFile,
            closeModal: this.closeModal
          }
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

</style>
