<template lang="pug">
  div(class="dialog")
    div(class="dialog__title pb-0 mb-8") {{ $t('Refund confirmation') }}
    div(
      v-if="loading"
      class="dialog__loader"
    )
      e-progress-circular(
        width="4"
        size="xl"
        color="#161b25"
      )
    div(v-else-if="pdf")
      embed(
        :src="`data:application/pdf;base64,${pdf}#toolbar=0&navpanes=0&scrollbar=0`"
        class="dialog__pdf"
      )
      div(class="dialog__actions")
        v-btn(
          class="main-button mx-auto d-block mt-3"
          :loading="saveLoading"
          @click="signAndSend"
        ) {{ $t('Sign and send') }}
    v-form(
      @submit.prevent.stop="save"
      v-else
    )
      ValidationObserver(
        ref="form"
        slim
      )
        t-orm-fields(
          v-bind.sync="data"
          :items="fields"
          class="mt-3"
        )
      div(class="dialog__actions")
        v-btn(
          class="main-button mx-auto d-block"
          :loading="saveLoading"
          type="submit"
        ) {{ $t('Confirm') }}
</template>

<script>
import Base64js from 'base64-js'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Refunds from '~/modules/refunds/models/Refunds'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'

export default {
  name: 'BlockConfirmRefundRequestForm',
  components: {
    TOrmFields,
    EProgressCircular
  },
  mixins: [edsIitCheckboxDialog],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    changeDialogWidth: {
      type: Function,
      default: () => {}
    },
    onSend: {
      type: Function,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    saveLoading: false,
    data: {
      bankAccount: null,
      recipientFullName: null,
      recipientEdrpou: null
    },
    pdf: null
  }),
  computed: {
    model () {
      return Refunds
    },
    fields () {
      return [
        {
          model: 'bankAccount',
          component: 'v-text-field',
          cast: val => val ? val.trim() : val,
          provider: {
            vid: 'bankAccount',
            name: 'Bank account',
            rules: 'required|IBAN|noSpaces'
          },
          attrs: {
            outlined: true,
            label: 'Bank account'
          }
        },
        {
          model: 'recipientFullName',
          component: 'v-text-field',
          provider: {
            vid: 'recipientFullName',
            name: 'Recipient full name',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Recipient full name',
            disabled: Boolean(this._.get(this.$Organization, 'chiefName', ''))
          },
          fieldVal: () => this._.get(this.$Organization, 'chiefName', '')
        },
        {
          model: 'recipientEdrpou',
          component: 'v-text-field',
          provider: {
            vid: 'recipientEdrpou',
            name: 'Recipient edrpou',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Recipient edrpou',
            disabled: Boolean(this._.get(this.$Organization, 'edrpou', ''))
          },
          fieldVal: () => this._.get(this.$Organization, 'edrpou', '')
        }
      ]
    },
    isSignaturePending () {
      return this._.get(this.item, 'requestStatus') === this.model.STATUSES.signaturePending
    }
  },
  async created () {
    if (this.isSignaturePending) {
      await this.getPdfOnOpen()
    }
  },
  methods: {
    async getPdfOnOpen () {
      try {
        this.loading = true
        await this.getPdf()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async getPdf () {
      this.pdf = this._.get(await this.model.api().getFile(this._.get(this.item, 'id')), 'response.data.pdf')
      this.changeDialogWidth('800px')
    },
    async save () {
      try {
        this.saveLoading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        const itemId = this._.get(this.item, 'id')
        await this.model.api().update({
          ...this.item,
          $id: itemId
        }, this.data)
        await this.getPdf()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.saveLoading = false
      }
    },
    async signAndSend () {
      this.saveLoading = true
      await this.useEdsIitCheckboxDialog({
        fallbackMethod: this.signAndSend,
        pluginTitles: {
          options: this.$t('Signing the application with a digital electronic key')
        },
        hints: {
          options: this.$t('signPlugin.request'),
          fileSign: this.$t('signPlugin.directorSign'),
          smartId: this.$t('signPlugin.smartId')
        },
        onConfirm: async ({ error, noReturn, sign }) => {
          try {
            if (error) {
              return !noReturn ? this.signAndSend() : null
            }

            const pdf = Base64js.toByteArray(this.pdf)
            const signedData = await sign(pdf, {
              fallbackMethod: this.signAndSend
            })

            if (!signedData) {
              this.error = true
              return null
            }

            await this.model.api().sendFile(this._.get(this.item, 'id'), {
              pdf: signedData
            })
            this.$notification.success(this.$t('Refund application successfully signed and sent'))
            this.onSend()
            this.closeModal()
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
      })
      this.saveLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__pdf {
    border: 2px solid #F0F1F2;
    width: 100%;
    height: 600px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
  }
}
</style>
