<template lang="pug">
  v-row
    v-col(cols="12" class="py-0")
      e-input-wrapper(v-if="isVisible"
        :item="config.multyCheckbox"
        :checked="chkAllState"
        @input="chkAll") {{ $t('Select all') }}

      v-divider(v-if="isVisible")

      slot(v-bind="scopedAttrs")

    e-progress-linear(v-show="loading" size="sm" absolute class="m-group-renderer__loader")
</template>

<script>
import DOMPurify from 'dompurify'
import EProgressLinear from '~/components/elements/progress/e-progress-linear'
import EInputWrapper from '~/components/elements/inputs/e-input-wrapper'

export default {
  components: {
    EProgressLinear,
    EInputWrapper
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      default: 'dynamic',
      validator: (value) => {
        return ['dynamic', 'static'].includes(value)
      }
    }
  },
  data: () => ({
    items: [],
    reactive: {},
    loading: false
  }),
  computed: {
    isVisible () {
      return this.scopedAttrs.items.length
    },
    counter () {
      return this.config.counter || 1
    },
    scopedAttrs () {
      return {
        items: this.fields,
        on: this.listeners,
        reactive: this.reactive
      }
    },
    models () {
      return this.isCtx('dynamic') ? this.config.class.all() : null
    },
    fields () {
      let configs = []
      if (this.isCtx('dynamic')) {
        configs = this.models.map((item, index) => {
          const id = `field${index}`
          const n = item.name
          const model = id; const vid = id
          const label = n; const name = n

          return Object.assign({}, this.config.prototype, {
            model,
            provider: Object.assign({}, this.config.prototype.provider, {
              vid,
              name
            }),
            attrs: Object.assign({}, {
              label
            }),
            payload: item
          })
        })
      } else {
        for (let fieldNum = this.counter; fieldNum !== 0; fieldNum--) {
          const id = `field${fieldNum}`
          let n = `${this.item.label}`
          if (fieldNum !== this.counter) {
            n = this.$t('Additional', { type: DOMPurify.sanitize(this.$t(this.item.label).toLowerCase()) })
          }
          const model = id; const vid = id
          const label = n; const name = n

          configs.push(Object.assign({}, this.config.prototype, {
            model,
            provider: Object.assign({}, this.config.prototype.provider, {
              vid,
              name
            }),
            attrs: Object.assign({}, this.config.prototype.attrs, {
              label
            }),
            payload: model
          }))
        }
      }
      if (configs.length) {
        this.$set(this.$data, 'reactive', this.config.class.generateOrmFieldsObject(
          configs,
          this.isCtx('dynamic') ? null : this.value
        ))
      }

      return configs
    },
    listeners () {
      return Object.assign({},
        this.$listeners,
        {
          input: (data, field) => {
            if (this.isCtx('dynamic')) {
              if (this._.isBoolean(data)) {
                this.items = this._.xor(this.items, [field.payload])
              }
            } else {
              this.reactive[field.payload] = data
              this.$emit('input', this._.values(this.reactive))
            }
          }
        }
      )
    },
    chkAllState () {
      return Object.values(this.reactive).every(field => field)
    }
  },
  watch: {
    items (cur) {
      if (this.isCtx('dynamic')) {
        if (cur.length) {
          for (const key in this.reactive) {
            this.reactive[key] = false
          }
          for (const item of cur) {
            const config = this.fields.find(field => item[this.config.class.primaryKey] === field.payload[this.config.class.primaryKey])
            this.reactive[config.model] = true
          }
        }
        this.$emit('input', cur)
      }
    }
  },
  async created () {
    if (this._.isFunction(this.item.request)) {
      this.loading = true
      await this.item.request()
      this.loading = false
    }

    if (this.isCtx('dynamic')) {
      if (this.value && this.value.length) {
        this.items = this._.intersectionBy(this.models, this.value, this.config.class.primaryKey)
      }
    }
  },
  methods: {
    isCtx (ctx) {
      return this.context === ctx
    },
    chkAll (value) {
      const items = []
      const reactive = Object.assign({}, this.reactive)
      if (this._.isBoolean(value)) {
        if (value) {
          this.scopedAttrs.items.map((item) => {
            items.push(item.payload)
          })
        }
        this.items = items
      }
      this.reactive = this._.mapValues(reactive, () => value)
    }
  }
}
</script>

<style lang="scss">
  .m-group-renderer {
    &__loader {
      bottom: -1px;
    }
  }
</style>
