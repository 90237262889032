import { intersection } from 'lodash/array'
import AdTag from '~/modules/advertising/models/AdTag'

export default class AdService {
  static adTypeKey = 'utm_source'

  static tagTypeMap = {
    [AdTag.admitadType]: 'admitad_uid'
  }

  static higherTags = ['gclid', 'partner_ref', 'registration_code']

  static async setTags (queryParams = {}) {
    const type = queryParams[AdService.adTypeKey]
    const tag = type ? queryParams[AdService.tagTypeMap[type]] : null
    if (type && tag) {
      await AdService.setTag(type, tag)
    }
  }

  static setTag (type, tag) {
    const expired = Date.now() + AdTag.expiredAfter
    return AdTag.create({
      data: {
        tag,
        tagType: type,
        expiredAt: expired
      }
    })
  }

  static removeExpired () {
    return AdTag.delete((item) => {
      return item.expiredAt < Date.now()
    })
  }

  static async markExpired (queryParams = {}) {
    if (intersection(AdService.higherTags, Object.keys(queryParams)).length || (queryParams[AdService.adTypeKey] && (queryParams[AdService.adTypeKey] !== AdTag.admitadType))) {
      await AdTag.update({
        where: AdTag.admitadType,
        data: {
          expiredAt: 0
        }
      })
    }
    return true
  }
}
