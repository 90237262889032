<template lang="pug">
  div(class="monobank-qr__wrapper")
    div(v-if="loader" class="py-5 d-flex justify-center")
      e-progress-circular(size="xxl" width="4")
    div(@click="goToLink(qrUrl)" v-else)
      qrcode-vue(v-if="qrUrl" :value="qrUrl" size="319")
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Acquiring from '~/modules/acquiring/models/Acquiring'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  components: { QrcodeVue, EProgressCircular },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    qrUrl: null,
    loader: true,
    authReqId: null,
    terminals: null,
    intervalRequest: null,
    loading: false
  }),
  created () {
    this.apiCall()
  },
  beforeDestroy () {
    if (this.intervalRequest instanceof IntervalRequest) {
      this.intervalRequest.stopExponential()
    }
  },
  methods: {
    goToLink (link) {
      window.open(link, '_blank')
    },
    async apiCall () {
      try {
        this.loader = true
        this.intervalRequest = new IntervalRequest(() => Acquiring.api().getMonobankTapToPhoneTerminals(this.authReqId), {
          count: 300,
          interval: 2e3,
          exponential: 1,
          maxDelay: 6e5 // ~10 minutes
        })

        const res = this._.get(await this.intervalRequest.startExponential((res) => {
          const status = this._.get(res, 'response.status', null)

          if (status === 201) {
            this.qrUrl = this._.get(res, 'response.data.acceptUrl')
            this.authReqId = this._.get(res, 'response.data.id')
            this.loader = false
          }

          return [200, 400, 500].includes(status)
        }), 'response', null)

        this.loader = false
        const data = this._.get(res, 'data')
        this.terminals = this._.get(data, 'terminals')

        if (this.terminals && !this.terminals.length) {
          this.closeModal({
            terminals: this.terminals,
            success: false
          })
          return
        }

        this.$emit('getMonobankQrData', data)
        this.$emit('showNextStep', true)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.error-text {
  color: $color-error;
}
</style>
