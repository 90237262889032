<template lang="pug">
  t-dialog(v-bind="$attrs" type="card" v-on="$listeners" max-width="1170px" content-class="m-orm-card-dialog" :content-without-padding="xsDevice")
    template(#title)
      span(class="text-center d-inline-block") {{ $t(title) }}

    template(#content)
      v-treeview(
        :items="rootItems",
        :active="active"
        @update:active="updateActive"
        :item-key="model.primaryKey"
        :item-children="childrenFieldName"
        :load-children="loadChildren"
        selection-type="independent"
        hoverable
        activatable
      )
        template(#prepend="{item}")
          v-icon(v-if="!item[hasChildrenFlagName]") {{ getItemIcon(item) }}
        template(#append="{item}")
          span(
            v-if="model.ormTreeConfig.append"
            class="item__append"
          ) {{ _.isFunction(model.ormTreeConfig.append) ? model.ormTreeConfig.append(item) : item[model.ormTreeConfig.append] }}
        template(#label="{item}")
          div(
            v-if="!item[hasChildrenFlagName]"
            class="item__overlay"
          )
          div(
            class="item"
          ) {{ _.isFunction(model.ormTreeConfig.textFieldName) ? model.ormTreeConfig.textFieldName(item) : item[model.ormTreeConfig.textFieldName] }}

    template(#actions)
      t-orm-buttons(:items="ormButtons")

</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import responsive from '~/mixins/pages/responsive'

export default {
  components: {
    TDialog,
    TOrmButtons
  },
  mixins: [responsive],
  props: {
    model: {
      type: Function,
      required: true
    },
    returnField: {
      type: Boolean,
      default: false
    },
    returnedFieldName: {
      type: String,
      default: null
    },
    allSelectable: {
      type: Boolean,
      default: false
    },
    selectedVal: {
      type: null,
      default: null
    }
  },
  data: () => ({
    title: null,
    localLoading: false,
    active: []
  }),
  computed: {
    childrenFieldName () {
      return this.model.ormTreeConfig.childrenFieldName
    },
    hasChildrenFlagName () {
      return this.model.ormTreeConfig.hasChildrenFlagName
    },
    ormButtons () {
      return [
        {
          text: 'Select',
          attrs: {
            class: 'main-button w-100',
            wrapperClass: this.xsDevice ? ['w-100'] : [],
            depressed: true
          },
          call: this.select
        }
      ]
    },
    rootItems () {
      const items = this.model.query().withAllRecursive(10).where(this.model.ormTreeConfig.parentFieldName, null).get()
      return this.updateChildrenField(items)
    }
  },
  methods: {
    getItemIcon (item) {
      if (this._.get(this.active, '[0]') === this._.get(item, 'id')) {
        return 'mdi-minus'
      }
      return 'mdi-plus'
    },
    updateActive (items) {
      this.active = []
      if (items.length) {
        if (this.allSelectable) {
          this.active.push(items[0])
        } else if (!this.model.query().whereId(items[0]).get()[0][this.hasChildrenFlagName]) {
          this.active.push(items[0])
        }
      }
    },
    async loadChildren (item) {
      if (this.model.ormTreeConfig.asyncLoading && item[this.model.ormTreeConfig.hasChildrenFlagName]) {
        await this.model.api()[this.model.ormTreeConfig.asyncLoading.requestMethod](item.id)
      }
    },
    updateChildrenField (items) {
      const itemsCopy = JSON.parse(JSON.stringify(items))
      this._.each(itemsCopy, (item) => {
        const hasChildren = this._.get(item, this.hasChildrenFlagName)
        if (!hasChildren) {
          delete item[this.childrenFieldName]
        } else {
          const children = this.updateChildrenField(item.children)
          item[this.childrenFieldName] = children
        }
      })
      return itemsCopy
    },
    clear (immediate = false) {
      immediate ? this.item = {} : setTimeout(() => {
        this.item = {}
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    select () {
      if (!this.active.length) { return }
      const data = this.model.query().whereId(this.active[0]).get()[0]
      this.$emit(['update', 'selectedVal'].join(':'), this.returnField ? data[this.returnedFieldName] : data)
      this.close()
    },

    fill (title) {
      this.title = title
    }
  }

}
</script>

<style scoped lang="scss">
.item {
  position: relative;
  z-index: 2;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;

    & + .item {
      cursor: pointer;
    }
  }

  &__append {
    color: #04aed5;
  }
}

::v-deep {
  .v-treeview-node__label {
    white-space: normal;
    overflow: visible;
  }
  .v-treeview-node__prepend, v-treeview-node__append {
    min-width: 0;
  }
  .v-treeview-node__level {
    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 12px;
    }
    @media (max-width: map-get($breakpoints, 'xs')) {
      width: 4px;
    }
  }
}
</style>
