<template lang="pug">
  t-orm-page
    template(v-slot:title) {{ $tc( model.ormTrans.multy, 1 ) }}
    m-orm-table(:model="model" :custom-request="tableRequest")
</template>

<script>
import { Model } from '@vuex-orm/core'
import TOrmPage from '~/components/templates/orm/t-orm-page'
import MOrmTable from '~/components/modules/tables/orm/m-orm-table'
import SmsPayment from '~/models/sms/SmsPayment'
import APIPlatformConnector from '~/plugins/vuex-orm/plugins/_drivers/APIPlatformConnector'

export default {
  components: {
    TOrmPage,
    MOrmTable
  },
  middleware: ['auth', 'superAdmin'],
  computed: {
    model () {
      return SmsPayment
    }
  },
  methods: {
    async tableRequest ({ model, requestParams }) {
      const connector = new APIPlatformConnector(model, requestParams)
      const entryDateBefore = this._.get(connector, 'config.params[\'entryDate[before]\']')
      const entryDateAfter = this._.get(connector, 'config.params[\'entryDate[after]\']')
      if (entryDateBefore && entryDateAfter) {
        const entryDate = this._.get(requestParams, 'filterBy.entryDate')
        const sortedValue = entryDate.sort((a, b) => new Date(a) - new Date(b))
        connector.config.params['entryDate[after]'] = this._.get(sortedValue, '[0]')
        connector.config.params['entryDate[before]'] = this._.get(sortedValue, '[1]') || this._.get(sortedValue, '[0]')
      }
      return await this.model.api().get(Model.$routes[this.model.entity].list(), {
        ...connector.config
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
