import { Model } from '@vuex-orm/core'
import moment from 'moment'
import Outlets from '~/models/directories/Outlets'
import PersistAble from '~/models/mixins/PersistAble'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import OrmModel from '~/models/abstracts/base/OrmModel'
import CashRegisters from '~/models/directories/CashRegisters'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import { formats } from '~/const/global'
import i18n from '~/plugins/nuxt-i18n/i18n'

export class CashRegisterOrder extends PersistAble(OrmModel) {
  static entity = 'cashregisterorder'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'dateSend'
  static defaultSortOrder = true
  static dblClickAction = 'read'
  static persistOptions = {
    insertOrUpdate: ['outlet']
  }

  static prroTypes = {
    stationary: {
      text: i18n.t('Stationary'),
      value: 'M041'
    },
    mobile: {
      text: i18n.t('Movable'),
      value: 'M042'
    },
    selfService: {
      text: i18n.t('Self-service cash register'),
      value: 'M043'
    },
    internet: {
      text: i18n.t('Internet trade'),
      value: 'M044'
    }
  }

  static orderStatuses = {
    100: {
      text: i18n.t('Created '),
      value: 100,
      type: 'order'
    },
    200: {
      text: i18n.t('On registration at the DPS'),
      value: 200,
      type: 'order'
    },
    300: {
      text: i18n.t('Registered'),
      value: 300,
      type: 'checkbox'
    },
    400: {
      text: i18n.t('Error'),
      value: 400,
      type: 'error'
    }
  }

  static orderTypes = {
    100: {
      text: i18n.t('Creating of'),
      value: 100,
      type: 'checkbox'
    },
    200: {
      text: i18n.t('Updating'),
      value: 200,
      type: 'order'
    },
    300: {
      text: i18n.t('Deletion'),
      value: 300,
      type: 'error'
    }
  }

  static fields () {
    return {
      outletId: this.attr(null),
      cashRegisterId: this.attr(null),

      id: this.attr(null),
      // name
      R04G11S: this.attr(null),
      // local number
      R04G12S: this.attr(null),
      // type
      M04: this.attr(null),
      // outlet address
      R03G4S: this.attr(null),
      // User name
      HBOS: this.attr(null),
      // Organization name
      HNAME: this.attr(null),
      // Реєстраційний номер облікової картки платника податків, або серія та номер паспорта, номер паспорта у вигляді ID картки
      HKBOS: this.attr(null),

      HKATOTTG: this.attr(null),

      outlet: this.belongsTo(Outlets, 'outletId'),
      // cashRegister: this.belongsTo(CashRegisters, 'cashRegisterId'),
      dateSend: this.attr(null),
      status: this.attr(null),
      type: this.attr(null),
      hasErrorFile: this.attr(null),
      taxErrorMessage: this.attr(null),
      isReserve: this.attr(false),

      // shadow fields
      fiscalNumber: this.attr('-'),
      licenseKey: this.attr('-')
    }
  }

  get outletString () {
    return this.outlet ? this.outlet.name || '-' : 'No outlet'
  }

  static ormRelationMap = [
    'outlet'
  ]

  static ormTrans = {
    single: 'Cash register',
    multy: 'Cash registers',
    notification: 'Cash register'
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', tableHeader: 'modeString', align: 'left', width: '150', sortable: false, filterable: false },
    { text: 'Fiscal number', value: 'fiscalNumber', align: 'left', filterable: false, sortable: false },
    { text: 'Cash register number', value: 'R04G12S', tableHeader: 'number', align: 'left', sortable: false, filterable: false },
    { text: 'License key', value: 'licenseKey', align: 'left', filterable: false, sortable: false },
    { text: 'Outlet name', value: 'outletString', sortable: false, filterable: false },
    { text: 'Registration address', value: 'R03G4S', tableHeader: 'address', width: '450', sortable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) {
            return {}
          }
          const orderStatus = this.orderStatuses[item.status]

          return {
            id: item.id,
            model: CashRegisterOrder,
            [item.status]: {
              text: orderStatus.text,
              type: orderStatus.type,
              taxErrorMessage: item.taxErrorMessage,
              hasErrorFile: item.hasErrorFile
            }
          }
        }
      }
    }
  }

  static ormFields = [
    {
      model: 'R04G12S',
      component: 'v-text-field',
      context: this.contexts['c.u'],
      asyncDefault: (items, lastCode) => {
        return lastCode ? ++lastCode + '' : ''
      },
      request: async (e) => {
        return this.contexts.isCreate(e.context)
          ? this._.get(await CashRegisters.api().lastPrimaryKey(), 'response.data.number', null)
          : null
      },
      provider: {
        vid: 'R04G12S',
        name: 'Cash register number',
        rules: 'required|numeric'
      },
      attrs: {
        label: 'Cash register number',
        outlined: true
      },
      hint: 'We entered the number automatically, but you can change it'
    },
    {
      model: 'M04',
      component: 'v-select',
      context: this.contexts.c,
      provider: {
        vid: 'M04',
        name: 'Prro type',
        rules: 'required'
      },
      attrs: {
        label: 'Prro type',
        outlined: true
      },
      default: () => Object.values(this.prroTypes)[0].value,
      items: () => Object.values(this.prroTypes)
    },
    new AutocompleteBuilder({
      model: 'outlet',
      label: 'outlet',
      rules: 'required',
      context: this.contexts.c,
      query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode }),
      fieldVal: ctx => this._.get(ctx.attrs, ctx.field.model),
      asyncDefault: data => data?.length === 1 ? data[0] : data
    }, OutletsSearch).build()
  ]

  static ormActions = [
    {
      name: 'delete',
      visible: item => item.status === 400,
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await confirmationDialog.open({
          title: 'Delete error',
          text: 'Are you sure to delete this order?',
          width: '420px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          }
        })
        if (confirmed) {
          try {
            await CashRegisterOrder.api().del(item)
            AppNotifications.success('Error order was deleted successfully')
          } catch (e) {
            // TODO add logs service and logs errors
            const message = this._.get(e, 'response.data.detail', null) || this._.get(e, 'response.data.message', null)
            AppNotifications.error(message)
          }
        }
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    delete: {
      title: item => ({ type: this.ormTrans.single, name: item.name }),
      notification: item => ({ type: 'order ', name: item.name })
    },
    read: {
      title: item => ({ type: 'Cash register ', name: item.name }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'dateSend',
            label: 'Date of created',
            value: val => moment(val).format(formats.dateTimeSec)
          },
          {
            model: 'fiscalNumber',
            label: 'Fiscal number',
            hideEmpty: true
          },
          {
            model: 'R04G12S',
            label: 'Number'
          },
          {
            model: 'R04G11S',
            label: 'Name'
          },
          {
            model: 'outletString',
            translate: true,
            label: 'Outlet name'
          },
          {
            model: 'R03G4S',
            label: 'Registration address'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.xmlDoc = function (model) {
        return this.get(Model.$routes.cashregisterorder.xmlDoc(model.$id || model.id), { save: false })
      }
      configActions.taxRegister = function (model, payload) {
        return this.post(Model.$routes.cashregisterorder.taxRegister(model.$id || model.id), payload)
      }
      configActions.signTaxId = function (model, payload) {
        return this.put(Model.$routes.cashregisterorder.signTaxId(model.$id || model.id), payload)
      }
      configActions.removeFromTax = function (payload) {
        return this.post(Model.$routes.cashregisterorder.removeFromTax(), payload)
      }
      configActions.modifyInTax = function (payload) {
        return this.post(Model.$routes.cashregisterorder.modifyInTax(), payload)
      }
      configActions.getActive = function () {
        CashRegisterOrder.deleteAll()
        return this.all()
      }
      return configActions
    }
  }
}

export default CashRegisterOrder
