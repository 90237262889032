import { Model } from '@vuex-orm/core'
import Search from '~/models/abstracts/Search'
import MediaObject from '~/models/system/MediaObject'
import User from '~/models/administration/User'
import Tags from '~/models/directories/Tags'

export default class ManagedOrganizationsSearch extends Search {
  static entity = 'managedOrganizationsSearch'
  static paginated = true

  static fields () {
    return {
      owner_id: this.attr(null),
      logo_id: this.attr(null),

      ownerId: this.attr(null),
      // billing_id: this.attr(null, val => val),

      id: this.attr(null),
      name: this.attr(null),
      shortName: this.attr(null),
      edrpou: this.attr(null),
      logo: this.belongsTo(MediaObject, 'logo_id'),
      taxNumber: this.attr(null),
      owner: this.belongsTo(User, 'owner_id'),
      users: this.hasMany(User, 'organization', 'id'),
      synchronized: this.attr(null),
      personType: this.attr(null),
      region: this.attr(null),
      district: this.attr(null),
      isSingleTax: this.attr(null),
      isDraft: this.attr(null),
      isVAT: this.attr(null),
      receiptFormationType: this.attr(null),
      receiptSetting: this.attr(null),
      billEmail: this.attr(null),
      emergencyPhone: this.attr(null),
      shiftCloseTime: this.attr(null),
      allowEmployeeLogin: this.attr(null),
      tags: this.attr(null, value => this._.isArray(value)
        ? value.map(item => new Tags(item))
        : []),
      // billing: this.belongsTo(Billings, 'billing_id'),
      billing: this.attr(null),
      promoCodeOrganizations: this.attr(null),
      documentFlowServiceName: this.attr(null),
      documentFlowServiceUser: this.attr(null),
      autoCollectionOnClose: this.attr(null),
      referralCode: this.attr(null),
      actSignatoryPosition: this.attr(null),
      actSignatoryFullName: this.attr(null),
      goodsAccountingEnabled: this.attr(null),
      remindShiftCloseTime: this.attr(null),
      enableSmsModule: this.attr(null),
      hideTestData: this.attr(null),
      isPawnshop: this.attr(null),
      concordLogin: this.attr(null),
      concordTerminalId: this.attr(null),
      chiefName: this.attr(null),
      vip: this.attr(null),
      labels: this.attr([]),
      shares: this.attr([]),
      hasRroShare: this.attr(null)
    }
  }

  toString () {
    return this.name
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.getByUser = function (userId, config = {}) {
        return this.get(Model.$routes.managedOrganizations.getByUser(userId), {
          save: true,
          dataKey: 'data',
          persistBy: config?.persistBy || 'insertOrUpdate',
          params: {
            ...(config?.params || {}),
            'order[name]': 'asc'
          }
        })
      }

      return configActions
    }
  }
}
