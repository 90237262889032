<template lang="pug">
  div(class="h-100")
    v-expansion-panels(v-if="mobile")
      v-expansion-panel(class="hints-block-mobile")
        v-expansion-panel-header {{ title }}
        v-expansion-panel-content
          m-block-set-ettn-settings-hint-content(mobile)
    div(
      class="hints-block"
      v-else
    )
      h3(class="hints-block__title") {{ title }}
      m-block-set-ettn-settings-hint-content
</template>

<script>
import MBlockSetEttnSettingsHintContent from '~/components/modules/blocks/concrete/ettn/m-block-set-ettn-settings-hint-content'

export default {
  name: 'MBlockSetEttnSettingsHint',
  components: {
    MBlockSetEttnSettingsHintContent
  },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return `💡 ${this.$t('Hint')}`
    }
  }
}
</script>

<style scoped lang="scss">

</style>
