<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title mb-3") {{ title }}
    div(
      v-if="itemLoading"
      class="dialog__loader"
    )
      e-progress-circular(
        width="4"
        size="xl"
        color="#161b25"
      )
    v-form(
      @submit.prevent.stop="createReport"
      v-else
    )
      ValidationObserver(
        ref="mallReport"
        slim
      )
        t-orm-fields(
          :items="fields"
          v-bind.sync="form"
        )
      div(class="dialog__actions")
        v-btn(
          class="main-button mx-auto d-block"
          type="submit"
          :loading="loading"
        ) {{ btnText }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import reports from '~/modules/reports/mixins/reports'
import MallReport from '~/modules/reports/models/MallReport'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  name: 'BlockMallReportForm',
  components: {
    TOrmFields,
    EProgressCircular
  },
  mixins: [reports],
  props: {
    isEditing: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    defaultName: {
      type: String,
      default: null
    },
    defaultPeriod: {
      type: Number,
      default: null
    },
    isRespublica: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    form: {
      name: null,
      dateInterval: null,
      outlets: []
    },
    loading: false,
    itemLoading: false,
    intervalRequest: null
  }),
  computed: {
    model () {
      return MallReport
    },
    itemId () {
      return this._.get(this.item, 'id')
    },
    singleItem () {
      return this.model.query().whereId(this.itemId).first()
    },
    title () {
      if (this.isEditing) {
        return this.$t('Edit report')
      }
      return this.$t('Add report')
    },
    btnText () {
      if (this.isEditing) {
        return this.$t('Save')
      }
      return this.$t('Add')
    },
    fields () {
      const fields = []

      if (!this.isRespublica) {
        fields.push(
          {
            model: 'name',
            component: 'v-text-field',
            provider: {
              vid: 'name',
              name: 'Report name',
              rules: 'required'
            },
            attrs: {
              outlined: true,
              label: 'Report name'
            }
          },
          {
            model: 'dateInterval',
            component: 'v-select',
            provider: {
              vid: 'dateInterval',
              name: 'Report period',
              rules: 'required'
            },
            attrs: {
              outlined: true,
              label: 'Report period',
              items: [
                { value: 1, text: this.$t('For day') },
                { value: 2, text: this.$t('For two days') },
                { value: 3, text: this.$t('For three days') }
              ]
            }
          }
        )
      }

      fields.push(this.outletAutocompleteField({
        options: {
          model: 'outlets',
          ...this.multipleAutocompleteCommonOptions({
            chipText: 'name'
          })
        }
      }))

      return fields
    }
  },
  created () {
    if (this.isRespublica) {
      this.form.name = this.defaultName
      this.form.dateInterval = this.defaultPeriod
    }
    this.getSingleItem()
  },
  beforeDestroy () {
    if (this.intervalRequest) {
      this.intervalRequest.stopExponential()
    }
  },
  methods: {
    async getSingleItem () {
      if (!this.isEditing || !this.itemId) {
        return
      }
      try {
        this.itemLoading = true
        await this.model.api().read(this.itemId)
        this.fillData()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.itemLoading = false
      }
    },
    fillData () {
      if (this.singleItem) {
        const nextForm = {
          name: this._.get(this.singleItem, 'name'),
          dateInterval: this._.get(this.singleItem, 'dateInterval'),
          outlets: this._.get(this.singleItem, 'outlets')
        }
        this.$set(this.$data, 'form', nextForm)
      }
    },
    checkStatus (reportId, taskId) {
      const request = new IntervalRequest(() => this.model.api().checkStatus(reportId, { id: taskId }), {
        interval: 1e3,
        count: 300,
        maxDelay: 3e5 // ~5 minutes
      })
      this.intervalRequest = request
      return request.startExponential((response) => {
        const status = this._.get(response, 'response.data.status', null)
        return status === this.model.STATUSES.done || status === this.model.STATUSES.error
      })
    },
    async createReport () {
      try {
        this.loading = true
        const valid = await this._.get(this.$refs, 'mallReport').validate()
        if (!valid || !await this.$checkOrganization()) {
          return
        }
        const payload = {
          ...this.form,
          outlets: this._.map(this.form.outlets, i => `/outlets/${i?.id}`)
        }
        let reportId = null

        if (this.isEditing) {
          reportId = this._.get(await this.model.api().update(this.singleItem, payload), 'response.data.id')
        } else {
          reportId = this._.get(await this.model.api().create(payload), 'response.data.id')
        }

        const taskId = this._.get(await this.model.api().generateReport(reportId, { extension: 'JSON' }), 'response.data.id')
        const status = this._.get(await this.checkStatus(reportId, taskId), 'response.data.status')

        if (status === this.model.STATUSES.error) {
          this.$notification.error(this.$t('An error occurred while generating the report file'))
        } else if (status === this.model.STATUSES.done) {
          const msg = this.isEditing ? 'Report successfully updated' : 'Report successfully created'
          await this.model.api().read(reportId)
          this.$notification.success(this.$t(msg))
          this.closeModal()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
  }
}
</style>
