import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class WeAreWorking extends ChainInheritance(Directory, []) {
  static entity = 'weareworking'

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.update = function (id, payload) {
        return this.put(Model.$routes.weareworking.concrete(id), payload, { save: false })
      }
      configActions.create = function (payload) {
        return this.post(Model.$routes.weareworking.list(), payload, { save: false })
      }
      return configActions
    }
  }
}

export default WeAreWorking
