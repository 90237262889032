<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="$listeners")

    template(#title) {{ $t( config.model.ormTrans.multy ) }}

    template(#content)
      m-orm-table(:model="config.model" v-bind="tableAttrs" :parentItem="selectedItem" topBarBackground="white")
</template>

<script>
// TODO: fix "topBarBackground" property.
import TDialog from '~/components/templates/t-dialog'
import fill from '~/mixins/modules/dialogs/fill'
import props from '~/mixins/modules/dialogs/props'
import configFilters from '~/mixins/computed/configFilters'
import CurrentUser from '~/modules/auth/Models/CurrentUser'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'

export default {
  components: { TDialog },
  mixins: [props, fill, configFilters],
  data: () => ({
    item: {}
  }),
  computed: {
    attrsDefaultFilter () {
      return (typeof this._.get(this.$attrs, 'childAttrs.defaultFilter') === 'function' && this.$attrs.childAttrs.defaultFilter(this.item)) || {}
    },
    tableAttrs () {
      return Object.assign({},
        {
          ...this.$attrs.childAttrs,
          defaultFilter: {
            ...this.configFilters,
            ...this.attrsDefaultFilter
          }
        },
        {
          requestRoute: typeof this.$attrs.requestRoute === 'function' && this.$attrs.requestRoute(this.item)
        }
      )
    }
  },
  methods: {
    fill (ctx, item) {
      if (this.$User.isSuperAdmin) {
        CashRegistersSearch.deleteAll()
        CurrentUser.update({
          where: this.$User.id,
          data: {
            viewedOrganization: item
          }
        })
      }
      this.item = item
    }
  }
}
</script>

<style lang="scss" scoped>
  //
</style>
