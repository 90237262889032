<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" type="assign" fullscreen)
    template(#title) {{ title }}

    template(#content)
      v-row
        v-col(v-if="!pivot.isSimpleAssignable" cols="12")
          t-orm-toolbar(color="transparent")
            v-col(cols="12" class="py-0")
              v-row(justify="space-between")
                v-col(cols="auto" class="py-0")
                  v-row
                    t-orm-buttons(:items="leftToolbarButtons" :classes="['text-left', 'px-0']")
                v-col(cols="auto" class="py-0")
                  v-row
                    t-orm-buttons(:items="rightToolbarButtons" :classes="['text-left', 'px-0']")

        v-col(v-if="!pivot.isSimpleAssignable" cols="9" class="py-0")
          v-card(class="elevation-0" outlined)
            m-orm-table(v-bind="ormTableConfig" :hideTopBar="true")

        v-col(:cols="pivot.isSimpleAssignable ? 12 : 3" class="py-0")
          v-row
            v-col(:cols="pivot.isSimpleAssignable ? 4 : 12" class="py-0 mb-5")
              v-card(class="elevation-0" outlined)
                m-group-tree(v-model="selectedGroup"
                             v-bind.sync="tree"
                             :model="groupModel"
                             :config="ormTreeConfig"
                             :context="$config.contexts.read")

            v-col(:cols="pivot.isSimpleAssignable ? 8 : 12" class="py-0")
              v-card(class="elevation-0" outlined)
                m-orm-select-table(v-model="selectedItems"
                                   v-bind="selectTableConfig.attrs"
                                   :config="selectTableConfig")

    template(#actions)
      v-row
        v-col(cols="12")
          t-orm-buttons(:items="ormModalButtons" :context="context")
</template>

<script>
import DOMPurify from 'dompurify'
// TODO: auto selecting of rows based on selected group.
import mgtsfc from '~/mixins/modules/trees/m-group-tree-cfg'
import itemsFilter from '~/mixins/computed/itemsFilter'
import loading from '~/mixins/system/loading'
import MOrmSelectTable from '~/components/modules/tables/orm/m-orm-select-table'
import TDialog from '~/components/templates/t-dialog'
import TOrmToolbar from '~/components/templates/orm/t-orm-toolbar'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

export default {
  components: {
    MOrmSelectTable,
    TDialog,
    TOrmToolbar,
    TOrmButtons
  },
  mixins: [mgtsfc, itemsFilter, loading],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    ctx: {},
    filters: {},
    selectedItem: {},
    selectedItems: []
  }),
  computed: {
    $modelsCtor () {
      return {
        model: this.whom,
        group: this.groupModel,
        pivot: this.pivot
      }
    },
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type),
        ctx: this.ctx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', 'default')
    },
    whom () {
      return this.dialogConfig.whom
    },
    pivot () {
      return this.dialogConfig.target
    },
    groupModel () {
      return this.dialogConfig.groupModel
    },
    ormTreeConfig () {
      return Object.assign({}, this.treeConfig, {
        // selectable: this.pivot.isSimpleAssignable
      })
    },
    ormTableConfig () {
      return {
        model: this.pivot,
        parentItem: this.selectedItem,
        context: this.$config.contexts.read
      }
    },
    leftToolbarButtons () {
      return [
        {
          text: 'Export to CSV',
          afterText: {
            component: 'e-svg-icon',
            text: 'scv',
            attrs: {
              size: 'lg',
              class: ['ml-2']
            }
          },
          attrs: {
            color: 'success',
            class: ['mr-2']
          },
          // visible: item => this.$ability.can(ormActions.create, this.model[ormConfig.modelKey]),
          call: this.exportCSV
        },
        {
          text: 'Import to CSV',
          afterText: {
            component: 'e-svg-icon',
            text: 'scv',
            attrs: {
              size: 'lg',
              class: ['ml-2']
            }
          },
          attrs: {
            color: 'import',
            class: ['mr-2', 'white--text']
          },
          // visible: item => this.$ability.can(ormActions.create, this.model[ormConfig.modelKey]),
          call: this.importCSV
        },
        {
          component: 'e-button-help',
          attrs: {
            class: ['mr-2']
          },
          // visible: item => this.$ability.can(ormActions.create, this.model[ormConfig.modelKey]),
          call: this.help
        }
      ]
    },
    rightToolbarButtons () {
      return [
        {
          afterText: {
            component: 'v-icon',
            text: 'mdi-reload'
          },
          attrs: {
            color: 'primary',
            class: ['mr-2']
          },
          // visible: item => this.$ability.can(ormActions.create, this.model[ormConfig.modelKey]),
          call: this.refresh
        },
        {
          text: 'Print',
          afterText: {
            component: 'e-svg-icon',
            text: 'printer',
            attrs: {
              size: 'lg',
              class: ['ml-2']
            }
          },
          attrs: {
            color: 'primary'
          },
          // visible: item => this.$ability.can(ormActions.create, this.model[ormConfig.modelKey]),
          call: this.print
        }
      ]
    },
    modelSortParams () {
      return this.whom.getSortParams()
    },
    selectTableConfig () {
      return Object.assign(
        this.$config.table,
        this.modelSortParams,
        {
          attrs: {
            short: true,
            async: true,
            loading: this.isLoading,
            dictionary: this.whom,
            defaultFilter: this.itemsFilter
          }
        })
    },
    isGridded () {
      return this.dialogConfig && ('grid' in this.dialogConfig)
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    morphedCtx () {
      return {
        parent: this.selectedItem,
        $modelsCtor: this.$modelsCtor,
        context: this.context,
        $i18n: this.$i18n,
        ...this.ctx
      }
    },
    title () {
      const result = this.dialogConfig.title(this.morphedCtx)

      if (this._.isObject(result)) {
        result.type = this._.toLower(this.$tc(DOMPurify.sanitize(result.type), 2))
        result.whom = this._.toLower(this.$tc(DOMPurify.sanitize(result.whom), 2))

        return this.$t('m-orm-assign-dialog.title', result)
      }

      return result
    },
    notification () {
      const result = this.dialogConfig.notification(this.morphedCtx)

      if (this._.isObject(result)) {
        result.whom = this._.toLower(DOMPurify.sanitize(result.whom))

        return this.$t('m-orm-assign-dialog.notification', result)
      }

      return result
    },
    ormModalButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            text: true,
            depressed: true,
            class: ['mr-2']
          },
          call: this.close
        },
        {
          text: 'Confirm',
          attrs: {
            color: 'primary',
            depressed: true
          },
          loading: this.isLoading,
          call: this.save
        }
      ]
    }
  },
  methods: {
    exportCSV () {
      //
    },
    importCSV () {
      //
    },
    help () {
      //
    },
    refresh () {
      //
    },
    print () {
      //
    },
    save () {
      this.$loadFn(async () => {
        try {
          await this.model.api().update(this.selectedItem, {
            [this.selectedItem.$self().getAssignMapFor(this.whom)]: this.selectedItems
          })

          this.$notification.success(this.notification)

          this.close()
        } catch (e) {
          this.$handlers.error(e, this.$refs[this.model.entity])
        }
      })
    },
    clear () {
      setTimeout(() => {
        this.selectedItems = []
        this.tree.checked = []
      }, 300)
    },
    close () {
      this.$emit('input', false)

      this.clear()
    },

    fill (context, item, ctx = {}) {
      this.ctx = ctx
      this.selectedItem = item
      if (item.$self().isSimpleAssignable) {
        this.selectedItems = item[this.selectedItem.$self().getAssignMapFor(this.whom)]
      }
    }
  }
}
</script>

<style lang="scss">
  //
</style>
