<template lang="pug">
  div()
    block-employee-information(
      v-if="sentSuccessfully"
      title="You have successfully created a cashier"
      :close-modal="closeModal"
    )
    div(
      class="dialog"
      v-else
    )
      div(class="dialog__title mb-2") {{ $t('Enter the password from DepositSign') }}
      ValidationObserver(ref="validator" slim)
        v-form(@submit.prevent.stop="submit")
          ValidationProvider(
            vid="password"
            :name="$t('Deposit sign key password')"
            mode="passive"
            rules="required|max:32"
            v-slot="{ errors }"
          )
            e-input-password(
              v-model="password"
              type="password"
              outlined
              :label="$t('Deposit sign key password')"
              :error-messages="errors")
          div(class="dialog__actions")
            v-btn(
              class="main-button"
              :loading="loading"
              type="submit"
            ) {{ $t('Send') }}
</template>

<script>
import Base64js from 'base64-js'
import Dialog from '~/models/system/Dialog'
import BlockEmployeeInformation from '~/modules/employees/views/components/block-employee-information'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import EInputPassword from '~/components/elements/inputs/e-input-password.vue'

export default {
  name: 'FormDepositsignPassword',
  components: {
    BlockEmployeeInformation,
    EInputPassword
  },
  mixins: [edsIitCheckboxDialog],
  props: {
    item: {
      type: Object,
      default: null
    },
    model: {
      type: null,
      default: null
    },
    token: {
      type: String,
      default: null
    },
    deletedOrder: {
      type: Object,
      default: null
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    localAxios: null,
    password: null,
    sentSuccessfully: false,
    certificateIsInvalid: false
  }),
  computed: {},
  created () {
    this.localAxios = this.$axios.create()
  },
  methods: {
    async checkPassword () {
      const blob = new Blob([this.token])
      const signData = await new Response(blob).arrayBuffer()
      try {
        const { data } = await this.localAxios.post('https://depositsign.com/api/v1/checkbox/sign/file',
          {
            FileData: Base64js.fromByteArray(new Uint8Array(signData)),
            AppendData: true,
            Password: this.password
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.token
            }
          })
        return !!data.SignedData
      } catch (e) {
        if (e.response.data.DetailCode === 'CertificateIsInvalid') {
          const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
          await confirmationDialog.open({
            text: this.$t('Your DepositSign key has been revoked, in order to continue with cashier registration, you must go through the key generation procedure again.'),
            title: this.$t('Confirm action'),
            onConfirm: async () => {
              await this.model.api().recreateDsRequest(this.item.id)
              this.$notification.success(this.$t('The request to change the key from DepositSign has been successfully submitted.'))
            }
          })
          this.certificateIsInvalid = true
        }

        return false
      }
    },
    async validateData () {
      const localValidation = await this.$refs.validator.validate()
      if (!localValidation) {
        return false
      }
      const passwordValid = await this.checkPassword()
      if (passwordValid === false) {
        this.$refs.validator.setErrors({
          password: this.$t('This password is not valid. Enter another')
        })
      }
      if (this.certificateIsInvalid) {
        this.closeModal()
      }
      return passwordValid
    },
    async sendToTax () {
      await this.useEdsIitCheckboxDialog({
        fallbackMethod: this.sendToTax,
        checkAuthService: true,
        createReportService: true,
        onConfirm: async ({ error, noReturn, reportService, fallbackMethod }) => {
          try {
            if (error) {
              return !noReturn ? this.sendToTax() : null
            }

            if (this.deletedOrder && this._.get(this.deletedOrder, 'status') === EmployeeOrder.orderStatuses['100'].value) {
              const deletedOrderSentToTaxSuccessfully = await this.sendToTaxRequest({
                item: this.deletedOrder,
                reportService,
                fallbackMethod
              })
              if (!deletedOrderSentToTaxSuccessfully) {
                this.$notification.error(this.$t('An error occurred when sending the cashier to the DPS. Please try again or contact an administrator'))
                return null
              }
            }

            const orderSentToTaxSuccessfully = await this.sendToTaxRequest({
              item: this.item,
              reportService,
              fallbackMethod
            })
            if (!orderSentToTaxSuccessfully) {
              this.$notification.error(this.$t('An error occurred when sending the cashier to the DPS. Please try again or contact an administrator'))
              return null
            }

            this.sentSuccessfully = true
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
      })
    },
    async sendToTaxRequest ({ reportService, item, fallbackMethod } = {}) {
      const xmlResponse = await this.model.api().xmlDoc(item)
      const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
      const fname = this._.get(xmlResponse, 'response.data.fname', null)
      const signData = await reportService.getEnvelopedData({
        dataToSign: xml,
        verifyKey: true,
        fallbackMethod
      })
      if (!signData) {
        return false
      }
      const taxId = this._.get(await this.model.api().taxRegister(item, { signData, fname }), 'response.data.response.taxId', null)
      const encodedTaxId = new TextEncoder().encode(String(taxId))
      const signedTaxId = await reportService.getEnvelopedData({
        dataToSign: encodedTaxId,
        isXml: false,
        fallbackMethod
      })
      if (!signedTaxId) {
        return false
      }
      await this.model.api().signTaxId(item, { tax_id: signedTaxId })
      return true
    },
    async submit () {
      try {
        this.loading = true
        const valid = await this.validateData()
        if (!valid) {
          return
        }
        // Updating model password
        await this.model.api().changeDsPassword(this._.get(this.item, 'id'), { dsPass: this.password })
        // Sending to tax
        await this.sendToTax()
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 16px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    padding: 16px 32px 32px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0 0;

    .main-button {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        max-width: 180px;
      }
    }
  }
}
</style>
