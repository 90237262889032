import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class MonobankTransitions extends PersistAble(OrmModel) {
  static entity = 'monobanktransitions'

  static ormTrans = {
    single: '',
    multy: ''
  }

  static fields () {
    return {
      id: this.attr(null)
    }
  }

  static ormFields = []

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.setTransition = function (payload) {
        return this.post(Model.$routes.monobanktransitions.setTransition(), payload, { save: false })
      }
      configActions.acceptTransition = function (payload) {
        return this.post(Model.$routes.monobanktransitions.acceptTransition(), payload, { save: false })
      }
      configActions.checkTransition = function () {
        return this.get(Model.$routes.monobanktransitions.checkTransition(), { save: false })
      }
      return configActions
    }
  }
}

export default MonobankTransitions
