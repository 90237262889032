import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'

export default class SmsBilling extends OrmModel {
  static entity = 'smsBilling'
  static paginated = false

  static fields () {
    return {
      balance: this.attr(null),
      blocked: this.attr(false),
      createdAt: this.attr(null),
      organizationId: this.attr(null),
      organizationData: this.attr({}),
      payerCode: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.getSmsBilling = function () {
        return this.get(Model.$routes.smsBilling.list(), { save: false })
      }
      configActions.getReconciliationAct = function (params) {
        return this.get(Model.$routes.smsBilling.reconciliationAct(), {
          responseType: 'blob',
          save: false,
          params
        })
      }

      return configActions
    }
  }
}
