import ChainInheritance from '~/models/mixins/ChainInheritance'
import Directory from '~/models/abstracts/Directory'

export default class Restorations extends ChainInheritance(Directory, []) {
    static entity = 'restorations'
    static defaultSortParam = 'date'
    static paginated = true
    static ormLoadWithRelations = true

    static ormTrans = {
      single: 'Activation log',
      multy: 'Activation logs'
    }

    static fields () {
      return {
        id: this.attr(null),
        date: this.attr(null),
        organization: this.attr(null),
        user: this.attr(null),
        crmUserName: this.attr(null)

      }
    }

    get dateString () {
      return this.getDateTime(this.date)
    }

    get adminFullName () {
      return this.crmUserName ? this.crmUserName : `${this.user.profile.firstName} ${this.user.profile.lastName}`
    }

    static ormHeaders = [
      { text: 'organization', value: 'organization.name', sortable: false, filterable: false },
      { text: 'Admin', value: 'adminFullName', sortable: false, filterable: false },
      { text: 'Date', value: 'dateString', sortable: true, filterable: false }
    ]
}
