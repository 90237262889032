import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'

const _commonData = {
  computed: {
    authenticatedCashier () {
      return AuthenticatedCashier.query().first() || {}
    }
  }
}

export default _commonData
