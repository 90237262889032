import { Model } from '@vuex-orm/core'
import Tags from '~/models/directories/Tags'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import authUser from '~/modules/auth/authUser'
import i18n from '~/plugins/nuxt-i18n/i18n'

export default class Invoices extends ChainInheritance(Directory, []) {
  static entity = 'invoices'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'date'

  static ormTrans = {
    single: 'Invoice',
    multy: 'Invoices'
  }

  static statuses = {
    new: {
      value: 'new',
      text: i18n.t('New')
    },
    invoiced: {
      value: 'invoiced',
      text: i18n.t('Awaiting payment')
    },
    payed: {
      value: 'payed',
      text: i18n.t('Paid ')
    },
    not_payed: {
      value: 'not_payed',
      text: i18n.t('Not paid')
    },
    payed_partly: {
      value: 'payed_partly',
      text: i18n.t('Partially paid')
    },
    errors: {
      value: 'errors',
      text: i18n.t('Error')
    },
    free: {
      value: 'free',
      text: i18n.t('Free of charge')
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      date: this.attr(null),
      errorMessage: this.attr(null),
      number: this.attr(null),
      status: this.attr(null),
      period: this.attr(null),
      subscrCount: this.attr(null),
      sum: this.attr(null),
      sumInitial: this.attr(null),
      sumWithVat: this.attr(null),
      organization: this.attr(null),
      promoCodeOrganization: this.attr(null),
      comment: this.attr(null),
      payedAt: this.attr(null)
    }
  }

  get dateString () {
    return this.getDateTime(this.date)
  }

  get payedAtString () {
    return this.getDateTime(this.payedAt)
  }

  get statusString () {
    return this.status && Invoices.statuses[this.status] && Invoices.statuses[this.status].text
  }

  get sumString () {
    return typeof this.sumWithVat === 'number' && (this.sumWithVat / 100).toFixed(2)
  }

  get verifCodeString () {
    const verifCode = this._.get(this.organization, 'billing.verifCode', null)
    return verifCode ? '#' + verifCode : ''
  }

  static ormHeaders = [
    { text: 'Organization ', value: 'organization.name', sortable: true, filterable: false },
    { text: 'Date', value: 'dateString', sortable: true, filterable: false },
    { text: 'Number', value: 'number', sortable: true, filterable: false },
    { text: 'Status', value: 'statusString', sortable: true, filterable: false },
    { text: 'Payed date', value: 'payedAtString', sortable: true, filterable: false },
    { text: 'Sum', value: 'sumString', sortable: true, filterable: false },
    { text: 'Payment code', value: 'verifCodeString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      attrs: {
        label: 'Status',
        outlined: true,
        'hide-details': true
      },
      default: () => '',
      items: () => [{ text: i18n.t('All'), value: '' }, ...Object.values(this.statuses)]
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    },
    {
      model: 'organization.tags',
      component: 'v-select',
      provider: {
        name: 'Tags',
        vid: 'tags',
        rules: ''
      },
      attrs: {
        label: 'Tags',
        outlined: true,
        clearable: true,
        'return-object': true,
        'item-text': value => value.name,
        visible: () => authUser.get().isSuperAdmin
      },
      async request () {
        Tags.deleteAll()
        await Tags.api().all()
      },
      items: () => Tags.all()
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 4,
                sm: 6,
                md: 4
              },
              fields: [
                'organization.tags'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4,
                sm: 6,
                md: 4
              },
              fields: [
                'search'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 4,
                sm: 6,
                md: 4
              },
              fields: [
                'status'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormDialogs = {
  }

  static ormActions = [
    {
      name: 'downloadPdf',
      text: 'Download PDF',
      icon: {
        type: 'e-svg-icon',
        text: 'pdf'
      },
      call: async (item) => {
        if (item && item.id) {
          const res = await Invoices.api().get(Invoices.$routes[Invoices.entity].getFile(item.id), {
            save: false,
            responseType: 'blob'
          })
          const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
          window.open(URL.createObjectURL(blob), '_blank')
        }
      }
    },
    {
      name: 'set_free',
      text: i18n.t('Exempt from payment'),
      icon: {
        type: 'e-svg-icon',
        text: 'billing'
      },
      visible: (item, user) => {
        return item.status === Invoices.statuses.not_payed.value && user?.isSuperAdmin
      },
      call: async (item) => {
        const confirnationDialog = Dialog.query().where('type', 'confirmation').first()
        const confirmed = await confirnationDialog.open({
          title: i18n.t('Are you sure you want to release the invoice from {date}?', { date: new Intl.DateTimeFormat('uk-UA').format(new Date(item.date)) })
        })
        if (confirmed) {
          try {
            await Invoices.api().setFree(item.id)
            AppNotifications.success(i18n.t('The account has been successfully released from payment'))
          } catch (e) {
            AppNotifications.error(`${i18n.t('An error occurred')}: ${e.message}`)
          }
        }
      }
    },
    {
      name: 'regeneration',
      text: 'Regeneration',
      icon: {
        type: 'e-svg-icon',
        text: 'refresh'
      },
      visible: (item, user) => {
        return item.status === Invoices.statuses.invoiced.value && user?.isBillingManager
      },
      call: async (item) => {
        const invoice = this._.get(await Invoices.api().read(item.id), 'entities.invoices[0]')
        const cashRegisterPeriods = this._.get(await Invoices.api().cashRegisterPeriods(item.id), 'response.data.data', [])
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          title: 'Invoice regeneration',
          subtitle: this._.get(invoice, 'promoCodeOrganization', null) ? 'Importantly! A promo code has been applied to this account!' : '',
          width: '860px',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Save',
            fields: [
              {
                model: 'sum',
                component: 'v-text-field',
                provider: {
                  vid: 'sum',
                  name: 'Sum',
                  rules: 'required'
                },
                attrs: {
                  label: 'Sum',
                  outlined: true,
                  type: 'number'
                },
                fieldVal: () => this._.get(invoice, 'sum', 0) / 100,
                cast: val => Math.round(parseFloat(val) * 100)
              },
              {
                model: 'sum_initial',
                component: 'v-text-field',
                provider: {
                  vid: 'sum_initial',
                  name: 'Sum without promocode',
                  rules: 'required'
                },
                attrs: {
                  label: 'Sum without promocode',
                  outlined: true,
                  type: 'number'
                },
                fieldVal: () => this._.get(invoice, 'sumInitial', 0) / 100,
                cast: val => Math.round(parseFloat(val) * 100)
              },
              {
                model: 'comment',
                component: 'v-text-field',
                provider: {
                  vid: 'comment',
                  name: 'Comment',
                  rules: 'required'
                },
                attrs: {
                  label: 'Comment',
                  outlined: true
                },
                fieldVal: () => this._.get(invoice, 'comment', '')
              },
              {
                model: 'cash_register_periods',
                component: 'v-select',
                provider: {
                  vid: 'cash_register_periods',
                  name: 'Cash register periods',
                  rules: 'required'
                },
                attrs: {
                  label: 'Cash register periods',
                  outlined: true,
                  smallChips: true,
                  multiple: true,
                  clearable: true,
                  cacheItems: true,
                  useDefaultSearchFilter: true,
                  items: cashRegisterPeriods,
                  itemText: (item) => {
                    const { cashRegister = {}, type, count } = item
                    return `${cashRegister.fiscalNumber}_${type}_${count}`
                  },
                  itemValue: 'id'
                },
                fieldVal: () => this._.map(cashRegisterPeriods, item => item.id)
              }
            ],
            onSubmit: async (data) => {
              await Invoices.api().regenerate(item.id, data)
              AppNotifications.success('Invoice successfully regenerated')
              await contentDialog.close()
            }
          }
        })
      }
    }
  ]

  static ormDialogsConfig = {
    read: {
      title: item => ({ type: this.ormTrans.single, name: item.number }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'organization.name',
            label: 'Organization '
          },
          {
            model: 'dateString',
            label: 'Date'
          },
          {
            model: 'number',
            label: 'Number'
          },
          {
            model: 'statusString',
            label: 'Status'
          },
          {
            model: 'sumString',
            label: 'Sum'
          },
          {
            model: 'subscrCount',
            label: 'Cash registers amount'
          },
          {
            model: 'verifCodeString',
            label: 'Payment code'
          },
          {
            model: 'comment',
            label: 'Comment'
          },
          {
            model: 'errorMessage',
            label: 'Error',
            value: val => val || '—'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.createWaybillXml = function (id, payload) {
        return this.post(Model.$routes.invoices.getWaybillXml(id), payload, { save: false })
      }
      configActions.getWayBillData = function (id) {
        return this.get(Model.$routes.invoices.getWaybillXmlParams(id), { save: false })
      }
      configActions.sendWaybillXml = function (id, body) {
        return this.post(Model.$routes.invoices.sendSignedXml(id), body, { save: false })
      }
      configActions.regenerate = function (id, payload = {}) {
        return this.put(Model.$routes.invoices.regenerate(id), payload)
      }
      configActions.setFree = function (id) {
        return this.put(Model.$routes.invoices.setFree(id), {})
      }
      configActions.cashRegisterPeriods = function (id) {
        return this.get(Model.$routes.invoices.cashRegisterPeriods(id), {
          save: false,
          params: {
            limit: 1000,
            offset: 1
          }
        })
      }
      configActions.applePayValidation = function (body) {
        return this.post(Model.$routes.invoices.applePayValidation(), body, { save: false })
      }
      configActions.applePayPayment = function (body, params) {
        return this.post(Model.$routes.invoices.applePayPayment(), body, { save: false, params })
      }
      configActions.invoiceRegister = function (body) {
        return this.post(Model.$routes.invoices.invoiceRegister(), body, { save: false })
      }
      configActions.invoiceRegisterStatus = function (id) {
        return this.get(Model.$routes.invoices.invoiceRegisterStatus(id), {}, { save: false })
      }
      configActions.getExcelList = function (id) {
        return this.get(Model.$routes.invoices.getExcelList(id), { responseType: 'blob', save: false })
      }
      configActions.setCardToken = function (payload) {
        return this.post(Model.$routes.invoices.setCardToken(), payload)
      }
      return configActions
    }
  }
}
