<template lang="pug">
  v-row( justify="center" class="px-6")
    v-col(cols="12")
      h3(class="title font-weight-bold wb-bw text-center" style="color: #000" v-if="showTitle")
        | {{title}}
      p(v-if="showText" class="title text-center mb-0" style="margin-top:5px") {{$t('Close shift by cashier') + shift.cashier.name}} ?
    v-col(cols="12" class="text-center" v-if="showLoading")
      e-progress-circular(size="lg")
    v-col(
      v-if="isHeadCashier || (!hasAuthenticatedCashier || type==='head_cashier') && showRadioButtons"
      cols="12"
    )
      ValidationObserver(ref="selectedCashier" v-if="isHeadCashier" slim)
        t-fields(:items="[cashierAutocompleteConfig]" style="margin-top:0!important" :selectedCashier.sync="selectedCashier" :cols="{cols:12}" )
      v-radio-group(class="mt-0" v-model="signType" v-if="(!hasAuthenticatedCashier || isHeadCashier) && showRadioButtons" hide-details)
        v-radio(:label="$t('Load EDS key in browser')" value="eds_key" color="black" :ripple="false")
        v-radio(:label="$t('I run the Checkbox Signature software locally')" value="agent" color="black" :ripple="false")
      div()
    v-col(cols="auto")
      v-btn(color="primary" class="main-button" @click="closeShiftAction" :loading="loading" depressed :disabled="disabledButton")
        | {{$t('Continue')}}
</template>

<script>
import Shifts from '~/models/directories/Shifts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import Employees from '~/modules/employees/models/Employees'
import ProcessingSignAgent from '~/services/Processing/ProcessingSignAgent'
import processingReports from '~/modules/receipt/mixins/actions/processingReports'
import _comonData from '~/modules/receipt/mixins/getters/commonData'
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
import TFields from '~/components/templates/t-fields'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import processingCheckAgent from '~/modules/receipt/mixins/actions/processingCheckAgent'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'

export default {
  name: 'MBlockShiftClose',
  components: { TFields, EAutocomplete, EProgressCircular },
  mixins: [processingReports, _comonData, processingCheckAgent, edsIitCheckboxDialog, processingApiRequest],
  props: {
    shift: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'current_cashier'
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      token: null,
      signType: 'agent',
      loading: false,
      zReportPreview: null,
      selectedCashier: null,
      showRadioButtons: false,
      showLoading: true,
      showText: false,
      showTitle: true,
      disabledButton: true,
      selectLoading: false
    }
  },
  computed: {
    isHeadCashier () {
      return this.type === 'head_cashier'
    },
    cashierAutocompleteConfig () {
      return new AutocompleteBuilder({
        model: 'selectedCashier',
        label: 'Select employee',
        rules: 'required',
        returnObject: true,
        itemClass: 'ws-pre',
        hideDetails: true,
        selectLoading: this.selectLoading,
        query: model => model.api().filter({ mode: Employees.itemModes.checkbox.mode, type: Employees.itemTypes.head_cashier.value })
      }, EmployeesSearch).build()
    },
    cashier () {
      return Employees.query().whereId(this._.get(this.shift, 'cashier.id')).first()
    },
    hasAuthenticatedCashier () {
      return this._.get(this.authenticatedCashier, 'cashier.id') === this._.get(this.cashier, 'id') &&
        this._.get(this.authenticatedCashier, 'cashRegister.id') === this._.get(this.shift, 'cashRegister')
    },
    title () {
      if (this.type === 'current_cashier' && !this.showLoading) {
        return this.hasAuthenticatedCashier ? this.$t('Are you sure to close shift?') : this.$t('Select type of closing shift')
      } else if (this.type === 'current_cashier') {
        return this.$t('Check cashier key status...')
      } else if (this.type === 'head_cashier' && this.showRadioButtons) {
        return this.$t('Select type of closing shift')
      } else if (this.type === 'head_cashier') {
        return this.$t('Select employee')
      } else {
        return ''
      }
    }
  },
  watch: {
    async selectedCashier (val, prevVal) {
      if (val && ((prevVal ? (val?.id !== prevVal?.id) : true))) {
        this.selectLoading = true
        const { signAgentLaunched } = await this.checkSignAgent(val)
        this.selectLoading = false
        this.disabledButton = false
        if (signAgentLaunched) {
          this.showText = true
        } else {
          this.showRadioButtons = true
        }
      } else {
        this.disabledButton = true
        this.showRadioButtons = false
        this.showText = false
      }
    }
  },
  async created () {
    await Employees.api().read(this._.get(this.shift, 'cashier.id'))
  },
  async mounted () {
    if (this.type === 'current_cashier') {
      const { signAgentLaunched } = await this.checkSignAgent(this.shift.cashier)
      this.showLoading = false
      this.disabledButton = false
      if (signAgentLaunched) {
        this.showText = true
        this.showTitle = false
      } else {
        this.showRadioButtons = true
      }
    } else {
      this.showLoading = false
      this.showRadioButtons = false
    }
  },
  methods: {
    async closeShiftAction (e, skipClientNameCheck = false) {
      if (this.isHeadCashier && !await this.$refs.selectedCashier.validate()) {
        return
      }
      this.loading = true
      let closedShift = null
      if (this.hasAuthenticatedCashier && !this.isHeadCashier) {
        closedShift = await this.closeByAuthenticatedCashier(skipClientNameCheck)
      } else if (this.signType === 'agent') {
        closedShift = await this.closeByPinCode(skipClientNameCheck)
      } else if (this.signType === 'eds_key') {
        closedShift = await this.closeShiftBySign(skipClientNameCheck)
      }
      if (closedShift) {
        this.$notification.success(this.$t('Shift closed successfully'))
        const zReport = this._.get(await this.getReportText(this._.get(closedShift, 'z_report.id')), 'response.data')
        this.closeModal(zReport)
      } else {
        this.loading = false
      }
    },
    async closeByPinCode (skipClientNameCheck) {
      try {
        const token = await this.authenticateByPinCode()
        if (!token) { return null }
        return await this.closeShift(token, skipClientNameCheck)
      } catch (e) {
        this.$handlers.error(e, this)
        return null
      }
    },
    async closeShiftBySign (skipClientNameCheck) {
      let signAgent = null
      const dialogType = 'edsIitCheckboxCommon'
      try {
        const signToken = await this.authenticateBySign()
        const accessToken = await this.authenticateByPinCode()
        if (!signToken || !accessToken) { return null }
        const signInterface = this.getEdsIitCheckboxInterface(dialogType)
        signAgent = new ProcessingSignAgent(signToken, signInterface)
        signAgent.start()
        const closedShift = await this.closeShift(accessToken, skipClientNameCheck)
        await signAgent.stop()
        await this.closeEdsIitCheckboxDialog(dialogType)
        return closedShift
      } catch (e) {
        this.$handlers.error(e, this)
        await this.closeEdsIitCheckboxDialog(dialogType)
        return null
      } finally {
        if (signAgent instanceof ProcessingSignAgent) {
          await signAgent.stop()
        }
      }
    },
    async closeByAuthenticatedCashier (skipClientNameCheck) {
      try {
        if (this.authenticatedCashier?.maybeStartSignAgent) {
          await this.authenticatedCashier.maybeStartSignAgent()
        }
        return await this.closeShift(this.authenticatedCashier?.accessToken, skipClientNameCheck)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async authenticateByPinCode () {
      const cashier = this.isHeadCashier ? this.selectedCashier : this.shift.cashier
      return this._.get(await Employees.api().processingSignin(cashier), 'response.data.access_token')
    },
    async authenticateBySign () {
      const cashier = this.isHeadCashier ? this.selectedCashier : this.cashier
      const dialogType = 'edsIitCheckboxCommon'
      let res = null
      await this.useEdsIitCheckboxDialog({
        type: dialogType,
        method: 'open',
        keyCheck: false,
        onlyFileSign: true,
        pluginTitles: {
          fileSign: this.$t('signPlugin.employeeFileKey')
        },
        hints: {
          fileSign: this.$t('signPlugin.edsWindow')
        },
        onConfirm: async ({ edsKeyData, sign, dialogClosed }) => {
          try {
            if (dialogClosed) {
              return
            } else if (!edsKeyData?.keyData) {
              this.$handlers.error('We could not read the key data. Please try again or choose a different key', this)
              return null
            }
            if (edsKeyData.publicKeyId.replace(/ /g, '').toLowerCase() !== cashier.publicKeyId) {
              this.$handlers.error(this.$t('Selected key do not matched with selected cashier. Please select right EDS Key'))
              return await this.authenticateBySign()
            }
            const signedData = await sign(cashier.login)
            res = this._.get(await Employees.api().processingLoginSignature(signedData), 'response.data.access_token', null)
          } catch (e) {
            this.$handlers.error(e, this)
          } finally {
            await this.closeEdsIitCheckboxDialog(dialogType)
          }
        }
      })
      return res
    },
    async closeShift (accessToken, skipClientNameCheck) {
      const resolveCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.CLOSED)
      const employee = this.isHeadCashier ? this.selectedCashier : this.shift.cashier
      let closingShift = null
      if (this.isHeadCashier) {
        const cashRegisterId = this._.get(this.shift, 'cashRegister.id')
        closingShift = await this.processingApiRequest({
          request: token => Shifts.api().processingCloseById(token, cashRegisterId, this._.get(this.shift, 'id'), skipClientNameCheck),
          token: accessToken,
          employee
        })
      } else {
        closingShift = await this.processingApiRequest({
          request: token => Shifts.api().processingCloseCurrent(token, skipClientNameCheck),
          token: accessToken,
          employee
        })
      }
      const intervalRequest = new IntervalRequest(async () => {
        return await this.processingApiRequest({
          request: token => Shifts.api().processingRead(this._.get(closingShift, 'response.data.id'), token),
          token: accessToken,
          employee
        })
      })
      const res = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data', null)
      // TODO Временное решение (таймзона)
      let closedAt = this._.get(res, 'closed_at') || ''
      if (closedAt) {
        const date = new Date(closedAt)
        const offset = Math.abs(date.getTimezoneOffset() / 60)
        closedAt = closedAt.replace('+00:00', `+0${offset}:00`)
      }
      Shifts.update({
        where: this._.get(res, 'id'),
        data: {
          closedAt,
          status: this._.get(res, 'status')
        }
      })
      return res
    }
  }
}
</script>

<style scoped lang="scss">
.print-preview {
  height: 400px;
  overflow-y: auto;
}
</style>
