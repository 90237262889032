import RozetkapayOrder from '~/modules/acquiring/models/RozetkapayOrder'

const rozetkaPay = {
  computed: {
    model () {
      return RozetkapayOrder
    },
    steps () {
      return {
        preview: 'preview',
        phoneVerification: 'phoneVerification',
        form: 'form',
        clientDocuments: 'clientDocuments',
        financialDocuments: 'financialDocuments',
        signInfo: 'signInfo',
        orderSentInfo: 'orderSentInfo',
        status: 'status'
      }
    },
    statusText () {
      return {
        [this.model.STATUSES_EXTERNAL.userInfoProviding]: 'Application submission',
        [this.model.STATUSES_EXTERNAL.documentsUploading]: 'Application submission',
        [this.model.STATUSES_EXTERNAL.initialReview]: 'The application is pending',
        [this.model.STATUSES_EXTERNAL.finmonReview]: 'The application is pending',
        [this.model.STATUSES_EXTERNAL.covenant]: 'The application is pending',
        [this.model.STATUSES_EXTERNAL.activation]: 'The application is pending',
        [this.model.STATUSES_EXTERNAL.activated]: 'The application is approved',
        [this.model.STATUSES_EXTERNAL.declined]: 'Order declined',
        [this.model.STATUSES_EXTERNAL.failed]: 'Order error'
      }
    }
  }
}

export default rozetkaPay
