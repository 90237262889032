<template lang="pug">
  div(class="h-100")
    v-expansion-panels(v-if="mobile")
      v-expansion-panel(class="hints-block-mobile")
        v-expansion-panel-header {{ title }}
        v-expansion-panel-content
          block-eds-iit-checkbox-hint-content(
            :steps="steps"
            :current-step="currentStep"
            :content="content"
            mobile
          )
    div(
      class="hints-block"
      v-else
    )
      h3(class="hints-block__title") {{ title }}
      block-eds-iit-checkbox-hint-content(
        :steps="steps"
        :current-step="currentStep"
        :content="content"
      )
</template>

<script>
import edsIitCheckboxSteps from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxSteps'
import BlockEdsIitCheckboxHintContent from '~/modules/eds-iit-checkbox/views/block-eds-iit-checkbox-hint-content'

export default {
  name: 'BlockEdsIitCheckboxHint',
  components: {
    BlockEdsIitCheckboxHintContent
  },
  mixins: [edsIitCheckboxSteps],
  props: {
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    title () {
      return `💡 ${this.$t('Hint')}`
    }
  }
}
</script>

<style scoped lang="scss">
</style>
