import { Model } from '@vuex-orm/core'
import { get, concat, map, isEmpty, reverse } from 'lodash'
import Dialog from '~/models/system/Dialog'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import FilterAble from '~/models/mixins/FilterAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'
import AppNotifications from '~/services/Notifications/AppNotifications'
import { formats } from '~/const/global'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import Receipts from '~/modules/receipt/models/Receipts'
import i18n from '~/plugins/nuxt-i18n/i18n'

class Prepayment extends ChainInheritance(Directory, [
  FilterAble,
  PersistAble,
  TimestampAble,
  AssignAble
]) {
  static entity = 'prepayment'
  static paginated = true

  static statuses = {
    partialPaid: {
      value: 'PARTIAL_PAID',
      text: i18n.t('Partial payment')
    },
    fullPaid: {
      value: 'FULL_PAID',
      text: i18n.t('Payed')
    },
    cancelled: {
      value: 'CANCELLED',
      text: i18n.t('Canceled')
    },
    partialCancelled: {
      value: 'PARTIAL_CANCELLED',
      text: i18n.t('Partially canceled')
    }
  }

  static ormTrans = {
    single: 'Prepayment',
    multy: 'Prepayments'
  }

  static fields () {
    return {
      id: this.attr(null),
      relation_id: this.attr(null),
      paid_sum: this.attr(null),
      total_sum: this.attr(null),
      left_to_pay: this.attr(null),
      is_debt_receipt: this.attr(null),
      pre_payment_status: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null)
    }
  }

  get formattedPaidSum () {
    return ((this.paid_sum || 0) / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get formattedTotalSum () {
    return ((this.total_sum || 0) / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get formattedLeftToPay () {
    return ((this.left_to_pay || 0) / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get createdAtString () {
    return this.getDateTime(this.created_at)
  }

  static ormHeaders = [
    { text: 'Order number', value: 'relation_id', sortable: false, filterable: false },
    { text: 'Date created', value: 'createdAtString', sortable: true, filterable: false },
    { text: 'Total to pay, ₴', value: 'formattedTotalSum', sortable: false, filterable: false },
    { text: 'Prepayment sum, ₴', value: 'formattedPaidSum', sortable: false, filterable: false },
    { text: 'Postpayment sum, ₴', value: 'formattedLeftToPay', sortable: false, filterable: false },
    { text: 'Status', value: 'pre_payment_status', width: '150', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '50', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'relation_id'
  static ormMobileSuptitle = 'createdAtString'
  static ormMobileText = 'formattedTotalSum'

  static ormColsComponents = {
    pre_payment_status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }

          const {
            partialPaid,
            fullPaid,
            cancelled,
            partialCancelled
          } = this.statuses

          return {
            id: item.id,
            [partialPaid.value]: {
              text: partialPaid.text,
              value: partialPaid.value,
              appearance: {
                color: 'rgba(67, 204, 180, 0.08)',
                textColor: '#43CCB4'
              }
            },
            [fullPaid.value]: {
              text: fullPaid.text,
              value: fullPaid.value,
              appearance: {
                color: '#161b25',
                textColor: '#ffffff'
              }
            },
            [cancelled.value]: {
              text: cancelled.text,
              value: cancelled.value,
              appearance: {
                color: 'rgba(255, 23, 68, 0.08)',
                textColor: '#FF1744'
              }
            },
            [partialCancelled.value]: {
              text: cancelled.text,
              value: cancelled.value,
              appearance: {
                color: 'rgba(210,130,27,0.08)',
                textColor: '#d2821b'
              }
            }
          }
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'created_at',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      default: () => {
        const date = new Date()
        date.setMonth(date.getMonth() - 1)
        return [this.$moment(date).format(formats.dateISO8601), this.$moment(new Date()).format(formats.dateISO8601)]
      },
      attrs: {
        closeOnClick: false,
        clearable: false,
        emmitOnlyOnSelectClicked: true,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Date created',
        min: (dates) => {
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() - 2)
            return this.$moment(date).format(formats.dateISO8601)
          }
        },
        max: (dates) => {
          const today = new Date()
          const formattedToday = this.$moment(today).format(formats.dateISO8601)
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() + 2)
            if (date.getTime() > today.getTime()) {
              return formattedToday
            }
            return this.$moment(date).format(formats.dateISO8601)
          }
          return formattedToday
        },
        fastDates: true,
        filterFastDates: (dates) => {
          return dates.filter((item) => {
            const val = item.value
            return val !== 'firstQuarter' && val !== 'secondQuarter' && val !== 'thirdQuarter' && val !== 'fourthQuarter' && val !== 'currentQuarter' && val !== 'currentYear'
          })
        }
      }
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search by order number',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    },
    new AutocompleteBuilder({
      model: 'cashRegister',
      label: 'Cash register ',
      autoWidth: true,
      itemClass: 'ws-pre',
      hideDetails: true,
      emitSearch: false,
      emmitEmptyObject: true,
      default: () => {
        return get(AuthenticatedCashier.query().first(), 'cashRegister')
      },
      query: model => model.api()
    }, CashRegistersSearch).build()
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 4,
                md: 4
              },
              fields: [
                'search'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 4,
                md: 4
              },
              fields: [
                'created_at'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 4,
                md: 4
              },
              fields: [
                'cashRegister'
              ]
            }
          ]
        }
      ]
    }
  }

  static cashierToken = () => {
    const authenticatedCashier = AuthenticatedCashier.query().first()
    return get(authenticatedCashier, 'accessToken')
  }

  static ormActions = [
    {
      name: 'makePostpayment',
      text: 'Make postpayment',
      icon: {
        type: 'e-svg-icon',
        text: 'two-coins'
      },
      visible: item => item.pre_payment_status === Prepayment.statuses.partialPaid.value,
      call: async (item, data, ctx) => {
        const { receiptHtml, resultReceipt } = await Dialog.query().where('type', 'content_top').first().open({
          title: 'Postpayment',
          component: 'block-prepayment-payment',
          width: '464px',
          componentProps: {
            item
          }
        })
        await ctx.processingApiRequest({
          request: token => Prepayment.api().getPrepaymentsReceiptsById(item.relation_id, token)
        })
        if (!receiptHtml || !resultReceipt) {
          return
        }
        const contentDialogTop = Dialog.query().where('type', 'content_top')
        await contentDialogTop.first().open({
          component: 'block-receipt-view',
          width: '470px',
          contentFullHeight: true,
          componentProps: {
            model: Receipts,
            view: receiptHtml,
            item: resultReceipt,
            config: {
              view: 'html',
              download: 'pdf',
              downloadNameField: 'fiscal_code',
              downloadPrefix: 'receipt',
              showActionButtons: false
            }
          }
        })
      }
    },
    {
      name: 'viewReceipts',
      text: 'View receipts',
      icon: {
        text: 'read',
        type: 'e-svg-icon'
      },
      call: async (item, data, ctx) => {
        const receiptsChain = get(
          await ctx.processingApiRequest({
            request: token => Prepayment.api().getPrepaymentsReceiptsById(item.relation_id, token)
          }), 'response.data.receipts_chain', {})
        let allReceipts
        const prepaymentReceiptsOrdered = reverse(get(receiptsChain, 'pre_payment_receipts', []))
        if (!isEmpty(get(receiptsChain, 'after_payment_receipt', {}))) {
          allReceipts = concat(prepaymentReceiptsOrdered, get(receiptsChain, 'after_payment_receipt', {}))
        } else {
          allReceipts = prepaymentReceiptsOrdered
        }
        const receiptIds = map(allReceipts, receipt => receipt.id)
        await Dialog.query().where('type', 'content_top').first().open({
          component: 'block-receipts-chain-view',
          width: 483 + 'px',
          contentFullHeight: true,
          componentProps: {
            receiptIds,
            token: Prepayment.cashierToken()
          }
        })
      }
    },
    {
      name: 'refundReceipt',
      text: 'Refund receipt',
      icon: {
        type: 'e-svg-icon',
        text: 'selling-2'
      },
      visible: item => item.pre_payment_status !== Prepayment.statuses.cancelled.value,
      call: async (item, data, ctx) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: 'Are you sure you want to refund prepayment receipt?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              const receipts = get(
                await ctx.processingApiRequest({
                  request: token => Prepayment.api().returnPrepaymentReceipts(item.relation_id, {}, token)
                }), 'response.data', null)
              await ctx.processingApiRequest({
                request: token => Prepayment.api().getPrepaymentsReceiptsById(item.relation_id, token)
              })
              const receiptIds = map(receipts, receipt => receipt.id)
              await Dialog.query().where('type', 'content_top').first().open({
                component: 'block-receipts-chain-view',
                width: 483 + 'px',
                contentFullHeight: true,
                componentProps: {
                  receiptIds,
                  isReturn: true,
                  token: Prepayment.cashierToken()
                }
              })
              Prepayment.update({
                where: item?.id,
                data: {
                  pre_payment_status: Prepayment.statuses.cancelled.value
                }
              })
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.getPrepaymentsReceiptsById = function (id, token) {
        return this.get(Model.$routes.prepayment.createPostpaymentReceipt(id), {
          headers: { 'X-Processing-Authorization': token }
        })
      }

      configActions.createPrepaymentReceipt = function (payload, token) {
        return this.post(Model.$routes.prepayment.createPrepaymentReceipt(), payload, {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.createPostpaymentReceipt = function (id, payload, token) {
        return this.post(Model.$routes.prepayment.createPostpaymentReceipt(id), payload, {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      configActions.returnPrepaymentReceipts = function (id, payload, token) {
        return this.post(Model.$routes.prepayment.returnPrepaymentReceipts(id), payload, {
          save: false,
          headers: { 'X-Processing-Authorization': token }
        })
      }
      return configActions
    }
  }
}

export default Prepayment
