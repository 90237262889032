import { render, staticRenderFns } from "./block-qr-hint-content.vue?vue&type=template&id=6ac9126c&scoped=true&lang=pug&"
import script from "./block-qr-hint-content.vue?vue&type=script&lang=js&"
export * from "./block-qr-hint-content.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ac9126c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
