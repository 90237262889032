import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import CashRegisterOrder from '~/models/directories/Orders/CashRegisterOrder'
import TimestampAble from '~/models/mixins/TimestampAble'
import Dialog from '~/models/system/Dialog'

export class PromoCashRegisters extends PersistAble(Directory, [
  TimestampAble
]) {
  static entity = 'promocashregisters'
  static paginated = true
  static ormLoadWithRelations = true

  static ormTrans = {
    single: 'Promotional cash register',
    multy: 'Promotional cash registers',
    notification: 'Promotional cash register'
  }

  static itemModes = {
    rro: {
      text: 'RRO',
      value: 'rro'
    },
    promotional: {
      text: 'Promotional',
      value: 'promotional'
    },
    orderCreated: {
      text: 'Order created',
      value: 'orderCreated'
    },
    sentToDps: {
      text: 'On registration at the DPS',
      value: 'sentToDps'
    }
  }

  static checkTaxOrdersStatus (item) {
    const sortedTaxOrders = this._.sortBy(this._.get(item, 'cashRegisterTaxOrders', []), order => new Date(order.dateSend).getTime())
    const lastTaxOrder = this._.last(sortedTaxOrders)
    const orderCreated = this._.get(lastTaxOrder, 'type') === 100 && this._.get(lastTaxOrder, 'status') === 100 && !this._.get(item, 'cashRegister')
    const sentToDps = this._.get(lastTaxOrder, 'type') === 100 && this._.get(lastTaxOrder, 'status') === 200 && !this._.get(item, 'cashRegister')

    return {
      lastTaxOrder,
      orderCreated,
      sentToDps
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      active: this.attr(null),
      cashRegister: this.attr(null),
      disableDate: this.attr(null),
      fiscalNumber: this.attr(null),
      oroRegDate: this.attr(null),
      regDate: this.attr(null),
      registrationNumber: this.attr(null),
      usage: this.attr(null),
      usedInShare: this.attr(null),
      cashRegisterTaxOrders: this.attr(null)
    }
  }

  get modeString () {
    const {
      rro,
      promotional,
      orderCreated,
      sentToDps
    } = PromoCashRegisters.itemModes
    const orderStatus = PromoCashRegisters.checkTaxOrdersStatus(this)
    if (this._.get(orderStatus, orderCreated.value)) {
      return orderCreated.value
    } else if (this._.get(orderStatus, sentToDps.value)) {
      return sentToDps.value
    } else if (this.cashRegister) {
      return promotional.value
    }
    return rro.value
  }

  get numberString () {
    return this._.get(this, 'cashRegister.number') || '–'
  }

  get licenseKeyString () {
    return this._.get(this, 'cashRegister.licenseKey') || '–'
  }

  get outletString () {
    return this._.get(this, 'cashRegister.outlet.name') || '-'
  }

  get addressString () {
    return this._.get(this, 'cashRegister.address') || '-'
  }

  static ormHeaders = [
    { text: 'Status', value: 'modeString', align: 'left', width: '150', sortable: true, filterable: true },
    { text: 'Fiscal number', value: 'fiscalNumber', align: 'left', filterable: true, sortable: false },
    { text: 'Cash register number', value: 'numberString', align: 'left', sortable: true, filterable: false },
    { text: 'License key', value: 'licenseKeyString', align: 'left', filterable: true, sortable: false },
    { text: 'Outlet name', value: 'outletString', sortable: false, filterable: false },
    { text: 'Registration address', value: 'addressString', width: '400', sortable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false }
  ]

  static ormMobileTitle = 'fiscalNumber'

  static ormRowsConfig = {
    disabled: false
  }

  static ormColsComponents = {
    licenseKey: {
      component: 'e-copy-text'
    },
    modeString: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }
          const {
            rro,
            promotional,
            orderCreated,
            sentToDps
          } = this.itemModes

          return {
            id: item.id,
            model: CashRegisterOrder,
            [rro.value]: {
              text: rro.text,
              type: rro.value,
              appearance: {
                color: '#161B25',
                textColor: '#fff'
              }
            },
            [promotional.value]: {
              text: promotional.text,
              type: promotional.value,
              appearance: {
                color: '#04aed5',
                textColor: '#fff'
              }
            },
            [orderCreated.value]: {
              text: orderCreated.text,
              type: orderCreated.value,
              tooltip: 'The application has been created and is waiting to be sent to the DPS',
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            },
            [sentToDps.value]: {
              text: sentToDps.text,
              type: sentToDps.value,
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            }
          }
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = []

  static ormActions = [
    {
      name: 'sendToDps',
      text: 'Send to sfs',
      visible: (item) => {
        const orderStatus = PromoCashRegisters.checkTaxOrdersStatus(item)
        return orderStatus?.orderCreated
      },
      icon: {
        type: 'e-svg-icon',
        text: 'send'
      },
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        const orderStatus = PromoCashRegisters.checkTaxOrdersStatus(item)
        await confirmationDialog.open({
          title: 'Confirm sending the application for creating a cash register to the DPS',
          onConfirm: async (ctx) => {
            await ctx.sendEntityTaxOrder({
              type: 'sendToDps',
              entity: item,
              orderModel: CashRegisterOrder,
              model: PromoCashRegisters,
              order: orderStatus?.lastTaxOrder
            })
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}

export default PromoCashRegisters
