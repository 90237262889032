import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'
import AppNotifications from '~/services/Notifications/AppNotifications'
import Dialog from '~/models/system/Dialog'
import i18n from '~/plugins/nuxt-i18n/i18n'

class TaxService extends Model {
  static entity = 'taxservice';

  static async edsKeyVerify ({ keyData, fallbackMethod, dialogType = 'edsIitCheckboxForOrganization' } = {}) {
    const modelInstance = EdsIitCheckbox.query().where('type', dialogType).first()
    const modelInterface = modelInstance?.getInterface()
    const isDiia = modelInterface?.isDiia
    const isSmartId = modelInterface?.isSmartId
    if (!keyData || !modelInstance?.sign || isDiia || isSmartId) {
      return true
    }
    const edsKeyData = new EdsKeyData([keyData])
    const EDRPOUCode = get(edsKeyData, 'edrpouCode', '')
    const DRFOCode = get(edsKeyData, 'drfoCode', '')
    const dataToSign = EDRPOUCode || DRFOCode
    if (!dataToSign) {
      return true
    }
    try {
      const signedData = await modelInstance.sign(dataToSign, {
        title: i18n.t('Data signature for key verification in STS'),
        fallbackMethod
      })
      if (!signedData) {
        return true
      }
      const payload = {
        EDRPOUCode,
        DRFOCode,
        sign: signedData
      }
      const res = this._.get(await TaxService.api().edsKeyVerify(payload), 'response.data', [])
      const keyDataPublicKeyId = (edsKeyData.publicKeyId || '').toLowerCase().replace(/\s/g, '')
      const publicIdKeyExists = this._.some(res, i => i?.publicKeyId === keyDataPublicKeyId)
      if (!publicIdKeyExists) {
        const contentDialogTop = Dialog.query().where('type', 'content_top').first()
        const confirmed = await contentDialogTop.open({
          component: 'block-key-verification-warning',
          width: '500px',
          hideCloseButton: true
        })
        if (!confirmed) {
          // Close edsIitDialog and delete plugin instance
          await modelInstance.delete()
          const edsIitCheckboxDialog = Dialog.query().where('type', 'edsIitCheckboxDialog').first()
          if (edsIitCheckboxDialog) {
            await edsIitCheckboxDialog.close()
          }

          // Restart method
          if (typeof fallbackMethod === 'function') {
            await fallbackMethod()
          }
          return false
        } else {
          return true
        }
      }
      return true
    } catch (e) {
      AppNotifications.error(e)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.edsKeyVerify = function (payload) {
        return this.post(Model.$routes.taxservice.edsKeyVerify(), payload, { save: false })
      }

      return configActions
    }
  }
}

export default TaxService
