import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class Reports extends ChainInheritance(Directory, []) {
  static entity = 'reports'

  static fields () {
    return {
      id: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.processingCreateXReport = function (token) {
        return this.post(Model.$routes.reports.processingCreateXReport(), {},
          {
            save: false,
            headers: { 'X-Processing-Authorization': token }
          }
        )
      }
      configActions.processingGetReport = function (id) {
        return this.get(Model.$routes.reports.processingRead(id), { save: false })
      }
      configActions.processingGetReportText = function (id) {
        return this.get(Model.$routes.reports.processingReadAsText(id), { save: false })
      }
      configActions.getGroups = function () {
        return this.get(Model.$routes.reports.groups(), { save: false })
      }
      configActions.addGroup = function (payload) {
        return this.post(Model.$routes.reports.groups(), payload, { save: false })
      }
      configActions.editGroup = function (id, payload) {
        return this.put(Model.$routes.reports.concreteGroup(id), payload, { save: false })
      }
      configActions.deleteGroup = function (id) {
        return this.delete(Model.$routes.reports.concreteGroup(id), { save: false })
      }
      return configActions
    }
  }
}

export default Reports
