<template lang="pug">
  v-alert(
    v-model="showNotification"
    variant="tonal"
    :type="type"
    class="block-notification-alert"
  )
    h4 {{ _.get(lastNotification, 'title') }}
    p(
      class="mb-0"
      v-dompurify-html="_.get(lastNotification, 'message')"
    )
    v-btn(
      @click="close"
      class="block-notification-alert__close"
      icon
      small
    )
      e-svg-icon(size="xmd") decline-2
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Notifications from '~/modules/notifications/models/Notifications'

export default {
  name: 'BlockNotificationAlert',
  components: {
    ESvgIcon
  },
  data: () => ({
    showAlert: false,
    lastNotificationId: null
  }),
  computed: {
    model () {
      return Notifications
    },
    lastNotification () {
      return this.model.query().whereId(this.lastNotificationId).first()
    },
    showNotification () {
      return this.showAlert && this.lastNotification && !this._.get(this.lastNotification, 'viewed')
    },
    type () {
      const priority = this._.get(this.lastNotification, 'priority')
      if (priority === this.model.priorities.high.value) {
        return 'error'
      } else if (priority === this.model.priorities.middle.value) {
        return 'warning'
      } else {
        return 'info'
      }
    }
  },
  async created () {
    await this.getNotifications()
  },
  methods: {
    async close () {
      try {
        this.showAlert = false
        await this.model.api().readNotification(this._.get(this.lastNotification, 'id'))
        await this.$root.$emit('notificationRead', this.lastNotification)
        this.$setLocalStorageClientData({
          lastNotificationAlert: this.lastNotification
        })
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async getNotifications () {
      if (this.$User.isSuperAdmin) {
        return
      }
      try {
        const res = this._.get(await this.model.api().filter({
          limit: 1,
          offset: 1,
          only_new: 1
        }).all({}, {
          route: this.model.$routes[this.model.entity].userList()
        }), 'response.data')
        this.lastNotificationId = this._.get(res, 'data[0].id', null)
        const priority = this._.get(this.lastNotification, 'priority')
        if (this.lastNotification && priority !== this.model.priorities.low.value) {
          const lastNotificationAlertDate = this._.get(this.$localStorageClientData(), 'lastNotificationAlert.date')
          if (lastNotificationAlertDate) {
            const lastNotificationAlertDateTime = new Date(lastNotificationAlertDate).getTime()
            const lastNotificationDateTime = new Date(this._.get(this.lastNotification, 'date')).getTime()
            this.showAlert = lastNotificationAlertDateTime < lastNotificationDateTime
          } else {
            this.showAlert = true
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
.block-notification-alert {
  &.v-alert {
    border-radius: 10px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    padding-right: 35px;

    &.warning {
      background-color: $color-warning !important;
      border-color: $color-warning !important;
    }

    &.error {
      background-color: $color-error !important;
      border-color: $color-error !important;
    }

    &.info {
      background-color: rgba(0, 0, 0, 0.15) !important;
      border-color: rgba(0, 0, 0, 0.15) !important;
      color: #161b25;

      .v-icon {
        color: #161b25;
      }
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
