<template lang="pug">
  div(class="h-100")
    v-expansion-panels(v-if="mobile")
      v-expansion-panel(class="hints-block-mobile")
        v-expansion-panel-header {{ title }}
        v-expansion-panel-content
          block-employee-form-hint-content(
            :steps="steps"
            :current-step="currentStep"
            mobile
          )
    div(
      class="hints-block"
      v-else
    )
      h3(class="hints-block__title") {{ title }}
      block-employee-form-hint-content(
        :steps="steps"
        :current-step="currentStep"
      )
</template>

<script>
import BlockEmployeeFormHintContent from '~/modules/employees/views/components/block-employee-form-hint-content'
import employeeCreating from '~/modules/employees/mixins/employeeCreating'

export default {
  name: 'BlockEmployeeFormHint',
  components: {
    BlockEmployeeFormHintContent
  },
  mixins: [employeeCreating],
  props: {
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return `💡 ${this.$t('Hint')}`
    }
  }
}
</script>

<style scoped lang="scss">

</style>
