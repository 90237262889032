const searching = {
  data: _ => ({
    debouncedSearchString: null
  }),
  computed: {
    $searching () {
      if (!this.model.paginated) {
        return {
          search: this.searchString
        }
      }
    },
    searchString () {
      return this._.get(this.tableFilters, 'search', null) || this._.get(this.filters, 'search', null) || ''
    }
  },
  watch: {
    searchString () {
      if (this.model.paginated) {
        this.debouncedFilter()
      }
    }
  },
  created () {
    if (this.model.paginated) {
      this.debouncedFilter = this._.debounce(this.getSearchString, 800)
    }
  },
  methods: {
    getSearchString () {
      this.debouncedSearchString = this.searchString && this.searchString.trim()
    }
  }
}

export default searching
