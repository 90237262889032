<template lang="pug">
  div(class="rozetkapay-client-documents")
    div(class="mb-8")
      p(class="rozetkapay-client-documents__label") {{ $t('Type of passport') }}
      div(class="rozetkapay-client-documents__cards-block")
        div(
          class="rozetkapay-client-documents__card"
          :class="{ 'rozetkapay-client-documents__card--active': isBookletPassportSelected }"
          @click="changePassportType(passportTypes.booklet)"
        )
          p {{ $t('Passport booklet') }}
          img(
            class="rozetkapay-client-documents__card-img"
            src="~/assets/images/ukrainian-passport.png"
            srcset="~/assets/images/ukrainian-passport.png 1x, ~/assets/images/ukrainian-passport@2x.png 2x"
          )
        div(
          class="rozetkapay-client-documents__card"
          :class="{ 'rozetkapay-client-documents__card--active': passportType === passportTypes.idCard }"
          @click="changePassportType(passportTypes.idCard)"
        )
          p {{ $t('ID card') }}
          img(
            class="rozetkapay-client-documents__card-img"
            src="~/assets/images/ukrainian-id-card.png"
            srcset="~/assets/images/ukrainian-id-card.png 1x, ~/assets/images/ukrainian-id-card@2x.png 2x"
          )
    v-radio-group(
      v-if="isBookletPassportSelected"
      v-model="hasItn"
      v-bind="fields.hasItn"
      @change="handleItnChange"
    )
      v-radio(:label="$t('Yes')" :value="true" :ripple="false")
      v-radio(:label="$t('No')" :value="false" :ripple="false")
    div()
      m-block-uploader(
        v-for="(item, idx) in requiredDocuments"
        v-if="item.visible"
        :key="idx"
        v-bind="item"
        :validation-field-name="item.title"
        :files.sync="files[item.ref]"
      )
    ValidationProvider(
      v-if="showTerms"
      ref="termsRef"
      vid="terms"
      name=" "
      mode="lazy"
      rules="is:1"
      v-slot="{ errors }"
      class="mt-3"
      slim
    )
      v-checkbox(
        v-model="terms"
        :label="$t('I give my consent to the processing and transfer of personal data, including IE data and the name of the product, to FC \"EVO\" LLC (RozetkaPay)')"
        true-value="1"
        false-value="0"
        :error-messages="errors"
        :ripple="false"
      )
</template>

<script>
import MBlockUploader from '~/components/modules/blocks/concrete/m-block-uploader'

export default {
  name: 'BlockRozetkapayClientDocuments',
  components: {
    MBlockUploader
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    showTerms: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    hasItn: true,
    terms: '0',
    passportType: 'booklet',
    files: {
      passportCopy: [],
      registrationCopy: [],
      itnCopy: [],
      licenses: []
    },
    errors: {
      passportCopy: [],
      registrationCopy: [],
      itnCopy: [],
      licenses: []
    }
  }),
  computed: {
    passportTypes () {
      return {
        booklet: 'booklet',
        idCard: 'idCard'
      }
    },
    isBookletPassportSelected () {
      return this.passportType === this.passportTypes.booklet
    },
    fields () {
      return {
        hasItn: {
          class: 'rozetkapay-client-documents__radio-group mb-7',
          label: this.$t('I have RNOCPP (IPN)'),
          row: true,
          hideDetails: true
        }
      }
    },
    accept () {
      return 'image/*,.docx,.doc,.pdf'
    },
    showLicensesField () {
      return Boolean(this._.get(this.order, 'personData.company_details.license_numbers.length'))
    },
    requiredDocuments () {
      return [
        {
          ...this.commonDocumentAttrs('passportCopy'),
          icon: 'passport',
          title: this.$t(this.isBookletPassportSelected ? 'A copy of all completed pages of the passport' : 'A copy of both sides of the passport'),
          multiple: true
        },
        {
          ...this.commonDocumentAttrs('registrationCopy'),
          icon: 'file-text-dark',
          title: this.$t('Extract from the EDDR regarding the place of registration')
        },
        {
          ...this.commonDocumentAttrs('itnCopy'),
          visible: this.hasItn && this.isBookletPassportSelected,
          icon: 'clipboard-list',
          title: this.$t('A copy of the RNOCPP (IPN)')
        },
        {
          ...this.commonDocumentAttrs('licenses'),
          visible: this.showLicensesField,
          icon: 'file-text-dark',
          title: this.$t('Copy of license/permit'),
          multiple: true
        }
      ]
    }
  },
  methods: {
    changePassportType (type) {
      this.passportType = type
    },
    handleItnChange (val) {
      if (!val) {
        this.files.itnCopy = []
        this.errors.itnCopy = []
      }
    },
    commonDocumentAttrs (name) {
      return {
        accept: this.accept,
        btnText: 'Select',
        visible: true,
        ref: name,
        inputErrors: this.errors[name],
        validationMode: 'passive',
        rules: 'required|min_size:1|size:10000|ext:jpg,jpeg,png,docx,doc,pdf'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.rozetkapay-client-documents {
  &__label {
    font-size: 13px;
    font-weight: 300;
    color: $btn-secondary-color;
    margin-bottom: 16px;
  }

  &__radio-group {
    margin: 0;

    &::v-deep {
      .v-input--radio-group__input {
        flex-wrap: wrap;

        & > .v-label {
          width: 100%;
          font-weight: 300;
          margin-bottom: 14px;
          color: $btn-secondary-color;
          height: auto;
          padding: 0;
        }
      }
    }
  }

  &__cards-block {
    display: flex;
    gap: 16px 32px;
    align-items: stretch;
    flex-wrap: wrap;

    @media (min-width: map-get($breakpoints, 'md')) {
      flex-wrap: nowrap;
    }
  }

  &__card {
    padding: 16px;
    border-radius: 16px;
    display: flex;
    color: $btn-secondary-color;
    font-size: 14px;
    font-weight: 700;
    background: rgba(0, 0, 0, 0.03);
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 16px;
    cursor: pointer;
    border: 2px solid transparent;

    p {
      margin: 0;
    }

    &--active {
      background: none;
      border: 2px solid $btn-secondary-color;
    }
  }

  &__card-img {
    height: 40px;
  }

  &::v-deep {
    .uploader {
      align-items: center;
      flex-wrap: wrap;

      @media (min-width: map-get($breakpoints, 'md')) {
        flex-wrap: nowrap;
      }

      .d-flex {
        align-items: center;
      }
    }
  }
}
</style>
