import { Model } from '@vuex-orm/core'
import Search from '~/models/abstracts/Search'

export class OutletsSearch extends Search {
  static entity = 'outletssearch'
  static defaultSortParam = 'name'

  static fields () {
    return {
      id: this.attr(null),
      address: this.attr(null),
      name: this.attr(null),
      reqStreet: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Outlet',
    multy: 'Outlets',
    notificationSingle: 'Outlet '
  }

  static ormHeaders = [
    { text: 'Name', value: 'name', width: '250', sortable: false, filterable: false },
    { text: 'Address', value: 'address', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      classes: ['ml-2'],
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      }
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'space-between'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                md: 4
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  toString () {
    let address = this.address && this.address.toString().slice()

    if (this.address && this.address.toString().toLowerCase().startsWith('україна')) {
      address = address.replace('УКРАЇНА, ', '')
    }

    return (this.name ? `${this.name}, ` : '') + (address || this.reqStreet)
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.listMultiAccount = function (payload, options = {}) {
        return this.post(Model.$routes.outletssearch.listMultiAccount(), payload, { dataKey: 'data', ...options })
      }
      return configActions
    }
  }
}

export default OutletsSearch
