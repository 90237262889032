<template lang="pug">
  div(class="dialog")
    div(class="dialog__title pb-0 mb-8") {{ $t('Signed refund document') }}
    div(
      v-if="loading"
      class="dialog__loader"
    )
      e-progress-circular(
        width="4"
        size="xl"
        color="#161b25"
      )
    div(v-else)
      embed(
        :src="`data:application/pdf;base64,${pdf}#toolbar=0&navpanes=0&scrollbar=0`"
        class="dialog__pdf"
      )
</template>

<script>
import Refunds from '~/modules/refunds/models/Refunds'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  name: 'BlockApplicationPdfView',
  components: {
    EProgressCircular
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    pdf: null
  }),
  computed: {
    model () {
      return Refunds
    }
  },
  async created () {
    await this.getPdf()
  },
  methods: {
    async getPdf () {
      try {
        this.loading = true
        this.pdf = this._.get(await this.model.api().getSignedFile(this._.get(this.item, 'id')), 'response.data.pdf')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__pdf {
    border: 2px solid #F0F1F2;
    width: 100%;
    height: 600px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
  }
}
</style>
