<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ dialogTitle }}!
    div(
      v-if="loading"
      class="pt-3 pb-6"
    )
      e-process-progress(
        :value="processedItemsValue"
        :current="processedItems"
        :total="totalItems"
      )
    div(v-else)
      div {{ $t('Are you sure to delete all goods') }}
      div(class="dialog__actions")
        v-btn(
          @click="confirm"
          class="secondary-button color-error"
          :loading="loading"
        ) {{ $t('Approve') }}
        v-btn(
          @click="close"
          class="main-button"
          outlined
        ) {{ $t('Dismiss') }}
</template>

<script>
import Download from 'js-file-download'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import EProcessProgress from '~/components/elements/progress/e-process-progress'
import wait from '~/mixins/methods/wait'

export default {
  name: 'BlockAllGoodsDeleting',
  components: {
    EProcessProgress
  },
  mixins: [wait],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    onSuccess: {
      type: Function,
      default: () => {}
    },
    useBranches: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    request: null,
    currentTitleProcess: null
  }),
  computed: {
    model () {
      return AccountingGoods
    },
    processedItemsValue () {
      return Math.round(this.processedItems / (this.totalItems / 100)) || 0
    },
    processedItems () {
      return this._.get(this.request, 'response.response.data.processed_items', 0)
    },
    totalItems () {
      return this._.get(this.request, 'response.response.data.total_items', 0)
    },
    dialogTitle () {
      switch (this.currentTitleProcess) {
        case 'backup':
          return this.$t('Backing up goods')
        case 'deletion':
          return this.$t('Deleting all goods')
        default:
          return this.$t('Warning')
      }
    }
  },
  beforeDestroy () {
    if (this.request) {
      this.request.stopExponential()
    }
  },
  methods: {
    async close () {
      await this.closeModal()
    },
    async confirm () {
      try {
        this.loading = true
        this.currentTitleProcess = 'backup'
        await this.exportGoods('excel')
        this.currentTitleProcess = 'deletion'
        await this.deleteAllGoods()
        await this.close()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async deleteAllGoods () {
      try {
        const taskId = this._.get(await this.model.api().deleteAll(), 'response.data.task_id', null)
        const intervalRequest = new IntervalRequest(() => this.model.api().deleteAllStatus(taskId), {
          maxDelay: 3e5 // ~5 minutes
        })
        this.request = intervalRequest
        const resolveStatus = 'done'
        const resolveCondition = ({ response }) => (response.data.status === resolveStatus)
        const resolveResponse = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data', null)
        const deletedCount = this._.get(resolveResponse, 'processed_items', null)
        await this.wait(2000)
        if (this._.get(resolveResponse, 'status') === resolveStatus) {
          await this.onSuccess()
          this.$notification.success(this.$t('The number of deleted goods is ') + deletedCount)
        }
        await this.close()
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    checkStatus (id) {
      const resolveStatus = 'done'
      const request = new IntervalRequest(() => this.model.api().checkExportStatus(id), {
        interval: 2e3,
        count: 300,
        maxDelay: 18e5 // ~30 minutes
      })
      this.request = request
      return request.startExponential(response => this._.get(response, 'response.data.status', null) === resolveStatus)
    },
    async exportGoods (type) {
      try {
        const action = `export${this._.capitalize(type)}`
        const taskId = this._.get(await this.model.api()[action]({ useBranches: this.useBranches }), 'response.data.task_id', null)
        const resolved = this._.get(await this.checkStatus(taskId), 'response.data', null)

        if (resolved) {
          const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
          const blob = this._.get(await this.model.api().exportFile(taskId, {
            encoding: isMacLike ? 'utf8' : 'windows-1251'
          }), 'response.data', null)
          Download(blob, 'goods.xlsx')
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    justify-content: center;
    gap: 16px;
    display: flex;
    margin-top: 20px;
  }
}
</style>
