<template lang="pug">
  div(class="a-bank-second-acquiring-warning")
    h3(class="a-bank-second-acquiring-warning__title") {{ $t('You already have a signed contract, are you sure you want to sign another one?') }}
    div(class="a-bank-second-acquiring-warning__actions")
      v-btn(class="secondary-button a-bank-second-acquiring-warning__action" outlined @click="closeModal") {{ $t('No') }}
      v-btn(class="main-button a-bank-second-acquiring-warning__action" @click="agree") {{ $t('Yes') }}
</template>

<script>
export default {
  name: 'BlockABankSecondAcquiringWarning',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    steps: {
      type: Object,
      default: () => {}
    },
    changeTitle: {
      type: Function,
      default: () => {}
    },
    changeStep: {
      type: Function,
      default: () => {}
    }
  },
  created () {
    this.changeTitle('')
    this.toggleCloseBtn(false)
  },
  methods: {
    agree () {
      this.changeStep(this.steps.acquiringOrder)
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-second-acquiring-warning {
  text-align: center;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 18px;
    }
  }

  &__action {
    min-width: 120px !important;
  }

  &__actions {
    margin-top: 32px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}
</style>
