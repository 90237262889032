<template lang="pug">
  div(class="dialog")
    div(class="dialog__title pb-7")
      div(:class="{ 'text-center': !xsDevice }") {{ title }}
    div
      ValidationObserver(
        ref="form"
        v-if="showSelectItemsBlock"
        slim
      )
        v-row(no-gutters)
          v-col(
            cols="12"
            v-if="!_.get($User, 'isEmployee')"
          )
            e-input-wrapper(
              :item="selectEmployeeConfig"
              v-model="employee"
            )
          v-col(cols="12")
            e-input-wrapper(
              :item="selectCashRegisterConfig"
              v-model="cashRegister"
            )
      v-row(v-else-if="showSelectSignTypeBlock")
        v-col(cols="12")
          v-radio-group(
            class="mt-0"
            v-model="signType"
            hide-details
          )
            v-radio(
              :label="$t('Launch Checkbox Signature on a secure cloud service')"
              value="cloud_sign"
              color="black"
              :ripple="false"
            )
            v-radio(
              :label="$t('Load EDS key in browser')"
              value="eds_key"
              color="black"
              :ripple="false"
            )
            v-radio(
              :label="$t('I run the Checkbox Signature software locally')"
              value="agent"
              color="black"
              :ripple="false"
            )
    div(class="dialog__actions")
      v-btn(
        class="main-button"
        :block="xsDevice"
        :loading="checkLoading"
        @click="selectItemsForLogin"
        v-if="showSelectItemsBlock"
      )
        | {{$t('Select')}}
      v-btn(
        class="main-button"
        :block="xsDevice"
        @click="autByType"
        v-else-if="showSelectSignTypeBlock"
      )
        | {{$t('Select')}}

</template>

<script>
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import EInputWrapper from '~/components/elements/inputs/e-input-wrapper'
import TDialog from '~/components/templates/t-dialog'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

import setAuthenticatedCashier from '~/modules/receipt/mixins/setters/setAuthenticatedCashier'
import processingCheckAgent from '~/modules/receipt/mixins/actions/processingCheckAgent'
import _commonData from '~/modules/receipt/mixins/getters/commonData'
import responsive from '~/mixins/pages/responsive'

import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
import CashRegisters from '~/models/directories/CashRegisters'
import Employees from '~/modules/employees/models/Employees'

import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'

export default {
  name: 'BlockProcessingEmployeeLogin',
  components: {
    EAutocomplete,
    EInputWrapper,
    TDialog,
    TOrmButtons
  },
  mixins: [
    setAuthenticatedCashier,
    processingCheckAgent,
    _commonData,
    responsive
  ],
  props: {
    onResolved: {
      type: Function,
      default: () => {}
    },
    onInput: {
      type: Function,
      default: () => {}
    },
    // Need only for monobank orders
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => (
    {
      checkLoading: false,
      signAgentLaunched: false,
      employee: null,
      cashRegister: null,
      selected: false,
      signType: 'cloud_sign'
    }
  ),
  computed: {
    title () {
      return this.showSelectItemsBlock ? this.$t('Please login as cashier') : this.$t('Select type of sign receipt')
    },
    selectCashRegisterConfig () {
      return new AutocompleteBuilder({
        model: 'cashRegister',
        label: 'Select cash register',
        rules: 'required',
        itemClass: 'ws-pre',
        query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
        asyncDefault: () => {
          const cashRegister = this._.get(this.item, 'cashRegister', null)
          if (cashRegister) {
            CashRegistersSearch.insert({
              data: { id: cashRegister.id, number: cashRegister.number, fiscalNumber: cashRegister.fiscalNumber, outlet: cashRegister.outlet }
            })
            const index = CashRegistersSearch.all().findIndex(item => item.id === cashRegister.id)
            return index >= 0 ? CashRegistersSearch.all()[index] : null
          }
        }
      }, CashRegistersSearch).build()
    },
    selectEmployeeConfig () {
      return new AutocompleteBuilder({
        model: 'employee',
        label: 'Select employee',
        rules: 'required',
        query: model => model.api().filter({ mode: Employees.itemModes.checkbox.mode }),
        asyncDefault: () => {
          const employee = this._.get(this.item, 'employee', null)
          if (employee) {
            EmployeesSearch.insert({
              data: { id: employee.id, name: employee.name, login: employee.login, publicKeyId: employee.publicKeyId }
            })
            const index = EmployeesSearch.all().findIndex(item => item.id === employee.id)
            return index >= 0 ? EmployeesSearch.all()[index] : null
          }
        }
      }, EmployeesSearch).build()
    },
    employeeForLogin () {
      if (this._.get(this.$User, 'isEmployee')) {
        return Employees.query().whereId(this.$User.relatedEmployee.id).first()
      }
      return EmployeesSearch.query().all().length === 1 ? EmployeesSearch.query().first() : null
    },
    cashRegisterForLogin () {
      return CashRegistersSearch.query().all().length === 1 ? CashRegistersSearch.query().first() : null
    },
    showSelectItemsBlock () {
      const employee = typeof this.employee !== 'string' && (this._.get(this.employee, 'id', null) || this._.get(this.employeeForLogin, 'id', null))
      const cashRegister = typeof this.cashRegister !== 'string' && (this._.get(this.cashRegister, 'id', null) || this._.get(this.cashRegisterForLogin, 'id', null))
      const oneEntity = !this.employee && !this.cashRegister && Boolean(this.employeeForLogin && this.cashRegisterForLogin)
      return oneEntity ? false : !(employee && cashRegister) || !this.selected
    },
    showSelectSignTypeBlock () {
      return !this.authenticatedCashier || !this.authenticatedCashier.saveSignChoice
    },
    buttons () {
      return [
        {
          text: 'Select',
          attrs: {
            class: 'main-button'
          },
          loading: this.itemLoading,
          call: this.select
        }
      ]
    }
  },
  methods: {
    close () {
      this.clear()
      if (this._.isFunction(this.onInput)) {
        this.onInput(false)
      }
      const closeModal = this._.get(this.$attrs, 'closeModal')
      if (this._.isFunction(closeModal)) {
        closeModal()
      }
    },
    clear () {
      this.employee = null
      this.cashRegister = null
      this.selected = false
      this.signType = 'agent'
    },
    resolve (isResolved, token) {
      if (this._.isFunction(this.onResolved)) {
        this.onResolved(isResolved, token)
      }
    },
    async selectItemsForLogin () {
      const cashier = (this._.isObject(this.employee) && this.employee) || (this._.isObject(this.employeeForLogin) && this.employeeForLogin)
      const cashRegister = (this._.isObject(this.cashRegister) && this.cashRegister) || (this._.isObject(this.cashRegisterForLogin) && this.cashRegisterForLogin)
      let cashierAccessToken = null
      try {
        this.checkLoading = true
        if (!await this.$refs.form.validate()) {
          return false
        }
        if (this._.get(cashier, 'signatureType') === 'CLOUD_SIGNATURE_3') {
          this.signAgentLaunched = true
        } else {
          const { signAgentLaunched, token } = await this.checkSignAgent(cashier)
          cashierAccessToken = token
          this.signAgentLaunched = signAgentLaunched
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.checkLoading = false
      }

      if (!this.showSelectSignTypeBlock || this.signAgentLaunched) {
        const payload = {
          cashRegister,
          cashier
        }
        if (this.signAgentLaunched) {
          payload.signType = 'agent'
        }
        await this.setAuthenticatedCashier(payload, this.authenticatedCashier)
        this.resolve(true, cashierAccessToken)
        this.close()
      }

      this.selected = true
    },
    async autByType () {
      const payload = {
        cashRegister: (this._.isObject(this.cashRegister) && this.cashRegister) || (this._.isObject(this.cashRegisterForLogin) && this.cashRegisterForLogin),
        cashier: (this._.isObject(this.employee) && this.employee) || (this._.isObject(this.employeeForLogin) && this.employeeForLogin),
        signType: this.signType
      }
      await this.setAuthenticatedCashier(payload, this.authenticatedCashier)

      if (this.signType === 'agent' && !this.signAgentLaunched) {
        this.$notification.info(this.$t('The signature agent for the selected cashier is not running. To work with the checkout, start the signature agent'))
      }

      this.resolve(true)
      this.close()
    }
  }

}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    display: flex;
    justify-content: center;

    .main-button {
      width: 100%;
      max-width: 180px;
    }
  }
}
</style>
