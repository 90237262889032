<template lang="pug">
  div(class="a-bank-connecting")
    div(
      v-if="infoLoading"
      class="a-bank-connecting__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    div(v-else)
      block-a-bank-preview-info(
        v-if="step === steps.aBankPreview"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
        :closeModal="closeModal"
      )
      block-a-bank-download-app(
        v-if="step === steps.downloadApp"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
      )
      block-a-bank-information-check(
        v-else-if="step === steps.checkInformation"
        :changeTitle="changeTitle"
        :changeStep="changeStep"
        :previousStep="previousStep"
        :steps="steps"
        :toggleCloseBtn="toggleCloseBtn"
        :model="model"
      )
      block-a-bank-questionnaire(
        v-else-if="step === steps.questionnaire"
        :changeTitle="changeTitle"
        :changeStep="changeStep"
        :steps="steps"
        ref="questionnaire"
      )
      block-a-bank-account-opening(
        v-else-if="step === steps.accountOpening"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
        :changeStep="changeStep"
        :steps="steps"
        :changeAccountOrder="changeAccountOrder"
        :changeAccountOrderError="changeAccountOrderError"
        :changeShowFooterActions="changeShowFooterActions"
        :changeAccountOrderBase64Pdf="changeAccountOrderBase64Pdf"
      )
      block-a-bank-account-application-loader(
        v-else-if="step === steps.accountApplicationLoader"
        :changeTitle="changeTitle"
        :showBankSelectionForm="showBankSelectionForm"
        :changeStep="changeStep"
        :steps="steps"
        :toggleCloseBtn="toggleCloseBtn"
        :showError="accountOrderError"
        :accountOrder="accountOrder"
        :closeModal="closeModal"
      )
      block-a-bank-second-acquiring-warning(
        v-else-if="step === steps.secondAcquiringWarning"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
        :changeStep="changeStep"
        :steps="steps"
      )
      block-a-bank-acquiring-order(
        v-else-if="step === steps.acquiringOrder"
        ref="acquiringOrderForm"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
      )
      block-a-bank-acquiring-order-pdf(
        v-else-if="step === steps.acquiringOrderPdf"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
        :changeStep="changeStep"
        :steps="steps"
        :acquiringOrderBase64Pdf="acquiringOrderBase64Pdf"
        :acquiringOrder="acquiringOrder"
        :tableRequest="tableRequest"
        :changeAcquiringOrderInfo="changeAcquiringOrderInfo"
        is-on-boarding-dialog-part
      )
      block-a-bank-acquiring-order-info(
        v-else-if="step === steps.acquiringOrderInfo"
        :info="acquiringOrderErrorInfo"
        :changeTitle="changeTitle"
        :toggleCloseBtn="toggleCloseBtn"
      )
      div(
        v-if="showActions"
        class="a-bank-connecting__actions"
      )
        v-btn(
          v-if="showGoBackAction"
          class="a-bank-connecting__action"
          :class="{ 'mr-4': !isResponsive, 'mb-3 w-100': isResponsive }"
          outlined
          color="#161B25"
          @click="goBack"
        ) {{ $t(goBackButtonText) }}
        v-btn(
          class="main-button a-bank-connecting__action"
          :class="{ 'w-100': isResponsive }"
          @click="handleClick"
          :loading="loading"
        ) {{ $t(buttonText) }}
</template>

<script>
import Base64js from 'base64-js'
import responsive from '~/mixins/pages/responsive'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import BlockABankInformationCheck from '~/modules/acquiring/views/a-bank/block-a-bank-information-check'
import BlockABankAccountOpening from '~/modules/acquiring/views/a-bank/block-a-bank-account-opening'
import BlockABankAccountApplicationLoader from '~/modules/acquiring/views/a-bank/block-a-bank-account-application-loader'
import BlockABankAcquiringOrder from '~/modules/acquiring/views/a-bank/block-a-bank-acquiring-order'
import BlockABankAcquiringOrderPdf from '~/modules/acquiring/views/a-bank/block-a-bank-acquiring-order-pdf'
import BlockABankAcquiringOrderInfo from '~/modules/acquiring/views/a-bank/block-a-bank-acquiring-order-info'
import BlockABankPreviewInfo from '~/modules/acquiring/views/a-bank/block-a-bank-preview-info'
import BlockABankSecondAcquiringWarning from '~/modules/acquiring/views/a-bank/block-a-bank-second-acquiring-warning'
import ABank from '~/modules/acquiring/models/ABank'
import AccountOrder from '~/modules/acquiring/models/AccountOrder'
import AcquiringOrder from '~/modules/acquiring/models/AcquiringOrder'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import BlockABankDownloadApp from '~/modules/acquiring/views/a-bank/block-a-bank-download-app'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import BlockABankQuestionnaire from '~/modules/acquiring/views/a-bank/block-a-bank-questionnaire'

export default {
  name: 'BlockABankConnecting',
  components: {
    BlockABankDownloadApp,
    BlockABankAcquiringOrderInfo,
    BlockABankAcquiringOrderPdf,
    BlockABankAcquiringOrder,
    BlockABankAccountApplicationLoader,
    BlockABankAccountOpening,
    BlockABankInformationCheck,
    BlockABankPreviewInfo,
    EProgressCircular,
    BlockABankSecondAcquiringWarning,
    BlockABankQuestionnaire
  },
  mixins: [responsive, edsIitCheckboxDialog],
  data: () => ({
    infoLoading: false,
    loading: false,
    step: null,
    clientStatus: null,
    accountOrder: null,
    accountOrderBase64Pdf: null,
    accountOrderError: false,
    acquiringOrder: null,
    acquiringOrderBase64Pdf: null,
    acquiringOrderErrorInfo: false,
    showFooterActions: true,
    intervalRequest: null
  }),
  computed: {
    model () {
      return ABank
    },
    accountModel () {
      return AccountOrder
    },
    acquiringModel () {
      return AcquiringOrder
    },
    steps () {
      return {
        aBankPreview: 'aBankPreview',
        downloadApp: 'downloadApp',
        checkInformation: 'checkInformation',
        questionnaire: 'questionnaire',
        accountOpening: 'accountOpening',
        accountApplicationLoader: 'accountApplicationLoader',
        secondAcquiringWarning: 'secondAcquiringWarning',
        acquiringOrder: 'acquiringOrder',
        acquiringOrderPdf: 'acquiringOrderPdf',
        acquiringOrderInfo: 'acquiringOrderInfo',
        terminalConnectingOptions: 'terminalConnectingOptions'
      }
    },
    stepperSteps () {
      return {
        downloadApp: 1,
        checkInformation: 1,
        questionnaire: 2,
        accountOpening: 3,
        accountApplicationLoader: 3,
        acquiringOrder: 4,
        acquiringOrderPdf: 5,
        acquiringOrderInfo: 6
      }
    },
    showActions () {
      return this.showFooterActions &&
        this.step !== this.steps.checkInformation &&
        this.step !== this.steps.accountApplicationLoader &&
        this.step !== this.steps.acquiringOrderPdf &&
        this.step !== this.steps.secondAcquiringWarning
    },
    buttonText () {
      if (this.step === this.steps.accountOpening) {
        return 'Sign the application'
      } else if (this.step === this.steps.acquiringOrder) {
        return 'Submit an application'
      }
      return 'Continue'
    },
    goBackButtonText () {
      if (this.step === this.steps.aBankPreview) {
        return 'Not now'
      } else {
        return 'Come back'
      }
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    },
    showGoBackAction () {
      return this.step === this.steps.aBankPreview ||
        this.step === this.steps.downloadApp ||
        this.step === this.steps.accountOpening
    },
    previousStep () {
      const stepsArr = Object.keys(this.steps)
      const prevStep = stepsArr.findIndex(step => step === this.step) - 1
      return stepsArr[prevStep]
    }
  },
  watch: {
    step (val) {
      this.$emit('step', this._.get(this.stepperSteps, val, null))
      if (val === this.steps.accountOpening || val === this.steps.acquiringOrderPdf) {
        this.changeDialogWidth('900px')
      } else if (val === this.steps.secondAcquiringWarning) {
        this.changeDialogWidth('510px')
      } else {
        this.changeDialogWidth('755px')
      }
    }
  },
  created () {
    this.getClientInfo()
    this.changeTitle('Add POS-terminal')
    this.toggleCloseBtn(true)
    this.setStepperCount()
  },
  beforeDestroy () {
    if (this.intervalRequest) {
      this.intervalRequest.stopExponential()
    }
  },
  methods: {
    changeTitle (val) {
      this.$emit('changeTitle', val)
    },
    closeModal () {
      this.$emit('closeModal')
    },
    showBankSelectionForm (val) {
      this.$emit('showBankSelectionForm', val)
    },
    toggleCloseBtn (val) {
      this.$emit('toggleCloseBtn', val)
    },
    changeDialogWidth (val) {
      this.$emit('changeDialogWidth', val)
    },
    changeStep (val) {
      this.step = val
    },
    changeAccountOrder (val) {
      this.accountOrder = val
    },
    changeAccountOrderBase64Pdf (val) {
      this.accountOrderBase64Pdf = val
    },
    changeAccountOrderError (val) {
      this.accountOrderError = val
    },
    changeAcquiringOrderInfo (val) {
      this.acquiringOrderErrorInfo = val
    },
    changeShowFooterActions (val) {
      this.showFooterActions = val
    },
    async tableRequest () {
      await this.$emit('tableRequest')
    },
    setStepperCount () {
      const maxStep = Math.max(...Object.values(this.stepperSteps))
      this.$emit('maxStep', maxStep)
    },
    goToStepByClientStatus () {
      switch (this.clientStatus) {
        case this.model.CLIENT_STATUSES.greenPhysClient:
          this.changeStep(this.steps.questionnaire)
          break
        case this.model.CLIENT_STATUSES.fopWithAccount:
          this.changeStep(this.steps.acquiringOrder)
          break
        case this.model.CLIENT_STATUSES.fopWithAcquiring:
          this.changeStep(this.steps.secondAcquiringWarning)
          break
        default:
          this.changeStep(this.steps.aBankPreview)
          break
      }
    },
    async getClientInfo () {
      try {
        this.infoLoading = true
        this.clientStatus = this._.get(await this.model.api().getClientStatus(), 'response.data.clientType')
        this.goToStepByClientStatus()
      } catch (e) {
        this.$handlers.error(e, this)
        this.$emit('closeModal')
      } finally {
        this.infoLoading = false
      }
    },
    async sendAccountPdf () {
      this.loading = true
      await this.useEdsIitCheckboxDialog({
        fallbackMethod: this.sendAccountPdf,
        checkAuthService: true,
        createReportService: true,
        pluginTitles: {
          options: this.$t('Signing the application with a digital electronic key')
        },
        hints: {
          options: this.$t('signPlugin.request'),
          fileSign: this.$t('signPlugin.directorSign'),
          smartId: this.$t('signPlugin.smartId')
        },
        onConfirm: async ({ error, noReturn, sign }) => {
          try {
            if (error) {
              return !noReturn ? this.sendAccountPdf() : null
            }

            const pdf = Base64js.toByteArray(this.accountOrderBase64Pdf)
            const signedData = await sign(pdf, { fallbackMethod: this.sendAccountPdf })

            if (!signedData) {
              return null
            }

            await this.accountModel.api().sendSigned(this._.get(this.accountOrder, 'id'), {
              pdf: signedData
            })

            this.$gtm.push({
              event: 'success_sign_a_bank_account_opening',
              organization: this._.get(this.$Organization, 'owner.email')
            })

            this.changeStep(this.steps.accountApplicationLoader)
          } catch (e) {
            this.$handlers.error(e, this)
          }
        }
      })
      this.loading = false
    },
    checkStatus (model, id) {
      const request = new IntervalRequest(() => model.api().read(id), {
        interval: 1e3,
        count: 300,
        maxDelay: 12e4 // ~2 minutes
      })
      this.intervalRequest = request
      return request.startExponential((response) => {
        const status = this._.get(response, 'response.data.status', null)
        return status === model.STATUSES.signaturePending || status === model.STATUSES.error
      })
    },
    async sendAcquiringOrder () {
      try {
        this.loading = true
        const formRef = this.$refs.acquiringOrderForm
        const valid = formRef ? await formRef.$refs.form.validate() : false
        if (!valid) {
          return
        }
        const formData = formRef.form
        const outletId = this._.get(formData, 'outlet.id', null)
        const mccCode = this._.get(formData, 'mccCode.mcc', null)
        const payload = {
          ...formData,
          mccCode: mccCode ? `${mccCode}` : null,
          outlet: outletId ? `/outlets/${outletId}` : null
        }
        const order = this._.get(await this.acquiringModel.api().create(payload), 'response.data')
        const orderId = this._.get(order, 'id')
        const status = this._.get(await this.checkStatus(this.acquiringModel, orderId), 'response.data.status', null)

        await this.tableRequest()

        if (status === this.acquiringModel.STATUSES.signaturePending) {
          this.acquiringOrder = order
          this.acquiringOrderBase64Pdf = this._.get(await this.acquiringModel.api().getDoc(orderId), 'response.data.pdf')
          this.changeStep(this.steps.acquiringOrderPdf)
        } else {
          this.$emit('closeModal')
        }

        this.$gtm.push({
          event: 'success_a_bank_acquiring_order',
          organization: this._.get(this.$Organization, 'owner.email')
        })
      } catch (e) {
        this.$handlers.error(e, this)
        await this.tableRequest()
        this.$emit('closeModal')
      } finally {
        this.loading = false
      }
    },
    async sendQuestions () {
      return await this.$refs.questionnaire.submitQuestionnaire()
    },
    async handleClick () {
      switch (this.step) {
        case this.steps.aBankPreview:
          this.changeStep(this.steps.downloadApp)
          break
        case this.steps.downloadApp:
          this.$gtm.push({
            event: 'download_a_bank_mobile_app',
            organization: this._.get(this.$Organization, 'owner.email')
          })
          this.changeStep(this.steps.checkInformation)
          break
        case this.steps.questionnaire:
          await this.sendQuestions()
          break
        case this.steps.accountOpening:
          await this.sendAccountPdf()
          break
        case this.steps.acquiringOrder:
          await this.sendAcquiringOrder()
          break
        // TODO: maybe move it to default
        case this.steps.acquiringOrderInfo:
          await this.closeModal()
          break
        default:
          break
      }
    },
    goBack () {
      switch (this.step) {
        case this.steps.aBankPreview:
          this.closeModal()
          break
        default:
          this.changeStep(this.previousStep)
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-connecting {
  padding: 0 0 20px;

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
  }

  &__actions {
    padding-top: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-direction: column;
      padding-top: 16px;
    }
  }

  &__stepper {
    display: flex;
    gap: 24px;
    list-style: none;
    padding: 0;
    flex-grow: 1;
  }

  &__stepper-item {
    color: #000;
    font-size: 12px;
    font-weight: 300;

    &--disabled {
      opacity: 0.2;
    }

    &:before {
      content: '1';
      display: inline-flex;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-right: 4px;
      border: 1px solid #000;
      align-items: center;
      justify-content: center;
    }

    &:nth-child(2) {
      &:before {
        content: '2';
      }
    }

    &:nth-child(3) {
      &:before {
        content: '3';
      }
    }
  }
}
</style>
