<template lang="pug">
  div(class="dialog")
    div(class="dialog__banner") {{ $t('Action "Replacements of RRO"') }}
    v-img(
      class="dialog__img"
      :src="require('~/assets/images/racoons/racoon-rock.svg')"
    )
    h3(class="dialog__title") {{ $t('Switch from PRO to Checkbox Free for at least 7 years!') }}
    div
      p(class="dialog__text") {{ $t('To participate in the promotion, you need to have active PPOs, which you can exchange for the opportunity to use Checkbox for free.') }}
    div(class="dialog__actions")
      v-btn(
        class="secondary-button"
        @click="handleSkip"
        black
        text
      ) {{ $t('Later') }}
      v-btn(
        class="main-button"
        @click="openPromoFormAction"
        :loading="syncLoading"
      ) {{ $t('Take part') }}
</template>

<script>
import promoCashRegisters from '~/modules/promotion/mixins/promoCashRegisters'

export default {
  name: 'BlockPromotionGreeting',
  mixins: [promoCashRegisters],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    onSuccess: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    handleSkip () {
      this.promoCashRegistersDone(true)
      this.closeModal()
    },
    async openPromoFormAction () {
      await this.openPromoForm({
        onSuccess: this.onSuccess
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__banner {
    border-radius: 6px;
    background: #000;
    color: #fff;
    font-size: 11px;
    line-height: 16px;
    width: 100%;
    max-width: 156px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 20px;
  }

  &__text {
    color: rgba(0, 0, 0, 0.8);
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    & > .v-btn {
      width: 100%;
      min-height: 48px;

      @media (min-width: map-get($breakpoints, 'xs')) {
        width: calc(50% - 8px);
      }
    }
  }

  &__img {
    width: 100%;
    max-width: 138px;
    margin: 0 auto 12px;
    display: block;
  }
}
</style>
