var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.submit($event)}}},[_c('div',{staticClass:"addition"},[_c('div',{staticClass:"addition__header"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Receipt note')))]),_c('p',[_vm._v("("+_vm._s(_vm.$t('stamp of the controlling authority'))+")")])]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Addition'))+" 2")]),_c('p',[_vm._v(_vm._s(_vm.$t('to the tax declaration of the payer of the single tax of the third group for the period of military and state of emergency in Ukraine')))])])]),_c('div',{staticClass:"addition__data"},[_c('div',[_c('h3',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm._.upperCase(_vm.$t('Statements'))))]),_c('p',{staticClass:"bigger"},[_c('b',[_vm._v(_vm._s(_vm.$t('about the amount of the accumulated income of the insured persons and the amount of the accumulated single contribution')))])])]),_c('div',{staticClass:"addition__type-table"},[_c('div',{staticClass:"addition__type-table-row"},[_c('div',[_vm._v("01")]),_c('div',[_c('ValidationProvider',{attrs:{"vid":"reportingType","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"true-value":"HZ","false-value":null,"error-messages":errors,"hide-details":""},model:{value:(_vm.form.reportingType),callback:function ($$v) {_vm.$set(_vm.form, "reportingType", $$v)},expression:"form.reportingType"}})]}}])})],1),_c('div',[_vm._v(_vm._s(_vm.$t('Reportable')))])]),_c('div',{staticClass:"addition__type-table-row"},[_c('div',[_vm._v("02")]),_c('div',[_c('ValidationProvider',{attrs:{"vid":"reportingType","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"true-value":"HZN","false-value":null,"error-messages":errors,"hide-details":""},model:{value:(_vm.form.reportingType),callback:function ($$v) {_vm.$set(_vm.form, "reportingType", $$v)},expression:"form.reportingType"}})]}}])})],1),_c('div',[_vm._v(_vm._s(_vm.$t('Reportable new')))])])])]),_c('div',{staticClass:"addition__text-input-block"},[_c('div',[_c('p',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('Registration number of the taxpayer\'s registration card or series (if available) and passport number')))]),_c('sup',[_vm._v("1")])])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"HTIN","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Number'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HTIN),callback:function ($$v) {_vm.$set(_vm.form, "HTIN", $$v)},expression:"form.HTIN"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-input-block"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Surname, first name, patronymic (if available)')))])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"HNAME","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Full name'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HNAME),callback:function ($$v) {_vm.$set(_vm.form, "HNAME", $$v)},expression:"form.HNAME"}})]}}])})],1)]),_c('div',{staticClass:"addition__period"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Tax (reporting) period'))+":")])]),_c('div',{staticClass:"addition__period-inputs"},[_c('ValidationProvider',{attrs:{"name":" ","vid":"reportingPeriod","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Month'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.reportingPeriod),callback:function ($$v) {_vm.$set(_vm.form, "reportingPeriod", $$v)},expression:"form.reportingPeriod"}})]}}])}),_c('ValidationProvider',{attrs:{"name":" ","vid":"HZY","mode":"lazy","rules":"required|length:4|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Year '),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HZY),callback:function ($$v) {_vm.$set(_vm.form, "HZY", $$v)},expression:"form.HZY"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-input-block"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Code of the main type of economic activity')))])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"HKVED","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('KVED'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HKVED),callback:function ($$v) {_vm.$set(_vm.form, "HKVED", $$v)},expression:"form.HKVED"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-input-block addition__text-input-block--borderless"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('The period of stay of an individual - an entrepreneur on the simplified taxation system')))])]),_c('div',{staticClass:"addition__right-block-inputs"},[_c('ValidationProvider',{attrs:{"name":" ","vid":"R08G1D","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-input-datetime',{attrs:{"error-messages":errors,"label":_vm.$t('From'),"outlined":""},model:{value:(_vm.form.R08G1D),callback:function ($$v) {_vm.$set(_vm.form, "R08G1D", $$v)},expression:"form.R08G1D"}})]}}])}),_c('ValidationProvider',{attrs:{"name":" ","vid":"R08G2D","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-input-datetime',{attrs:{"error-messages":errors,"label":_vm.$t('To'),"outlined":""},model:{value:(_vm.form.R08G2D),callback:function ($$v) {_vm.$set(_vm.form, "R08G2D", $$v)},expression:"form.R08G2D"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-input-block"},[_c('div',[_c('p',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('Category code of the insured person')))]),_c('sup',[_vm._v("2")])])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"R081G1","mode":"lazy","rules":"required|max:2|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Code'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.R081G1),callback:function ($$v) {_vm.$set(_vm.form, "R081G1", $$v)},expression:"form.R081G1"}})]}}])})],1)]),_c('div',{staticClass:"addition__text-block"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('DETERMINATION OF THE AMOUNT OF ACCRUED INCOME OF THE INSURED PERSONS AND THE AMOUNT OF THE ACCRUED SINGLE CONTRIBUTION')))])])]),_c('div',{staticClass:"addition__table"},[_c('div',{staticClass:"addition__table-row addition__table-row--head"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Month')))])]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('An independently determined amount of income for which a single contribution is calculated, taking into account the maximum amount')))])]),_c('div',[_c('p',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('The size of the single contribution, percentage')))]),_c('sup',[_vm._v("3")])])]),_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('The amount of the single contribution payable to non-budget accounts, according to the data of the reporting (tax) period (column 2 x column 3) hryvnias, kopecks')))])])]),_vm._l((_vm.taxSumTableRows),function(item,index){return _c('div',{key:index,staticClass:"addition__table-row"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t(item.month)))])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":item.field1,"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Sum'),"error-messages":errors,"full-width":"","outlined":""},on:{"input":function($event){return _vm.onSumChange(item.field1)}},model:{value:(_vm.form.taxSum[item.field1]),callback:function ($$v) {_vm.$set(_vm.form.taxSum, item.field1, $$v)},expression:"form.taxSum[item.field1]"}})]}}],null,true)})],1),_c('div',[(item.field2)?_c('ValidationProvider',{attrs:{"name":" ","vid":item.field2,"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Percent'),"error-messages":errors,"full-width":"","outlined":""},on:{"input":function($event){return _vm.onSumChange(item.field2)}},model:{value:(_vm.form.taxSum[item.field2]),callback:function ($$v) {_vm.$set(_vm.form.taxSum, item.field2, $$v)},expression:"form.taxSum[item.field2]"}})]}}],null,true)}):_vm._e()],1),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":item.field3,"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Sum'),"error-messages":errors,"full-width":"","outlined":"","disabled":""},on:{"input":function($event){return _vm.onSumChange(item.field3)}},model:{value:(_vm.form.taxSum[item.field3]),callback:function ($$v) {_vm.$set(_vm.form.taxSum, item.field3, $$v)},expression:"form.taxSum[item.field3]"}})]}}],null,true)})],1)])})],2),_c('div',{staticClass:"addition__footer"},[_c('div',[_c('p',[_c('sup',[_vm._v("1")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('The series (if available) and the passport number are indicated by natural persons')))])]),_c('p',[_c('sup',[_vm._v("2")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('The category code of the insured person is "6" - a natural person - an entrepreneur on the simplified taxation system.')))])]),_c('p',[_c('sup',[_vm._v("3")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('The amount of the single contribution established by law is indicated.')))])])])]),_c('div',{staticClass:"addition__footer-input"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('The given information is correct'))+":")]),_c('p',[_vm._v(_vm._s(_vm.$t('A natural person is a payer of a single contribution or an authorized person')))])]),_c('div',[_c('ValidationProvider',{attrs:{"name":" ","vid":"HBOS","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Full name'),"error-messages":errors,"full-width":"","outlined":""},model:{value:(_vm.form.HBOS),callback:function ($$v) {_vm.$set(_vm.form, "HBOS", $$v)},expression:"form.HBOS"}})]}}])})],1)])]),_c('div',{staticClass:"py-6"},[_c('v-btn',{staticClass:"main-button d-block mx-auto",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t('Save')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }