import filterOrmObject from '~/mixins/orm/filterOrmObject'

const employeeCreating = {
  mixins: [filterOrmObject],
  computed: {
    steps () {
      return {
        options: 'options',
        selectEdsKey: 'selectEdsKey',
        edsKeyInfo: 'edsKeyInfo',
        employeeFormEdsKey: 'employeeFormEdsKey',
        sign: 'sign',
        creatingDsKey: 'creatingDsKey',
        existingDsKey: 'existingDsKey',
        manualEmployeeForm: 'manualEmployeeForm',
        keyReplacementOptions: 'keyReplacementOptions',
        keyReplacementFormEdsKey: 'keyReplacementFormEdsKey',
        keyReplacementDsCreating: 'keyReplacementDsCreating',
        keyReplacementDsExisting: 'keyReplacementDsExisting'
      }
    }
  }
}

export default employeeCreating
