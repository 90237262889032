import { get } from 'lodash'
import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'

const setAuthenticatedCashier = {
  methods: {
    async setAuthenticatedCashier (data, authenticatedCashier) {
      const currentCashier = AuthenticatedCashier.find(get(authenticatedCashier, 'id'))

      if (currentCashier) {
        await AuthenticatedCashier.update({
          where: get(currentCashier, 'id'),
          data
        })
      } else {
        await AuthenticatedCashier.create({
          data: Object.assign({}, authenticatedCashier || {}, data)
        })
      }
    }
  }
}

export default setAuthenticatedCashier
