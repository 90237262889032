import _commonData from '~/modules/receipt/mixins/getters/commonData'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'
import PayLinkTerminals from '~/modules/acquiring/models/PayLinkTerminals'
import setAuthenticatedCashier from '~/modules/receipt/mixins/setters/setAuthenticatedCashier'

let payLinkSocketInstance = null
let payLinkSocketReconnectTimeout = null

const payLinkProcesses = {
  mixins: [_commonData, setAuthenticatedCashier],
  methods: {
    async initializePayLink () {
      if (!this.isAuthenticated) {
        return
      }

      if (!this.getPayLinkDataFromAuthenticatedCashier('token')) {
        try {
          const posTerminalsRequest = await PosTerminals.api()
            .filter({ 'cashRegister.id': this._.get(this.cashRegister, 'id'), limit: 1 })
            .exists(['payLinkTerminalId'], true)
            .all({ save: false })
          const posTerminalsResponse = this._.get(posTerminalsRequest, 'response.data.data')

          if (posTerminalsResponse && posTerminalsResponse.length && posTerminalsResponse[0].usage) {
            const payLinkTerminalRequest = await PayLinkTerminals.api().authAsClient(posTerminalsResponse[0].payLinkTerminalId)
            const payLinkTerminalResponse = this._.get(payLinkTerminalRequest, 'response.data')

            await this.saveDataForAuthenticatedCashier({
              id: payLinkTerminalResponse.terminal.id,
              token: payLinkTerminalResponse.token,
              online: payLinkTerminalResponse.terminal.online.server
            })
          }
        } catch (e) {
          await this.destroyPayLinkSocket(true)
        }
      }

      this.initializePayLinkSocket()
    },
    initializePayLinkSocket () {
      if (!this.getPayLinkDataFromAuthenticatedCashier('token') || payLinkSocketInstance) {
        return
      }

      const token = this.getPayLinkDataFromAuthenticatedCashier('token')
      const cashRegisterId = this._.get(this.cashRegister, 'id')

      const address = this.$env.WSS_PAY_LINK_BASE_URL + `/api/v1/proxy?token=${token}&cash_register_id=${cashRegisterId}`
      payLinkSocketInstance = new WebSocket(address)

      payLinkSocketInstance.onclose = async (e) => {
        const needReconnect = e.code === 1006

        await this.destroyPayLinkSocket(needReconnect === false)

        if (needReconnect) {
          this.reconnectToPayLinkSocket()
        }
      }
      payLinkSocketInstance.onerror = async () => {
        // await this.destroyPayLinkSocket()
      }
      payLinkSocketInstance.onmessage = async (e) => {
        const payload = JSON.parse(this._.get(e, 'data'))

        // event from processing about terminal status
        if (payload.sender && payload.sender === 'proxy') {
          const online = payload.event === 'connected'
          await this.saveDataForAuthenticatedCashier({ online })
        }

        this.$root.$emit('payLinkSocketEvent', payload)
      }
      payLinkSocketInstance.onopen = () => {
        clearTimeout(payLinkSocketReconnectTimeout)
      }
    },
    async destroyPayLink () {
      await this.destroyPayLinkSocket()
      await this.saveDataForAuthenticatedCashier({})
    },
    sentEventToPayLinkSocket (payload) {
      if (payLinkSocketInstance) {
        payLinkSocketInstance.send(JSON.stringify(payload))
      }
    },
    reconnectToPayLinkSocket () {
      clearTimeout(payLinkSocketReconnectTimeout)

      payLinkSocketReconnectTimeout = setInterval(() => {
        this.initializePayLinkSocket()
      }, 2000)
    },
    async destroyPayLinkSocket (forceClear = false) {
      if (payLinkSocketInstance) {
        payLinkSocketInstance.close()
        payLinkSocketInstance = null
      }

      const payload = forceClear ? { id: null, online: false, token: null } : { online: false }

      this.$root.$emit('payLinkSocketEvent', { sender: 'proxy', event: 'disconnected' })
      await this.saveDataForAuthenticatedCashier(payload)
    },
    async saveDataForAuthenticatedCashier (payload) {
      await this.setAuthenticatedCashier({
        payLinkTerminal: Object.assign({}, this.getPayLinkDataFromAuthenticatedCashier(), payload)
      }, this.authenticatedCashier)
    },
    getPayLinkDataFromAuthenticatedCashier (field = null) {
      const data = this._.get(this.authenticatedCashier, 'payLinkTerminal', {})
      return field ? this._.get(data, field) : data
    }
  }
}

export default payLinkProcesses
