import Dialog from '~/models/system/Dialog'

export default function ({ app }) {
  setTimeout(() => {
    const dialog = Dialog.query().where('type', 'information').first()
    dialog.open({
      text: 'Currently, the work of the DPS is not stable, therefore the check processing time has been increased. In this regard, there may be significant delays in issuing checks. We apologize for the temporary inconvenience.',
      width: '660px'
    })
  }, 2500)
}
