<template lang="pug">
  span(v-if="value" class="employee-key-end" :class="keyEndClass") {{ value }}
  span(v-else) –
</template>

<script>
export default {
  name: 'BlockEmployeeKeyEnd',
  props: {
    value: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    keyStatus () {
      const date = new Date()
      const certificateEnd = this._.get(this.item, 'certificateEnd')
      if (certificateEnd === null) {
        return false
      } else if (date.getTime() > new Date(certificateEnd)) {
        return 'overdue'
      } else if (date.setDate(date.getDate() + 5) > new Date(certificateEnd)) {
        return 'warning'
      }
      return ''
    },
    keyEndClass () {
      if (this.keyStatus) {
        return `employee-key-end--${this.keyStatus}`
      }
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
.employee-key-end {
  color: #161B25;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;

  &--overdue {
    color: #FF1744;
  }

  &--warning {
    color: #FFAA01;
  }
}
</style>
