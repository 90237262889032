<template lang="pug">
  div(class="dialog")
    ValidationObserver(ref="form" slim)
      t-orm-label(
        :item="{ label: { text: 'Restrictions on the sale of alcoholic beverages', subText: 'Beta' } }"
        class="dialog__label"
      )
      t-orm-fields(
        v-bind.sync="form"
        :items="fields"
      )
      t-orm-label(
        :item="{ label: { text: 'Type object', subText: 'Beta' } }"
        class="dialog__label my-4"
      )
      t-orm-fields(
        v-bind.sync="formSecond"
        :items="fieldsSecond"
      )
      t-orm-label(
        v-if="isVehicle"
        :item="{ label: { text: 'Car number of vehicle license plate' } }"
        class="my-4"
      )
      t-orm-fields(
        v-if="isVehicle"
        v-bind.sync="vehicleForm"
        :items="vehicleFields"
      )
    div(class="dialog__actions text-center mt-5")
      v-btn(
        class="main-button"
        :loading="loading"
        @click="save"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import Outlets from '~/models/directories/Outlets'

export default {
  name: 'MFormOutletSettings',
  components: {
    TOrmFields,
    TOrmLabel
  },
  mixins: [],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: null,
      required: true
    }
  },
  data: () => ({
    loading: false,
    form: {
      timeFrom: null,
      timeTo: null,
      taxTime: false
    },
    formSecond: {
      showTypeTaxationObject: false
    },
    vehicleForm: {
      vehicleLicensePlate: null
    }
  }),
  computed: {
    model () {
      return Outlets
    },
    fields () {
      return [
        {
          model: 'timeFrom',
          component: 'e-input-datetime',
          provider: {
            vid: 'timeFrom',
            name: 'Sale from',
            rules: this._.get(this.form, 'timeTo') ? 'required' : ''
          },
          attrs: {
            outlined: true,
            clearable: true,
            label: 'Sale from',
            type: 'time'
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'timeTo',
          component: 'e-input-datetime',
          provider: {
            vid: 'timeTo',
            name: 'Sale to',
            rules: this._.get(this.form, 'timeFrom') ? 'required' : ''
          },
          attrs: {
            outlined: true,
            clearable: true,
            label: 'Sale to',
            type: 'time'
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'taxTime',
          component: 'e-input-switch-tooltip',
          provider: {
            vid: 'taxTime',
            name: 'Activate sales restrictions'
          },
          attrs: {
            label: 'Activate sales restrictions'
          },
          cols: {
            cols: 12
          }
        }
      ]
    },
    fieldsSecond () {
      return [
        {
          model: 'showTypeTaxationObject',
          component: 'e-input-switch-tooltip',
          provider: {
            vid: 'showTypeTaxationObject',
            name: 'Output the type of object of taxation in the check'
          },
          attrs: {
            label: 'Output the type of object of taxation in the check'
          }
        }
      ]
    },
    vehicleFields () {
      return [
        {
          model: 'vehicleLicensePlate',
          component: 'v-text-field',
          provider: {
            vid: 'vehicleLicensePlate',
            name: 'Vehicle license plate'
          },
          attrs: {
            label: 'Vehicle license plate',
            outlined: true
          }
        }
      ]
    },
    isVehicle () {
      return this._.get(this.item, 'regNumber')
    }
  },
  created () {
    this.$set(this.$data, 'form', {
      timeFrom: this._.get(this.item, 'timeFrom', null),
      timeTo: this._.get(this.item, 'timeTo', null),
      taxTime: this._.get(this.item, 'taxTime', null)
    })
    this.$set(this.$data, 'formSecond', {
      showTypeTaxationObject: this._.get(this.item, 'showTypeTaxationObject', false)
    })
    this.$set(this.$data, 'vehicleForm', {
      vehicleLicensePlate: this._.get(this.item, 'vehicleLicensePlate', null)
    })
  },
  methods: {
    async save () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        await this.model.api().update(this.item, this.form)

        if (this._.get(this.formSecond, 'showTypeTaxationObject') !== this._.get(this.item, 'showTypeTaxationObject')) {
          await this.model.api().setTypeTaxationObject(this._.get(this.item, 'id'), this.formSecond)
        }

        if (this._.get(this.formThird, 'vehicleLicensePlate') !== this._.get(this.item, 'vehicleLicensePlate')) {
          await this.model.api().setVehicleRegistrationPlate(this._.get(this.item, 'id'), this.vehicleForm)
        }

        this.$notification.success(this.$t('The point of sale settings have been saved successfully'))
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__label {
    margin-bottom: 16px;

    &::v-deep {
      .label {
        display: inline;

        &__subtext {
          display: inline;
          font-size: 11px;
          color: $color-warning;
          position: relative;
          top: -6px;
          left: 6px;
        }
      }
    }
  }
}
</style>
