<template lang="pug">
  v-row(no-gutters)
    v-col(cols="12" sm="8" class="block-iban-payment")
      h2(class="block-iban-payment__title") {{ $t('To be paid') }}: {{ totalPaymentSum | currencyFormat }} <span class="curr-symbol">&#8372;</span>
      v-expansion-panels(v-if="isResponsive" v-model="mobileHint" class="mb-6" accordion)
        v-expansion-panel
          v-expansion-panel-header(color="rgba(0, 0, 0, 0.03)") 💡 {{ $t('Hint') }}
          v-expansion-panel-content(color="rgba(0, 0, 0, 0.03)")
            div(class="block-iban-payment__hint-text") {{ $t('To pay, the client needs to scan this QR code') }}
            qrcode-vue(v-if="qrLink" :value="qrLink" :size="250" background="#00000007")
            div(class="receipt__buttons")
              e-link(@click="copyData(qrLink)")
                e-svg-icon(class="mr-2") receipt-link
                | {{ $t('Copy payment link') }}
              e-link(@click="copyData(paymentDetails)")
                e-svg-icon(class="mr-2") receipt-link
                | {{ $t('Copy details') }}
      ValidationObserver(ref="ibanValidator")
        t-orm-fields(:iban.sync="iban" :items="fields")
      div(class="block-iban-payment__info")
        div(class="block-iban-payment__item")
          div(class="block-iban-payment__item-label") {{ $t('TIN/EDRPOU') }}
          div(class="block-iban-payment__item-text") {{ $Organization.edrpou }}
        div(class="block-iban-payment__item")
          div(class="block-iban-payment__item-label") {{ $t('Recipient') }}
          div(class="block-iban-payment__item-text") {{ $Organization.name }}
        div(class="block-iban-payment__item")
          div(class="block-iban-payment__item-label") {{ $t('Appointment') }}
          div(class="block-iban-payment__item-text") {{ paymentDescription }}
      div(class="block-iban-payment__message") 💡 {{ $t('To issue a receipt, please confirm below that the buyer has made a bank transfer') }}
      div(class="block-iban-payment__actions")
        v-checkbox(v-model="isApprove" :label="$t('Transfer received')")
        v-btn(class="main-button" :class={ 'w-100': isResponsive } :disabled="!isApprove" height="48" @click="closeModal(true)") {{ $t('Create receipt') }}
    v-col(v-if="!isResponsive" cols="12" sm="4" class="block-iban-payment__hint")
      div
        div(class="block-iban-payment__hint-title") 💡 {{ $t('Hint') }}
        div(class="block-iban-payment__hint-text") {{ $t('To pay, the client needs to scan this QR code') }}
        qrcode-vue(v-if="qrLink" :value="qrLink" :size="250" level="M")
      div(class="receipt__buttons")
        e-link(@click="copyData(qrLink)")
          e-svg-icon(class="mr-2") receipt-link
          | {{ $t('Copy payment link') }}
        e-link(@click="copyData(paymentDetails)")
          e-svg-icon(class="mr-2") receipt-link
          | {{ $t('Copy details') }}
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Base64js from 'base64-js'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Iban from '~/modules/acquiring/models/Iban'
import currencyFormat from '~/mixins/filters/currencyFormat'
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import clipboard from '~/mixins/global/_clipboard'

export default {
  name: 'BlockIbanPayment',
  components: {
    TOrmFields,
    QrcodeVue,
    ELink,
    ESvgIcon
  },
  mixins: [currencyFormat, clipboard],
  props: {
    closeModal: {
      type: Function,
      default: null
    },
    totalSum: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      iban: null,
      isApprove: false,
      mobileHint: false
    }
  },
  computed: {
    fields () {
      return [
        {
          model: 'iban',
          component: 'v-select',
          provider: {
            vid: 'iban',
            name: 'IBAN',
            rules: 'IBAN'
          },
          items: () => Iban.all(),
          asyncDefault: items => items[0],
          attrs: {
            label: 'IBAN',
            outlined: true,
            itemText: 'number',
            returnObject: true
          }
        }
      ]
    },
    totalPaymentSum () {
      return (this.totalSum / 100).toFixed(2)
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    },
    paymentInfo () {
      return `BCD
002
1
UCT

${this.$Organization.name}
${this.iban && this.iban.number}
UAH${this.totalPaymentSum}
${this.$Organization.edrpou}


${this.paymentDescription}
`
    },
    encodedPaymentInfo () {
      const encoder = new TextEncoder()
      const uint8Array = encoder.encode(this.paymentInfo)
      const base64Str = Base64js.fromByteArray(uint8Array)
      let base64URL = base64Str.replace(/\+/g, '-').replace(/\//g, '_')
      base64URL = base64URL.replace(/=+$/, '')
      return base64URL
    },
    qrLink () {
      return `https://bank.gov.ua/qr/${this.encodedPaymentInfo}`
    },
    paymentDetails () {
      return this.$Organization.name + ';' + this.$Organization.edrpou + ';' + this.iban.number + ';' + this.totalPaymentSum
    },
    paymentDescription () {
      const description = this._.get(this.iban, 'paymentDescription')
      return description || this.$t('Payment for goods and services')
    }
  },
  methods: {
    copyData (data) {
      try {
        this.copy(data)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.block-iban-payment {
  padding: 40px 32px 32px 32px;

  @media (max-width: map-get($breakpoints, 'sm')) {
    padding: 16px 12px;
  }

  &__title {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.24px;
    padding-bottom: 32px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__item {

    &-label {
      color: rgba(22, 27, 37, 0.50);
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
      padding-bottom: 4px;
    }

    &-text {
      color: #161B25;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__hint {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 40px 32px;
    background: rgba(0, 0, 0, 0.03);

    &-title {
      color: #161B25;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &-text {
      padding: 12px 0 24px 0;
      color: rgba(22, 27, 37, 0.75);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  &__message {
    padding: 12px;
    margin-top: 64px;
    margin-bottom: 32px;

    border-radius: 12px;

    background: rgba(67, 204, 180, 0.08);

    color: rgba(22, 27, 37, 0.75);
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-direction: column;
    }
  }

  &::v-deep {
    .v-expansion-panel {
      border-radius: 12px !important;
    }
    .v-expansion-panel::before {
      box-shadow: none !important;
    }
    .v-expansion-panel-header {
      border-radius: 12px !important;
    }
    .v-expansion-panel-content {
      border-radius: 0 0 12px 12px !important;
    }
  }
}
</style>
