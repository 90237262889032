import { Model } from '@vuex-orm/core'
import moment from 'moment'
import { get } from 'lodash/object'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import TimestampAble from '~/models/mixins/TimestampAble'
import { formats } from '~/const/global'

export class MallReport extends ChainInheritance(Directory, [
  TimestampAble
]) {
  static entity = 'mallreport'
  static paginated = true
  static defaultSortParam = 'generateDate'

  static ormTrans = {
    single: 'Report',
    multy: 'Reports'
  }

  static STATUSES = {
    new: 'NEW',
    pending: 'PENDING',
    done: 'DONE',
    error: 'ERROR'
  }

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      outlets: this.attr(null),
      cashRegisters: this.attr(null),
      dateInterval: this.attr(null),
      generateDate: this.attr(null),
      mallReportApiSetting: this.attr(null),
      mallReportEmailSetting: this.attr(null),
      mallReportFtpSetting: this.attr(null)
    }
  }

  get autoReportingString () {
    const list = []
    if (get(this.mallReportEmailSetting, 'active')) {
      list.push('E-mail')
    }
    if (get(this.mallReportFtpSetting, 'active')) {
      list.push('FTP')
    }
    return list.join(', ') || '–'
  }

  get generateDateString () {
    return moment(this.generateDate).tz('Europe/Kiev').format(formats.dateTimeSec)
  }

  static ormHeaders = [
    { text: 'Name ', value: 'name', align: 'left', filterable: false, sortable: false },
    { text: 'Period', value: 'dateInterval', align: 'left', filterable: false, sortable: false },
    { text: 'Date of generation', value: 'generateDateString', align: 'left', filterable: false, sortable: false },
    { text: 'Auto-reporting', value: 'autoReportingString', align: 'left', filterable: false, sortable: false },
    { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '72' }
  ]

  static ormActions = [
    {
      name: 'download',
      text: 'Download',
      icon: {
        type: 'e-svg-icon',
        text: 'download-black'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-mall-report-download',
          width: '450px',
          componentProps: {
            item
          }
        })
      }
    },
    {
      name: 'settings',
      text: 'Settings',
      icon: {
        type: 'e-svg-icon',
        text: 'settings-2'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-mall-report-settings',
          width: '550px',
          componentProps: {
            item
          }
        })
      }
    },
    {
      name: 'sendCustomReport',
      text: 'Send report ',
      icon: {
        type: 'e-svg-icon',
        text: 'send'
      },
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-mall-report-settings',
          width: '550px',
          componentProps: {
            item,
            sendFile: true
          }
        })
      }
    },
    {
      name: 'delete',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        dialog.open({
          title: 'Are you sure you want to delete report?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            await MallReport.api().del(item)
            AppNotifications.success('Report successfully deleted')
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.generateReport = function (id, payload) {
        return this.post(Model.$routes.mallreport.generate(id), payload, { save: false })
      }
      configActions.checkStatus = function (id, payload) {
        return this.post(Model.$routes.mallreport.status(id), payload, { save: false })
      }
      configActions.generateExport = function (id) {
        return this.get(Model.$routes.mallreport.generateExport(id), {
          save: false,
          responseType: 'blob'
        })
      }
      configActions.updateFtpSettings = function (id, payload) {
        return this.put(Model.$routes.mallreport.ftpSettings(id), payload)
      }
      configActions.updateEmailSettings = function (id, payload) {
        return this.put(Model.$routes.mallreport.emailSettings(id), payload)
      }
      configActions.sendReport = function (id, payload) {
        return this.post(Model.$routes.mallreport.send(id), payload, { save: false })
      }
      return configActions
    }
  }
}

export default MallReport
