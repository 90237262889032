<template lang="pug">
  div
    div(id="tranzzo-widget")
    div(v-if="loading" class="d-flex align-center justify-center pa-10")
      e-progress-circular(size="xl" width="4")
</template>

<script>
import TranzzoToken from '~/modules/tranzzo/models/TranzzoToken'
import TDialog from '~/components/templates/t-dialog'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import Billings from '~/models/billing/Billings'

export default {
  name: 'BlockTranzzoGenerateToken',
  components: {
    EProgressCircular,
    TDialog
  },
  props: {
    model: {
      type: null,
      required: true
    },
    billing: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      tranzzoWidget: null,
      loading: false
    }
  },
  computed: {
    token () {
      return TranzzoToken.query().first()
    }
  },
  watch: {
    token (val) {
      if (val) {
        this.setCardToken(this.token)
      }
    }
  },
  mounted () {
    this.loading = true
    this.tranzzoInit()
    document.addEventListener('widget-init-ready', this.setWidgetLoading)
  },
  beforeDestroy () {
    document.removeEventListener('widget-init-ready', this.setWidgetLoading)
  },
  methods: {
    tranzzoInit () {
      if (!document.body.querySelector('script[src="https://gcdn.tranzzo.com/widget.js"]')) {
        const script = document.createElement('script')
        script.src = 'https://gcdn.tranzzo.com/widget.js'
        script.async = true
        script.onload = this.onScriptLoaded

        document.body.appendChild(script)
      } else {
        this.onScriptLoaded()
      }
    },
    onScriptLoaded () {
      window.Tranzzo.init({
        key: this.$env.TRANZZO_API_TOKEN,
        mode: 'inline',
        style: 'checkbox',
        template: 'default',
        lang: 'uk',
        selector: 'tranzzo-widget',
        locale: {
          uk: {
            submit: 'Зберегти'
          }
        },
        onToken (tokenData) {
          TranzzoToken.insert({
            data: tokenData
          })
        }
      }).open()
    },
    async setCardToken (data) {
      try {
        this.loading = true
        await this.model.api().setCardToken({ token: data.token, cardMask: data.card_mask, sourceName: 'tranzzo' })
        await Billings.api().read(this.billing.id)
        this.$notification.success('Auto payment by card has been enabled')
        this.$emit('onSuccess')
      } catch (e) {
        this.$emit('onError')
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    setWidgetLoading () {
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
