<template lang="pug">
  div(class="confirm-state")
    img(class="confirm-state__illustration" src="~/assets/images/racoons/racoon-idea.svg" alt="Raccoon")

    div
      div(class="text-wrapper")
        h2(class="confirm-state__title") {{ title }}
        p(class="secondary-button confirm-state__description") {{ description }}

      div(class="confirm-state__actions")
        v-btn(class="secondary-button confirm-state__button leave-button" @click="confirmExit")
          | {{ $t('Leave') }}

        v-btn(class="main-button confirm-state__button" @click="stay")
          | {{ $t('Stay') }}
</template>

<script>
import { OperationType } from '~/modules/goods/views/import-export-flow/const/operationType'

export default {
  name: 'MConfirmExitState',
  props: {
    closeModal: {
      type: Function,
      default: null
    },
    operationType: {
      type: String,
      required: true
    },
    operationStatus: {
      type: String,
      required: true
    }
  },
  computed: {
    title () {
      switch (this.operationType) {
        case OperationType.UPLOAD:
          return this.$t('Import operation has not yet been completed')
        case OperationType.EXPORT:
          return this.$t('Export operation has not yet been completed')
        case OperationType.DELETE:
          return this.$t('Delete operation has not yet been completed')
        default:
          return this.$t('Unknown operation')
      }
    },
    description () {
      switch (this.operationType) {
        case OperationType.UPLOAD:
          return this.$t('Are you sure you want to leave this page? Unsaved data may be lost during import.')
        case OperationType.EXPORT:
          return this.$t('Are you sure you want to leave this page? Unsaved data may be lost during export.')
        case OperationType.DELETE:
          return this.$t('Are you sure you want to leave this page? Unsaved data may be lost during deletion.')
        default:
          return this.$t('Are you sure you want to leave this page? Unsaved data may be lost')
      }
    }
  },
  beforeDestroy () {
    this.confirmExit()
  },
  methods: {
    confirmExit () {
      this.closeModal(this.operationStatus === 'done' ? 'showSuccessSnackbar' : 'showInformationSnackbar')
    },
    stay () {
      this.closeModal('loading')
    }
  }
}
</script>

<style lang="scss" scoped>
.leave-button {
  background-color: transparent !important;
  border: solid 2px #161B25;
}

.confirm-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__illustration {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 106px;
      height: 106px;
    }
  }

  &__title {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
    color: #161B25;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__description {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    color: #555;
    margin: 10px 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 50px;
    gap: 0 16px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-direction: column;
      gap: 16px 0;
    }
  }

  &__button {
    padding: 16px;
    border-radius: 12px;
    cursor: pointer;
    width: 200px;
    height: 48px !important;

    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 100%;
      height: 48px !important;
    }

    &--leave {
      background-color: transparent;
      color: #000;
      border: 2px solid black;

      &:hover {
        background-color: #ff4c4c;
        color: #fff;
      }
    }

    &--stay {
      background-color: #00b4d8;
      color: #fff;
      border: none;

      &:hover {
        background-color: #0077b6;
      }
    }
  }
}
</style>
