import { Model } from '@vuex-orm/core'
import Organization from '~/models/directories/Organization'

export default class ManagedOrganizations extends Organization {
  static entity = 'managedOrganizations'
  static paginated = true

  static fields () {
    return super.fields()
  }

  toString () {
    return this.name
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.getByUser = function (userId, config = {}) {
        return this.get(Model.$routes.managedOrganizations.getByUser(userId), {
          save: true,
          dataKey: 'data',
          persistBy: config?.persistBy || 'insertOrUpdate',
          params: {
            ...(config?.params || {}),
            'order[name]': 'asc'
          }
        })
      }

      return configActions
    }
  }
}
