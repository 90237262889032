import Employees from '~/modules/employees/models/Employees'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'

const processingCheckAgent = {
  mixins: [processingApiRequest],
  methods: {
    async checkSignAgent (employee) {
      let accessToken = this._.get(await Employees.api().processingSignin(employee), 'response.data.access_token', null)
      const signAgentLaunched = await this.processingApiRequest({
        request: async (token) => {
          accessToken = token
          return this._.get(await Employees.api().processingCheckAgent(token), 'response.data.online', false)
        },
        token: accessToken,
        employee
      })
      return { signAgentLaunched, token: accessToken }
    }
  }
}

export default processingCheckAgent
