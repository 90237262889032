<template lang="pug">
  div(class="rozetkapay-acquiring-settings-form")
    v-form
      ValidationObserver(ref="form" slim)
        t-orm-fields(v-bind.sync="form" :items="fields")
    div(class="rozetkapay-acquiring-settings-form__actions")
      v-btn(
        class="main-button"
        :class="{ 'w-100': xsDevice }"
        @click="saveToken"
        :loading="loading"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import InternetAcquiring from '~/modules/acquiring/models/InternetAcquiring'
import CashRegisters from '~/models/directories/CashRegisters'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockRozetkapayAcquiringForm',
  components: {
    TOrmFields
  },
  mixins: [responsive],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      form: {
        login: null,
        password: null,
        cashRegister: null
      }
    }
  },
  computed: {
    fields () {
      const tokenArr = (this._.get(this.item, 'token') || '').split(':')
      const login = tokenArr[0] || ''
      const password = tokenArr.splice(1).join('') || ''
      return [
        {
          model: 'login',
          component: 'v-text-field',
          provider: {
            vid: 'token',
            name: 'Login ',
            rules: 'required'
          },
          attrs: {
            label: 'Login ',
            outlined: true
          },
          fieldVal: () => login
        },
        {
          model: 'password',
          component: 'v-text-field',
          provider: {
            vid: 'password',
            name: 'Password',
            rules: 'required'
          },
          attrs: {
            label: 'Password',
            outlined: true
          },
          fieldVal: () => password
        },
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Connected to cash register',
          rules: 'required',
          visible: () => !this.isEditing,
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (!this._.get(this.item, 'cashRegister', null) && items && items.length === 1) {
              return items[0]
            }
            const cashRegisterOnEdit = this._.get(this.item, 'cashRegister', null)
            if (cashRegisterOnEdit) {
              return cashRegisterOnEdit
            }
          },
          itemValue: item => this._.get(item, 'id', null)
        }, CashRegistersSearch).build()
      ]
    },
    title () {
      return 'Edit RozetkaPay settings'
    }
  },
  methods: {
    async saveToken () {
      const valid = await this.$refs.form.validate()
      if (!valid) { return null }

      const payload = {
        login: this.form.login,
        password: this.form.password
      }

      if (!this.isEditing) {
        payload.cashRegister = `cash-registers/${this._.get(this.form, 'cashRegister.id')}`
      }

      try {
        this.loading = true
        if (this.isEditing) {
          await InternetAcquiring.api().updateRozetkaPaySettings(this._.get(this.item, 'id'), payload)
        } else {
          await InternetAcquiring.api().createRozetkaPaySettings(payload)
        }
        if (this.isEditing) {
          this.$notification.success(this.$t('RozetkaPay settings have been updated successfully'))
        }

        await this.tableRequest()
        this.closeModal({ success: true })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.rozetkapay-acquiring-settings-form {
  &__title {
    color: #000;
    font-weight: 700;
    padding: 15px 0 20px;
    font-size: 1.1rem !important;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 1.25rem !important;
      padding: 20px 0;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
