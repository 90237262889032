<template lang="pug">
  div(class="form")
    div(
      v-for="(item, index) in fields"
      :key="index"
      class="form__item"
    )
      component(
        :is="item.component"
        :value="item.value"
        :label="$t(item.label)"
        outlined
        readonly
        hide-details
      )
      v-tooltip(top)
        template(#activator="{on}")
          v-btn(
            v-on="on"
            @click="copyToClipboard(item.value)"
            icon
          )
            e-svg-icon(size="sm") copy
        | {{ $t('Copy') }}
</template>

<script>
import { VTextField } from 'vuetify/lib'
import EInputPassword from '~/components/elements/inputs/e-input-password'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import clipboard from '~/mixins/global/_clipboard'

export default {
  name: 'BlockMallReportApiUrlSettings',
  components: {
    ESvgIcon,
    VTextField,
    EInputPassword
  },
  mixins: [clipboard],
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    settings: {
      url: null,
      login: null,
      password: null
    }
  }),
  computed: {
    fields () {
      return [
        {
          component: 'v-text-field',
          value: this.settings.url,
          label: 'Link'
        },
        {
          component: 'v-text-field',
          value: this.settings.login,
          label: 'Login '
        },
        {
          component: 'e-input-password',
          value: this.settings.password,
          label: 'Password'
        }
      ]
    }
  },
  created () {
    this.setSettings()
  },
  methods: {
    copyToClipboard (val) {
      this.copy(val)
      this.$notification.success(this.$t('Copied to clipboard'))
    },
    setSettings () {
      if (this.item) {
        this.$set(this.$data, 'settings', this.item)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 24px;

  &__item {
    display: flex;
    gap: 15px;
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

.loader {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
