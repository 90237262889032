<template lang="pug">
  div(class="h-100")
    div(
      v-if="viewLoading"
      class="loader"
    )
      e-progress-circular(
        width="4"
        size="xl"
        color="#161b25"
      )
    div(
      v-else
      class="content"
    )
      div(
        id="docWrapper"
        v-dompurify-html="docHtml"
        class="content__docWrapper"
      )
      v-btn(
        class="main-button mx-auto d-block mt-6"
        @click="downloadPdf"
        :loading="loading"
      ) {{ $t('Download PDF') }}
</template>

<script>
import html2pdf from 'html2pdf.js'
import TaxReports from '~/modules/reports/models/TaxReports'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  name: 'BlockTaxReportView',
  components: {
    EProgressCircular
  },
  props: {
    reportType: {
      type: String,
      default: () => {}
    },
    payload: {
      type: [Object, String],
      default: null
    },
    draft: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    docHtml: null,
    loading: null,
    viewLoading: false,
    showDocWrapper: false
  }),
  computed: {
    model () {
      return TaxReports
    }
  },
  created () {
    this.getReportView()
  },
  methods: {
    async getReportView () {
      try {
        this.viewLoading = true
        let method = null
        if (this.draft) {
          if (this.reportType === this.model.REPORT_TYPES.thirdTwo) {
            method = 'getTemporaryReportDraftHtml'
          } else if (this.reportType === this.model.REPORT_TYPES.thirdFive || this.reportType === this.model.REPORT_TYPES.thirdFiveOld) {
            method = 'getReportDraftHtml'
          } else if (this.reportType === this.model.REPORT_TYPES.second || this.reportType === this.model.REPORT_TYPES.secondOld) {
            method = 'getAnnualReportDraftHtml'
          }
        } else if (this.reportType === this.model.REPORT_TYPES.thirdTwo) {
          method = 'getTemporaryReportHtml'
        } else if (this.reportType === this.model.REPORT_TYPES.thirdFive || this.reportType === this.model.REPORT_TYPES.thirdFiveOld) {
          method = 'getReportHtml'
        } else if (this.reportType === this.model.REPORT_TYPES.second || this.reportType === this.model.REPORT_TYPES.secondOld) {
          method = 'getAnnualReportHtml'
        }
        this.docHtml = this._.get(await this.model.api()[method](this.payload), 'response.data')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.viewLoading = false
      }
    },
    downloadPdf () {
      html2pdf().set({
        margin: 3,
        filename: 'tax-report.pdf'
      }).from(document.getElementById('docWrapper')).save()
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  height: 100%;

  &__docWrapper {
    flex-grow: 1;
    overflow: auto;
    padding-right: 5px;
  }
}
</style>
