<template lang="pug">
  div(class="success-state")
    img(
      class="success-state__illustration"
      src="~/assets/images/racoons/racoon-success.svg"
      alt="Raccoon"
    )

    div(class="success-state__content")
      h2(class="success-state__title") {{ title }}
      p(class="success-state__description") {{ description }}

      div(class="success-state__button-wrapper")
        v-btn(
          class="success-state__button main-button"
          @click="completeOperation"
        ) {{ $t('Okay') }}
</template>

<script>
import { OperationType } from '~/modules/goods/views/import-export-flow/const/operationType'

export default {
  name: 'MSuccessState',
  props: {
    closeModal: {
      type: Function,
      default: null
    },
    operationType: {
      type: String,
      required: true
    }
  },
  computed: {
    title () {
      switch (this.operationType) {
        case OperationType.EXPORT:
          return this.$t('The file for export is ready')
        case OperationType.UPLOAD:
          return this.$t('Goods have been uploaded')
        case OperationType.DELETE:
          return this.$t('Goods have been successfully deleted')
        default:
          return this.$t('The operation is completed')
      }
    },
    description () {
      switch (this.operationType) {
        case OperationType.EXPORT:
          return this.$t('You can continue working with exported goods')
        case OperationType.UPLOAD:
          return this.$t('You can continue working with the goods')
        case OperationType.DELETE:
          return this.$t('You can continue working')
        default:
          return this.$t('You can continue working')
      }
    }
  },
  methods: {
    completeOperation () {
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.success-state {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;

  &__illustration {
    width: 200px;
    height: 200px;
    align-self: center;
    margin-bottom: 20px;
    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 106px;
      height: 106px;
    }
  }

  &__content {
    width: 100%;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
    text-align: left;
    color: #202E43;
    font-family: 'e-Ukraine', serif;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__description {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    color: #555;
    font-family: 'e-Ukraine', serif;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 50px;
  }

  &__button {
    width: 200px;
    height: 48px;
    align-self: flex-end;
    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 100%;
      height: 48px !important;
    }
  }
}
</style>
