<template lang="pug">
  v-snackbar(:value="isOpened" class="information-snackbar" :timeout="timeout")
    | {{ text }}
    v-btn(
      v-if="onButtonClick"
      :text="buttonTextVariant"
      :class="buttonClass"
      @click="onButtonClick"
    ) {{ $t(buttonText) }}
</template>

<script>
import Snackbar from '~/components/modules/snackbar/models/Snackbar'

export default {
  name: 'InformationSnackbar',
  props: {
    type: {
      type: String,
      default: 'information'
    }
  },
  computed: {
    snackbar () {
      return Snackbar.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.snackbar, 'isOpen', false)
    },
    params () {
      return this._.get(this.snackbar, 'params', {})
    },
    text () {
      return this._.get(this.params, 'text', '')
    },
    timeout () {
      return this._.get(this.params, 'timeout', 5000)
    },
    wrapperClass () {
      return this._.get(this.params, 'wrapperClass')
    },
    onButtonClick () {
      return this._.get(this.params, 'onButtonClick', null)
    },
    buttonText () {
      return this._.get(this.params, 'buttonText', '')
    },
    buttonClass () {
      return this._.get(this.params, 'buttonClass', '')
    },
    buttonTextVariant () {
      return this._.get(this.params, 'buttonTextVariant', false)
    }
  },
  created () {
    if (!this.snackbar) {
      Snackbar.insert({
        data: {
          type: this.type
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
