<template lang="pug">
  div(class="a-bank-account-application-loader")
    div(class="a-bank-account-application-loader__banner")
      p(class="a-bank-account-application-loader__banner-icon") {{ icon }}
      h3(class="a-bank-account-application-loader__banner-title") {{ $t(title) }}
      h3(class="a-bank-account-application-loader__banner-text") {{ $t(text) }}
      div(v-if="!error" class="a-bank-account-application-loader__banner-progress-wrapper")
        v-progress-linear(
          class="a-bank-account-application-loader__banner-progress"
          indeterminate
        )
    div(v-if="error" class="a-bank-account-application-loader__actions")
      v-btn(
        outlined
        :class="{ 'w-100': isResponsive }"
        color="#161B25"
        @click="resetBank"
      ) {{ $t('Choose another bank') }}
    div(v-else class="a-bank-account-application-loader__actions a-bank-account-application-loader__actions--loader")
      span(class="a-bank-account-application-loader__hint" :class="{ 'pb-4 text-center': isResponsive }") {{ $t('The process will not be rejected, we will inform you about the bank`s decision') }}
      v-btn(
        outlined
        class="a-bank-account-application-loader__action"
        :class="{ 'w-100': isResponsive }"
        color="#161B25"
        @click="closeModal"
      ) {{ $t('Close window') }}
</template>

<script>
import AccountOrder from '~/modules/acquiring/models/AccountOrder'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  name: 'BlockABankAccountApplicationLoader',
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    showBankSelectionForm: {
      type: Function,
      default: () => {}
    },
    changeStep: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    steps: {
      type: Object,
      default: () => {}
    },
    accountOrder: {
      type: Object,
      default: () => {}
    },
    showError: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    error: false,
    intervalRequest: null
  }),
  computed: {
    model () {
      return AccountOrder
    },
    icon () {
      if (this.error) {
        return '❌'
      }
      return '✍️'
    },
    title () {
      if (this.error) {
        return 'Unfortunately, you received a refusal from the bank to open a FOP account'
      }
      return 'The application is under consideration at the bank'
    },
    text () {
      if (this.error) {
        return 'But you can still try to get services from another bank'
      }
      return 'It will take no more than 2 minutes'
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  created () {
    this.changeTitle('Opening an FOP account')

    if (this.showError) {
      this.showErrorScreen()
    } else {
      this.toggleCloseBtn(false)
      this.checkInformation()
    }
  },
  beforeDestroy () {
    if (this.intervalRequest) {
      this.intervalRequest.stopExponential()
    }
  },
  methods: {
    checkStatus (id) {
      const request = new IntervalRequest(() => this.model.api().read(id), {
        interval: 1e3,
        count: 300,
        maxDelay: 3e5 // ~5 minutes
      })
      this.intervalRequest = request
      return request.startExponential((response) => {
        const status = this._.get(response, 'response.data.status', null)
        return status === this.model.STATUSES.success || status === this.model.STATUSES.error
      })
    },
    showErrorScreen () {
      this.error = true
      this.toggleCloseBtn(true)
    },
    resetBank () {
      this.changeTitle('Add POS-terminal')
      this.showBankSelectionForm(true)
    },
    async checkInformation () {
      try {
        this.error = false
        this.toggleCloseBtn(false)
        const status = this._.get(await this.checkStatus(this.accountOrder.id), 'response.data.status', null)
        if (status === this.model.STATUSES.error) {
          this.showErrorScreen()
        } else if (status === this.model.STATUSES.success) {
          this.changeStep(this.steps.acquiringOrder)
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.showErrorScreen()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-account-application-loader {
  text-align: center;

  &__banner {
    border-radius: 12px;
    background: #F7F7F7;
    padding: 45px 0;

    &-icon {
      font-size: 48px;
      margin-bottom: 40px;
      color: #000;

      @media (max-width: map-get($breakpoints, 'sm')) {
        font-size: 40px;
        margin-bottom: 30px;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 12px;
      color: #000;

      @media (max-width: map-get($breakpoints, 'sm')) {
        font-size: 18px;
      }
    }

    &-text {
      font-size: 14px;
      font-weight: 300;
      color: #000;
      margin: 0;

      @media (max-width: map-get($breakpoints, 'sm')) {
        font-size: 12px;
      }
    }

    &-progress {
      margin-top: 40px;

      @media (max-width: map-get($breakpoints, 'sm')) {
        margin-top: 30px;
      }

      &-wrapper {
        max-width: 400px;
        margin: 0 auto;

        @media (max-width: map-get($breakpoints, 'sm')) {
          max-width: 240px;
        }
      }
    }
  }

  &__hint {
    color: rgba(22, 27, 37, 0.50);
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    max-width: 350px;
    text-align: left;
  }

  &__actions {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &--loader {
      justify-content: space-between;
      align-items: center;

      @media (max-width: map-get($breakpoints, 'sm')) {
        flex-direction: column;
      }
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-top: 24px;
    }
  }
}
</style>
