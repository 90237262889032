<template lang="pug">
  ValidationObserver(
    ref="tableValidation"
    slim
  )
    v-data-table(
      v-bind="tableConfig"
      ref="table"
      :options.sync="options"
      :headers="headers"
      :items="rows"
      :item-key="model.entity"
      :loading-text="$t('Loading... Please wait')"
      :locale="$config.locale.iso"
      @update:options="onOptionsUpdate"
      :class="['m-orm-table']"
      :show-select="showSelect"
    )
      template(
        v-if="showSelect"
        v-slot:header.data-table-select="{ header }"
      )
        v-checkbox(
          v-if="_.get(tableItemConfig, 'checkbox')"
          :value="allRowsSelected"
          @change="selectAllRows"
          color="#04AED4"
          class="mt-0"
          :ripple="!smDevice"
          hide-details
        )
      template(v-if="showEmpty" v-slot:no-data)
        m-block-empty-table
      template(v-if="loader" v-slot:body)
        tr
          td(colspan="100" class="m-orm-table__loader-wrap")
            e-overlay-block(:loading="loader" opacity="0" progress-size="xl" progress-width="4")

      template(v-if="prepend" v-slot:body.prepend="{headers}")
        template(v-if="!xsDevice")
          t-orm-item(
            v-for="(item, idx) in prepend.items"
            :key="item.id"
            :model="prepend.model"
            :scopedItem="generatePrependScoped(item, headers, idx)"
            :config="prependTableConfig"
            :dialogModel.sync="prepend.model"
            class="m-orm-table__prepend-tr"
            @update:dialogModel="updateDialogModel"
            :requestParams="requestParams"
          )
        template(v-for="(item, idx) in prepend.items" v-else)
          t-orm-item-mobile(
            :model="prepend.model"
            :scopedItem="generatePrependScoped(item, headers, idx)"
            :config="prependTableConfig"
            class="m-orm-table__prepend-tr"
            :requestParams="requestParams"
            :item-name="prepend.model.ormMobileTitle"
            :item-subtitle="prepend.model.ormMobileSubtitle"
            :item-suptitle="prepend.model.ormMobileSuptitle"
            :additional-text="prepend.model.ormMobileText"
            :items-length="prepend.items.length"
            :expanded="expanded"
            @expand="expandRow"
            :item-index="getItemIndex(generatePrependScoped(item, headers, idx))"
          )
          t-orm-item-mobile-expanded(
            v-if="isExpanded(generatePrependScoped(item, headers, idx))"
            :scopedItem="generatePrependScoped(item, headers)"
            :config="prependTableConfig"
            :model="prepend.model"
            :requestParams="requestParams"
          )

      template(v-slot:top)
        m-block-table-hint(:model="model" :loading="loader" :hint="hint")

        slot(name="top" v-if="!hideTopBar")
          t-orm-toolbar(:color="topBarBackground")
            v-row(:justify="showToolbarLeftSide && !reactiveSelectedItems.length ? 'space-between' : 'end'")
              v-col(
                v-if="showToolbarLeftSide"
                cols="12"
                sm="auto"
                :class="{ 'pl-0': !xsDevice, 'm-orm-table__toolbar-left': headersFilter }"
              )
                t-orm-buttons(
                  v-if="showButtons"
                  v-bind="buttonsConfig"
                )
                v-select(
                  v-if="headersFilter"
                  :value="headersFilterValue"
                  :items="tableHeadersItems"
                  :label="$t('Table settings')"
                  @change="handleTableHeadersChange"
                  class="m-orm-table__headers-select pl-3"
                  outlined
                  hide-details
                  multiple
                  return-object
                  small-chips
                  deletable-chips
                  clearable
                )
              v-col(cols="12" sm="auto" class="py-0 table-filtration" :class="{ 'w-100': filtersFullWidth }" :key="key")
                // TODO: decide do we need to show TOrmFiltersMobile in every component
                template(v-if="xsDevice && model.ormFiltersMobile")
                  t-orm-filters-mobile(v-model="filters" :model="model")
                template(v-else)
                  t-orm-filters(v-if="model.ormFilters" v-model="filters" :model="model")

        component(
          v-model="dialog"
          :is="dialogModel.ormDialogs[ormDialog]"
          v-bind="tableDialogConfig"
          @update:ormDialog="changeContextAction"
          @update:selection="defaultAction(dictionaryDataProcessor($event), false)"
        )

        m-block-table-warning(v-if="warning" :title="warningTitle" :text="warningText")

        e-breadcrumbs(
          v-if="breadcrumbs && breadcrumbs.length"
          :items="breadcrumbs"
          class="mb-4 mt-1"
        )

      template(
        v-if="xsDevice"
        v-slot:item="scopedItemProps"
      )
        t-orm-item-mobile(
          :scopedItem="scopedItemProps"
          :item-subtitle="model.ormMobileSubtitle"
          :item-name="model.ormMobileTitle"
          :item-suptitle="model.ormMobileSuptitle"
          :additional-text="model.ormMobileText"
          :model="model"
          :config="tableItemConfig"
          :expanded="expanded"
          :requestParams="requestParams"
          @expand="expandRow"
          @select="selectRow"
          :selected="selected"
          :item-index="getItemIndex(scopedItemProps)"
        )
        t-orm-item-mobile-expanded(
          v-if="isExpanded(scopedItemProps)"
          :scopedItem="scopedItemProps"
          :config="tableItemConfig"
          :model="model"
          :requestParams="requestParams"
        )
      template(
        v-slot:item="scopedItemProps"
        v-else
      )
        t-orm-item(
          :scopedItem="scopedItemProps"
          :dialogModel.sync="model"
          :model="model"
          :config="tableItemConfig"
          @select="selectRow"
          :selected="selected"
          @update:dialogModel="updateDialogModel"
          :requestParams="requestParams"
        )
      template(v-if="'footer' in $slots" v-slot:footer)
        slot(name="footer")
</template>

<script>
import pagination from '~/mixins/tables/pagination'
import filtration from '~/mixins/tables/filtration'
import searching from '~/mixins/tables/searching'
import rows from '~/mixins/tables/rows'
import actions from '~/mixins/tables/actions'
import contexts from '~/mixins/props/contexts'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import {
  actions as ormActions,
  ormConfig
} from '~/const/global'
import TOrmItem from '~/components/templates/orm/t-orm-item'
import MOrmCrudDialog from '~/components/modules/dialogs/orm/m-orm-crud-dialog'
import MOrmGridDialog from '~/components/modules/dialogs/orm/m-orm-grid-dialog'
import MOrmSelectDialog from '~/components/modules/dialogs/orm/m-orm-select-dialog'
import MOrmDeleteDialog from '~/components/modules/dialogs/orm/m-orm-delete-dialog'
import MOrmCardDialog from '~/components/modules/dialogs/orm/m-orm-card-dialog'
import MOrmTableDialog from '~/components/modules/dialogs/orm/m-orm-table-dialog'
import MOrmSynchronizationDialog from '~/components/modules/dialogs/orm/m-orm-synchronization-dialog'
import MOrmAssignDialog from '~/components/modules/dialogs/orm/m-orm-assign-dialog'
import TOrmToolbar from '~/components/templates/orm/t-orm-toolbar'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import MOrmCopyDialog from '~/components/modules/dialogs/orm/m-orm-copy-dialog'
import MOrmSelectWithPopularDialog from '~/components/modules/dialogs/orm/m-orm-select-with-popular-dialog'
import MOrmFormsStepperDialog from '~/components/modules/dialogs/orm/m-orm-forms-stepper-dialog'
import MOrmDeactivateDialog from '~/components/modules/dialogs/orm/m-orm-deactivate-dialog'
import MOrmActivateDialog from '~/components/modules/dialogs/orm/m-orm-activate-dialog'
import MStepperDialog from '~/components/modules/dialogs/m-stepper-dialog'
import MOrmResetPasswordDialog from '~/components/modules/dialogs/orm/m-orm-reset-password-dialog'
import MOrmCashRegisterFormDialog from '~/components/modules/dialogs/orm/concrete/m-orm-cash-register-form-dialog'
import MOrmGoodsFormDialog from '~/modules/goods/views/components/m-orm-goods-form-dialog'
import MOrmSuppliesFormDialog from '~/modules/goods/views/components/m-orm-supplies-form-dialog'
import EmployeeFormDialog from '~/modules/employees/views/components/employee-form-dialog'
import MOrmOutletFormDialog from '~/components/modules/dialogs/orm/concrete/m-orm-outlet-form-dialog'
import MOrmViewDialog from '~/components/modules/dialogs/orm/m-orm-view-dialog'
import MBlockTableHint from '~/components/modules/blocks/concrete/m-block-table-hint'
import responsive from '~/mixins/pages/responsive'
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'
import EBreadcrumbs from '~/components/elements/breadcrumbs/e-breadcrumbs'
import TaxRatesFormDialog from '~/modules/tax-rates/views/components/tax-rates-form-dialog'
import TOrmItemMobile from '~/components/templates/orm/t-orm-item-mobile'
import TOrmItemMobileExpanded from '~/components/templates/orm/t-orm-item-mobile-expanded'
import TOrmFiltersMobile from '~/components/templates/orm/t-orm-filters-mobile'
import MBlockTableWarning from '~/components/modules/blocks/concrete/m-block-table-warning'
import MBlockEmptyTable from '~/components/modules/blocks/concrete/tables/m-block-empty-table'

export default {
  components: {
    MBlockEmptyTable,
    TOrmFiltersMobile,
    TOrmItemMobileExpanded,
    TOrmItemMobile,
    EBreadcrumbs,
    EOverlayBlock,
    MOrmViewDialog,
    MOrmOutletFormDialog,
    EmployeeFormDialog,
    MOrmCashRegisterFormDialog,
    MOrmResetPasswordDialog,
    MStepperDialog,
    MOrmActivateDialog,
    MOrmDeactivateDialog,
    MOrmFormsStepperDialog,
    MOrmSelectWithPopularDialog,
    MOrmCopyDialog,
    MOrmTableDialog,
    TOrmItem,
    MOrmCrudDialog,
    MOrmSelectDialog,
    MOrmDeleteDialog,
    MOrmCardDialog,
    MOrmSynchronizationDialog,
    MOrmAssignDialog,
    MOrmGridDialog,
    TOrmButtons,
    TOrmToolbar,
    MBlockTableHint,
    MOrmGoodsFormDialog,
    MOrmSuppliesFormDialog,
    TaxRatesFormDialog,
    MBlockTableWarning
  },
  mixins: [pagination, filtration, searching, rows, contexts, checkPropCtx, actions, responsive],
  inheritAttrs: false,
  props: {
    model: {
      type: Function,
      required: true
    },
    hideTopBar: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: Object,
      default: null
    },
    defaultFilter: {
      type: Object,
      default: null
    },
    itemsFilter: {
      type: Object,
      default: null
    },
    parentItem: {
      type: [Object, String],
      default: null
    },
    // TODO: what is it ?
    topBarBackground: {
      type: String,
      default: 'white'
    },
    buttons: {
      type: Array,
      default: _ => ([])
    },
    breadcrumbs: {
      type: Array,
      default: _ => ([])
    },
    customRequest: {
      type: Function,
      default: null
    },
    requestRoute: {
      type: [Function, String, Boolean],
      default: ''
    },
    headersFilter: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    warning: {
      type: Object,
      default: null
    },
    filtersFullWidth: {
      type: Boolean,
      default: false
    },
    extraDialogParams: {
      type: Object,
      default: () => ({})
    },
    tableFilters: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      tableLoading: false,
      ormDialog: 'default',
      dialogModel: this.model,
      selected: [],
      expanded: [],
      headersFilterValue: null,
      customTableHeaders: [],
      key: 0,
      allRowsSelected: false,
      tableMaxHeight: null
    }
  },
  computed: {
    reactiveSelectedItems () {
      const selectedItemsIds = this._.map(this.selected, i => i?.id)
      if (this.model && selectedItemsIds.length) {
        return this.model.query().whereId(selectedItemsIds).all()
      }
      return []
    },
    showEmpty () {
      return this.rows && !this.rows.length && (!this.prepend || (this.prepend && this.prepend.items && !this.prepend.items.length))
    },
    showToolbarLeftSide () {
      return this.showButtons || this.headersFilter
    },
    headers () {
      return this.headersFilter ? this.customTableHeaders : this.tableHeaders
    },
    loader () {
      return this.tableLoading || this.loading
    },
    modelTableConfig () {
      // NOTE: Config will be cached once.
      return this.model.getTableConfig()
    },
    tableConfig () {
      return Object.assign(this.$config.table, this.modelTableConfig, this.$pagination, this.$searching, this.$attrs)
    },
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.ormDialog)
    },
    buttonsConfig () {
      const trans = this._.isFunction(this.model.texts)
        ? this.model.texts().buttons.create
        : this.model.ormTrans.single

      const list = this.buttons.map(btn => ({ ...btn, loading: this.loader }))
      const action = this._.get(list, '[0].action')
      const ormToolbarButtons = this._.map(this._.get(this.model, 'ormToolbarButtons', []), (item) => {
        item.parentCtx = this
        return item
      })
      const items = [
        {
          text: `${this.$t('Add')} ${this._.lowerFirst(this.$tc(trans, 1))}`,
          dictionaryFirst: this.hasDictionary,
          attrs: {
            color: 'primary',
            class: ['main-button w-100'],
            wrapperClass: [this.xsDevice ? 'flex-grow-1' : 'mr-2']
          },
          visible: item => this.model.entity !== 'users' && this.$ability.can(ormActions.create, this.model[ormConfig.modelKey]),
          loading: this.loader,
          call: this.defaultAction
        },
        ...ormToolbarButtons
      ]

      switch (action) {
        case 'add':
          items.push(...this._.get(list, '[0].items'))
          break
        case 'replace':
          this._.merge(items, this._.get(list, '[0].items'))
          break
      }

      const extraButtonsClasses = this._.get(this.buttons, '[0].buttonsClasses', null)

      return {
        context: this.context,
        classes: extraButtonsClasses ? ['m-orm-table__buttons', ...extraButtonsClasses] : ['m-orm-table__buttons'],
        items
      }
    },
    showButtons () {
      return this.buttonsConfig.items.some((item) => {
        return this._.isFunction(item.visible) ? item.visible(item) : item.visible
      })
    },
    tableHeaders () {
      const visibleHeaders = this._.filter(this.model.ormHeaders, (item) => {
        if (item.visible === undefined || (this._.isFunction(item.visible) && item.visible(this))) {
          return item
        }
      })
      return this._.map(visibleHeaders, item => (Object.assign({}, {
        ...item
      }, {
        text: this._.upperFirst((item.text.includes('|'))
          ? this.$tc(item.text.split('|')[0], item.text.split('|')[1])
          : this.$tc(item.text, 1))
      })))
    },
    tableHeadersItemAllValue () {
      return 'allHeaders'
    },
    tableHeadersItemAll () {
      return { text: this.$t('All'), value: this.tableHeadersItemAllValue }
    },
    tableHeadersItems () {
      const items = [...this.tableHeaders]
      if (items.length > 1) {
        items.unshift(this.tableHeadersItemAll)
      }
      return items
    },
    tableDialogConfig () {
      return Object.assign({
        type: this.ormDialog,
        model: this.dialogModel,
        loading: this.loader,
        ref: 'modal',
        extraParams: this.extraDialogParams
      }, this.dialogModel.getOrmDialogsConfig(this.ormDialog, {}))
    },
    tableItemConfig () {
      const intersectedActions = this.intersectedActions(this.model)
      const dblClickActionName = this.modelTableConfig.dblClickAction
      const dblClickAction = this._.get(this._.find(intersectedActions, i => i?.name === dblClickActionName), 'call', null)
      const alternateDblClickAction = this.modelTableConfig.dblClickAction && this[this.modelTableConfig.dblClickAction + 'Action']

      return Object.assign({
        dblclick: dblClickAction || alternateDblClickAction,
        actions: this.$actions,
        showSelect: this.showSelect,
        intersectedActions
      }, this.model.ormRowsConfig)
    },
    prependTableConfig () {
      if (!this.prepend) {
        return null
      }
      const intersectedActions = this.intersectedActions(this.prepend.model)
      const dblClickActionName = this.prepend.model.getTableConfig().dblClickAction
      const dblClickAction = this._.get(this._.find(intersectedActions, i => i?.name === dblClickActionName), 'call', null)
      const alternateDblClickAction = this.prepend.model.getTableConfig().dblClickAction && this[this.prepend.model.getTableConfig().dblClickAction + 'Action']

      return Object.assign({
        dblclick: dblClickAction || alternateDblClickAction,
        actions: this.$actions,
        showSelect: this.showSelect,
        intersectedActions
      }, this.prepend.model.ormRowsConfig)
    },
    empty () {
      return this.$t('Table is empty')
    },
    warningTitle () {
      return this._.get(this.warning, 'title', '')
    },
    warningText () {
      return this._.get(this.warning, 'text', '')
    },
    allTableItemsIndexes () {
      const prependItems = this._.map(this.prepend?.items, (i, idx) => `prepend-${idx}`)
      const items = this._.map(this.rows, (i, idx) => idx)
      return [...prependItems, ...items]
    }
  },
  watch: {
    rows (val) {
      this.$set(this.$data, 'selected', [])
      this.$set(this.$data, 'allRowsSelected', false)
      this.$nextTick(() => {
        this.setActionsHeight()
      })
      this.setTableMinWidth(val?.length)
    },
    headersFilterValue (val) {
      const firstItemValue = this._.get(this._.first(val), 'value')
      const storageHeaders = this._.get(this.$localStorageClientData(), 'tableHeaders', {})
      this.customTableHeaders = firstItemValue === this.tableHeadersItemAllValue ? this.tableHeaders : val
      this.$setLocalStorageClientData({
        tableHeaders: {
          ...storageHeaders,
          [this.model.entity]: this.customTableHeaders
        }
      })
    }
  },
  created () {
    if (this.$route.hash === '#createDialogOpen') {
      this.defaultAction()
    }
    this.setTableHeaders()
  },
  mounted () {
    this.changeSortByText()
    this.calculateTableMaxHeight()
  },
  methods: {
    setTableMinWidth (length) {
      const table = this._.get(this.$refs, 'table.$el')
      if (!table) {
        return
      }
      const tableWrapper = table.querySelector('.v-data-table__wrapper')
      if (tableWrapper) {
        if (length === 1) {
          tableWrapper.style.minHeight = '110px'
        } else if (length === 2) {
          tableWrapper.style.minHeight = '160px'
        } else if (length === 3) {
          tableWrapper.style.minHeight = '210px'
        } else {
          tableWrapper.style.minHeight = '250px'
        }
      }
    },
    getItemIndex (item) {
      return this._.findIndex(this.allTableItemsIndexes, i => i === item?.index)
    },
    intersectedActions (model) {
      return this._.map(model.ormActions, (ormAction) => {
        return Object.assign({}, this._.find(this.$actions, ['name', ormAction.name]), ormAction)
      })
    },
    calculateTableMaxHeight () {
      const table = this._.get(this.$refs, 'table.$el')
      if (!table) {
        return
      }
      const tableWrapper = table.querySelector('.v-data-table__wrapper')
      const inDialog = table.closest('.v-dialog')
      if (!inDialog) {
        const pageToolbarHeight = this._.get(document.querySelector('.t-orm-page__toolbar-card'), 'clientHeight', 0)
        const tableToolbarHeight = this._.get(table.querySelector('.m-orm-toolbar'), 'clientHeight', 0)
        const tableHintHeight = this._.get(table.querySelector('.info-hint'), 'clientHeight', 0)
        const tableFooterHeight = this._.get(table.querySelector('.v-data-footer'), 'clientHeight', 0)
        tableWrapper.style.maxHeight = `calc(90vh - ${pageToolbarHeight}px - ${tableToolbarHeight}px - ${tableHintHeight}px - ${tableFooterHeight}px)`
      } else {
        tableWrapper.style.maxHeight = '50vh'
      }
      this.tableMaxHeight = tableWrapper.style.maxHeight
    },
    rerenderFields () {
      this.key += 1
    },
    setTableHeaders () {
      if (this.headersFilter && Array.isArray(this.tableHeaders)) {
        const storageHeaders = this._.get(this.$localStorageClientData(), `tableHeaders.${this.model.entity}`, [])
        if (storageHeaders.length) {
          const allHeaders = this._.filter(storageHeaders, item => item.value !== this.tableHeadersItemAllValue).length === this.tableHeaders.length
          this.headersFilterValue = allHeaders ? [this.tableHeadersItemAll] : storageHeaders
        } else {
          this.headersFilterValue = this.tableHeaders.length > 1 ? [this.tableHeadersItemAll] : this.tableHeaders
        }
      }
    },
    handleTableHeadersChange (val) {
      let items = val || []
      const hasAllItem = this._.filter(this.tableHeadersItems, item => item.value === this.tableHeadersItemAllValue).length
      const hasAllItemInValue = this._.filter(items, item => item.value === this.tableHeadersItemAllValue).length
      const allItemSelectedLast = this._.get(this._.last(items), 'value') === this.tableHeadersItemAllValue
      if (hasAllItem) {
        if (allItemSelectedLast || !items.length || (items.length === this.tableHeadersItems.length - 1 && !hasAllItemInValue)) {
          items = [this.tableHeadersItemAll]
        } else if (hasAllItemInValue && items.length > 1) {
          items = this._.filter(items, item => item.value !== this.tableHeadersItemAllValue)
        }
      } else if (!items.length) {
        items = [...this.tableHeadersItems]
      }
      this.headersFilterValue = items.sort((a, b) => this.tableHeadersItems.indexOf(a) - this.tableHeadersItems.indexOf(b))
    },
    changeSortByText () {
      if (this.xsDevice) {
        const sortLabel = document.querySelectorAll('.v-data-table-header-mobile .v-input .v-label')
        sortLabel.forEach((label) => {
          if (label.textContent === 'Sort by') {
            label.textContent = this.$t(label.textContent)
          }
        })
      }
    },
    updateDialogModel (model) {
      this.dialogModel = model
    },
    onOptionsUpdate (options) {
      const itemsPerPage = this._.get(options, 'itemsPerPage')
      const modelEntity = this._.get(this.model, 'entity')

      if (itemsPerPage && modelEntity) {
        this.$setLocalStorageClientData({
          itemsPerPage: {
            ...this._.get(this.$localStorageClientData(), 'itemsPerPage'),
            [modelEntity]: itemsPerPage
          }
        })
      }
    },
    generatePrependScoped (item, headers, idx) {
      return {
        item,
        headers,
        index: `prepend-${idx}`
      }
    },
    dictionaryDataProcessor (data) {
      return this.hasDictionary && this._.isFunction(this.dialogConfig.dictionary.dataProcessor) ? this.dialogConfig.dictionary.dataProcessor(data) : data
    },
    fixedActionsClass () {
      const wrapper = this._.get(this.$refs, 'table.$el', null)
      const table = wrapper && wrapper.querySelector('table')
      const hasActions = this._.get(this.model, 'ormActions', []).length

      if (hasActions && wrapper && table && (table.clientWidth > wrapper.clientWidth) && !this.xsDevice) {
        return 'fixed-actions'
      }
    },
    setActionsHeight () {
      const table = this._.get(this.$refs, 'table', null)

      if (table) {
        const theadRow = table.$el.querySelector('table thead tr')
        const actionsTh = theadRow && theadRow.lastChild

        if (actionsTh) {
          actionsTh.style.height = theadRow.clientHeight + 'px'
        }
      }
    },
    selectAllRows (val) {
      if (val) {
        this.selected = this.rows
      } else {
        this.selected = []
      }
      this.allRowsSelected = val
      this.$emit('select', this.selected)
    },
    selectRow (item, val) {
      if (val) {
        this.selected.push(item)
      } else {
        const key = this._.get(this.tableItemConfig, 'checkbox.selectedKey') || 'id'
        this.selected = this._.filter(this.selected, selected => selected[key] !== item[key])
      }
      this.allRowsSelected = this._.get(this.selected, 'length') === this._.get(this.rows, 'length')
      this.$emit('select', this.selected)
    },
    expandRow (item, val) {
      if (val) {
        this.expanded.push(item)
      } else {
        this.expanded = this._.filter(this.expanded, expanded => expanded.index !== item.index)
      }
    },
    isExpanded (item) {
      const filtered = this._.filter(this.expanded, expanded => expanded.index === this._.get(item, 'index', null))
      return filtered && Boolean(filtered.length)
    }
  }
}
</script>

<style lang="scss">
.m-orm-table {
  /*TODO because tables do not have full width in modal dialogs (ex. goods card page)*/
  width: 100%;

  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (min-width: map-get($breakpoints, 'sm')) {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }

  .v-data-table__wrapper {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    overflow: auto;

    table {
      overflow: auto;

      thead {
        position: sticky;
        background-color: #fff;
        top: 0;
        z-index: 4;
      }
    }
  }

  .v-data-footer {
    border-top: none !important;
  }

  &__loader-wrap {
    position: relative;
    height: 300px !important;
  }

  .table-filtration {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px 24px;
  }

  .v-label {
    &--active {
      background: #fff;
      padding-right: 3px;
      margin-right: -3px;
      z-index: 3;
    }
  }

  &__headers-select {
    max-width: 300px;

    .v-select {
      &__selections {
        max-height: 100px;
        padding: 0;

        &::-webkit-scrollbar-track {
          margin: 2px 0;
        }
      }
    }
  }

  &__toolbar-left {
    display: flex;

    .col {
      flex: 0;
    }
  }
}
</style>
