import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'

export class AcquiringSettings extends PersistAble(OrmModel) {
  static entity = 'acquiringsettings'
  static paginated = true
  static defaultSortOrder = true

  static ormTrans = {
    single: 'Acquiring setting',
    multy: 'Acquiring settings'
  }

  static fields () {
    return {
      id: this.attr(null),
      cashRegister: this.attr(null),
      name: this.attr(null),
      qrCodes: this.attr(null),
      type: this.attr(null),
      token: this.attr(null),
      valid: this.attr(null)
    }
  }

  get cashRegisterString () {
    const cashRegister = this.cashRegister
    return cashRegister ? `№${cashRegister.number} (${cashRegister.fiscalNumber})` : '-'
  }

  get terminalNameString () {
    return this.name ? this.name : '-'
  }

  static ormHeaders = [
    { text: 'Terminal name', value: 'terminalNameString', sortable: false, filterable: false },
    { text: 'Connected to cash register', value: 'cashRegisterString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'edit',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-qr-acquiring-form',
          width: '900px',
          contentNoGutters: true,
          componentProps: {
            item,
            isEditing: true,
            tableRequest: async () => await AcquiringSettings.api().read(item.id)
          }
        })
      }
    },
    {
      name: 'delete',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        dialog.open({
          title: item.type === 'qr' ? 'Are you sure you want to delete QR settings?' : 'Are you sure you want to delete Internet settings?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            await AcquiringSettings.api().del(item)
            AppNotifications.success(item.type === 'qr' ? 'QR setup successfully deleted' : 'Internet setup successfully deleted')
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.checkMonoToken = function (payload) {
        return this.post(Model.$routes.acquiringsettings.checkMonoToken(), payload, { save: false })
      }
      configActions.employeeList = function (id) {
        return this.get(Model.$routes.acquiringsettings.employeeList(id), { save: false })
      }
      return configActions
    }
  }
}

export default AcquiringSettings
