var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('m-dialog-form',{ref:"form",staticClass:"login-form mb-2",attrs:{"onSubmitCall":_vm.submit,"content-class-name":"px-8"}},[_c('template',{slot:"header"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center pb-0",attrs:{"sm":"12"}},[_c('h2',{staticClass:"login-wrapper__title px-2"},[_vm._v(_vm._s(_vm.$t('Registration')))])])],1)],1),_c('template',{slot:"default"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pt-4",attrs:{"sm":"12"}},[_c('ValidationProvider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-input-mask',_vm._b({staticClass:"app-outlined-input",attrs:{"mask":"mobile","error-messages":errors,"outlined":""},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}},'e-input-mask',_vm.fields.phone.attrs,false))]}}])},'ValidationProvider',_vm.fields.phone.provider,false)),_c('ValidationProvider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({staticClass:"app-outlined-input",attrs:{"error-messages":errors,"outlined":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}},'v-text-field',_vm.fields.email.attrs,false))]}}])},'ValidationProvider',_vm.fields.email.provider,false)),_c('ValidationProvider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._b({staticClass:"app-outlined-input",attrs:{"error-messages":errors,"outlined":""},model:{value:(_vm.formData.profile.firstName),callback:function ($$v) {_vm.$set(_vm.formData.profile, "firstName", $$v)},expression:"formData.profile.firstName"}},'v-text-field',_vm.fields.firstName.attrs,false))]}}])},'ValidationProvider',_vm.fields.firstName.provider,false)),_c('ValidationProvider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-input-password',_vm._b({staticClass:"app-outlined-input",attrs:{"error-messages":errors,"outlined":""},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}},'e-input-password',_vm.fields.password.attrs,false))]}}])},'ValidationProvider',_vm.fields.password.provider,false)),_c('ValidationProvider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-input-password',_vm._b({staticClass:"app-outlined-input",attrs:{"error-messages":errors,"outlined":""},model:{value:(_vm.formData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirmation", $$v)},expression:"formData.passwordConfirmation"}},'e-input-password',_vm.fields.passwordConfirmation.attrs,false))]}}])},'ValidationProvider',_vm.fields.passwordConfirmation.provider,false)),_c('div',{staticClass:"pt-0 mt-1"},[_c('span',{staticClass:"login-form__text mr-1"},[_vm._v(_vm._s(_vm.$t('By clicking'))+" “"+_vm._s(_vm.$t('Continue'))+"”, "+_vm._s(_vm.$t('you agree to the terms of the')))]),_c('e-link',{staticClass:"login-form__link",attrs:{"href":"https://checkbox.ua/publichna-oferta/"}},[_vm._v(_vm._s(_vm.$t('Public offer')))])],1)],1),_c('v-col',{staticClass:"text-center pb-4",attrs:{"cols":"12"}},[_c('e-button-main',{attrs:{"enableIcon":false,"loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t('Continue')))])],1)],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"login-form__footer"},[_vm._v(_vm._s(_vm.$t('Already have an account?'))),_c('e-link',{on:{"click":function($event){return _vm.$router.push('/auth/login')}}},[_vm._v(_vm._s(_vm.$t('Log in'))+" ->")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }