import Receipts from '~/modules/receipt/models/Receipts'
import commonData from '~/modules/receipt/mixins/getters/commonData'
import payLinkProcesses from '~/modules/receipt/mixins/payLinkProcesses'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'

const posTerminalProcesses = {
  mixins: [commonData, payLinkProcesses],
  data: () => ({
    printOnPosTerminalLoading: false,
    displayOnPosTerminalLoading: false
  }),
  methods: {
    async getPosTerminalSettings () {
      const cashRegisterId = this._.get(this.authenticatedCashier, 'cashRegister.id')
      if (!cashRegisterId) {
        return
      }
      let posTerminalSettings = PosTerminals.query().where(item => this._.get(item, 'cashRegister.id') === cashRegisterId).first()
      if (!posTerminalSettings) {
        posTerminalSettings = this._.get(await PosTerminals.api().filter({ 'cashRegister.id': cashRegisterId }).all(), 'response.data.data[0]')
      }
      return posTerminalSettings
    },
    async printOnPosTerminal (params = {}) {
      this.printOnPosTerminalLoading = true
      await this.posTerminalAction({
        method: 'print-receipt',
        ...params
      })
      await this.logPosTerminalAnalytics('pos_terminal_receipt_print')
      this.printOnPosTerminalLoading = false
    },
    async displayOnPosTerminal (params = {}) {
      this.displayOnPosTerminalLoading = true
      await this.posTerminalAction({
        method: 'display-receipt',
        ...params
      })
      await this.logPosTerminalAnalytics('pos_terminal_receipt_display')
      this.displayOnPosTerminalLoading = false
    },
    async posTerminalAction ({ method, posTerminalSettings, payload } = {}) {
      await this.makePosTerminalRequest({
        operationType: method,
        posTerminalSettings,
        payload,
        url: `${method}/${this._.get(posTerminalSettings, 'terminalName', null)}`
      })
    },
    async logPosTerminalAnalytics (eventName) {
      const loggingPayload = {
        event_name: eventName,
        event_date: new Date().getTime(),
        client_id: this._.get(this.$Organization, 'id'),
        client_info: {
          app_name: 'portal',
          app_version: 'NA',
          os_name: navigator?.platform,
          os_platform: navigator?.platform
        }
      }
      try {
        await Receipts.api().logAnalytics(loggingPayload)
      } catch (e) {
        this.$handlers.error(e, this, true)
      }
    },
    async makePosTerminalRequest ({ posTerminalSettings, payload, url, fetchMethod = 'POST', operationType = '' } = {}) {
      try {
        const payLinkTerminalId = this._.get(posTerminalSettings, 'payLinkTerminalId')

        if (payLinkTerminalId) {
          this.sentEventToPayLinkSocket({
            operation_type: operationType,
            terminal_id: payLinkTerminalId,
            payload
          })
        } else {
          const port = this._.get(posTerminalSettings, 'port', null)
          const ipAddress = this._.get(posTerminalSettings, 'ipAddress', null)
          const fetchUrl = `https://${ipAddress}:${port}/api/pos/${url}`

          if (fetchMethod === 'GET') {
            await fetch(fetchUrl, { method: 'GET' })
          } else {
            await fetch(fetchUrl, { method: 'POST', body: JSON.stringify(payload) })
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}

export default posTerminalProcesses
