<template lang="pug">
  div(class="dialog")
    div(class="dialog__title mb-2") {{ $t('Enter data for courier delivery') }}
    ValidationObserver(ref="form" slim)
      v-form(@submit.prevent.stop="onSubmitForm")
        t-orm-label(:item="{ label: { text: 'Add customer details' } }" class="mb-2")
        t-orm-fields(v-bind.sync="customerForm" :items="customerFields")

        t-orm-label(:item="{ label: { text: 'Add courier details' } }" class="mb-2")
        t-orm-fields(v-bind.sync="courierForm" :items="courierFields")

        div(class="dialog__actions")
          v-btn(
            type="submit"
            class="main-button d-block mx-auto"
            :loading="loading"
          ) {{ $t('Create a delivery') }}
</template>

<script>
import { formats } from '~/const/global'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import OrderDelivery from '~/modules/receipt/models/OrderDelivery'
import Employees from '~/modules/employees/models/Employees'

export default {
  name: 'BlockDeliveryOrderForm',
  components: {
    TOrmLabel,
    TOrmFields
  },
  props: {
    receipt: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    notFiscalize: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    loading: false,
    courierForm: {
      cashier: null,
      clientComment: ''
    },
    customerForm: {
      address: null,
      clientName: null,
      clientPhone: null,
      deliveryTime: null
    }
  }),
  computed: {
    courierFields () {
      return [
        new AutocompleteBuilder({
          model: 'cashier',
          label: 'Choose a courier',
          rules: 'required',
          itemClass: 'ws-pre',
          hideDetails: true,
          returnObject: true,
          query: model => model.api().filter({ mode: Employees.itemModes.checkbox.mode })
        }, EmployeesSearch).build(),
        {
          model: 'clientComment',
          component: 'v-text-field',
          provider: {
            vid: 'clientComment',
            name: 'Add a comment for the courier'
          },
          attrs: {
            label: 'Add a comment for the courier',
            outlined: true
          },
          classes: ['mt-6']
        }
      ]
    },
    customerFields () {
      return [
        {
          model: 'clientName',
          component: 'v-text-field',
          provider: {
            vid: 'clientName',
            name: 'Client name',
            rules: 'required'
          },
          attrs: {
            label: 'Client name',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'address',
          component: 'v-text-field',
          provider: {
            vid: 'address',
            name: 'Address',
            rules: 'required'
          },
          attrs: {
            label: 'Address',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'clientPhone',
          component: 'e-input-mask',
          provider: {
            vid: 'clientPhone',
            name: 'Phone',
            rules: 'required|phone'
          },
          attrs: {
            mask: 'mobile',
            label: 'Phone',
            outlined: true,
            autocomplete: 'off'
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'deliveryTime',
          component: 'e-input-datetime',
          provider: {
            vid: 'deliveryTime',
            name: 'Delivery time',
            rules: 'required'
          },
          attrs: {
            type: 'datetime',
            label: 'Delivery time',
            outlined: true,
            clearable: true
          },
          fieldVal: () => this.$moment().add(3, 'hours').set({ minute: 0, second: 0 }).format(formats.dateTimeSec),
          cols: {
            cols: 12,
            md: 6
          }
        }
      ]
    }
  },
  methods: {
    async onSubmitForm () {
      const valid = await this.$refs.form.validate()

      if (valid) {
        const receipt = this.receipt

        if (this.notFiscalize === false) {
          delete receipt.payments
        }

        const payload = {
          cashier_id: this._.get(this.courierForm, 'cashier.id'),
          receipt_draft: {
            ...receipt,
            cashier_name: this._.get(this.courierForm, 'cashier.name')
          },
          not_fiscalize: this.notFiscalize,
          delivery_details: {
            address: {
              street: this.customerForm.address
            },
            client_name: this.customerForm.clientName,
            delivery_time: this.customerForm.deliveryTime,
            client_comment: this.courierForm.clientComment,
            client_phone_number: this.customerForm.clientPhone.replaceAll('-', ''),
            thermal_dependence: false
          }
        }

        try {
          this.loading = true

          const token = await OrderDelivery.cashierToken()
          await OrderDelivery.api().createOrder(payload, token)

          this.closeModal(true)
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
