<template lang="pug">
  ValidationProvider(
    v-bind="_.get(config, 'validationProviderAttrs')"
    :vid="vid()"
    :name="label"
    mode="lazy"
    v-slot="{ errors }"
  )
    v-select(
      v-bind="_.get(config, 'attrs')"
      :label="label"
      :items="items"
      :value="value"
      @change="handleChange"
      :error-messages="errors"
      :class="_.get(config, 'classes', [])"
    )
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    value: null
  }),
  computed: {
    label () {
      return this.$t(this._.get(this.config, 'attrs.label'))
    },
    items () {
      const items = this._.get(this.config, 'items')
      if (this._.isFunction(items)) {
        return items()
      }
      return items
    }
  },
  mounted () {
    if (this._.isFunction(this._.get(this.config, 'default'))) {
      const val = this.config.default()
      this.value = val
      this.emitChange(val)
    }
  },
  methods: {
    vid () {
      const vid = this._.get(this.config, 'validationProviderAttrs.vid')
      if (this._.isFunction(vid)) {
        return vid(this.item)
      }
      return vid
    },
    handleChange (val) {
      this.value = val
      this.emitChange(val)
    },
    async emitChange (val) {
      if (this._.isFunction(this._.get(this.config, 'onChange'))) {
        await this.config.onChange(this.item, val)
      }
    }
  }
}
</script>

<style lang="scss">
  //
</style>
