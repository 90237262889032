import Employees from '~/modules/employees/models/Employees'
import commonData from '~/modules/receipt/mixins/getters/commonData'

const processingApiRequest = {
  mixins: [commonData],
  methods: {
    async processingApiRequest (params = {}) {
      const { request, employee, token } = params
      try {
        if (this._.isFunction(request)) {
          const authCashierToken = !employee ? this.authenticatedCashier?.accessToken : null
          return await request(token || authCashierToken)
        }
      } catch (e) {
        if (this._.get(e, 'response.data.code') === 498) {
          const cashier = employee || this.authenticatedCashier?.cashier
          const nextToken = this._.get(await Employees.api().processingSignin(cashier, { refresh: true }), 'response.data.access_token', null)
          if (!employee && this.authenticatedCashier?.saveData) {
            await this.authenticatedCashier.saveData({
              accessToken: nextToken
            })
          }
          return await this.processingApiRequest({
            ...params,
            token: nextToken
          })
        } else {
          throw e
        }
      }
    }
  }
}

export default processingApiRequest
