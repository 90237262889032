<template lang="pug">
  div(class="ettn-phone-error")
    div(class="ettn-phone-error__image")
      v-img(:src="require('~/assets/images/racoons/racoon-dont-know.svg')" :max-width="xsDevice ? 150 : 200" class="mx-auto")
    h2(class="ettn-phone-error__title")
      | {{ $t('Enter the sender phone number!') }}
    p(class="ettn-phone-error__text")
      | {{ $t('Unfortunately, we cannot obtain information on invoices created in the branch without the sender phone number.') }}
    ValidationObserver(ref="validator" slim)
      t-orm-fields(v-bind.sync="form" :items="fields")
    div(class="ettn-phone-error__actions")
      v-btn(class="secondary-button" @click="$router.push('/dashboard/cashregisters')")
        | {{ $t('Setup') }}
      v-btn(class="main-button" :loading="loading" @click="onSubmit")
        | {{ $t('Continue') }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import TOrmFields from '~/components/templates/orm/t-orm-fields'

export default {
  name: 'MBlockEttnOrderPhoneError',
  components: { TOrmFields },
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      form: {
        senderPhone: null
      },
      loading: false
    }
  },
  computed: {
    fields () {
      return [
        {
          model: 'senderPhone',
          component: 'e-input-mask',
          provider: {
            vid: 'phone',
            name: 'Sender phone',
            rules: 'required|phone'
          },
          attrs: {
            mask: 'mobile',
            label: 'Sender phone',
            outlined: true,
            placeholder: 'Enter a sender phone number'
          },
          cast: val => val && val.replace(/-/g, '')
        }
      ]
    }
  },
  methods: {
    async onSubmit () {
      const valid = await this.$refs.validator.validate()

      if (!valid) {
        return
      }

      this.closeModal(this.form.senderPhone)
    }
  }
}
</script>

<style scoped lang="scss">
.ettn-phone-error {
  padding: 18px 0 12px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    padding: 24px 0 16px;
  }

  &__image {
    margin-bottom: 16px;
  }

  &__title {
    color: #161B25;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__text {
    color: #161B25BF;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 12px;
    flex-wrap: wrap;

    .v-btn {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        max-width: 180px;
      }
    }
  }
}
</style>
