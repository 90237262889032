<template lang="pug">
  v-row
    v-col(cols="12" class="py-0")
      v-btn(outlined class="black w-100" @click="toggleDialog")
        span(class="mr-2") {{ $t('Filter') }}
        span(class="t-orm-filters-mobile__counter") {{ selectedFiltersCounter }}
    v-col(cols="12" v-if="model.ormFiltersMobile")
      t-orm-fields(:search.sync="filters.search" :items="model.ormFiltersMobile")
    v-dialog(v-model="filtersDialog" eager content-class="t-dialog" :scrollable="true")
      v-card(class="t-dialog__default")
        e-button-close(x-small class="t-dialog__close-btn" @click="toggleDialog")
        v-card-title
          h3(class="title font-weight-bold wb-bw") {{ $t('Filter') }}
        v-card-text
          t-orm-fields(v-bind.sync="filters" :items="mobileModelFilters" :config="config")
        v-card-actions
          v-btn(class="main-button w-100" @click="toggleDialog") {{ $t('Apply') }}
</template>

<script>
import contentDialog from '~/mixins/dialogs/contentDialog'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TDialog from '~/components/templates/t-dialog'
import TOrmFilters from '~/components/templates/orm/t-orm-filters'
import EButtonClose from '~/components/elements/buttons/e-button-close'

export default {
  components: {
    TOrmFilters,
    TOrmFields,
    TDialog,
    EButtonClose
  },
  mixins: [contentDialog],
  props: {
    model: {
      type: Function,
      required: true
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      filters: {},
      filtersDialog: false
    }
  },
  computed: {
    selectedFiltersCounter () {
      if (this.filters) {
        return this._.values(this.filters).filter((filter) => {
          if (typeof filter === 'object') {
            return this._.values(filter).some(item => !!item)
          }
          return !!filter
        }).length
      }
      return null
    },
    mobileModelFilters () {
      return this.model.ormFilters.filter(filter => filter.model !== 'search')
    },
    config () {
      return this.model.getOrmFiltersConfig(this.type)
    }
  },
  watch: {
    filters: {
      handler (cur) {
        this.$emit('input', cur)
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.$set(this.$data, 'filters', this.model.getOrmFiltersObject(null, null, { queryString: this.$route.query }))
  },
  methods: {
    toggleDialog () {
      this.filtersDialog = !this.filtersDialog
    }
  }
}
</script>

<style lang="scss" scoped>
.t-orm-filters-mobile__counter {
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  background: #ffffff;

  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 300;
  color: $btn-secondary-color;
}
</style>
