<template lang="pug">
  div(class="message-wrapper")
    h4(class="message__title")
      span(class="mr-1") ❗
      span {{ $t(title) }}
    p(class="message__text") {{ $t(text) }}
</template>

<script>
export default {
  name: 'MBlockTableWarning',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.message-wrapper {
  background: #FEFAEB;
  padding: 16px;
  width: 100%;
  border-radius: 10px;
}

.message {
  &__title {
    font-size: 14px;
    color: #161B25;
    font-weight: 600;
    line-height: 20px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__text {
    font-weight: 40;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 8px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 13px;
      line-height: 18px;
      margin: 8px 0 0 20px;
    }
  }
}
</style>
