import Base64js from 'base64-js'
import Employees from '~/modules/employees/models/Employees'
import setAuthenticatedCashier from '~/modules/receipt/mixins/setters/setAuthenticatedCashier'
import ProcessingWebSocket from '~/services/_utils/ProcessingWebSoket/ProcessingWebSocket'
import { ProcessingWebSocketMessageHandler } from '~/services/_utils/ProcessingWebSoket/ProcessingWebSocketMessageHandler'
import _comonData from '~/modules/receipt/mixins/getters/commonData'
import contentDialog from '~/mixins/dialogs/contentDialog'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'

const processingAuth = {
  mixins: [setAuthenticatedCashier, _comonData, contentDialog, edsIitCheckboxDialog],
  methods: {
    // top level functions
    async authenticate (authenticatedCashier, cashierAccessToken) {
      let accessToken = cashierAccessToken
      if (typeof accessToken !== 'string') {
        accessToken = this._.get(await Employees.api().processingSignin(authenticatedCashier.cashier), 'response.data.access_token')
      }
      await this.setAuthenticatedCashier({
        accessToken: typeof accessToken === 'string' ? accessToken : null,
        shift: null
      }, authenticatedCashier)
      return true
    },

    async authenticateWithSign (authenticatedCashier) {
      const dialogType = 'edsIitCheckboxSignAgent'
      let authenticated = false
      await this.closeEdsIitCheckboxDialog(dialogType)
      await this.useEdsIitCheckboxDialog({
        type: dialogType,
        keyCheck: false,
        onlyFileSign: true,
        pluginTitles: {
          fileSign: this.$t('signPlugin.employeeFileKey')
        },
        hints: {
          fileSign: this.$t('signPlugin.edsWindow')
        },
        onConfirm: async ({ edsKeyData, sign }) => {
          try {
            if (!edsKeyData?.keyData) { return }
            if (edsKeyData?.publicKeyId && (edsKeyData.publicKeyId.replace(/ /g, '').toLowerCase() !== authenticatedCashier.cashier.publicKeyId)) {
              this.$notification.error(this.$t('Selected key do not matched with selected cashier. Please select right EDS Key'))
              await this.authenticateWithSign(authenticatedCashier)
              return
            }
            const signedData = await sign(authenticatedCashier.cashier.login)
            const signToken = this._.get(await Employees.api().processingLoginSignature(signedData), 'response.data.access_token', null)
            const accessToken = this._.get(await Employees.api().processingSignin(authenticatedCashier.cashier), 'response.data.access_token')
            const socket = new ProcessingWebSocket(this.$env.WSS_PROCESSING_BASE_URL, signToken, this)
            const messageHandler = new ProcessingWebSocketMessageHandler({ sign })
            const onStateChange = async (target, event) => {
              if (event.type === 'close' || event.type === 'open') {
                await this.setAuthenticatedCashier({
                  signAgent: target
                }, this.authenticatedCashier)
              }
            }
            await socket.open(messageHandler, onStateChange)

            await this.setAuthenticatedCashier({
              accessToken: typeof accessToken === 'string' ? accessToken : null,
              shift: null,
              signAgent: socket
            }, authenticatedCashier)
            authenticated = true
          } catch (e) {
            this.$notification.error(e)
          }
        }
      })
      return authenticated
    },

    async authenticateWithCloudSign (authenticatedCashier) {
      const keyData = await this.contentDialog.open(
        {
          title: 'Launch Checkbox Signature on a secure cloud service',
          component: 'form-employee-upload-eds-key',
          width: '650px',
          componentProps: {
            inModal: true
          }
        }
      )
      if (!keyData) {
        return null
      }
      const issuerCN = this._.get(keyData, 'keyData.certificate_info.certificate.pszIssuerCN', null)
      const certificateStart = this._.get(keyData, 'keyData.certificate_start', null)
      const certificateEnd = this._.get(keyData, 'keyData.certificate_end', null)
      const certificate = this._.get(keyData, 'keyData.certificate_info', null)
      const keyIdFromKey = this._.get(keyData, 'keyData.key_id', '').replace(/ /g, '').toLowerCase()
      if (keyIdFromKey !== this._.get(authenticatedCashier, 'cashier.publicKeyId')) {
        this.$notification.error('Selected key does not match of cashier key', true)
        return null
      }
      const data = {
        ca: this._.get(keyData, 'keyData.ca', null),
        keyPassword: keyData.form.keyPassword,
        keyFile: Base64js.fromByteArray(new Uint8Array(keyData.form.keyFile)),
        caIssuerCN: issuerCN,
        certificateStart,
        certificateEnd,
        certificateInfo: certificate
      }
      try {
        await Employees.api().uploadEdsKey(this._.get(authenticatedCashier, 'cashier.id'), data)
        this.$notification.success('Key successfully upload to checkbox server')
        return await this.authenticate(authenticatedCashier)
      } catch (e) {
        this.$notification.error(e)
        return null
      }
    },

    async logOut (authenticatedCashier) {
      if (authenticatedCashier.signAgent && authenticatedCashier.signAgent.close) {
        await authenticatedCashier.signAgent.close()
      }
      await this.setAuthenticatedCashier({
        accessToken: null,
        cashier: null,
        cashRegister: null,
        acquiringSetting: null,
        ettnSettings: null,
        shift: null,
        signAgent: null
      }, authenticatedCashier)
    }
  }

}

export default processingAuth
