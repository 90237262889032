import { intersectionBy } from 'lodash/array'
import { get } from 'lodash/object'
import CurrentOrganization from '~/modules/auth/Models/CurrentOrganization'
import authUser from '~/modules/auth/authUser'
import Organization from '~/models/directories/Organization'
import ManagedOrganizations from '~/models/directories/ManagedOrganizations'

export default {
  refresh () {
    const currentOrganization = this.getCurrentOrganization()
    const orgId = get(currentOrganization, 'id', null)
    const organization = Organization.query().whereId(orgId).first()
    const managedOrganization = ManagedOrganizations.query().whereId(orgId).first()
    this.setCurrentOrganization(managedOrganization || organization || currentOrganization)
  },

  getCurrentOrganization () {
    return CurrentOrganization.query().withAll().first()
  },

  getManagedOrganizations () {
    return ManagedOrganizations.query().all() || []
  },

  setCurrentOrganization (organization) {
    if (!organization) {
      return CurrentOrganization.deleteAll()
    }
    return CurrentOrganization.create({
      data: organization
    })
  },

  updateCurrentOrganization () {
    const user = authUser.get()
    const currentOrganization = this.getCurrentOrganization()
    if (!currentOrganization || !intersectionBy([user.ownedOrganization, ...this.getManagedOrganizations()], [currentOrganization], 'id').length) {
      this.setCurrentOrganization(this.getDefaultOrganization(user))
    } else {
      this.refresh()
    }
  },

  getDefaultOrganization (user) {
    if (user.isOwner) {
      return user.ownedOrganization
    } else if (user.isOrganizationUser) {
      return this.getManagedOrganizations()[0]
    }
    return null
  }

}
