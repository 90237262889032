import { mapState } from 'vuex'
import Organization from '~/models/directories/Organization'

const taxAuth = {
  computed: {
    ...mapState({
      iitDialog: state => state.dialogs.dialogs.edsIitCheckboxDialog
    })
  },
  methods: {
    destroyIitGlobalDialog (store) {
      const $store = this.$store || store
      $store.commit('user/SET_KEY_DATA', {
        keyData: null
      })
      $store.commit('dialogs/SET_DIALOG_OPTIONS', {
        type: 'edsIitCheckboxDialog',
        options: {
          open: false,
          visible: false,
          keysMatchCheck: true,
          model: null,
          loading: false
        },
        actions: {
          onKeyLoaded: null
        }
      })
    },
    async sfsSyncData (data) {
      try {
        const syncData = this._.get(this.iitDialog, 'actions.syncData', null)
        await syncData(data)
      } catch (e) {
        this.destroyIitGlobalDialog()
        this.$handlers.error(e, this)
      }
    },
    async sfsSignData (data) {
      try {
        const signData = this._.get(this.iitDialog, 'actions.signData', null)
        await signData(data)
      } catch (e) {
        this.destroyIitGlobalDialog()
        this.$handlers.error(e, this)
      }
    },
    async syncOrganization (keyData, sign) {
      const EDRPOUCode = this._.get(keyData, '[0].infoEx.subjEDRPOUCode', '')
      const DRFOCode = this._.get(keyData, '[0].infoEx.subjDRFOCode', '')
      let afterSyncData = {}

      if (sign) {
        try {
          this.iitLoading = true
          afterSyncData = await this.$store._actions.synchronizeOrganization[0](
            {
              id: this.organization[Organization.primaryKey],
              data: { sign, EDRPOUCode, DRFOCode }
            })
          await Organization.api().within(this.$User, '/organization', { dataKey: null })

          const { deactivated: outletsDeactivated, new: outletsNew, sync: outletsSync } = afterSyncData.data.outlets
          this.$notification.success(this.$t('Organization successfully synchronized', {
            outletsDeactivated,
            outletsNew,
            outletsSync,
            cashRegisters: this._.get(afterSyncData, 'data.cashRegisters.sync', ''),
            employees: this._.get(afterSyncData, 'data.employees.sync', '')
          }))
        } catch (e) {
          this.destroyIitGlobalDialog()
          this.$handlers.error(e, this)
        } finally {
          this.iitLoading = false
        }
      } else {
        this.$handlers.error('Error occurred. Try again later or contact with administrator', this)
      }
    }
  }
}

export default taxAuth
