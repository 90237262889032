<template lang="pug">
  div(class="dialog")
    p(class="dialog__text")
      span(class="mr-1") {{ $t('In order for you to be able to try the service before registering the cash register, we have prepared for you a demo access to the workplace so that you can evaluate the work in') }}
      b Checkbox
    p(class="dialog__text mt-4")
      b {{ $t('For this') }}:
    ul
      li(v-if="!isAuthenticated") {{ $t('Log in as a test cashier and cashier') }}
      li {{ $t('Add the product to the check') }}
      li {{ $t('Click "Issue check"') }}
    div(class="dialog__actions mt-4")
      v-btn(
        @click="handleSkip"
      ) {{ $t('Skip') }}
      v-btn(
        class="main-button"
        @click="handleConfirm"
      ) {{ $t('Start') }}
</template>

<script>
export default {
  name: 'BlockCreateReceiptOnboarding',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    isAuthenticated: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleSkip () {
      this.$setLocalStorageClientData({ createReceiptOnBoardingDone: true })
      this.closeModal(false)
    },
    handleConfirm () {
      this.closeModal(true)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 40px 30px 10px;
  color: #161b25;

  &__text {
    margin: 0;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    align-items: center;

    &::v-deep {
      .v-btn {
        width: 100%;
        max-width: 180px;
      }
    }
  }
}
</style>
