import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import i18n from '~/plugins/nuxt-i18n/i18n'

export class OfflineStatus extends PersistAble(OrmModel) {
  static entity = 'offlinestatus'
  static paginated = true
  static defaultSortOrder = true

  static STATUSES = {
    online: {
      value: 'online',
      text: i18n.t('Online Mode'),
      textShort: 'On'
    },
    offline: {
      value: 'offline',
      text: i18n.t('Offline Mode'),
      textShort: 'Off'
    }
  }

  static ormTrans = {
    single: 'Offline status',
    multy: 'Offline status'
  }

  static fields () {
    return {
      id: this.attr(null),
      duration: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}

export default OfflineStatus
