import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class PrivatbankToken extends PersistAble(OrmModel) {
  static entity = 'privatbanktoken'
  static paginated = false
  static defaultSortOrder = true

  static fields () {
    return {
      id: this.attr(null),
      clientTokenEncrypted: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      return Object.assign({}, Model.apiConfig.actions)
    }
  }
}

export default PrivatbankToken
