<template lang="pug">
  v-menu(
    @input="onMenuInput"
    :left="xsDevice"
    :bottom="!xsDevice"
    offset-x
    offset-y
  )
    template(v-slot:activator="{ on, attrs }")
      v-badge(
        :value="hasNewNotifications"
        :color="hasHighPriorityNotifications ? '#ff002b' : '#ff8a00'"
        class="notifications-button"
        :class="{ 'notifications-button': true, 'notifications-button--small': small }"
        overlap
        bordered
      )
        v-btn(
          class="notifications-button__btn"
          v-bind="attrs"
          v-on="on"
        )
          e-svg-icon(
            :size="small ? 'md' : 'lg'"
            :class="{ 'notifications-button__bell--animated': hasHighPriorityNotifications }"
          ) notification-2
    v-list(
      v-if="menuOpen"
      class="notifications-button__content"
    )
      div(class="notifications-button__menu")
        block-notifications-list(
          :queryFilter="newNotificationsFilter"
          noDataText="There are no new notifications"
          :onNotificationRead="onNotificationRead"
        )
      div(class="notifications-button__bottom")
        v-btn(
          class="notifications-button__open-all-btn"
          @click="openAllNotifications"
          dark
        ) {{ $t('All notifications') }}
        button-read-all-notifications(
          v-if="showReadAllButton"
          :onReadAll="onReadAll"
        )
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Notifications from '~/modules/notifications/models/Notifications'
import switchingSidebar from '~/modules/sidebar/mixins/switchingSidebar'
import BlockNotificationsList from '~/modules/notifications/views/components/block-notifications-list'
import ButtonReadAllNotifications from '~/modules/notifications/views/components/button-read-all-notifications'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockNotificationsButton',
  components: {
    ButtonReadAllNotifications,
    BlockNotificationsList,
    ESvgIcon
  },
  mixins: [switchingSidebar, responsive],
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    newNotifications: {},
    menuOpen: false
  }),
  computed: {
    model () {
      return Notifications
    },
    newNotificationsFilter () {
      return { only_new: 1 }
    },
    hasNewNotifications () {
      return this.hasLowPriorityNotifications || this.hasMiddlePriorityNotifications || this.hasHighPriorityNotifications || 0
    },
    hasLowPriorityNotifications () {
      return this.newNotifications[this.model.priorities.low.value]
    },
    hasMiddlePriorityNotifications () {
      return this.newNotifications[this.model.priorities.middle.value]
    },
    hasHighPriorityNotifications () {
      return this.newNotifications[this.model.priorities.high.value]
    },
    showReadAllButton () {
      return Boolean(this.newNotifications && Object.keys(this.newNotifications).length)
    }
  },
  watch: {
    $route () {
      this.checkNewNotifications()
    }
  },
  created () {
    this.checkNewNotifications()
    this.$root.$on('notificationRead', this.onNotificationRead)
  },
  methods: {
    onReadAll () {
      this.$set(this.$data, 'newNotifications', {})
    },
    onMenuInput (val) {
      this.menuOpen = val
      this.$emit('input', val)
    },
    onNotificationRead (item) {
      const priority = this._.get(item, 'priority')
      if (this.newNotifications[priority]) {
        this.newNotifications[priority] -= 1
      }
    },
    async checkNewNotifications () {
      if (!this.$Organization) {
        return
      }
      try {
        const res = this._.get(await this.model.api().checkNew(), 'response.data')
        this._.each(res, (item) => {
          this.$set(this.newNotifications, this._.get(item, 'priority'), this._.get(item, 'new'))
        })
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async openAllNotifications () {
      this.$emit('openAllNotifications')
      await this.switchingSidebar.open({
        title: 'Notification',
        maxWidth: '500',
        right: true,
        component: 'block-notifications-list',
        componentProps: {
          loadMoreBtn: true,
          readAllButton: this.showReadAllButton,
          onNotificationOpen: (item, ctx) => {
            ctx.$emit('stateless', true)
          },
          onNotificationClose: (item, ctx) => {
            ctx.$emit('stateless', false)
          },
          onNotificationRead: this.onNotificationRead,
          onReadAll: this.onReadAll
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.notifications-button {
  &__btn {
    background-color: rgba(231, 232, 233, 0.08) !important;
    height: 35px !important;
    padding: 0 13px !important;
    border-radius: 7px;
  }

  &__bottom {
    margin-top: 15px;
    justify-content: center;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &::v-deep {
    .v-badge {
      &__badge {
        min-width: 14px;
        height: 14px;
        transform: translate(-1px, 0);
      }
    }
  }

  &--small {
    .notifications-button {
      &__btn {
        width: 32px;
        padding: 0 5px !important;
      }
    }

    &::v-deep {
      .v-badge {
        &__badge {
          min-width: 12px;
          height: 12px;
          transform: translate(1px, -2px);
        }
      }
    }
  }

  &__content {
    width: 100%;
    padding: 20px 15px;
    min-width: 250px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      width: 450px;
    }
  }

  &__menu {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    margin-right: -5px;
  }

  &__open-all-btn {
    display: block;
  }

  @keyframes shake {
    0%,
    22% {
      transform: rotate(0deg);
    }
    2%,
    8% {
      transform: rotate(15deg);
    }
    6%,
    16% {
      transform: rotate(0deg);
    }
    14%,
    20% {
      transform: rotate(-15deg);
    }
  }

  &__bell {
    &--animated {
      animation: shake 3.5s infinite;
    }
  }
}
</style>
