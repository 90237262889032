<template lang="pug">
  v-app(:key="key")
    drawer(v-if="showDrawer" @rerenderContent="rerenderContent")
    v-content()
      v-container(fluid fill-height class="default pa-0 background align-start")
        nuxt
    notification
    dialogs(ref="dialogs")
    e-new-version-informer
    sidebars
    snackbars
</template>

<script>
import drawer from './includes/_drawer'
import notification from './includes/_notification'
import dialogs from './includes/_dialogs'
import auth from '~/modules/auth/auth'
import authUser from '~/modules/auth/authUser'
import ENewVersionInformer from '~/components/elements/informers/e-new-version-informer'
import informationDialog from '~/mixins/dialogs/informationDialog'
import sidebars from '~/modules/sidebar/views/components/sidebars'
import Snackbars from '~/components/modules/snackbar/snackbars.vue'

export default {
  components: {
    Snackbars,
    drawer,
    notification,
    dialogs,
    ENewVersionInformer,
    sidebars
  },
  mixins: [informationDialog],
  data () {
    return {
      key: 1
    }
  },
  computed: {
    showDrawer () {
      return auth.isAuthenticated() && authUser.get()
    }
  },
  async mounted () {
    if (this.$Organization && !this._.get(this.$localStorageClientData(), 'testModeNotificationViewed', false) && new Date(this.$Organization.dateCreated) < new Date('2021-12-27')) {
      await this.informationDialog.open({
        text: 'A test mode has been added to the portal. Now all users have the opportunity to work with the test cash register and cashier. Go to the appropriate sections on the portal, and select the appropriate data marked test.',
        textPrefix: 'Attention, new function!',
        width: '550px',
        buttonText: 'Clear'
      })
      this.$setLocalStorageClientData({ testModeNotificationViewed: true })
    }
  },
  methods: {
    rerenderContent () {
      this.key = Date.now()
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-width: 320px !important;
}
</style>
