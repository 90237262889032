import contentDialog from '~/mixins/dialogs/contentDialog'
import Suppliers from '~/modules/goods/models/Suppliers'

const actions = {
  mixins: [contentDialog],
  methods: {
    async addGroup () {
      return await this.contentDialog.open({
        width: '700px',
        component: 'm-block-goods-group-add'
      })
    },
    async addProvider () {
      return await this.contentDialog.open({
        title: 'New provider',
        width: '700px',
        component: 'm-form-block',
        componentProps: {
          fields: Suppliers.ormFields,
          buttonText: 'Add provider',
          onSubmit: async (data) => {
            const supplierRequest = await Suppliers.api().create(data)
            const supplier = this._.get(supplierRequest, 'entities.suppliers[0]', null)
            this.$notification.success(this.$t('Provider successfully added'))
            await this.contentDialog.close(supplier)
          }
        }
      })
    }
  }
}

export default actions
