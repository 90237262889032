import Dialog from '~/models/system/Dialog'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'
import edsIitCheckboxHelper from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxHelper'

const edsIitCheckboxDialog = {
  mixins: [edsIitCheckboxHelper],
  methods: {
    getEdsIitCheckboxDialog () {
      return Dialog.query().where('type', 'edsIitCheckboxDialog').first()
    },
    getEdsIitCheckboxModel (type) {
      return EdsIitCheckbox.query().where('type', type).first()
    },
    getEdsIitCheckboxInterface (type) {
      const modelInstance = this.getEdsIitCheckboxModel(type)
      return modelInstance ? modelInstance.getInterface() : null
    },
    async closeEdsIitCheckboxDialog (type, hide = false) {
      const modelInstance = this.getEdsIitCheckboxModel(type)
      if (modelInstance && !hide) {
        await modelInstance.delete()
      }
      const dialog = this.getEdsIitCheckboxDialog()
      if (dialog) {
        await dialog.close()
      }
    },
    async handleOnConfirmEdsIitCheckboxDialog (params = {}) {
      const {
        onConfirm,
        type,
        signInterface,
        fallbackMethod,
        dialog,
        keyCheck,
        checkAuthService,
        createReportService
      } = params
      if (typeof onConfirm === 'function') {
        const data = await this.generateSignInterfacePayloadAndErrorsCheck({
          type,
          keyCheck,
          checkAuthService,
          createReportService,
          signInterface,
          fallbackMethod: async () => {
            if (typeof fallbackMethod === 'function') {
              await fallbackMethod()
            }
          },
          errorFallback: async () => {
            if (dialog) {
              await dialog.close()
            }
          }
        })
        await onConfirm(data)
      }
    },
    async useEdsIitCheckboxDialog ({
      type = 'edsIitCheckboxForOrganization',
      method = 'use',
      fallbackMethod,
      checkAuthService = false,
      createReportService = false,
      keyCheck = true,
      onlyFileSign = false,
      pluginTitles = {},
      hints = {},
      dialogParams = {},
      onConfirm
    } = {}) {
      const dialog = this.getEdsIitCheckboxDialog()
      const signInterface = await dialog.use({
        open: method === 'open',
        pluginType: type,
        pluginTitles,
        hints,
        onlyFileSign,
        ...(dialogParams || {}),
        onConfirm: async (signInterface) => {
          await this.handleOnConfirmEdsIitCheckboxDialog({
            onConfirm,
            type,
            signInterface,
            fallbackMethod,
            dialog,
            keyCheck,
            checkAuthService,
            createReportService
          })
        }
      })
      if (signInterface) {
        await this.handleOnConfirmEdsIitCheckboxDialog({
          onConfirm,
          type,
          signInterface,
          fallbackMethod,
          dialog,
          keyCheck,
          checkAuthService,
          createReportService
        })
      }
    }
  }
}
export default edsIitCheckboxDialog
