<template lang="pug">
  div(class="pt-8 pb-10")
    ValidationObserver(ref="form" slim)
      t-orm-fields(v-bind.sync="dataForm" :items="fields")
    div(class="d-flex justify-center")
      v-btn(
        class="main-button"
        :loading="loading"
        @click="submit"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Billings from '~/models/billing/Billings'

export default {
  name: 'MFormBillingEditDiscount',
  components: {
    TOrmFields
  },
  props: {
    discountId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dataForm: {
        endAt: null
      },
      loading: false
    }
  },
  computed: {
    fields () {
      return [
        {
          model: 'endAt',
          component: 'e-input-datetime',
          provider: {
            vid: 'endAt',
            name: 'Select end date',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Select end date'
          }
        }
      ]
    }
  },
  methods: {
    async submit () {
      const valid = this.$refs.form.validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true

        await Billings.api().editDiscount(this.discountId, this.dataForm)
        this.$emit('onSuccess')
      } catch (e) {
        this.$emit('onError')
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
