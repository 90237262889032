<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ dialogTitle }}!
    div(
      v-if="loading"
      class="pt-3 pb-6 loading-container"
    )
      v-progress-circular(indeterminate, size="50", color="primary")
    div(v-else)
      div {{ $t('Are you sure to delete select goods') }}
      div(class="dialog__actions")
        v-btn(
          @click="confirm"
          class="secondary-button color-error"
          :loading="loading"
        ) {{ $t('Approve') }}
        v-btn(
          @click="close"
          class="main-button"
          outlined
        ) {{ $t('Dismiss') }}
</template>

<script>

import AccountingGoods from '~/modules/goods/models/AccountingGoods'
export default {
  name: 'BlockSelectGoodsDeleting',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    onSuccess: {
      type: Function,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    loading: false,
    currentDialogTitle: null
  }),
  computed: {
    model () {
      return AccountingGoods
    },
    dialogTitle () {
      switch (this.currentDialogTitle) {
        case 'deletion':
          return this.$t('Deleting select goods')
        default:
          return this.$t('Warning')
      }
    }
  },
  methods: {
    async close () {
      await this.closeModal()
    },
    async confirm () {
      try {
        this.loading = true
        this.currentDialogTitle = 'deletion'
        await this.deleteSelectedGoods()
        await this.close()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async deleteSelectedGoods () {
      try {
        const productId = this.items.map(item => item.id)
        await this.model.api().deleteSelected({ products: productId })
        await this.onSuccess()
        this.$notification.success(this.$t('Selected goods successfully deleted'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    justify-content: center;
    gap: 16px;
    display: flex;
    margin-top: 20px;
  }
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
</style>
