<template lang="pug">
  div(class="image-cropper py-5")
    cropper(
      ref="cropper"
      class="cropper"
      :src="imageUrl"
      :stencil-props="stencilConfig"
      :canvas="canvasConfig"
    )
    div(class="image-cropper__actions pt-4")
      v-btn(class="main-button" :loading="loading" @click="setImageArea") {{ $t('Save') }}
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: 'MBlockImageCropper',
  components: {
    Cropper
  },
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    imageType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      loading: false
    }
  },
  computed: {
    stencilConfig () {
      return {
        aspectRatio: 1.5
      }
    },
    canvasConfig () {
      return {
        fillColor: '#ffffff'
      }
    }
  },
  methods: {
    async setImageArea () {
      this.loading = true
      const { canvas } = this.$refs.cropper.getResult()

      if (canvas) {
        // DataURL for preview image
        const dataUrl = canvas.toDataURL()
        // BLOB for upload to server
        await canvas.toBlob((blob) => {
          this.loading = false
          this.$attrs.closeModal({ blob, dataUrl })
        }, this.imageType)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image-cropper {
  .cropper {
    width: 100%;
    height: 100%;
    max-height: 500px;
    background: #DDD;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}

</style>
