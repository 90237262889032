import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _448a1202 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _5ba23968 = () => interopDefault(import('../pages/UsefulMap.vue' /* webpackChunkName: "pages/UsefulMap" */))
const _36129135 = () => interopDefault(import('../pages/WeAreWorking.vue' /* webpackChunkName: "pages/WeAreWorking" */))
const _43fe2188 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _279fad9e = () => interopDefault(import('../pages/dashboard/AccountingAutomation.vue' /* webpackChunkName: "pages/dashboard/AccountingAutomation" */))
const _0ab8983e = () => interopDefault(import('../pages/dashboard/Acquiring.vue' /* webpackChunkName: "pages/dashboard/Acquiring" */))
const _b2612574 = () => interopDefault(import('../pages/dashboard/Acts.vue' /* webpackChunkName: "pages/dashboard/Acts" */))
const _6431bd5e = () => interopDefault(import('../pages/dashboard/AdminUsers.vue' /* webpackChunkName: "pages/dashboard/AdminUsers" */))
const _9a8b8306 = () => interopDefault(import('../pages/dashboard/Billings.vue' /* webpackChunkName: "pages/dashboard/Billings" */))
const _7bf145e8 = () => interopDefault(import('../pages/dashboard/CashRegisters.vue' /* webpackChunkName: "pages/dashboard/CashRegisters" */))
const _507a9867 = () => interopDefault(import('../pages/dashboard/ClientBalances.vue' /* webpackChunkName: "pages/dashboard/ClientBalances" */))
const _1cb83da6 = () => interopDefault(import('../pages/dashboard/CreateDeposit.vue' /* webpackChunkName: "pages/dashboard/CreateDeposit" */))
const _39588b88 = () => interopDefault(import('../pages/dashboard/CreateReceipts.vue' /* webpackChunkName: "pages/dashboard/CreateReceipts" */))
const _2f2e7f19 = () => interopDefault(import('../pages/dashboard/Delivery.vue' /* webpackChunkName: "pages/dashboard/Delivery" */))
const _a4e82b9a = () => interopDefault(import('../pages/dashboard/EDSKeys.vue' /* webpackChunkName: "pages/dashboard/EDSKeys" */))
const _54b2deb0 = () => interopDefault(import('../pages/dashboard/Employees.vue' /* webpackChunkName: "pages/dashboard/Employees" */))
const _804857fe = () => interopDefault(import('../pages/dashboard/Faq.vue' /* webpackChunkName: "pages/dashboard/Faq" */))
const _6cdab781 = () => interopDefault(import('../pages/dashboard/Goods.vue' /* webpackChunkName: "pages/dashboard/Goods" */))
const _51ddb9ca = () => interopDefault(import('../pages/dashboard/Instructions.vue' /* webpackChunkName: "pages/dashboard/Instructions" */))
const _612a85ea = () => interopDefault(import('../pages/dashboard/Invoices.vue' /* webpackChunkName: "pages/dashboard/Invoices" */))
const _1eff9638 = () => interopDefault(import('../pages/dashboard/MyOrganization.vue' /* webpackChunkName: "pages/dashboard/MyOrganization" */))
const _77d85913 = () => interopDefault(import('../pages/dashboard/Notifications.vue' /* webpackChunkName: "pages/dashboard/Notifications" */))
const _5be712a0 = () => interopDefault(import('../pages/dashboard/OnBoarding.vue' /* webpackChunkName: "pages/dashboard/OnBoarding" */))
const _ba6d3c6a = () => interopDefault(import('../pages/dashboard/Organizations.vue' /* webpackChunkName: "pages/dashboard/Organizations" */))
const _df05c680 = () => interopDefault(import('../pages/dashboard/OrganizationUsers.vue' /* webpackChunkName: "pages/dashboard/OrganizationUsers" */))
const _8e4b2d1e = () => interopDefault(import('../pages/dashboard/Outlets.vue' /* webpackChunkName: "pages/dashboard/Outlets" */))
const _faf1e158 = () => interopDefault(import('../pages/dashboard/PageInDevelop.vue' /* webpackChunkName: "pages/dashboard/PageInDevelop" */))
const _7b0aa171 = () => interopDefault(import('../pages/dashboard/Payment.vue' /* webpackChunkName: "pages/dashboard/Payment" */))
const _2c15a45c = () => interopDefault(import('../pages/dashboard/Payments.vue' /* webpackChunkName: "pages/dashboard/Payments" */))
const _17191553 = () => interopDefault(import('../pages/dashboard/PromoCashRegisters.vue' /* webpackChunkName: "pages/dashboard/PromoCashRegisters" */))
const _946d8640 = () => interopDefault(import('../pages/dashboard/Receipts.vue' /* webpackChunkName: "pages/dashboard/Receipts" */))
const _1367a5da = () => interopDefault(import('../pages/dashboard/ReconciliationAct.vue' /* webpackChunkName: "pages/dashboard/ReconciliationAct" */))
const _aea9b6e6 = () => interopDefault(import('../pages/dashboard/RedeemDeposit.vue' /* webpackChunkName: "pages/dashboard/RedeemDeposit" */))
const _5a4bf1be = () => interopDefault(import('../pages/dashboard/Referrals.vue' /* webpackChunkName: "pages/dashboard/Referrals" */))
const _20cafbcb = () => interopDefault(import('../pages/dashboard/RefundReceipt.vue' /* webpackChunkName: "pages/dashboard/RefundReceipt" */))
const _7ee87ce6 = () => interopDefault(import('../pages/dashboard/Refunds.vue' /* webpackChunkName: "pages/dashboard/Refunds" */))
const _6d9786ac = () => interopDefault(import('../pages/dashboard/Reports.vue' /* webpackChunkName: "pages/dashboard/Reports" */))
const _28f55106 = () => interopDefault(import('../pages/dashboard/Reviews.vue' /* webpackChunkName: "pages/dashboard/Reviews" */))
const _d8173fd4 = () => interopDefault(import('../pages/dashboard/Shifts.vue' /* webpackChunkName: "pages/dashboard/Shifts" */))
const _844774de = () => interopDefault(import('../pages/dashboard/SmsPayments.vue' /* webpackChunkName: "pages/dashboard/SmsPayments" */))
const _55052506 = () => interopDefault(import('../pages/dashboard/TaxBills.vue' /* webpackChunkName: "pages/dashboard/TaxBills" */))
const _14fac866 = () => interopDefault(import('../pages/dashboard/TaxRates.vue' /* webpackChunkName: "pages/dashboard/TaxRates" */))
const _2f030533 = () => interopDefault(import('../pages/dashboard/Users.vue' /* webpackChunkName: "pages/dashboard/Users" */))
const _3cc9e476 = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _01a91105 = () => interopDefault(import('../pages/payment/status.vue' /* webpackChunkName: "pages/payment/status" */))
const _1f9f5100 = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _705c6cae = () => interopDefault(import('../pages/dashboard/reports/DailyCashFlowReport.vue' /* webpackChunkName: "pages/dashboard/reports/DailyCashFlowReport" */))
const _206be797 = () => interopDefault(import('../pages/dashboard/reports/ExcisesReport.vue' /* webpackChunkName: "pages/dashboard/reports/ExcisesReport" */))
const _7df30602 = () => interopDefault(import('../pages/dashboard/reports/ExtendedReport.vue' /* webpackChunkName: "pages/dashboard/reports/ExtendedReport" */))
const _48b710b8 = () => interopDefault(import('../pages/dashboard/reports/FinancialReport.vue' /* webpackChunkName: "pages/dashboard/reports/FinancialReport" */))
const _3024a8c5 = () => interopDefault(import('../pages/dashboard/reports/GoodsReport.vue' /* webpackChunkName: "pages/dashboard/reports/GoodsReport" */))
const _390d1f9d = () => interopDefault(import('../pages/dashboard/reports/MallReport.vue' /* webpackChunkName: "pages/dashboard/reports/MallReport" */))
const _03a5dce4 = () => interopDefault(import('../pages/dashboard/reports/PeriodicReport.vue' /* webpackChunkName: "pages/dashboard/reports/PeriodicReport" */))
const _73aed384 = () => interopDefault(import('../pages/dashboard/reports/ReceiptsReport.vue' /* webpackChunkName: "pages/dashboard/reports/ReceiptsReport" */))
const _06614a3a = () => interopDefault(import('../pages/dashboard/reports/ShiftsReport.vue' /* webpackChunkName: "pages/dashboard/reports/ShiftsReport" */))
const _5dc884c9 = () => interopDefault(import('../pages/dashboard/reports/TaxReports.vue' /* webpackChunkName: "pages/dashboard/reports/TaxReports" */))
const _6626c7dc = () => interopDefault(import('../pages/dashboard/reports/TechnicalReturnReport.vue' /* webpackChunkName: "pages/dashboard/reports/TechnicalReturnReport" */))
const _0df2ee69 = () => interopDefault(import('../pages/dashboard/reports/ZReport.vue' /* webpackChunkName: "pages/dashboard/reports/ZReport" */))
const _23f84489 = () => interopDefault(import('../pages/auth/set-password/_token/index.vue' /* webpackChunkName: "pages/auth/set-password/_token/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/forbidden",
    component: _448a1202,
    name: "forbidden"
  }, {
    path: "/profile",
    component: _4bb7511c,
    name: "profile"
  }, {
    path: "/UsefulMap",
    component: _5ba23968,
    name: "UsefulMap"
  }, {
    path: "/WeAreWorking",
    component: _36129135,
    name: "WeAreWorking"
  }, {
    path: "/auth/forgot-password",
    component: _43fe2188,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration"
  }, {
    path: "/dashboard/AccountingAutomation",
    component: _279fad9e,
    name: "dashboard-AccountingAutomation"
  }, {
    path: "/dashboard/Acquiring",
    component: _0ab8983e,
    name: "dashboard-Acquiring"
  }, {
    path: "/dashboard/Acts",
    component: _b2612574,
    name: "dashboard-Acts"
  }, {
    path: "/dashboard/AdminUsers",
    component: _6431bd5e,
    name: "dashboard-AdminUsers"
  }, {
    path: "/dashboard/Billings",
    component: _9a8b8306,
    name: "dashboard-Billings"
  }, {
    path: "/dashboard/CashRegisters",
    component: _7bf145e8,
    name: "dashboard-CashRegisters"
  }, {
    path: "/dashboard/ClientBalances",
    component: _507a9867,
    name: "dashboard-ClientBalances"
  }, {
    path: "/dashboard/CreateDeposit",
    component: _1cb83da6,
    name: "dashboard-CreateDeposit"
  }, {
    path: "/dashboard/CreateReceipts",
    component: _39588b88,
    name: "dashboard-CreateReceipts"
  }, {
    path: "/dashboard/Delivery",
    component: _2f2e7f19,
    name: "dashboard-Delivery"
  }, {
    path: "/dashboard/EDSKeys",
    component: _a4e82b9a,
    name: "dashboard-EDSKeys"
  }, {
    path: "/dashboard/Employees",
    component: _54b2deb0,
    name: "dashboard-Employees"
  }, {
    path: "/dashboard/Faq",
    component: _804857fe,
    name: "dashboard-Faq"
  }, {
    path: "/dashboard/Goods",
    component: _6cdab781,
    name: "dashboard-Goods"
  }, {
    path: "/dashboard/Instructions",
    component: _51ddb9ca,
    name: "dashboard-Instructions"
  }, {
    path: "/dashboard/Invoices",
    component: _612a85ea,
    name: "dashboard-Invoices"
  }, {
    path: "/dashboard/MyOrganization",
    component: _1eff9638,
    name: "dashboard-MyOrganization"
  }, {
    path: "/dashboard/Notifications",
    component: _77d85913,
    name: "dashboard-Notifications"
  }, {
    path: "/dashboard/OnBoarding",
    component: _5be712a0,
    name: "dashboard-OnBoarding"
  }, {
    path: "/dashboard/Organizations",
    component: _ba6d3c6a,
    name: "dashboard-Organizations"
  }, {
    path: "/dashboard/OrganizationUsers",
    component: _df05c680,
    name: "dashboard-OrganizationUsers"
  }, {
    path: "/dashboard/Outlets",
    component: _8e4b2d1e,
    name: "dashboard-Outlets"
  }, {
    path: "/dashboard/PageInDevelop",
    component: _faf1e158,
    name: "dashboard-PageInDevelop"
  }, {
    path: "/dashboard/Payment",
    component: _7b0aa171,
    name: "dashboard-Payment"
  }, {
    path: "/dashboard/Payments",
    component: _2c15a45c,
    name: "dashboard-Payments"
  }, {
    path: "/dashboard/PromoCashRegisters",
    component: _17191553,
    name: "dashboard-PromoCashRegisters"
  }, {
    path: "/dashboard/Receipts",
    component: _946d8640,
    name: "dashboard-Receipts"
  }, {
    path: "/dashboard/ReconciliationAct",
    component: _1367a5da,
    name: "dashboard-ReconciliationAct"
  }, {
    path: "/dashboard/RedeemDeposit",
    component: _aea9b6e6,
    name: "dashboard-RedeemDeposit"
  }, {
    path: "/dashboard/Referrals",
    component: _5a4bf1be,
    name: "dashboard-Referrals"
  }, {
    path: "/dashboard/RefundReceipt",
    component: _20cafbcb,
    name: "dashboard-RefundReceipt"
  }, {
    path: "/dashboard/Refunds",
    component: _7ee87ce6,
    name: "dashboard-Refunds"
  }, {
    path: "/dashboard/Reports",
    component: _6d9786ac,
    name: "dashboard-Reports"
  }, {
    path: "/dashboard/Reviews",
    component: _28f55106,
    name: "dashboard-Reviews"
  }, {
    path: "/dashboard/Shifts",
    component: _d8173fd4,
    name: "dashboard-Shifts"
  }, {
    path: "/dashboard/SmsPayments",
    component: _844774de,
    name: "dashboard-SmsPayments"
  }, {
    path: "/dashboard/TaxBills",
    component: _55052506,
    name: "dashboard-TaxBills"
  }, {
    path: "/dashboard/TaxRates",
    component: _14fac866,
    name: "dashboard-TaxRates"
  }, {
    path: "/dashboard/Users",
    component: _2f030533,
    name: "dashboard-Users"
  }, {
    path: "/payment/error",
    component: _3cc9e476,
    name: "payment-error"
  }, {
    path: "/payment/status",
    component: _01a91105,
    name: "payment-status"
  }, {
    path: "/payment/success",
    component: _1f9f5100,
    name: "payment-success"
  }, {
    path: "/dashboard/reports/DailyCashFlowReport",
    component: _705c6cae,
    name: "dashboard-reports-DailyCashFlowReport"
  }, {
    path: "/dashboard/reports/ExcisesReport",
    component: _206be797,
    name: "dashboard-reports-ExcisesReport"
  }, {
    path: "/dashboard/reports/ExtendedReport",
    component: _7df30602,
    name: "dashboard-reports-ExtendedReport"
  }, {
    path: "/dashboard/reports/FinancialReport",
    component: _48b710b8,
    name: "dashboard-reports-FinancialReport"
  }, {
    path: "/dashboard/reports/GoodsReport",
    component: _3024a8c5,
    name: "dashboard-reports-GoodsReport"
  }, {
    path: "/dashboard/reports/MallReport",
    component: _390d1f9d,
    name: "dashboard-reports-MallReport"
  }, {
    path: "/dashboard/reports/PeriodicReport",
    component: _03a5dce4,
    name: "dashboard-reports-PeriodicReport"
  }, {
    path: "/dashboard/reports/ReceiptsReport",
    component: _73aed384,
    name: "dashboard-reports-ReceiptsReport"
  }, {
    path: "/dashboard/reports/ShiftsReport",
    component: _06614a3a,
    name: "dashboard-reports-ShiftsReport"
  }, {
    path: "/dashboard/reports/TaxReports",
    component: _5dc884c9,
    name: "dashboard-reports-TaxReports"
  }, {
    path: "/dashboard/reports/TechnicalReturnReport",
    component: _6626c7dc,
    name: "dashboard-reports-TechnicalReturnReport"
  }, {
    path: "/dashboard/reports/ZReport",
    component: _0df2ee69,
    name: "dashboard-reports-ZReport"
  }, {
    path: "/auth/set-password/:token?",
    component: _23f84489,
    name: "auth-set-password-token"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
