<template lang="pug">
  div(class="loader-container")
    div(class="loader")
      div(class="loader__rect")
</template>

<script>
export default {
  name: 'MLoader'
}
</script>

<style scoped lang="scss">
$loader-size: 48px;
$border-thickness: 12px;
$rect-size: 12px;
$gray-color: #e0e0e0;
$black-color: #000;
$animation-duration: 3s;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  @include flex-center;
  background-color: #fff;
}

.loader {
  width: $loader-size;
  height: $loader-size;
  border: $border-thickness solid $gray-color;
  position: relative;

  &__rect {
    width: $rect-size;
    height: $rect-size;
    background-color: $black-color;
    position: absolute;
    top: -$border-thickness;
    left: -$border-thickness;
    transform-origin: center;
    animation: move $animation-duration linear infinite;
  }
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate($loader-size - $rect-size, 0);
  }
  50% {
    transform: translate($loader-size - $rect-size, $loader-size - $rect-size);
  }
  75% {
    transform: translate(0, $loader-size - $rect-size);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
