<template lang="pug">
  div(class="a-bank-acquiring-order-info")
    div(v-if="error" class="a-bank-acquiring-order-info__banner")
      p(class="a-bank-acquiring-order-info__icon") {{ icon }}
      h3(class="a-bank-acquiring-order-info__title") {{ $t(title) }}
    m-block-banner(
      v-else
      :image="require(`~/assets/images/pos-terminal-a-bank.png`)"
      :title="title"
    )
      template(#text)
        p(class="a-bank-acquiring-order-info__banner-text") {{ $t('As soon as the bank makes a decision, you will receive a notification on') }}
          |
          |
          span(class="a-bank-acquiring-order-info__banner-text--link") {{ $User.email }}
</template>

<script>
import MBlockBanner from '~/components/modules/blocks/concrete/m-block-banner'
export default {
  name: 'BlockABankAcquiringOrderInfo',
  components: {
    MBlockBanner
  },
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    error () {
      return this._.get(this.info, 'error', false)
    },
    icon () {
      if (this.error) {
        return '❌'
      }
      return '✅'
    },
    title () {
      const infoText = this._.get(this.info, 'text', null)
      return infoText || 'The application for the provision of acquiring services has been sent!'
    }
  },
  created () {
    this.changeTitle('Get bank`s terminal and start working')
    this.toggleCloseBtn(true)
  }
}
</script>

<style scoped lang="scss">
.a-bank-acquiring-order-info {

  &__icon {
    font-size: 48px;
    margin-bottom: 40px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 18px;
    }
  }

  &__banner-text {
    color: #000;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;

    &--link {
      color: #04AED4;
    }
  }

  //&__text {
  //  font-size: 14px;
  //  font-weight: 300;
  //  color: #000;
  //  margin: 0;
  //}
}
</style>
