import { render, staticRenderFns } from "./m-orm-dialog-tree-select.vue?vue&type=template&id=6bf2c139&scoped=true&lang=pug&"
import script from "./m-orm-dialog-tree-select.vue?vue&type=script&lang=js&"
export * from "./m-orm-dialog-tree-select.vue?vue&type=script&lang=js&"
import style0 from "./m-orm-dialog-tree-select.vue?vue&type=style&index=0&id=6bf2c139&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf2c139",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VIcon,VTreeview})
