import contentDialog from '~/mixins/dialogs/contentDialog'
import contentDialogOverTop from '~/mixins/dialogs/contentDialogOverTop'
import Receipts from '~/modules/receipt/models/Receipts'

const receiptDialogs = {
  mixins: [contentDialog, contentDialogOverTop],
  methods: {
    async openProcessingAuthDialog ({ onInput, ...props }) {
      await this.contentDialog.open({
        component: 'block-processing-employee-login',
        width: '600px',
        onClose: onInput,
        componentProps: {
          onInput,
          ...props
        }
      })
    },
    async openServiceReceiptDialog ({ ...props }) {
      await this.contentDialog.open({
        component: 'block-receipt-service',
        width: '450px',
        componentProps: {
          ...props
        }
      })
    },
    async openReceiptTextDialog ({ width, contentDialogTop = false, ...props } = {}) {
      let dialogWidth
      const defaultReceiptWidth = 42
      if (width) {
        dialogWidth = width
      } else {
        const receiptSettingsWidth = this._.get(this.$Organization, 'receiptSetting.width', defaultReceiptWidth) || defaultReceiptWidth // lodash get method returns third value, only if second value is undefined, but we get null if not setting receipts settings
        dialogWidth = receiptSettingsWidth * 9.5 + 84
      }
      const params = {
        component: 'block-receipt-text-view',
        width: dialogWidth + 'px',
        componentProps: {
          ...(props || {})
        }
      }
      if (contentDialogTop) {
        await this.contentDialogOverTop.open(params)
      } else {
        await this.contentDialog.open(params)
      }
    },
    async openReceiptDialog ({ width = '470px', contentDialogTop = false, model, view, item, config } = {}) {
      const params = {
        component: 'block-receipt-view',
        width,
        contentFullHeight: true,
        componentProps: {
          model: model || Receipts,
          view,
          item,
          config: {
            view: 'html',
            download: 'pdf',
            downloadNameField: 'fiscal_code',
            downloadPrefix: 'receipt',
            showActionButtons: false,
            ...(config || {})
          }
        }
      }
      if (contentDialogTop) {
        await this.contentDialogOverTop.open(params)
      } else {
        await this.contentDialog.open(params)
      }
    }
  }
}

export default receiptDialogs
