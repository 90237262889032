import Taxes from '~/modules/tax-rates/models/Taxes'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'

const actions = {
  computed: {
    model () {
      return AccountingGoods
    }
  },
  methods: {
    async onBarcodeScanRequest ({ barcode, useLeftovers, outletId, useBranches }) {
      const filter = {
        search: barcode,
        limit: 10,
        offset: 1,
        'order[code]': 'desc',
        without_total_items: true,
        load_childs: true,
        load_count: useLeftovers
      }
      if (useBranches) {
        filter.branch_id = outletId
      }
      const res = this._.get(await this.model.api().filter(filter).all({}, { save: false }), 'response.data.data', [])
      this._.each(res, (product) => {
        product.taxes = Taxes.query().whereIdIn(product.taxes).get()
        return product
      })
      // eslint-disable-next-line new-cap
      const goods = this._.map(res, (item) => {
        if (item.related_barcodes) {
          const relatedBarcodesArr = item.related_barcodes.split(',')
          if (relatedBarcodesArr.includes(barcode)) {
            item.barcode = barcode
          }
        }
        // eslint-disable-next-line new-cap
        return new this.model(item)
      })
      return goods
    }
  }
}

export default actions
