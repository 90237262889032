import moment from 'moment-timezone'
import { formats } from '~/const/global'

export default superclass => class extends superclass {
  getDate (date) {
    return this.$moment(date).format(formats.date)
  }

  getTime (time) {
    return this.$moment(time).format(formats.time)
  }

  getDateTime (datetime) {
    if (!datetime) { return null }
    return this.$moment(datetime).format(formats.dateTime)
  }

  getDateTimeProcessing (datetime) {
    if (!datetime) { return null }
    // TODO Временное решение (таймзона)
    const date = moment(datetime).toDate()
    let offset = 0
    // if (date.toString().includes('GMT+0300')) {
    if (datetime.includes('+03:00')) {
      offset = 3
    } else if (datetime.includes('+02:00')) {
      offset = 2
    } else if (datetime.includes('+01:00')) {
      offset = 1
    }
    date.setHours(Math.round(date.getHours() + offset))
    return this.$moment(date).format(formats.dateTime)
  }
}
