import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'

export class RozetkapayOrder extends PersistAble(OrmModel) {
  static entity = 'rozetkapayorder'
  static paginated = true
  static defaultSortOrder = true

  static STATUSES = {
    orderCreated: 'order_created',
    questionarySend: 'questionary_send',
    docsSaved: 'docs_saved',
    docsSend: 'docs_send',
    signed: 'signed'
  }

  static STATUSES_EXTERNAL = {
    userInfoProviding: 'user_info_providing',
    documentsUploading: 'documents_uploading',
    initialReview: 'initial_review',
    finmonReview: 'finmon_review',
    covenant: 'covenant',
    activation: 'activation',
    activated: 'activated',
    declined: 'declined',
    failed: 'failed'
  }

  static fields () {
    return {
      id: this.attr(null),
      rid: this.attr(null),
      token: this.attr(null),
      phone: this.attr(null),
      identifierType: this.attr(null),
      identifierValue: this.attr(null),
      orderId: this.attr(null),
      businessId: this.attr(null),
      orderStatus: this.attr(null),
      orderStatusExternal: this.attr(null),
      personData: this.attr([]),
      documents: this.attr([])
    }
  }

  get terminalNameString () {
    return '–'
  }

  get providerNameString () {
    return 'RozetkaPay'
  }

  static ormHeaders = [
    { text: 'Status', value: 'usageString', sortable: false, filterable: false },
    { text: 'Terminal ID', value: 'terminalNameString', sortable: false, filterable: false },
    { text: 'Connected to cash register', value: 'cashRegister', sortable: false, filterable: false },
    { text: 'Bank', value: 'providerNameString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    usageString: {
      component: 'block-rozetkapay-status'
    },
    cashRegister: {
      component: 'block-pos-terminal-cash-register-status',
      attrs: {
        isCreating: true
      }
    }
  }

  static ormActions = [
    {
      name: 'continueOrder',
      text: 'Continue the order',
      visible: (item) => {
        const orderStatusExternal = get(item, 'orderStatusExternal')
        return orderStatusExternal === RozetkapayOrder.STATUSES_EXTERNAL.userInfoProviding ||
          orderStatusExternal === RozetkapayOrder.STATUSES_EXTERNAL.documentsUploading
      },
      icon: {
        type: 'e-svg-icon',
        text: 'shopping-cart-5'
      },
      call: () => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          width: '900px',
          component: 'block-rozetkapay-connecting',
          contentNoGutters: true,
          contentFullHeight: true
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.updatePersonData = function (id, payload) {
        return this.put(Model.$routes.rozetkapayorder.personData(id), payload)
      }
      configActions.getSignLink = function (id) {
        return this.get(Model.$routes.rozetkapayorder.signLink(id), {
          save: false
        })
      }
      configActions.documents = function (id, payload) {
        return this.post(Model.$routes.rozetkapayorder.documents(id), payload, {
          save: false,
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      }
      return configActions
    }
  }
}

export default RozetkapayOrder
