<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('Error in submitting an application to the DPS') }}
    div(class="dialog-video")
      span(v-if="isEmployeeOrder")
        e-link(
          href="https://youtu.be/dHzrXcttMTs"
        ) {{ $t('Cashier\'s key replacement') }}
    div
      v-row()
        v-col(cols="12")
          p(class="color-warning mb-0") {{ $t(message) }}
        v-col(
          v-if="errorComponent"
          cols="12"
        )
          component(:is="errorComponent")
    div(class="dialog__actions")
      v-row(justify="center")
        v-col(
          cols="12"
          sm="auto"
          class="pb-0"
        )
          e-button-main(
            v-if="!errorComponent"
            @click="openLink('https://wiki.checkbox.ua/uk/instructions/error/registration-errors')"
          )
            | {{ $t('The most common mistakes') }}
        v-col(
          cols="12"
          sm="auto"
          class="pb-0"
        )
          v-btn(
            v-if="hasErrorFile"
            class="secondary-button"
            @click="loadErrorFile"
            color="black"
            outlined
            :loading="errorFileLoading"
          )
            | {{ $t('Document ') }}
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'
import BlockPrivatBankFirstDocumentError
  from '~/modules/orders/views/components/errors/block-privat-bank-first-document-error'
import errorsMessages from '~/modules/orders/constants/errorsMessages'
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'BlockOrderError',
  components: {
    ELink,
    EButtonMain,
    BlockPrivatBankFirstDocumentError
  },
  props: {
    orderId: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    hasErrorFile: {
      type: Boolean,
      default: false
    },
    model: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    errorFileLoading: false,
    errorComponent: null
  }),
  computed: {
    isEmployeeOrder () {
      return this.model && this.model.name === 'EmployeeOrder'
    }
  },
  created () {
    this.errorsMap()
  },
  methods: {
    errorsMap (message) {
      const regEx = /[^a-zA-Zа-яА-ЯіІїЇєЄґҐ\s]/gi
      const msg = this._.lowerCase((message || this.message).replace(regEx, ''))
      for (const key in errorsMessages) {
        const errMsg = this._.lowerCase(this._.get(errorsMessages, `${key}.message`, ''))
        const callEvent = this._.get(errorsMessages, `${key}.callEvent`, '')
        if (msg.includes(errMsg)) {
          this.errorComponent = this._.get(errorsMessages, `${key}.component`, null)
          if (this._.isFunction(callEvent)) {
            callEvent(this)
          }
        }
      }
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    async loadErrorFile () {
      try {
        this.errorFileLoading = true
        const res = await this.model.api().get(this.model.$routes[this.model.entity].error(this.orderId), {
          responseType: 'blob'
        })
        const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), '_blank')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.errorFileLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  .dialog__title {
    padding-bottom: 10px;
  }
  .dialog-video {
    span {
      font-size: 14px;
    }
  }
}

</style>
