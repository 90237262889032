<template lang="pug">
  div(class="dialog")
    div(class="dialog__img-wrap")
      img(
        src="~assets/images/racoons/racoon-greeting.svg"
        class="dialog__img"
      )
      div(class="dialog__img-text") {{ $t('News') }}
    div(class="dialog__title") {{ $t('Connecting the portal with the terminal!') }}
    div(class="dialog__text") {{ $t('We have the possibility of combining the portal with your terminal for sales using acquiring') }}
    v-checkbox(
      v-model="dontRemind"
      :label="$t('Don\\'t remind me anymore')"
      @change="dontRemindChange"
      hide-details
    )
    div(class="dialog__actions")
      v-btn(
        v-if="!dontRemind"
        class="secondary-button"
        color="black"
        @click="onRemindLateClick"
        outlined
      ) {{ $t('Remind me later') }}
      v-btn(
        class="secondary-button"
        @click="mainBtnClick"
        dark
      ) {{ mainBtnText }}
</template>

<script>
export default {
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    currentShiftId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dontRemind: false
  }),
  computed: {
    mainBtnText () {
      return this.dontRemind ? this.$t('Clear') : this.$t('More details')
    }
  },
  created () {
    this.saveDataToLocalStorage({
      shiftId: this.currentShiftId
    })
  },
  methods: {
    saveDataToLocalStorage (data = {}) {
      const localStorageClientData = localStorage.getItem('clientData') && JSON.parse(localStorage.getItem('clientData'))
      const terminalUsageInfo = this._.get(localStorageClientData, 'terminalUsageInfo')
      this.$setLocalStorageClientData({
        terminalUsageInfo: {
          ...terminalUsageInfo,
          ...data
        }
      })
    },
    dontRemindChange (val) {
      this.saveDataToLocalStorage({
        dontShowTerminalUsageReminder: val
      })
    },
    onRemindLateClick () {
      this.$gtm.push({
        event: 'epz_reminder_dialog_remind_later_btn_click',
        organization: this._.get(this.$Organization, 'owner.email')
      })
      this.closeModal()
    },
    mainBtnClick () {
      if (!this.dontRemind) {
        this.$gtm.push({
          event: 'epz_reminder_dialog_details_btn_click',
          organization: this._.get(this.$Organization, 'owner.email')
        })
        window.open('https://checkbox.ua/acquiring/', '_blank')
      } else {
        this.closeModal()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__img-wrap {
    text-align: center;
  }

  &__img {
    display: block;
    position: relative;
    margin: 0 auto;
    right: -30px;
    bottom: -23px;
    z-index: 1;
    width: 112px;
  }

  &__img-text {
    background: #8F257D;
    border-radius: 12px;
    padding: 8px 20px;
    font-weight: 700;
    color: #fff;
    font-size: 24px;
    display: inline-block;
    position: relative;
    z-index: 2;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    padding: 32px 0 12px;
  }

  &__text {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 5px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 22px;
  }
}
</style>
