<template lang="pug">
  ValidationObserver(:ref="orderModel.entity" slim)
    //t-dialog(
    //  v-model="depositSignDialog"
    //  type="default"
    //  size="md"
    //  max-width="500"
    //)
    //  template(#title)
    //    p(class='text-center ma-0 pa-0') {{$t('Set deposit sign token')}}
    //  template(#content)
    //    v-row
    //      v-col(class="pb-6")
    //        m-form-depositsign-token(:closeModal="closeDepositSignModal")
    v-form(@submit.prevent.stop="modify")
      t-orm-fields(
        v-if="renderFields"
        v-bind.sync="editedItem"
        :items="fields"
      )
      div(
        v-if="editedItem.signatureType === orderModel.signTypes.depositsign.value"
        class="mb-4 hint"
      )
        | {{ $t('Click') }}
        e-link(
          @click="openDepositSignDialog"
          class="ml-1 hint"
          underline
        ) {{ $t('here') }}
        |, {{ $t('if you want to change access to the Deposit Sign') }}
      v-row(class="my-4")
        v-col(cols="12")
          t-orm-buttons(ref="fields" :items="buttons" :key="fieldsKey" :classes="['text-center']")
</template>

<script>
import DOMPurify from 'dompurify'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import MFormDepositsignToken from '~/components/modules/forms/concrete/m-form-depositsign-token'
import TDialog from '~/components/templates/t-dialog'
import Employees from '~/modules/employees/models/Employees'
import signPluginService from '~/modules/eds-iit-checkbox/mixins/signPluginService'
import informationDialog from '~/mixins/dialogs/informationDialog'

export default {
  components: {
    TOrmButtons,
    TOrmFields,
    ELink,
    ESvgIcon,
    MFormDepositsignToken,
    TDialog
  },
  mixins: [signPluginService, informationDialog],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    onSubmit: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    fieldsKey: 1,
    model: Employees,
    orderModel: EmployeeOrder,
    editedItem: {},
    renderFields: true,
    loading: false,
    depositSignDialog: false
  }),
  computed: {
    fields () {
      return [
        {
          model: 'T1RXXXXG1S',
          component: 'v-text-field',
          provider: {
            vid: 'T1RXXXXG1S',
            name: 'Name of the cashier',
            rules: 'required|max:150'
          },
          fieldVal: () => this.item.name,
          attrs: {
            label: 'Name of the cashier',
            outlined: true
          }
        },
        {
          model: 'login',
          component: 'v-text-field',
          provider: {
            vid: 'login',
            name: 'Employee login',
            rules: 'latin_dashes|required|max:20'
          },
          attrs: {
            label: 'Employee login',
            outlined: true
          }
        },
        {
          model: 'password',
          component: 'e-input-password',
          provider: {
            vid: 'password',
            name: 'New password for employee',
            rules: 'min:6|max:50' + (this.item.hasPassword ? '' : '|required')
          },
          attrs: {
            label: 'New password for employee',
            appendIcon: true,
            outlined: true,
            showPasswordStrength: true,
            hasHint: true
          },
          hint: 'Set if need to update employee password'
        },
        {
          model: 'T1RXXXXG2S',
          component: 'v-text-field',
          provider: {
            vid: 'T1RXXXXG2S',
            name: 'DRFOCode',
            rules: 'required|inn_passport'
          },
          fieldVal: () => this.item.DRFOCode,
          attrs: {
            visible: false
          }
        },
        {
          model: 'T1RXXXXG3S',
          component: 'v-text-field',
          provider: {
            vid: 'T1RXXXXG3S',
            name: 'Specify the EDS key ID',
            rules: 'required'
          },
          attrs: {
            label: 'Specify the EDS key ID',
            type: 'text',
            visible: false
          }
        },
        {
          model: 'signatureType',
          component: 'v-select',
          fieldVal: () => this.orderModel.signTypes.agent.value,
          provider: {
            vid: 'signatureType',
            name: 'Signature type',
            rules: 'required'
          },
          attrs: {
            visible: false
          },
          items: () => Object.values(this.orderModel.signTypes)
        },
        {
          model: 'T1RXXXXG4S',
          component: 'v-checkbox',
          provider: {
            vid: 'T1RXXXXG4S',
            name: this.isActiveEntity ? 'Deactivate' : 'Activate',
            rules: 'required'
          },
          attrs: {
            label: this.isActiveEntity ? 'Deactivate' : 'Activate',
            outlined: true,
            trueValue: this.isActiveEntity ? this.orderModel.itemTypes.deactivate.value : this.orderModel.itemTypes.head_cashier.value,
            falseValue: this.isActiveEntity ? this.orderModel.itemTypes.head_cashier.value : this.orderModel.itemTypes.deactivate.value
          },
          hint: this.$t('Select if you want to change the entity in the sfs', {
            entity: this.$t('Employee '),
            action: this.isActiveEntity ? this._.lowerCase(this.$t('Deactivate')) : this._.lowerCase(this.$t('Activate'))
          })
        }
      ]
    },
    isActiveEntity () {
      return this._.get(this.item, 'mode') === this.model.itemModes.checkbox.mode
    },
    buttons () {
      return [
        {
          text: 'Save',
          attrs: {
            class: 'main-button',
            type: 'submit'
          },
          loading: this.loading
        }
      ]
    }
  },
  created () {
    const active = this.isActiveEntity ? this.orderModel.itemTypes[this._.get(this.item, 'type', null)].value : this.orderModel.itemTypes.deactivate.value
    this.setFields({
      login: this._.get(this.item, 'login', null),
      T1RXXXXG1S: this._.get(this.item, 'name', null),
      T1RXXXXG2S: this._.get(this.item, 'DRFOCode', null),
      T1RXXXXG3S: this._.get(this.item, 'publicKeyId', null),
      T1RXXXXG4S: active,
      signatureType: this._.get(this.item, 'signatureType', null),
      password: null,

      dsToken: this._.get(this.item, 'depositsignKeyToken', null),
      dsPass: this._.get(this.item, 'depositsignKeyPassword', null)
    })
  },
  methods: {
    rerenderFields () {
      // Note. Workaround to force rerender fields and set new values
      // TODO delete this when orm fields will be refactored
      this.renderFields = false
      return this.$nextTick().then(() => {
        this.renderFields = true
      })
    },
    setFields (data) {
      this.$set(this.$data, 'editedItem', data)
      this.rerenderFields()
    },
    async modify () {
      const valid = await this.$refs[this.orderModel.entity].validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true
        const itemType = this._.filter(Object.values(this.orderModel.itemTypes), { value: this._.get(this.editedItem, 'T1RXXXXG4S', null) })
        const modifyInSfs = this._.get(this.item, 'name') !== this._.get(this.editedItem, 'T1RXXXXG1S') ||
          this._.get(this.item, 'mode') !== this._.get(itemType, '[0].mode')
        const loginChanged = this._.get(this.item, 'login') !== this._.get(this.editedItem, 'login')
        const modifyLocal = loginChanged ||
          this._.get(this.editedItem, 'password') ||
          this._.get(this.item, 'depositsignKeyToken') !== this._.get(this.editedItem, 'dsToken') ||
          this._.get(this.item, 'depositsignKeyPassword') !== this._.get(this.editedItem, 'dsPass')

        if (modifyLocal) {
          const payload = {
            login: this._.get(this.editedItem, 'login'),
            depositsignKeyToken: this._.get(this.editedItem, 'dsToken'),
            depositsignKeyPassword: this._.get(this.editedItem, 'dsPass')
          }
          if (this._.get(this.editedItem, 'password')) {
            payload.password = this._.get(this.editedItem, 'password')
          }

          if (loginChanged) {
            const login = payload.login
            const checkLoginResponse = await this.orderModel.api().checkLogin({ login })
            const freeLogin = this._.get(checkLoginResponse, 'response.data.freeLogin', null)
            if (freeLogin) {
              await this.informationDialog.open({ text: this.$t('dialogs.information.loginOccupiedByAnotherUser', { login: DOMPurify.sanitize(login), freeLogin }), width: '600px' })
              this.editedItem.login = freeLogin
              await this.rerenderFields()
              return
            }
          }

          await this.model.api().updateLocal(this.item, payload)

          if (!modifyInSfs) {
            this.$notification.success(this.$t('The cashier has been successfully edited'))
          }
        }

        if (modifyInSfs) {
          const DRFOCode = this._.get(this.editedItem, 'T1RXXXXG2S') || this._.get(this.$Organization, 'edrpou', null) || this._.get(this.$Organization, 'taxNumber', null)

          await this.sendEntityTaxOrder({
            type: 'modify',
            entity: this.item,
            orderModel: this.orderModel,
            model: this.model,
            data: {
              T1RXXXXG4S: this._.get(this.editedItem, 'T1RXXXXG4S'),
              T1RXXXXG1S: this._.get(this.editedItem, 'T1RXXXXG1S'),
              T1RXXXXG2S: DRFOCode,
              employee: `/employees/${this._.get(this.item, 'id')}`
            }
          })
        }

        if (this._.isFunction(this.onSubmit)) {
          await this.onSubmit(this.editedItem)
        }
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.orderModel.entity])
      } finally {
        this.loading = false
      }
    },
    async closeDepositSignModal ({ token, password, fullName, publicKeyId, DRFOCode }) {
      if (!token || !password || !publicKeyId) {
        return null
      }

      if (publicKeyId !== this._.get(this.item, 'publicKeyId')) {
        this.$handlers.error(this.$t('The EDS key does not match the selected cashier'), this)
        return null
      }

      this.editedItem.T1RXXXXG1S = fullName
      this.editedItem.T1RXXXXG3S = publicKeyId.toLowerCase()
      this.editedItem.T1RXXXXG2S = DRFOCode
      this.editedItem.dsToken = token
      this.editedItem.dsPass = password
      await this.rerenderFields()
      this.editedItem.signatureType = 'DEPOSITSIGN'
    },
    openDepositSignDialog () {
      this.depositSignDialog = true
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem;
  line-height: 1.2;
}
.choice-text {
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 20px 0 40px;
  flex-grow: 1;
}
</style>
