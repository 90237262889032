<template lang="pug">
  v-card(:class="{ 'org-form': true, 'org-form--registration': isRegistration }")
    v-row(justify="center" align="center" no-gutters)
      v-col(cols="12")
        v-img(
          v-if="showSkipButton"
          class="org-form__img"
          :src="require('~/assets/images/racoons/racoon-greeting.svg')"
        )
        h2(:class="{ 'org-form__title': true, 'mb-6': title && !orgExists, 'text-left': isPromotion && showSkipButton }") {{ title }}

        div(v-if="showOrgData")
          v-row(
            v-if="showOrgData[key]"
            v-for="(value, key, index) in showOrgData"
            :key="index"
          )
            v-col(cols="6")
              span {{ $t(key) }}:
            v-col(cols="6")
              span {{ $t(value) }}

        div(v-else)
          div(v-if="orgExists")
            h3(class="my-0 text-center") {{ $t('Organization exists') }}
          div(v-else-if="isPhysicalKey")
            p(class="my-0") {{ $t('Physical key error') }}
          div(v-else-if="notEnoughData")
            p(class="my-0") {{ $t('Something wrong with key') }}
          div(v-else-if="notIeOrLlc")
            h3(class="my-0 text-center") {{ $t('According to this key, no FOP or LLC is registered in the tax office') }}
          div(v-else)
            p(
              v-if="isPromotion"
              class="mt-0 mb-6"
            ) {{ $t('registrationPromotionText') }}
            p(class="my-0") {{ $t('not to enter information manually') }}

        div(v-if="showManuallyData")
          p(class="org-form__text") {{ $t('But, unfortunately, some data are not available in the traffic police, so we suggest entering them manually') }}:
          ValidationObserver(
            ref="manuallyData"
            slim
          )
            ValidationProvider(
              v-if="!_.get(orgData, 'ownedOrganization.name')"
              :name="$t('Organization name')"
              vid="name"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="manuallyData.name"
                :label="$t('Organization name')"
                :error-messages="errors"
                class="app-outlined-input"
                outlined
              )
            ValidationProvider(
              v-if="showTaxationTypeChoose"
              :name="$t('The type of taxation')"
              vid="tax"
              mode="lazy"
              v-slot="{ errors }"
            )
              v-checkbox(
                :label="$t('I am a vat payer')"
                v-model="manuallyData.isVAT"
                class="mt-0"
              )
        e-link(
          v-if="!(showOrgData || orgExists || (isPhysicalKey || notEnoughData) || notIeOrLlc)"
          href="https://checkbox.ua/yak-otrymaty-klyuch-ecp.html"
          class="mt-6"
        ) {{ $t('How to get an EDS key?') }}
      v-col(cols="12" class="mt-6")
        div(:class="{ 'org-form__btns-wrap': true, 'org-form__btns-wrap--one-button': !showSkipButton && isRegistration }")
          v-btn(
            v-if="showSkipButton"
            class="secondary-button"
            @click="skipStep"
            black
            text
          ) {{ $t('Later') }}
          e-button-main(
            :enableIcon="false"
            @click="btnClick"
            :loading="orgDataLoading"
          ) {{ btnText }}
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'
import taxAuth from '~/mixins/pages/tax-auth'
import ELink from '~/components/elements/links/e-link'
import loadOrganizations from '~/middleware/loadOrganizations'
import loadCurrentUser from '~/middleware/loadCurrentUser'
import organization from '~/mixins/organization/organization'
import multiAccountUsers from '~/mixins/organization/multiAccountUsers'
import promoCashRegisters from '~/modules/promotion/mixins/promoCashRegisters'

export default {
  components: {
    ELink,
    EButtonMain
  },
  mixins: [taxAuth, organization, multiAccountUsers, promoCashRegisters],
  props: {
    isRegistration: {
      type: Boolean,
      default: false
    },
    isPromotion: {
      type: Boolean,
      default: false
    },
    isMultiUser: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showOrgData: null,
    orgData: {
      ownedOrganization: {}
    },
    orgDataLoading: false,
    orgExists: false,
    notIeOrLlc: false,
    isPhysicalKey: false,
    notEnoughData: false,
    showManuallyData: false,
    manuallyData: {
      isVAT: null,
      name: null
    }
  }),
  computed: {
    showSkipButton () {
      return this.isRegistration && !(this.showOrgData || this.orgExists || (this.isPhysicalKey || this.notEnoughData) || this.notIeOrLlc)
    },
    showTaxationTypeChoose () {
      return !this._.get(this.orgData, 'ownedOrganization.isVAT') && !this._.get(this.orgData, 'ownedOrganization.isSingleTax')
    },
    title () {
      if (this.showOrgData) {
        return `${this.$t('Thank you')}, ${this.$t('we received the following data from the SFS')}:`
      } else if (this.orgExists || this.isPhysicalKey || this.notEnoughData || this.notIeOrLlc) {
        return ''
      } else if (this.isRegistration) {
        return this.isPromotion ? `${this.$t('Welcome')}!` : this.$t('Company information')
      } else if (this.isMultiUser) {
        return this.$t('Add organization')
      } else {
        return this.$t('You need to add an organization')
      }
    },
    btnText () {
      if (this.showOrgData) {
        return this.isRegistration ? this.$t('Continue') : this.$t('Add organization')
      } else if (this.orgExists) {
        return this.isRegistration ? this.$t('Please log in') : this.$t('Try with EDS key')
      } else if (this.isPhysicalKey || this.notEnoughData || this.notIeOrLlc) {
        return this.$t('Try with EDS key')
      } else {
        return this.$t('Get data automatically')
      }
    }
  },
  created () {
    this.destroyIitGlobalDialog()
  },
  methods: {
    skipStep () {
      this.$attrs.btnCall(this.$attrs.step, null)
    },
    async btnClick () {
      if (this.showManuallyData) {
        const valid = await this.$refs.manuallyData.validate()
        if (!valid) {
          return
        } else {
          const { name: manuallyName, isVAT } = this.manuallyData
          const name = manuallyName || this._.get(this.orgData, 'ownedOrganization.name', '')
          const shortName = this._.get(this.orgData, 'ownedOrganization.shortName', '') || name
          this.$set(this.orgData, 'ownedOrganization', {
            ...this._.get(this.orgData, 'ownedOrganization', {}),
            name,
            shortName,
            isVAT: !!isVAT,
            isSingleTax: !isVAT
          })
        }
      }

      if (this.showOrgData) {
        if (this.isRegistration) {
          this.$attrs.btnCall(this.$attrs.step, this.orgData)
        } else {
          await this.handleAddOrganization()
        }
      } else if (this.isRegistration && this.orgExists) {
        await this.$router.push('/auth/login')
      } else {
        await this.continueWithSyncData()
      }
    },
    async continueWithSyncData () {
      const data = await this.getEdsKeyData({
        fallback: this.continueWithSyncData
      })
      const edsKeyData = this._.get(data, 'edsKeyData')
      const sign = this._.get(data, 'sign')

      if (!edsKeyData || !sign) {
        return
      }

      try {
        this.orgDataLoading = true

        const dataFromSfs = await this.getDataFromSfs({ edsKeyData, sign })

        if (this._.get(dataFromSfs, 'exists')) {
          this.orgExists = true
        } else if (!this._.get(dataFromSfs, 'registration')) {
          this.notIeOrLlc = true
        } else {
          const { organizationData, token, personShowType } = this.getOrganizationData({ dataFromSfs, edsKeyData })

          this.$set(this.$data, 'orgData', {
            ownedOrganization: {
              ...(organizationData || {}),
              hasRroShare: this.isPromotion && !this.isV2Promotion(organizationData)
            },
            token
          })

          this.showOrgData = {
            Name: name,
            Edrpou: this._.get(organizationData, 'edrpou'),
            'Person type': this.$t(personShowType),
            Director: edsKeyData.subjFullName
          }

          if (!this._.get(organizationData, 'name') || (!this._.get(organizationData, 'isVAT') && !this._.get(organizationData, 'isSingleTax'))) {
            this.showManuallyData = true
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
        this.$handlers.error(new Error('Failed registration. Info: ' + JSON.stringify({
          identity: this._.get(edsKeyData, 'identity'),
          sign
        })), this, true)
      } finally {
        this.orgDataLoading = false
      }
    },
    async handleAddOrganization () {
      try {
        this.orgDataLoading = true

        await this.addOrganization({
          organizationData: this._.get(this.orgData, 'ownedOrganization', null),
          token: this._.get(this.orgData, 'token', null),
          isMultiUser: this.isMultiUser
        })

        await loadCurrentUser({}, true)
        await loadOrganizations({}, true)

        if (this.isMultiUser) {
          this.updateAllOrganizationSelectedUserData()
        }

        this.$notification.success(this.$t('Organization successfully added'))
        this.$nextTick(() => {
          this.$attrs.closeModal(true)
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.orgDataLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.org-form {
  width: 100%;
  box-shadow: none;
  padding: 20px 0;

  &--registration {
    border-radius: 24px !important;
    overflow: hidden;
    max-width: 548px;
    margin: 0 auto;
    padding: 32px;
  }

  &__btns-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    & > .v-btn {
      width: 100%;
      min-height: 48px;

      @media (min-width: map-get($breakpoints, 'xs')) {
        width: calc(50% - 8px);
      }
    }

    &--one-button {
      & > .v-btn {
        width: 100%;

        @media (min-width: map-get($breakpoints, 'xs')) {
          width: 100%;
        }
      }
    }
  }

  &__title {
    font-family: $card-title-font-family;
    font-size: 24px;
    line-height: 32px;
    word-break: break-word;
    text-align: center;
    font-weight: 500;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
    }
  }

  &__text {
    font-weight: 700;
    margin: 15px 0;
  }

  &__img {
    width: 100%;
    max-width: 192px;
    margin: 0 auto 32px;
    display: block;
  }
}
</style>
