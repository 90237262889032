<template lang="pug">
  div(class="dialog")
    div(class="dialog__img-wrap")
      img(
        src="~assets/images/racoons/racoon-idea.svg"
        class="dialog__img"
      )
    div(class="dialog__title") {{ $t('Iban is not connected in Acquiring page') }}
    div(class="dialog__text") {{ $t('You can use QR generation for IBAN payments by going to the Acquiring page') }}
    div(class="dialog__actions" :class="{ 'flex-column': isResponsive }")
      v-btn(
        class="secondary-button"
        :class="{'w-100': isResponsive}"
        color="black"
        @click="closeModal"
        outlined
      ) {{ $t('Not now') }}
      v-btn(
        class="main-button"
        :class="{'w-100': isResponsive}"
        @click="goToAcquiring"
      ) {{ $t('Go to Acquiring') }}
</template>

<script>
export default {
  name: 'BlockIbanUsageInfo',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    currentShiftId: {
      type: String,
      default: ''
    }
  },
  computed: {
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  created () {
    this.saveDataToLocalStorage({
      shiftId: this.currentShiftId
    })
  },
  methods: {
    saveDataToLocalStorage (data = {}) {
      const localStorageClientData = localStorage.getItem('clientData') && JSON.parse(localStorage.getItem('clientData'))
      const ibanUsageInfo = this._.get(localStorageClientData, 'ibanUsageInfo')
      this.$setLocalStorageClientData({
        ibanUsageInfo: {
          ...ibanUsageInfo,
          ...data
        }
      })
    },
    goToAcquiring () {
      this.$router.push({
        path: '/dashboard/acquiring',
        query: {
          to: 'iban'
        }
      })
      this.closeModal(true)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__img-wrap {
    display: flex;
    justify-content: center;
  }

  &__img {
    display: block;
    width: 200px;
    text-align: center;

    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 112px;
    }
  }

  &__title {
    text-align: center;
    font-weight: 700;
    padding: 32px 0 12px;
  }

  &__text {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 5px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 22px;
  }
}
</style>
