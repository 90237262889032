<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ $t('Print settings') }}
    ValidationObserver(ref="form" slim)
      v-form(@submit.prevent.stop="print")
        t-orm-fields(
          v-bind.sync="form"
          :items="fieldsCheckboxes"
        )
        p(class="dialog__error")
          span(v-if="showCheckboxesError") {{ $t('It is necessary to select at least one field') }}
        ValidationProvider(
          rules="required"
          :name="$t('Price tags quantity')"
          vid="priceTagsQuantity"
          v-slot="{ errors }"
          mode="lazy"
        )
          v-select(
            :label="$t('Price tags quantity')"
            v-model="form.priceTagsQuantity"
            :items="selectItems"
            @change="onSelectChange"
            outlined
          )
        div(
          v-if="form.priceTagsQuantity === quantityOptions.custom.value"
          class="dialog__products"
        )
          div(
            v-for="item in items"
            :key="item.id"
            class="dialog__product"
          )
            p(class="dialog__product-name") {{ item.name }}
            div(class="dialog__product-quantity-input")
              ValidationProvider(
                rules="required|min_value:1|integer"
                :name="$t('Count')"
                :vid="customProductsQuantity[item.id]"
                v-slot="{ errors }"
                mode="lazy"
              )
                v-text-field(
                  :label="$t('Count')"
                  v-model="customProductsQuantity[item.id]"
                  :error-messages="errors"
                  type="number"
                  height="20"
                  min="1"
                  outlined
                  hide-details
                )
        div(class="dialog__actions")
          v-btn(
            class="main-button"
            :loading="loading"
            type="submit"
          ) {{ $t('Print ') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AccountingGoodsSettings from '~/modules/goods/models/AccountingGoodsSettings'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  name: 'BlockGoodsPrintForm',
  components: {
    TOrmFields
  },
  mixins: [contentDialog],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    form: {
      barcode: true,
      code: true,
      name: true,
      price: true,
      priceTagsQuantity: 'one'
    },
    customProductsQuantity: {},
    loading: false,
    showCheckboxesError: false
  }),
  computed: {
    useLeftovers () {
      return this._.get(AccountingGoodsSettings.query().first(), 'use_leftovers')
    },
    quantityOptions () {
      return {
        one: {
          value: 'one',
          text: this.$t('One label each')
        },
        total: {
          value: 'total',
          text: this.$t('Total quantity in stock')
        },
        custom: {
          value: 'custom',
          text: this.$t('Specify the quantity')
        }
      }
    },
    selectItems () {
      const items = [
        { text: this.quantityOptions.one.text, value: this.quantityOptions.one.value },
        { text: this.quantityOptions.custom.text, value: this.quantityOptions.custom.value }
      ]
      if (this.useLeftovers) {
        items.splice(1, 0, {
          text: this.quantityOptions.total.text,
          value: this.quantityOptions.total.value
        })
      }
      return items
    },
    fieldsCheckboxes () {
      return [
        {
          model: 'barcode',
          component: 'v-checkbox',
          provider: {
            vid: 'barcode',
            name: 'Barcode'
          },
          attrs: {
            label: 'Barcode',
            hideDetails: true
          },
          cols: {
            force: true,
            cols: 12,
            sm: 6
          }
        },
        {
          model: 'code',
          component: 'v-checkbox',
          provider: {
            vid: 'code',
            name: 'Product code'
          },
          attrs: {
            label: 'Product code',
            hideDetails: true
          },
          cols: {
            force: true,
            cols: 12,
            sm: 6
          }
        },
        {
          model: 'name',
          component: 'v-checkbox',
          provider: {
            vid: 'name',
            name: 'Product name'
          },
          attrs: {
            label: 'Product name',
            hideDetails: true
          },
          cols: {
            force: true,
            cols: 12,
            sm: 6
          }
        },
        {
          model: 'price',
          component: 'v-checkbox',
          provider: {
            vid: 'price',
            name: 'Price'
          },
          attrs: {
            label: 'Price',
            hideDetails: true
          },
          cols: {
            force: true,
            cols: 12,
            sm: 6
          }
        }
      ]
    }
  },
  methods: {
    onSelectChange (val) {
      if (val === this.quantityOptions.custom.value && !Object.keys(this.customProductsQuantity).length) {
        this._.each(this.items, (item) => {
          this.$set(this.customProductsQuantity, item.id, '1')
        })
      }
    },
    generateItemsPayload () {
      return new Promise((resolve) => {
        const result = []
        const priceTagsQuantity = this.form.priceTagsQuantity

        this._.each(this.items, (item) => {
          const formattedItem = {
            id: this._.get(item, 'id'),
            code: this._.get(item, 'code'),
            price: (parseFloat(this._.get(item, 'price')) / 100).toFixed(2) || 0,
            barcode: this._.get(item, 'barcode'),
            name: this._.get(item, 'name')
          }
          if (priceTagsQuantity === this.quantityOptions.one.value) {
            result.push(formattedItem)
          } else {
            let itemCount = (parseInt(this._.get(item, 'count')) / 1000).toFixed(0)

            if (priceTagsQuantity === this.quantityOptions.custom.value) {
              itemCount = parseInt(this._.get(this.customProductsQuantity, item.id)).toFixed(0)
            }

            if (itemCount) {
              for (let i = 0; i < itemCount; i++) {
                result.push(formattedItem)
              }
            }
          }
        })

        resolve(result)
      })
    },
    async print () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        const atLeastOneOptionSelected = this.form.barcode || this.form.code || this.form.name || this.form.price
        this.showCheckboxesError = !atLeastOneOptionSelected
        if (!valid || !atLeastOneOptionSelected) {
          return
        }
        const items = await this.generateItemsPayload()
        this.closeModal()
        setTimeout(() => {
          this.contentDialog.open({
            width: '760px',
            component: 'block-goods-price-tags-print',
            componentProps: {
              items,
              options: this.form
            }
          })
        }, 500)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__actions {
    justify-content: center;
    gap: 16px;
    display: flex;
  }

  &__error {
    padding: 0 12px;
    color: #ff1744;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    min-height: 14px;
    margin: 3px 0 10px;
  }

  &__products {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 7px 20px 0;
    margin-right: -7px;
    max-height: 223px;
    overflow-y: auto;
  }

  &__product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__product-name {
    font-size: 14px;
    flex-grow: 1;
    margin: 0;
  }

  &__product-quantity-input {
    width: 25%;

    &::v-deep {
      .v-input {
        &__slot {
          min-height: 32px;
          height: 32px;
        }
      }
    }
  }
}
</style>
