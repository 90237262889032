import Administration from '~/models/abstracts/Administration'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import Organization from '~/models/directories/Organization'
import i18n from '~/plugins/nuxt-i18n/i18n'

export default class Payment extends ChainInheritance(Administration, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'payment'
  static paginated = true
  static defaultSortParam = 'date'
  static ormLoadWithRelations = true

  static ormTrans = {
    single: 'Payment ',
    multy: 'Payments'
  }

  static statuses = {
    new: {
      text: i18n.t('waiting to be processed'),
      value: 'new'
    },
    error: {
      text: i18n.t('not scattered'),
      value: 'error'
    },
    parsed: {
      text: i18n.t('counted'),
      value: 'parsed'
    }
  }

  static fields () {
    return super.fields({
      id: this.attr(null),
      org_id: this.attr(null),
      date: this.attr(null),
      bankDate: this.attr(null),
      organization: this.belongsTo(Organization, 'org_id'),
      sum: this.attr(null),
      status: this.attr(null),
      errorMessage: this.attr(null),
      typeObj: this.attr(null),
      narrative: this.attr(null)
    })
  }

  get amount () {
    return this.sum && (this.sum / 100).toFixed(2)
  }

  get handleDate () {
    return this.getDateTime(this.date)
  }

  get handleBankDate () {
    return this.getDateTime(this.bankDate)
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', sortable: true, filterable: false },
    { text: 'Date', value: 'handleDate', sortable: true, filterable: false },
    { text: 'Bank date', value: 'handleBankDate', sortable: true, filterable: false },
    { text: 'Purpose of payment', value: 'narrative', sortable: true, width: '300', filterable: true },
    { text: 'Payer', value: 'typeObj.payer_name', sortable: true, filterable: false },
    { text: 'Payer number', value: 'typeObj.payer_iban', sortable: true, filterable: false },
    { text: 'Payer code', value: 'typeObj.payer_code', sortable: true, filterable: false },
    { text: 'Total Amount', value: 'amount', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-radio-status',
      attrs: {
        valueMap: {
          parsed: true,
          new: true,
          error: true
        },
        colorMap: {
          parsed: '#161b25',
          new: '#ff8a00',
          error: '#FF002B'
        },
        tooltipsMap: {
          parsed: this.statuses.parsed.text,
          new: this.statuses.new.text,
          error: this.statuses.error.text
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      attrs: {
        outlined: true,
        'hide-details': true,
        itemText: 'text',
        placeholder: 'Status'
      },
      items: () => [{ text: i18n.t('All'), value: '' }, ...Object.values(this.statuses)]
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 6
              },
              fields: [
                'status'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    {
      model: 'narrative',
      component: 'v-text-field',
      provider: {
        vid: 'narrative',
        name: 'Purpose of payment',
        rules: 'required'
      },
      attrs: {
        label: 'Purpose of payment',
        outlined: true
      },
      fieldVal: ctx => this._.get(ctx.attrs, 'narrative', '')
    },
    {
      model: 'status',
      component: 'v-text-field',
      provider: {
        vid: 'status',
        name: 'Status',
        rules: 'required'
      },
      attrs: {
        label: 'Status',
        outlined: true,
        visible: false
      },
      fieldVal: () => this.statuses.new.value
    },
    {
      model: 'errorMessage',
      component: 'v-text-field',
      provider: {
        vid: 'errorMessage',
        name: 'Error'
      },
      attrs: {
        label: 'Error',
        outlined: true,
        visible: false
      },
      fieldVal: () => ''
    }
  ]

  static ormActions = [
    {
      name: 'edit'
    }
  ]

  static ormDialogs = {
    edit: 'm-orm-crud-dialog'
  }

  static ormDialogsConfig = {
    edit: {
      config: {
        context: 'update'
      }
    }
  }
}
