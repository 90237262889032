import { Reports } from '~/modules/reports/models/Reports'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'

const processingReports = {
  mixins: [processingApiRequest],
  methods: {
    async createXReport (accessToken) {
      return await this.processingApiRequest({
        request: token => Reports.api().processingCreateXReport(token || accessToken)
      })
    },
    getReport (id) {
      return Reports.api().processingGetReport(id)
    },
    getReportText (id) {
      return Reports.api().processingGetReportText(id)
    }
  }
}

export default processingReports
