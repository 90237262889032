<template lang="pug">
  v-row()
    v-col(cols="12")
      v-row(class="mt-2" justify="center" v-if="loading")
        v-col(cols="auto")
          e-progress-circular(size="xl", width=4)
        v-col(cols="12")
          p(class="text-center") {{ $t('Please wait') }}...
      v-row(class="mt-2" justify="center" v-if="done")
        v-col(cols="auto")
          e-svg-icon(name="done-all" size="xbg")
        v-col(cols="12")
          p(class="text-center") {{ $t('A check for the order has been created') }}!
</template>

<script>
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Employees from '~/modules/employees/models/Employees'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import Receipts from '~/modules/receipt/models/Receipts'
import converters from '~/mixins/methods/converters'
import processingReceipt from '~/modules/receipt/mixins/actions/processingReceipt'

export default {
  name: 'BlockCreateOrderReceipt',
  components: { ESvgIcon, EProgressCircular },
  mixins: [converters, processingReceipt],
  props: {
    order: {
      type: Object,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    done: false
  }),
  async created () {
    try {
      this.loading = true
      const order = this._.get(await this.model.api().createReceipt(this.order?.id), 'response.data', null)
      const resultReceipt = await this.checkReceipt(this.order, order?.receiptId)
      const receiptHtml = await this.createHtmlReceiptPreview(resultReceipt?.id)
      this.loading = false
      this.done = true
      if (receiptHtml && resultReceipt) {
        setTimeout(async () => {
          await this.openReceiptDialog({
            contentDialogTop: true,
            view: receiptHtml,
            item: resultReceipt
          })
        }, 3000)
      }
    } catch (e) {
      this.$handlers.error(e, this)
      this.closeModal()
    }
  },
  methods: {
    async checkReceipt (order, receiptId) {
      const accessToken = this._.get(await Employees.api().processingSignin(order?.employee), 'response.data.access_token', null)
      const intervalRequest = new IntervalRequest(async () => {
        return await this.processingApiRequest({
          request: token => Receipts.api().processingRead(receiptId, token),
          token: accessToken,
          employee: order.employee
        })
      })
      const resolveCondition = res => res?.response?.data?.status === Receipts.processingStatuses.DONE || res?.response?.data?.status === Receipts.processingStatuses.ERROR
      const resultReceipt = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
      const errMsg = this._.get(resultReceipt, 'transaction.response_error_message')
      if (resultReceipt?.transaction?.status === 'ERROR' && errMsg) {
        throw new Error(this.$t('Error when get receipt:') + ' ' + errMsg)
      }
      return resultReceipt
    }
  }
}
</script>

<style scoped>

</style>
