import { get } from 'lodash'
import { Model } from '@vuex-orm/core'
import Receipts from '~/modules/receipt/models/Receipts'
import Dialog from '~/models/system/Dialog'
import PersistAble from '~/models/mixins/PersistAble'
import FilterAble from '~/models/mixins/FilterAble'
// import CashRegisters from '~/models/directories/CashRegisters'
import currentOrganization from '~/modules/auth/services/currentOrganization'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import Directory from '~/models/abstracts/Directory'
import { formats } from '~/const/global'
import AppNotifications from '@/services/Notifications/AppNotifications'
import AuthenticatedCashier from '~/modules/receipt/models/AuthenticatedCashier'
import i18n from '~/plugins/nuxt-i18n/i18n'

export class EttnOrders extends ChainInheritance(Directory, [
  FilterAble,
  PersistAble,
  TimestampAble,
  AssignAble
]) {
  static entity = 'ettnOrders'
  static paginated = true
  static defaultSortParam = 'dateCreated'
  static defaultSortOrder = true

  static statuses = {
    created: {
      value: 'created',
      text: i18n.t('Awaiting payment')
    },
    canceled: {
      value: 'cancelled',
      text: i18n.t('Canceled ')
    },
    receiptError: {
      value: 'receipt_error',
      text: i18n.t('Error when issuing a check')
    },
    doneWithoutSms: {
      value: 'done_without_sms',
      text: i18n.t('Fiscalized without SMS')
    },
    returned: {
      value: 'returned',
      text: i18n.t('Receipt returned')
    },
    done: {
      value: 'done',
      text: i18n.t('Fiscalized')
    }
  }

  static ormTrans = {
    single: 'ettn order',
    multy: 'eTTN Orders'
  }

  static fields () {
    return {
      id: this.attr(null),
      status: this.attr(null),
      receiptId: this.attr(null),
      ettnNumber: this.attr(null),
      description: this.attr(null),
      dateCreated: this.attr(null),
      dateModified: this.attr(null),
      lastCheckDate: this.attr(null),
      receiptRelationId: this.attr(null),
      recipientPhone: this.attr(null),
      deliveryEmail: this.attr(null),
      deliveryPhone: this.attr(null),
      cashRegister: this.attr(null),
      receiptBody: this.attr(null),
      totalSum: this.attr(null),
      employee: this.attr(null),

      // admin fields
      hasWebhook: this.attr(null),
      rawError: this.attr(null)
    }
  }

  get totalSumString () {
    return ((this.totalSum || 0) / 100).toFixed(2)
      .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get dateCreatedString () {
    return this.getDateTime(this.dateCreated)
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', width: '150', sortable: true, filterable: false },
    { text: 'Date created', value: 'dateCreatedString', sortable: true, filterable: false, sortQuery: 'dateCreated' },
    { text: 'eTTN number', value: 'ettnNumber', sortable: true, filterable: false },
    { text: 'eTTN description', value: 'description', sortable: true, filterable: false },
    { text: 'Total sum', value: 'totalSumString', align: 'right', sortable: false, filterable: false },
    { text: 'eTTN recipient', value: 'recipientPhone', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '50', sortable: false, filterable: false }
  ]

  static ormFields = [
    {
      model: 'deliveryEmail',
      component: 'v-text-field',
      provider: {
        vid: 'email',
        name: 'E-mail',
        rules: 'email'
      },
      attrs: {
        type: 'email',
        label: 'E-mail',
        outlined: true
      }
    },
    {
      model: 'deliveryPhone',
      component: 'e-input-mask',
      provider: {
        vid: 'phone',
        name: 'Phone',
        rules: 'phone'
      },
      attrs: {
        mask: 'mobile',
        label: 'Phone',
        outlined: true,
        visible: () => {
          const organization = currentOrganization.getCurrentOrganization()
          return organization && organization.enableSmsModule && !organization.isDraft
        }
      }
    }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }

          const {
            done,
            created,
            canceled,
            receiptError,
            doneWithoutSms,
            returned
          } = this.statuses

          return {
            id: item.id,
            [created.value]: {
              text: created.text,
              value: created.value,
              appearance: {
                color: 'rgba(255, 170, 1, 0.2)',
                textColor: '#c37f00'
              }
            },
            [done.value]: {
              text: done.text,
              value: done.value,
              appearance: {
                color: '#161b25',
                textColor: '#ffffff'
              }
            },
            [doneWithoutSms.value]: {
              text: doneWithoutSms.text,
              value: doneWithoutSms.value,
              appearance: {
                color: '#161b25',
                textColor: '#ffffff'
              }
            },
            [canceled.value]: {
              text: canceled.text,
              value: canceled.value,
              appearance: {
                color: 'rgba(22, 27, 37, 0.1)',
                textColor: '#161b25'
              }
            },
            [receiptError.value]: {
              text: receiptError.text,
              value: receiptError.value,
              appearance: {
                color: 'rgba(255, 0, 43, 0.1)',
                textColor: '#FF002B'
              }
            },
            [returned.value]: {
              text: returned.text,
              value: returned.value,
              appearance: {
                color: 'rgba(255, 0, 43, 0.1)',
                textColor: '#FF002B'
              }
            }
          }
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'dateCreated',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      default: () => {
        const date = new Date()
        date.setDate(date.getDate() - 5)
        return [this.$moment(date).format(formats.dateISO8601), this.$moment(new Date()).format(formats.dateISO8601)]
      },
      attrs: {
        outlined: true,
        clearable: true,
        chooseOnClose: true,
        emmitOnlyOnSelectClicked: true,
        range: false,
        type: 'date',
        'hide-details': true,
        label: 'Date created',
        fastDates: true
      }
    },
    {
      model: 'status',
      component: 'v-select',
      attrs: {
        outlined: true,
        'hide-details': true,
        itemText: 'text',
        placeholder: 'Status'
      },
      items: () => [{ text: i18n.t('All'), value: '' }, ...Object.values(this.statuses)]
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search by phone number, ettn numbers',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input'] // todo: ??
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 6
              },
              fields: [
                'search'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 3,
                md: 3
              },
              fields: [
                'dateCreated'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 3,
                md: 3
              },
              fields: [
                'status'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'read',
      text: 'View eTTN',
      call: async (item) => {
        await Dialog.query().where('type', 'content_top').first().open({
          title: ctx => ctx.$t('Express waybill {number}', { number: item.ettnNumber }),
          component: 'm-block-ettn-order-view',
          componentProps: {
            order: item
          }
        })
      }
    },
    {
      name: 'edit',
      visible: item => [EttnOrders.statuses.created.value].includes(item.status)
    },
    {
      name: 'delete',
      visible: item => [EttnOrders.statuses.created.value, EttnOrders.statuses.canceled.value].includes(item.status),
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        await confirmationDialog.open({
          title: ctx => ctx.$t('Confirm deletion of express invoice {number}', { number: item.ettnNumber }),
          onConfirm: async () => {
            await EttnOrders.api().del(item)
            AppNotifications.success('The express waybill has been successfully deleted')
          }
        })
      }
    },
    {
      name: 'openReceiptView',
      icon: {
        type: 'e-svg-icon',
        text: 'receipt'
      },
      visible: item => [EttnOrders.statuses.done.value, EttnOrders.statuses.doneWithoutSms.value].includes(item.status),
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'content_top').first()
        await dialog.open({
          component: 'block-receipt-view',
          width: '470px',
          contentFullHeight: true,
          componentProps: {
            item,
            config: {
              view: 'html',
              download: 'pdf',
              downloadNameField: 'payedAtString',
              downloadPrefix: 'receipt',
              requestField: 'receiptId',
              model: Receipts
            }
          }
        })
      }
    },
    {
      name: 'createReceipt',
      text: 'Create receipt',
      icon: {
        type: 'e-svg-icon',
        text: 'receipt'
      },
      visible: item => [EttnOrders.statuses.receiptError.value].includes(item.status),
      call: async (order) => {
        await Dialog.query().where('type', 'content_top').first().open({
          component: 'block-create-order-receipt',
          width: '400px',
          title: 'Create receipt',
          componentProps: {
            order,
            model: EttnOrders
          }
        })
      }
    },
    {
      name: 'refundReceipt',
      text: 'Refund receipt',
      icon: {
        type: 'e-svg-icon',
        text: 'selling-2'
      },
      visible: (item) => {
        const isDoneStatus = [EttnOrders.statuses.done.value, EttnOrders.statuses.doneWithoutSms.value].includes(item.status)
        const isReceiptRelationId = !item.receiptRelationId
        return isDoneStatus && isReceiptRelationId
      },
      call: (item, data, ctx) => {
        ctx.$router.push({
          path: '/dashboard/refundreceipt',
          query: {
            receiptId: item.receiptId
          }
        })
      }
    }
  ]

  static ormDialogs = {
    edit: 'm-orm-crud-dialog'
  }

  static ormDialogsConfig = {
    edit: {
      config: {
        context: 'update',
        apiActionName: 'updateDelivery'
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.validate = function (payload) {
        return this.post(Model.$routes.ettnOrders.validate(), payload, { save: false })
      }
      configActions.createReceipt = function (orderId) {
        return this.put(Model.$routes.ettnOrders.createReceipt(orderId), {}, { dataKey: null })
      }
      configActions.updateDelivery = function (order, payload) {
        return this.put(Model.$routes.ettnOrders.updateDelivery(order.id), payload, { save: true })
      }
      configActions.updateWebhookSettings = function (orderId) {
        return this.put(Model.$routes.ettnOrders.updateWebhookSettings(orderId), {}, { save: true })
      }
      configActions.validateRelationId = function (payload, token) {
        return this.post(Model.$routes.ettnOrders.validateRelationId(), payload, {
          headers: { 'X-Processing-Authorization': token },
          save: false
        })
      }

      return configActions
    }
  }

  static cashierToken = () => {
    const authenticatedCashier = AuthenticatedCashier.query().first()
    return get(authenticatedCashier, 'accessToken')
  }
}

export default EttnOrders
