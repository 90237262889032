<template lang="pug">
  div(:class="{ 'support-buttons': true, 'mobile': mobile }")
    a(
      v-if="!login"
      :href="`tel:${telLink}`"
      class="support-buttons__link"
      target="_blank"
    ) {{ $t('tel') }}. {{ tel }} (07:00 - 22:00)
    div(
      v-for="(btn, key) in supportButtons"
      :key="key"
      v-if="btn.visible"
    )
      v-tooltip(v-if="btn.tooltip" bottom)
        template(#activator="{on}")
          v-btn(
            v-on="on"
            @click="callAction(btn.call)"
            class="support-buttons__btn"
            :id="btn.id"
            v-bind="$attrs"
            :icon="login"
            depressed
          )
            e-svg-icon(:size="btn.iconSize || 'md'") {{ btn.icon }}
        | {{ btn.tooltip }}
      v-btn(
        @click="callAction(btn.call)"
        class="support-buttons__btn"
        :id="btn.id"
        v-bind="$attrs"
        :icon="login"
        depressed
        v-else
      )
        e-svg-icon(:size="btn.iconSize || 'md'") {{ btn.icon }}
    m-language-switcher(
      :gray="mobile"
      :light="login"
    )
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import contentDialog from '~/mixins/dialogs/contentDialog'
import MLanguageSwitcher from '~/components/modules/m-language-switcher'

export default {
  components: {
    ESvgIcon,
    MLanguageSwitcher
  },
  mixins: [responsive, contentDialog],
  props: {
    login: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tel: '0 800 330 676',
    showChatWidget: false
  }),
  computed: {
    telLink () {
      return 'tel:+38' + this.tel.replace(/\s/g, '')
    },
    supportButtons () {
      return [
        {
          // eslint-disable-next-line no-nested-ternary
          icon: this.mobile ? 'chat-3' : this.login ? 'chat-4' : 'chat-2',
          tooltip: this.$t('Online application'),
          id: 'support-chat',
          iconSize: 'mdl',
          call: this.callSupportChat,
          visible: true
        },
        {
          // eslint-disable-next-line no-nested-ternary
          icon: this.mobile ? 'telegram-2' : this.login ? 'telegram-3' : 'telegram',
          tooltip: 'Telegram',
          call: () => this.openLink('https://t.me/checkboxua_bot'),
          visible: true
        },
        {
          // eslint-disable-next-line no-nested-ternary
          icon: this.mobile ? 'viber-2' : this.login ? 'viber-3' : 'viber',
          tooltip: 'Viber',
          iconSize: 'lg',
          call: () => this.openLink('viber://pa?chatURI=checkboxua'),
          visible: true
        },
        {
          icon: this.login ? 'phone-2' : 'phone',
          iconSize: 'xmd',
          call: () => this.openLink(this.telLink),
          visible: this.login
        },
        {
          // eslint-disable-next-line no-nested-ternary
          icon: this.mobile ? 'like-2' : this.login ? 'like-3' : 'like',
          tooltip: this.$t('Suggestions'),
          iconSize: 'xmd',
          call: this.openReviewForm,
          visible: this.$Organization && !this.$Organization.isDraft && this.$User?.isOwner
        }
      ]
    }
  },
  mounted () {
    this.helpdeskeddyInit()
  },
  methods: {
    async openReviewForm () {
      await this.contentDialog.open({
        component: 'block-review-form',
        width: '600px'
      })
    },
    helpdeskeddyInit () {
      const docScript = document.querySelector('#hde-chat-widget')
      if (docScript) {
        if (docScript.previousSibling.tagName === 'STYLE') {
          docScript.previousSibling.remove()
        }
        const chat = document.querySelector('#hde-container')
        if (chat) {
          chat.remove()
        }
        docScript.remove()
      }
      const script = document.createElement('script')
      script.src = '//cdn.helpdeskeddy.com/js/modules/chat_widget/hde.js'
      script.id = 'hde-chat-widget'
      script.setAttribute('data-lang', 'ua')
      script.setAttribute('data-host', 'support.checkbox.ua')
      script.setAttribute('data-custom-button', 'support-chat')
      script.setAttribute('data-user-name', `${this._.get(this.$User, 'profile.firstName', '')} ${this._.get(this.$User, 'profile.lastName', '')}`)
      script.setAttribute('data-user-email', this._.get(this.$User, 'email', ''))
      document.body.appendChild(script)
    },
    callSupportChat () {
      this.showChatWidget = true
    },
    openLink (url) {
      window.open(url, '_blank')
    },
    callAction (action) {
      if (this._.isFunction(action)) {
        action()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.support-buttons {
  display: flex;
  align-items: center;
  gap: 6px;

  &__link {
    color: rgba(0, 0, 0, 0.6) !important;
    text-align: center;
    font-size: .8rem;
    text-decoration: none;
    margin-right: 7px;
  }

  &__btn {
    width: 40px;
    height: 40px;
  }

  &.mobile {
    flex-wrap: wrap;
    gap: 15px 10px;

    .support-buttons {
      &__link {
        order: 1;
        margin-right: 0;
        width: 100%;
        display: block;
        text-align: left;
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 13px;
      }

      &__btn {
        background-color: rgba(240, 241, 242, 0.2) !important;
      }
    }
  }
}
</style>
