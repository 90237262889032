import { render, staticRenderFns } from "./block-groups-tree.vue?vue&type=template&id=dc6369c8&scoped=true&lang=pug&"
import script from "./block-groups-tree.vue?vue&type=script&lang=js&"
export * from "./block-groups-tree.vue?vue&type=script&lang=js&"
import style0 from "./block-groups-tree.vue?vue&type=style&index=0&id=dc6369c8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc6369c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VCard,VTextField,VTreeview})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
