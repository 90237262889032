<template lang="pug">
  div(class="eds-iit-checkbox")
    h3(class="eds-iit-checkbox__title")
      v-btn(
        v-if="backBtnVisible"
        class="eds-iit-checkbox__back-btn"
        @click="handleBackBtnClick"
        :disabled="backButtonDisabled"
        icon
        small
      )
        e-svg-icon(size="sm") arrow-next
      span {{ $t(shownTitle) }}
    slot(name="subtitle")
    div(
      v-if="localShowCachedDataMessage"
      class="eds-iit-checkbox__loader"
    )
      p {{ $t('You already downloaded the director\'s EDS signature as part of the session, so we took it from the cache. Wait for the operation to complete') }}
      e-progress-circular(
        size="xl"
        width="5"
        color="#161b25"
      )
    eds-iit-checkbox-sign-options(
      v-else-if="currentStep === steps.options"
      @select="handleOptionSelect"
    )
    eds-iit-checkbox-key-info(
      v-else-if="currentStep === steps.keyInfo && localKeyData"
      :key-data="localKeyData"
      :loading="loading"
      @confirm="handleConfirm"
    )
    div(v-else-if="currentStep === steps.fileSign")
      v-radio-group(
        v-model="pkFormType"
        :label="$t('Type of private key carrier')"
        class="eds-iit-checkbox__radio-group"
        :disabled="loading"
        hide-details
      )
        v-radio(
          :value="pkFormTypeFile"
          :ripple="false"
        )
          template(v-slot:label)
            div(class="eds-iit-checkbox__radio-label")
              p {{ $t('File carrier') }}
              span ({{ $t('flash disk, CD disk, SD card, etc') }})
        v-radio(
          :value="pkFormTypeKM"
          :ripple="false"
        )
          template(v-slot:label)
            div(class="eds-iit-checkbox__radio-label")
              p {{ $t('Protected carriers') }}
              span ({{ $t('electronic key Almaz-1K, Crystal-1, Hryada-301, ID card, etc') }})
      div(
        v-if="textError"
        v-html="textError"
        class="eds-iit-checkbox__text-error"
      )
      ValidationObserver(
        ref="form"
        slim
        v-else
      )
        v-form(@submit.prevent.stop="handleReadKey")
          div(v-if="isPkFormTypeFile || isPkFormTypeKM")
            v-select(
              v-model="ca"
              :label="$t('EDS service provider')"
              :items="acskProviders"
              class="eds-iit-checkbox__select"
              :no-data-text="$t('No data available')"
              outlined
              hide-details
            )
            div(v-if="isPkFormTypeFile")
              div(
                class="eds-iit-checkbox__file-input-wrapper"
                :class="{ 'eds-iit-checkbox__file-input-wrapper--error': fileError, 'eds-iit-checkbox__file-input-wrapper--success': _.get(fileData, 'file') }"
              )
                div(class="eds-iit-checkbox__file-input-content")
                  input(
                    v-show="false"
                    @input="handleFileSelected"
                    :accept="isIOS ? '' : '.dat,.pfx,.pk8,.zs2,.jks'"
                    type="file"
                    ref="fileInput"
                    :key="fileInputKey"
                  )
                  div(class="eds-iit-checkbox__file-input-key-icon")
                    e-svg-icon(v-show="fileSelected") key-white-2
                    e-svg-icon(v-show="!fileSelected") key-2
                  div(class="eds-iit-checkbox__file-input-text")
                    h3 {{ fileName || $t('Select eds key') }}
                    p(v-if="!fileSelected") {{ $t('Possible formats: *.dat, *.pfx, *.pk8, *.zs2, *.jks') }}
                  v-btn(
                    @click="handleFileSelect"
                    class="secondary-button eds-iit-checkbox__file-input-btn"
                    outlined
                  ) {{ fileSelected ? $t('Change') : $t('choose') }}
                div(
                  v-if="fileError"
                  class="eds-iit-checkbox__error"
                ) {{ $t(fileError) }}
            div(v-else-if="isPkFormTypeKM")
              ValidationProvider(
                rules="required"
                vid="km"
                :name="$t('Personal key carrier')"
                v-slot="{ errors }"
                mode="passive"
              )
                v-select(
                  v-model="km"
                  :label="$t('Personal key carrier')"
                  :items="keyMedias"
                  class="mb-1"
                  :error-messages="errors"
                  :no-data-text="$t('No data available')"
                  item-text="visibleName"
                  item-value="visibleName"
                  outlined
                )
            ValidationProvider(
              rules="required"
              vid="keyPassword"
              :name="$t('Key protection password')"
              v-slot="{ errors }"
              mode="passive"
            )
              e-input-password(
                v-model="password"
                :label="$t('Key protection password')"
                type="password"
                :error-messages="errors"
                outlined
              )
          div(class="eds-iit-checkbox__actions")
            v-btn(
              class="main-button"
              :loading="loading"
              type="submit"
              :disabled="btnDisabled"
            ) {{ $t('Read key') }}
    div(v-else-if="showQrBlock")
      a(
        v-if="qrUrl"
        :href="qrUrl"
        target="_blank"
        class="eds-iit-checkbox__qr-code"
      )
        qrcode-vue(
          :value="qrUrl"
          :size="xsDevice ? 248 : 300"
        )
      div(
        class="eds-iit-checkbox__loader"
        v-else
      )
        e-progress-circular(
          size="xl"
          width="5"
          color="#161b25"
        )
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Base64js from 'base64-js'
import { EndUser, EndUserConstants, EndUserKeyMedia } from 'euscp'
import CAs from 'assets/data/iit/CAs.json'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EInputPassword from '~/components/elements/inputs/e-input-password'
import EdsIitCheckboxKeyInfo from '~/modules/eds-iit-checkbox/views/eds-iit-checkbox-key-info'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import EdsIitCheckbox from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'
import EdsIitCheckboxSignOptions from '~/modules/eds-iit-checkbox/views/eds-iit-checkbox-sign-options'
import edsIitCheckboxSteps from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxSteps'
import responsive from '~/mixins/pages/responsive'
import allowedKeyMediaTypes from '~/modules/eds-iit-checkbox/constants/allowedKeyMediaTypes'

export default {
  name: 'EdsIitCheckbox',
  components: {
    QrcodeVue,
    ESvgIcon,
    EInputPassword,
    EdsIitCheckboxKeyInfo,
    EProgressCircular,
    EdsIitCheckboxSignOptions
  },
  mixins: [edsIitCheckboxSteps, responsive],
  props: {
    type: {
      type: String,
      default: 'edsIitCheckboxForOrganization'
    },
    showCachedDataMessage: {
      type: Boolean,
      default: false
    },
    onlyFileSign: {
      type: Boolean,
      default: false
    },
    keepBackBtnVisible: {
      type: Boolean,
      default: false
    },
    readDiiaKey: {
      type: Boolean,
      default: false
    },
    onInterfaceActionError: {
      type: Function,
      default: () => {}
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    titles: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    endUser: null,
    fileData: null,
    password: null,
    ca: null,
    km: null,
    ksp: null,
    keyMedias: [],
    pkFormType: 1,
    pkFormTypeFile: 1,
    pkFormTypeKM: 2,
    pkFormTypeKSP: 3,
    textError: null,
    fileError: null,
    loading: false,
    btnDisabled: false,
    localKeyData: null,
    selectedOption: null,
    currentStep: 'options',
    backButtonDisabled: false,
    fileInputKey: 0
  }),
  computed: {
    model () {
      return EdsIitCheckbox
    },
    showQrBlock () {
      return this.currentStep === this.steps.smartIdRead ||
        this.currentStep === this.steps.smartIdSign ||
        this.currentStep === this.steps.diiaRead ||
        this.currentStep === this.steps.diiaSign
    },
    backBtnVisible () {
      if (this.keepBackBtnVisible) {
        return true
      }
      if (this.currentStep === this.steps.fileSign && this.onlyFileSign) {
        return false
      }
      return this.currentStep !== this.steps.options && this.currentStep !== this.steps.smartIdSign && this.currentStep !== this.steps.diiaSign
    },
    shownTitle () {
      let title = ''
      if (this.currentStep === this.steps.options) {
        title = 'Signature of the request to the DPS'
      } else if (this.currentStep === this.steps.keyInfo) {
        title = 'Check the read key information'
      } else if (this.currentStep === this.steps.smartIdRead) {
        title = 'Read the key via SmartID'
      } else if (this.currentStep === this.steps.smartIdSign) {
        title = this.modelInstance?.title || 'Signature via SmartID'
      } else if (this.currentStep === this.steps.diiaRead) {
        title = 'Read the key through Diia.Signature'
      } else if (this.currentStep === this.steps.diiaSign) {
        title = this.modelInstance?.title || 'Signature via Diia.Signature'
      } else {
        title = 'EDS file key'
      }
      return this._.get(this.titles, this.currentStep, null) || title
    },
    modelInstance () {
      return this.model.query().where('type', this.type).first()
    },
    qrUrl () {
      return this.modelInstance?.qrUrl || null
    },
    isPkFormTypeFile () {
      return this.pkFormType === this.pkFormTypeFile
    },
    isPkFormTypeKM () {
      return this.pkFormType === this.pkFormTypeKM
    },
    isPkFormTypeKSP () {
      return this.pkFormType === this.pkFormTypeKSP
    },
    localShowCachedDataMessage () {
      const signInterface = this.modelInstance?.getInterface()
      return Boolean(signInterface && !signInterface?.isSmartId && !signInterface?.isDiia && this.showCachedDataMessage)
    },
    acskProviders () {
      const result = [{
        text: this.$t('Determine automatically'),
        value: null
      }]
      this._.each(this.settings.CAs, (ca) => {
        const caCN = ca.issuerCNs[0]
        result.push({
          text: caCN,
          value: caCN
        })
      })
      return result
    },
    settings () {
      return {
        language: 'uk',
        encoding: 'utf-8',
        httpProxyServiceURL: this.$env.IIT_PROXY_HANDLER_URL,
        directAccess: true,
        CAs,
        CACertificates: `${this.$env.WEB_URL}/CACertificates.p7b`,
        allowedKeyMediaTypes,
        KSPs: [
          {
            name: 'Приватбанк - хмарний підпис "SmartID"',
            ksp: 6,
            address: 'https://acsk.privatbank.ua/cloud/api/back/',
            port: '',
            directAccess: true,
            clientIdPrefix: 'IIT_',
            confirmationURL: 'https://www.privat24.ua/rd/kep',
            mobileAppName: 'Приват24'
          }
        ]
      }
    },
    isIOS () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document) // iPad on iOS 13 detection
    },
    fileName () {
      return this._.get(this.fileData, 'file.name', null)
    },
    fileSelected () {
      return Boolean(this.fileError || this._.get(this.fileData, 'file'))
    }
  },
  watch: {
    pkFormType () {
      if (this.selectedOption === this.steps.fileSign) {
        this.clearData()
        this.initialize()
      }
    }
  },
  created () {
    this.onCreate()
  },
  beforeDestroy () {
    this.stopIntervalRequest()
    this.resetOperationKSP()
  },
  methods: {
    handleBackBtnClick () {
      if (this.currentStep === this.steps.keyInfo) {
        this.handleStepChange(this.selectedOption || this.steps.options)
        if (this.selectedOption === this.steps.smartIdRead) {
          this.handleReadKey()
        }
      } else {
        if (this.currentStep === this.steps.smartIdRead || this.currentStep === this.steps.smartIdSign) {
          this.resetOperationKSP()
        } else if (this.currentStep === this.steps.diiaRead || this.currentStep === this.steps.diiaSign) {
          this.stopIntervalRequest()
        }
        this.handleStepChange(this.steps.options)
      }
    },
    stopIntervalRequest () {
      if (this.modelInstance) {
        this.modelInstance.stopIntervalRequest()
      }
    },
    async resetOperationKSP () {
      if (this.endUser) {
        await this.endUser.ResetOperationKSP()
      }
      const signInterface = this.modelInstance?.getInterface()
      if (signInterface?.endUser) {
        await signInterface.endUser.ResetOperationKSP()
      }
    },
    async onCreate () {
      const signInterface = this.modelInstance?.getInterface()
      if (signInterface) {
        if (signInterface?.isSmartId) {
          this.pkFormType = this.pkFormTypeKSP
          this.selectedOption = this.steps.smartIdRead
        } else if (signInterface?.isDiia) {
          const nextStep = this.steps.diiaSign
          this.selectedOption = nextStep
          this.handleStepChange(nextStep)
        }
        await this.handleConfirm()
      } else if (this.onlyFileSign) {
        await this.handleOptionSelect(this.steps.fileSign)
      }
    },
    async handleOptionSelect (option) {
      this.selectedOption = option
      this.handleStepChange(option)
      if (option === this.steps.smartIdRead) {
        this.pkFormType = this.pkFormTypeKSP
        const initialized = await this.initialize()
        if (initialized) {
          await this.handleReadKey()
        }
      } else if (option === this.steps.diiaSign) {
        if (this.readDiiaKey) {
          this.selectedOption = this.steps.diiaRead
          this.handleStepChange(this.steps.diiaRead)
          await this.saveModelData({
            title: null,
            qrUrl: null
          })
        }
        const initialized = await this.initialize()
        if (initialized) {
          if (this.readDiiaKey) {
            await this.handleReadDiiaKey()
          } else {
            await this.handleConfirm()
          }
        }
      } else {
        this.pkFormType = this.pkFormTypeFile
        await this.initialize()
      }
    },
    handleStepChange (step) {
      this.currentStep = step
      this.$emit('stepChange', step)
      if (step === this.steps.options) {
        this.selectedOption = null
        this.clearData()
        if (this.modelInstance) {
          this.modelInstance.delete()
        }
      }
    },
    async resetSavedData () {
      if (this.modelInstance) {
        await this.modelInstance.delete()
      }
      await this.clearData()
      await this.initialize()
    },
    saveModelData (data = {}) {
      if (this.modelInstance) {
        this.modelInstance.saveModelData(data)
      }
    },
    clearData () {
      const form = this.$refs?.form
      if (form) {
        form.reset()
      }
      this.endUser = null
      this.fileData = null
      this.password = null
      this.ca = null
      this.km = null
      this.ksp = null
      this.fileError = null
      this.textError = null
      this.localKeyData = null
      this.keyMedias = []
      this.currentStep = this.selectedOption || this.steps.options
      this.backButtonDisabled = false
      this.saveModelData({
        qrUrl: null,
        title: null
      })
      this.fileInputKey += 1
    },
    handleFileSelect () {
      if (this.$refs?.fileInput) {
        this.$refs.fileInput.click()
      }
    },
    handleFileSelected (e) {
      this.fileError = null
      const file = this._.get(e, 'target.files[0]')
      if (file instanceof File) {
        const reader = new FileReader()
        reader.onloadend = (e) => {
          if (e.target.readyState !== FileReader.DONE) {
            return
          }
          this.$set(this.$data, 'fileData', {
            file,
            data: new Uint8Array(e.target.result)
          })
        }
        reader.readAsArrayBuffer(file)
      }
    },
    async checkLibraryModules () {
      // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
      const result = await this.endUser.GetLibraryInfo()
      if (!result.supported) {
        this.textError = this.$t('The web signature library is not supported by your browser or OS')
        return false
      }
      if (!result.loaded) {
        // Бібліотека встановлена, але потребує оновлення
        if (result.isNativeLibraryNeedUpdate) {
          this.textError = `${this.$t('The web signature library needs updating. Please install the update by')}
            <a href="${result.nativeLibraryInstallURL}" target="_blank">${this.$t('link ')}</a>`
          return false
        }
        // Якщо браузер підтримує web-розширення рекомендується
        // додатково до нативних модулів встановлювати web-розширення
        // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
        if (result.isWebExtensionSupported && !result.isWebExtensionInstalled) {
          this.textError = `${this.$t('The web signature library requires the web extension to be installed. Please install the web extension by')}
            <a href="${result.webExtensionInstallURL}" target="_blank">${this.$t('link ')}</a> ${this.$t('and refresh the page')}`
          return false
        }
        // Бібліотека (нативні модулі) не встановлені
        this.textError = `${this.$t('The web signature library needs to be installed. Please install the library by')}
            <a href="${result.nativeLibraryInstallURL}" target="_blank">${this.$t('link ')}</a> ${this.$t('and refresh the page')}`
        return false
      }
      return true
    },
    async initialize () {
      try {
        this.loading = true
        this.btnDisabled = false
        if (!this.modelInstance) {
          await this.model.insert({
            data: {
              type: this.type,
              helpers: {
                axios: this.$axios,
                organization: this.$Organization
              }
            }
          })
        }
        if (this.isPkFormTypeFile || this.isPkFormTypeKSP) {
          this.endUser = new EndUser()
        } else if (this.isPkFormTypeKM) {
          this.endUser = new EndUser(null, EndUserConstants.EndUserLibraryType.SW)
          const readyToUse = await this.checkLibraryModules()
          if (!readyToUse) {
            return
          }
        }
        const initialized = await this.endUser.IsInitialized()
        if (!this.endUser) {
          return
        }
        if (!initialized) {
          await this.endUser.Initialize(this.settings)
        }

        if (this.isPkFormTypeKM) {
          this.keyMedias = await this.endUser.GetKeyMedias()
        }

        if (this.isPkFormTypeKSP) {
          this.endUser.AddEventListener(EndUserConstants.EndUserEventType.ConfirmKSPOperation, (e) => {
            this.saveModelData({
              qrUrl: e?.url
            })
          })
        }
        return true
      } catch (e) {
        this.btnDisabled = true
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async handleReadDiiaKey () {
      try {
        if (this.modelInstance) {
          const signedData = await this.modelInstance.signByDiia('Checkbox', {
            endUser: this.endUser,
            title: this.shownTitle,
            keyCheck: false
          })
          if (signedData) {
            const keyData = await this.endUser.GetSigner(Base64js.toByteArray(signedData), 0)
            this.localKeyData = [keyData]
            this.handleStepChange(this.steps.keyInfo)
            await this.saveModelData({
              qrUrl: null
            })
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
        await this.resetSavedData()
      }
    },
    async handleReadKey () {
      try {
        this.loading = true
        this.fileError = null
        if (this.isPkFormTypeFile || this.isPkFormTypeKM) {
          const valid = await this.$refs?.form?.validate()
          const selectedFile = this.isPkFormTypeFile ? this.fileData : true
          if (!selectedFile) {
            this.fileError = 'The field "Choose EDS key" must have a value'
          }
          if (!valid || !selectedFile) {
            return
          }
        }
        if (!this.endUser) {
          return
        }
        if (this.isPkFormTypeFile) {
          const fileData = new Uint8Array(this._.get(this.fileData, 'data'))
          if (this.fileName.endsWith('.jks')) {
            const jksKeys = await this.endUser.GetJKSPrivateKeys(fileData)
            const pkIndex = 0
            const pkCertificates = []
            for (let i = 0; i < jksKeys[pkIndex].certificates.length; i++) {
              pkCertificates.push(jksKeys[pkIndex].certificates[i].data)
            }
            await this.endUser.ReadPrivateKeyBinary(jksKeys[pkIndex].privateKey, this.password, pkCertificates, this.ca)
          } else {
            await this.endUser.ReadPrivateKeyBinary(fileData, this.password, null, this.ca)
          }
        } else if (this.isPkFormTypeKM) {
          const selectedKeyMedia = this._.find(this.keyMedias, { visibleName: this.km })
          const keyMedia = new EndUserKeyMedia(selectedKeyMedia)
          keyMedia.password = this.password
          await this.endUser.ReadPrivateKey(keyMedia, null, this.ca)
        } else if (this.isPkFormTypeKSP) {
          const ksp = this._.get(this.settings, 'KSPs[0].name')
          await this.endUser.ReadPrivateKeyKSP('', ksp, true)
        }
        this.localKeyData = await this.endUser.GetOwnCertificates()
        this.handleStepChange(this.steps.keyInfo)
        this.saveModelData({
          qrUrl: null
        })
      } catch (e) {
        if (e?.code !== 12) {
          this.$handlers.error(e, this)
        }
        await this.resetSavedData()
      } finally {
        this.loading = false
      }
    },
    async handleConfirm () {
      try {
        this.loading = true
        this.backButtonDisabled = true
        const isSmartId = this.isPkFormTypeKSP && this.selectedOption === this.steps.smartIdRead
        const isDiia = this.selectedOption === this.steps.diiaRead || this.selectedOption === this.steps.diiaSign
        if (!this.modelInstance.getInterface()) {
          this.modelInstance.saveData({
            endUser: this.endUser,
            keyData: this.localKeyData,
            isSeal: !this._.get(this.localKeyData, '[0]infoEx.subjDRFOCode', ''),
            onInterfaceActionError: this.onInterfaceActionError,
            isSmartId,
            isDiia
          })
        }
        if (isSmartId) {
          this.handleStepChange(this.steps.smartIdSign)
        } else if (isDiia) {
          this.handleStepChange(this.steps.diiaSign)
        }
        const signInterface = this.modelInstance.getInterface()
        if (this._.isFunction(this.onConfirm)) {
          try {
            await this.onConfirm(signInterface)
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e)
          }
        }
        this.$emit('confirm', signInterface)
      } catch (e) {
        if (e?.code !== 12) {
          this.$handlers.error(e, this)
        }
        await this.resetSavedData()
      } finally {
        this.loading = false
        this.saveModelData({
          title: null,
          qrUrl: null
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.eds-iit-checkbox {
  position: relative;

  &__back-btn {
    transform: rotate(180deg);
    margin-right: 5px;

    &[disabled] {
      opacity: 0.35;
    }
  }

  &__title {
    font-weight: 700;
    color: $btn-secondary-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 32px;
    }
  }

  &__file-input-wrapper {
    padding: 20px;
    border: 2px dashed #F0F1F2;
    border-radius: 16px;
    margin-bottom: 32px;

    &--error {
      .eds-iit-checkbox {
        &__file-input-key-icon {
          background: $color-error-secondary;
        }

        &__file-input-btn {
          border-color: $color-error-secondary !important;
          color: $color-error-secondary !important;
        }
      }
    }

    &--success {
      .eds-iit-checkbox {
        &__file-input-key-icon {
          background: $focus-color-2;
        }

        &__file-input-btn {
          border-color: $focus-color-2 !important;
          color: $focus-color-2 !important;
        }
      }
    }
  }

  &__file-input-content {
    gap: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: map-get($breakpoints, 'md')) {
      flex-wrap: nowrap;
    }
  }

  &__file-input-key-icon {
    padding: 12px;
    border-radius: 12px;
    background: #F0F1F2;
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__file-input-text {
    flex-grow: 1;
    overflow: hidden;

    @media (min-width: map-get($breakpoints, 'sm')) {
      max-width: 224px;
    }

    h3 {
      font-weight: 600;
      font-size: 14px;
      color: #000;
      margin-bottom: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    p {
      font-weight: 300;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.75);
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__file-input-btn {
    width: 100%;
    max-width: 140px;
  }

  &__error {
    font-size: 12px;
    color: #ff1744;
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    .main-button {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        max-width: 180px;
      }
    }
  }

  &__radio-group {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 32px;

    &::v-deep {
      legend.v-label {
        font-size: 14px !important;
        padding-bottom: 16px;
        color: #161B25;
      }

      .v-radio:not(:last-child):not(:only-child) {
        margin-bottom: 13px;
      }
    }
  }

  &__radio-label {
    color: #161B25;
    font-size: 14px;
    font-weight: 300;

    p {
      margin: 0;
    }

    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__select {
    margin-bottom: 32px;
  }

  &__text-error {
    color: #161B25;
    font-size: 14px;
    margin-bottom: 27px;
  }

  &__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 36px;
    padding-bottom: 30px;

    p {
      text-align: center;
      margin: 0;
      font-size: 14px;
    }
  }

  &__qr-code {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}
</style>
