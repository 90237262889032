import { collectEnv } from './lib.utils.30a7278c.js'; // eslint-disable-line import/extensions, import/no-unresolved

const transformedKeys = JSON.parse('[{"name":"API_URL","key":"API_URL"},{"name":"API_URL_BROWSER","key":"API_URL_BROWSER"},{"name":"API_HOST","key":"API_HOST"},{"name":"API_PORT","key":"API_PORT"},{"name":"API_PREFIX","key":"API_PREFIX"},{"name":"API_URL","key":"API_URL"},{"name":"APM_ENABLE","key":"APM_ENABLE"},{"name":"APM_SERVER_URL","key":"APM_SERVER_URL"},{"name":"APM_SERVICE_NAME","key":"APM_SERVICE_NAME"},{"name":"WSS_PAY_LINK_BASE_URL","key":"WSS_PAY_LINK_BASE_URL"},{"name":"WSS_PROCESSING_BASE_URL","key":"WSS_PROCESSING_BASE_URL"},{"name":"GOOGLE_PAY_MERCHANT_ID","key":"GOOGLE_PAY_MERCHANT_ID"},{"name":"IPAY_GOOGLE_PAY_MERCHANT_ID","key":"IPAY_GOOGLE_PAY_MERCHANT_ID"},{"name":"IPAY_MERCHANT_NAME","key":"IPAY_MERCHANT_NAME"},{"name":"GOOGLE_PAY_ENVIRONMENT","key":"GOOGLE_PAY_ENVIRONMENT"},{"name":"IPAY_GOOGLE_PAY_GATEWAY","key":"IPAY_GOOGLE_PAY_GATEWAY"},{"name":"APPLE_PAY_MERCHANT_ID","key":"APPLE_PAY_MERCHANT_ID"},{"name":"TRANZZO_API_TOKEN","key":"TRANZZO_API_TOKEN"},{"name":"WEB_URL","key":"WEB_URL"},{"name":"IIT_PROXY_HANDLER_URL","key":"IIT_PROXY_HANDLER_URL"}]');

export default (ctx, inject) => {
  const nuxtEnv = ctx.env;
  let env = {};

  if (process.server) {
    // @NOTE: On the SSR server, we don't have access to HTML meta,
    //        and can't pass collected vars from the module,
    //        so we have to collect them again from the runtime `process.env`
    const { secretEnv, publicEnv } = collectEnv(process.env, transformedKeys);
    env = { ...nuxtEnv, ...secretEnv, ...publicEnv };

    // @NOTE: Work around an issue with SSR client, where it's HTML meta is outdated
    ctx.beforeNuxtRender((renderCtx) => {
      renderCtx.nuxtState.publicEnv = publicEnv;
    });
  }

  else if (ctx.nuxtState) {
    // @NOTE: On SSR client, load vars from nuxtState
    const { publicEnv } = ctx.nuxtState;
    env = { ...nuxtEnv, ...publicEnv };
  }

  else {
    // @NOTE: On SPA client, load vars from the HTML meta, saved by the plugin
    let publicEnv = {};
    const meta = document.querySelector('meta[name=nuxt-public-env]');
    if (meta) {
      try {
        publicEnv = JSON.parse(meta.content);
      }
      catch (error) {
        console.error('Nuxt public env meta tag is malformed');
      }
    }
    env = { ...nuxtEnv, ...publicEnv };
  }

  ctx.$env = env;
  inject('env', env);
};
