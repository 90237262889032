<template lang="pug">
  div(class="monobank-acquiring-no-terminals")
    div(class="monobank-acquiring-no-terminals__image-wrapper")
      v-img(:src="require('~/assets/images/racoons/racoon-dont-know.svg')" :max-width="xsDevice ? 150 : 200" class="mx-auto")
    div(class="monobank-acquiring-no-terminals__content")
      h3(class="monobank-acquiring-no-terminals__title") {{ $t('Unfortunately, you don`t have any terminals available yet') }}
      p(class="monobank-acquiring-no-terminals__text") {{ $t('Go to Monobank`s office and register your first terminal') }}
      p(class="monobank-acquiring-no-terminals__text") {{ $t('If you made a mistake with the FOP account when scanning the QR, unlink your current monobank account') }}
    div(class="monobank-acquiring-no-terminals__actions")
      v-btn(
        class="secondary-button"
        :class="{ 'w-100': xsDevice }"
        color="black"
        @click="revokeToken"
        :loading="loading"
        outlined
      ) {{ $t('Revoke') }}
      v-btn(
        class="main-button"
        :class="{ 'w-100': xsDevice }"
        @click="closeModal"
      ) {{ $t('Understand') }}
</template>

<script>
import Acquiring from '~/modules/acquiring/models/Acquiring'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockMonobankAcquiringNoTerminals',
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async revokeToken () {
      try {
        this.loading = true
        await Acquiring.api().revokeToken()
        this.$notification.success('Token has been revoked successfully')
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.monobank-acquiring-no-terminals {
  padding-bottom: 5px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    padding-bottom: 16px;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
  }

  &__title {
    color: #161B25;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;

    padding: 4px 0 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      line-height: 32px;
      padding: 16px 0 12px;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
    color: #161B25BF;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 16px;
    padding-top: 22px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      flex-direction: row;
    }
  }
}
</style>
