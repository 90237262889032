<template lang="pug">
  div(class="dialog")
    v-progress-linear(
      :value="value"
      height="20"
      stream
      rounded
    )
      template(v-slot:default="{ value }")
        strong {{ Math.ceil(current) }} / {{ Math.ceil(total) }}
</template>

<script>
export default {
  name: 'EProcessProgress',
  props: {
    total: {
      type: [String, Number],
      default: null
    },
    current: {
      type: [String, Number],
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    }
  }
}
</script>

<style scoped lang="scss">

</style>
