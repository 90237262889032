<template lang="pug">
  div(class="pt-8 pb-10")
    ValidationObserver(ref="form" slim)
      t-orm-fields(v-bind.sync="dataForm" :items="fields")
    div(class="d-flex justify-center")
      v-btn(
        class="main-button"
        :loading="loading"
        @click="submit"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Billings from '~/models/billing/Billings'

export default {
  name: 'MFormBillingSetNewPrice',
  components: {
    TOrmFields
  },
  props: {
    billingId: {
      type: String,
      default: null
    },
    currentPrice: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dataForm: {
        startAt: null,
        cashRegisterPrice: null,
        priceForNewCashRegister: null,
        overflowLimit: null
      },
      loading: false
    }
  },
  computed: {
    fields () {
      return [
        {
          label: 'Cash register price',
          labelClassName: 'mb-3',
          model: 'cashRegisterPrice',
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          moneyFlag: true,
          component: 'v-text-field',
          provider: {
            name: 'Price, ₴ per unit.',
            vid: 'cashRegisterPrice',
            rules: 'required|money'
          },
          attrs: {
            label: 'Price, ₴ per unit.',
            outlined: true,
            type: 'number',
            min: 0,
            step: 0.01
          },
          fieldVal: () => this.currentPrice.cashRegisterPrice / 100,
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          label: 'New cash register price',
          labelClassName: 'mb-3',
          model: 'priceForNewCashRegister',
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          moneyFlag: true,
          component: 'v-text-field',
          provider: {
            name: 'Price, ₴ per unit.',
            vid: 'priceForNewCashRegister',
            rules: 'required|money'
          },
          attrs: {
            label: 'Price, ₴ per unit.',
            outlined: true,
            type: 'number',
            min: 0,
            step: 0.01
          },
          fieldVal: () => this.currentPrice.priceForNewCashRegister / 100,
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          model: 'startAt',
          component: 'e-input-datetime',
          provider: {
            vid: 'startAt',
            name: 'Select start date',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Select start date'
          },
          fieldVal: () => this.currentPrice.startAt
        },
        {
          model: 'overflowLimit',
          component: 'v-text-field',
          provider: {
            vid: 'overflowLimit',
            name: 'Receipts limit',
            rules: 'required'
          },
          attrs: {
            label: 'Receipts limit',
            outlined: true,
            type: 'number'
          },
          fieldVal: () => this.currentPrice.overflowLimit
        }
      ]
    }
  },
  methods: {
    async submit () {
      const valid = this.$refs.form.validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true
        const payload = {
          ...this.dataForm,
          overflowLimit: +this.dataForm.overflowLimit,
          billing: `/billings/${this.billingId}`
        }
        await Billings.api().updatePrice(payload)
        this.$emit('onSuccess')
      } catch (e) {
        this.$emit('onError')
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
