<template lang="pug">
  div(class="pt-6")
    ValidationObserver(ref="form" slim)
      t-orm-fields(
        v-bind.sync="form"
        :items="fields"
      )
    e-input-file-content(
      v-if="sendFile"
      accept=".xls, .xlsx"
      rules="required|ext:xls,xlsx"
      v-model="file"
      expect="dataUrl"
      :label="$t('Select file')"
      prependIcon=""
      appendIcon='$file'
      ref="fileInput"
      mode="lazy"
      outlined
    )
    v-btn(
      class="main-button d-block mx-auto"
      :class="{ 'mt-8': !this.sendFile, 'mt-3': this.sendFile }"
      @click="save"
      :loading="loading"
    ) {{ btnText }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import MallReport from '~/modules/reports/models/MallReport'

export default {
  name: 'BlockMallReportFtpSettings',
  components: {
    TOrmFields,
    EProgressCircular,
    EInputFileContent
  },
  props: {
    sendFile: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    form: {
      host: null,
      port: null,
      dir: null,
      login: null,
      password: null,
      active: false
    },
    file: null
  }),
  computed: {
    model () {
      return MallReport
    },
    btnText () {
      if (this.sendFile) {
        return this.$t('Send')
      }
      return this.$t('Save')
    },
    fields () {
      return [
        {
          model: 'host',
          component: 'v-text-field',
          provider: {
            vid: 'host',
            name: 'Host',
            rules: 'required'
          },
          attrs: {
            label: 'Host',
            outlined: true
          },
          cols: {
            cols: 12,
            sm: 8
          }
        },
        {
          model: 'port',
          component: 'v-text-field',
          provider: {
            vid: 'port',
            name: 'Port',
            rules: 'required|integer'
          },
          attrs: {
            label: 'Port',
            type: 'number',
            outlined: true
          },
          cols: {
            cols: 12,
            sm: 4
          },
          cast: val => val ? parseInt(val) : null
        },
        {
          model: 'dir',
          component: 'v-text-field',
          provider: {
            vid: 'dir',
            name: 'Directory',
            rules: ''
          },
          attrs: {
            label: 'Directory',
            outlined: true
          }
        },
        {
          model: 'login',
          component: 'v-text-field',
          provider: {
            vid: 'login',
            name: 'Login ',
            rules: 'required'
          },
          attrs: {
            label: 'Login ',
            outlined: true
          }
        },
        {
          model: 'password',
          component: 'e-input-password',
          provider: {
            vid: 'password',
            name: 'Password',
            rules: 'required'
          },
          attrs: {
            label: 'Password',
            outlined: true
          }
        },
        {
          model: 'active',
          component: 'e-input-switch-tooltip',
          provider: {
            vid: 'active',
            name: 'Send automatically'
          },
          attrs: {
            label: 'Send automatically',
            tooltip: 'The report will be sent to subscribers automatically',
            visible: !this.sendFile
          }
        }
      ]
    }
  },
  created () {
    this.setSettings()
  },
  methods: {
    setSettings () {
      const settings = this._.get(this.item, 'mallReportFtpSetting')
      if (settings) {
        this.$set(this.$data, 'form', JSON.parse(JSON.stringify(settings)))
      }
    },
    async save () {
      try {
        this.loading = true
        const reportId = this._.get(this.item, 'id')
        let msg = ''
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }

        if (this.sendFile) {
          const { valid } = await this.$refs.fileInput.validate()
          if (!valid) {
            return
          }
          const base64File = this.file.split(',')[1]
          const formCopy = JSON.parse(JSON.stringify(this.form))
          delete formCopy.active
          await this.model.api().sendReport(reportId, {
            channel: 'ftp',
            settingsFtp: formCopy,
            file: base64File
          })
          msg = 'File sent successfully'
        } else {
          await this.model.api().updateFtpSettings(reportId, this.form)
          msg = 'FTP report settings saved successfully'
        }

        this.$notification.success(this.$t(msg))
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
