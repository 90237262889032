<template lang="pug">
  div(:class="{'e-files-status-wrapper-scroll': files.length > 2 }")
    v-card(
      v-for="(file, index) in files"
      :key="index"
      class="e-file-status"
      elevation="0"
    )
      div(class="e-file-status__icon" :class="{'error': isUploadError }")
        e-svg-icon(name="file-text-white")
      div(class="e-file-status__content")
        div(class="e-file-status__file-name") {{ file.name }}
        div(class="e-file-status__status" :class="{'error': isUploadError }") {{ fileStatus }}
      div(class="e-file-status__size") {{ formatBytes(file.size) }}
      v-btn(
        @click.stop="$emit('delete')"
        class="e-file-status__delete"
        icon
        small
      )
        e-svg-icon(name="trash" size="md")
</template>

<script>
import prettyBytes from 'pretty-bytes'
import ESvgIcon from '@/components/elements/icons/e-svg-icon.vue'

export default {
  name: 'MBlockUploadedFileStatus',
  components: {
    ESvgIcon
  },
  props: {
    files: {
      type: Array,
      default: null
    },
    // TODO: related to errors on file uploading, for now unused, because vee-validate validate input
    isUploadError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fileStatus () {
      return this.isUploadError ? this.$t('Error') : this.$t('Downloaded')
    }
  },
  methods: {
    formatBytes (bytes) {
      return prettyBytes(bytes)
    }
  }
}
</script>

<style lang="scss" scoped>
.e-files-status-wrapper-scroll {
  max-height: 150px;
  overflow-y: scroll;
}

.e-file-status {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  border: 1px solid #F0F1F2;
  border-radius: 12px !important;
  padding: 9px 8px;
  margin-top: 16px;
  gap: 12px;

  @media (max-width: map-get($breakpoints, 'sm')) {
    border: 0;
    border-radius: 0 !important;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    background: $focus-color-2;
    border-radius: 50% !important;

    &.error {
      background: $color-error-secondary;
    }
  }

  &__content {
    overflow-x: hidden;
  }

  &__file-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 12px;
    }
  }

  &__status {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $focus-color-2;

    &.error {
      color: $color-error-secondary;
    }
  }

  &__size {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    margin-left: auto;

    @media (max-width: map-get($breakpoints, 'sm')) {
      display: none;
    }
  }

  &__delete {
    background: none !important;

    @media (max-width: map-get($breakpoints, 'sm')) {
      margin-left: auto;
    }
  }
}
</style>
