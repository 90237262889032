import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class Oschadbank extends PersistAble(OrmModel) {
  static entity = 'oschadbank'
  static paginated = false
  static defaultSortOrder = true

  static STATUSES = {
    new: 'new',
    sendToBank: 'send',
    decline: 'decline',
    success: 'success',
    error: 'error'
  }

  static fields () {
    return {
      id: this.uid(),
      clientType: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.downloadQuestionnaire = function () {
        return this.get(Model.$routes.oschadbank.downloadQuestionnaire(), {
          save: false,
          responseType: 'blob'
        })
      }
      configActions.createOrder = function (formData) {
        return this.post(Model.$routes.oschadbank.createOrder(), formData, {
          save: false,
          responseType: 'json',
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      }
      return configActions
    }
  }
}

export default Oschadbank
