<template lang="pug">
  div(class="dialog")
    div(
      v-if="receiptLoading"
      class="dialog__loader"
    )
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="5"
      )
    template(v-else)
      div(class="dialog__content")
        div(
          class="dialog__preview"
          ref="preview-wrap"
        )
        iframe(
          ref="receiptDoc"
          class="d-none"
        )
        div(class="dialog__frame")
          div(
            v-if="!preview"
            class="dialog__text"
          )  {{ $t('Requested preview not exist. Please contact with manager.') }}
          div(
            v-dompurify-html="preview"
            v-else
          )
      div(class="dialog__actions")
        div(class="pagination-wrapper")
          v-pagination(
            :length="receiptIds.length"
            :value="currentPage"
            @previous="onPrev"
            @next="onNext"
          )
          span(v-if="currentPage > 1" class="pagination-prev" @click="onPrev") {{ $t('Previous') }}
          span(class="pagination-counter") {{ currentPage }} - {{ totalPages }}
          span(v-if="currentPage < totalPages" class="pagination-next" @click="onNext") {{ $t('Next one') }}
        v-col(cols="12" class="receipt__buttons")
          e-link(@click="copyToClipboard")
            e-svg-icon(class="mr-2") receipt-link
            | {{ $t('Copy link') }}
          e-link(@click="printImg" :loading="loading")
            e-svg-icon(class="mr-2") printer-3
            | {{$t('Print')}}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import ELink from '~/components/elements/links/e-link'
import clipboard from '~/mixins/global/_clipboard'
import converters from '~/mixins/methods/converters'
import processingReceipt from '~/modules/receipt/mixins/actions/processingReceipt'
import Receipts from '~/modules/receipt/models/Receipts'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import processingShifts from '~/modules/receipt/mixins/actions/processingShifts'

export default {
  name: 'BlockReceiptsChainView',
  components: {
    EProgressCircular,
    ESvgIcon,
    ELink
  },
  mixins: [
    clipboard,
    converters,
    processingReceipt,
    processingShifts
  ],
  props: {
    receiptIds: {
      type: Array,
      default: null
    },
    action: {
      type: String,
      default: 'download',
      validate: value => ['print', 'download', 'close'].includes(value)
    },
    fileName: {
      type: String,
      default: 'file'
    },
    token: {
      type: String,
      default: null
    },
    isReturn: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    receiptLoading: false,
    preview: null,
    currentPage: 1
  }),
  computed: {
    totalPages () {
      return this.receiptIds?.length
    },
    currentReceiptId () {
      return this.receiptIds[this.currentPage - 1]
    }
  },
  created () {
    if (this.isReturn) {
      this.checkShiftForCashier(this.authenticatedCashier)
    }
    this.createReceiptPreview()
  },
  methods: {
    async createReceiptPreview () {
      try {
        this.receiptLoading = true
        this.preview = await this.createHtmlReceiptPreview(this.currentReceiptId)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.receiptLoading = false
      }
    },
    onNext () {
      if (this.currentPage >= this.receiptIds.length) {
        return
      }
      this.currentPage++
      this.createReceiptPreview()
    },
    onPrev () {
      if (this.currentPage <= 1) {
        return
      }
      this.currentPage--
      this.createReceiptPreview()
    },
    close () {
      const closeModal = this._.get(this.$attrs, 'closeModal')
      if (this._.isFunction(closeModal)) {
        closeModal()
      }
    },
    async printImg () {
      try {
        this.loading = true
        const res = this._.get(await Receipts.api().processingReadPng(this.currentReceiptId), 'response.data', '')
        const receiptUrl = await this.blobToBase64(res)
        const cw = this.$refs.receiptDoc.contentWindow
        const paperWidth = this._.get(this.$Organization, 'receiptSetting.paperWidth') || 58
        const imgWidth = (paperWidth * 3.78).toFixed(2) + 'px'
        cw.document.open()
        cw.document.write(`<img src="${receiptUrl}" alt="Receipt" style="width: ${imgWidth};">`)
        cw.document.close()
        setTimeout(() => {
          cw.print()
        })
      } catch (e) {
        let err = e
        if (this._.get(e, 'response.status') === 404) {
          err = this.$t('No check view was found, please try again later or contact technical support')
        }
        this.$handlers.error(err, this)
      } finally {
        this.loading = false
      }
    },
    copyToClipboard () {
      try {
        this.copy('https://check.checkbox.ua/' + this.currentReceiptId)
        this.$notification.success(this.$t('Copied to clipboard'))
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 100%;
  }

  &__content {
    overflow-y: auto;
    flex-grow: 1;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  &__frame {
    min-height: 200px;

    &--html {
      max-width: 290px;

      @media (min-width: map-get($breakpoints, 'sm')) {
        min-width: 370px;
      }
    }
  }

  &__text {
    text-align: center;
    margin-top: 50px;
  }

  .e-link {
    font-size: 12px !important;
  }

  .v-card {
    padding-top: 30px;

    .v-card {
      &__actions {
        padding: 0;
      }
    }
  }
}
.pagination-wrapper {
  position: relative;
  text-align: center;
}
.pagination-prev {
  color: rgba(22, 27, 37, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.pagination-next {
  color: rgba(22, 27, 37, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.pagination-counter {
  color: rgba(22, 27, 37, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 5;
}
.v-pagination::v-deep {
  justify-content: space-between !important;

  .v-pagination__item {
    display: none;
  }
  .v-pagination__navigation {
    background: none;
    box-shadow: none;
  }

  .v-pagination__more {
    display: none!important;
  }
}
</style>
