<template lang="pug">
  div(class="tap-to-phone-acquiring-order-info")
    div(class="tap-to-phone-acquiring-order-info__content")
      h4(class="tap-to-phone-acquiring-order-info__title") {{ $t('The application for the provision of acquiring services has been sent!') }}
      p(class="tap-to-phone-acquiring-order-info__text") {{ $t('The bank will contact you within 2 days. Visit a bank branch to sign all the necessary documents.') }}
      p(class="tap-to-phone-acquiring-order-info__text") {{ $t('After that, you will be able to configure the connection on the web portal in the "Acquiring" section and use the service!') }}
      v-img(v-if="isResponsive" :src="require('~/assets/images/paper-signs.png')" width="100%")
    div(class="tap-to-phone-acquiring-order-info__actions")
      v-btn(
        class="main-button tap-to-phone-acquiring-order-info__action"
        :class="{ 'w-100': isResponsive }"
        @click="closeModal"
      ) {{ $t('Continue') }}
</template>

<script>

export default {
  name: 'BlockOschadbankAcquiringOrderInfo',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  }
}
</script>

<style scoped lang="scss">
.tap-to-phone-acquiring-order-info {
  &__content {
    padding: 20px;
    border-radius: 12px;
    background: #F7F7F7;
    margin-bottom: 24px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 24px;
      margin-bottom: 32px;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #161B25;
    margin-bottom: 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__text {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 12px;
    }
  }
  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
