<template lang="pug">
  div
    block-tax-report-addition-third-five(
      v-if="type === model.REPORT_TYPES.thirdFive"
      :item="item"
      :close-modal="closeModal"
      :on-submit="onSubmit"
      :type="type"
    )
    block-tax-report-addition-third-two(
      v-else-if="type === model.REPORT_TYPES.thirdTwo"
      :item="item"
      :close-modal="closeModal"
      :on-submit="onSubmit"
      :type="type"
    )
    block-tax-report-addition-second(
      v-else-if="type === model.REPORT_TYPES.second"
      :item="item"
      :close-modal="closeModal"
      :on-submit="onSubmit"
      :type="type"
    )
</template>

<script>
import TaxReports from '~/modules/reports/models/TaxReports'
import BlockTaxReportAdditionThirdFive from '~/modules/reports/views/components/tax-reports-additions/block-tax-report-addition-third-five'
import BlockTaxReportAdditionThirdTwo from '~/modules/reports/views/components/tax-reports-additions/block-tax-report-addition-third-two'
import BlockTaxReportAdditionSecond from '~/modules/reports/views/components/tax-reports-additions/block-tax-report-addition-second'

export default {
  name: 'BlockTaxReportAdditionForm',
  components: {
    BlockTaxReportAdditionThirdFive,
    BlockTaxReportAdditionThirdTwo,
    BlockTaxReportAdditionSecond
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    onSubmit: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    model () {
      return TaxReports
    }
  }
}
</script>

<style scoped lang="scss">

</style>
