import Search from '~/models/abstracts/Search'

export class Tags extends Search {
  static entity = 'tagssearch'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      code: this.attr(null),
      manager: this.attr(null)
    }
  }

  toString () {
    return this.name
  }
}

export default Tags
