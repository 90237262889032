<template lang="pug">
  tr(class="v-data-table__mobile-row__tr" :key="scopedItem.item.id || scopedItem.item.name")
    td(
      class="v-data-table__mobile-row__td"
      :class="{ 'v-data-table__mobile-row__td--odd': isOddItem }"
      @click="expand"
    )
      div(class="d-flex align-center")
        div(
          v-if="_.get(config, 'checkbox')"
          :class="tdContClass"
        )
          v-checkbox(
            :value="isSelected"
            @change="select"
            @click="onCheckboxClick"
            color="#04AED4"
            class="mt-0 pt-0"
            :ripple="false"
            hide-details
          )
        div(v-if="model.mobileFieldIcons" class="pr-2")
          e-svg-icon(:name="model.mobileFieldIcons[scopedItem.item.type]")
        div(:class="statusClass")
          t-orm-wrapper(
            v-if="modelStatusComponent"
            @click.native="itemActionFired(prependFieldIndex, scopedItem.item)"
            :item="scopedItem.item"
            :field="modelStatusComponent"
            :request-params="requestParams"
          )
        div(
          :class="{ 'pb-2 pl-2': modelStatusComponent }"
        )
          div(v-if="itemSubtitle" class="v-data-table__mobile-row__subtitle") {{ handleValue(itemSubtitle) }}
          div(class="v-data-table__mobile-row__title" :class="{ 'v-data-table__mobile-row__title--active': isExpanded }") {{ handleValue(itemName) }}
          div(v-if="itemSuptitle" class="v-data-table__mobile-row__subtitle") {{ handleValue(itemSuptitle) }}
      div(class="d-flex align-center")
        div(v-if="additionalText" class="v-data-table__mobile-row__additional-text mr-2") {{ handleValue(additionalText) }}
        v-btn(
          class="v-data-table__mobile-row__expand-btn"
          :class="{ 'v-data-table__mobile-row__expand-btn--active': isExpanded }"
          @click.capture="expand"
          small
          icon
        )
          e-svg-icon(size="xsm" name="arrow-down")
</template>

<script>
import {
  Ripple
} from 'vuetify/lib'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import TOrmWrapper from '~/components/templates/orm/t-orm-wrapper'
import TOrmMenuActions from '~/components/templates/orm/t-orm-menu-actions'

export default {
  directives: {
    Ripple
  },
  components: {
    TOrmWrapper,
    ESvgIcon,
    TOrmMenuActions
  },
  props: {
    scopedItem: {
      type: Object,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    expanded: {
      type: Array,
      default: () => []
    },
    itemSubtitle: {
      type: String,
      default: null
    },
    itemSuptitle: {
      type: String,
      default: null
    },
    itemName: {
      type: String,
      default: 'name'
    },
    additionalText: {
      type: String,
      default: null
    },
    itemIndex: {
      type: Number,
      default: null
    },
    requestParams: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      expandItem: false
    }
  },
  computed: {
    tdContClass () {
      return [
        this.disabledState ? 'disabled' : ''
      ]
    },
    isSelected () {
      const key = this._.get(this.config, 'checkbox.selectedKey') || 'id'
      const filtered = this._.filter(this.selected, selected => this._.get(selected, key) === this._.get(this.scopedItem.item, key))
      return filtered && Boolean(filtered.length)
    },
    isExpanded () {
      const filtered = this._.filter(this.expanded, expanded => expanded.index === this._.get(this.scopedItem, 'index', null))
      return filtered && Boolean(filtered.length)
    },
    isOddItem () {
      const copy = this.itemIndex
      return copy % 2 === 0
    },
    modelStatusComponent () {
      const statusObj = this._.get(this.model.ormColsComponents, 'status', null)
      return statusObj ? { ...statusObj, col: 'status' } : null
    },
    prependFieldIndex () {
      return this.isPrependItem ? this.model.ormCols(this.config.short, this.scopedItem.headers).findIndex(item => item.col === this.itemName || 'name') : null
    },
    intersectedActions () {
      return this._.get(this.config, 'intersectedActions', [])
    },
    disabledState () {
      return this._.isFunction(this.config.disabled) ? this.config.disabled(this.scopedItem) : false
    },
    statusClass () {
      return [
        this.disabledState ? 'disabled' : ''
      ]
    }
  },
  watch: {
    expandItem (val) {
      this.$emit('expand', this.scopedItem, val)
    }
  },
  methods: {
    onCheckboxClick (e) {
      e.stopPropagation()
    },
    select (val) {
      this.$emit('select', this._.get(this.scopedItem, 'item'), val)
    },
    expand () {
      this.expandItem = !this.expandItem
    },
    itemActionFired (index, item) {
      const header = this.scopedItem.headers[index]
      if (!this._.isEmpty(header.action)) {
        const actionItem = item[header.action.key]
        const action = this.intersectedActions.find(item => item.name === header.action.name)
        action.call(actionItem, true)
      }
    },
    handleValue (value) {
      return (typeof this.scopedItem.item[value] === 'number')
        ? this.scopedItem.item[value]
        : this.$t(this.scopedItem.item[value])
    }
  }
}
</script>

<style scoped lang="scss">

</style>
