import { find, get } from 'lodash'
import authUser from '~/modules/auth/authUser'

export default function ({ store, redirect, error }) {
  const tags = get(authUser.get(), 'organization.tags')
  const hasKopeechkaTag = find(tags, i => i.id === 'ea4fe1fb-590b-40cd-b458-5f7c48ae6da5')
  if (hasKopeechkaTag) {
    return redirect('/dashboard')
  }
}
