<template lang="pug">
  div()
    div(style="border: 1px solid black;border-radius: 14px")
      v-btn(
        style="width: 50%"
        :dark="!isBtnTransparent"
        @click="btnClickLeft"
        :class="{ transparent: isBtnTransparent }"
        ) {{ $t(btnLeft) }}
      v-tooltip(top)
        template(#activator="{on, attrs}")
          v-btn(
            v-on="on"
            v-bind="attrs"
            style="width: 50%"
            @click="btnClickRight"
            :dark="isBtnTransparent"
            :class="{ transparent: !isBtnTransparent }"
            :disabled="isService"
          ) {{ $t(btnRight) }}
        span {{ $t(tooltip) }}
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    btnLeft: {
      type: String,
      default: 'Piece by piece'
    },
    btnRight: {
      type: String,
      default: 'Measurable '
    },
    btnLeftValue: {
      type: String,
      default: null
    },
    btnRightValue: {
      type: String,
      default: null
    },
    value: {
      type: null,
      default: null
    },
    isService: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isBtnTransparent: false
    }
  },
  watch: {
    isService (val) {
      if (val) {
        this.isBtnTransparent = false
        this.$emit('input', false)
      }
    }
  },
  created () {
    if (this.value) {
      this.$emit('input', this.value)
    }
    this.isBtnTransparent = this.value
  },
  methods: {
    btnClickLeft () {
      this.isBtnTransparent = false
      if (this.btnLeftValue) {
        this.$emit('input', this.btnLeftValue)
      } else {
        this.$emit('input', false)
      }
    },
    btnClickRight () {
      this.isBtnTransparent = true
      if (this.btnRightValue) {
        this.$emit('input', this.btnRightValue)
      } else {
        this.$emit('input', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transparent {
  background: transparent;
}
::v-deep {
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
</style>
