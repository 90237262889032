import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import informationDialog from '~/mixins/dialogs/informationDialog'
import Dialog from '@/models/system/Dialog'
import AppNotifications from '@/services/Notifications/AppNotifications'

export class Taxes extends ChainInheritance(Directory, [PersistAble]) {
  static entity = 'taxes'

  static fields () {
    return {
      id: this.attr(null),

      symbol: this.attr(null),
      label: this.attr(null),
      rate: this.attr(null),
      extraRate: this.attr(null),
      isDefault: this.attr(null),
      noVat: this.attr(null),
      extendedTaxCode: this.attr(null),
      code: this.attr(null)
    }
  }

  static ormTrans = {
    single: 'Tax ',
    multy: 'Taxes'
  }

  static ormHeaders = [
    { text: 'Letter', value: 'symbol', align: 'left', sortable: false, filterable: false },
    { text: 'Tax rate', value: 'rate', align: 'left', sortable: true, filterable: false },
    { text: 'Tax name', value: 'label' },
    { text: 'Extra rate', value: 'extraRate', align: 'left', filterable: true, sortable: true },
    { text: 'Tax rate code', value: 'code', align: 'left', filterable: true, sortable: true },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'label'

  static getSortParams () {
    return super.getSortParams({
      'sort-by': ['code'],
      'sort-desc': [true]
    })
  }

  static ormActions = [
    {
      name: 'edit'
    },
    {
      name: 'delete',
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        await confirmationDialog.open({
          title: ctx => ctx.$t('Confirm removal of tax rate "{symbol}"', { symbol: item.symbol }),
          onConfirm: async () => {
            await Taxes.api().del(item)
            AppNotifications.success('The tax rate has been successfully deleted')
          }
        })
      }
    }
  ]

  static ormDialogs = {
    default: 'tax-rates-form-dialog',
    edit: 'tax-rates-form-dialog'
  }

  static ormDialogsConfig = {
    default: {
      config: {
        context: 'create',
        hooks: {
          afterSave: async (data) => {
            if (data.instance.extraRate > 0) {
              await informationDialog.computed.informationDialog().open({
                text: 'WARNING! When using excise tax, it is absolutely necessary to issue a license for the sale of alcohol or tobacco.',
                width: '600px'
              })
            }
          }
        }
      }
    },
    edit: {
      config: {
        context: 'update'
      }
    }
  }
}

export default Taxes
