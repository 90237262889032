import Search from '~/models/abstracts/Search'

export class KatottgSearch extends Search {
  static entity = 'katottgsearch'
  static defaultSortParam = 'name'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null)
    }
  }

  toString () {
    return this.name
  }
}

export default KatottgSearch
