<template lang="pug">
  div(class="dialog")
    div(class="dialog__title") {{ $t(item.name) }}
    div(
      v-if="loading"
      class="dialog__loader"
    )
      e-progress-circular(
        width="4"
        size="xl"
        color="#161b25"
      )
    div(
      class="pb-6"
      v-else
    )
      v-tabs(
        v-model="tab"
        class="mb-5"
      )
        v-tab {{ $t('good') }}
        v-tab {{ $t('Leftovers') }}
      v-tabs-items(
        v-model="tab"
        :show-arrows="false"
      )
        v-tab-item
          v-simple-table
            tbody
              tr(
                v-for="(item, index) in productTableData"
                :key="index"
                class="dialog__table-row"
                :class="{ 'dialog__table-row--even': index % 2 === 1 }"
              )
                td(class="dialog__table-td dialog__table-td--left") {{ $t(item.label) }}
                td(class="dialog__table-td dialog__table-td--right") {{ item.translate ? $t(item.value) : item.value }}
        v-tab-item
          m-orm-table(
            :model="suppliesModel"
            :default-filter="{ good_id: _.get(item, 'id') }"
            :custom-request="suppliesTableRequest"
          )
</template>

<script>
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import Taxes from '~/modules/tax-rates/models/Taxes'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import Supplies from '~/modules/goods/models/Supplies'
import AccountingGoodsSettings from '~/modules/goods/models/AccountingGoodsSettings'
import OutletsSearch from '~/models/directories/search/OutletsSearch'

export default {
  name: 'BlockProductView',
  components: {
    EProgressCircular
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    item: {
      type: null,
      default: null
    }
  },
  data: () => ({
    loading: false,
    tab: 0,
    product: null
  }),
  computed: {
    model () {
      return AccountingGoods
    },
    useBranches () {
      return this._.get(AccountingGoodsSettings.query().first(), 'use_branches')
    },
    suppliesModel () {
      return Supplies
    },
    taxesValue () {
      const itemTaxes = this._.get(this.product, 'taxes', [])
      if (itemTaxes?.length) {
        const taxes = Taxes.query().whereId(itemTaxes).get()
        return taxes.map(x => x.label).join(', ')
      } else {
        return ''
      }
    },
    countValue () {
      if (this._.get(this.product, 'type') === AccountingGoods.TYPES_MAP.service) {
        return '-'
      } else if (this._.get(this.product, 'is_weight')) {
        return ((this._.get(this.product, 'count') || 0) / 1000).toFixed(3)
      } else {
        return ((this._.get(this.product, 'count') || 0) / 1000).toFixed(0)
      }
    },
    productTableData () {
      const price = (this._.get(this.product, 'price', 0) / 100).toFixed(2).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
      return [
        {
          value: this._.get(this.product, 'type'),
          label: 'Type',
          translate: true
        },
        {
          value: this._.get(this.product, 'code'),
          label: 'Code'
        },
        {
          value: this._.get(this.product, 'name'),
          label: 'Name'
        },
        {
          value: this._.get(this.product, 'related_barcodes') || this._.get(this.product, 'barcode'),
          label: 'Barcodes'
        },
        {
          value: price,
          label: 'Price, ₴'
        },
        {
          value: this.taxesValue,
          label: 'Group taxes'
        },
        {
          value: this._.get(this.product, 'uktzed'),
          label: 'Uktzed'
        },
        {
          value: this.countValue,
          label: 'Supply '
        },
        {
          value: this._.get(this.product, 'is_weight') ? 'Measurable' : 'One by one',
          label: 'Sale type',
          translate: true
        },
        {
          model: 'group',
          label: 'Group',
          value: this._.get(this.product, 'group.name')
        },
        {
          value: this._.map(this._.get(this.product, 'children', []), item => item.name).join(', '),
          label: 'Related products'
        }
      ]
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        this.loading = true
        this.product = this._.get(await this.model.api().read(this._.get(this.item, 'id')), 'response.data')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async suppliesTableRequest ({ model, requestParams, route }) {
      const res = await model.api().all(requestParams, { route })

      if (this.useBranches) {
        const data = this._.get(res, 'response.data.data')
        // eslint-disable-next-line camelcase
        const branches = this._.uniq(this._.filter(this._.map(data, i => i?.branch_id), Boolean))
        if (branches.length) {
          await OutletsSearch.api().filter({ 'id[in]': branches.join(',') }).all()

          this._.each(data, (item) => {
            const outlet = OutletsSearch.query().whereId(this._.get(item, 'branch_id')).first()
            this.suppliesModel.update({
              where: this._.get(item, 'id'),
              data: {
                branch_id: outlet ? outlet.toString() : '–'
              }
            })
          })
        }
      }
      return res
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__table-row {
    border-radius: 12px;
    overflow: hidden;

    .dialog__table-td {
      background: #fff;
    }

    &--even {
      .dialog__table-td {
        background: #f7f7f7;
      }
    }

    &:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      .dialog__table-td {
        background: #eeeeee;
      }
    }
  }

  &__table-td {
    &--left {
      border-radius: 12px 0 0 12px;
      color: rgba(22, 27, 37, 0.5);
    }

    &--right {
      border-radius: 0 12px 12px 0;
      color: #000;
    }
  }
}
</style>
