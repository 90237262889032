<template lang="pug">
  div(class="a-bank-questionnaire")
    div(v-if="loading" class="a-bank-questionnaire__loader")
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
    div(v-else)
      v-form(@submit.prevent="submitQuestionnaire")
        div(v-for="field in fields" :key="field.model" class="question-block")
          div(class="answer-block" v-if="field.attrs.answer_type === 'one'")
            h4 {{ field.attrs.label }}
            span(class="question-error") {{ $t(errors[field.model]) }}
            v-radio-group(
              v-model="answers[field.model]"
              :required="field.attrs.required"
              color="primary"
            )
              v-radio(
                v-for="option in field.options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              )
          div(:class="['answer-block', {'many': field.attrs.answer_type !== 'one'}]"  v-else)
            h4 {{ field.attrs.label }}
            span(class="question-error") {{ $t(errors[field.model])}}
            v-checkbox(
              v-for="option in field.options"
              :key="option.value"
              v-model="answers[field.model]"
              :label="option.label"
              :value="option.value"
              :required="field.attrs.required"
            )
</template>

<script>
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ABank from '~/modules/acquiring/models/ABank'
import TOrmFields from '~/components/templates/orm/t-orm-fields'

export default {
  name: 'BlockABankQuestionnaire',
  components: {
    EProgressCircular,
    TOrmFields
  },
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    changeStep: {
      type: Function,
      required: true
    },
    steps: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      questionnaire: {},
      answers: {},
      errors: {}
    }
  },
  computed: {
    fields () {
      return Object.keys(this.questionnaire).map((key) => {
        const question = this.questionnaire[key]
        return {
          model: key,
          attrs: {
            label: question.question,
            required: true,
            answer_type: question.answer_type
          },
          options: question.answers.map(answer => ({
            label: answer.text,
            value: answer.code
          }))
        }
      })
    }
  },
  created () {
    this.changeTitle('Fill out the form to receive the terminal')
    this.getAbQuestions()
  },
  methods: {
    async getAbQuestions () {
      this.loading = true
      try {
        const response = await ABank.api().getAbQuestionnaire()
        this.questionnaire = response.response.data.data
        for (const key in this.questionnaire) {
          this.$set(this.answers, key, this.questionnaire[key].answer_type === 'one' ? '' : [])
        }
      } catch (error) {
        this.$handlers.error(error, this)
      } finally {
        this.loading = false
      }
    },
    validateAnswers () {
      this.errors = {}
      let isValid = true
      for (const field of this.fields) {
        if (field.attrs.required && (this.answers[field.model] === '' || this.answers[field.model].length === 0)) {
          this.errors[field.model] = 'You need to choose a value'
          isValid = false
        }
      }
      return isValid
    },
    async submitQuestionnaire () {
      if (!this.validateAnswers()) {
        this.loading = false
        return
      }
      try {
        this.loading = true
        const payload = {
          corpClientIncomeMonth: this.answers.corp_client_income_month,
          corpClientSourceIncome: this.answers.corp_client_source_income,
          selfEmployedIsPublicPerson: this.answers.self_employed_is_public_person
        }
        await ABank.api().sendQuestionnaire(payload)
        this.$emit('submitQuestionnaire', payload)
        this.changeStep(this.steps.accountOpening)
      } catch (error) {
        this.$handlers.error(error, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.a-bank-questionnaire {
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
  }
  .question-error {
    font-size: 12px;
    color: #FF1744;
    display: block;
    height: 12px;
    margin-bottom: 6px;
  }
  .v-input--selection-controls {
    margin: 0;
  }
  ::v-deep .v-messages {
    display: none !important;
  }
  ::v-deep  .v-radio {
    .v-icon {
      &.mdi-radiobox-blank {
        &:before {
          border: 2px solid #808080;
        }
      }
      &.mdi-radiobox-marked {
        &:before {
          border: 6px solid #04aed5;
        }
      }
    }
  }
  .answer-block {
    margin-bottom: 10px;
  }
  .answer-block h4::after {
    content: "*";
    color: #04aed5;
    margin-left: 4px;
  }
  .answer-block.many {
    h4 {
      padding-bottom: 10px;
      position: relative;
    }
      h4::before{
        content: "(Можна обрати декілька)";
        position: absolute;
        top: 16px;
        font-size: 10px;
        left: 0;
    }
  }
}
</style>
