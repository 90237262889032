<template lang="pug">
  div()
    div(
      v-if="showAcquiringOrderStatus"
      class="pos-terminal-status"
    )
      v-btn(
        v-if="neededAction"
        class="pos-terminal-status__btn"
        @click="handleAction"
        :loading="loading"
        dark
        small
      ) {{ acquiringOrderStatusText }}
      v-tooltip(
        :disabled="!tooltipText"
        v-else
        top
      )
        template(#activator="{ on, attrs }")
          v-chip(
            v-bind="acquiringOrderStatusColors"
            v-on="on"
            small
          )
            span {{ $t(acquiringOrderStatusText) }}
        span() {{ $t(tooltipText) }}
    e-models-cols-map(
      v-bind="colsMapAttrs"
      v-else
    )
</template>

<script>
import EModelsColsMap from '~/components/elements/selections/e-models-cols-map'
import AcquiringOrder from '~/modules/acquiring/models/AcquiringOrder'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  name: 'BlockPosTerminalStatus',
  components: {
    EModelsColsMap
  },
  mixins: [contentDialog],
  data: () => ({
    loading: false
  }),
  computed: {
    item () {
      return this._.get(this.$attrs, 'item')
    },
    acquiringOrderModel () {
      return AcquiringOrder
    },
    acquiringOrderStatus () {
      return this._.get(this.item, 'acquiringOrder.status')
    },
    acquiringOrderStatusText () {
      const text = {
        [this.acquiringOrderModel.STATUSES.new]: this.$t('New'),
        [this.acquiringOrderModel.STATUSES.created]: this.$t('CREATED'),
        [this.acquiringOrderModel.STATUSES.signaturePending]: this.$t('A signature is required'),
        [this.acquiringOrderModel.STATUSES.signed]: this.$t('Signed'),
        [this.acquiringOrderModel.STATUSES.onApproval]: this.$t('Under consideration at the bank'),
        [this.acquiringOrderModel.STATUSES.success]: this.$t('The application has been accepted'),
        [this.acquiringOrderModel.STATUSES.expired]: this.$t('Deadline overdue'),
        [this.acquiringOrderModel.STATUSES.error]: this.$t('Error')
      }
      return text[this.acquiringOrderStatus]
    },
    tooltipText () {
      if (this.acquiringOrderStatus === this.acquiringOrderModel.STATUSES.expired) {
        return 'You have not completed your application within 24 hours. The application can be deleted in the "Actions" menu'
      }
      return ''
    },
    neededAction () {
      return this.acquiringOrderStatus === this.acquiringOrderModel.STATUSES.signaturePending
    },
    showAcquiringOrderStatus () {
      return this.acquiringOrderStatus && this.acquiringOrderStatus !== this.acquiringOrderModel.STATUSES.success
    },
    colsMapAttrs () {
      return {
        ...this.$attrs,
        chips: true,
        template: '{text}',
        map: this.colsMapFunc
      }
    },
    colsMapFunc () {
      if (!this.item) {
        return
      }
      return {
        id: this.item.id,
        active: {
          text: 'Active ',
          type: 'active',
          appearance: {
            color: 'rgba(67,204,180,0.2)',
            textColor: '#43CCB4'
          }
        },
        deactivated: {
          text: 'Deactivated ',
          type: 'error'
        }
      }
    },
    acquiringOrderStatusColors () {
      if (this.acquiringOrderStatus === this.acquiringOrderModel.STATUSES.error || this.acquiringOrderStatus === this.acquiringOrderModel.STATUSES.expired) {
        return this.colorsMap.error
      }
      return this.colorsMap.alert
    },
    colorsMap () {
      return {
        alert: {
          color: 'rgba(255, 170, 1, 0.2)',
          textColor: '#c37f00'
        },
        error: {
          color: 'rgba(255, 0, 43, 0.1)',
          textColor: '#ff002b'
        }
      }
    }
  },
  methods: {
    async handleAction () {
      await this.contentDialog.open({
        component: 'block-a-bank-acquiring-order-pdf',
        width: '900px',
        componentProps: {
          item: this.item,
          tableRequest: this.tableRequest
        }
      })
    },
    async tableRequest () {
      await PosTerminals.api().all(this._.get(this.$attrs, 'requestParams'))
    }
  }
}
</script>

<style scoped lang="scss">
.pos-terminal-status {
  &__btn {
    max-height: 26px;
    font-size: 0.68rem;
  }

  &::v-deep {
    .v-chip {
      border-radius: 8px;
      padding: 0 7px;
      font-size: .69rem;
      height: 22px;
    }
  }
}
</style>
