import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Organization from '~/models/directories/Organization'
import Payment from '~/modules/payments/models/Payment'
import Dialog from '~/models/system/Dialog'
import i18n from '~/plugins/nuxt-i18n/i18n'

export default class TaxBills extends ChainInheritance(Directory, []) {
  static entity = 'taxbills'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'date'
  static persistOptions = {
    insertOrUpdate: [Organization.entity, Payment.entity]
  }

  static ormTrans = {
    single: 'Tax Bill',
    multy: 'Tax Bills'
  }

  static statuses = {
    100: {
      value: 100,
      text: i18n.t('New')
    },
    200: {
      value: 200,
      text: i18n.t('Sent ')
    },
    300: {
      value: 300,
      text: i18n.t('Error')
    }
  }

  static hkbCodes = {
    0: {
      value: 0,
      text: i18n.t('Not a VAT payer')
    },
    1: {
      value: 1,
      text: i18n.t('Juridical person, VAT payer')
    },
    2: {
      value: 2,
      text: i18n.t('IE, VAT payer with code')
    },
    3: {
      value: 3,
      text: i18n.t('Tax number 9 characters')
    },
    4: {
      value: 4,
      text: i18n.t('An ID card is used')
    }
  }

  static fields () {
    return {
      org_id: this.attr(null),
      payment_id: this.attr(null),

      id: this.attr(null),
      dateCreated: this.attr(null),
      dateModified: this.attr(null),
      status: this.attr(null),
      errorMessage: this.attr(null),
      tax_id: this.attr(null),
      date: this.attr(null),
      dateSent: this.attr(null),
      number: this.attr(null),
      receiverName: this.attr(null),
      receiverEdrpou: this.attr(null),
      receiverTaxNumber: this.attr(null),
      receiverOrig: this.attr(null),
      receiverHtypr: this.attr(null),
      sum: this.attr(null),
      sumDetails: this.attr(null),
      label: this.attr(null),
      hkb: this.attr(null),
      organization: this.belongsTo(Organization, 'org_id'),
      payment: this.belongsTo(Payment, 'payment_id')
    }
  }

  get handleDate () {
    return this.getDateTime(this.date)
  }

  get handleDateSent () {
    return this.getDateTime(this.dateSent)
  }

  get handlePaymentDate () {
    return this.payment ? this.getDateTime(this.payment.date) : null
  }

  get handleStatus () {
    return this.status && TaxBills.statuses[this.status].text
  }

  get handleSum () {
    return typeof this.sum === 'number' && (this.sum / 100).toFixed(2)
  }

  get handleSumDetails () {
    let res = ''
    if (this.sumDetails && Array.isArray(this.sumDetails)) {
      for (let i = 0; i < this.sumDetails.length; i++) {
        res += `${i18n.t('Name ')}: ${this.sumDetails[i].name},
        ${i18n.t('Sum')}: ${this.sumDetails[i].sum},
        ${i18n.t('Amount')}: ${this.sumDetails[i].count},
        ${i18n.t('Price')}: ${this.sumDetails[i].price};
        `
      }
    }
    return res
  }

  get hkbString () {
    return this.hkb === null ? `${TaxBills.hkbCodes[0].text}` : `${TaxBills.hkbCodes[this.hkb].value} - ${TaxBills.hkbCodes[this.hkb].text}`
  }

  static ormHeaders = [
    { text: 'Status', value: 'handleStatus', sortable: true, filterable: false },
    { text: 'Number', value: 'number', sortable: true, filterable: false },
    { text: 'Label', value: 'label', sortable: true, filterable: false },
    { text: 'organization', value: 'organization.name', sortable: true, filterable: false },
    { text: 'Receiver name', value: 'receiverName', sortable: true, filterable: false },
    { text: 'EDRPOU', value: 'receiverEdrpou', sortable: true, filterable: false },
    { text: 'Tax number', value: 'receiverTaxNumber', sortable: true, filterable: false },
    { text: 'Code', value: 'hkbString', sortable: true, filterable: false },
    { text: 'Sum', value: 'handleSum', sortable: true, filterable: false },
    { text: 'Date', value: 'handleDate', sortable: true, filterable: false },
    { text: 'Date sent', value: 'handleDateSent', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      attrs: {
        label: 'Status',
        outlined: true,
        'hide-details': true
      },
      default: () => '',
      items: () => [{ text: i18n.t('All'), value: '' }, ...Object.values(this.statuses)]
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormRowsConfig = {
    checkbox: true
  }

  static ormToolbarButtons = [
    {
      text: 'Send selected tax bills',
      attrs: {
        color: 'primary',
        class: ['mr-2 main-button']
      },
      visible: (item) => {
        return this._.get(item, 'parentCtx.selected', []).length
      },
      call: async (fields, dictionary, parentCtx) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          title: 'Tax bills sending',
          width: '800px',
          component: 'm-block-multiple-tax-bills-sending',
          componentProps: {
            items: this._.get(parentCtx, 'selected', [])
          }
        })
      }
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 6
              },
              fields: [
                'status'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 6
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    {
      model: 'number',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'number',
        name: 'Number'
      },
      attrs: {
        label: 'Number',
        outlined: true,
        type: 'number'
      }
    },
    {
      model: 'label',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'label',
        name: 'Label'
      },
      attrs: {
        label: 'Label',
        outlined: true
      }
    },
    {
      model: 'receiverName',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'receiverName',
        name: 'Receiver name',
        rules: 'required'
      },
      attrs: {
        label: 'Receiver name',
        outlined: true
      }
    },
    {
      model: 'receiverEdrpou',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'receiverEdrpou',
        name: 'Receiver EDRPOU'
      },
      attrs: {
        label: 'Receiver EDRPOU',
        outlined: true
      }
    },
    {
      model: 'receiverTaxNumber',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'receiverTaxNumber',
        name: 'Receiver tax number',
        rules: 'required'
      },
      attrs: {
        label: 'Receiver tax number',
        outlined: true
      }
    },
    {
      model: 'hkb',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'hkb',
        name: 'Code'
      },
      attrs: {
        label: 'Code',
        outlined: true,
        type: 'number'
      }
    },
    {
      model: 'receiverOrig',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'receiverOrig',
        name: 'The bill of lading is not subject to provision'
      },
      attrs: {
        label: 'Bill of lading cannot be provided (HORIG1) (null/0/1)',
        outlined: true
      }
    },
    {
      model: 'receiverHtypr',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'receiverHtypr',
        name: 'The reason is not provided'
      },
      attrs: {
        label: 'Reason not provided (HTYPR, null/01/02)',
        outlined: true
      }
    },
    {
      model: 'date',
      component: 'v-text-field',
      context: this.contexts.u,
      provider: {
        vid: 'number',
        name: 'Date'
      },
      attrs: {
        label: 'Date',
        outlined: true
      }
    },
    {
      model: 'sum',
      component: 'v-text-field',
      context: this.contexts.u,
      fieldVal: (ctx) => {
        const val = this._.get(ctx.attrs, ctx.field.model)
        return val / 100
      },
      provider: {
        vid: 'sum',
        name: 'Sum',
        rules: 'required'
      },
      attrs: {
        label: 'Sum (uah)',
        outlined: true
      }
    },
    {
      model: 'sumDetails',
      component: 'v-textarea',
      fieldVal: (ctx) => {
        const val = this._.get(ctx.attrs, ctx.field.model)
        return JSON.stringify(val)
      },
      context: this.contexts.u,
      provider: {
        vid: 'sumDetails',
        name: 'Sum details',
        rules: 'required'
      },
      attrs: {
        label: 'Sum details',
        outlined: true
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormActions = [
    {
      name: 'read'
    },
    {
      name: 'send',
      text: i18n.t('Send invoice'),
      icon: {
        text: 'invoice-2',
        type: 'e-svg-icon'
      },
      visible: item => item.status === TaxBills.statuses[100].value || item.status === TaxBills.statuses[300].value,
      call: (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Send tax bill',
          component: 'm-form-billing-tax-bill',
          componentProps: {
            selectedItem: item
          }
        })
      }
    }
  ]

  static ormDialogsConfig = {
    read: {
      title: item => ({ type: this.ormTrans.single, name: item.number }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'handleStatus',
            label: 'Status'
          },
          {
            model: 'number',
            label: 'Number'
          },
          {
            model: 'handleDate',
            label: 'Date'
          },
          {
            model: 'handleDateSent',
            label: 'Date sent',
            value: val => val || '—'
          },
          {
            model: 'organization',
            label: 'Organization of the recipient',
            value: val => val.name
          },
          {
            model: 'receiverName',
            label: 'Recipient\'s name'
          },
          {
            model: 'receiverEdrpou',
            label: 'USREOU of the recipient'
          },
          {
            model: 'receiverTaxNumber',
            label: 'Receiver tax number'
          },
          {
            model: 'hkbString',
            label: 'Code'
          },
          {
            model: 'receiverOrig',
            label: 'Bill of lading is not subject to provision (HORIG1)',
            value: val => val || '—'
          },
          {
            model: 'receiverHtypr',
            label: 'Bill of lading cannot be issued for reason (reason type HTYPR)',
            value: val => val || '—'
          },
          {
            model: 'handleSum',
            label: 'Sum'
          },
          {
            model: 'handleSumDetails',
            label: 'Sum details'
          },
          {
            model: 'errorMessage',
            label: 'Error',
            value: val => val || '—'
          },
          {
            model: 'tax_id',
            label: 'Id in the tax office',
            value: val => val || '—'
          }
        ]
      }
    }
  }
}
