import Organization from '~/models/directories/Organization'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'

const organization = {
  mixins: [edsIitCheckboxDialog],
  methods: {
    checkPersonType (code = '') {
      // for old passport type (8 symbols)
      if (isNaN(parseInt(code))) {
        return 'individual'
      }
      // edrpou always 8 symbols
      return code.toString().length === 8 ? 'juridical' : 'individual'
    },
    async getEdsKeyData (params = {}) {
      const { fallback } = params
      const dialogType = 'edsIitCheckboxCommon'
      let data = null
      let signedData = null
      await this.useEdsIitCheckboxDialog({
        type: dialogType,
        method: 'open',
        keyCheck: false,
        dialogParams: {
          readDiiaKey: true
        },
        pluginTitles: {
          options: this.$t('signPlugin.addOrg')
        },
        hints: {
          options: this.$t('signPlugin.readOrgDataFromSfs'),
          fileSign: this.$t('signPlugin.directorSign'),
          smartId: this.$t('signPlugin.smartId')
        },
        onConfirm: async ({ edsKeyData, sign, dialogClosed, isDiia }) => {
          if (dialogClosed) {
            return
          } else if (!edsKeyData?.keyData && !isDiia) {
            this.$handlers.error('We could not read the key data. Please try again or choose a different key', this)
            await this.closeEdsIitCheckboxDialog(dialogType)
            return fallback(params)
          } else if (edsKeyData.isSeal() && !isDiia) {
            this.$handlers.error('Please use the EDS key instead of seal', this)
            await this.closeEdsIitCheckboxDialog(dialogType)
            return fallback(params)
          }
          signedData = await sign(edsKeyData.identity, { keyCheck: false })
          data = edsKeyData
          await this.closeEdsIitCheckboxDialog(dialogType)
        }
      })
      return {
        edsKeyData: data,
        sign: signedData
      }
    },
    async getDataFromSfs ({ edsKeyData, sign } = {}) {
      return await this.$store.dispatch('user/getOrganizationFromTax', {
        EDRPOUCode: edsKeyData.edrpouCode,
        DRFOCode: edsKeyData.drfoCode,
        sign
      }) || {}
    },
    getOrganizationData ({ dataFromSfs, edsKeyData } = {}) {
      const name = this._.get(dataFromSfs, 'identify.FULL_NAME', '')
      const shortName = this._.get(dataFromSfs, 'identify.NAME', '') || name
      const edrpou = edsKeyData.identity || this._.get(dataFromSfs, 'identify.TIN', '')
      const taxNumber = this._.get(dataFromSfs, 'vat.KOD_PDV', '') || edsKeyData.identity
      const personType = this.checkPersonType(edsKeyData.identity)
      const personShowType = personType === 'juridical' ? 'Legal entity' : 'Individual'
      const isVAT = Boolean(this._.get(dataFromSfs, 'vat', null)) && !this._.get(dataFromSfs, 'vat.DAT_ANUL', null)
      const isSingleTax = Boolean(this._.get(dataFromSfs, 'singleTax', null)) && !this._.get(dataFromSfs, 'singleTax.DAT_ANUL', null)
      const dataFromSfsCopy = JSON.parse(JSON.stringify(dataFromSfs))
      delete dataFromSfsCopy.exists
      delete dataFromSfsCopy.token

      return {
        organizationData: {
          name,
          shortName,
          edrpou,
          taxNumber,
          personType,
          isVAT,
          isSingleTax,
          taxInfo: dataFromSfsCopy
        },
        token: this._.get(dataFromSfs, 'token'),
        personShowType
      }
    },
    async addOrganization ({ organizationData, token, isMultiUser, email }) {
      this.$gtm.push({
        event: 'create_organization',
        organization_name: this._.get(organizationData, 'name', ''),
        email: email || this._.get(this.$User, 'email') || ''
      })
      if (isMultiUser) {
        return await Organization.api().multiAccount(organizationData, token)
      }
      return await Organization.api().putDraftOrganization(this._.get(this.$User, 'ownedOrganization.id', ''), organizationData, token)
    }
  }
}

export default organization
