<template lang="pug">
  ValidationObserver(ref="form" slim)
    v-row
      v-col(cols="12")
        ValidationProvider(
          vid="balance"
          :name="$t('Top-up amount')"
          rules="required|numeric|min_value:1"
          v-slot="{ errors }"
          slim
        )
          v-text-field(
            type="number"
            :label="$t('Top-up amount')"
            v-model="balance"
            :placeholder="$t('Enter the top-up amount')"
            :error-messages="errors"
            outlined
          )
          p {{ $t('Sms left') }}: {{ estimatedSmsCount }}
      v-col(cols="12" class="text-center pt-0")
        v-btn(
          class="pay__button"
          color="black"
          @click="payment"
          :loading="cartLoader"
          :disabled="cartLoader || invoiceLoader"
          block
          dark
        )
          | {{ $t('Pay by card') }}
          e-svg-icon(size="lg" class="ml-3") mastercard
          e-svg-icon(size="lg" class="ml-3") visa-white
        v-btn(
          class="pay__button mt-4"
          color="black"
          @click="payViaInvoice"
          :loading="invoiceLoader"
          :disabled="invoiceLoader || cartLoader"
          block
          dark
        )
          | {{ $t('Create an invoice') }}
          e-svg-icon(size="lg" class="ml-5") invoice
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import TOrmFields from '~/components/templates/orm/t-orm-fields'

import { pricePerSms } from '~/const/global'
import contentDialog from '~/mixins/dialogs/contentDialog'

import SmsBilling from '~/models/sms/SmsBilling'
import SmsInvoice from '~/models/sms/SmsInvoice'

export default {
  components: { ESvgIcon, TOrmFields },
  mixins: [contentDialog],
  props: {
    context: {
      type: String,
      default: 'create',
      validator: (value) => {
        return ['create', 'update'].includes(value)
      }
    }
  },
  data: () => ({
    balance: null,
    editedItem: {},
    invoiceLoader: false,
    cartLoader: false,
    shortLink: '',
    debouncedGetPaymentLink: null
  }),
  computed: {
    estimatedSmsCount () {
      return (this.balance) ? Math.floor(this.balance * 100 / pricePerSms) : 0
    }
  },
  watch: {
    balance (val) {
      if (val) {
        this.debouncedGetPaymentLink()
      }
    }
  },
  created () {
    this.debouncedGetPaymentLink = this._.debounce(this.getPaymentLink, 500)
  },
  methods: {
    async isValidFrom () {
      return await this.$refs.form.validate()
    },
    async payViaInvoice () {
      if (!await this.isValidFrom()) {
        return
      }

      try {
        this.invoiceLoader = true
        const invoice = await SmsInvoice.api().generateSmsInvoice({
          amount: parseInt(this.balance * 100)
        })

        this.$notification.success(this.$t('The invoice has been generated'))

        const pdf = await SmsInvoice.api().getSmsInvoicePdf(this._.get(invoice, 'response.data.id'))
        const blob = new Blob([this._.get(pdf, 'response.data', '')], { type: 'application/pdf' })

        window.open(URL.createObjectURL(blob), '_blank')
        await this.contentDialog.close()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.invoiceLoader = false
      }
    },
    async getPaymentLink () {
      if (!await this.isValidFrom()) {
        return
      }

      try {
        this.cartLoader = true
        const response = await SmsBilling.api().post(SmsBilling.$routes[SmsBilling.entity].generatePaymentLink(), {
          lang: 'ua',
          amount: parseInt(this.balance * 100)
        }, { save: false })

        this.shortLink = this._.get(response, 'response.data.url')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.cartLoader = false
      }
    },
    payment () {
      if (this.shortLink) {
        window.open(this.shortLink, '_blank')
      }
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
