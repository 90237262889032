<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" type="confirmation" max-width="495px" content-class="m-orm-copy-dialog")
    template(#title)
      span(class="text-center d-inline-block") {{ title }}
    template(#actions)
      t-orm-buttons(:items="ormButtons" :classes="['text-center']")
</template>

<script>
import DOMPurify from 'dompurify'
import TDialog from '~/components/templates/t-dialog'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'

export default {
  components: {
    TDialog,
    EFlatIcon,
    TOrmButtons
  },
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    title: null,
    source: {},
    successAction: null,
    localLoading: false
  }),
  computed: {
    dialogConfig () {
      return this.model.getOrmDialogsConfig(this.type)
    },
    copyConfig () {
      return this.dialogConfig.copyConfig
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.$emit('input', event)
            vm.clear()
          }
        }
      )
    },
    ormButtons () {
      return [
        {
          text: 'Cancel',
          attrs: {
            color: 'primary',
            depressed: true,
            text: true
          },
          call: this.close
        },
        {
          text: 'Confirm',
          attrs: {
            color: 'error',
            depressed: true,
            class: ['mr-4']
          },
          loading: this.localLoading,
          call: this.copy
        }
      ]
    }
  },
  methods: {
    clear (immediate = false) {
      immediate ? this.source = {} : setTimeout(() => {
        this.source = {}
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    async copy () {
      this.localLoading = true
      try {
        const source = this.model.getOrmFieldsObject(this.source)
        const data = {}
        this.copyConfig.fields.forEach((field) => { data[field] = source[field] })
        if (!this._.isEmpty(this.copyConfig.autogeneratedFields)) {
          for (let i = 0; i < this.copyConfig.autogeneratedFields.length; i++) {
            let value = source[this.copyConfig.autogeneratedFields[i].name]
            if (this._.isFunction(this.copyConfig.autogeneratedFields[i].request)) {
              value = await this.copyConfig.autogeneratedFields[i].request(source)
            }
            if (this._.isFunction(this.copyConfig.autogeneratedFields[i].generator)) {
              value = this.copyConfig.autogeneratedFields[i].generator(value)
            }
            data[this.copyConfig.autogeneratedFields[i].name] = value
          }
        }
        const newItemResponse = await this.model.api().create(data)
        const newItem = this._.get(newItemResponse, `entities.${this.model.entity}[0]`, null)
        const newItemLocal = this.model.query().withAll().whereId(newItem[this.model.primaryKey]).first()

        this.localLoading = false
        const notificationConfig = this.dialogConfig.notification(source)
        const type = { ...notificationConfig.type.split('|') }
        notificationConfig.type = this.$tc(DOMPurify.sanitize(type[0]), DOMPurify.sanitize(type[0]))
        this.$notification.success(this.$t('m-orm-copy-dialog.notification', notificationConfig))
        this.close()

        return this._.isFunction(this.successAction) ? this.successAction(newItemLocal) : null
      } catch (e) {
        this.localLoading = false
        this.$handlers.error(e, this.$refs[this.model.entity])
      }
    },

    fill (source, title, successAction) {
      this.source = source
      this.title = title
      this.successAction = successAction
    }
  }
}
</script>

<style lang="sass">
  .m-orm-copy-dialog
    overflow-y: unset !important
</style>
