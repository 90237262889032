import Search from '~/models/abstracts/Search'
import { formats } from '~/const/global'
import i18n from '~/plugins/nuxt-i18n/i18n'

export class InvoicesSearch extends Search {
  static entity = 'invoicessearch'

  static fields () {
    return {
      id: this.attr(null),
      number: this.attr(null),
      date: this.attr(null)
    }
  }

  toString () {
    const date = this.date && this.$moment(this.date).format(formats.date)
    return date ? `${i18n.t('Invoice')} №${this.number} ${i18n.t('from ')} ${date}` : `${i18n.t('Invoice')} №${this.number}`
  }
}

export default InvoicesSearch
