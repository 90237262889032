import Search from '~/models/abstracts/Search'

export class EmployeesSearch extends Search {
  static entity = 'employeessearch'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      login: this.attr(null),
      publicKeyId: this.attr(null),
      signatureType: this.attr(null),
      mode: this.attr(null),
      certificateEnd: this.attr(null)
    }
  }

  toString () {
    return `${this.name} (${this.login})`
  }
}

export default EmployeesSearch
