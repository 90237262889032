<template lang="pug">
  div(
    class="block-groups-tree"
    :class="{ 'block-groups-tree--mobile': isMobile }"
    :style="{ maxHeight: maxHeight }"
  )
    div(
      v-if="isMobile"
      class="block-groups-tree__header"
    )
      v-btn(
        @click="onCloseDrawer(false)"
        icon
        small
      )
        e-svg-icon cross-2
      span {{ _.get(this.selectedGroup, 'name') || $t('Groups of goods')}}
      div(class="block-groups-tree__header-actions")
        t-orm-menu-actions(
          v-if="selectedGroup"
          :items="intersectedActions"
          :target="selectedOrmItem"
          :menu-props="{ left: true, top: false, bottom: true, offsetY: true }"
          small-button
        )
    div(class="block-groups-tree__selected")
      v-text-field(
        @input="onSearch"
        :value="inputValue"
        class="block-groups-tree__input"
        :class="{ 'block-groups-tree__input--selected': isGroupSelected }"
        :placeholder="$t('Search by groups')"
        :prepend-inner-icon="isGroupSelected ? '' : 'mdi-magnify'"
        :readonly="isGroupSelected"
        :clearable="!isGroupSelected"
        ref="input"
        hide-details
      )
        //template(v-slot:prepend)
        //  v-btn(
        //    v-if="Boolean(_.get(selectedGroup, 'parent_id'))"
        //    @click="onBackBtnClick"
        //    class="block-groups-tree__back-btn"
        //    icon
        //    small
        //  )
        //    e-svg-icon(size="xsm") arrow-next
        template(v-slot:append-outer)
          div(
            v-if="isGroupSelected"
            :style="{ display: 'flex', alignItems: 'center', gap: '2px' }"
          )
            v-btn(
              @click="onGroupSelect(null, true)"
              class="block-groups-tree__cross-btn"
              icon
              small
            )
              e-svg-icon cross-2
            t-orm-menu-actions(
              :items="intersectedActions"
              :target="selectedOrmItem"
              :menu-props="{ left: false, offsetY: true }"
              small-button
            )
    div(class="block-groups-tree__content")
      div(
        v-if="!_.get(items, 'length') && !loading"
        class="block-groups-tree__no-found"
      )
        span(v-if="searchString") {{ $t('Nothing found') }}
        span(v-else) {{ $t('You do not have settings groups yet') }}
      v-treeview(
        :items="items"
        class="block-groups-tree__treeview"
        :search="searchString"
        :active="selectedGroup ? [_.get(selectedGroup, 'id')] : []"
        ref="treeview"
        expand-icon="mdi-chevron-down"
        return-object
        activatable
        hoverable
      )
        template(v-slot:label="{ item }")
          e-text-with-image(
            v-bind="textWithImageProps"
            :item="item"
            :value="_.get(item, 'name')"
          )
          div(
            class="block-groups-tree__overlay"
            @click="onGroupSelect(item)"
          )
      div(
        v-if="loading"
        class="block-groups-tree__loader"
      )
        e-progress-circular(
          width="4"
          size="xl"
          color="#04aed5"
        )
      v-card(
        v-if="firstRequestDone"
        v-intersect.quiet="chunkRequest"
      )
</template>

<script>
import ETextWithImage from '~/components/elements/text/e-text-with-image'
import GoodsGroups from '~/modules/goods/models/GoodsGroups'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import TOrmMenuActions from '~/components/templates/orm/t-orm-menu-actions'
import actions from '~/mixins/tables/actions'

export default {
  name: 'BlockGroupsTree',
  components: {
    ESvgIcon,
    ETextWithImage,
    EProgressCircular,
    TOrmMenuActions
  },
  mixins: [actions],
  props: {
    maxHeight: {
      type: null,
      default: null
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    onCloseDrawer: {
      type: Function,
      default: () => {}
    },
    onSelectGroup: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    originalItems: [],
    items: [],
    meta: {},
    selectedGroup: null,
    limit: 50,
    page: 1,
    firstRequestDone: false,
    debouncedRequest: null,
    searchString: null
  }),
  computed: {
    model () {
      return GoodsGroups
    },
    textWithImageProps () {
      return {
        icon: {
          name: 'folder'
        }
      }
    },
    isGroupSelected () {
      return Boolean(this.selectedGroup && !this.isMobile)
    },
    inputValue () {
      return this.isMobile ? null : (this._.get(this.selectedGroup, 'name') || this.searchString)
    },
    intersectedActions () {
      return this._.map(this.model.ormActions, (ormAction) => {
        return Object.assign({}, this._.find(this.$actions, ['name', ormAction.name]), ormAction)
      })
    },
    selectedOrmItem () {
      const id = this._.get(this.selectedGroup, 'id')
      if (id) {
        return {
          $id: id,
          ...this.selectedGroup
        }
      } else {
        return {}
      }
    },
    totalItems () {
      return this._.get(this.meta, 'totalItems')
    },
    itemsLength () {
      return this._.get(this.items, 'length')
    }
  },
  created () {
    this.debouncedRequest = this._.debounce(async ({ request, beforeRequest } = {}) => {
      if (this._.isFunction(beforeRequest)) {
        await beforeRequest()
      }
      if (this._.isFunction(request)) {
        await request()
      }
    }, 800)
    this.$root.$on('goodsGroupsChangeAction', this.goodsGroupsChangeAction)
  },
  beforeDestroy () {
    this.$root.$off('goodsGroupsChangeAction', this.goodsGroupsChangeAction)
  },
  async mounted () {
    await this.loadPage()
  },
  methods: {
    onSearch (val) {
      this.debouncedRequest({
        beforeRequest: () => {
          this.searchString = val
          this.page = 1
          this.items = []
        },
        request: async () => {
          const { items = [] } = await this.getItems({ search: val })
          this.originalItems = items
          this.items = items.slice(0, this.limit)
        }
      })
    },
    onBackBtnClick () {
      const parentId = this._.get(this.selectedGroup, 'parent_id')
      const foundGroupItem = this.findGroupById(this.items, parentId)
      this.onGroupSelect(foundGroupItem)
    },
    async onGroupSelect (item, onRoot = false) {
      this.$set(this.$data, 'selectedGroup', item)
      if (this._.isFunction(this.onSelectGroup)) {
        this.onSelectGroup(item)
      }
      if (this.isMobile) {
        this.onCloseDrawer(false)
      }
      if (onRoot) {
        const inputRef = this._.get(this.$refs, 'input')
        if (inputRef) {
          inputRef.lazyValue = null
        }
        this.searchString = null
        this.page = 1
        this.items = []
        await this.loadPage()
      }
    },
    findGroupById (arr, id) {
      for (const obj of arr) {
        if (obj?.id === id) {
          return obj
        }
        if (obj?.children?.length) {
          const result = this.findGroupById(obj.children, id)
          if (result) {
            return result
          }
        }
      }
      return null
    },
    goodsGroupsChangeAction ({ group, action } = {}) {
      const id = this._.get(group, 'id')
      const parentId = this._.get(group, 'parent_id')
      if (action === 'add') {
        if (this.totalItems) {
          this.meta.totalItems += 1
        }

        if (!parentId) {
          this.items.unshift(group)
          return
        }

        const foundGroupItem = this.findGroupById(this.items, parentId)
        if (foundGroupItem) {
          if (!this._.get(foundGroupItem, 'children')) {
            foundGroupItem.children = []
          }

          foundGroupItem.children.push(group)
          const itemsCopy = JSON.parse(JSON.stringify(this.items))
          this.$set(this.$data, 'items', itemsCopy)
        }
      } else if (action === 'edit') {
        const foundGroupItem = this.findGroupById(this.items, id)
        this._.each(foundGroupItem, (val, key) => {
          const newVal = this._.get(group, key, null)
          if (val !== newVal) {
            foundGroupItem[key] = newVal
          }
        })
      } else if (action === 'delete') {
        this.onGroupSelect(null)

        if (this.totalItems) {
          this.meta.totalItems -= 1
        }

        if (!parentId) {
          this.items = this._.filter(this.items, i => this._.get(i, 'id') !== id)
          return
        }

        const foundGroupItem = this.findGroupById(this.items, parentId)
        if (foundGroupItem) {
          if (this._.get(foundGroupItem, 'children.length') === 1) {
            delete foundGroupItem.children
          } else {
            foundGroupItem.children = this._.filter(foundGroupItem.children, i => this._.get(i, 'id') !== id)
          }

          const itemsCopy = JSON.parse(JSON.stringify(this.items))
          this.$set(this.$data, 'items', itemsCopy)
        }
      }
    },
    chunkRequest (entries, observer, isIntersecting) {
      const isRequestAble = (!this.totalItems || this.itemsLength < this.totalItems) && !this.loading
      const allItemsReceived = this.itemsLength === this.totalItems
      if (!isRequestAble || allItemsReceived || (this.loading && !isIntersecting)) {
        return
      }
      if (this.searchString) {
        if (this._.get(this.items, 'length') < this._.get(this.originalItems, 'length')) {
          this.page += 1
          const offset = Math.round((this.page - 1) * this.limit)
          const limit = Math.round(this.limit * this.page)
          const nextItems = this.originalItems.slice(offset, limit) || []
          this.items.push(...nextItems)
        }
      } else {
        this.debouncedRequest({
          beforeRequest: () => {
            this.page += 1
          },
          request: this.loadPage
        })
      }
    },
    async loadPage (params) {
      const { meta = {}, items = [] } = await this.getItems(params)

      this.$set(this.$data, 'items', [...this.items, ...items])
      this.$set(this.$data, 'meta', meta)
    },
    async getItems (params = {}) {
      try {
        this.loading = true
        const filter = {
          recursive_tree: true,
          limit: this.limit,
          offset: this.page,
          'order[name]': 'asc',
          ...params
        }
        const res = await this.model.api().filter(filter).getActive()
        const data = this._.get(res, 'response.data.data')
        const meta = this._.get(res, 'response.data.meta')
        this.firstRequestDone = true
        return {
          meta,
          items: data || []
        }
      } catch (e) {
        this.$handlers.error(e, this)
        return {}
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.block-groups-tree {
  overflow-y: auto;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &--mobile {
    .block-groups-tree {
      &__selected {
        padding: 0 16px;
      }

      &__treeview {
        &::v-deep {
          .v-treeview-node {
            &__root {
              padding: 12px 16px;
            }
          }
        }
      }
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
  }

  &__cross-btn {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__back-btn {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &__selected {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 0 12px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__input {
    padding: 0;
    margin: 0;

    &::v-deep {
      input {
        font-size: 12px;
        text-overflow: ellipsis;
      }

      .v-input {
        &__control {
          margin: 0;
        }

        &__append-outer {
          margin: 0;
          align-self: center;
        }

        &__prepend-outer {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 3px;
          align-self: center;
        }

        &__append-inner {
          margin: 0;
          align-self: center;

          button {
            font-size: 18px;
          }
        }

        &__prepend-inner {
          margin: 0;
          align-self: center;

          i {
            font-size: 19px;
          }
        }

        &__slot {
          height: 47px;

          &::after {
            display: none;
          }
          &::before {
            display: none;
          }
        }
      }
    }

    &--selected {
      &::v-deep {
        input {
          font-weight: 700;
          cursor: default;
        }

        .v-input {
          &__slot {
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    overflow: auto;
    flex-grow: 1;
    padding-bottom: 10px;
  }

  &__treeview {
    &::v-deep {
      .e-text-with-image {
        min-height: 21px;

        span {
          font-size: 13px;
        }
      }

      .v-treeview-node {
        &--active {
          color: initial !important;

          &::before {
            opacity: 0.04 !important;
          }
        }

        &__toggle {
          order: 1;
          position: relative;
          z-index: 2;
          transform: none;
          color: rgba(0, 0, 0, 0.45);

          &--open {
            transform: rotate(-180deg);
            color: $btn-main-color-dark;
          }
        }

        &--leaf {
          .v-treeview-node {
            &__level {
              &:first-child {
                display: none;
              }
            }
          }
        }

        &__level {
          width: 16px;
        }

        &__label {
          white-space: normal;
        }

        &__content {
          margin-left: 0;
          margin-right: 6px;
        }

        &__root {
          padding: 12px;
          min-height: 24px;
          cursor: pointer;
          display: inline-flex;
          min-width: 100%;
          position: relative;

          &::before {
            border-radius: 10px;
          }
        }
      }
    }
  }

  &::v-deep {
    .t-orm-menu-actions {
      .v-btn {
        color: $btn-main-color-dark;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__header {
    display: flex;
    gap: 8px;
    padding: 14px 16px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    span {
      flex-grow: 1;
      text-align: center;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $btn-main-color-dark;
      white-space: nowrap;
    }
  }

  &__header-actions {
    width: 24px;

    &::v-deep {
      .t-orm-menu-actions {
        padding: 0;

        .v-btn {
          opacity: 1;
        }
      }
    }
  }

  &__no-found {
    font-size: 13px;
    color: #555;
    text-align: center;
    padding: 24px 16px;
  }
}
</style>
