<template lang="pug">
  ValidationObserver(ref="form")
    t-orm-fields(
      v-bind.sync="form"
      :items="fields"
    )
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import Outlets from '~/models/directories/Outlets'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import MccCodesSearch from '~/models/directories/search/MccCodesSearch'

export default {
  name: 'BlockABankAcquiringOrder',
  components: {
    TOrmFields
  },
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    form: {
      posTerminalCount: null,
      mccCode: null,
      outlet: null
    }
  }),
  computed: {
    fields () {
      return [
        {
          model: 'posTerminalCount',
          component: 'v-text-field',
          provider: {
            vid: 'posTerminalCount',
            name: 'Terminals count',
            rules: 'required|numeric|min_value:0'
          },
          attrs: {
            label: 'Terminals count',
            outlined: true
          },
          cast: val => val ? parseInt(val) : 0
        },
        new AutocompleteBuilder({
          model: 'mccCode',
          label: 'The main type of activity',
          returnObject: true,
          itemClass: 'ws-pre',
          multiple: false,
          itemValue: item => this._.get(item, 'mcc', null),
          query: model => model.api()
        }, MccCodesSearch).build(),
        new AutocompleteBuilder({
          model: 'outlet',
          label: 'outlet',
          rules: 'required',
          query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (items && items.length === 1) {
              return items[0]
            }
          }
        }, OutletsSearch).build()
      ]
    }
  },
  created () {
    this.changeTitle('Application for the use of acquiring')
    this.toggleCloseBtn(true)
  }
}
</script>

<style scoped lang="scss">

</style>
