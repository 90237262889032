<template lang="pug">
  div(
    class="pos-terminal-settings-form"
    :class="{ dialog: isDialogPart }"
  )
    div(
      v-if="isDialogPart"
      class="dialog__title pos-terminal-settings-form__title mb-3"
    )
      span {{ $t(title) }}
      div(
        v-if="!isEditing"
        class="pos-terminal-settings-form__link"
      )
        e-link(href="https://wiki.checkbox.ua/uk/app/pc/portal_acquiring") {{ $t('Instructions') }} &#x2192;
    v-form(@submit.prevent.stop="submit")
      div()
        ValidationObserver(ref="form" slim)
          v-radio-group(
            v-model="form.platform"
            class="pos-terminal-settings-form__radio-group"
            :column="false"
            hide-details
          )
            v-radio(
              label="Desktop Pay Link"
              :value="model.platforms.desktop.value"
            )
            v-radio(
              label="Android Pay Link"
              :value="model.platforms.android.value"
            )
          t-orm-fields(
            v-bind.sync="form"
            :items="fields"
          )
      div(class="pos-terminal-settings-form__actions")
        v-btn(
          class="main-button"
          :loading="loading"
          type="submit"
        ) {{ $t(buttonText) }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import CashRegisters from '~/models/directories/CashRegisters'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import commonData from '~/modules/receipt/mixins/getters/commonData'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import PayLinkTerminals from '~/modules/acquiring/models/PayLinkTerminals'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'BlockPosTerminalSettingsForm',
  components: {
    TOrmFields,
    ELink
  },
  mixins: [commonData],
  props: {
    item: {
      type: null,
      default: null
    },
    isDialogPart: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    posTerminals: [],
    devicesLoading: false,
    debouncedGetPosTerminals: null,
    form: {
      port: null,
      usage: true,
      ipAddress: null,
      terminalName: null,
      payLinkTerminal: null,
      printReceiptOnAndroidTerminal: false,
      displayReceiptOnAndroidTerminal: false,
      cashRegister: null,
      platform: 'desktop'
    }
  }),
  computed: {
    model () {
      return PosTerminals
    },
    title () {
      if (this.isEditing) {
        return 'POS-terminal editing'
      }
      return 'Add POS-terminal'
    },
    buttonText () {
      if (this.isEditing) {
        return 'Save'
      }
      return 'Add'
    },
    isAndroidForm () {
      return this._.get(this.form, 'platform') === this.model.platforms.android.value
    },
    fields () {
      return [
        {
          model: 'ipAddress',
          component: 'v-text-field',
          provider: {
            vid: 'ipAddress',
            name: 'IP address',
            rules: 'required'
          },
          attrs: {
            label: 'IP address',
            visible: _ => !this.isAndroidForm,
            outlined: true
          },
          fieldVal: () => this._.get(this.item, 'ipAddress') || '127.0.0.1'
        },
        {
          model: 'port',
          component: 'v-text-field',
          provider: {
            vid: 'port',
            name: 'Port',
            rules: 'required'
          },
          attrs: {
            label: 'Port',
            visible: _ => !this.isAndroidForm,
            outlined: true
          },
          fieldVal: () => this._.get(this.item, 'port') || '9020'
        },
        {
          model: 'terminalName',
          component: 'v-select',
          provider: {
            vid: 'terminalName',
            name: 'Available terminals',
            rules: 'required',
            loading: this.devicesLoading
          },
          attrs: {
            outlined: true,
            label: 'Available terminals',
            itemText: item => `${item.name} - ${item.id}`,
            itemValue: 'id',
            disabled: !this.form.ipAddress || !this.form.port,
            visible: _ => !this.isAndroidForm
          },
          items: () => this.posTerminals,
          fieldVal: () => this._.get(this.item, 'terminalName', '')
        },
        {
          model: 'payLinkTerminal',
          component: 'v-select',
          provider: {
            vid: 'payLinkTerminal',
            name: 'Available terminals',
            rules: 'required'
          },
          attrs: {
            label: 'Available terminals',
            loading: this.devicesLoading,
            outlined: true,
            'item-text': item => `${item.label} - ${item.id}`,
            'item-value': 'id',
            'return-object': true,
            visible: _ => this.isAndroidForm
          },
          fieldVal: () => {
            return this._.get(this.item, 'payLinkTerminalId', '')
          },
          items: () => this.posTerminals
        },
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Select cash register',
          returnObject: true,
          itemClass: 'ws-pre',
          rules: 'required',
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (!this._.get(this.item, 'cashRegister', null) && items && items.length === 1) {
              return items[0]
            }
          },
          fieldVal: () => this._.get(this.item, 'cashRegister', null),
          itemValue: item => this._.get(item, 'id', null)
        }, CashRegistersSearch).build(),
        {
          model: 'printReceiptOnAndroidTerminal',
          component: 'v-switch',
          provider: {
            vid: 'printReceiptOnAndroidTerminal',
            name: 'Print a check on the Android terminal (automatically)'
          },
          attrs: {
            label: 'Print a check on the Android terminal (automatically)',
            visible: _ => this.isAndroidForm
          },
          fieldVal: () => this._.get(this.item, 'printReceiptOnAndroidTerminal', false)
        },
        {
          model: 'displayReceiptOnAndroidTerminal',
          component: 'v-switch',
          provider: {
            vid: 'displayReceiptOnAndroidTerminal',
            name: 'Display the check on the Android terminal (automatically)'
          },
          attrs: {
            label: 'Display the check on the Android terminal (automatically)',
            visible: _ => this.isAndroidForm
          },
          fieldVal: () => this._.get(this.item, 'displayReceiptOnAndroidTerminal', false)
        },
        {
          model: 'usage',
          component: 'v-switch',
          provider: {
            vid: 'usage',
            name: 'Connect'
          },
          attrs: {
            label: 'Connect'
          },
          fieldVal: () => this._.get(this.item, 'usage', true)
        }
      ]
    },
    fetchDevicesProtocolsMap () {
      return {
        [this.model.platforms.desktop.value]: 'http',
        [this.model.platforms.android.value]: 'https'
      }
    }
  },
  watch: {
    'form.ipAddress' (val) {
      if (!val) {
        return
      }
      if (!this.form.port) {
        return
      }
      this.debouncedGetPosTerminals()
    },
    'form.port' (val) {
      if (!val) {
        return
      }
      if (!this.form.ipAddress) {
        return
      }
      this.debouncedGetPosTerminals()
    },
    'form.platform' () {
      this.posTerminals = []
      this.debouncedGetPosTerminals()
    }
  },
  created () {
    this.debouncedGetPosTerminals = this._.debounce(this.fetchDevices, 500)
    this.$gtm.push({
      event: 'connect_pos_terminal_to_cashregister_dialog',
      organization: this._.get(this.$Organization, 'owner.email')
    })

    const platform = this._.get(this.item, 'platform')
    if (platform) {
      this.form.platform = platform
    }
  },
  methods: {
    async fetchDevices () {
      try {
        this.devicesLoading = true

        if (this.isAndroidForm) {
          const request = await PayLinkTerminals.api().all({ limit: 100 }, { save: false })
          this.posTerminals = this._.get(request, 'response.data.results')
        } else {
          const response = await fetch(PosTerminals.$routes.payLinkDevices(this.fetchDevicesProtocolsMap[this.form.platform], this.form.ipAddress, this.form.port), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              accept: 'application/json'
            }
          })
          this.posTerminals = await response.json()
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.devicesLoading = false
      }
    },
    async submit () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        const payload = {
          ...this.form,
          cashRegister: this._.get(this.form, 'cashRegister') ? `cash-registers/${this._.get(this.form, 'cashRegister.id', null)}` : null
        }

        if (this.form.payLinkTerminal !== undefined) {
          payload.terminalName = this.form.payLinkTerminal.label
          payload.payLinkTerminalId = this.form.payLinkTerminal.id
        }

        if (this.isEditing) {
          await this.model.api().update(this.item, payload)
          this.$notification.success(this.$t('Bank terminal settings have been successfully edited'))
        } else {
          await this.model.api().create(payload)
          this.$notification.success(this.$t('Bank terminal settings have been successfully created'))
        }

        this.$gtm.push({
          event: 'submit_pos_terminal_to_cashregister',
          organization: this._.get(this.$Organization, 'owner.email'),
          payload: JSON.stringify(payload)
        })
        await this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this.$refs.form)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pos-terminal-settings-form {
  &__actions {
    display: flex;
    justify-content: center;
    padding: 5px 0 20px;
    width: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__link {
    font-size: 14px;
    font-weight: 700;

    &::v-deep {
      .e-link {
        font-weight: 700;
      }
    }
  }

  &__radio-group {
    margin: 0 0 32px;

    &::v-deep {
      .v-input--radio-group__input {
        gap: 12px 24px;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
