<template lang="pug">
  div(class="dialog")
    div(class="dialog__img-wrap")
      img(
        src="~assets/images/racoons/racoon-key-cash-register.svg"
        class="dialog__img"
      )
    div(class="dialog__title") {{ title }}
    div(v-if="isValid" class="dialog__text") {{ text }}
    div(v-else class="dialog__text") {{ $t('Please replace the EDS key to continue working with the cash register.') }}
    div(class="dialog__actions")
      v-btn(
        class="secondary-button"
        color="black"
        @click="onRemindLateClick"
        outlined
      ) {{ $t('Remind me later') }}
      v-btn(
        class="main-button"
        @click="onReplaceKeyModal"
      ) {{ $t('Replace key') }}
</template>

<script>
export default {
  name: 'BlockReceiptEmployeeKeyEnd',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    keyDaysLeft: {
      type: Number,
      default: null
    },
    keyEndDate: {
      type: String,
      default: null
    },
    cashierLogin: {
      type: String,
      default: null
    },
    isValid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      if (this.isValid) {
        return this.$t('Days left', { daysLeft: this.keyDaysLeft })
      }
      return this.$t('This cashier`s EDS key has expired')
    },
    text () {
      return this.$t('Cashier message reminder', { login: this.cashierLogin, days: this.keyDaysLeft, endDate: this.keyEndDate })
    }
  },
  methods: {
    onRemindLateClick () {
      this.closeModal()
    },
    onReplaceKeyModal () {
      this.closeModal(true)
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__img-wrap {
    text-align: center;
  }

  &__img {
    display: block;
    margin: 0 auto;
    width: 200px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 112px;
    }
  }

  &__title {
    color: #161B25;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.24px;
  }

  &__text {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;

    color: rgba(0, 0, 0, 0.80);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 22px;
  }
}
</style>
