import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import AcquiringSettings from '~/modules/acquiring/models/AcquiringSettings'

export class InternetAcquiring extends PersistAble(OrmModel) {
  static entity = 'internetacquirings'
  static paginated = true
  static defaultSortOrder = true

  static ormTrans = {
    single: 'Internet Acquiring',
    multy: 'Internet Acquirings'
  }

  static fields () {
    return {
      id: this.attr(null),
      cashRegister: this.attr(null),
      dateCreated: this.attr(null),
      token: this.attr(null),
      name: this.attr(null),
      type: this.attr(null)
    }
  }

  get cashRegisterString () {
    const cashRegister = this.cashRegister
    return cashRegister ? `№${cashRegister.number} (${cashRegister.fiscalNumber})` : '-'
  }

  get terminalNameString () {
    return this.name || '-'
  }

  get terminalTypeString () {
    if (this.type === 'rozetkapay') {
      return 'Rozetka Pay'
    } else if (this.type === 'easy_pay') {
      return 'Easy Pay'
    }
    return 'Monobank'
  }

  static ormHeaders = [
    { text: 'Terminal name', value: 'terminalNameString', sortable: false, filterable: false },
    { text: 'Payment service', value: 'terminalTypeString', sortable: false, filterable: false },
    { text: 'Connected to cash register', value: 'cashRegisterString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'edit',
      call: (item) => {
        const dialog = Dialog.query().where('type', 'content').first()
        dialog.open({
          component: 'block-internet-acquiring-form',
          width: '900px',
          contentNoGutters: true,
          componentProps: {
            item,
            isEditing: true,
            tableRequest: async () => {
              if (item.type === 'monobank' || item.type === 'rozetkapay') {
                // we can't read mono INTERNET by `InternetAcquiring.api().read(item.id)` method
                return await InternetAcquiring.api().all()
              } else {
                return await InternetAcquiring.api().read(item.id)
              }
            }
          }
        })
      }
    },
    {
      name: 'delete',
      call: (item, data, ctx) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        dialog.open({
          title: item.type === 'qr' ? 'Are you sure you want to delete QR settings?' : 'Are you sure you want to delete Internet settings?',
          width: '460px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              if (item.type === 'monobank') {
                await AcquiringSettings.api().del(item)
                InternetAcquiring.delete(item.id)
              } else if (item.type === 'rozetkapay') {
                await InternetAcquiring.api().deleteRozetkaPaySettings(item?.id)
                await InternetAcquiring.api().all()
              } else {
                await InternetAcquiring.api().del(item)
              }
              AppNotifications.success('Internet setup successfully deleted')
            } catch (e) {
              ctx.$handlers.error(e, ctx)
            }
          }
        })
      }
    }
  ]

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.createRozetkaPaySettings = function (payload) {
        return this.post(Model.$routes.internetacquirings.rozetkaPay(), payload)
      }
      configActions.updateRozetkaPaySettings = function (id, payload) {
        return this.put(Model.$routes.internetacquirings.rozetkaPayConcrete(id), payload)
      }
      configActions.deleteRozetkaPaySettings = function (id) {
        return this.delete(Model.$routes.internetacquirings.rozetkaPayConcrete(id))
      }
      return configActions
    }
  }
}

export default InternetAcquiring
