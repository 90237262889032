<template lang="pug">
  div(class="rozetkapay-preview-info")
    h3(class="dialog__title mb-3 py-0") {{ $t('Get a POS terminal without unnecessary visits to the bank') }}
    p(class="rozetkapay-preview-info__text") {{ $t('In order not to go to the bank branch several times, we will now apply for a new POS terminal online') }}
</template>

<script>
export default {
  name: 'BlockRozetkapayPreviewInfo'
}
</script>

<style scoped lang="scss">
.rozetkapay-preview-info {
  background: rgba(67, 204, 180, 0.08);
  padding: 16px;
  border-radius: 12px;

  &__text {
    color: #000;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin: 0 0 4px;
  }
}
</style>
