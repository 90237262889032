<template lang="pug">
  e-models-cols-map(v-bind="colsMapAttrs")
</template>

<script>
import EModelsColsMap from '~/components/elements/selections/e-models-cols-map'

export default {
  name: 'BlockTapToPhoneStatus',
  components: {
    EModelsColsMap
  },
  computed: {
    item () {
      return this._.get(this.$attrs, 'item')
    },
    colsMapAttrs () {
      return {
        ...this.$attrs,
        chips: true,
        template: '{text}',
        map: this.colsMapFunc
      }
    },
    status () {
      if (this._.get(this.item, 'oschadbankAcquiringOrder.status') !== undefined) {
        return this._.get(this.item, 'oschadbankAcquiringOrder.status')
      }
      return this._.get(this.item, 'isActive')
    },
    colsMapFunc () {
      if (!this.item) {
        return
      }
      return {
        id: this.item.id,
        new: {
          text: 'New',
          type: 'created',
          appearance: {
            color: 'rgba(12,177,214,0.2)',
            textColor: '#0cb1d6'
          }
        },
        connected: {
          text: 'Connected',
          type: 'connected',
          appearance: {
            color: '#EAFFF5',
            textColor: '#039855'
          }
        },
        active: {
          text: 'Active ',
          type: 'active',
          appearance: {
            color: 'rgba(67,204,180,0.2)',
            textColor: '#43CCB4'
          }
        },
        deactivated: {
          text: 'Deactivated ',
          type: 'error'
        },
        send: {
          text: 'The application is being considered',
          type: 'send',
          appearance: {
            color: 'rgba(213,209,112,0.2)',
            textColor: 'rgb(197 191 55)'
          }
        },
        decline: {
          text: 'Decline',
          type: 'decline',
          appearance: {
            color: 'rgba(255,138,0,0.2)',
            textColor: '#ff8a00'
          }
        },
        error: {
          text: 'Error',
          type: 'error',
          appearance: {
            color: 'rgba(255,0,43,0.2)',
            textColor: '#ff002b'
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
