<template lang="pug">
  div(class="dialog")
    h2(class="dialog__title") {{ $t('Warning') }}!
    p(class="dialog__text") {{ $t('publicKeyIdVerificationError') }}
    div(class="dialog__actions")
      v-btn(
        class="secondary-button color-error"
        @click="closeModal(false)"
      ) {{ $t('Repeat') }}
      v-btn(
        class="main-button"
        @click="closeModal(true)"
      ) {{ $t('Confirm') }}
</template>

<script>
export default {
  name: 'BlockKeyVerificationWarning',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__title {
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .v-btn {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        width: calc(50% - 8px);
      }
    }
  }
}
</style>
