<template lang="pug">
  div(class="e-text-with-image d-flex align-center")
    template(v-if="_.get(item, 'image_url')")
      div(class="e-text-with-image__wrapper e-text-with-image__wrapper--preview-image mr-2" :style="imageStyles")
    template(v-else)
      div(class="e-text-with-image__wrapper mr-2")
        e-svg-icon(v-bind="_.get(icon, 'attrs')") {{ _.get(icon, 'name') }}
    span(:class="textClass") {{ value }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    ESvgIcon
  },
  props: {
    icon: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    },
    textClass: {
      type: String,
      default: ''
    },
    imageWidth: {
      type: String,
      default: '36'
    },
    imageHeight: {
      type: String,
      default: '24'
    }
  },
  computed: {
    imageStyles () {
      const thumbnail = this._.get(this.item, 'image_url', null)
      if (thumbnail) {
        return {
          backgroundImage: `url(${this.processedImageUrl(thumbnail)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      }
      return null
    }
  },
  watch: {
    item: {
      deep: true,
      handler (newItem, oldItem) {
        if (this._.get(newItem, 'image_url')) {
          if (newItem.image_url !== this._.get(oldItem, 'image_url')) {
            this.processedImageUrl(newItem.image_url)
          }
        }
      }
    }
  },
  methods: {
    processedImageUrl (imageUrl) {
      if (imageUrl) {
        const extPosition = imageUrl.lastIndexOf('.')
        const imageName = imageUrl.slice(0, extPosition)
        const imageExtension = imageUrl.slice(extPosition)

        return `${imageName}_${this.imageWidth}_${this.imageHeight}${imageExtension}?${Date.now()}`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.e-text-with-image {
  &__wrapper {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &--preview-image {
      background: #F0F1F2;
      border-radius: 6px;
    }
  }
}
</style>
