<template lang="pug">
  ValidationObserver(ref="form" slim)
    v-form(class="tap-to-phone-contact-form")
      t-orm-fields(v-bind.sync="dataForm" :items="fields")
      div(class="d-flex justify-end" :class="{ 'pt-8': !isResponsive, 'pt-5': isResponsive }")
        v-btn(
          class="main-button tap-to-phone-contact-form__action"
          :class="{ 'w-100': isResponsive }"
          :loading="loading"
          @click="submit"
        ) {{ $t('Send') }}
</template>

<script>
import KatottgSearch from '~/models/directories/search/KatottgSearch'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import Oschadbank from '~/modules/acquiring/models/Oschadbank'
import Outlets from '~/models/directories/Outlets'
import OutletsSearch from '~/models/directories/search/OutletsSearch'

export default {
  name: 'BlockTapToPhoneContactForm',
  components: {
    TOrmFields
  },
  props: {
    documents: {
      type: Array,
      required: true
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      dataForm: {
        name: null,
        phone: null,
        email: null,
        region: null,
        outletAddress: null
      },
      loading: false
    }
  },
  computed: {
    fields () {
      return [
        {
          model: 'name',
          component: 'v-text-field',
          provider: {
            name: 'First name',
            vid: 'name',
            rules: 'required'
          },
          fieldVal: () => {
            return this._.get(this.$Organization, 'shortName', '')
          },
          attrs: {
            label: 'First name',
            outlined: true,
            autocomplete: 'off'
          }
        },
        {
          model: 'phone',
          component: 'e-input-mask',
          provider: {
            name: 'Phone',
            vid: 'phone',
            rules: 'required|phone'
          },
          fieldVal: () => {
            return this._.get(this.$User, 'phone', '')
          },
          attrs: {
            label: 'Phone',
            outlined: true,
            mask: 'mobile',
            autocomplete: 'off'
          }
        },
        {
          model: 'email',
          component: 'v-text-field',
          provider: {
            name: 'Email',
            vid: 'email',
            rules: 'required|email'
          },
          fieldVal: () => {
            return this._.get(this.$User, 'email', '')
          },
          attrs: {
            label: 'Email',
            type: 'email',
            outlined: true,
            autocomplete: 'off'
          }
        },
        new AutocompleteBuilder({
          model: 'outletAddress',
          label: 'outlet',
          rules: 'required',
          query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode })
        }, OutletsSearch).build(),
        new AutocompleteBuilder({
          model: 'region',
          label: 'Region',
          itemClass: 'ws-pre',
          rules: 'required',
          useDefaultSearchFilter: true,
          itemsPerPage: 30,
          autocomplete: 'one-time-code',
          query: model => model.api().filter({ 'exists[parent]': false })
        }, KatottgSearch).build()
      ]
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    async submit () {
      const valid = await this.$refs.form.validate()

      if (valid) {
        try {
          this.loading = true

          this.dataForm.region = this.dataForm.region.name
          this.dataForm.outletAddress = this.dataForm.outletAddress.address
          const formData = new FormData()
          this.documents.forEach((document, idx) => formData.append(`file${idx + 1}`, document))
          Object.entries(this.dataForm).map(([key, value]) => {
            formData.append(key, value)
          })

          this._.get(await Oschadbank.api().createOrder(formData), 'response.data')

          await this.tableRequest()

          this.$gtm.push({
            event: 'submit_tap_to_phone',
            organization: this._.get(this.$Organization, 'owner.email')
          })

          this.$emit('nextStep', {
            prevStep: 'contactForm',
            nextStep: 'oschadOrderInfo'
          })
        } catch (e) {
          this.$handlers.error(e, this)
          this.closeModal()
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
