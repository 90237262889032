<template lang="pug">
  div()
    p(class="groups__upper-label")
      span(class="mr-1") 💡 {{ $t('Choose which tax groups you are interested in or create your own by clicking') }}
      v-btn(
        @click="$emit('showForm')"
        class="groups__btn"
        text
        x-small
      ) {{ $t('New tax rate') }}
    div(class="groups__cards")
      div(
        v-for="group in groups"
        :key="group.value"
        class="groups__card"
        :class="{ 'groups__card--disabled': group.disabled }"
        @click="selectGroup(group)"
      )
        span(class="groups__symbol") {{ group.tax.symbol }}
        span(class="groups__label") {{ group.tax.label }}
        v-checkbox(
          :value="isSelected(group)"
          class="mt-0"
          :disabled="loading || group.disabled"
          readonly
          hide-details
        )
    e-input-switch-tooltip(
      v-model="isDefault"
      label="Apply to all products"
      tooltip="The tax rate will be applied to all products that do not have their own tax rate"
      :disabled="loading"
    )
    v-btn(
      class="main-button mt-8 mb-8 d-block mx-auto"
      @click="save"
      :loading="loading"
    ) {{ $t('Save') }}
</template>

<script>
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Taxes from '~/modules/tax-rates/models/Taxes'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import EInputSwitchTooltip from '~/components/elements/inputs/e-input-switch-tooltip'

export default {
  components: {
    EInputSwitchTooltip,
    TOrmButtons,
    TOrmFields,
    TOrmLabel
  },
  props: {
    createdTaxRates: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    model: Taxes,
    selectedGroups: [],
    isDefault: false,
    loading: false
  }),
  computed: {
    createdTaxRatesSymbols () {
      return this._.uniq(this._.map(this.createdTaxRates, i => i?.symbol))
    },
    groups () {
      return [
        {
          value: 'pdv-20-excise',
          tax: {
            symbol: 'А',
            rate: 20,
            label: 'ПДВ + Акцизний збір',
            extraRate: 5
          },
          disabled: this.createdTaxRatesSymbols.includes('А')
        },
        {
          value: 'excise',
          tax: {
            symbol: 'Б',
            rate: 0,
            label: 'Акцизний збір',
            extraRate: 5
          },
          disabled: this.createdTaxRatesSymbols.includes('Б')
        },
        {
          value: 'pdv-7',
          tax: {
            symbol: 'В',
            rate: 7,
            label: 'ПДВ 7%'
          },
          disabled: this.createdTaxRatesSymbols.includes('В')
        },
        {
          value: 'pdv-14',
          tax: {
            symbol: 'Г',
            rate: 14,
            label: 'ПДВ 14%'
          },
          disabled: this.createdTaxRatesSymbols.includes('Г')
        },
        {
          value: 'pdv-0',
          tax: {
            symbol: 'Ґ',
            rate: 0,
            label: 'ПДВ 0%'
          },
          disabled: this.createdTaxRatesSymbols.includes('Ґ')
        },
        {
          value: 'pdv-20',
          tax: {
            symbol: 'Д',
            rate: 20,
            label: 'ПДВ 20%'
          },
          disabled: this.createdTaxRatesSymbols.includes('Д')
        },
        // {
        //   value: 'pdv-20',
        //   tax: {
        //     symbol: 'Ж',
        //     rate: 0,
        //     label: ''
        //   }
        // },
        {
          value: 'without-pdv',
          tax: {
            symbol: 'Є',
            rate: 0,
            label: 'Без ПДВ',
            noVat: true
          },
          disabled: this.createdTaxRatesSymbols.includes('Є')
        }
      ]
    }
  },
  methods: {
    isSelected (group) {
      const selected = this._.find(this.selectedGroups, i => i.value === group.value)
      return Boolean(selected)
    },
    selectGroup (group) {
      if (this.loading || group.disabled) {
        return
      }
      const selected = this._.find(this.selectedGroups, i => i.value === group.value)
      if (selected) {
        this.selectedGroups = this._.filter(this.selectedGroups, i => i.value !== group.value)
      } else {
        const selectedGroupsCount = this._.get(this.selectedGroups, 'length', 0) + 1
        const createdTaxRatesCount = this._.get(this.createdTaxRates, 'length', 0)
        if ((selectedGroupsCount + createdTaxRatesCount) > 8) {
          this.$notification.info(this.$t('The number of tax rates should not exceed 8. Delete unnecessary ones or correct already created ones'))
          return
        }
        this.selectedGroups.push(group)
      }
    },
    async save () {
      if (this.selectedGroups.length) {
        for (let i = 0; i < this.selectedGroups.length; i++) {
          try {
            this.loading = true
            const group = this.selectedGroups[i]
            const code = this._.get(await this.model.api().lastPrimaryKey(), 'response.data.code', null)
            const payload = {
              ...group.tax,
              code,
              isDefault: this.isDefault
            }
            await this.model.api().create(payload, false)

            if (i === this.selectedGroups.length - 1) {
              this.$notification.success(this.$t('Tax rates have been successfully saved'))
              this.loading = false
              this.$emit('dialog:close')
            }
          } catch (e) {
            this.$handlers.error(e, this)
            this.loading = false
          }
        }
      } else {
        this.$emit('dialog:close')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.groups {
  &__upper-label {
    font-weight: 300;
    color: #161B25;
    font-size: 12px;
    margin: 6px 0 16px;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 24px;
    margin-bottom: 32px;
  }

  &__card {
    background: #F7F7F7;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 13px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    width: calc(50% - 12px);

    &--disabled {
      pointer-events: none;
      opacity: 0.55;
    }
  }

  &__symbol {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  &__label {
    flex-grow: 1;
  }

  &__btn {
    font-size: 12px;
    color: #04AED4;
    background: #ECFBFF;
    font-weight: 300;
    border-radius: 6px;
    padding: 0 8px !important;
    height: 24px !important;
    margin-top: 2px;
  }
}
</style>
