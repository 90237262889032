<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('Not enough funds') }}
    p(class="dialog__text") {{ $t('There are not enough funds on your balance to send SMS') }}. {{ $t('You can continue issuing the check, but the SMS will not be sent') }}
    div(class="dialog__actions pb-0")
      v-btn(
        v-if="showTopUpButton"
        @click="handleTopUpBalance"
        dark
      ) {{ $t('Top up the balance') }}
      v-btn(
        @click="handleContinue"
        class="secondary-button"
        outlined
      ) {{ $t('Continue') }}
</template>

<script>
import { menuItems, menuItemsKeys } from '~/const/menuItems'

export default {
  name: 'BlockReceiptSaleSmsBalance',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    showTopUpButton () {
      const myOrganizationItem = this._.find(menuItems, i => i?.key === menuItemsKeys.myOrganization)
      if (myOrganizationItem && myOrganizationItem.visible(this.$User)) {
        const paymentChild = this._.find(myOrganizationItem.children, i => i?.href === '/dashboard/payment')
        if (paymentChild && paymentChild.visible(this.$User, this.$Organization)) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    handleContinue () {
      this.closeModal(true)
    },
    handleTopUpBalance () {
      this.$router.push({
        path: '/dashboard/payment',
        hash: '#openTopUpSmsBalanceDialog'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__title {
    padding-top: 32px;
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;

    &::v-deep {
      .v-btn {
        width: 193px;
      }
    }
  }
}
</style>
