<template lang="pug">
  m-dialog-form(:onSubmitCall="submit" class="login-form mb-2" content-class-name="px-8" ref="form")
    template(slot="header")
      v-row(align="center" justify="center")
        v-col(sm="12" class="text-center pb-0")
          h2(class="login-wrapper__title px-2") {{ $t('Registration') }}

    template(slot="default")
      v-row(align="center" justify="center")
        v-col(sm="12" class="pt-4")
          ValidationProvider(v-bind="fields.phone.provider" v-slot="{ errors }")
            e-input-mask(
              mask="mobile"
              v-model="formData.phone"
              :error-messages="errors"
              v-bind="fields.phone.attrs"
              class="app-outlined-input"
              outlined
            )
          ValidationProvider(v-bind="fields.email.provider" v-slot="{ errors }")
            v-text-field(
              v-model="formData.email"
              :error-messages="errors"
              v-bind="fields.email.attrs"
              class="app-outlined-input"
              outlined
            )
          ValidationProvider(v-bind="fields.firstName.provider" v-slot="{ errors }")
            v-text-field(
              v-model="formData.profile.firstName"
              :error-messages="errors"
              v-bind="fields.firstName.attrs"
              class="app-outlined-input"
              outlined
            )
          ValidationProvider(v-bind="fields.password.provider" v-slot="{ errors }")
            e-input-password(
              v-model="formData.password"
              :error-messages="errors"
              v-bind="fields.password.attrs"
              class="app-outlined-input"
              outlined
            )
          ValidationProvider(v-bind="fields.passwordConfirmation.provider" v-slot="{ errors }")
            e-input-password(
              v-model="formData.passwordConfirmation"
              :error-messages="errors"
              v-bind="fields.passwordConfirmation.attrs"
              class="app-outlined-input"
              outlined
            )

          div(class="pt-0 mt-1")
            span(class="login-form__text mr-1") {{ $t('By clicking') }} “{{ $t('Continue') }}”, {{ $t('you agree to the terms of the') }}
            e-link(
              class="login-form__link"
              href="https://checkbox.ua/publichna-oferta/"
            ) {{ $t('Public offer') }}

        v-col(cols="12" class="text-center pb-4")
          e-button-main(
            :enableIcon="false"
            :loading="loading"
            type="submit"
          ) {{ $t('Continue') }}
    template(slot="footer")
      div(class="login-form__footer")
        | {{ $t('Already have an account?') }}
        e-link(@click="$router.push('/auth/login')") {{ $t('Log in') }} ->

</template>

<script>
import auth from '~/modules/auth/auth'
import AdTag from '~/modules/advertising/models/AdTag'
import AdService from '~/modules/advertising/servises/AdService'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import MDialogForm from '~/components/modules/forms/m-dialog-form'
import ELink from '~/components/elements/links/e-link'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import EInputPassword from '~/components/elements/inputs/e-input-password'
import EInputMask from '~/components/elements/inputs/e-input-mask'

export default {
  name: 'MBlockRegisterLoginData',
  components: {
    EInputMask,
    MDialogForm,
    TOrmFields,
    ELink,
    EButtonMain,
    EFlatIcon,
    EInputPassword
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    fields () {
      return {
        phone: {
          provider: {
            name: this.$t('Phone'),
            vid: 'phone',
            rules: 'required|phone',
            mode: 'lazy'
          },
          attrs: {
            label: this.$t('Phone'),
            outlined: true
          }
        },
        email: {
          component: 'v-text-field',
          provider: {
            name: this.$t('E. mail'),
            vid: 'email',
            rules: 'required|email',
            mode: 'lazy'
          },
          attrs: {
            label: this.$t('E. mail'),
            type: 'email',
            outlined: true
          }
        },
        firstName: {
          component: 'v-text-field',
          provider: {
            name: this.$t('First name'),
            vid: 'firstName',
            rules: 'required',
            mode: 'lazy'
          },
          attrs: {
            label: this.$t('First name'),
            outlined: true
          }
        },
        password: {
          component: 'e-input-password',
          provider: {
            name: this.$t('Password'),
            vid: 'password',
            rules: 'required|min:6',
            mode: 'lazy'
          },
          attrs: {
            label: this.$t('Password'),
            type: 'password',
            outlined: true,
            showPasswordStrength: true
          }
        },
        passwordConfirmation: {
          component: 'e-input-password',
          provider: {
            name: this.$t('Confirm password'),
            vid: 'passwordConfirmation',
            rules: 'required|confirmed:password',
            mode: 'lazy'
          },
          attrs: {
            label: this.$t('Confirm password'),
            type: 'passwordConfirmation',
            outlined: true
          }
        }
      }
    }
  },
  methods: {
    submit () {
      return {
        onSuccess: async () => {
          this.loading = true
          this.formData.login = this.formData.email

          if (AdTag.query().all()) {
            await AdService.removeExpired()
            this.$set(this.formData, 'adTags', AdTag.query().all())
          }

          this.$store.dispatch('user/registration', this.formData).then(async () => {
            this.$notification.success(this.$t('You are successfully registered!'))
            await auth.login(this.formData.login, this.formData.password)
            this.$attrs.btnCall(this.$attrs.step, null)
          }).catch((e) => {
            const form = this._.get(this.$refs, 'form.$refs.form')
            this.$handlers.error(e, form || this)
          }).finally(() => {
            this.loading = false
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-form {
  margin: 0 auto;
  max-width: 548px;
  border-radius: 24px;
  overflow: hidden;

  &__text {
    font-size: 13px;
    line-height: 22px;
  }

  &__link {
    font-weight: 700;
    font-size: 13px !important;
  }

  &__footer {
    color: rgba(22, 27, 37, 0.6);
    text-align: center;
    font-size: 13px;
    line-height: 1rem;

    button {
      color: rgba(22, 27, 37, 1);
      margin-left: 5px;
      font-weight: 700;
    }
  }

  &::v-deep {
    .v-card {
      padding-top: 20px;
    }

    .v-text-field--outlined fieldset {
      border-radius: 12px;
    }

    .e-button-main {
      width: 100%;
      min-height: 48px;
    }
  }
}
</style>
