import PersistAble from '../mixins/PersistAble'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import i18n from '~/plugins/nuxt-i18n/i18n'

export default class PromoCodeOrganizations extends ChainInheritance(Directory, [PersistAble]) {
  static entity = 'promocodeorganizations'
  static defaultSortParam = 'dateCreated'
  static defaultSortOrder = false
  static paginated = true

  static ormTrans = {
    single: 'Promo code',
    multy: 'Promo codes'
  }

  static fields () {
    return {
      id: this.attr(null),
      promoCode: this.attr(null),
      dateCreated: this.attr(null),
      used: this.attr(null)
    }
  }

  static ormColsComponents = {
    usedInversion: {
      component: 'e-radio-status'
    }
  }

  get dateString () {
    return this.getDateTime(this.dateCreated)
  }

  get usedInversion () {
    return !this.used
  }

  get usedText () {
    return this.used ? i18n.t('Yes') : i18n.t('No')
  }

  get userCreator () {
    return this.promoCode.crmUserName ? this.promoCode.crmUserName : this.promoCode.user.email
  }

  static ormDialogs = {
    read: 'm-orm-table-dialog'
  }

  static ormHeaders = [
    { text: 'Applied', value: 'usedText', sortable: true, filterable: false, sortQuery: 'used' },
    { text: 'Promo code purpose', value: 'promoCode.promoCodePurpose', sortable: false, filterable: false },
    { text: 'Promo code creator', value: 'userCreator', sortable: false, filterable: false },
    { text: 'Promo code value', value: 'promoCode.value', sortable: false, filterable: false },
    { text: 'Promo code months', value: 'promoCode.months', sortable: false, filterable: false },
    { text: 'Date of created', value: 'dateString', sortable: true, filterable: true, sortQuery: 'dateCreated' }
  ]

  static ormActions = []
}
