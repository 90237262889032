import moment from 'moment-timezone'
import { DateTime } from '~/services/_utils/DateTime'
import { formats } from '~/const/global'

const dateTime = {
  computed: {
    yesterday () {
      return this.$moment().subtract(1, 'day').format(formats.dateISO8601)
    },
    today () {
      return this.$moment().format(formats.dateISO8601)
    },
    nowDatetime () {
      const date = new Date()
      return moment(date).format(formats.dateTimeSec)
    }
  },
  methods: {
    toTime (date) {
      return DateTime.toTime(date)
    },
    formatTime (time, format = formats.dateTime) {
      if (!time) { return null }
      // TODO Временное решение
      return moment(new Date(time)).tz('Europe/Kiev').format(format)
    }
  }
}

export default dateTime
