<template lang="pug">
  div(class="rozetkapay-sign-info")
    div(class="rozetkapay-sign-info__content")
      p(class="rozetkapay-sign-info__text") {{ $t('Application: Get a POS terminal') }}
      p(class="rozetkapay-sign-info__legend") {{ $t('IE') }}
      p(class="rozetkapay-sign-info__text") {{ _.get($Organization, 'shortName') || '–' }}
      p(class="rozetkapay-sign-info__legend") {{ $t('TIN/EDRPOU') }}
      p(class="rozetkapay-sign-info__text") {{ _.get($Organization, 'edrpou') }}
    div(class="rozetkapay-sign-info__label") {{ $t('Ready to sign') || '–' }}
</template>

<script>
export default {
  name: 'BlockRozetkapaySignInfo',
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.rozetkapay-sign-info {
  padding: 24px 0;
  background: rgba(67, 204, 180, 0.08);
  border-radius: 12px;

  &__label {
    padding: 8px 16px;
    background: $focus-color-2;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

  &__content {
    padding: 0 16px;
  }

  &__text {
    font-size: 14px;
    font-weight: 700;
    color: $btn-secondary-color;
    margin: 0 0 24px;
  }

  &__legend {
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 4px;
    color: rgba(22, 27, 37, 0.5);
  }
}
</style>
