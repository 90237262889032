<template lang="pug">
  div(class="pt-8 pb-10")
    ValidationObserver(ref="form" slim)
      t-orm-fields(v-bind.sync="dataForm" :items="fields")
    div(class="d-flex justify-center")
      v-btn(
        class="main-button"
        :loading="loading"
        @click="submit"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Billings from '~/models/billing/Billings'
import currencyFormat from '~/mixins/filters/currencyFormat'

export default {
  name: 'MFormBillingSetNewDiscount',
  components: {
    TOrmFields
  },
  mixins: [currencyFormat],
  props: {
    billingId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dataForm: {
        startAt: null,
        endAt: null,
        amount: null
      },
      loading: false
    }
  },
  computed: {
    fields () {
      return [
        {
          model: 'startAt',
          component: 'e-input-datetime',
          provider: {
            vid: 'startAt',
            name: 'Select start date',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Select start date'
          }
        },
        {
          model: 'endAt',
          component: 'e-input-datetime',
          provider: {
            vid: 'endAt',
            name: 'Select end date'
          },
          attrs: {
            outlined: true,
            label: 'Select end date'
          }
        },
        {
          model: 'amount',
          component: 'v-text-field',
          provider: {
            vid: 'amount',
            name: 'Discount (in percentage)',
            rules: 'required'
          },
          attrs: {
            label: 'Discount (in percentage)',
            outlined: true,
            type: 'number'
          }
        }
      ]
    }
  },
  methods: {
    async submit () {
      const valid = this.$refs.form.validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true

        const payload = {
          ...this.dataForm,
          amount: +this.dataForm.amount,
          billing: `/billings/${this.billingId}`
        }

        await Billings.api().updateDiscount(payload)
        this.$emit('onSuccess')
      } catch (e) {
        this.$emit('onError')
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
