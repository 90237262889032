<template lang="pug">
  div(class="rozetkapay-preview-info")
    h3(class="dialog__title pb-0") {{ $t('You have already applied for a RozetkaPay terminal') }}
</template>

<script>
export default {
  name: 'BlockRozetkapayPreviewInfo'
}
</script>

<style scoped lang="scss">
.rozetkapay-preview-info {
  &__text {
    color: #000;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin: 0 0 4px;
  }
}
</style>
