<template lang="pug">
  div(class="oschadbank-preview-info")
    div(class="oschadbank-preview-info__content")
      div(class="oschadbank-preview-info__content-wrapper")
        p(class="oschadbank-preview-info__text") {{ $t('Get the opportunity to receive card payments on your smartphone. Send funds to any bank convenient for you.') }}
          |
          |
          span(class="oschadbank-preview-info__text--bold") {{ $t('The lowest commission') }}
          |
          | {{ $t('for acquiring among all banks - 1.3%. No rent or other additional payments.') }}
        p(class="oschadbank-preview-info__text")
          |
          span(class="oschadbank-preview-info__text--bold") {{ $t('Warning') }}!
          |
          | {{ $t('The service is only available for Android mobile devices with NFC') }}
      v-img(v-if="isResponsive" :src="require('~/assets/images/oschad-tap-to-phone-mobile-2.png')" width="100%")
    div(class="oschadbank-preview-info__actions")
      v-btn(
        class="main-button oschadbank-preview-info__action"
        @click="btnClick"
      ) {{ $t('Continue') }}
</template>

<script>
export default {
  name: 'BlockOschadbankPreviewInfo',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    btnClick () {
      this.$emit('nextStep', {
        prevStep: 'oschadPreview',
        nextStep: 'uploadDocuments'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.oschadbank-preview-info {
  &__title {
    color: #161B25;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.24px;
  }

  &__content {
    border-radius: 12px;
    background: #F7F7F7;
    margin: 24px 0;

    @media (min-width: map-get($breakpoints, 'sm')) {
      margin: 32px 0;
    }

    &-wrapper {
      padding: 20px;

      @media (min-width: map-get($breakpoints, 'sm')) {
        padding: 24px;
      }
    }
  }

  &__text {
    color: #000;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;

    &--bold {
      font-weight: 700;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
