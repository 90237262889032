<template lang="pug">
  div(class="h-100")
    v-expansion-panels(v-if="mobile")
      v-expansion-panel(class="hints-block-mobile")
        v-expansion-panel-header {{ title }}
        v-expansion-panel-content
          template(v-if="acquiringType === 'tap2phone'")
            block-tap-to-phone-hint-content(
              :steps="steps"
              :currentStep="currentStep"
              mobile
              :isEditing="isEditing"
            )
          template(v-else-if="acquiringType === 'qr'")
            block-qr-hint-content(
              :steps="steps"
              :currentStep="currentStep"
              mobile
              :isEditing="isEditing"
            )
          template(v-else-if="acquiringType === 'internet'")
            block-internet-hint-content(
              :steps="steps"
              :currentStep="currentStep"
              mobile
              :isEditing="isEditing"
            )
    div(
      class="hints-block"
      :class="{'px-8 py-10': !isImageHint }"
      v-else
    )
      template(v-if="acquiringType === 'tap2phone'")
        block-tap-to-phone-hint-content(
          :steps="steps"
          :currentStep="currentStep"
          :isEditing="isEditing"
        )
      template(v-else-if="acquiringType === 'qr'")
        block-qr-hint-content(
          :steps="steps"
          :currentStep="currentStep"
          :isEditing="isEditing"
          @setTokenManual="$emit('setTokenManual')"
        )
      template(v-else-if="acquiringType === 'internet'")
        block-internet-hint-content(
          :steps="steps"
          :currentStep="currentStep"
          :isEditing="isEditing"
          @setTokenManual="$emit('setTokenManual')"
        )
</template>

<script>
import BlockTapToPhoneHintContent from '~/modules/acquiring/views/block-tap-to-phone-hint-content'
import BlockQrHintContent from '~/modules/acquiring/views/qr-acquiring/block-qr-hint-content'
import BlockInternetHintContent from '~/modules/acquiring/views/internet-acquiring/block-internet-hint-content'
export default {
  name: 'BlockAcquiringFormHint',
  components: {
    BlockInternetHintContent,
    BlockQrHintContent,
    BlockTapToPhoneHintContent
  },
  props: {
    acquiringType: {
      type: String,
      default: null,
      validator: (value) => {
        return ['qr', 'tap2phone', 'internet'].includes(value)
      }
    },
    steps: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return `💡 ${this.$t('Hint')}`
    },
    isImageHint () {
      return [this.steps.oschadPreview, this.steps.oschadOrderInfo].includes(this.currentStep)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
