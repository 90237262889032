import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class Merchants extends PersistAble(OrmModel) {
  static entity = 'merchants'
  static paginated = true
  static defaultSortOrder = true

  static ormTrans = {
    single: 'Merchant',
    multy: 'Merchants'
  }

  static fields () {
    return {
      id: this.attr(null),
      clientLogin: this.attr(null),
      clientPhone: this.attr(null),
      clientUsername: this.attr(null),
      provider: this.attr(null),
      monoAccessToken: this.attr(null)
    }
  }

  static ormHeaders = []

  static ormFilters = []

  static ormActions = []

  static ormDialogs = {}

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.oschad = function (payload) {
        return this.post(Model.$routes.merchants.oschad(), payload, { save: false })
      }
      return configActions
    }
  }
}

export default Merchants
