<template lang="pug">
  div(class="dialog add-notification")
    div(class="dialog__title pb-0 mb-8") {{ title }}
    v-form(@submit.prevent.stop="addNotification")
      ValidationObserver(
        ref="form"
        slim
      )
        ValidationProvider(
          :name="$t('Title')"
          vid="title"
          mode="lazy"
          rules="required"
          v-slot="{ errors }"
        )
          v-text-field(
            :label="$t('Title')"
            v-model="data.title"
            :error-messages="errors"
            outlined
          )
        ValidationProvider(
          :name="$t('Message')"
          vid="message"
          mode="lazy"
          rules="required"
          v-slot="{ errors }"
        )
          wysiwyg(
            v-model="data.message"
            :placeholder="$t('Message')"
            :class="{ 'add-notification__editor--errors': errors.length }"
          )
          div(class="add-notification__errors") {{ errors[0] }}
        t-orm-fields(
          v-bind.sync="data"
          :items="fields"
          class="mt-3"
        )
        ValidationProvider(
          vid="file"
          v-if="isFileUpload"
          rules="required|mimes:text/csv"
          v-slot="{ errors }"
          :name="$t('CSV file with organizations')"
        )
          v-file-input(
            name="file"
            :label="$t('CSV file with organizations')"
            accept="text/csv"
            v-model="data.file"
            appendIcon="$file"
            :error-messages="errors"
            prependIcon=""
            outlined
          )
      div(class="dialog__actions")
        v-row(justify="center")
          v-col(
            cols="auto"
            class="pa-0"
          )
            v-btn(
              class="main-button"
              :loading="loading"
              type="submit"
            ) {{ btnText }}
    v-dialog(v-if="isFileUpload" v-model="loading" persistent max-width="400px" )
      v-card(style="padding-top:16px;padding-bottom:16px;")
        v-card-title
          span {{ $t('Sending notifications') }}
        v-card-text
          e-process-progress(:value="processedItemsValue" :current="processedItems" :total="totalItems")
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import dateTime from '~/mixins/datetime/dateTime'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import OrganizationSearch from '~/models/directories/search/OrganizationSearch'
import Notifications from '~/modules/notifications/models/Notifications'
import { formats } from '~/const/global'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import EProcessProgress from '~/components/elements/progress/e-process-progress'
import wait from '~/mixins/methods/wait'

export default {
  name: 'BlockAddNotification',
  components: {
    TOrmFields,
    EProcessProgress
  },
  mixins: [dateTime, wait],
  props: {
    item: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'create'
    },
    closeModal: {
      type: Function,
      default: null
    },
    isFileUpload: {
      type: Boolean,
      default: false,
      required: false
    },
    onSuccess: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    data: {
      file: null,
      title: null,
      message: null,
      organization: null,
      availableAt: null,
      priority: 'low',
      status: 'published'
    },
    loading: false,
    request: null
  }),
  computed: {
    model () {
      return Notifications
    },
    title () {
      return this.type === 'edit' ? this.$t('Edit notification') : this.$t('New notification')
    },
    btnText () {
      return this.type === 'edit' ? this.$t('Save') : this.$t('Create')
    },
    priorities () {
      return Object.values(this.model.priorities)
    },
    statuses () {
      return Object.values(this.model.statuses)
    },
    processedItemsValue () {
      return Math.round(this.processedItems / (this.totalItems / 100)) || 0
    },
    processedItems () {
      return this._.get(this.request, 'response.response.data.processedCount', 0)
    },
    totalItems () {
      return this._.get(this.request, 'response.response.data.totalCount', 0)
    },
    fields () {
      return [
        {
          model: 'priority',
          component: 'v-select',
          provider: {
            vid: 'priority',
            name: 'Priority',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Priority'
          },
          items: () => this.priorities
        },
        {
          model: 'status',
          component: 'v-select',
          provider: {
            vid: 'status',
            name: 'Status',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Status',
            disabled: this.isFileUpload
          },
          items: () => this.statuses
        },
        {
          model: 'availableAt',
          component: 'e-input-datetime',
          provider: {
            vid: 'availableAt',
            name: 'Date the notification was sent',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Date the notification was sent',
            type: 'datetime'
          },
          fieldVal: () => this.data.availableAt || this.nowDatetime
        },
        new AutocompleteBuilder({
          model: 'organization',
          label: 'organization',
          returnObject: true,
          itemClass: 'ws-pre',
          cacheItems: true,
          useDefaultSearchFilter: true,
          chipText: item => this._.get(item, 'name', '-'),
          itemValue: item => this._.get(item, 'id', null),
          query: model => model.api(),
          visible: () => !this.isFileUpload,
          asyncDefault: () => this._.get(this.item, 'organization', [])
        }, OrganizationSearch).build()
      ]
    }
  },
  created () {
    this.fillData()
  },
  methods: {
    fillData () {
      if (this.item) {
        for (const key in this.item) {
          const dataKeys = Object.keys(this.data)
          if (dataKeys.includes(key)) {
            let val = this._.get(this.item, key, null)
            if (key === 'availableAt') {
              val = this.$moment(val).format(formats.dateTimeSec)
            }
            if (key !== 'organizations') {
              this.$set(this.data, key, val)
            }
          }
        }
      }
    },
    close (data) {
      if (this._.isFunction(this.closeModal)) {
        this.closeModal(data || this.data)
      }
    },
    async addNotification () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }

        const organization = this._.get(this.data, 'organization', null)
        const orgId = organization ? `organizations/${organization.id}` : null
        const payload = { ...this.data, organization: orgId }

        if (this.isFileUpload) {
          delete payload.organization
          delete payload.status
        } else {
          delete payload.file
        }

        if (this.type === 'create') {
          if (this.isFileUpload) {
            const response = await this.model.api().createNotificationFromFile(payload)
            const taskId = this._.get(response, 'response.data.id', null)
            const intervalRequest = new IntervalRequest(() => this.model.api().checkNotificationStatus(taskId), {
              maxDelay: 3e5 // ~5 хвилин
            })
            this.request = intervalRequest
            const resolveStatus = 'SUCCESS'
            const resolveCondition = ({ response }) => response.data.status === resolveStatus
            const resolveResponse = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data', null)
            await this.wait(2000)
            if (this._.get(resolveResponse, 'status') === resolveStatus) {
              await this.onSuccess()
            }
          } else {
            await this.model.api().createNotification(payload)
          }
          this.$notification.success(this.$t('Alert successfully created'))
        } else if (this.type === 'edit') {
          await this.model.api().updateNotification(this._.get(this.item, 'id'), payload)
          this.$notification.success(this.$t('Alert successfully edited'))
        }

        this.close()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.add-notification {
  &__errors {
    padding: 0 12px;
    color: #ff1744;
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
    min-height: 14px;
  }

  &__editor {
    &--errors {
      border: 2px solid #ff1744 !important;
    }
  }
}
</style>
