<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" :max-width="cardWidth" :loading="isLoading")
    template(#title) {{ title }}
    template(#content)
      div(v-if="isLoading" class="preload")
      div(v-else)
        m-form-accounting-goods(:context="context" :selected-item="editedItem" :item="item" v-bind="extraParams" @dialog:close="close")
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import fill from '~/mixins/modules/dialogs/fill'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import MFormAccountingGoods from '~/modules/goods/views/components/m-form-accounting-goods'

export default {
  components: {
    MFormAccountingGoods,
    TDialog
  },
  mixins: [checkPropCtx, fill],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    extraParams: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    itemLoading: false,
    editedItem: {},
    item: {}
  }),
  computed: {
    dialogCtx () {
      return Object.assign({}, {
        parent: this.selectedItem,
        dialogRefs: this.$refs,
        model: this.model.entity
      }, { ...this.ctx })
    },
    dialogConfig () {
      return {
        ...this.model.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    cardWidth () {
      return [860, 'px'].join('')
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    title () {
      // TODO: create one logic for pattern generation.
      let title, name

      if (this.dialogConfig.title) {
        title = { ...this.dialogConfig.title.split('|') }
      }

      // TODO: move to class config???
      if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
        name = this.dialogConfig.config.modalName
      } else {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'Add'
            break
          case this.$config.contexts.read:
            name = 'Card of'
            break
          case this.$config.contexts.update:
            name = 'Editing of'
            break
        }
      }

      const modalType = (() => {
        if (title) {
          return this.$tc(title[0], title[1])
        } else if (this.dialogConfig.config && this.dialogConfig.config.ucFirst) {
          return this._.upperFirst(this.$tc(this.model.ormTrans.single, 2))
        } else if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
          return this.$tc(this.model.ormTrans.single, 1)
        } else {
          return this.$tc(this.model.ormTrans.single, 2)
        }
      })()

      return [
        this.$t(name),
        modalType,
        (this.dialogConfig.config && this.dialogConfig.config.ucFirst) ? this.selectedItem.name : ''
      ].join(' ').trim(' ')
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    clear () {
      setTimeout(() => {
        this.parentContext = this.$config.contexts.create
        this.item = null
        this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
      }, 300)
    },
    close () {
      this.$emit('input', false)
      this.clear()
    },
    async afterFill (context, item) {
      if (!this.$isCreateCtx(context)) {
        try {
          this.itemLoading = true
          const itemId = this._.get(item, 'id')
          await this.model.api().read(itemId)
          this.item = this.model.query().withAll().whereId(itemId).first() || {}
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.itemLoading = false
        }
      }
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ from: item, ctx: this.context }))
    }
  }
}
</script>

<style scoped lang="scss">
.preload {
  height: 400px;
}
</style>
