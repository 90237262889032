<template lang="pug">
  div(class="rozetkapay-status")
    v-tooltip(
      :disabled="!tooltipText"
      top
    )
      template(#activator="{ on, attrs }")
        v-chip(
          v-bind="orderStatusColors"
          v-on="on"
          @click="handleAction"
          small
        )
          span {{ $t(orderStatusText) }}
      span() {{ $t(tooltipText) }}
</template>

<script>
import EModelsColsMap from '~/components/elements/selections/e-models-cols-map'
import contentDialog from '~/mixins/dialogs/contentDialog'
import rozetkaPay from '~/modules/acquiring/mixins/rozetkaPay'

export default {
  name: 'BlockRozetkapayStatus',
  components: {
    EModelsColsMap
  },
  mixins: [contentDialog, rozetkaPay],
  computed: {
    item () {
      return this._.get(this.$attrs, 'item')
    },
    orderStatus () {
      return this._.get(this.item, 'orderStatusExternal')
    },
    orderStatusText () {
      return this.$t(this.statusText[this.orderStatus])
    },
    tooltipText () {
      return 'Click to view order status'
    },
    orderStatusColors () {
      if (this.orderStatus === this.model.STATUSES_EXTERNAL.declined || this.orderStatus === this.model.STATUSES_EXTERNAL.failed) {
        return this.colorsMap.error
      } else if (this.orderStatus === this.model.STATUSES_EXTERNAL.activated) {
        return this.colorsMap.success
      }
      return this.colorsMap.alert
    },
    colorsMap () {
      return {
        alert: {
          color: 'rgba(255, 170, 1, 0.2)',
          textColor: '#c37f00'
        },
        error: {
          color: 'rgba(255, 0, 43, 0.1)',
          textColor: '#ff002b'
        },
        success: {
          color: 'rgba(67,204,180,0.2)',
          textColor: '#43CCB4'
        }
      }
    }
  },
  methods: {
    async handleAction () {
      await this.contentDialog.open({
        component: 'block-rozetkapay-status-extended',
        width: '900px',
        contentNoGutters: true,
        componentProps: {
          item: this.item
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.rozetkapay-status {
  &::v-deep {
    .v-chip {
      border-radius: 8px;
      padding: 0 7px;
      font-size: .69rem;
      height: 28px;
      width: 100%;
      text-align: center;
      display: block;
      cursor: pointer;
    }
  }
}
</style>
