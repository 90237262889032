<template lang="pug">
  e-autocomplete(
    :customQuery="_ => true"
    :item="autocompleteItem"
    :class="classes"
    :loading="loading"
    @change="setOrganization"
    background-color="transparent"
    :no-data-text="$t('Organization not found ')"
    :label="currentOrganization.name"
    :menu-props="menuProps"
    @blur="handleOnBlur"
    @focus="$emit('focus')"
    item-value="id"
    ref="autocomplete"
    dense
    solo
    flat
    return-object
    hide-details
  )
</template>

<script>
import auth from '~/modules/auth/auth'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'
import taxAuth from '~/mixins/pages/tax-auth'
import multiAccountUsers from '~/mixins/organization/multiAccountUsers'
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import responsive from '~/mixins/pages/responsive'
import ManagedOrganizationsSearch from '~/models/directories/search/ManagedOrganizationsSearch'

export default {
  components: {
    EAutocomplete
  },
  mixins: [taxAuth, multiAccountUsers, responsive],
  data () {
    return {
      showInput: false,
      loading: false,
      current: this.currentOrganization
    }
  },
  computed: {
    autocompleteItem () {
      return {
        attrs: {
          itemClass: 'ws-pre',
          returnObject: true,
          requestOnMounted: false,
          itemsFilter: items => this.listOrganizations(items),
          initialTotalItems: this._.get(this.$User, 'managedOrganizationsTotalItems'),
          showLoader: true,
          loaderColor: '#161b25'
        },
        searchModel: ManagedOrganizationsSearch,
        asyncDefault: (items) => {
          const foundItem = this._.find(items, i => i?.id === this.currentOrganization?.id)
          return foundItem || this.currentOrganization
        },
        query: (model, ctx, config) => model.api().getByUser(this._.get(this.$User, 'id'), config)
      }
    },
    menuProps () {
      return {
        offsetY: true,
        zIndex: 999,
        contentClass: 'organization-change-menu',
        maxWidth: this.xsDevice ? 300 : 400
      }
    },
    classes () {
      return [
        'mt-0', 'e-input-organization-change',
        {
          'e-input-organization-change--disabled': this.disabled
        }
      ]
    },
    disabled () {
      return this.listOrganizations().length <= 1
    },
    ownedOrganization () {
      return {
        ...this._.get(this.$User, 'ownedOrganization', {}),
        toString () {
          return this.name
        }
      }
    },
    allOrganizationsItem () {
      return {
        name: this.$t('All organizations'),
        value: 'globalAllOrganizationsSelected',
        toString () {
          return this.name
        }
      }
    },
    currentOrganization () {
      if (this._.get(this.$User, 'globalAllOrganizationsSelected')) {
        return this.allOrganizationsItem
      }
      return this.$Organization
    }
  },
  methods: {
    reloadItemsOnSearch () {
      const autocomplete = this._.get(this.$refs, 'autocomplete')
      if (autocomplete && autocomplete.debouncedSearchString) {
        autocomplete.setSearchString(null)
      }
    },
    handleOnBlur () {
      this.reloadItemsOnSearch()
      this.$emit('blur')
    },
    listOrganizations (items) {
      const arr = [].concat(items)
      const isSearching = this._.get(this.$refs, 'autocomplete.debouncedSearchString')

      if (!isSearching) {
        if (this._.get(this.$User, 'ownedOrganization')) {
          arr.unshift(this.ownedOrganization)
        }
        if (this.isMultiAccountUser) {
          arr.unshift(this.allOrganizationsItem)
        }
      }

      return arr || []
    },
    setGlobalAllOrganizationsSelected (val) {
      this.$setLocalStorageClientData({ globalAllOrganizationsSelected: val })
      location.reload()
    },
    async setOrganization (organization) {
      await this.reloadItemsOnSearch()
      if (!organization || (organization.id === this.currentOrganization.id)) {
        return
      }
      if (this._.get(organization, 'value') === 'globalAllOrganizationsSelected') {
        this.setGlobalAllOrganizationsSelected(true)
      } else {
        await currentOrganizationService.setCurrentOrganization(organization)
        if (this._.get(this.$localStorageClientData(), 'globalAllOrganizationsSelected')) {
          this.setGlobalAllOrganizationsSelected(false)
        }
      }
      await Promise.all(auth.deleteCommonEntities())
      this.destroyIitGlobalDialog()
      this.$emit('change', this.currentOrganization)
    }
  }
}
</script>

<style lang="scss">
.organization-change-menu {
  width: 100%;

  .v-list-item__title {
    white-space: normal;
  }
}
</style>

<style scoped lang="scss">
.e-input-organization-change {
  font-size: 12px !important;
  padding: 8px !important;
  background: #2D323B;
  border-radius: 8px;
  position: relative;
  z-index: 2;

  &__selection {
    max-width: 100%;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 11px;
    max-height: 38px;
    font-weight: 400;
  }

  &::v-deep {
    .v-input {
      &__slot {
        padding: 0 !important;
      }

      &__control {
        margin: 0;
      }
    }

    .v-select {
      &__slot {
        font-size: 10px;
        cursor: pointer;

        input {
          text-overflow: ellipsis;
        }

        .v-label {
          font-size: 10px !important;
          color: #fff !important;
        }
      }
    }
  }

  &--disabled {
    pointer-events: none;

    ::v-deep .v-input__append-inner {
      display: none;
    }
  }
}
</style>
