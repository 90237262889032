import contentDialogTop from '~/mixins/dialogs/contentDialogTop'

const images = {
  mixins: [contentDialogTop],
  data () {
    return {
      image: {
        src: null,
        type: null
      },
      imageBlob: null,
      previewImage: null
    }
  },
  computed: {
    accept () {
      return 'image/png,image/jpg,image/jpeg'
    },
    validationAccept () {
      return 'jpg,jpeg,png'
    },
    rules () {
      const rules = 'image|size:1000|ext:'

      return rules + this.validationAccept
    }
  },
  watch: {
    files (files) {
      if (files && files.length) {
        this.handleImage(files[0])
      }
    },
    image: {
      deep: true,
      handler () {
        this.openResizeImage()
      }
    }
  },
  destroyed () {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  },
  methods: {
    async openResizeImage () {
      const resizedImage = await this.contentDialogTop.open({
        width: '500px',
        component: 'm-block-image-cropper',
        componentProps: {
          imageUrl: this.image.src,
          imageType: this.image.type
        }
      })
      if (!resizedImage) {
        this.$refs.uploader.reset()
        this.files = []
        return
      }
      this.imageBlob = resizedImage.blob
      this.previewImage = resizedImage.dataUrl
    },
    getMimeType (file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4)
      let header = ''
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16)
      }
      switch (header) {
        case '89504e47':
          return 'image/png'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg'
        default:
          return fallback
      }
    },
    handleImage (image) {
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src)
      }

      const blob = URL.createObjectURL(image)

      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = {
          src: blob,
          type: this.getMimeType(e.target.result, image.type)
        }
      }
      reader.readAsArrayBuffer(image)
    },
    removePreviewImage () {
      this.previewImage = null
      this.imageBlob = null
    }
  }
}

export default images
