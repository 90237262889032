<template lang="pug">
  div(
    v-if="showButton"
    class="apple-pay-button apple-pay-button-black"
    id="applePayButton"
    @click="onButtonClick"
  )
</template>

<script>
import ApplePayService from '~/modules/applePay/service/ApplePayService'

export default {
  props: {
    amount: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    merchantId: {
      type: String,
      required: true
    },
    merchantValidation: {
      type: Function,
      required: true
    },
    payment: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    applePayService: null,
    showButton: false
  }),
  async created () {
    this.applePayService = new ApplePayService()
    this.showButton = await this.applePayService.isReadyToPay(this.merchantId)
    this.$emit('onButtonVisible', Boolean(this.showButton))
  },
  methods: {
    onButtonClick () {
      this.applePayService._onClickPaymentButton({
        amount: this.amount,
        label: this.label,
        performValidation: async (validationUrl) => {
          return await (this._.isFunction(this.merchantValidation) && this.merchantValidation(validationUrl))
        },
        payment: async (token) => {
          return await (this._.isFunction(this.payment) && this.payment(token))
        }
      })
    }
  }
}
</script>

<style scoped>
@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: block;
    -webkit-appearance: -apple-pay-button;
    height: 48px;
    cursor: pointer;
    width: 100%;
    max-width: 100px;
    border-radius: 12px;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: none !important;
  }
}
</style>
