import moment from 'moment-timezone'
import TaxReports from '~/modules/reports/models/TaxReports'
import TaxInfo from '~/modules/reports/models/TaxInfo'

const taxReports = {
  methods: {
    generateAvailableReports () {
      const taxInfo = TaxInfo.query().first()
      const group = taxInfo?.GRUP
      const stavka = taxInfo?.STAVKA
      const date = new Date()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const inFirstQuarter = month === 4 || (month === 5 && day <= 10)
      const inSecondQuarter = month === 7 || (month === 8 && day <= 9)
      const inThirdQuarter = month === 10 || (month === 11 && day <= 9)
      const inFourthQuarter = month === 1 || (month === 2 && day <= 9)
      const availableReports = []
      const temporaryReportAvailable = group === 3 && stavka === 2
      const defaultReportAvailable = group === 3 && stavka === 5
      if (temporaryReportAvailable && (day >= 1 && day <= 20)) {
        availableReports.push(TaxReports.REPORT_TYPES.thirdTwo)
      }
      if (defaultReportAvailable && (inFirstQuarter || inSecondQuarter || inThirdQuarter || inFourthQuarter)) {
        availableReports.push(TaxReports.REPORT_TYPES.thirdFive)
      }
      const includeMarch = !moment().isLeapYear() ? month === 3 && day === 1 : false
      if (group === 2 && (month === 1 || month === 2 || includeMarch)) {
        availableReports.push(TaxReports.REPORT_TYPES.second)
      }
      return availableReports
    },
    taxReportVisible (org) {
      const taxInfo = TaxInfo.query().first()
      if (taxInfo) {
        const group = taxInfo.GRUP
        const stavka = taxInfo.STAVKA
        const third = group === 3 && (stavka === 5 || stavka === 2)
        const second = group === 2
        return (third || second) && (org && org.personType === 'individual')
      }
      return false
    }
  }
}

export default taxReports
