<template lang="pug">
  ValidationObserver(ref="form" slim)
    v-form(@submit.prevent.stop="submit")
      div(class="addition")
        div(class="addition__header")
          div()
            p() {{ $t('Receipt note') }}
            p() ({{ $t('stamp of the controlling authority') }})
          div()
            p() {{ $t('Addition') }} 1
            p() {{ $t('to the tax declaration of a single tax payer - an individual - an entrepreneur') }}
        div(class="addition__data")
          div()
            h3(class="mb-3") {{ _.upperCase($t('Statements')) }}*
            p(class="bigger")
              b {{ $t('about the amount of the accumulated income of the insured persons and the amount of the accumulated single contribution') }}
          div(class="addition__type-table")
            div(class="addition__type-table-row")
              div() 01
              div()
                ValidationProvider(
                  vid="reportingType"
                  mode="lazy"
                  rules="required"
                  v-slot="{ errors }"
                )
                  v-checkbox(
                    v-model="form.reportingType"
                    true-value="HZ"
                    :false-value="null"
                    class="mt-0"
                    :error-messages="errors"
                    hide-details
                  )
              div() {{ $t('Reportable') }}
            div(class="addition__type-table-row")
              div() 02
              div()
                ValidationProvider(
                  vid="reportingType"
                  mode="lazy"
                  rules="required"
                  v-slot="{ errors }"
                )
                  v-checkbox(
                    v-model="form.reportingType"
                    true-value="HZN"
                    :false-value="null"
                    class="mt-0"
                    :error-messages="errors"
                    hide-details
                  )
              div()
                span(class="mr-1") {{ $t('Reportable new') }}
                sup 1
        div(class="addition__text-input-block")
          div()
            p()
             span(class="mr-1") {{ $t('Registration number of the taxpayer\'s registration card or series (if available) and passport number') }}
             sup 2
          div()
            ValidationProvider(
              name=" "
              vid="HTIN"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HTIN"
                :label="$t('Number')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__text-input-block")
          div()
            p() {{ $t('Surname, first name, patronymic (if available)') }}
          div()
            ValidationProvider(
              name=" "
              vid="HNAME"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HNAME"
                :label="$t('Full name')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__period")
          div()
            p() {{ $t('Tax (reporting) period') }}:
          div(class="addition__period-inputs")
            ValidationProvider(
              vid="reportingPeriod"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-radio-group(
                v-model="form.reportingPeriod"
                :error-messages="errors"
                class="mt-0 mb-4"
                hide-details
                row
              )
                v-radio(
                  :label="$t('1 quarter')"
                  value="H1KV"
                )
                v-radio(
                  :label="$t('six months')"
                  value="HHY"
                )
                v-radio(
                  :label="$t('three quarters')"
                  value="H3KV"
                )
                v-radio(
                  :label="$t('year')"
                  value="HY"
                )
            ValidationProvider(
              v-if="item.field2"
              name=" "
              vid="HZY"
              mode="lazy"
              rules="required|length:4|numeric"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HZY"
                :label="$t('Year ')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__text-input-block")
          div()
            p() {{ $t('Code of the main type of economic activity') }}
          div()
            ValidationProvider(
              name=" "
              vid="HKVED"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HKVED"
                :label="$t('KVED')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__text-input-block addition__text-input-block--borderless")
          div()
            p() {{ $t('The period of stay of an individual - an entrepreneur on the simplified taxation system') }}
          div(class="addition__right-block-inputs")
            ValidationProvider(
              name=" "
              vid="R08G1D"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              e-input-datetime(
                v-model="form.R08G1D"
                :error-messages="errors"
                :label="$t('From')"
                outlined
              )
            ValidationProvider(
              name=" "
              vid="R08G2D"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              e-input-datetime(
                v-model="form.R08G2D"
                :error-messages="errors"
                :label="$t('To')"
                outlined
              )
        div(class="addition__text-input-block")
          div()
            p()
              span(class="mr-1") {{ $t('Category code of the insured person') }}
              sup 3
          div()
            ValidationProvider(
              name=" "
              vid="R081G1"
              mode="lazy"
              rules="required|max:2|numeric"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.R081G1"
                :label="$t('Code')"
                :error-messages="errors"
                full-width
                outlined
              )
        div(class="addition__text-block")
          div()
            p() {{ $t('DETERMINATION OF THE AMOUNT OF ACCRUED INCOME OF THE INSURED PERSONS AND THE AMOUNT OF THE ACCRUED SINGLE CONTRIBUTION') }}
        div(class="addition__table")
          div(class="addition__table-row addition__table-row--head")
            div()
              p() {{ $t('Month') }}
            div()
              p() {{ $t('An independently determined amount of income for which a single contribution is calculated, taking into account the maximum amount') }}
            div()
              p()
                span(class="mr-1") {{ $t('The size of the single contribution, percentage') }}
                sup 4
            div()
              p() {{ $t('Amount of accrued single contribution (column 3 x column 4)') }}
          div(
            class="addition__table-row"
            v-for="(item, index) in taxSumTableRows"
            :key="index"
          )
            div()
              p() {{ $t(item.month) }}
            div()
              ValidationProvider(
                name=" "
                :vid="item.field1"
                mode="lazy"
                v-slot="{ errors }"
              )
                v-text-field(
                  v-model="form.taxSum[item.field1]"
                  :label="$t('Sum')"
                  :error-messages="errors"
                  @input="onSumChange(item.field1)"
                  full-width
                  outlined
                )
            div()
              ValidationProvider(
                v-if="item.field2"
                name=" "
                :vid="item.field2"
                mode="lazy"
                v-slot="{ errors }"
              )
                v-text-field(
                  v-model="form.taxSum[item.field2]"
                  :label="$t('Percent')"
                  :error-messages="errors"
                  @input="onSumChange(item.field2)"
                  full-width
                  outlined
                )
            div()
              ValidationProvider(
                name=" "
                :vid="item.field3"
                mode="lazy"
                v-slot="{ errors }"
              )
                v-text-field(
                  v-model="form.taxSum[item.field3]"
                  :label="$t('Sum')"
                  :error-messages="errors"
                  @input="onSumChange(item.field3)"
                  full-width
                  outlined
                  disabled
                )
        div(class="addition__footer")
          div()
            p()
              sup *
              span(class="ml-1") {{ $t('Submitted and filled out by natural persons - entrepreneurs - payers of the single tax of the first - third groups') }}
            p()
              sup 1
              span(class="ml-1") {{ $t('In case of submission of a new declaration with corrected indicators before the end of the deadline') }}
            p()
              sup 2
              span(class="ml-1") {{ $t('The series (if available) and the passport number are indicated by natural persons') }}
            p()
              sup 3
              span(class="ml-1") {{ $t('The category code of the insured person is "6" - a natural person - an entrepreneur on the simplified taxation system.') }}
            p()
              sup 4
              span(class="ml-1") {{ $t('The amount of the single contribution established by law is indicated.') }}
        div(class="addition__footer-input")
          div()
            p() {{ $t('The given information is correct') }}:
            p() {{ $t('A natural person is a payer of a single contribution or an authorized person') }}
          div()
            ValidationProvider(
              name=" "
              vid="HBOS"
              mode="lazy"
              rules="required"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="form.HBOS"
                :label="$t('Full name')"
                :error-messages="errors"
                full-width
                outlined
              )
      div(class="py-6")
        v-btn(
          class="main-button d-block mx-auto"
          type="submit"
          :loading="loading"
        ) {{ $t('Save') }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import EInputDatetime from '~/components/elements/inputs/e-input-datetime'
import taxReportsAdditions from '~/modules/reports/mixins/taxReportsAdditions'

export default {
  name: 'BlockTaxReportAdditionFormThirdFive',
  components: {
    EInputDatetime
  },
  mixins: [responsive, taxReportsAdditions],
  props: {
    type: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    onSubmit: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
$border-solid: 2px solid #000;
$border-thin: 1px solid rgba(0, 0, 0, 0.35);

.addition {
  border: $border-solid;
  font-size: 13px;

  p {
    font-size: 13px;
    margin-bottom: 0;

    &.bigger {
      font-size: 15px;
    }
  }

  &__header {
    border-bottom: $border-solid;
    display: flex;

    > div:first-child {
      width: 40%;
      border-right: $border-thin;
      padding: 15px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    > div:last-child {
      width: 60%;
      padding: 15px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      p {
        width: 90%;
      }
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;

      > div:first-child {
        width: 100%;
        border-bottom: $border-thin;
        border-right: none;
      }

      > div:last-child {
        width: 100%;
        align-items: flex-start;

        p {
          width: 100%;
        }
      }
    }
  }

  &__data {
    border-bottom: $border-solid;
    display: flex;
    align-items: stretch;

    > div:first-child {
      text-align: center;
      padding: 20px 10px;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;
    }
  }

  &__type-table {
    display: flex;
    flex-direction: column;

    @media (max-width: map-get($breakpoints, 'sm')) {
      width: 100%;
    }

    &-row {
      border: $border-thin;
      border-top: none;
      border-right: none;
      display: flex;
      font-size: 13px;
      height: 100%;

      &:last-child {
        border-bottom: none;
      }

      > div {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
          border-right: $border-thin;
        }
      }

      > div:nth-child(1) {
        width: 40px;
      }

      > div:nth-child(2) {
        padding: 10px 15px;

        &::v-deep {
          .v-input--selection-controls__input {
            margin-right: 0;
          }
        }
      }

      > div:nth-child(3) {
        width: 150px;
        text-align: center;
      }

      @media (max-width: map-get($breakpoints, 'sm')) {
        border-left: none;

        &:first-child {
          border-top: $border-thin;
        }

        > div:nth-child(3) {
          flex-grow: 1;
        }
      }
    }
  }

  &__text-input-block {
    border-bottom: $border-solid;
    display: flex;

    &--borderless {
      border-bottom: none;
    }

    > div {
      padding: 15px 10px;
    }

    > div:first-child {
      border-right: $border-thin;
      width: 65%;
    }

    > div:last-child {
      width: 35%;
      padding: 15px 15px 0;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;

      > div:first-child {
        border-right: none;
        width: 100%;
      }

      > div:last-child {
        width: 100%;
        padding-top: 0;
      }
    }
  }

  &__period {
    padding: 15px 10px;
    border-bottom: $border-solid;

    &-inputs {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 10px;

      @media (max-width: map-get($breakpoints, 'sm')) {
        display: block;
      }
    }

    &::v-deep {
      .v-input--radio-group__input {
        gap: 7px;
      }
    }
  }

  &__right-block-inputs {
    display: flex;
    gap: 10px;

    @media (max-width: map-get($breakpoints, 'md')) {
      display: block;
    }
  }

  &__text-block {
    padding: 15px 10px;
    border-bottom: $border-solid;

    p {
      font-size: 13px;
      font-weight: 700;
    }
  }

  &__footer {
    padding: 15px 10px;

    p {
      font-size: 11px;
      font-weight: 400;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__small-table {
    border-bottom: $border-solid;

    &-row {
      border-bottom: $border-thin;
      display: flex;

      &:last-child {
        border-bottom: none;
      }

      > div:nth-child(1) {
        border-right: $border-thin;
        padding: 15px 10px;
        width: 5%;
        text-align: center;
      }

      > div:nth-child(2) {
        border-right: $border-thin;
        padding: 15px 10px;
        width: 60%;
      }

      > div:nth-child(3) {
        width: 35%;
        padding: 15px 10px 0;
      }

      @media (max-width: map-get($breakpoints, 'sm')) {
        flex-wrap: wrap;

        > div:nth-child(1) {
          min-width: 30px;
          max-width: 30px;
          border-right: none;
        }

        > div:nth-child(2) {
          width: calc(100% - 30px);
          border-right: none;
        }

        > div:nth-child(3) {
          width: 100%;
          padding: 0 10px;
        }
      }
    }
  }

  &__footer-input {
    border-top: $border-solid;
    display: flex;

    p {
      font-size: 13px;
      font-weight: 700;
    }

    > div:first-child {
      padding: 15px 10px;
      border-right: $border-thin;
      width: 65%;
    }

    > div:last-child {
      padding: 15px 10px 0;
      width: 35%;
    }

    @media (max-width: map-get($breakpoints, 'sm')) {
      flex-wrap: wrap;

      > div:first-child {
        border-right: none;
        width: 100%;
      }

      > div:last-child {
        padding: 0 10px;
        width: 100%;
      }
    }
  }

  &__table {
    border-bottom: $border-solid;
    overflow-x: auto;

    &-row {
      border-bottom: $border-thin;
      display: flex;
      min-width: 620px;

      &:last-child {
        border-bottom: none;
      }

      > div {
        padding: 15px 10px;
      }

      > div:nth-child(1) {
        width: 10%;
        border-right: $border-thin;
        min-width: 93px;
      }

      > div:nth-child(2) {
        width: 40%;
        padding-bottom: 0;
        border-right: $border-thin;
      }

      > div:nth-child(3) {
        width: 25%;
        padding-bottom: 0;
        border-right: $border-thin;
      }

      > div:nth-child(4) {
        width: 25%;
        padding-bottom: 0;
      }

      &--head {
        > div:nth-child(2),
        > div:nth-child(3),
        > div:nth-child(4) {
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>
