import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'
import PersistAble from '~/models/mixins/PersistAble'

export class PayLinkTerminals extends PersistAble(OrmModel) {
  static entity = 'payLinkTerminals'
  static paginated = true
  static defaultSortOrder = true

  static ormTrans = {
    single: 'Pay link',
    multy: 'Pay link'
  }

  static fields () {
    return {
      id: this.attr(null),
      label: this.attr(null),
      online: this.attr(null),
      device_id: this.attr(null),
      organization_id: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null)
    }
  }

  static ormHeaders = [
    { text: 'Label', value: 'label', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
  ]

  static ormFiltersConfig = {
  }

  static ormActions = [
  ]

  static ormDialogs = {
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.authAsClient = function (terminalId) {
        return this.post(Model.$routes.payLinkTerminals.authAsClient(), { terminalId }, { save: false })
      }

      return configActions
    }
  }
}

export default PayLinkTerminals
