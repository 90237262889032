import Search from '~/models/abstracts/Search'

export class ProvidersSearch extends Search {
  static entity = 'providerssearch'
  static primaryKey = 'providerId'

  static fields () {
    return {
      providerId: this.attr(null),
      name: this.attr(null)
    }
  }

  toString () {
    return this.name
  }
}

export default ProvidersSearch
