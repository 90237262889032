import Vue from 'vue'
import VueI18n from 'vue-i18n'
import uk from '~/plugins/nuxt-i18n/lang/ua-UA'
import en from '~/plugins/nuxt-i18n/lang/en-US'

Vue.use(VueI18n)

const langLS = localStorage.getItem('i18nLocale')

const i18n = new VueI18n({
  locale: langLS || 'uk', // set locale
  messages: {
    uk,
    en
  }
})

export default i18n
