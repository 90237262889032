import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'

export class Tags extends ChainInheritance(Directory, []) {
  static entity = 'tags'
  // static paginated = true

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      code: this.attr(null),
      manager: this.attr(null)
    }
  }

  toString () {
    return this.name
  }
}

export default Tags
