<template lang="pug">
  div(class="pb-4")
    div(v-if="loading" class="loader")
      e-progress-circular(color="#161b25" size="xl" width="4")
    div(v-else)
      t-orm-simple-data(:items="fields" class="mb-3")
      div(v-if="orderGoods" class="pt-4")
        h3(class="title") {{ $t('Goods') }}
        v-simple-table
          thead
            tr
              th {{ $t('Name') }}
              th {{ $t('Amount') }}
              th(class="text-right") {{ $t('Price, ₴') }}
          tbody
            tr(v-for="item in orderGoods" :key="item.id")
              td {{ _.get(item, 'good.name') }}
              td {{ getQuantity(item) }}
              td(class="text-right") {{ getPrice(item) }}
      div(v-if="isSuperAdmin" class="pt-4")
        h3(class="title") {{ $t('Admin') }}
        v-simple-table
          thead
            tr
              th {{ $t('Error') }}
              th {{ $t('Webhook') }}
              th(v-if="hasWebhook" class="text-right") {{ $t('Actions') }}
          tbody
            tr
              td {{ _.get(fullOrder, 'rawError') || '-' }}
              td {{ hasWebhook ? $t('Active ') : $t('Inactive') }}
              td(v-if="hasWebhook" class="text-right")
                v-tooltip(top)
                  template(#activator="{on}")
                    e-link(@click="sendToManualCheck" :loading="manualCheckLoader")
                      e-svg-icon(v-on="on") refresh
                  span {{ $t('Send to manual check') }}
</template>

<script>
import auth from '~/modules/auth/auth'
import dateTime from '~/mixins/datetime/dateTime'
import TOrmSimpleData from '~/components/templates/orm/t-orm-simple-data'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import EttnOrders from '~/modules/ettn/models/EttnOrders'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'MBlockEttnOrderView',
  components: {
    EProgressCircular,
    TOrmSimpleData,
    ESvgIcon,
    ELink
  },
  mixins: [dateTime],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    fullOrder: null,
    manualCheckLoader: false
  }),
  computed: {
    model () {
      return EttnOrders
    },
    hasWebhook () {
      return this._.get(this.fullOrder, 'hasWebhook')
    },
    isSuperAdmin () {
      return auth.getSuperAdminAuthToken() !== null
    },
    orderGoods () {
      return this._.get(this.fullOrder, 'receiptBody.goods', [])
    },
    fields () {
      return [
        {
          label: 'Status',
          val: this.$t(Object.values(this.model.statuses).find(elem => elem.value === this.order?.status).text)
        },
        {
          label: 'Date',
          val: this.formatTime(this.order?.dateCreated)
        },
        {
          label: 'eTTN number',
          val: this.order?.ettnNumber
        },
        {
          hideEmpty: true,
          label: 'Order number',
          val: this.fullOrder?.receiptRelationId
        },
        {
          label: 'Total sum',
          val: ((this.order?.totalSum || 0) / 100).toFixed(2)
            .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
        },
        {
          label: 'Cash register ',
          val: this._.get(this.order, 'cashRegister.fiscalNumber')
        },
        {
          label: 'employee',
          val: this._.get(this.order, 'employee.login')
        }
      ]
    }
  },
  created () {
    this.getOrder()
  },
  methods: {
    async sendToManualCheck () {
      try {
        this.manualCheckLoader = true

        const request = await EttnOrders.api().updateWebhookSettings(this.order.id)
        this.fullOrder = this._.get(request, 'response.data')

        this.$notification.success(this.$t('eTTN was successfully sent for manual check'))
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.manualCheckLoader = false
      }
    },
    async getOrder () {
      try {
        this.loading = true
        this.fullOrder = this._.get(await this.model.api().read(this._.get(this.order, 'id')), 'response.data')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    getPrice (item) {
      const price = parseFloat((this._.get(item, 'good.price') / 100).toFixed(2))
      const totalPrice = (price * this.getQuantity(item)).toFixed(2)
      return totalPrice.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
    },
    getQuantity (item) {
      const quantity = (this._.get(item, 'quantity') / 1000).toFixed(2)
      return parseFloat(quantity)
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  width: 100%;
  height: 100%;
  max-height: 400px;
  padding: 20px 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  color: #000;
  margin-bottom: 20px;
  font-weight: 700;
}
</style>
