import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class Privatbank extends PersistAble(OrmModel) {
  static entity = 'privatbank'
  static paginated = false
  static defaultSortOrder = true

  static fields () {
    return {
      id: this.attr(null),
      privatbankTerminalToken: this.attr(null),
      cashRegister: this.attr(null),
      isActive: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.setTerminal = function (payload) {
        return this.post(Model.$routes.privatbank.terminals(), payload, {
          save: false
        })
      }
      return configActions
    }
  }
}

export default Privatbank
