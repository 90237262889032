import ChainInheritance from '~/models/mixins/ChainInheritance'
import Directory from '~/models/abstracts/Directory'

export default class BranchTaxBillSettings extends ChainInheritance(Directory, []) {
  static entity = 'branchtaxbillsettings'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      taxNumber: this.attr(null),
      branchNumber: this.attr(null),
      edrpou: this.attr(null)
    }
  }
}
