<template lang="pug">
  div(class="loading-state")
    div(class="loader-wrapper")
      m-loader

    div(class="loading-state__text-wrapper")
      h2(class="loading-state__title") {{ title }}
      p(class="loading-state__description") {{ description }}
</template>

<script>
import MLoader from '~/modules/goods/views/import-export-flow/m-loader.vue'
import { OperationType } from '~/modules/goods/views/import-export-flow/const/operationType'

export default {
  name: 'MLoadingState',
  components: { MLoader },
  props: {
    operationType: {
      type: String,
      required: true
    },
    operationStatus: {
      type: String,
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: {
      excel: false,
      csv: false
    }
  }),
  computed: {
    title () {
      switch (this.operationType) {
        case OperationType.UPLOAD:
          return this.$t('The system uploads goods')
        case OperationType.EXPORT:
          return this.$t('The system prepares a file for export')
        case OperationType.DELETE:
          return this.$t('The system deletes goods')
        default:
          return this.$t('Unknown operation')
      }
    },
    description () {
      switch (this.operationType) {
        case OperationType.UPLOAD:
          return this.$t('This may take a few minutes if there are a lot of goods')
        case OperationType.EXPORT:
          return this.$t('This may take a few minutes if there are a lot of goods. Once complete, the file will be automatically downloaded to your device')
        case OperationType.DELETE:
          return this.$t('This may take a few minutes if there are a lot of goods')
        default:
          return this.$t('This may take some time.')
      }
    }
  },
  watch: {
    operationStatus: {
      handler (val) {
        this.closeModal(val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loader-wrapper {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;

  &__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-family: 'e-Ukraine', serif;
    color: #161B25;
    margin-bottom: 8px;
  }

  &__description {
    font-family: 'e-Ukraine', serif;
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
  }

  &__text-wrapper {
    width: 100%;
    text-align: left;
  }
}
</style>
