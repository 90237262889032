<template lang="pug">
  v-row( justify="center" class="px-6")
    v-col(cols="12")
      h3(class="title font-weight-bold wb-bw text-center" style="color: #000") {{ title }}
      p(
        v-if="showText"
        class="title text-center mt-4 mb-0"
      ) {{ $t('Cancel receipt by cashier') + selectedCashier.name }} ?
    v-col(cols="12")
      ValidationObserver(ref="selectedCashier" slim)
        ValidationProvider(
          :name="$t('Select employee')"
          vid="selectedCashier"
          mode="lazy"
          rules="required"
          v-slot="{ errors }"
        )
          e-autocomplete(
            v-model="selectedCashier"
            :item="autocompleteItem"
            :label="$t('Select employee')"
            @change="handleCashierChange"
            :selectLoading="selectLoading"
            :error-messages="errors"
            outlined
            clearable
          )
      v-radio-group(class="mt-0 pb-3" v-model="signType" v-if="!hasAuthenticatedCashier && showRadioButtons" hide-details)
        v-radio(:label="$t('Load EDS key in browser')" value="eds_key" color="black" :ripple="false")
        v-radio(:label="$t('I run the Checkbox Signature software locally')" value="agent" color="black" :ripple="false")
    v-col(cols="auto" class="pt-0")
      v-btn(
        color="primary"
        class="main-button"
        @click="action"
        :loading="loading"
        depressed
      ) {{ $t('Continue') }}
</template>

<script>
import Employees from '~/modules/employees/models/Employees'
import processingReports from '~/modules/receipt/mixins/actions/processingReports'
import _comonData from '~/modules/receipt/mixins/getters/commonData'
import EAutocomplete from '~/components/elements/inputs/e-autocomplete'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
import TFields from '~/components/templates/t-fields'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import processingCheckAgent from '~/modules/receipt/mixins/actions/processingCheckAgent'
import informationDialog from '~/mixins/dialogs/informationDialog'
import Receipts from '~/modules/receipt/models/Receipts'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'

export default {
  name: 'BlockCancelReceipt',
  components: { TFields, EAutocomplete },
  mixins: [processingReports, _comonData, confirmationDialog, informationDialog, processingCheckAgent, processingApiRequest],
  props: {
    receipt: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      token: null,
      showRadioButtons: false,
      showLoading: false,
      selectedCashier: null,
      showText: false,
      selectLoading: false,
      signType: 'agent',
      loading: false
    }
  },
  computed: {
    autocompleteItem () {
      return {
        attrs: {
          itemClass: 'ws-pre',
          returnObject: true
        },
        searchModel: EmployeesSearch,
        query: model => model.api().filter({ mode: Employees.itemModes.checkbox.mode, type: Employees.itemTypes.head_cashier.value })
      }
    },
    hasAuthenticatedCashier () {
      return this.authenticatedCashier
    },
    title () {
      if (this.showRadioButtons) {
        return this.$t('Select type of receipt cancellation')
      } else {
        return this.$t('Select employee')
      }
    }
  },
  methods: {
    async handleCashierChange (val) {
      if (val) {
        try {
          this.selectLoading = true
          const { signAgentLaunched } = await this.checkSignAgent(val)
          this.showText = signAgentLaunched
          this.showRadioButtons = !signAgentLaunched
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.selectLoading = false
        }
      } else {
        this.showRadioButtons = false
        this.showText = false
      }
    },
    async action () {
      try {
        if (!await this.$refs.selectedCashier.validate()) {
          return
        }
        this.loading = true

        let token = null
        if (this.hasAuthenticatedCashier) {
          token = this.authenticatedCashier.accessToken
        } else if (this.signType === 'agent' || this.signType === 'eds_key') {
          token = await this.authenticateByPinCode()
        }
        if (!token) {
          return
        }
        const id = this._.get(this.receipt, 'id', null)
        await this.processingApiRequest({
          request: token => Receipts.api().processingCancelReceipt(id, token),
          token,
          employee: !this.hasAuthenticatedCashier ? this.selectedCashier : null
        })
        Receipts.delete(id)
        this.$notification.success(this.$t('Receipt canceled successfully'))
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async authenticateByPinCode () {
      return this._.get(await Employees.api().processingSignin(this.selectedCashier), 'response.data.access_token')
    }
  }
}
</script>

<style scoped lang="scss">
.print-preview {
  height: 400px;
  overflow-y: auto;
}
</style>
