<template lang="pug">
  div(class="version")
    | {{ version }}
</template>

<script>
export default {
  name: 'LatestVersion',
  data () {
    return {
      version: this.extractVersion(this.$env.app_version || '')
    }
  },
  methods: {
    extractVersion (input) {
      return input.split('-')[1]
    }
  }
}
</script>

<style scoped>
.version {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
