<template lang="pug">
  v-tooltip(v-if="tooltip" right)
    template(#activator="{on, attrs}")
      div(v-on="on" class="e-radio-status" :style="radioStatusStyle")
    span()
      | {{$t(tooltip)}}
  div(v-else class="e-radio-status" :style="radioStatusStyle")
</template>

<script>
import { VRadio, VRadioGroup } from 'vuetify/lib'

export default {
  components: {
    VRadio,
    VRadioGroup
  },
  props: {
    value: {
      type: null,
      required: true
    },
    valueMap: {
      type: Object,
      default: () => ({})
    },
    colorMap: {
      type: Object,
      default: () => ({})
    },
    tooltipsMap: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    color () {
      return this.colorMap[this.value] || 'success'
    },
    radioStatusStyle () {
      return {
        borderColor: this.color
      }
    },
    tooltip () {
      if (this.tooltipsMap[this.value]) {
        return this.tooltipsMap[this.value].replace('{value}', this.value)
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.e-radio-status {
  display: block;
  width: 20px;
  height: 20px;
  border: 6px solid;
  border-radius: 50%;
  background: none;
}
</style>
