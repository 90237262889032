<template lang="pug">
  v-row()
    v-col(class="mt-4")
      v-stepper(v-model="step" style="box-shadow:none" )
        v-stepper-header()
          v-stepper-step(
            step="1"
            @click="goToFirst"
            editable
          ) {{$t('Select import source')}}
          v-stepper-step(
            step="2"
          ) {{$t('Upload file')}}
        v-stepper-items()
          v-stepper-content(step="1" )
            v-row( justify="center" )
              v-col(cols="auto")
                v-btn(class="secondary-button" outlined style="min-width:200px" @click="selectSource('excel')" )
                  e-svg-icon(size="xmd" name="xlsx" class="mr-2" )
                  | {{$t('Excel file')}}
            v-row( justify="center" )
              v-col(cols="auto")
                v-btn(class="secondary-button" outlined style="min-width:200px" @click="selectSource('csv')" )
                  e-svg-icon(size="xmd" name="csv" class="mr-2")
                  | {{$t('Csv file')}}
          v-stepper-content(step="2" )
            v-row(justify="center" no-gutters class="mt-4" )
              v-col(cols="10" v-if="loading")
                e-process-progress(
                  :value="processedItemsValue"
                  :current="processedItems"
                  :total="totalItems"
                )
              v-col(cols="10" v-else)
                v-row(no-gutters)
                  v-col()
                    e-input-file-content(
                      :accept="accept"
                      :rules="rules"
                      v-model="file"
                      expect="blob"
                      :label="$t('Select file')"
                      prependIcon=''
                      appendIcon='$file'
                      ref="fileInput"
                      outlined
                    )
                    v-checkbox(
                      v-model="autoSupply"
                      :label="$t('Specify balances for new products')"
                      class="mt-0"
                      hide-details
                    )
                v-row(v-if="errors && errors.length" class="mt-4" no-gutters )
                  v-col(cols="12")
                    m-info-block(type="warning" icon-size="md" remove-padding )
                      span(style="font-size:12px")
                        | {{$t('There are errors in import file')}}
                    div(v-for="(error, i) in errors" :key="i" style="color:red")
                      | {{error.error}}: {{error.address}}
                v-row( no-gutters)
                  v-col()
                    v-list()
                      v-list-item(style="cursor:pointer")
                        v-list-item-icon()
                          e-svg-icon(name="download-black" size="md" v-if="!exampleLoading" )
                          e-progress-circular(v-else size="sm" )
                        v-list-item-content(@click="downloadExample")
                          v-list-item-title() {{$t('Download example')}}
                      // v-divider
                      //v-list-item(style="cursor:pointer")
                      //  v-list-item-icon()
                      //    e-svg-icon(name="info" size="md" )
                      //  v-list-item-content(@click="viewInstructions")
                      //    v-list-item-title() {{$t('View instructions')}}
                v-row(no-gutters)
                  v-btn(:loading="loading" class="main-button mt-2" @click="uploadFile" block outlined  )
                    | {{$t('Import')}}
                  span(class="hint") {{ $t('Warning') }}! {{ $t('If the file is large, the import may take several minutes') }}
</template>

<script>
import Download from 'js-file-download'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import MInfoBlock from '~/components/modules/blocks/m-info-block'
import EHintTooltip from '~/components/elements/tooltips/e-hint-tooltip'
import EProcessProgress from '~/components/elements/progress/e-process-progress'

export default {
  components: { EProcessProgress, EHintTooltip, MInfoBlock, EProgressCircular, EInputFileContent, ESvgIcon },
  props: {
    closeModal: {
      type: Function,
      default: null
    },
    onSuccess: {
      type: Function,
      default: () => {}
    },
    useBranches: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    step: 1,
    fileSource: null,
    file: null,
    loading: false,
    exampleLoading: false,
    errors: null,
    request: null,
    autoSupply: false
  }),
  computed: {
    isExcelSource () {
      return this.fileSource === 'excel'
    },
    exampleName () {
      return this.isCsvSource ? 'goods.csv' : 'goods.xlsx'
    },
    isCsvSource () {
      return this.fileSource === 'csv'
    },
    accept () {
      return this.isCsvSource ? '.csv' : '.xls, .xlsx'
    },
    rules () {
      const rules = 'required|size:30000|ext:'

      return rules + (this.isCsvSource ? 'csv' : 'xls,xlsx')
    },
    uploadAction () {
      return this.isCsvSource ? 'uploadCsv' : 'uploadExcel'
    },
    processedItemsValue () {
      return Math.round(this.processedItems / (this.totalItems / 100)) || 0
    },
    processedItems () {
      return this._.get(this.request, 'response.response.data.processed_items', 0)
    },
    totalItems () {
      return this._.get(this.request, 'response.response.data.total_items', 0)
    }
  },
  methods: {
    goToFirst () {
      this.step = 1
      this.file = null
      this.errors = null
    },
    selectSource (source) {
      this.fileSource = source
      this.step = 2
    },
    async downloadExample () {
      this.exampleLoading = true
      try {
        const blob = this._.get(await AccountingGoods.api().getUploadExample(this.fileSource), 'response.data', null)
        Download(blob, this.exampleName)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.exampleLoading = false
      }
    },
    checkStatus (id, approveRequestStatus = false) {
      const resolveStatus = approveRequestStatus ? 'done' : 'completed'
      const request = new IntervalRequest(() => AccountingGoods.api().checkUploadStatus(id), {
        interval: 2000,
        count: 300,
        maxDelay: 6e5
      })
      if (!approveRequestStatus) {
        this.request = request
      }
      return request.startExponential(response => this._.get(response, 'response.data.status', null) === resolveStatus)
    },
    async uploadFile () {
      try {
        this.loading = true
        const { valid } = await this.$refs.fileInput.validate()
        if (!valid) {
          return
        }
        const taskId = this._.get(await AccountingGoods.api()[this.uploadAction]({
          file: this.file,
          useBranches: this.useBranches
        }, {
          auto_supply: this.autoSupply
        }), 'response.data.task_id', null)
        const status = this._.get(await this.checkStatus(taskId), 'response.data', null)
        if (status.errors && status.errors.length) {
          this.errors = status.errors.slice(0, 5)
          return
        } else {
          this.$gtm.push({
            event: 'goods_import',
            import_type: 'bulk',
            organization: this._.get(this.$Organization, 'owner.email')
          })

          const applyTaskId = this._.get(await AccountingGoods.api().approveFile(taskId), 'response.data.task_id', null)
          await this.checkStatus(applyTaskId, true)
          await this.onSuccess()
          this.$notification.success(this.$t('File imported successfully'))

          if (this.closeModal) {
            this.closeModal(true)
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }

}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem;
  line-height: 1.2;
  margin-top: 12px;
  color: $color-warning;
}
</style>
