<template lang="pug">
  div
    information-snackbar
</template>

<script>
import InformationSnackbar from '~/components/modules/snackbar/information-snackbar.vue'

export default {
  name: 'Snackbars',
  components: {
    InformationSnackbar
  }
}
</script>

<style scoped lang="scss">

</style>
