import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'

export class AcquiringOrder extends PersistAble(OrmModel) {
  static entity = 'acquiringorder'
  static paginated = true
  static defaultSortOrder = true

  static STATUSES = {
    new: 'new',
    created: 'created',
    signaturePending: 'signature_pending',
    signed: 'signed',
    onApproval: 'on_approval',
    success: 'success',
    expired: 'expired',
    error: 'error'
  }

  static fields () {
    return {
      id: this.attr(null),
      posTerminalCount: this.attr(null),
      mccCode: this.attr(null),
      status: this.attr(null),
      outlet: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getDoc = function (id) {
        return this.get(Model.$routes.acquiringorder.getDoc(id))
      }
      configActions.sendSigned = function (id, payload) {
        return this.post(Model.$routes.acquiringorder.sendSigned(id), payload)
      }
      return configActions
    }
  }
}

export default AcquiringOrder
