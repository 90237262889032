import { render, staticRenderFns } from "./m-block-ettn-order-phone-error.vue?vue&type=template&id=8fcb11d6&scoped=true&lang=pug&"
import script from "./m-block-ettn-order-phone-error.vue?vue&type=script&lang=js&"
export * from "./m-block-ettn-order-phone-error.vue?vue&type=script&lang=js&"
import style0 from "./m-block-ettn-order-phone-error.vue?vue&type=style&index=0&id=8fcb11d6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fcb11d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VImg})
