import { Model } from '@vuex-orm/core'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import Employees from '~/modules/employees/models/Employees'
import CashRegisters from '~/models/directories/CashRegisters'
import UserManagerCashRegisters from '~/models/directories/pivot/UserManagerCashRegisters'
import Directory from '~/models/abstracts/Directory'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import Dialog from '@/models/system/Dialog'
import AppNotifications from '@/services/Notifications/AppNotifications'
import i18n from '~/plugins/nuxt-i18n/i18n'

export default class ManagerUsers extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'managerusers'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortOrder = true
  static persistOptions = {
    insertOrUpdate: ['cashRegisters', 'userManagerCashRegisters']
  }

  static ROLES = {
    owner: 'ROLE_OWNER',
    user: 'ROLE_USER',
    admin: 'ROLE_ADMIN',
    cashier: 'ROLE_CASHIER',
    headCashier: 'ROLE_HEAD_CASHIER',
    emergencyCashier: 'ROLE_EMERGENCY_CASHIER',
    accountant: 'ROLE_ACCOUNTANT'
  }

  static ROLE_NAMES= {
    [ManagerUsers.ROLES.owner]: i18n.t('Super admin'),
    [ManagerUsers.ROLES.admin]: i18n.t('Administrator'),
    [ManagerUsers.ROLES.cashier]: i18n.t('employee'),
    [ManagerUsers.ROLES.headCashier]: i18n.t('Head cashier'),
    [ManagerUsers.ROLES.emergencyCashier]: i18n.t('Emergency cashier'),
    [ManagerUsers.ROLES.accountant]: i18n.t('Accountant')
  }

  static ROLE_ITEMS = [
    {
      text: ManagerUsers.ROLE_NAMES[ManagerUsers.ROLES.headCashier],
      value: ManagerUsers.ROLES.headCashier
    },
    {
      text: ManagerUsers.ROLE_NAMES[ManagerUsers.ROLES.emergencyCashier],
      value: ManagerUsers.ROLES.emergencyCashier
    },
    {
      text: ManagerUsers.ROLE_NAMES[ManagerUsers.ROLES.admin],
      value: ManagerUsers.ROLES.admin
    },
    {
      text: ManagerUsers.ROLE_NAMES[ManagerUsers.ROLES.accountant],
      value: ManagerUsers.ROLES.accountant
    }
  ]

  static fields () {
    return super.fields({
      // Dynamic relation fields.
      employee_id: this.attr(null),

      id: this.attr(null),
      login: this.attr(null),
      roles: this.attr([], (value) => {
        const removeIndex = value.indexOf(ManagerUsers.ROLES.user)
        if (removeIndex > -1) { // only splice array when item is found
          value.splice(removeIndex, 1) // 2nd parameter means remove one item only
        }
        return value
      }),
      profile: this.attr(null),
      password: this.attr(null), // todo
      relatedEmployee: this.belongsTo(Employees, 'employee_id'),
      relatedCashRegisters: this.belongsToMany(CashRegisters, UserManagerCashRegisters, 'userId', 'cashRegisterId'),
      referralCode: this.attr(null)
    })
  }

  get fullName () {
    return `${this._.get(this, 'profile.firstName', '')} ${this._.get(this, 'profile.lastName', '')}`
  }

  get rolesString () {
    let roles = ''
    this.roles.map((role, key) => {
      if (role !== ManagerUsers.ROLES.user) {
        roles += ManagerUsers.ROLE_NAMES[role] + ' '
      }
    })

    return roles || '-'
  }

  get isOrganizationUser () {
    return this.roles.length === 1 && [ManagerUsers.ROLES.cashier, ManagerUsers.ROLES.admin, ManagerUsers.ROLES.headCashier, ManagerUsers.ROLES.emergencyCashier].includes(this.roles[0])
  }

  get organizationRole () {
    return this.isOrganizationUser ? this.roles[0] : null
  }

  static ormFieldsMap = {
    firstName: 'profile.firstName',
    lastName: 'profile.lastName'
  }

  static ormRelationMap = [
    'relatedCashRegisters'
  ]

  static ormTrans = {
    single: 'user',
    multy: 'users'
  }

  static ormHeaders = [
    { text: 'Full name', value: 'fullName', sortable: false, filterable: false },
    { text: 'Roles', value: 'rolesString', sortable: false, filterable: false },
    { text: 'Login ', value: 'login', sortable: false, filterable: true },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormMobileTitle = 'fullName'

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    {
      model: 'firstName',
      component: 'v-text-field',
      provider: {
        vid: 'firstName',
        name: 'First name',
        rules: 'required'
      },
      attrs: {
        label: 'First name',
        outlined: true,
        visible: (item) => {
          const attrsRoles = this._.get(item, 'attrs.roles', undefined)
          const parentRoles = this._.get(item, 'parent.roles', undefined)
          const roles = attrsRoles || parentRoles || []

          return !roles.includes(ManagerUsers.ROLES.cashier)
        }
      }
    },
    {
      model: 'login',
      component: 'v-text-field',
      context: this.contexts.c,
      provider: {
        vid: 'login',
        name: 'Login ',
        rules: 'required'
      },
      attrs: {
        label: 'Login ',
        type: 'text',
        outlined: true
      }
    },
    {
      model: 'password',
      component: 'e-input-password',
      context: this.contexts.c,
      provider: {
        vid: 'password',
        name: 'Password',
        rules: 'required'
      },
      attrs: {
        label: 'Password',
        type: 'text',
        outlined: true
      }
    },
    {
      model: 'password',
      component: 'e-input-password',
      context: this.contexts.u,
      provider: {
        vid: 'password',
        name: 'New password'
      },
      attrs: {
        label: 'New password',
        type: 'text',
        outlined: true,
        visible: (item) => {
          const attrsRoles = this._.get(item, 'attrs.roles', undefined)
          const parentRoles = this._.get(item, 'parent.roles', undefined)
          const roles = attrsRoles || parentRoles || []

          return !roles.includes(ManagerUsers.ROLES.cashier)
        }
      }
    },
    {
      model: 'roles',
      component: 'v-select',
      cast: val => [val],
      fieldVal: (ctx) => {
        const val = this._.get(ctx.attrs, ctx.field.model)
        // todo
        return Array.isArray(val) ? val[0] : null
      },
      provider: {
        vid: 'role',
        name: 'Role',
        rules: 'required'
      },
      attrs: {
        label: 'Role',
        outlined: true,
        visible: (item) => {
          const attrsRoles = this._.get(item, 'attrs.roles', undefined)
          const parentRoles = this._.get(item, 'parent.roles', undefined)
          const roles = attrsRoles || parentRoles || []

          return !roles.includes(ManagerUsers.ROLES.cashier)
        }
      },
      items: () => this.ROLE_ITEMS
    },
    new AutocompleteBuilder({
      model: 'relatedCashRegisters',
      label: 'Select cash register',
      multiple: true,
      itemClass: 'ws-pre',
      cacheItems: true,
      useDefaultSearchFilter: true,
      hideDetails: true,
      returnObject: true,
      saveModelInstance: false,
      itemValue: (item) => {
        return this._.get(item, 'id', null)
      },
      chipText: item => this._.get(item, 'fiscalNumber', '-'),
      query: model => model.api()
    }, CashRegistersSearch).build()

  ]

  static ormActions = [
    {
      name: 'edit',
      visible: (item) => {
        return item.isOrganizationUser || item.roles.includes(ManagerUsers.ROLES.accountant)
      }
    },
    {
      name: 'delete',
      visible: (item) => {
        return (item.isOrganizationUser && !item.roles.includes(ManagerUsers.ROLES.cashier)) || item.roles.includes(ManagerUsers.ROLES.accountant)
      },
      call: async (item) => {
        const confirmationDialog = Dialog.query().where('type', 'confirmation').first()
        await confirmationDialog.open({
          title: ctx => ctx.$t('Confirm deletion of user "{fullName}"', { fullName: item.fullName }),
          onConfirm: async () => {
            await ManagerUsers.api().del(item)
            AppNotifications.success('User deleted successfully')
          }
        })
      }
    }
  ]

  static ormDialogs = {
    default: 'm-orm-crud-dialog',
    edit: 'm-orm-crud-dialog'
  }

  static ormDialogsConfig = {
    edit: {
      config: {
        context: 'update',
        filterPayload: (payload, item) => {
          if (!payload.password) {
            delete payload.password
          }
          if (payload?.firstName) {
            payload.profile = {
              id: item?.profile?.id,
              firstName: payload?.firstName
            }
            delete payload.firstName
          }
          return payload
        }
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.update = function (obj, payload) {
        const data = { ...payload, relatedCashRegisters: payload.relatedCashRegisters.map(el => `/cash-registers/${el.id}`) }

        if (!Array.isArray(payload.roles)) {
          delete data.roles
        }

        return this.put(Model.$routes.managerusers.concrete(obj.id), data, { save: true })
      }

      return configActions
    }
  }
}
