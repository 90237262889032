<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    v-form(@submit.prevent.stop="saveAndSendToTax")
      v-row()
        v-col( cols="12" class="mt-1 mb-1")
          v-card-title(class="text-center" v-if="!isDialogPart")
            div(style="width:100%")
              | {{$t('Create outlet')}}
          t-orm-fields(
            v-bind.sync="editedItem"
            :items="model.ormFields"
            :tabs="model.ormTabs"
            :context="context")
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :context="context" :classes="['text-center']")
</template>

<script>
import Base64js from 'base64-js'
import KatottgDictionary from '~/models/directories/dictionaries/KatottgDictionary'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import validate from '~/mixins/validation/validate'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import taxAuth from '~/mixins/pages/tax-auth'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'
import OutletOrder from '~/models/directories/Orders/OutletOrder'
import scrollToFailedValidation from '~/mixins/methods/scrollToFailedValidation'

export default {
  components: { TOrmButtons, TOrmFields },
  mixins: [validate, filterOrmObject, checkPropCtx, taxAuth, edsIitCheckboxDialog, scrollToFailedValidation],
  props: {
    context: {
      type: String,
      default: 'create'
    },
    selectedItem: {
      type: Object,
      default: null
    },
    isDialogPart: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    model: OutletOrder,
    editedItem: {},
    globalDataLoading: false,
    itemLoading: false,
    createdOutlet: null
  }),
  computed: {
    buttons () {
      return [
        {
          text: 'Add outlet',
          contexts: this.$config.contexts.only('c.u'),
          loading: this.itemLoading || this._.get(this.iitDialog, 'options.loading', false),
          attrs: {
            depressed: true,
            class: 'main-button',
            type: 'submit'
          },
          hint: 'This action must be signed by the EDS of the director'
        }
      ]
    }
  },
  created () {
    this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
  },
  methods: {
    close () {
      this.$emit(['dialog', 'close'].join(':'), null)
    },
    clear () {
      this.$set(this.$data, 'editedItem', this.model.getFieldsObject({ ctx: this.context }))
    },
    async saveAndSendToTax () {
      if (!await this.validate()) {
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
        return false
      }

      try {
        this.itemLoading = true
        const payload = this.filterOrmObject(this.editedItem)
        payload.T1RXXXXG11 = this._.get(payload, 'T1RXXXXG11', '0')
        payload.T1RXXXXG3 = payload.T1RXXXXG3.code
        // dpi processing
        payload.HSTI = payload.HSTI.cSti + ''
        payload.T1RXXXXG5 = payload.T1RXXXXG3 + payload.code
        payload.T1RXXXXG6S = KatottgDictionary.getCode(payload.T1RXXXXG6S)

        delete payload.code

        await this.useEdsIitCheckboxDialog({
          fallbackMethod: this.saveAndSendToTax,
          checkAuthService: true,
          createReportService: true,
          onConfirm: async ({ error, noReturn, reportService, fallbackMethod }) => {
            try {
              if (error) {
                return !noReturn ? this.saveAndSendToTax() : null
              }

              const createdEntity = this._.get(await this.model.api().create(payload), `entities.${this.model.entity}[0]`, null)
              const xmlResponse = await this.model.api().xmlDoc(createdEntity)
              const xml = Base64js.toByteArray(this._.get(xmlResponse, 'response.data.xml', null))
              const fname = this._.get(xmlResponse, 'response.data.fname', null)
              const signData = await reportService.getEnvelopedData({
                dataToSign: xml,
                verifyKey: true,
                fallbackMethod
              })

              if (!signData) {
                return null
              }

              const taxId = this._.get(await this.model.api().taxRegister(createdEntity, { signData, fname }), 'response.data.response.taxId', null)
              const encodedTaxId = new TextEncoder().encode(String(taxId))
              const signedTaxId = await reportService.getEnvelopedData({
                dataToSign: encodedTaxId,
                isXml: false,
                fallbackMethod
              })

              if (!signedTaxId) {
                return null
              }

              await this.model.api().signTaxId(createdEntity, { tax_id: signedTaxId })

              this.$notification.success(`entityActions.${this.context}.${this.model.entity}`)
              this.close()
            } catch (e) {
              this.$handlers.error(e, this.$refs[this.model.entity])
              this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
            }
          }
        })
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
