<template lang="pug">
  t-dialog(v-bind="$attrs" v-on="listeners" :max-width="cardWidth" @onOpen="onOpen")
    template(#title)
      span(v-if="checkboxOutletsExists || showOutletsForm") {{ title }}
    template(#content)
      m-form-cash-register(v-if="checkboxOutletsExists" :context="context" :selected-item="editedItem" is-dialog-part @dialog:close="close")
      m-form-outlet(v-else-if="showOutletsForm" :context="context" :selected-item="editedItem" is-dialog-part @dialog:close="close" )
      div(v-else-if="onlyTaxOutletsExists")
        p {{ $t('The point of sale is still registered in the tax') }}.
        p {{ $t('After 2-3 minutes, click "Update data"') }}
        e-button-main(
          @click="close"
          :enableIcon="false"
          class="d-block mx-auto mt-7 mb-8"
        ) {{ $t('Okay') }}
      div(
        class="no-outlet-block"
        v-else
      )
        v-img(
          class="no-outlet-block__img"
          :src="require('~/assets/images/racoons/racoon-cash-register.svg')"
        )
        h3(class="no-outlet-block__title") {{ $t('First, add a point of sale') }}
        p(class="no-outlet-block__text") {{ $t('Before you add a cash register, add an outlet') }}
        e-button-main(
          @click="addOutlet"
          :enableIcon="false"
          class="no-outlet-block__btn"
        ) {{ $t('Add outlet') }}
</template>

<script>
import TDialog from '~/components/templates/t-dialog'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import fill from '~/mixins/modules/dialogs/fill'
import MFormCashRegister from '~/components/modules/forms/concrete/m-form-cash-register'
import MFormOutlet from '~/components/modules/forms/concrete/m-form-outlet'
import Outlets from '~/models/directories/Outlets'
import EButtonMain from '~/components/elements/buttons/e-button-main'
import apm from '~/mixins/apm/apm'
import OutletOrder from '~/models/directories/Orders/OutletOrder'
import authUser from '~/modules/auth/authUser'

export default {
  components: {
    MFormCashRegister,
    TDialog,
    MFormOutlet,
    EButtonMain
  },
  mixins: [checkPropCtx, fill, apm],
  props: {
    type: {
      type: String,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    itemLoading: false,
    editedItem: {},
    checkboxOutletsExists: false,
    onlyTaxOutletsExists: false,
    localModel: null,
    showOutletsForm: false
  }),
  computed: {
    dialogCtx () {
      return Object.assign({}, {
        parent: this.selectedItem,
        dialogRefs: this.$refs,
        model: this.localModel.entity
      }, { ...this.ctx })
    },
    dialogConfig () {
      return {
        ...this.localModel.getOrmDialogsConfig(this.type),
        ctx: this.dialogCtx
      }
    },
    context () {
      return this._.get(this.dialogConfig.config, 'context', this.parentContext || this.$config.contexts.create)
    },
    cardWidth () {
      const addOutletMessage = !this.checkboxOutletsExists && !this.showOutletsForm && !this.onlyTaxOutletsExists
      return [addOutletMessage ? 560 : 860, 'px'].join('')
    },
    listeners () {
      const vm = this
      return Object.assign({},
        this.$listeners,
        {
          input (event) {
            vm.clear()
            vm.$emit('input', event)
          }
        }
      )
    },
    isLoading () {
      return this.loading || this.itemLoading
    },
    title () {
      // TODO: create one logic for pattern generation.
      let title, name

      if (this.dialogConfig.title) {
        title = { ...this.dialogConfig.title.split('|') }
      }

      // TODO: move to class config???
      if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
        name = this.dialogConfig.config.modalName
      } else {
        switch (this.context) {
          case this.$config.contexts.create:
            name = 'Add'
            break
          case this.$config.contexts.read:
            name = 'Card of'
            break
          case this.$config.contexts.update:
            name = 'Editing of'
            break
        }
      }

      const modalType = (() => {
        if (title) {
          return this.$tc(title[0], title[1])
        } else if (this.dialogConfig.config && this.dialogConfig.config.ucFirst) {
          return this._.upperFirst(this.$tc(this.localModel.ormTrans.single, 2))
        } else if (this.dialogConfig.config && this.dialogConfig.config.modalName) {
          return this.$tc(this.localModel.ormTrans.single, 1)
        } else {
          return this._.lowerFirst(this.$tc(this.localModel.ormTrans.single, 1))
        }
      })()

      return [
        this.$t(name),
        modalType,
        (this.dialogConfig.config && this.dialogConfig.config.ucFirst) ? this.selectedItem.name : ''
      ].join(' ').trim(' ')
    }
  },
  async created () {
    this.localModel = this.model
    await this.checkOutlets()
  },
  methods: {
    onOpen () {
      this.apmRum({
        transactionName: 'Creating cash register process',
        spanName: 'Cash register creation dialog open'
      })
      this.apmRum({
        transactionName: 'Creating cash register process',
        spanName: 'Outlets check'
      })

      if (this.onlyTaxOutletsExists) {
        this.apmRum({
          transactionName: 'Creating cash register process',
          spanName: 'Only tax outlets exists',
          endTransaction: true
        })
      } else if (this.checkboxOutletsExists) {
        this.apmRum({
          transactionName: 'Creating cash register process',
          spanName: 'User has checkbox outlets'
        })
      } else {
        this.apmRum({
          transactionName: 'Creating cash register process',
          spanName: 'User has no outlet',
          endTransaction: true
        })
      }
    },

    addOutlet () {
      this.showOutletsForm = true
    },

    clear () {
      if (this.showOutletsForm) {
        this.apmRum({
          transactionName: 'Creating outlet process',
          transactionLabels: {
            message: 'User closed dialog without creating'
          },
          endTransaction: true
        })
      } else {
        this.apmRum({
          transactionName: 'Creating cash register process',
          transactionLabels: {
            message: 'User closed dialog without creating'
          },
          endTransaction: true
        })
      }

      setTimeout(() => {
        this.parentContext = this.$config.contexts.create
        this.showOutletsForm = false

        if (!this.checkboxOutletsExists) {
          this.checkOutlets()
        }
      }, 300)
    },

    close () {
      this.$emit('input', false)
      this.clear()
    },

    async checkOutlets () {
      // info: this checks because this role doesn't have access for outlets and user sees error message
      const isEmergencyCashier = authUser.get() && authUser.get().isEmergencyCashier

      if (!this.$Organization || isEmergencyCashier) {
        return
      }
      try {
        this.itemLoading = true
        const checkboxOutlets = await Outlets.api().filter({ mode: Outlets.itemModes.checkbox.mode, limit: 1, offset: 1 }).all(null, {
          save: false
        })
        const taxOutlets = await OutletOrder.api().filter({ type: OutletOrder.orderTypes['100'].value, limit: 1, offset: 1 }).all(null, {
          save: false
        })
        this.checkboxOutletsExists = Boolean(this._.get(checkboxOutlets, 'response.data.meta.totalItems', 0))
        this.onlyTaxOutletsExists = Boolean(this._.get(taxOutlets, 'response.data.meta.totalItems', 0)) && !this.checkboxOutletsExists

        if (!this.checkboxOutletsExists) {
          this.localModel = Outlets
        } else {
          this.localModel = this.model
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-outlet-block {
  &__img {
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 0 auto 32px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    color: $btn-secondary-color;
    margin-bottom: 14px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
    }
  }

  &__text {
    font-weight: 300;
    font-size: 14px;
    margin: 0;
  }

  &__btn {
    margin: 20px 0 20px auto;
    display: block;

    @media (min-width: map-get($breakpoints, 'sm')) {
      margin: 32px 0 32px auto;
    }
  }
}
</style>
