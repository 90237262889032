import { Model } from '@vuex-orm/core'
import PersistAble from '~/models/mixins/PersistAble'
import OrmModel from '~/models/abstracts/base/OrmModel'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import { contexts } from '~/const/global'

export class Iban extends PersistAble(OrmModel) {
  static entity = 'iban'
  static paginated = true

  static ormTrans = {
    single: 'Iban',
    multy: 'Ibans'
  }

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null),
      number: this.attr(null),
      paymentDescription: this.attr(null)
    }
  }

  static ormHeaders = [
    { text: 'Account name', value: 'name', sortable: false, filterable: false },
    { text: 'IBAN of the account', value: 'number', sortable: false, filterable: false },
    { text: 'Purpose of payment', value: 'paymentDescription', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormActions = [
    {
      name: 'edit',
      call: async (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          component: 'block-iban-form',
          width: '900px',
          contentNoGutters: true,
          componentProps: {
            item,
            context: contexts.update
          }
        })
      }
    },
    {
      name: 'delete',
      call: async (item) => {
        try {
          await Iban.api().del(item)
          AppNotifications.success('Iban has successfully deleted!')
        } catch (e) {
          AppNotifications.error(e)
        }
      }
    }
  ]

  static ormMobileTitle = 'number'

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}

export default Iban
