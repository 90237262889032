<template lang="pug">
  div(class="dialog")
    div(class="dialog__title mb-2") {{ $t('Permissions') }}
    div(v-if="pageLoading" class="dialog__loader text-center")
      e-progress-circular(width="4" size="xl" color="#161b25")
    ValidationObserver(ref="form" slim v-else)
      v-form(@submit.prevent.stop="onSubmitForm")
        t-orm-fields(v-bind.sync="form" :items="fields")

        div(class="dialog__actions mt-4")
          v-btn(
            type="submit"
            class="main-button d-block mx-auto"
            :loading="formLoading"
          ) {{ $t('Save') }}
</template>

<script>
import Employees from '~/modules/employees/models/Employees'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  name: 'FormEmployeePermissions',
  components: {
    TOrmFields,
    EProgressCircular
  },
  props: {
    employee: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    form: {
      orders: false
    },
    pageLoading: false,
    formLoading: false
  }),
  computed: {
    fields () {
      return [
        {
          model: 'orders',
          component: 'e-input-switch-tooltip',
          provider: {
            vid: 'orders',
            name: 'Delivery order'
          },
          attrs: {
            label: 'Delivery order'
          }
        }
      ]
    }
  },
  async created () {
    try {
      this.pageLoading = true
      const request = await Employees.api().processingGetPermissions(this._.get(this.employee, 'id'))
      // this.form = this._.get(request, 'response.data') // todo: for future
      this.form.orders = this._.get(request, 'response.data.orders')
    } finally {
      this.pageLoading = false
    }
  },
  methods: {
    async onSubmitForm () {
      const valid = await this.$refs.form.validate()

      if (valid) {
        try {
          this.formLoading = true
          await Employees.api().processingUpdatePermissions(this._.get(this.employee, 'id'), this.form)

          this.$notification.success('Permissions was successfully updated')
          this.closeModal()
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.formLoading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
