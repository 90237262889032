import { Model } from '@vuex-orm/core'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import PersistAble from '~/models/mixins/PersistAble'
import Directory from '~/models/abstracts/Directory'
import i18n from '~/plugins/nuxt-i18n/i18n'

const formattedTime = (time) => {
  const minutes = Math.round(parseFloat((time % 1).toFixed(2)) * 60)
  let formattedTime = Math.floor(parseFloat(time))
  if (minutes) {
    formattedTime += ` ${i18n.t('hours')} ${minutes} ${i18n.t('minutes')}.`
  }
  return formattedTime
}

export default class OfflineStatistic extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'offlinestatistic'
  static paginated = true

  static ormTrans = {
    single: 'Offline statistic',
    multy: 'Offline statistic'
  }

  static itemStatus = {
    offline: {
      text: 'Offline',
      value: 'offline'
    },
    online: {
      text: 'Online',
      value: 'online'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      fiscal_number: this.attr(null),
      offline_status: this.attr(null),
      offline_session: this.attr(null),
      limit_offline_session: this.attr(null),
      offline_per_month: this.attr(null),
      limit_per_month: this.attr(null),
      active_offline_codes_count: this.attr(null),
      limit_offline_codes: this.attr(null),
      start_offline_session: this.attr(null),
      outlet: this.attr(null)
    }
  }

  get offlineStatusString () {
    return this.offline_status ? OfflineStatistic.itemStatus.offline.value : OfflineStatistic.itemStatus.online.value
  }

  get outletString () {
    return this._.get(this.outlet, 'name', '')
  }

  static ormHeaders = [
    { text: 'Fiscal number', value: 'fiscal_number', sortable: false, filterable: false },
    { text: 'Status offline', value: 'offlineStatusString', sortable: false, filterable: false },
    { text: 'Continuous session', value: 'offline_session', sortable: false, filterable: false },
    { text: 'Since the beginning of the month', value: 'offline_per_month', sortable: false, filterable: false },
    { text: 'Offline codes are used', value: 'active_offline_codes_count', sortable: false, filterable: false },
    { text: 'Outlet name', value: 'outletString', sortable: false, filterable: false }
  ]

  static ormFilters = []

  static ormFiltersConfig = {}

  static ormColsComponents = {
    offlineStatusString: {
      component: 'e-models-cols-map',
      attrs: {
        chips: true,
        template: '{text}',
        map: (item) => {
          if (!item) { return {} }
          const offline = OfflineStatistic.itemStatus.offline
          const online = OfflineStatistic.itemStatus.online
          return {
            id: item.id,
            [offline.value]: {
              text: offline.text,
              type: offline.value
            },
            [online.value]: {
              text: online.text,
              type: online.value,
              appearance: {
                color: '#161b25',
                textColor: '#fff'
              }
            }
          }
        }
      }
    },
    offline_session: {
      component: 'e-comparison',
      attrs: {
        comparedValue: (item) => {
          const val = parseFloat((this._.get(item, 'offline_session', 0) / 3600).toFixed(2))
          const time = val.toFixed(Number.isInteger(val) ? 0 : 2)
          return formattedTime(time)
        },
        total: (item) => {
          const val = parseFloat((this._.get(item, 'limit_offline_session', 0) / 3600).toFixed(2))
          return val.toFixed(Number.isInteger(val) ? 0 : 2)
        },
        unitsText: 'hours'
      }
    },
    offline_per_month: {
      component: 'e-comparison',
      attrs: {
        comparedValue: (item) => {
          const val = parseFloat((this._.get(item, 'offline_per_month', 0) / 3600).toFixed(2))
          const time = val.toFixed(Number.isInteger(val) ? 0 : 2)
          return formattedTime(time)
        },
        total: (item) => {
          const val = parseFloat((this._.get(item, 'limit_per_month', 0) / 3600).toFixed(2))
          return val.toFixed(Number.isInteger(val) ? 0 : 2)
        },
        unitsText: 'hours'
      }
    },
    active_offline_codes_count: {
      component: 'e-comparison',
      attrs: {
        comparedValue: (item) => {
          const diff = Math.round(this._.get(item, 'limit_offline_codes', 0) - this._.get(item, 'active_offline_codes_count', 0))
          return diff > 0 ? diff : 0
        },
        total: item => this._.get(item, 'limit_offline_codes'),
        unitsText: 'pieces.'
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getStatistic = function (payload, params = {}) {
        return this.post(Model.$routes.offlinestatistic.list(), payload, { params, dataKey: 'data', persistBy: 'create' })
      }
      return configActions
    }
  }
}
