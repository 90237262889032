<template lang="pug">
  div(class="a-bank-preview-info")
    m-block-banner(
      :image="require(`~/assets/images/pos-terminal-a-bank.png`)"
    )
      template(#text)
        p(class="a-bank-preview-info__text") {{ $t('You get modern banking terminal on Android with checkbox app downloaded') }}
        p(class="a-bank-preview-info__text mb-0") {{ $t('Terms of use of the terminal from ABank:') }}
        ul
          li
            p(class="a-bank-preview-info__text mb-0") {{ $t('acquisition fee') }} 1.7%
          li
            p(class="a-bank-preview-info__text mb-0") {{ _.lowerCase('Terminal rental') }} 500 {{ $t('uah/month') }}
</template>

<script>
import MBlockBanner from '~/components/modules/blocks/concrete/m-block-banner'
export default {
  name: 'BlockABankPreviewInfo',
  components: {
    MBlockBanner
  },
  props: {
    changeTitle: {
      type: Function,
      default: () => {}
    },
    toggleCloseBtn: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  created () {
    this.changeTitle('POS-terminal from Abank')
    this.toggleCloseBtn(true)
  }
}
</script>

<style scoped lang="scss">
.a-bank-preview-info {
  &__text {
    color: #000;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @media (min-width: map-get($breakpoints, 'sm')) {
      flex-direction: row;
    }
  }
}
</style>
