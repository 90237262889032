import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import PersistAble from '~/models/mixins/PersistAble'
import CashRegisterOrder from '~/models/directories/Orders/CashRegisterOrder'
import TimestampAble from '~/models/mixins/TimestampAble'
import PromoCashRegisters from '~/modules/promotion/models/PromoCashRegisters'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import Outlets from '~/models/directories/Outlets'

const additionalData = {}

export class PromoCashRegistersForSelection extends PersistAble(Directory, [
  TimestampAble
]) {
  static entity = 'promocashregistersforselection'
  static paginated = true

  static ormTrans = PromoCashRegisters.ormTrans

  static itemModes = PromoCashRegisters.itemModes

  static fields () {
    return {
      id: this.attr(null),
      active: this.attr(null),
      cashRegister: this.attr(null),
      disableDate: this.attr(null),
      fiscalNumber: this.attr(null),
      oroRegDate: this.attr(null),
      regDate: this.attr(null),
      registrationNumber: this.attr(null),
      usage: this.attr(null),
      usedInShare: this.attr(null),
      cashRegisterTaxOrders: this.attr(null),

      prroType: this.attr(null),
      outlet: this.attr(null)
    }
  }

  get modeString () {
    const {
      rro,
      promotional,
      orderCreated,
      sentToDps
    } = PromoCashRegisters.itemModes
    const orderStatus = PromoCashRegisters.checkTaxOrdersStatus(this)
    if (this._.get(orderStatus, orderCreated.value)) {
      return orderCreated.value
    } else if (this._.get(orderStatus, sentToDps.value)) {
      return sentToDps.value
    } else if (this.cashRegister) {
      return promotional.value
    }
    return rro.value
  }

  get regDateString () {
    return this.getDateTime(this.regDate) || '–'
  }

  get freeToDateString () {
    if (this.regDate) {
      const date = new Date(this.regDate)
      date.setFullYear(date.getFullYear() + 7)
      return this.getDateTime(date)
    }
    return '–'
  }

  static ormHeaders = [
    { text: 'Status', value: 'modeString', align: 'left', width: '120', sortable: true, filterable: true },
    { text: 'Fiscal number', value: 'fiscalNumber', align: 'left', filterable: false, sortable: false },
    { text: 'Registration date', value: 'regDateString', align: 'left', filterable: false, sortable: true },
    { text: 'Free to', value: 'freeToDateString', align: 'left', filterable: false, sortable: true },
    { text: 'Prro type', value: 'prroType', align: 'left', width: '200', filterable: false, sortable: false },
    { text: 'Outlet  ', value: 'outlet', align: 'left', width: '240', filterable: false, sortable: false }
  ]

  static ormMobileTitle = 'fiscalNumber'

  static ormRowsConfig = {
    checkbox: true,
    disabled: false
  }

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormColsComponents = {
    prroType: {
      component: 'e-table-item-select',
      attrs: {
        config: {
          validationProviderAttrs: {
            vid: item => `prroType-${item?.id}`,
            rules: 'required'
          },
          attrs: {
            label: 'Prro type',
            outlined: true,
            hideDetails: true
          },
          classes: ['bg-white'],
          default: () => Object.values(CashRegisterOrder.prroTypes)[0].value,
          items: () => Object.values(CashRegisterOrder.prroTypes),
          onChange: (item, val) => {
            const id = item?.id
            if (!additionalData[id]) {
              additionalData[id] = {}
            }
            additionalData[id].prroType = val
          }
        }
      }
    },
    outlet: {
      component: 'e-table-item-autocomplete',
      attrs: {
        config: {
          validationProviderAttrs: {
            vid: item => `outlet-${item?.id}`,
            rules: 'required'
          },
          attrs: {
            label: 'Outlet  ',
            outlined: true,
            hideDetails: true
          },
          classes: ['bg-white'],
          searchModel: OutletsSearch,
          query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode }),
          onChange: (item, val) => {
            const id = item?.id
            if (!additionalData[id]) {
              additionalData[id] = {}
            }
            additionalData[id].outlet = val
          }
        }
      }
    },
    modeString: PromoCashRegisters.ormColsComponents.modeString
  }

  static ormActions = []

  getAdditionalData () {
    return additionalData[this?.id]
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.createOrder = function (id, payload, options = {}) {
        return this.post(Model.$routes.promocashregistersforselection.createOrder(id), payload)
      }
      return configActions
    }
  }
}

export default PromoCashRegistersForSelection
