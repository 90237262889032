import { render, staticRenderFns } from "./ApplePayButton.vue?vue&type=template&id=5aaf53c0&scoped=true&lang=pug&"
import script from "./ApplePayButton.vue?vue&type=script&lang=js&"
export * from "./ApplePayButton.vue?vue&type=script&lang=js&"
import style0 from "./ApplePayButton.vue?vue&type=style&index=0&id=5aaf53c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aaf53c0",
  null
  
)

export default component.exports