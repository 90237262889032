<template lang="pug">
  v-card(class="finish-card")
    v-row(justify="center" align="center" no-gutters)
      v-col(cols="12")
        v-img(
          class="finish-card__img"
          :src="require('~/assets/images/racoons/racoon-greeting.svg')"
        )
        h2(class="login-wrapper__title text-left mb-2") {{ $t('Completion of registration') }}
        p(class="mb-0") {{ $t('Go to the portal and start working with the most convenient software checkouts') }}

      v-col(cols="12")
        e-button-main(
          :enableIcon="false"
          @click="$attrs.btnCall"
          :loading="$attrs.loading"
          class="d-block mx-auto mt-8"
        ) {{ $t('Log in to the portal') }}
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'

export default {
  components: {
    EButtonMain
  }
}
</script>

<style scoped lang="scss">
.finish-card {
  border-radius: 24px !important;
  overflow: hidden;
  max-width: 548px;
  margin: 0 auto;
  width: 100%;
  padding: 32px;
  box-shadow: none;

  &__img {
    width: 100%;
    max-width: 192px;
    margin: 0 auto 32px;
    display: block;
  }

  &::v-deep {
    .e-button-main {
      min-height: 48px;
      width: 100%;
    }
  }
}
</style>
