<template lang="pug">
  div(class="pt-6")
    div(class="emails")
      div(v-if="_.get(form.emails, 'length')")
        h4(class="emails__title") {{ $t('Subscribers') }}
        div(class="emails__content")
          div(
            v-for="(item, index) in form.emails"
            :key="index"
            class="emails__item"
          )
            p(
              class="emails__email"
              :title="item"
            ) {{ item }}
            v-btn(
              size="sm"
              @click="deleteEmail(index)"
              icon
              small
            )
              e-svg-icon(size="sm") trash
      v-form(@submit.prevent.stop="addNewSubscriber")
        ValidationObserver(ref="input" slim)
          ValidationProvider(
            rules="required|email"
            mode="passive"
            name="E-mail"
            v-slot="{ errors }"
          )
            div(class="input-wrapper")
              v-text-field(
                :label="$t('Add subscriber')"
                v-model="newSubscriber"
                :error-messages="errors"
                outlined
              )
              v-btn(
                class="input-wrapper__btn"
                size="sm"
                type="submit"
                dark
                rounded
              )
                e-svg-icon(size="xsm") plus
    e-input-switch-tooltip(
      v-if="!sendFile"
      v-model="form.active"
      label="Send automatically"
      tooltip="The report will be sent to subscribers automatically"
    )
    e-input-file-content(
      accept=".xls, .xlsx"
      rules="required|ext:xls,xlsx"
      v-model="file"
      expect="dataUrl"
      :label="$t('Select file')"
      prependIcon=""
      appendIcon='$file'
      ref="fileInput"
      mode="lazy"
      outlined
      v-else
    )
    v-btn(
      class="main-button d-block mx-auto"
      :class="{ 'mt-8': !this.sendFile, 'mt-3': this.sendFile }"
      @click="save"
      :loading="loading"
    ) {{ btnText }}
</template>

<script>
import EInputSwitchTooltip from '~/components/elements/inputs/e-input-switch-tooltip'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import EInputFileContent from '~/components/elements/inputs/e-input-file-content'
import MallReport from '~/modules/reports/models/MallReport'

export default {
  name: 'BlockMallReportEmailSettings',
  components: {
    EInputSwitchTooltip,
    ESvgIcon,
    EProgressCircular,
    EInputFileContent
  },
  props: {
    sendFile: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    newSubscriber: '',
    form: {
      emails: [],
      active: false
    },
    file: null
  }),
  computed: {
    model () {
      return MallReport
    },
    btnText () {
      if (this.sendFile) {
        return this.$t('Send')
      }
      return this.$t('Save')
    }
  },
  created () {
    this.setSettings()
  },
  methods: {
    setSettings () {
      const settings = this._.get(this.item, 'mallReportEmailSetting')
      if (settings) {
        this.$set(this.$data, 'form', JSON.parse(JSON.stringify(settings)))
      }
    },
    async addNewSubscriber () {
      const valid = await this.$refs.input.validate()
      if (!valid) {
        return
      }
      if (!this.form.emails) {
        this.form.emails = []
      }
      this.form.emails.push(this.newSubscriber)
      this.newSubscriber = ''
    },
    deleteEmail (index) {
      this.form.emails.splice(index, 1)
    },
    async save () {
      try {
        this.loading = true
        const reportId = this._.get(this.item, 'id')
        let msg = ''

        if (this.sendFile) {
          let validEmailInput = true
          if (!this._.get(this.form, 'emails.length')) {
            validEmailInput = await this.$refs.input.validate()
          }
          const { valid } = await this.$refs.fileInput.validate()
          if (!valid || !validEmailInput) {
            return
          }
          const base64File = this.file.split(',')[1]
          const formCopy = JSON.parse(JSON.stringify(this.form))
          delete formCopy.active
          await this.model.api().sendReport(reportId, {
            channel: 'email',
            settingsEmail: formCopy,
            file: base64File
          })
          msg = 'File sent successfully'
        } else {
          await this.model.api().updateEmailSettings(reportId, this.form)
          msg = 'E-mail report settings saved successfully'
        }

        this.$notification.success(this.$t(msg))
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.emails {
  width: 100%;

  &__content {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 3px;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
  }

  &__item {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__email {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 13px;
  }
}

.input-wrapper {
  display: flex;
  gap: 16px;
  width: 100%;

  &__btn {
    width: 33px;
    height: 33px !important;
    margin-top: 4px;
  }
}

.loader {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
