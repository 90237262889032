import { Model } from '@vuex-orm/core'

export class TranzzoToken extends Model {
  static entity = 'tranzzotoken'

  static fields () {
    return {
      token: this.attr(null),
      expires_at: this.attr(null),
      card_mask: this.attr(null),
      issuer: this.attr(null)
    }
  }
}

export default TranzzoToken
