const paymentTypes = {
  computed: {
    paymentTypes () {
      return {
        cash: {
          value: 'CASH',
          label: this.$t('cash')
        },
        card: {
          value: 'CARD',
          label: this.$t('card')
        },
        other: {
          value: 'OTHER',
          label: 'Other'
        },
        cashless: {
          value: 'CASHLESS'
        },
        monobank: {
          value: 'MONOBANK',
          label: 'Monobank'
        },
        easyPay: {
          value: 'EASYPAY',
          label: 'EasyPay'
        },
        rozetkaPay: {
          value: 'ROZETKAPAY',
          label: 'RozetkaPay'
        },
        ettn: {
          value: 'ETTN',
          label: this.$t('ettn')
        }
      }
    }
  }
}

export default paymentTypes
