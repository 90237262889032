<template lang="pug">
  div(class="hints-block-content")
    h3(v-if="showTitle && !mobile" class="hints-block-content__title") {{ title }}
    div(v-if="currentStep === steps.options")
      p {{ $t('Connect your Tap to Phone to the Checkbox system or register a new one') }}
    div(v-else-if="currentStep === steps.contactForm")
      p {{ $t('Check you contact data, which goes to bank and click "Send"') }}
    div(v-else-if="currentStep === steps.settingsForm")
      p(v-if="!isEditing") {{ $t('This window allows you to specify a login for acquiring, select available terminals to which cash registers are connected') }}
      p(v-else) {{ $t('This window allows you to change the cash register for the terminal you are connected to') }}
    div(v-else-if="currentStep === steps.privatSettingsForm")
      p(v-if="!isEditing") {{ $t('This window allows you to specify a terminal token and connect to cash register') }}
      p(v-else) {{ $t('This window allows you to change the cash register for the terminal you are connected to') }}
    div(v-else-if="currentStep === steps.monoQr")
      p {{ $t('Click or scan QR-code to login and follow instructions') }}
    div(v-else-if="currentStep === steps.oschadPreview" class="hints-block-content__image-wrapper")
      v-img(:src="require('~/assets/images/oschad-tap-to-phone-3.png')" cover width="100%" height="100%")
    div(v-else-if="currentStep === steps.oschadOrderInfo" class="hints-block-content__image-wrapper")
      v-img(:src="require('~/assets/images/paper-signs.png')")
    div(v-else-if="currentStep === steps.uploadDocuments")
      p {{ $t('These documents are required for authorization without coming to bank') }}
      p {{ $t('Download acceptable document formats *.pdf *.jpg *.png *.doc') }}
</template>

<script>
export default {
  name: 'BlockTapToPhoneHintContent',
  props: {
    steps: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return `💡 ${this.$t('Hint')}`
    },
    showTitle () {
      return ![this.steps.oschadPreview, this.steps.oschadOrderInfo].includes(this.currentStep)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
