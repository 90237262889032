<template lang="pug">
  div(class="dialog")
    v-img(
      class="dialog__img"
      :src="require('~/assets/images/racoons/racoon-handshake.svg')"
    )
    div(class="dialog__title") {{ $t(title) }}
    div(class="mb-9")
      p(class="dialog__text") {{ $t(text) }}
      e-link(
        v-if="!error"
        @click="openAgreement"
        class="d-block"
      )
        e-svg-icon(class="mr-1") file-text
        span {{ $t('DepositSign Service Agreement') }}
    div(class="dialog__actions")
      v-btn(
        @click="handleClick"
        :loading="loading"
        class="main-button"
      ) {{ $t(btnText) }}
</template>

<script>
import Base64js from 'base64-js'
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EmployeeOrder from '~/modules/employees/models/EmployeeOrder'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'

export default {
  name: 'BlockSignDsPdf',
  components: {
    ESvgIcon,
    ELink
  },
  mixins: [edsIitCheckboxDialog],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    depositSignRequest: {
      type: null,
      default: null
    },
    item: {
      type: null,
      default: null
    }
  },
  data: () => ({
    loading: false,
    error: false
  }),
  computed: {
    model () {
      return EmployeeOrder
    },
    title () {
      if (this.error) {
        return 'The document was not signed'
      }
      return 'Acceptance of DepositSign Terms'
    },
    text () {
      if (this.error) {
        return 'Something went wrong and the document could not be signed. Please try again'
      }
      return 'Review and confirm the DepositSign Terms of Service. After approval, we will generate and add to the system the EDS key created through our DepositSign partners to your account'
    },
    btnText () {
      if (this.error) {
        return 'Sign again'
      }
      return 'Agree and sign'
    }
  },
  methods: {
    openAgreement () {
      const link = `data:application/pdf;base64,${this._.get(this.depositSignRequest, 'pdf')}`
      const downloadLink = document.createElement('a')
      downloadLink.href = link
      downloadLink.download = 'DepositSign_Service_Agreement.pdf'
      downloadLink.click()
    },
    async handleClick () {
      if (this.error) {
        this.error = false
      } else {
        await this.signAndSend()
      }
    },
    async signAndSend () {
      try {
        this.loading = true
        await this.useEdsIitCheckboxDialog({
          fallbackMethod: this.signAndSend,
          checkAuthService: true,
          pluginTitles: {
            options: this.$t('Signing the contract with an electronic digital signature key')
          },
          hints: {
            options: this.$t('signPlugin.request'),
            fileSign: this.$t('signPlugin.directorSign'),
            smartId: this.$t('signPlugin.smartId')
          },
          onConfirm: async ({ error, noReturn, sign }) => {
            try {
              if (error) {
                return !noReturn ? this.signAndSend() : null
              }

              const pdf = Base64js.toByteArray(this._.get(this.depositSignRequest, 'pdf', null))
              const signedData = await sign(pdf, {
                fallbackMethod: this.signAndSend
              })

              if (!signedData) {
                this.error = true
                return null
              }

              await this.model.api().depositSignRequest(this._.get(this.depositSignRequest, 'id'), {
                signedApplicationBase64: signedData
              })
              await this.model.api().read(this._.get(this.item, 'id'))
              this.closeModal()
            } catch (e) {
              this.error = true
              this.$handlers.error(e, this)
            }
          }
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  padding: 16px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    padding: 16px 32px 32px;
  }

  &__img {
    width: 100%;
    max-width: 130px;
    margin: 50px auto 28px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      max-width: 176px;
    }
  }

  &__title {
    padding: 0;
    margin-bottom: 24px;
    font-size: 18px !important;
    font-weight: 700;
    color: $btn-secondary-color;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px !important;
    }
  }

  &__text {
    font-size: 12px;
    font-weight: 300;
    color: rgba(22, 27, 37, 0.75);
    margin-bottom: 16px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 14px;
    }
  }

  &__actions {
    padding: 0;
    display: flex;
    justify-content: flex-end;

    .main-button {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        width: auto;
      }
    }
  }
}
</style>
