<template lang="pug">
  div(class="dialog")
    div(
      v-if="loading"
      class="dialog__loader-wrap"
    )
      p(class="dialog__text") {{ $t('Wait for the archive to be created') }}
      e-progress-circular(
        color="#161b25"
        size="xl"
        width="4"
      )
      p(class="dialog__text") {{ $t('It may take a few minutes') }}
    div(v-else)
      div(class="dialog__title mb-4") {{ $t('Download receipts') }}
      ValidationObserver(ref="form" slim)
        v-form(@submit.prevent.stop="download")
          t-orm-fields(
            v-bind.sync="form"
            :items="fields"
          )
          div(class="dialog__actions text-center mt-2")
            v-btn(
              :loading="loading"
              type="submit"
              class="main-button"
            ) {{ $t('Download') }}
</template>

<script>
import Download from 'js-file-download'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import { formats } from '~/const/global'
import reports from '~/modules/reports/mixins/reports'
import Receipts from '~/modules/receipt/models/Receipts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'

export default {
  components: {
    EProgressCircular,
    TOrmFields
  },
  mixins: [reports],
  props: {
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    form: {
      dateRange: [],
      offlineReceipts: false
    },
    loading: false,
    request: {}
  }),
  computed: {
    model () {
      return Receipts
    },
    fields () {
      return [
        {
          model: 'dateRange',
          type: 'dateRange',
          component: 'e-input-datetime-range',
          default: () => {
            const date = new Date()
            date.setMonth(date.getMonth() - 1)
            return [this.$moment(date).format(formats.dateISO8601), this.$moment(new Date()).format(formats.dateISO8601)]
          },
          provider: {
            vid: 'dateRange',
            name: 'Fiscal date',
            rules: 'required'
          },
          attrs: {
            closeOnClick: false,
            clearable: false,
            emmitOnlyOnSelectClicked: true,
            type: 'date',
            range: true,
            outlined: true,
            label: 'Fiscal date',
            min: (dates) => {
              if (dates && dates.length === 1) {
                const date = new Date(dates[0])
                date.setMonth(date.getMonth() - 3)
                return this.$moment(date).format(formats.dateISO8601)
              }
            },
            max: (dates) => {
              const today = new Date()
              const formattedToday = this.$moment(today).format(formats.dateISO8601)
              if (dates && dates.length === 1) {
                const date = new Date(dates[0])
                date.setMonth(date.getMonth() + 3)
                if (date.getTime() > today.getTime()) {
                  return formattedToday
                }
                return this.$moment(date).format(formats.dateISO8601)
              }
              return formattedToday
            }
          }
        },
        {
          model: 'offlineReceipts',
          component: 'e-input-switch-tooltip',
          provider: {
            vid: 'offlineReceipts',
            name: 'Only offline receipts'
          },
          attrs: {
            label: 'Only offline receipts'
          }
        }
      ]
    }
  },
  beforeDestroy () {
    if (typeof this.request.stopExponential === 'function') {
      this.request.stopExponential()
    }
  },
  methods: {
    checkStatus (id) {
      const resolveStatus = 'DONE'
      const request = new IntervalRequest(() => this.model.api().processingExportStatus(id), {
        interval: 1e3,
        count: 300,
        maxDelay: 18e5 // ~30 minutes
      })
      this.request = request
      return request.startExponential(response => this._.get(response, 'response.data.status', null) === resolveStatus)
    },
    async download () {
      try {
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        this.loading = true
        const dateRange = this._.get(this.form, 'dateRange', [])
        const payload = {
          from_date: this.startDate(dateRange),
          to_date: this.endDate(dateRange),
          is_created_offline: this.form.offlineReceipts
        }
        const taskId = this._.get(await this.model.api().processingExport(payload), 'response.data.id', null)
        const resolved = this._.get(await this.checkStatus(taskId), 'response.data', null)

        if (resolved) {
          const blob = this._.get(await this.model.api().processingExportGetReceipts(taskId), 'response.data', null)
          Download(blob, 'receipts.zip')
        }

        if (this.closeModal) {
          this.closeModal(true)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__loader-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 30px 0 25px;
    flex-direction: column;
  }
  &__text {
    font-size: 16px;
    margin: 0;
  }
}
</style>
