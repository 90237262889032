import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import i18n from '~/plugins/nuxt-i18n/i18n'

export default class AccountingAutomation extends ChainInheritance(Directory, []) {
  static entity = 'accountingautomation'
  static paginated = true
  static ormLoadWithRelations = false
  static defaultSortParam = 'date'

  static ormTrans = {
    single: 'Accounting automation',
    multy: 'Accounting automations'
  }

  static statuses = {
    created: {
      value: 'created',
      text: i18n.t('New')
    },
    running: {
      value: 'running',
      text: i18n.t('Launched')
    },
    need_to_check: {
      value: 'need_to_check',
      text: i18n.t('Needs verification')
    },
    critical_error: {
      value: 'critical_error',
      text: i18n.t('Critical startup error')
    },
    has_manual_checking_errors: {
      value: 'has_manual_checking_errors',
      text: i18n.t('It has errors when checking manually')
    },
    has_automation_checking_errors: {
      value: 'has_automation_checking_errors',
      text: i18n.t('It has errors during automatic proofreading')
    },
    checked: {
      value: 'checked',
      text: i18n.t('No errors')
    }
  }

  static actFileStatuses = {
    queued: {
      value: 'queued',
      text: i18n.t('Added to queue')
    },
    running: {
      value: 'running',
      text: i18n.t('Launched')
    },
    done: {
      value: 'done',
      text: i18n.t('Files generated')
    },
    error: {
      value: 'error',
      text: i18n.t('Startup error')
    }
  }

  static sendingTaxBillStatuses = {
    queued: {
      value: 'queued',
      text: i18n.t('Added to queue')
    },
    running: {
      value: 'running',
      text: i18n.t('Launched')
    },
    done: {
      value: 'done',
      text: i18n.t('TI sent')
    },
    error: {
      value: 'error',
      text: i18n.t('Startup error')
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      date: this.attr(null),
      status: this.attr(null),
      lastExecutedAt: this.attr(null),
      automationResult: this.attr([]),
      manualCheckResult: this.attr(null),
      automatedCheckResult: this.attr([]),
      errors: this.attr([]),
      executionTime: this.attr(null),
      executionCount: this.attr(null),
      actsFileStatus: this.attr(null),
      actsFileResults: this.attr([]),
      sendingTaxBillStatus: this.attr(null),
      sendingTaxBillResults: this.attr([])
    }
  }

  get dateString () {
    return this.date.match(/(\d{4}-\d{2}-\d{2})/g)[0]
  }

  get lastExecutedAtString () {
    return this.getDateTime(this.lastExecutedAt)
  }

  get statusString () {
    return this.status ? AccountingAutomation.statuses[this.status].text : '-'
  }

  get actsFileStatusString () {
    return this.actsFileStatus ? AccountingAutomation.actFileStatuses[this.actsFileStatus].text : '-'
  }

  get sendingTaxBillStatusString () {
    return this.sendingTaxBillStatus ? AccountingAutomation.sendingTaxBillStatuses[this.sendingTaxBillStatus].text : '-'
  }

  static ormHeaders = [
    { text: 'Date', value: 'dateString', sortable: true, filterable: false, sortQuery: 'date' },
    { text: 'Status', value: 'statusString', sortable: false, filterable: true },
    { text: 'Last executed at', value: 'lastExecutedAtString', sortable: false, filterable: false },
    { text: 'Execution Count', value: 'executionCount', sortable: false, filterable: false },
    { text: 'Acts file status', value: 'actsFileStatusString', sortable: false, filterable: false },
    { text: 'Sending tax bill status', value: 'sendingTaxBillStatusString', sortable: false, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      attrs: {
        label: 'Status',
        outlined: true,
        'hide-details': true
      },
      default: () => '',
      items: () => [{ text: i18n.t('All'), value: '' }, ...Object.values(this.statuses)]
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'status'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = []

  static ormActions = [
    {
      name: 'read',
      visible: (item, user) => {
        return user?.isAutomationAccountant || user?.isAutomationDeveloper
      }
    },
    {
      name: 'set_check_result',
      text: i18n.t('Specify the result of the check'),
      icon: {
        text: 'check',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return (user?.isAutomationAccountant || user?.isAutomationDeveloper) && item.status === this.statuses.need_to_check.value
      },
      call: (item) => {
        const contentDialog = Dialog.query().where('type', 'content').first()
        contentDialog.open({
          title: 'Set check result',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Set',
            fields: [
              {
                model: 'status',
                component: 'v-select',
                provider: {
                  vid: 'status',
                  name: 'Status',
                  rules: 'required'
                },
                attrs: {
                  label: 'Status',
                  outlined: true
                },
                default: () => '',
                items: () => [this.statuses.has_manual_checking_errors, this.statuses.checked]
              },
              {
                name: 'manualCheckResult',
                model: 'manualCheckResult',
                component: 'v-textarea',
                provider: {
                  vid: 'manualCheckResult',
                  name: 'Manual check result'
                },
                attrs: {
                  outlined: true,
                  label: 'Manual check result'
                }
              }
            ],
            successMessage: 'Successfully set',
            onSubmit: async (data) => {
              await AccountingAutomation.api().setCheckResult(item.id, data)
              await contentDialog.close()
            }
          }
        })
      }
    },
    {
      name: 'set_fixed_status',
      text: i18n.t('Set status fixed'),
      icon: {
        text: 'squares',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return user?.isAutomationDeveloper && (item.status === this.statuses.has_manual_checking_errors.value || item.status === this.statuses.has_automation_checking_errors.value)
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: i18n.t('Set status to "Requires review" for this automation (date {date})?', { date: item.dateString }),
          width: '500px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              const data = {
                status: this.statuses.need_to_check.value
              }
              await AccountingAutomation.api().setNeedToCheck(item.id, data)
              AppNotifications.success(i18n.t('Successfully set'))
              await dialog.close()
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    },
    {
      name: 'generate_act_file',
      text: i18n.t('Generate act files'),
      icon: {
        text: 'act',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return (user?.isAutomationDeveloper || user?.isAutomationAccountant) && (item.status === this.statuses.checked.value && item.actsFileStatus !== this.actFileStatuses.done.value)
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: i18n.t('Start generation of act files with date {date}?', { date: item.dateString }),
          width: '500px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await AccountingAutomation.api().generateActsFile(item.id)
              AppNotifications.success(i18n.t('Generation of acts has started'))
              await dialog.close()
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    },
    {
      name: 'send_tax_bills',
      text: i18n.t('Send tax invoices'),
      icon: {
        text: 'taxes-report',
        type: 'e-svg-icon'
      },
      visible: (item, user) => {
        return (user?.isAutomationDeveloper || user?.isAutomationAccountant) && (item.status === this.statuses.checked.value && item.sendingTaxBillStatus !== this.sendingTaxBillStatuses.done.value)
      },
      call: async (item) => {
        const dialog = Dialog.query().where('type', 'confirmation').first()
        await dialog.open({
          title: i18n.t('Start start sending TI with date {date}?', { date: item.dateString }),
          width: '500px',
          buttonText: {
            approve: 'Yes',
            dismiss: 'No'
          },
          onConfirm: async () => {
            try {
              await AccountingAutomation.api().sendTaxBills(item.id)
              AppNotifications.success(i18n.t('Tax invoices have been sent'))
              await dialog.close()
            } catch (e) {
              AppNotifications.error(e)
            }
          }
        })
      }
    }
  ]

  static ormDialogs = {
    read: 'm-orm-view-dialog'
  }

  static ormDialogsConfig = {
    read: {
      title: item => ({ name: `${i18n.t('Launch details from')} ${item.dateString}` }),
      config: {
        context: 'read',
        fields: [
          {
            model: 'dateString',
            label: 'Date'
          },
          {
            model: 'statusString',
            label: 'Status'
          },
          {
            model: 'lastExecutedAtString',
            label: 'Last executed at'
          },
          {
            model: 'automationResult',
            label: 'Automation result'
          },
          {
            model: 'automatedCheckResult',
            label: 'Automation check result'
          },
          {
            model: 'manualCheckResult',
            label: 'Manual check result'
          },
          {
            model: 'Errors',
            label: 'Errors'
          },
          {
            model: 'executionTime',
            label: 'Execution time (s)'
          },
          {
            model: 'executionCount',
            label: 'Execution count'
          },
          {
            model: 'actsFileStatus',
            label: 'Acts file status'
          },
          {
            model: 'actsFileResults',
            label: 'Results of generation of act files'
          },
          {
            model: 'sendingTaxBillStatus',
            label: 'Sending tax bill status'
          },
          {
            model: 'sendingTaxBillResults',
            label: 'Results of sending TI'
          }
        ]
      }
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.setCheckResult = function (id, payload) {
        return this.put(Model.$routes.accountingautomation.setCheckResult(id), payload)
      }
      configActions.setNeedToCheck = function (id, payload) {
        return this.put(Model.$routes.accountingautomation.setNeedToCheck(id), payload)
      }
      configActions.generateActsFile = function (id) {
        return this.put(Model.$routes.accountingautomation.generateActFile(id), {})
      }
      configActions.sendTaxBills = function (id) {
        return this.put(Model.$routes.accountingautomation.sendTaxBills(id), {})
      }
      return configActions
    }
  }
}
