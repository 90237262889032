import { Model } from '@vuex-orm/core'

class AuthenticatedCashier extends Model {
  static entity = '_processing_authenticated_cashier';

  static fields () {
    return {
      id: this.uid(),
      cashier: this.attr(null),
      cashRegister: this.attr(null),
      accessToken: this.attr(null),
      shift: this.attr(null),
      signType: this.attr(null),
      signAgent: this.attr(null),
      payLinkTerminal: this.attr(null),
      acquiringSetting: this.attr(null),
      ettnSettings: this.attr(null)
    }
  }

  async saveData (data) {
    Object.assign(this, data)
    await this.$save()
  }

  async maybeStartSignAgent () {
    if (this.signAgent && this.signType === 'eds_key' && !this.signAgent.opened) {
      await this.signAgent.open()
      return true
    } else {
      return false
    }
  }
}

export default AuthenticatedCashier
