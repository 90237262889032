<template lang="pug">
  div(v-if="item")
    span() {{ item.name }}
    v-tooltip( v-if="item.isCloudSign" top)
      template(#activator="{on}")
        v-btn(icon v-on="on")
          e-svg-icon(size="sm")
            | {{ iconName }}
      | {{ $t(tooltipText) }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
export default {
  components: { ESvgIcon },
  props: {
    value: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    hasUnloadedKey () {
      return this.item.isCloudSign2 && !this._.get(this.item, 'edsKey.signAgent', null)
    },
    iconName () {
      if (this.hasUnloadedKey) {
        return 'key'
      } else {
        return 'key_green'
      }
    },
    tooltipText () {
      if (this.hasUnloadedKey) {
        return 'The key is launched on a secure cloud service. The signature agent is not running'
      } else {
        return 'The key is launched on a secure cloud service. The signing agent is running'
      }
    }
  }
}
</script>

<style scoped>

</style>
