import { render, staticRenderFns } from "./block-tap-to-phone-status.vue?vue&type=template&id=c0a40458&scoped=true&lang=pug&"
import script from "./block-tap-to-phone-status.vue?vue&type=script&lang=js&"
export * from "./block-tap-to-phone-status.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0a40458",
  null
  
)

export default component.exports