import get from 'lodash/get'
import cyrillicToTranslit from 'cyrillic-to-translit-js'
import { EndUser } from '~/lib/EUSignWidget/eusign'

export default class EdsKeyData {
  keyData = null

  constructor (keyData) {
    this.loadKeyData(keyData)
  }

  loadKeyData (keyData) {
    this.keyData = keyData
  }

  hasKeyData () {
    return !!this.keyData
  }

  _checkKeyData () {
    if (!this.hasKeyData()) {
      throw new Error('Key data was not loading or empty')
    }
  }

  isSeal () {
    this._checkKeyData()
    return !this.drfoCode
  }

  dstu1445Supports () {
    return this.signAlgos.includes(EndUser.PublicKeyType.DSTU4145)
  }

  get publicKeyId () {
    return get(this.keyData, '[0].infoEx.publicKeyID', null)
  }

  get identity () {
    return this.edrpouCode || this.drfoCode
  }

  get edrpouCode () {
    // workaround for privat keys
    if (/^[A-Za-zА-Яа-я]{2,4}\d{6}$/.test(this.drfoCode)) {
      return this.drfoCode
    } else { return get(this.keyData, '[0].infoEx.subjEDRPOUCode', null) }
    // return cyrillicToTranslit({ preset: 'uk' }).reverse(get(this.keyData, '[0].infoEx.subjEDRPOUCode', null))
  }

  get drfoCode () {
    return cyrillicToTranslit({ preset: 'uk' }).reverse(get(this.keyData, '[0].infoEx.subjDRFOCode', null))
  }

  get subject () {
    return get(this.keyData, '[0].infoEx.subject', null)
  }

  get email () {
    return get(this.keyData, '[0].infoEx.subjEMail', null)
  }

  get subjFullName () {
    return get(this.keyData, '[0].infoEx.subjFullName', null)
  }

  get signAlgos () {
    if (this.hasKeyData()) { return [] }
    const signAlgos = []
    for (let i = 0; i < this.keyData.length; i++) {
      const infoEx = this.keyData[i].infoEx

      if ((infoEx.keyUsageType & EndUser.KeyUsage.DigitalSignature) !== EndUser.KeyUsage.DigitalSignature) {
        continue
      }

      switch (infoEx.publicKeyType) {
        case EndUser.PublicKeyType.DSTU4145:
          signAlgos.push(EndUser.SignAlgo.DSTU4145WithGOST34311)
          break
        case EndUser.PublicKeyType.RSA:
          signAlgos.push(EndUser.SignAlgo.RSAWithSHA)
          break
        case EndUser.PublicKeyType.ECDSA:
          signAlgos.push(EndUser.SignAlgo.ECDSAWithSHA)
          break
      }
    }

    return signAlgos
  }
}
