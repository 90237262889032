import auth from '~/modules/auth/auth'

export default function ({ redirect, route }) {
  if (auth.isAuthenticated()) {
    if (route.query && route.query.rro_promotion) {
      return redirect('/dashboard/promocashregisters')
    }
    return redirect('/dashboard')
  }
}
