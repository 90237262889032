<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    v-form(@submit.prevent.stop="save")
      v-row()
        v-col(cols="12" v-if="!isUpdate")
          v-btn(class="mr-4" :dark="isProduct" @click="changeEntityType(entityTypes.good)") {{ $t('The product (has a balance)') }}
          v-btn(:dark="!isProduct" @click="changeEntityType(entityTypes.service)") {{ $t('Service (no balance)') }}
        v-col(cols="12" class="pt-1 mb-1")
          v-tabs(
            v-model="tab"
            class="mb-5"
          )
            v-tab {{ $t('The main ones') }}
            v-tab {{ $t('Additional ') }}
          v-tabs-items(
            v-model="tab"
            :show-arrows="false"
          )
            v-tab-item
              t-orm-fields(
                v-bind.sync="productItem"
                :items="fields"
              )
            v-tab-item
              p(class="uploader__subtitle") {{ $t('Image') }}
              m-block-uploader(
                type="image"
                icon="landscape"
                ref="uploader"
                :title="$t('Upload an image to illustrate the product')"
                :subtitle="$t('PNG or JPG (recommendation 150×100px)')"
                :accept="accept"
                :rules="rules"
                :validation-field-name="$t('Image')"
                :files.sync="files"
                :preview-image="goodThumbnail"
                validation-mode="lazy"
                :has-image-been-hidden="hideThumbnailLocally"
                @deleteImage="onImageRemove"
              )
              div(v-if="useBranches" class="pb-5")
                div(
                  v-for="(item, index) in branchesInfo"
                  :key="index"
                )
                  div(class="d-flex justify-space-between align-center mb-3")
                    span(class="label") {{$t('Outlet  ') + (index !== 0 ? ` ${index + 1}` : '') }}
                    e-link(v-if="index" @click="removeOutletFields(index)" class="link delete-btn") {{ $t('Delete') }}
                  t-orm-fields(
                    v-bind.sync="item"
                    :items="branchesFields"
                    :key="branchesFieldsKey"
                  )
                e-link(@click="addOutletFields" class="link") + {{ $t('Add outlet') }}
              div(v-if="isProduct && !isUpdate")
                div(@click="checkIfLeftoversAvailable" style="display: inline-block;")
                  t-orm-fields(
                    v-bind.sync="switchers"
                    :items="leftoversSwitcher"
                  )
                t-orm-fields(
                  v-if="switchers.leftovers"
                  v-bind.sync="leftoversItem"
                  :items="leftoversFields"
                  class="mt-6"
                )

              t-orm-fields(
                v-bind.sync="switchers"
                :items="childrenSwitcher"
                :class="[(switchers.leftovers || isUpdate) ? 'mt-0' : 'mt-7']"
              )
              t-orm-fields(
                v-if="switchers.children"
                v-bind.sync="productItem"
                :items="childrenAutoselect"
                class="mt-6"
              )
      v-row(class="mb-4")
        v-col(cols="12")
          v-btn(
            class="main-button d-block mx-auto"
            type="submit"
            :loading="itemLoading"
          ) {{ $t(btnText) }}
</template>

<script>
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import validate from '~/mixins/validation/validate'
import filterOrmObject from '~/mixins/orm/filterOrmObject'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import taxAuth from '~/mixins/pages/tax-auth'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import ELink from '~/components/elements/links/e-link'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import GoodsGroupsSearch from '~/modules/goods/models/GoodsGroupsSearch'
import Taxes from '~/modules/tax-rates/models/Taxes'
import UktzedDictionary from '~/models/directories/dictionaries/UktzedDictionary'
import AccountingGoodsSearch from '~/modules/goods/models/AccountingGoodsSearch'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import actions from '~/modules/goods/mixins/actions'
import SuppliersSearch from '~/modules/goods/models/SuppliersSearch'
import AccountingGoodsSettings from '~/modules/goods/models/AccountingGoodsSettings'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import Outlets from '~/models/directories/Outlets'
import scrollToFailedValidation from '~/mixins/methods/scrollToFailedValidation'
import MBlockUploader from '~/components/modules/blocks/concrete/m-block-uploader'
import images from '~/modules/goods/mixins/images'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'

export default {
  components: { TOrmButtons, TOrmFields, ELink, TOrmLabel, MBlockUploader },
  mixins: [validate, filterOrmObject, checkPropCtx, taxAuth, actions, scrollToFailedValidation, images, confirmationDialog],
  props: {
    context: {
      type: String,
      default: 'create'
    },
    selectedItem: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    },
    selectedGroup: {
      type: Object,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    model: AccountingGoods,
    productItem: {
      type: AccountingGoods.TYPES_MAP.good,
      name: null,
      code: null,
      group: null,
      taxes: null,
      barcodes: null,
      uktzed: null,
      price: null,
      is_weight: false,
      children: null
    },
    leftoversItem: {
      count: null,
      purchase_price: null,
      supplier_id: null
    },
    switchers: {
      leftovers: false,
      children: false
    },
    branchesInfo: [
      {
        branch_id: null,
        price: null,
        supplier_id: null,
        count: null,
        purchase_price: null
      }
    ],
    itemLoading: false,
    tab: 0,
    branchesFieldsKey: 0,
    files: [],
    hideThumbnailLocally: false
  }),
  computed: {
    useBranches () {
      return this._.get(AccountingGoodsSettings.query().first(), 'use_branches')
    },
    useLeftovers () {
      return this._.get(AccountingGoodsSettings.query().first(), 'use_leftovers')
    },
    defaultBranchId () {
      return this._.get(AccountingGoodsSettings.query().first(), 'default_branch')
    },
    entityTypes () {
      return this.model.TYPES_MAP
    },
    isProduct () {
      return this.productItem.type === this.entityTypes.good
    },
    isUpdate () {
      return this.context === 'update'
    },
    branchesFields () {
      const showLeftoversField = this.isProduct && !this.isUpdate && this.useLeftovers
      return [
        new AutocompleteBuilder({
          model: 'branch_id',
          label: 'Outlet  ',
          itemClass: 'ws-pre',
          returnObject: true,
          query: model => model.api().filter({ mode: Outlets.itemModes.checkbox.mode }),
          chipText: item => this._.get(item, 'name', '-'),
          itemValue: item => this._.get(item, 'id', null),
          cacheItems: true,
          useDefaultSearchFilter: true,
          cols: {
            cols: 12,
            md: 6
          }
        }, OutletsSearch).build(),
        {
          model: 'price',
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          moneyFlag: true,
          component: 'v-text-field',
          provider: {
            name: 'Price, ₴ per unit.',
            vid: 'pricePerOutlet',
            rules: 'required_if:branch_id|money'
          },
          attrs: {
            label: 'Price, ₴ per unit.',
            outlined: true,
            type: 'number',
            min: 0,
            step: 0.01
          },
          cols: {
            cols: 12,
            md: 6
          },
          fieldVal: (e) => {
            const prevPrice = this._.get(e, 'attrs.price') ? (this._.get(e, 'attrs.price') / 100).toFixed(2) : 0
            return prevPrice || (this._.get(this.productItem, 'price', 0) / 100).toFixed(2)
          }
        },
        new AutocompleteBuilder({
          model: 'supplier_id',
          label: 'Provider',
          query: model => model.api(),
          addEntityBtn: {
            text: '+ ' + this.$t('Add provider'),
            call: async () => await this.addProvider()
          },
          deleteEntityBtn: true,
          rules: e => (this._.get(e, 'attrs.purchase_price') || this._.get(e, 'attrs.count')) ? 'required' : '',
          visible: e => this._.get(e, 'attrs.branch_id') && showLeftoversField
        }, SuppliersSearch).build(),
        {
          cast: (val) => {
            const value = val ? parseFloat(val).toFixed(this.productItem.is_weight ? 3 : 0) : 0
            return Math.round((value * 1000)) || null
          },
          model: 'count',
          component: 'v-text-field',
          provider: {
            name: this._.get(this.productItem, 'is_weight') ? 'Initial balance, kg' : 'Initial balance, pcs',
            vid: 'branchesInfoCount',
            rules: (e) => {
              const isWeight = this._.get(this.productItem, 'is_weight')
              const isRequired = this._.get(e, 'attrs.supplier_id') || this._.get(e, 'attrs.purchase_price')
              const rules = [isRequired ? 'required|' : '', isWeight ? 'decimal_number' : 'integer']
              return rules.join('')
            }
          },
          attrs: {
            label: this._.get(this.productItem, 'is_weight') ? 'Initial balance, kg' : 'Initial balance, pcs',
            type: 'number',
            outlined: true,
            min: 0,
            step: 1,
            visible: e => this._.get(e, 'attrs.branch_id') && showLeftoversField
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          model: 'purchase_price',
          component: 'v-text-field',
          provider: {
            name: 'Purchase price, ₴',
            vid: 'branchesInfoPurchasePrice',
            rules: e => (this._.get(e, 'attrs.supplier_id') || this._.get(e, 'attrs.count')) ? 'required|money' : ''
          },
          attrs: {
            label: 'Purchase price, ₴',
            outlined: true,
            type: 'number',
            min: 0,
            step: 0.01,
            visible: e => this._.get(e, 'attrs.branch_id') && showLeftoversField
          },
          cols: {
            cols: 12,
            md: 6
          }
        }
      ]
    },
    fields () {
      return [
        {
          label: {
            text: 'General information',
            className: 'mb-3'
          },
          model: 'name',
          component: 'v-text-field',
          provider: {
            name: 'Name',
            vid: 'name',
            rules: 'required'
          },
          attrs: {
            label: 'Name',
            required: true,
            outlined: true
          },
          cols: {
            cols: 12
          }
        },
        {
          model: 'code',
          component: 'v-text-field',
          provider: {
            vid: 'code',
            name: 'Code',
            rules: 'required'
          },
          attrs: {
            label: 'Code',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        new AutocompleteBuilder({
          model: 'group',
          label: 'Product group',
          cols: {
            cols: 12,
            md: 6
          },
          query: model => model.api(),
          asyncDefault: (items) => {
            const selectedGroupId = this._.get(this.selectedGroup, 'id')
            if (selectedGroupId) {
              const foundGroup = items.find(item => item.id === selectedGroupId)
              if (foundGroup) {
                return foundGroup
              } else {
                GoodsGroupsSearch.insert({
                  data: {
                    id: selectedGroupId,
                    name: this._.get(this.selectedGroup, 'name')
                  }
                })
                return GoodsGroupsSearch.query().whereId(selectedGroupId).first()
              }
            }
          },
          addEntityBtn: {
            text: '+ ' + this.$t('Add a new group'),
            call: async () => await this.addGroup()
          }
        }, GoodsGroupsSearch).build(),
        {
          model: 'taxes',
          component: 'v-select',
          provider: {
            name: 'Group taxes',
            vid: 'taxes'
          },
          default: [],
          attrs: {
            label: 'Group taxes',
            outlined: true,
            multiple: true,
            'return-object': true,
            'item-text': value => value.symbol + ', ' + value.rate + '%' + ', ' + value.label,
            'item-disabled': value => value.disabled
          },
          async request () {
            Taxes.deleteAll()
            await Taxes.api().all()
          },
          items: () => this.validateSelectTaxes(Taxes.all())
        },
        {
          model: 'barcodes',
          component: 'e-input-scanner',
          provider: {
            name: 'Barcodes',
            vid: 'barcodes'
          },
          attrs: {
            label: 'Barcodes',
            outlined: true,
            generateButton: true,
            multiple: true
          },
          cols: {
            cols: 12,
            md: 6
          },
          hint: 'You can specify multiple barcodes using a ","'
        },
        {
          cast: val => this._.trim(val),
          model: 'uktzed',
          component: 'e-dialog-input',
          provider: {
            name: 'Uktzed',
            vid: 'uktzed',
            rules: 'uktzed'
          },
          tooltip: 'Uktzed',
          attrs: {
            label: 'Uktzed',
            class: 'mb-4',
            valuePattern: '{code}',
            requestItems: 'onlyRootLevel',
            isInputField: true,
            modalAttrs: {
              component: 'm-orm-dialog-tree-select',
              title: 'Select Uktzed code',
              model: UktzedDictionary,
              returnField: true,
              returnedFieldName: 'code'
            },
            visible: () => {
              return this.productItem.type !== 'service'
            }
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          label: 'Sell price',
          labelClassName: 'mb-3',
          model: 'price',
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          moneyFlag: true,
          component: 'v-text-field',
          provider: {
            name: 'Price, ₴ per unit.',
            vid: 'price',
            rules: 'required|money'
          },
          attrs: {
            label: 'Price, ₴ per unit.',
            outlined: true,
            type: 'number',
            min: 0,
            step: 0.01
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          label: 'Sale type',
          labelClassName: 'mb-3 col-xl-12',
          model: 'is_weight',
          component: 'e-input-payment-type',
          attrs: {
            tooltip: 'The product can be sold in parts. The minimum is 0.01',
            isService: this.productItem.type === 'service'
          },
          cols: {
            cols: 12,
            md: 6
          }
        }
      ]
    },
    leftoversFields () {
      return [
        new AutocompleteBuilder({
          model: 'supplier_id',
          label: 'Provider',
          query: model => model.api(),
          addEntityBtn: {
            text: '+ ' + this.$t('Add provider'),
            call: async () => await this.addProvider()
          },
          deleteEntityBtn: true,
          rules: 'required'
        }, SuppliersSearch).build(),
        {
          cast: (val) => {
            const value = val ? parseFloat(val).toFixed(this.productItem.is_weight ? 3 : 0) : 0
            return Math.round((value * 1000)) || null
          },
          model: 'count',
          component: 'v-text-field',
          provider: {
            name: this._.get(this.productItem, 'is_weight') ? 'Initial balance, kg' : 'Initial balance, pcs',
            vid: 'count',
            rules: () => (this._.get(this.productItem, 'is_weight') ? 'required|decimal_number' : 'required|integer')
          },
          attrs: {
            label: this._.get(this.productItem, 'is_weight') ? 'Initial balance, kg' : 'Initial balance, pcs',
            type: 'number',
            outlined: true,
            min: 0,
            step: 1
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        {
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          model: 'purchase_price',
          component: 'v-text-field',
          provider: {
            name: 'Purchase price, ₴',
            vid: 'purchase_price',
            rules: 'required|money'
          },
          attrs: {
            label: 'Purchase price, ₴',
            outlined: true,
            type: 'number',
            min: 0,
            step: 0.01
          },
          cols: {
            cols: 12,
            md: 6
          }
        }
      ]
    },
    childrenAutoselect () {
      return [
        new AutocompleteBuilder({
          model: 'children',
          label: 'Related products',
          returnObject: true,
          itemClass: 'ws-pre',
          query: (model, item) => {
            const parent = AccountingGoods.query().where('code', item.attrs.code).first()
            const filter = {
              use_short_params: true
            }
            if (parent) {
              return model.api().filter({ ...filter, exclude_product: parent.id })
            } else {
              return model.api().filter(filter)
            }
          },
          multiple: true,
          cacheItems: true,
          useDefaultSearchFilter: true,
          saveModelInstance: true,
          chipText: item => this._.get(item, 'name', '-'),
          itemValue: item => this._.get(item, 'id', null)
        }, AccountingGoodsSearch).build()
      ]
    },
    leftoversSwitcher () {
      return [
        {
          label: 'Leftovers',
          labelClassName: 'mb-3',
          model: 'leftovers',
          component: 'e-input-switch-tooltip',
          provider: {
            name: 'Specify initial balances',
            vid: 'leftovers'
          },
          attrs: {
            label: 'Specify initial balances',
            tooltip: 'When saving the goods, a delivery document will be created for the quantity of goods specified here',
            disabled: !this.useLeftovers
          },
          cols: {
            cols: 12
          }
        }
      ]
    },
    childrenSwitcher () {
      return [
        {
          label: 'Related products',
          labelClassName: 'mb-3',
          model: 'children',
          component: 'e-input-switch-tooltip',
          provider: {
            name: 'Has related products',
            vid: 'children'
          },
          attrs: {
            label: 'Has related products',
            tooltip: 'Upon sale, these products will be automatically added with this product'
          },
          cols: {
            cols: 12
          }
        }
      ]
    },
    btnText () {
      let text = this.$isCreateCtx(this.context) ? 'Add goods' : 'Save'
      if (this.tab === 0) {
        text = 'Continue'
      }
      return text
    },
    branchesIds () {
      return this._.map(this._.get(this.item, 'branches_info', []), item => item.branch_id)
    },
    goodThumbnail () {
      if (this.hideThumbnailLocally) {
        return this.previewImage || null
      }
      // eslint-disable-next-line camelcase
      return this.item?.image_url || this.previewImage
    }
  },
  watch: {
    'switchers.children' (val) {
      if (!val) {
        this.$set(this.productItem, 'children', [])
      }
    }
  },
  created () {
    if (this.isUpdate && this._.get(this.item, 'branches_info.length')) {
      this.loadBranches()
    }

    this.fillData()
  },
  methods: {
    async checkFirstTaxToExtraRate () {
      const selectedTaxes = this.productItem?.taxes || []
      if (this.tab !== 0 || selectedTaxes.length < 2) {
        return true
      }

      const [firstTax, secondTax] = selectedTaxes
      const isFirstTaxExtraRateValid = firstTax?.extraRate > 0
      const isSecondTaxRateValid = secondTax?.rate > 0
      if (isFirstTaxExtraRateValid && isSecondTaxRateValid) {
        const confirm = await this.confirmationDialog.open({
          title: 'Pay attention!',
          text: 'It is necessary to first select VAT and then Excise. Otherwise, the tax calculation will be done incorrectly.',
          width: '500px',
          buttonText: {
            approve: 'Continue',
            dismiss: 'Cancel'
          }
        })
        if (!confirm) {
          return false
        }
      }
      return true
    },
    validateSelectTaxes (taxes) {
      if (!this.productItem.taxes) {
        return taxes.map(tax => ({ ...tax, disabled: false }))
      }

      const selectedTaxes = this.productItem.taxes
      if (selectedTaxes.length >= 2) {
        return taxes.map((tax) => {
          const isSelected = selectedTaxes.some(selectedTax => selectedTax.id === tax.id)
          return { ...tax, disabled: !isSelected }
        })
      }
      const selectedValidRate = selectedTaxes.some(tax => tax.rate !== null && tax.rate !== 0)
      const selectedValidExtraRate = selectedTaxes.some(tax => tax.extraRate !== null && tax.extraRate !== 0)

      const validateDisableTaxes = taxes.map((tax) => {
        const isSelected = selectedTaxes.some(selectedTax => selectedTax.id === tax.id)
        if (isSelected) {
          return { ...tax, disabled: false }
        }
        const hasTaxRate = tax.rate !== null && tax.rate !== 0
        const hasTaxExtraRate = tax.extraRate !== null && tax.extraRate !== 0
        const shouldDisable = (selectedValidRate && selectedValidExtraRate) ||
          (selectedValidRate && hasTaxRate) ||
          (selectedValidExtraRate && hasTaxExtraRate)

        return { ...tax, disabled: shouldDisable }
      })

      return validateDisableTaxes
    },
    onImageRemove () {
      if (this.isUpdate) {
        this.hideThumbnailLocally = true
        this.removePreviewImage()
        this.files = []
      } else {
        this.removePreviewImage()
        this.files = []
      }
    },
    addOutletFields () {
      this.$set(this.$data, 'branchesInfo', [
        ...this.branchesInfo,
        {
          branch_id: null,
          price: null,
          supplier_id: null,
          count: null,
          purchase_price: null
        }
      ])
    },
    removeOutletFields (key) {
      this.$set(this.$data, 'branchesInfo', this._.filter(this.branchesInfo, (item, index) => key !== index))
      this.branchesFieldsKey += 1
    },
    async loadBranches () {
      await OutletsSearch.api().filter({ 'id[in]': this.branchesIds.join(',') }).all()
      const branchesInfo = []
      this._.each(this._.get(this.item, 'branches_info'), (item) => {
        branchesInfo.push({
          branch_id: OutletsSearch.query().whereId(this._.get(item, 'branch_id')).first(),
          price: this._.get(item, 'price')
        })
      })
      this.$set(this.$data, 'branchesInfo', branchesInfo)
    },
    async checkIfLeftoversAvailable () {
      if (!this.useLeftovers) {
        await this.contentDialogTop.open({
          width: '500px',
          component: 'm-block-switch-supplies-setting',
          componentProps: {
            useLeftovers: this.useLeftovers
          }
        })
      }
    },
    changeEntityType (type) {
      this.$set(this.productItem, 'type', type)
    },
    fillData () {
      if (this.isUpdate) {
        for (const key in this.item) {
          const keys = Object.keys(this.productItem)
          if (keys.includes(key)) {
            let val = this.item[key]
            if (key === 'price') {
              val /= 100
            }
            if (key === 'taxes') {
              val = Taxes.query().whereId(val).get()
            }
            if (key === 'branches_info') {
              continue
            }
            this.$set(this.productItem, key, val)
            this.switchers.children = Boolean(this._.get(this.item, 'children', []).length)
          }
        }
        const barcodes = this._.get(this.item, 'related_barcodes', null) || this._.get(this.item, 'barcode', null)
        this.$set(this.productItem, 'barcodes', barcodes)
      }
    },
    close () {
      this.$emit(['dialog', 'close'].join(':'), null)
      if (this._.isFunction(this.closeModal)) {
        this.closeModal(true)
      }
    },
    getIdsArray (arr) {
      const filteredArr = this._.filter(arr, item => item && item.id)
      return this._.map(filteredArr, item => this._.get(item, 'id'), [])
    },
    findFieldsWithError () {
      return new Promise((resolve) => {
        const fieldsWithError = []
        const time = 50
        let iterations = 0
        const interval = setInterval(() => {
          const errors = this._.get(this.$refs[this.model.entity], 'errors')
          this._.each(errors, (val, key) => {
            if (val?.length) {
              fieldsWithError.push(key)
            }
          })
          if (fieldsWithError.length || iterations === 1e3) {
            clearInterval(interval)
            resolve(fieldsWithError)
          }
          iterations += time
        }, time)
      })
    },
    async checkIfValidationFailed () {
      const fieldsWithError = await this.findFieldsWithError()
      let isFailed = false
      for (let i = 0; i < this.fields.length; i++) {
        const field = this.fields[i]
        if (fieldsWithError.includes(field?.model)) {
          isFailed = true
          break
        }
      }
      if (isFailed) {
        this.tab = 0
      } else {
        this.tab = 1
      }
    },
    async addSupplies ({ productId, branchesInfo }) {
      if (this.switchers.leftovers) {
        const payload = {
          ...this.leftoversItem,
          branch_id: this.useBranches ? this.defaultBranchId : null,
          supplier_id: this._.get(this.leftoversItem, 'supplier_id.id', null),
          date: this.$moment(new Date()).format()
        }
        await this.model.api().addSupplyToProduct(productId, payload)
      }

      this._.each(branchesInfo, async (item) => {
        if (item.supplier_id) {
          const payload = {
            count: item.count,
            purchase_price: item.purchase_price,
            branch_id: this.useBranches ? this._.get(item, 'branch_id.id', null) : null,
            supplier_id: this._.get(item, 'supplier_id.id', null),
            date: this.$moment(new Date()).format()
          }
          await this.model.api().addSupplyToProduct(productId, payload)
        }
      })
    },
    checkStatus (id) {
      const resolveStatus = 'done'
      const request = new IntervalRequest(() => this.model.api().getImage(id), {
        interval: 1e3,
        maxDelay: 12e4 // ~2 minutes
      })
      this.request = request
      return request.startExponential(response => this._.get(response, 'response.data.status', null) === resolveStatus)
    },
    async updateImage (productId) {
      try {
        const form = new FormData()
        form.append('file', this.imageBlob, this.files[0].name)
        const taskId = this._.get(await this.model.api().uploadImage(productId, form), 'response.data.task_id', null)
        const resolved = this._.get(await this.checkStatus(taskId), 'response.data', null)

        if (resolved) {
          await this.model.api().read(productId)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async handleThumbnail (productId) {
      try {
        this.itemLoading = true
        if (this.imageBlob) {
          await this.updateImage(productId)
        }
        if (this.hideThumbnailLocally && !this.imageBlob) {
          await this.model.api().deleteImage(productId)
          await this.model.api().read(productId)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.itemLoading = false
      }
    },
    async save () {
      const valid = await this.validate()
      if (!valid) {
        await this.checkIfValidationFailed()
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
        return false
      }

      const confirmFirstTaxToExtraRate = await this.checkFirstTaxToExtraRate()
      if (!confirmFirstTaxToExtraRate) {
        return false
      }

      if (this.tab === 0) {
        this.tab = 1
        return
      }
      try {
        this.itemLoading = true
        const filteredBranchesInfo = this._.uniqBy(this._.filter(this.branchesInfo, i => this._.get(i, 'branch_id.id')), 'branch_id.id')
        const branchesInfo = this._.map(filteredBranchesInfo, item => ({
          branch_id: this._.get(item, 'branch_id.id'),
          price: this._.get(item, 'price')
        }))

        const data = {
          ...this.productItem,
          group: this._.get(this.productItem, 'group.id', null),
          branches_info: branchesInfo,
          children: this.getIdsArray(this.productItem.children),
          taxes: this.getIdsArray(this.productItem.taxes)
        }

        if (data.barcodes !== null) {
          data.barcodes = this._.map(data.barcodes.split(','), i => i && i.trim()).filter(Boolean)
        }

        const payload = this.filterOrmObject(data)

        if (this.isUpdate) {
          delete payload.type
        }

        const res = this.$isCreateCtx(this.context)
          ? await this.model.api()[this.context](payload, false)
          : await this.model.api()[this.context](this.item, payload, false)

        const productId = this._.get(res, 'response.data.id', null)

        await this.handleThumbnail(productId)

        await this.addSupplies({
          productId,
          branchesInfo: filteredBranchesInfo
        })

        const notification = this.$isCreateCtx(this.context) ? this.$t('was created!') : this.$t('was updated!')
        this.$notification.success([this.$t('good'), notification].join(' ').trim())

        if (this.$isCreateCtx(this.context)) {
          this.$gtm.push({
            event: 'goods_import',
            import_type: 'manual',
            organization: this._.get(this.$Organization, 'owner.email')
          })
        }

        this.close()
      } catch (e) {
        if (this._.get(e, 'response.data.violations')) {
          this._.each(this._.get(e, 'response.data.violations'), (item) => {
            if (item.propertyPath === 'barcode') {
              item.propertyPath = 'barcodes'
            }
          })
        }
        this.$handlers.error(e, this.$refs[this.model.entity])
        await this.checkIfValidationFailed()
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
        this.itemLoading = false
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem;
  line-height: 1.2;
}
.max-width {
  max-width: 27%;
}

.link {
  font-size: 13px !important;

  &.delete-btn {
    color: red;
  }
}
.uploader__subtitle {
  color: rgba(22, 27, 37, 0.50);
  font-size: 11px;
  font-weight: 300;
  line-height: 16px;
  text-transform: uppercase;
}
</style>
