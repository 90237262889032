<template lang="pug">
  div(class="dialog")
    v-img(
      class="dialog__img"
      :src="require('~/assets/images/racoons/racoon-nice.svg')"
    )
    h3(class="dialog__title") {{ $t('The application for obtaining a POS terminal has been successfully submitted to the RozetkaPay') }}
    p(class="dialog__text") {{ $t('Please wait, your application is under consideration, a RozetkaPay specialist will contact you within a day') }}
    div(class="dialog__actions")
      v-btn(
        class="main-button"
        @click="closeModal"
      ) {{ $t('Continue') }}
</template>

<script>
export default {
  name: 'BlockPromotionSuccess',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__text {
    color: rgba(0, 0, 0, 0.8);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .main-button {
      width: 100%;
      max-width: 180px;
    }
  }

  &__img {
    width: 100%;
    max-width: 200px;
    margin: 24px auto 12px;
    display: block;
  }
}
</style>
