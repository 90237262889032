import { get } from 'lodash'
import Dialog from '~/models/system/Dialog'
import Receipts from '~/modules/receipt/models/Receipts'
import Employees from '~/modules/employees/models/Employees'
import CashRegisters from '~/models/directories/CashRegisters'
import AppNotifications from '~/services/Notifications/AppNotifications'

export default class SmsService {
  static async openSmsModal (receiptId) {
    const contentDialog = Dialog.query().where('type', 'content_top').first()
    await contentDialog.open({
      title: 'Send to sms',
      width: '500px',
      component: 'm-form-block',
      componentProps: {
        buttonText: 'Send ',
        fields: [
          {
            model: 'phone',
            component: 'e-input-mask',
            cast: val => val && val.replace('+', '').replace(/-/g, ''),
            provider: {
              vid: 'phone',
              name: 'Phone',
              rules: 'required|phone'
            },
            attrs: {
              mask: 'mobile',
              outlined: true,
              placeholder: 'Phone'
            }
          }
        ],
        onSubmit: async (data, ctx) => {
          try {
            await Promise.all([
              Employees.api().filter({ mode: Employees.itemModes.checkbox.mode, limit: 10 }).all(),
              CashRegisters.api().filter({ mode: CashRegisters.itemModes.checkbox.mode, limit: 1 }).all()
            ])
            const employee = Employees.query().where((item) => {
              if (item.certificateEnd) {
                return item.mode === Employees.itemModes.checkbox.mode && new Date().getTime() < new Date(item.certificateEnd).getTime()
              }
            }).first()
            const accessToken = get(await Employees.api().processingSignin(employee), 'response.data.access_token')
            await ctx.processingApiRequest({
              request: token => Receipts.api().sendToSms(data, receiptId, token),
              token: accessToken,
              employee
            })
            AppNotifications.success('The check was successfully sent')
            await contentDialog.close()
          } catch (e) {
            AppNotifications.error(e)
          }
        }
      }
    })
  }
}
