import { Model } from '@vuex-orm/core'
import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import { formats } from '~/const/global'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import SuppliersSearch from '~/modules/goods/models/SuppliersSearch'
import GoodsGroupsSearch from '~/modules/goods/models/GoodsGroupsSearch'
import AccountingGoodsSearch from '~/modules/goods/models/AccountingGoodsSearch'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'

export class FinancialReport extends ChainInheritance(Directory, []) {
  static entity = 'financialreport'
  static paginated = true
  static defaultSortParam = 'created_at'
  static defaultSortOrder = false

  static ormTrans = {
    single: 'Report',
    multy: 'Reports'
  }

  static fields () {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      group_name: this.attr(null),
      code: this.attr(null),
      good_name: this.attr(null),
      purchase_price: this.attr(null),
      sell_price: this.attr(null),
      good_count_total: this.attr(null),
      good_purchase_turnover: this.attr(null),
      good_sale_turnover: this.attr(null),
      income_gross: this.attr(null),
      leftovers_count: this.attr(null),
      leftovers_in_money: this.attr(null),
      supplier_name: this.attr(null),
      is_weight: this.attr(null),
      markup: this.attr(null)
    }
  }

  get markupString () {
    return this.markup + '%'
  }

  get leftoversCountString () {
    if (this.type === AccountingGoods.TYPES_MAP.service) {
      return '-'
    } else if (this.is_weight) {
      return ((this.leftovers_count || 0) / 1000).toFixed(3)
    } else {
      return ((this.leftovers_count || 0) / 1000).toFixed(0)
    }
  }

  get goodCountTotalString () {
    if (this.type === AccountingGoods.TYPES_MAP.service) {
      return '-'
    } else if (this.is_weight) {
      return ((this.good_count_total || 0) / 1000).toFixed(3)
    } else {
      return ((this.good_count_total || 0) / 1000).toFixed(0)
    }
  }

  get purchasePriceString () {
    return (this.purchase_price / 100).toFixed(2).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get sellPriceString () {
    return (this.sell_price / 100).toFixed(2).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get goodPurchaseTurnoverString () {
    return (this.good_purchase_turnover / 100).toFixed(2).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get goodSaleTurnoverString () {
    return (this.good_sale_turnover / 100).toFixed(2).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get incomeGrossString () {
    return (this.income_gross / 100).toFixed(2).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ')
  }

  get leftoverInMoneyString () {
    return ((this.leftovers_in_money || 0) / 1e5).toFixed(2)
  }

  static ormHeaders = [
    { text: 'Group', value: 'group_name', align: 'left', filterable: false, sortable: true, sortQuery: 'group' },
    { text: 'Code', value: 'code', align: 'left', filterable: false, sortable: true, sortQuery: 'code' },
    { text: 'Name', value: 'good_name', align: 'left', filterable: false, sortable: true, sortQuery: 'name' },
    { text: 'Purchase price', value: 'purchasePriceString', align: 'right', filterable: false, sortable: false },
    { text: 'Markup', value: 'markupString', align: 'left', filterable: false, sortable: false },
    { text: 'Retail price', value: 'sellPriceString', align: 'right', filterable: false, sortable: false },
    { text: 'Sold out', value: 'goodCountTotalString', align: 'left', filterable: false, sortable: false },
    { text: 'Sales amount (purchase)', value: 'goodPurchaseTurnoverString', align: 'right', filterable: false, sortable: false, width: 150 },
    { text: 'Sales amount (retail)', value: 'goodSaleTurnoverString', align: 'right', filterable: false, sortable: false, width: 150 },
    { text: 'Gross income', value: 'incomeGrossString', align: 'right', filterable: false, sortable: false },
    { text: 'Supplier', value: 'supplier_name', align: 'left', filterable: false, sortable: false },
    { text: 'Supply ', value: 'leftoversCountString', align: 'left', filterable: false, sortable: false },
    { text: 'Supplies sum (uah)', value: 'leftoverInMoneyString', align: 'right', filterable: false, sortable: false }
  ]

  static ormMobileTitle = 'good_name'

  static ormFilters = [
    {
      model: 'reportDate',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      default: () => {
        const date = new Date()
        date.setMonth(date.getMonth() - 6)
        return [this.$moment(date).format(formats.dateISO8601), this.$moment(new Date()).format(formats.dateISO8601)]
      },
      attrs: {
        closeOnClick: false,
        clearable: false,
        emmitOnlyOnSelectClicked: true,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Period',
        min: (dates) => {
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() - 6)
            return this.$moment(date).format(formats.dateISO8601)
          }
        },
        max: (dates) => {
          const today = new Date()
          const formattedToday = this.$moment(today).format(formats.dateISO8601)
          if (dates && dates.length === 1) {
            const date = new Date(dates[0])
            date.setMonth(date.getMonth() + 6)
            if (date.getTime() > today.getTime()) {
              return formattedToday
            }
            return this.$moment(date).format(formats.dateISO8601)
          }
          return formattedToday
        }
      }
    },
    new AutocompleteBuilder({
      model: 'goods_groups',
      label: 'Groups',
      hideDetails: true,
      itemClass: 'ws-pre',
      multiple: true,
      cacheItems: true,
      useDefaultSearchFilter: true,
      saveModelInstance: false,
      returnObject: true,
      chipText: item => item.name,
      query: model => model.api(),
      itemValue: item => this._.get(item, 'id', null)
    }, GoodsGroupsSearch).build(),
    new AutocompleteBuilder({
      model: 'goods',
      label: 'Goods',
      hideDetails: true,
      itemClass: 'ws-pre',
      multiple: true,
      saveModelInstance: false,
      returnObject: true,
      chipText: item => item.name,
      query: (model, ctx) => {
        const groupId = this._.map(this._.get(ctx, 'attrs.goods_groups'), item => item.id).join(',')
        const filter = {
          without_total_items: true
        }
        if (groupId) {
          return model.api().filter({ ...filter, group_id: groupId, find_groups_children: true })
        }
        return model.api().filter(filter)
      },
      watchProps: ['ctx.attrs.goods_groups'],
      onWatchProps: async (ctx) => {
        await ctx.requesting()
      },
      itemValue: item => this._.get(item, 'id', null)
    }, AccountingGoodsSearch).build(),
    new AutocompleteBuilder({
      model: 'suppliers',
      label: 'Suppliers',
      hideDetails: true,
      itemClass: 'ws-pre',
      multiple: true,
      cacheItems: true,
      useDefaultSearchFilter: true,
      saveModelInstance: false,
      returnObject: true,
      chipText: item => item.name,
      query: model => model.api(),
      itemValue: item => this._.get(item, 'id', null)
    }, SuppliersSearch).build(),
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'start'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 4,
                lg: 2
              },
              fields: [
                'reportDate'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 4,
                lg: 2
              },
              fields: [
                'goods_groups'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 4,
                lg: 2
              },
              fields: [
                'goods'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 4,
                lg: 2
              },
              fields: [
                'suppliers'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 6,
                md: 4,
                lg: 2
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = []

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      configActions.getReport = function (payload) {
        FinancialReport.deleteAll()
        return this.post(Model.$routes.financialreport.list(), payload, { dataKey: 'data' })
      }
      configActions.downloadCsvFile = function (payload, params) {
        return this.post(Model.$routes.financialreport.downloadCsvFile(), payload, {
          params,
          save: false
        })
      }
      configActions.downloadExcelFile = function (payload, params) {
        return this.post(Model.$routes.financialreport.downloadExcelFile(), payload, {
          params,
          responseType: 'blob',
          save: false
        })
      }
      return configActions
    }
  }
}

export default FinancialReport
