<template lang="pug">
  div(@click="onClick()" class="cursor-pointer color-link")
    | {{ fullName }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'
import contentDialog from '~/mixins/dialogs/contentDialog'
export default {
  components: { ELink },
  mixins: [contentDialog],
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  computed: {
    fullName () {
      return `${this._.get(this.value, 'profile.firstName', '')} ${this._.get(this.value, 'profile.lastName', '')}`
    },
    modalFields () {
      return [
        {
          label: 'First name',
          val: this.value.profile.firstName
        },
        {
          label: 'Last name',
          val: this.value.profile.lastName,
          value: val => val || '-'
        },
        {
          label: 'Email',
          val: this.value.email,
          value: val => val || '-'
        },
        {
          label: 'Phone',
          val: this.value.phone,
          value: val => val || '-'
        }
      ]
    }
  },
  methods: {
    onClick () {
      this.contentDialog.open({
        component: 't-orm-simple-data',
        title: 'Owner\'s card',
        componentProps: {
          items: this.modalFields
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
