import moment from 'moment-timezone'
import Download from 'js-file-download'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

import dateTime from '~/mixins/datetime/dateTime'

import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import Reports from '~/modules/reports/models/Reports'
import Outlets from '~/models/directories/Outlets'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import { formats } from '~/const/global'
import CashRegisters from '~/models/directories/CashRegisters'

const reports = {
  mixins: [
    dateTime
  ],
  computed: {
    model () {
      return Reports
    },
    queryFilters () {
      return {
        'mode[in]': `${CashRegisters.itemModes.checkbox.mode},${CashRegisters.itemModes.deactivated.mode},${CashRegisters.itemModes.alert.mode},${CashRegisters.itemModes.deactivatedByUser.mode}`
      }
    },
    outletQueryFilters () {
      return { 'mode[in]': `${Outlets.itemModes.checkbox.mode},${Outlets.itemModes.deactivated.mode}` }
    },
    defaultFields () {
      return [
        {
          model: 'dateRange',
          component: 'e-input-datetime-range',
          name: 'reports-range',
          provider: {
            vid: 'reports-range',
            name: 'Date range',
            rules: 'required',
            mode: 'lazy'
          },
          attrs: {
            range: true,
            outlined: true,
            placeholder: 'Date range',
            max: this.yesterday
          }
        },
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Select cash register',
          rules: 'required',
          returnObject: true,
          itemClass: 'ws-pre',
          query: model => model.api().filter(this.queryFilters),
          asyncDefault: (items) => {
            if (items && items.length === 1) {
              return items[0]
            }
          }
        }, CashRegistersSearch).build()
      ]
    },
    organizationSelectField () {
      return {
        model: 'organizationId',
        component: 'e-input-organizations-select'
      }
    }
  },
  methods: {
    startDate (dateRange = []) {
      return this._.nth(dateRange, 0)
    },
    endDate (dateRange = []) {
      return this._.nth(dateRange, 1) || this._.nth(dateRange, 0)
    },
    reportName (type, form = {}) {
      let name = type
      const { dateRange } = form
      const nameParts = {
        startDate: this.startDate(dateRange),
        endDate: this.endDate(dateRange),
        cashRegisterNumber: this._.get(form, 'cashRegister.number', null),
        outletName: this._.get(form, 'outlet.name', null)
      }
      for (const key in nameParts) {
        if (nameParts[key]) {
          name += `_${nameParts[key]}`
        }
      }
      return name
    },
    dateToIsoString (str, endDate) {
      if (!str || typeof str !== 'string') {
        return
      }
      if (endDate) {
        str += 'T23:59:59.999Z'
      }
      const date = new Date(str)
      return date && date.toISOString()
    },
    formatReportDate (date, endDate) {
      const isoDate = this.dateToIsoString(date, endDate)
      const formattedDate = this.$moment(isoDate).toDate()
      // TODO Временное решение (таймзона)
      let offset = 0
      if (formattedDate.toString().includes('GMT+0300')) {
        offset = 3
      } else if (formattedDate.toString().includes('GMT+0200')) {
        offset = 2
      } else if (formattedDate.toString().includes('GMT+0100')) {
        offset = 1
      }
      if (new Date(formattedDate) <= new Date('2021-01-01') && !endDate) {
        offset = 0
      }
      formattedDate.setHours(Math.round(formattedDate.getHours() - offset))
      return formattedDate && moment(formattedDate).format()
    },
    multipleAutocompleteCommonOptions ({ chipText = '' } = {}) {
      return {
        multiple: true,
        cacheItems: true,
        useDefaultSearchFilter: true,
        saveModelInstance: false,
        chipText: item => this._.get(item, chipText, '-'),
        itemValue: item => this._.get(item, 'id', null),
        asyncDefault: (items) => {
          if (items && items.length === 1) {
            return items
          }
        }
      }
    },
    multiAccountAutocompleteOptions (filter) {
      return {
        watchProps: ['ctx.attrs.organizationId'],
        onWatchProps: async (ctx, val, prevVal) => {
          if (JSON.stringify(val) !== JSON.stringify(prevVal)) {
            await ctx.requesting()
          }
        },
        customQuery: ctx => ctx?.$User?.globalAllOrganizationsSelected,
        query: (model, ctx, config, user) => {
          const filters = filter || this.queryFilters
          if (!user?.globalAllOrganizationsSelected) {
            return model.api().filter(filters)
          } else {
            let organizations = this._.get(ctx, 'attrs.organizationId')
            if (this._.get(organizations, '[0].allOrganizationsItem')) {
              config.params.withAllOrganizations = true
              organizations = []
            } else {
              organizations = this._.map(organizations, i => i?.id)
            }
            return model.api().listMultiAccount({ organizations }, {
              ...config,
              params: {
                ...config.params,
                ...(filters || {})
              }
            })
          }
        }
      }
    },
    dateRangeField ({ options = {}, rules = '', max = '', min = '' } = {}) {
      return {
        model: 'dateRange',
        component: 'e-input-datetime-range',
        name: 'reports-range',
        provider: {
          vid: 'reports-range',
          name: 'Date range',
          mode: 'lazy',
          rules
        },
        attrs: {
          range: true,
          outlined: true,
          placeholder: 'Date range',
          max,
          min,
          fastDates: true
        },
        ...options
      }
    },
    cashRegisterAutocompleteField ({ options = {} } = {}) {
      return new AutocompleteBuilder({
        model: 'cashRegister',
        label: 'Select cash register',
        returnObject: true,
        itemClass: 'ws-pre',
        rules: this._.get(this.$User, 'isEmployee') ? 'required' : '',
        query: model => model.api().filter(this.queryFilters),
        asyncDefault: (items) => {
          if (items && items.length === 1) {
            return items[0]
          }
        },
        ...options
      }, CashRegistersSearch).build()
    },
    outletAutocompleteField ({ options = {} } = {}) {
      return new AutocompleteBuilder({
        model: 'outlet',
        label: 'Select outlet',
        returnObject: true,
        itemClass: 'ws-pre',
        query: model => model.api().filter(this.outletQueryFilters),
        asyncDefault: (items) => {
          if (items && items.length === 1) {
            return items[0]
          }
        },
        ...options
      }, OutletsSearch).build()
    },
    sendEmailCheckbox ({ options = {} } = {}) {
      return {
        model: 'sendEmailCheckbox',
        component: 'v-checkbox',
        attrs: {
          label: 'Send report on email'
        },
        ...options
      }
    },
    sendEmailField ({ options = {}, type } = {}) {
      return {
        model: 'emails',
        component: 'v-text-field',
        name: 'reports-emails',
        hint: 'Emails to send reports. Example: email1@example.com, email2@example.com',
        provider: {
          vid: 'reports-emails',
          name: 'E-mail',
          rules: 'required|emailsString'
        },
        attrs: {
          label: 'Emails for send reports',
          outlined: true
        },
        fieldVal: () => this._.get(this.$localStorageClientData(), `reports.${type}.emails`, ''),
        ...options
      }
    },
    makeArray (data) {
      if (Array.isArray(data)) {
        return data.filter(x => x)
      } else {
        return [data].filter(x => x)
      }
    },
    async getReport ({
      form = {},
      type,
      mode,
      model,
      params: optionalParams,
      method = 'report',
      withType = true,
      withMode = false,
      taskQueryParams = {},
      statusQueryParams = {},
      additionalTaskParams = {}
    } = {}) {
      try {
        this.loading = true
        const {
          dateRange,
          cashRegister,
          outlet,
          sendEmailCheckbox,
          emails,
          organizationId
        } = form
        const startDate = this.startDate(dateRange)
        const endDate = this.endDate(dateRange)
        const cashRegisters = this.makeArray(cashRegister)
        const outlets = this.makeArray(outlet)
        const params = optionalParams || {
          from_date: this.formatReportDate(startDate),
          to_date: this.formatReportDate(endDate, true) || this.formatReportDate(startDate, true),
          cash_register_id: this._.map(cashRegisters, item => item.id),
          branch_id: this._.map(outlets, item => item.id)
        }

        if (sendEmailCheckbox) {
          params.emails = emails.split(/\s*[;,:|\s]\s*/).filter(el => !!el)
        }

        if (withType) {
          params.type = type
        }

        if (withMode) {
          params.mode = mode
        }

        if (organizationId) {
          if (this._.get(organizationId, '[0].allOrganizationsItem')) {
            taskQueryParams.withAllOrganizations = true
            params.organization_id = []
          } else {
            params.organization_id = this._.map(organizationId, i => i?.id)
          }
        }

        const payload = {
          ...params,
          ...additionalTaskParams
        }

        const reportTaskId = this._.get(await model.api().post(model.$routes[this.model.entity][method](), payload, { save: false, params: taskQueryParams }), 'response.data.id', null)

        if (sendEmailCheckbox) {
          this.$notification.success('Report will be send to emails')
          this.$setLocalStorageClientData({
            reports: {
              ...this._.get(this.$localStorageClientData(), 'reports'),
              [type]: {
                emails
              }
            }
          })
        } else {
          const resolveCondition = ({ response }) => (response.data.status === 'DONE' || response.data.status === 'ERROR')
          const intervalRequest = new IntervalRequest(
            () => model.api().post(model.$routes[model.entity].checkStatus(), { reportTaskId }, { params: statusQueryParams }),
            {
              interval: 2000,
              maxDelay: 6e4
            })
          const response = await intervalRequest.startExponential(resolveCondition)
          if (this._.get(response, 'response.data.status', null) === 'DONE') {
            const reports = await model.api().post(model.$routes[model.entity].excel(), { reportTaskId }, { responseType: 'blob' })
            const res = this._.get(reports, 'response.data', '')
            Download(res, this.reportName(type, form) + '.xlsx')
          } else {
            this.$notification.error(this._.get(response, 'response.data.error_message', null))
          }
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    formatGroupData (data) {
      const fromDate = this._.get(data, 'fromDate', null) && this.$moment(this._.get(data, 'fromDate', null)).format(formats.dateISO8601)
      const toDate = this._.get(data, 'toDate', null) && this.$moment(this._.get(data, 'toDate', null)).format(formats.dateISO8601)
      const dateRange = fromDate && toDate ? [fromDate, toDate] : []
      return {
        dateRange,
        cashRegister: this._.get(data, 'cashRegisters', []),
        outlet: this._.get(data, 'outlets', [])
      }
    }
  }
}

export default reports
