<template lang="pug">
  v-autocomplete(
    v-bind="$attrs"
    @change="handleChange"
    :value="val"
  )
</template>

<script>
export default {
  computed: {
    multiple () {
      return this._.get(this.$attrs, 'multiple', false)
    },
    attrsValue () {
      return this._.get(this.$attrs, 'value', null)
    },
    val () {
      if (Array.isArray(this.attrsValue) && !this.multiple) {
        return this.attrsValue[0]
      }
      return this.attrsValue
    }
  },
  methods: {
    handleChange (val) {
      this.$emit('input', val)
    }
  }
}
</script>
