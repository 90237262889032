<template lang="pug">
  div(class="tap-to-phone-settings-form")
    div(v-if="isMonobankSelected && !isEditing && !isUpdating" class="tap-to-phone-settings-form__mono-wrapper")
      div
        div(class="tap-to-phone-settings-form__mono-name") {{ $t('Monobank') }}
        div(class="tap-to-phone-settings-form__mono-user") {{ monoAccountName }}
      v-btn(
        class="secondary-button"
        color="black"
        outlined
        :loading="revokeLoading"
        @click="revokeToken"
      ) {{ $t('Revoke') }}
    v-form
      ValidationObserver(ref="form" slim)
        t-orm-fields(v-bind.sync="form" :items="fields")
    div(class="tap-to-phone-settings-form__actions")
      v-btn(
        class="main-button"
        @click="save"
        :loading="loading"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import Acquiring from '~/modules/acquiring/models/Acquiring'
import Merchants from '~/modules/acquiring/models/Merchants'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import ProvidersSearch from '~/modules/acquiring/models/ProvidersSearch'
import CashRegisters from '~/models/directories/CashRegisters'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'

export default {
  name: 'BlockTapToPhoneSettingsForm',
  components: {
    TOrmFields
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isUpdating: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    selectedBank: {
      type: String,
      default: null
    },
    monobankData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      form: {
        provider: null,
        clientLogin: null,
        clientPhone: null,
        clientUsername: null,
        clientPassword: null,
        ptName: null,
        cashRegister: null,
        isActive: false,
        monobankTerminal: null,
        oschadTerminal: null
      },
      merchants: null,
      foundMerchant: null,
      clientLoginKey: 0,
      oschadMerchants: [],
      showOshadMerchantsSelect: false,
      showOshadTerminalsSelect: false,
      oschadMerchantsLoading: false,
      oschadTerminals: [],
      monobankTerminals: [],
      clientLoginDisabled: false,
      monoAccountName: null,
      revokeLoading: false
    }
  },
  computed: {
    model () {
      return Acquiring
    },
    merchantModel () {
      return Merchants
    },
    existedMerchants () {
      return Merchants.query().all()
    },
    providerId () {
      return this._.get(this.form, 'provider.providerId') || this._.get(this.item, 'merchant.provider.providerId')
    },
    isOschadbankSelected () {
      return this.providerId === '8394d703-1d77-4e79-832f-f9ba030e2a7d' // oschad providerId prod
    },
    isMonobankSelected () {
      return this.providerId === 'c8053ea5-ffdf-4d1e-81ce-3817da44e7a8' // monobank providerId prod
    },
    fields () {
      const ptNameRules = this.isOschadbankSelected ? 'required|integer|max:8|min:8' : 'required|tap2PhoneTerminalId'
      return [
        {
          model: 'clientLogin',
          component: 'v-text-field',
          provider: {
            vid: 'clientLogin',
            name: 'Login for acquiring',
            rules: 'required|noSpaces'
          },
          attrs: {
            visible: !this.isEditing && !this.isMonobankSelected && !this.showOshadMerchantsSelect,
            label: 'Login for acquiring',
            outlined: true,
            disabled: this.clientLoginDisabled
          },
          key: this.clientLoginKey,
          cast: item => item ? item.trim() : null
        },
        {
          model: 'clientLogin',
          component: 'v-select',
          provider: {
            vid: 'clientLogin',
            name: 'Login for acquiring',
            rules: 'required|noSpaces'
          },
          attrs: {
            visible: !this.isEditing && this.showOshadMerchantsSelect,
            label: 'Login for acquiring',
            outlined: true
          },
          key: this.clientLoginKey,
          items: () => this.oschadMerchants
        },
        {
          model: 'oschadTerminal',
          component: 'v-select',
          provider: {
            vid: 'oschadTerminal',
            name: 'Available terminals',
            rules: 'required'
          },
          attrs: {
            visible: !this.isEditing && this.showOshadTerminalsSelect,
            label: 'Available terminals',
            outlined: true,
            returnObject: true,
            itemText: (i) => {
              let str = this._.get(i, 'pt_name')
              const serialNumber = this._.get(i, 'serial_number')
              if (serialNumber) {
                str += ` (${serialNumber}) (${this._.toLower(this.$t('Connectedly'))})`
              }
              return str
            },
            itemDisabled: i => Boolean(this._.get(i, 'serial_number'))
          },
          items: () => this.oschadTerminals
        },
        {
          model: 'monobankTerminal',
          component: 'v-select',
          provider: {
            vid: 'monobankTerminal',
            name: 'Available terminals',
            rules: 'required'
          },
          attrs: {
            visible: this.isMonobankSelected && !this.isEditing && !this.isUpdating,
            label: 'Available terminals',
            outlined: true,
            returnObject: true,
            itemText: (item) => {
              const filteredItems = this._.filter(this.monobankTerminals, i => i.name === item.name)
              if (this._.get(filteredItems, 'length') !== 1) {
                return item.name + ` (${item.terminal})`
              }
              return item.name
            }
          },
          items: () => this.monobankTerminals
        },
        {
          model: 'clientPhone',
          component: 'v-text-field',
          provider: {
            vid: 'clientPhone',
            name: 'Phone for acquiring'
          },
          attrs: {
            label: 'Phone for acquiring',
            outlined: true,
            visible: false
          }
        },
        {
          model: 'clientUsername',
          component: 'v-text-field',
          provider: {
            vid: 'clientUsername',
            name: 'Username for acquiring'
          },
          attrs: {
            label: 'Username for acquiring',
            outlined: true,
            visible: false
          }
        },
        {
          model: 'clientPassword',
          component: 'v-text-field',
          provider: {
            vid: 'clientPassword',
            name: 'Password for acquiring'
          },
          attrs: {
            label: 'Password for acquiring',
            outlined: true,
            visible: false
          }
        },
        {
          model: 'ptName',
          component: 'v-text-field',
          provider: {
            vid: 'ptName',
            name: 'Terminal ID',
            rules: ptNameRules
          },
          attrs: {
            label: 'Terminal ID',
            outlined: true,
            visible: !this.isMonobankSelected && !this.isOschadbankSelected
          },
          cast: item => item || null,
          hint: {
            text: 'Acceptance of contactless payment cards works through the NFC of your mobile device.',
            link: 'https://wiki.checkbox.ua/uk/app/mobile/android_acquiring',
            linkText: 'Instructions to connect'
          },
          fieldVal: () => this._.get(this.item, 'ptName', null)
        },
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Connected to cash register',
          rules: 'required',
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (!this._.get(this.item, 'cashRegister', null) && items && items.length === 1) {
              return items[0]
            }
          },
          fieldVal: () => this._.get(this.item, 'cashRegister', null),
          itemValue: item => this._.get(item, 'id', null)
        }, CashRegistersSearch).build(),
        {
          model: 'isActive',
          component: 'v-switch',
          provider: {
            vid: 'isActive',
            name: 'Connect'
          },
          attrs: {
            label: 'Connect'
          },
          fieldVal: () => this._.get(this.item, 'isActive', true)
        }
      ]
    },
    providerNamesMap () {
      return {
        mono: 'Monobank',
        oschad: 'Ощадбанк'
      }
    }
  },
  watch: {
    'form.provider' (val) {
      this.showOshadMerchantsSelect = false
      this.showOshadTerminalsSelect = false
      const foundMerchant = this._.find(this.existedMerchants, (item) => {
        return this._.get(item, 'provider.providerId') === this._.get(val, 'providerId')
      })
      if (this.isMonobankSelected) {
        if (this.monobankData && this.monobankData.terminals && this.monobankData.terminals.length) {
          this.monobankTerminals = this.monobankData.terminals.filter(item => item.type === 'taptophone' && (item.status === 'active' && !item.integrationDetails.kassaId))
        }
        if (!this.monobankTerminals.length && !this.isEditing) {
          this.closeModal({
            terminals: this.monobankTerminals,
            success: false
          })
        }
        this.monoAccountName = this._.get(this.monobankData, 'name')
        this.$set(this.form, 'clientLogin', this._.get(this.monobankData, 'inn'))
      } else {
        this.$set(this.form, 'clientLogin', this._.get(foundMerchant, 'clientLogin', null))
        this.clientLoginKey += 1
      }
      this.foundMerchant = foundMerchant
      if (this.isOschadbankSelected) {
        const clientLogin = this._.get(foundMerchant, 'clientLogin')
        this.showOshadTerminalsSelect = true
        if (!clientLogin) {
          this.showOshadMerchantsSelect = true
        } else {
          this.clientLoginDisabled = true
        }
        if (!this.oschadMerchants?.length && !this.oschadMerchantsLoading && !clientLogin) {
          this.getOschadMerchants()
        }
      }
    },
    'form.clientLogin' (val) {
      if (this.showOshadTerminalsSelect && val && !this.oschadTerminals?.length) {
        this.getOschadTerminals()
      }
    }
  },
  async created () {
    await this.merchantModel.api().all()
    const providers = this._.get(await ProvidersSearch.api().all(), 'response.data.data')
    this.form.provider = providers.find(provider => provider.name === this.providerNamesMap[this.selectedBank])

    if (this.item) {
      this.$set(this.$data, 'form', {
        ...this.form,
        cashRegister: this._.get(this.item, 'cashRegister')
      })
    }
    this.$gtm.push({
      event: 'connect_tap_to_phone_to_cashregister_view',
      organization: this._.get(this.$Organization, 'owner.email')
    })
  },
  methods: {
    async getOschadMerchants () {
      try {
        this.oschadMerchantsLoading = true
        this.oschadMerchants = this._.get(await this.merchantModel.api().oschad(), 'response.data.merchants', [])
      } catch (e) {
        if (e.response.status === 404) {
          this.oschadMerchants = []
        } else {
          this.$handlers.error(e, this)
        }
      } finally {
        this.oschadMerchantsLoading = false
      }
    },
    async getOschadTerminals () {
      try {
        this.oschadTerminals = this._.get(await this.model.api().oschad({
          login: this._.get(this.form, 'clientLogin', null),
          all: false
        }), 'response.data.terminals', [])
      } catch (e) {
        if (e.response.status === 404) {
          this.oschadTerminals = []
        } else {
          this.$handlers.error(e, this)
        }
      }
    },
    async revokeToken () {
      try {
        this.revokeLoading = true
        await Acquiring.api().revokeToken()
        this.$notification.success('Token has been revoked successfully')
        this.$emit('changeMonoAccount')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.revokeLoading = false
      }
    },
    async save () {
      try {
        this.loading = true
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }

        if (this.isEditing || this.isUpdating) {
          const payload = {
            cashRegister: this._.get(this.form, 'cashRegister') ? `cash-registers/${this._.get(this.form, 'cashRegister.id', null)}` : null,
            ptName: this._.get(this.form, 'ptName', null),
            isActive: this._.get(this.form, 'isActive', null)
          }

          if (this.isMonobankSelected) {
            payload.ptName = this._.get(this.form, 'monobankTerminal.terminal')
            payload.monoId = this._.get(this.form, 'monobankTerminal.code')
          } else if (this.isOschadbankSelected) {
            payload.ptName = this._.get(this.form, 'oschadTerminal.pt_name')
          }

          await this.model.api().update(this.item, payload)

          if (this.isUpdating) {
            const payload = { clientLogin: this._.get(this.form, 'clientLogin', null) }
            await this.merchantModel.api().update({
              ...this._.get(this.item, 'merchant', {}),
              $id: this._.get(this.item, 'merchant.id')
            }, payload)
            this.$notification.success(this.$t('TAP2Phone setup successfully connected'))
          } else {
            this.$notification.success(this.$t('TAP2Phone setup successfully edited'))
          }
          this.closeModal()
        } else {
          let merchant = this.foundMerchant
          const clientLogin = this._.get(this.form, 'clientLogin', null)

          if (!merchant) {
            const merchantPayload = {
              provider: this.form.provider ? `providers/${this._.get(this.form, 'provider.providerId')}` : null,
              clientLogin
            }

            merchant = this._.get(await this.merchantModel.api().create(merchantPayload), 'response.data')
          } else if (this._.get(merchant, 'clientLogin') !== clientLogin) {
            await this.merchantModel.api().update(merchant, { clientLogin })
          }

          const terminalPayload = {
            merchant: this._.get(merchant, 'id') ? `merchants/${this._.get(merchant, 'id', null)}` : null,
            cashRegister: this._.get(this.form, 'cashRegister') ? `cash-registers/${this._.get(this.form, 'cashRegister.id', null)}` : null,
            ptName: this._.get(this.form, 'ptName', null),
            isActive: this._.get(this.form, 'isActive', null)
          }

          if (this.isMonobankSelected) {
            terminalPayload.ptName = this._.get(this.form, 'monobankTerminal.terminal')
            terminalPayload.monoId = this._.get(this.form, 'monobankTerminal.code')
          } else if (this.isOschadbankSelected) {
            terminalPayload.ptName = this._.get(this.form, 'oschadTerminal.pt_name')
          }

          await this.model.api().create(terminalPayload)
          this.$notification.success(this.$t('TAP2Phone setup successfully created'))
          this.closeModal({
            success: true
          })
        }

        this.$gtm.push({
          event: 'submit_tap_to_phone_to_cash_register',
          organization: this._.get(this.$Organization, 'owner.email')
        })
      } catch (e) {
        this.$handlers.error(e, this.$refs.form)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tap-to-phone-settings-form {
  &__mono {
    &-wrapper {
      display: flex;
      flex-direction: column;

      background: #43CCB414;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 32px;

      @media (min-width: map-get($breakpoints, 'sm')) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    &-name {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: #161B25BF;
    }
    &-user {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #161B25;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: -55px;
  }
}
</style>
