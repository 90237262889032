<template lang="pug">
  div(
    class="hints-block-content"
    :class="{ 'hints-block-content--mobile': mobile }"
  )
    div(
      v-if="contentHtml"
      v-html="contentHtml"
    )
    div(v-else-if="currentStep === steps.options")
      p {{ $t('A request has been prepared in the DPS, it needs to be signed with the EDS key') }}
      p {{ $t('You can do this in several ways: with the EDS file key or with SmartID') }}
      p {{ $t('To submit documents to the DPS, you must use the EDS "key of the first document"') }}
      p
        b(class="mr-1") {{ $t('The key of the first document') }} –
        | {{ $t('this is the EDS key that was used to sign the first document (application, request, report, etc.) in the DPS') }}
    div(v-else-if="currentStep === steps.fileSign")
      p {{ $t('signPlugin.edsWindow') }}
      p {{ $t('To submit documents to the DPS, you must use the EDS "key of the first document"') }}
      p
        b(class="mr-1") {{ $t('The key of the first document') }} –
        | {{ $t('this is the EDS key that was used to sign the first document (application, request, report, etc.) in the DPS') }}
    div(v-else-if="currentStep === steps.smartIdRead || currentStep === steps.smartIdSign")
      p {{ $t('Click or scan the QR code in the Privat24 application and follow the instructions') }}
      p {{ $t('Please note that the QR code is scanned several times') }}
      p {{ $t('To submit documents to the DPS, you must use the EDS "key of the first document"') }}
      p
        b(class="mr-1") {{ $t('The key of the first document') }} –
        | {{ $t('this is the EDS key that was used to sign the first document (application, request, report, etc.) in the DPS') }}
    div(v-else-if="currentStep === steps.diiaRead || currentStep === steps.diiaSign")
      p {{ $t('Scan the QR code with the scanner in the Action app and follow the instructions') }}
      p {{ $t('Please note that the QR code is scanned several times') }}
</template>

<script>
export default {
  name: 'BlockEdsIitCheckboxHintContent',
  props: {
    steps: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    contentHtml () {
      let step = this.currentStep
      if (this.currentStep === this.steps.smartIdRead || this.currentStep === this.steps.smartIdSign) {
        step = 'smartId'
      }
      return this._.get(this.content, step, null)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
