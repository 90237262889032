import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import PersistAble from '~/models/mixins/PersistAble'
import TimestampAble from '~/models/mixins/TimestampAble'
import AssignAble from '~/models/mixins/AssignAble'
import i18n from '~/plugins/nuxt-i18n/i18n'

export default class SmsPayment extends ChainInheritance(Directory, [
  TimestampAble,
  AssignAble,
  PersistAble
]) {
  static entity = 'smspayment'
  static paginated = true
  static defaultSortParam = 'entryDate'

  static ormTrans = {
    single: 'Payment SMS',
    multy: 'Payments SMS'
  }

  static statuses = {
    new: {
      text: i18n.t('waiting to be processed'),
      value: 'new'
    },
    error: {
      text: i18n.t('not scattered'),
      value: 'error'
    },
    parsed: {
      text: i18n.t('counted'),
      value: 'parsed'
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      billing: this.attr(null),
      description: this.attr(null),
      entryDate: this.attr(null),
      transferDate: this.attr(null),
      sum: this.attr(null),
      status: this.attr(null),
      sourceName: this.attr(null),
      typeObj: this.attr(null)
    }
  }

  get amount () {
    return this.sum && (this.sum / 100).toFixed(2)
  }

  get handleEntryDate () {
    return this.getDateTime(this.entryDate)
  }

  get handleTransferDate () {
    return this.getDateTime(this.transferDate)
  }

  static ormHeaders = [
    { text: 'Status', value: 'status', sortable: true, filterable: true },
    { text: 'Payment date ', value: 'handleEntryDate', sortable: true, filterable: true, sortQuery: 'entryDate' },
    { text: 'Transfer date', value: 'handleTransferDate', sortable: true, filterable: false },
    { text: 'Purpose of payment', value: 'description', sortable: false, width: '300', filterable: true },
    { text: 'Payer', value: 'typeObj.payer_name', sortable: false, filterable: false },
    { text: 'Payer number', value: 'typeObj.payer_iban', sortable: false, filterable: false },
    { text: 'Payer code', value: 'typeObj.payer_code', sortable: false, filterable: false },
    { text: 'Total Amount', value: 'amount', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormColsComponents = {
    status: {
      component: 'e-radio-status',
      attrs: {
        valueMap: {
          parsed: true,
          new: true,
          error: true
        },
        colorMap: {
          parsed: '#161b25',
          new: '#ff8a00',
          error: '#FF002B'
        },
        tooltipsMap: {
          parsed: this.statuses.parsed.text,
          new: this.statuses.new.text,
          error: this.statuses.error.text
        }
      }
    }
  }

  static ormFilters = [
    {
      model: 'status',
      component: 'v-select',
      attrs: {
        outlined: true,
        'hide-details': true,
        itemText: 'text',
        placeholder: 'Status'
      },
      items: () => [{ text: i18n.t('All'), value: '' }, ...Object.values(this.statuses)]
    },
    {
      model: 'entryDate',
      type: 'dateRange',
      component: 'e-input-datetime-range',
      attrs: {
        closeOnClick: false,
        clearable: true,
        emmitOnlyOnSelectClicked: true,
        type: 'date',
        range: true,
        'hide-details': true,
        outlined: true,
        label: 'Payment date '
      }
    },
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 4,
                md: 4
              },
              fields: [
                'status'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 4,
                md: 4
              },
              fields: [
                'entryDate'
              ]
            },
            {
              component: 'v-col',
              attrs: {
                cols: 12,
                sm: 4,
                md: 4
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormFields = [
    {
      model: 'description',
      component: 'v-text-field',
      provider: {
        vid: 'description',
        name: 'Purpose of payment',
        rules: 'required'
      },
      attrs: {
        label: 'Purpose of payment',
        outlined: true
      },
      fieldVal: ctx => this._.get(ctx.attrs, 'description', '')
    }
  ]

  static ormActions = [
    {
      name: 'edit'
    }
  ]

  static ormDialogs = {
    edit: 'm-orm-crud-dialog'
  }

  static ormDialogsConfig = {
    edit: {
      config: {
        context: 'update'
      }
    }
  }
}
