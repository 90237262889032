import Search from '~/models/abstracts/Search'
import { formats } from '~/const/global'
import i18n from '~/plugins/nuxt-i18n/i18n'

export class ActsSearch extends Search {
  static entity = 'actssearch'

  static fields () {
    return {
      id: this.attr(null),
      startDate: this.attr(null),
      endDate: this.attr(null)
    }
  }

  toString () {
    const date = this.startDate && this.$moment(this.startDate).format(formats.date)
    return `${i18n.t('Act from')} ${date}`
  }
}

export default ActsSearch
