import Vue from 'vue'
import { contexts } from '~/const/global'
import authUser from '~/modules/auth/authUser'
import Dialog from '~/models/system/Dialog'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'

Vue.mixin({
  computed: {
    $config () {
      const modelEntity = this._.get(this.model, 'entity')
      const storageItemsPerPage = this._.get(this.$localStorageClientData(), `itemsPerPage.${modelEntity}`)

      return {
        locale: this.$i18n.locales.find(locale => locale.code === this.$i18n.locale),
        customSort: items => items,
        contexts,
        table: {
          'items-per-page': storageItemsPerPage || 10,
          'no-data-text': this.$t('No data available'),
          'header-props': {
            'sort-by-text': this.$t('Sort by')
          },
          'footer-props': {
            'items-per-page-text': this.$t('Rows per page'),
            'items-per-page-all-text': this.$t('All'),
            'page-text': this.$t('{0}-{1} of {2}'),
            'items-per-page-options': [10, 15, 20, 50, 100],
            'show-first-last-page': true,
            'show-current-page': true
          }
        }
      }
    },
    $User () {
      return authUser.get()
    },
    $Organization () {
      return authUser.get() ? currentOrganizationService.getCurrentOrganization() : null
    }
  },
  methods: {
    $localStorageClientData () {
      if (!localStorage.getItem('clientData')) {
        localStorage.setItem('clientData', JSON.stringify({}))
      }
      return (localStorage.getItem('clientData') && JSON.parse(localStorage.getItem('clientData'))) || {}
    },
    async $checkOrganization () {
      if (this._.get(this.$Organization, 'isDraft', false) && !this._.get(this.$User, 'isSuperAdmin')) {
        // todo: maybe we can change a router name to anything else
        const deniedRoutes = [
          'dashboard-Employees',
          'dashboard-CashRegisters',
          'dashboard-Outlets',
          'dashboard-MyOrganization',
          'dashboard-PromoCashRegisters'
        ]

        if (!deniedRoutes.includes(this.$route.name)) {
          return true
        }

        const dialog = Dialog.query().where('type', 'content').first()
        return await dialog.open({
          component: 'm-block-set-organization',
          width: '600px'
        })
      }

      return true
    },
    $setLocalStorageClientData (data) {
      localStorage.setItem('clientData', JSON.stringify({
        ...this.$localStorageClientData(),
        ...data
      }))
    },
    $setVisitMenu () {
      const localVisitedMenu = this._.get(this.$localStorageClientData(), 'visitedMenu', {})
      if (localVisitedMenu && !localVisitedMenu[this.$route.path]) {
        this.$setLocalStorageClientData({
          visitedMenu: {
            ...localVisitedMenu,
            [this.$route.name]: true
          }
        })
      }
    }
  }
})
