<template lang="pug">
  div(class="rozetkapay-phone-verification py-2")
    iframe(
      :src="iframeLink"
      :style="{ width: '100%', height, border: 'none' }"
      @load="onFrameLoaded"
    )
</template>

<script>
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockRozetkapayPhoneVerification',
  mixins: [responsive],
  computed: {
    height () {
      return this.xsDevice ? '300px' : '240px'
    },
    iframeLink () {
      let link = 'https://onboarding-epdev.rozetkapay.com/checkbox-auth'
      const phone = this._.get(this.$Organization, 'owner.phone')
      if (phone) {
        link += `?phone=${phone.replace('+', '%2B')}`
      }
      return link
    }
  },
  mounted () {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage ({ data }) {
      if (data.source === 'rozetkapay_onboarding') {
        this.$emit('data-verified', data)
      }
    },
    onFrameLoaded () {
      this.$emit('frame-loaded')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
