import authUser from '~/modules/auth/authUser'
import multiAccountUsers from '~/mixins/organization/multiAccountUsers'

export default function ({ store, redirect, error }) {
  const localStorageData = localStorage.getItem('clientData') && JSON.parse(localStorage.getItem('clientData'))
  const globalAllOrganizationsSelected = localStorageData && localStorageData.globalAllOrganizationsSelected
  const user = authUser.get()
  const isMultiAccountUser = multiAccountUsers.data().multiOrganizationUsers.includes(user?.id)
  if (globalAllOrganizationsSelected && isMultiAccountUser) {
    return redirect('/dashboard/myorganization')
  }
}
