<template lang="pug">
  div(class="tap-to-phone-upload-documents")
    div(class="tap-to-phone-upload-documents__content")
      m-block-uploader(
        v-for="(item, idx) in uploadItems"
        :key="idx"
        :ref="item.name"
        :title="item.title"
        :icon="item.icon"
        :accept="accept"
        :rules="item.rules"
        :tooltip="item.text"
        multiple
        :validation-field-name="item.title"
        :files.sync="$data[item.name]"
        :input-errors="errors[item.name]"
      )
    div(class="tap-to-phone-upload-documents__actions")
      v-btn(
        class="main-button tap-to-phone-upload-documents__action"
        :loading="loading"
        @click="btnClick"
      ) {{ $t('Continue') }}
</template>

<script>
import MBlockUploader from '~/components/modules/blocks/concrete/m-block-uploader'

export default {
  name: 'BlockTapToPhoneUploadDocuments',
  components: {
    MBlockUploader
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      downloadLoading: false,
      passport: [],
      certificate: [],
      errors: {
        passport: [],
        certificate: []
      }
    }
  },
  computed: {
    personType () {
      return this.$Organization.personType
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    },
    uploadItems () {
      return this.personType === 'juridical' ? this.uploadItemsJuridical : this.uploadItemsIndividual
    },
    uploadItemsIndividual () {
      return [
        {
          name: 'certificate',
          icon: 'file-text-dark',
          title: this.$t('Copy of RNOKPP (TIN)'),
          text: this.$t('Certificate of the tax authority on the assignment of the registration number of the taxpayer\'s registration card'),
          rules: 'required|size:5000|ext:' + this.validationAccept
        },
        {
          name: 'passport',
          icon: 'passport',
          title: this.$t('Passport/Document'),
          text: this.$t('Passport of a citizen of Ukraine/passport of a citizen of a foreign state/identity document'),
          rules: 'required|size:15000|ext:' + this.validationAccept
        }
      ]
    },
    uploadItemsJuridical () {
      return [
        {
          name: 'certificate',
          icon: 'file-text-dark',
          title: this.$t('Extract from the Unified State Register'),
          text: this.$t('Description of the documents submitted by the applicant'),
          rules: 'required|size:5000|ext:' + this.validationAccept
        },
        {
          name: 'passport',
          icon: 'passport',
          title: this.$t('Passports/documents'),
          text: this.$t('Passports/documents confirming the authority of the person'),
          rules: 'required|size:15000|ext:' + this.validationAccept
        }
      ]
    },
    accept () {
      return 'image/*,.docx,.pdf'
    },
    validationAccept () {
      return 'jpg,jpeg,png,docx,pdf'
    }
  },
  methods: {
    async btnClick () {
      this.loading = true

      const { valid: isPassportValid, errors: passportErrors } = await this.$refs.passport[0].$refs.fileInput.validate()
      const { valid: isCertificateValid, errors: certificateErrors } = await this.$refs.certificate[0].$refs.fileInput.validate()
      if (isPassportValid && isCertificateValid) {
        const allDocuments = this._.flatten([this.passport, this.certificate])

        this.$emit('documents', allDocuments)
        this.$emit('nextStep', {
          prevStep: 'uploadDocuments',
          nextStep: 'contactForm'
        })
      } else {
        this.errors.certificate = certificateErrors
        this.errors.passport = passportErrors
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tap-to-phone-upload-documents {
  display: flex;
  flex-direction: column;

  //&__content {
  //  overflow-y: auto;
  //  overflow-x: hidden;
  //}

  &__item-link {
    color: $focus-color;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    background: none !important;
    height: 20px !important;
    padding: 0 !important;
    text-decoration: underline;
  }

  &__actions {
    padding-top: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @media (min-width: map-get($breakpoints, 'sm')) {
      flex-direction: row;
    }
  }
}
</style>
