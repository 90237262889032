import Search from '~/models/abstracts/Search'

export class MccCodesSearch extends Search {
  static entity = 'mcccodessearch'
  static primaryKey = 'mcc'

  static fields () {
    return {
      mcc: this.attr(null),
      nameUa: this.attr(null),
      description: this.attr(null)
    }
  }

  toString () {
    return this.nameUa
  }
}

export default MccCodesSearch
