<template lang="pug">
  div(class="dialog")
    h3(class="dialog__title") {{ $t('The file is downloading, please wait') }}
    div(class="dialog__loader")
      e-progress-circular(
        width="4"
        size="xl"
        color="#161b25"
      )
</template>

<script>
import Download from 'js-file-download'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import MallReport from '~/modules/reports/models/MallReport'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  name: 'BlockMallReportDownload',
  components: {
    EProgressCircular
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    intervalRequest: null
  }),
  computed: {
    model () {
      return MallReport
    },
    reportId () {
      return this._.get(this.item, 'id')
    }
  },
  created () {
    this.generateExport()
  },
  beforeDestroy () {
    if (this.intervalRequest) {
      this.intervalRequest.stopExponential()
    }
  },
  methods: {
    checkStatus (reportId, taskId) {
      const request = new IntervalRequest(() => this.model.api().checkStatus(reportId, { id: taskId }), {
        interval: 1e3,
        count: 300,
        maxDelay: 3e5 // ~5 minutes
      })
      this.intervalRequest = request
      return request.startExponential((response) => {
        const status = this._.get(response, 'response.data.status', null)
        return status === this.model.STATUSES.done || status === this.model.STATUSES.error
      })
    },
    async generateReport () {
      const taskId = this._.get(await this.model.api().generateReport(this.reportId, { extension: 'EXCEL' }), 'response.data.id')
      const status = this._.get(await this.checkStatus(this.reportId, taskId), 'response.data.status')
      if (status === this.model.STATUSES.error) {
        this.$notification.error(this.$t('An error occurred while generating the report file'))
      }
    },
    async generateExport (generateFile) {
      try {
        if (generateFile) {
          await this.generateReport()
        }
        const blob = this._.get(await this.model.api().generateExport(this.reportId), 'response.data')
        Download(blob, 'mall-report.xlsx')
        this.closeModal()
      } catch (e) {
        const status = this._.get(e, 'response.status')
        if (status === 404 && !generateFile) {
          await this.generateExport(true)
        } else {
          this.$handlers.error(e, this)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__title {
    text-align: center;
  }

  &__loader {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
