<template lang="pug">
  div(class="tap-to-phone-cash-register-status")
    v-btn(
      v-if="neededAction"
      class="pos-terminal-cash-register-status__btn"
      @click="handleAction"
      :loading="loading"
      dark
      small
    ) + {{ $t('Connect') }}
    div(v-else) {{ cashRegisterText }}
</template>

<script>
import contentDialog from '~/mixins/dialogs/contentDialog'
import Oschadbank from '~/modules/acquiring/models/Oschadbank'

export default {
  name: 'BlockTapToPhoneCashRegisterStatus',
  mixins: [contentDialog],
  data: () => ({
    loading: false
  }),
  computed: {
    item () {
      return this._.get(this.$attrs, 'item')
    },
    neededAction () {
      const hasSuccessAcquiringOrder = this._.get(this.item, 'oschadbankAcquiringOrder.status') === Oschadbank.STATUSES.success
      const cashRegister = this._.get(this.item, 'cashRegister')
      return hasSuccessAcquiringOrder && !cashRegister
    },
    cashRegisterText () {
      const cashRegister = this._.get(this.item, 'cashRegister')
      return cashRegister ? `№${cashRegister.number || '–'} (${cashRegister.fiscalNumber || '–'})` : '-'
    }
  },
  created () {
  },
  methods: {
    async handleAction () {
      await this.contentDialog.open({
        component: 'block-tap-to-phone-form',
        width: '900',
        contentNoGutters: true,
        componentProps: {
          item: this.item,
          isUpdating: true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.pos-terminal-cash-register-status {
  &__btn {
    max-height: 26px;
    font-size: 0.68rem;
  }
}
</style>
