import { get } from 'lodash/object'
import authUser from '~/modules/auth/authUser'
import Organization from '~/models/directories/Organization'
import ManagedOrganizations from '~/models/directories/ManagedOrganizations'
import ManagedOrganizationsSearch from '~/models/directories/search/ManagedOrganizationsSearch'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'
import CurrentUser from '~/modules/auth/Models/CurrentUser'

export default async function ({ route }, force = false, silent = true) {
  const excludedRoutes = ['index', 'auth-login', 'payment-success', 'payment-error']
  if (route && excludedRoutes.includes(route.name)) {
    return
  }
  const user = authUser.get()

  if (!get(user, 'isSuperAdmin')) {
    const currentOrganization = currentOrganizationService.getCurrentOrganization()
    const reloadOrganizations = !get(user, 'ownedOrganization.edrpou')
    if (currentOrganization && user?.ownedOrganization && currentOrganization.id !== user?.ownedOrganization?.id) {
      if (!ManagedOrganizations.query().whereId(currentOrganization.id).first()) {
        await ManagedOrganizations.api().read(get(currentOrganization, 'id'))

        // if (get(ManagedOrganizations.query().first(), 'edrpou') && reloadOrganizations) {
        //   reloadOrganizations = false
        // }
      }
    }

    const isOwner = get(user, 'isOwner')
    const hasNoManagedOrganizations = get(user, 'managedOrganizationsTotalItems') === null

    if (force || reloadOrganizations) {
      try {
        if (hasNoManagedOrganizations) {
          const res = await ManagedOrganizations.api().getByUser(get(user, 'id'), {
            params: {
              limit: 10,
              offset: 1
            }
          })
          const data = get(res, 'response.data.data')
          const totalItems = get(res, 'response.data.meta.totalItems')
          ManagedOrganizationsSearch.insert({
            data
          })
          CurrentUser.update({
            where: get(user, 'id'),
            data: {
              managedOrganizationsTotalItems: totalItems
            }
          })
        }

        if (isOwner) {
          await Organization.api().within(authUser.get(), '/owned-organization', { dataKey: null })
        }

        if (hasNoManagedOrganizations || isOwner) {
          currentOrganizationService.updateCurrentOrganization()
        }
      } catch (e) {
        if (!silent) { throw e }
      }
    }
  }
}
