<template lang="pug">
  ValidationObserver(ref="validator" slim)
    v-form(@submit.prevent.stop="saveEmail")
      v-row(no-gutters)
        v-col(cols="12")
          ValidationProvider(mode="passive" rules="email" :name="$t('Email')" vid="email" v-slot="{ errors }")
            v-text-field(v-model="email" outlined=true :placeholder="$t('Email')" :error-messages="errors")
      v-row(justify="center")
        v-col(cols="auto")
          v-btn(class="main-button mb-2" type="submit" :loading="loading")
            | {{ $t('Save') }}
</template>

<script>
import Outlets from '~/models/directories/Outlets'

export default {
  props: {
    outletId: {
      type: String,
      required: true
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    loading: false,
    email: null
  }),
  computed: {
    outlet () {
      return Outlets.query().whereId(this.outletId).first()
    }
  },
  created () {
    this.email = this._.get(this.outlet, 'serviceEmail', null)
  },
  methods: {
    async saveEmail () {
      const valid = await this.$refs.validator.validate()
      if (!valid) { return null }

      const payload = { serviceEmail: this.email || null }

      try {
        this.loading = true
        await Outlets.api().setServiceEmail(this.outletId, payload)
        this.$notification.success(this.$t('Email was updated'))
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this.$refs.validator)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//
</style>
