<template lang="pug">
  div(class="empty-table")
    div(class="empty-table__image")
      v-img(:src="require('~/assets/images/racoons/racoon-dont-know.svg')")
    div(class="empty-table__content")
      h3(class="empty-table__title") {{ $t('Nothing found') }}
      p(class="empty-table__text") {{ $t('Check if request is correct and try again') }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'

export default {
  mixins: [responsive]
}
</script>

<style scoped lang="scss">
.empty-table {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px 32px;
  border-radius: 24px;
  padding: 32px 0;
  flex-wrap: wrap;

  @media (max-width: map-get($breakpoints, 'sm')) {
    gap: 16px;
    padding: 16px;
  }

  &__content {
    width: 100%;
    max-width: 340px;
    text-align: left;

    @media (max-width: map-get($breakpoints, 'sm')) {
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 8px;
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  &__text {
    color: #555;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
}
</style>
