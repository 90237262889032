import checkPropCtx from '~/mixins/methods/checkPropCtx'

const filterOrmObject = {
  mixins: [checkPropCtx],
  methods: {
    filterOrmObject (payload) {
      const obj = Object.assign({}, payload)
      const keys = Object.keys(obj)

      const localFields = this.model.getOrmFields({ only: 'local' })
      const notNullFields = this.model.getOrmFields({ only: 'notNull', nested: true })

      for (const item of keys) {
        if (obj[item] === undefined) {
          obj[item] = null
        }

        // Removes null fields only for create context.
        if (this._.isNull(obj[item]) && this.$isCreateCtx(this.context)) {
          delete obj[item]
        }

        // Removes all local fields.
        if (localFields.length && localFields.includes(item)) {
          delete obj[item]
        }

        // Removes all not not nullable fields.
        if (notNullFields.length && notNullFields.includes(item) && this._.isNull(obj[item])) {
          delete obj[item]
        }
      }

      return obj
    }
  }
}

export default filterOrmObject
