<template lang="pug">
  div(class="hints-block-content")
    h3(v-if="!mobile" class="hints-block-content__title") {{ title }}
    div(v-if="currentStep === steps.monoSettingsForm")
      p(v-if="!isEditing") {{ $t('This window allows you to check the cash register and the terminal you are connected to, or unlink the mono account and reconnect') }}
      p(v-else) {{ $t('This window allows you to change the cash register for the terminal you are connected to') }}
    div(v-else-if="currentStep === steps.easypaySettingsForm")
      p(v-if="!isEditing") {{ $t('Copy key in EasyPay account and choose cash register you want to connect internet acquiring') }}
      p(v-else) {{ $t('This window allows you to change EasyPay key') }}
    div(v-else-if="currentStep === steps.rozetkaPaySettingsForm")
      p(v-if="!isEditing") {{ $t('Enter the login and password to connect to Internet acquiring from RozetkaPay') }}
      p(v-else) {{ $t('This window allows you to change your RozetkaPay login and password') }}
    div(v-else-if="currentStep === steps.monoQr" class="d-flex flex-column justify-space-between h-100")
      p {{ $t('Click or scan QR-code to login and follow instructions') }}
      div(v-if="_.get(this.$Organization, 'personType') === 'juridical'" class="pb-10 text-center")
        p {{ $t('Connect without app') }}
        v-btn(class="main-button mt-2" @click="$emit('setTokenManual')") {{ $t('Connect') }}
</template>

<script>
export default {
  name: 'BlockInternetHintContent',
  props: {
    steps: {
      type: Object,
      default: () => {
      }
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return `💡 ${this.$t('Hint')}`
    }
  }
}
</script>

<style scoped lang="scss">
</style>
