<template lang="pug">
  div(class="block-goods-edit dialog")
    h3(class="dialog__title mb-3") {{ $t('Edit group') }}
    div
      ValidationObserver(ref="form")
        t-orm-fields(:name.sync="name" :items="fields")
    div(class="block-goods-edit__uploader mb-8")
      p(class="block-goods-edit__subtitle") {{ $t('Image') }}
      m-block-uploader(
        type="image"
        icon="landscape"
        ref="uploader"
        :title="$t('Upload an image to illustrate the folder')"
        :subtitle="$t('PNG or JPG (recommendation 150×100px)')"
        :accept="accept"
        :rules="rules"
        :validation-field-name="$t('Image')"
        :files.sync="files"
        :preview-image="goodsGroupThumbnail"
        validation-mode="lazy"
        :has-image-been-hidden="hideThumbnailLocally"
        @deleteImage="deleteImageLocally"
      )
    div(class="block-goods-edit__actions")
      v-btn(
        class="main-button"
        :loading="loading"
        @click="submit"
      ) {{ $t('Save') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import MBlockUploader from '~/components/modules/blocks/concrete/m-block-uploader'
import GoodsGroups from '~/modules/goods/models/GoodsGroups'
import images from '~/modules/goods/mixins/images'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'

export default {
  name: 'MBlockGoodsGroupEdit',
  components: {
    TOrmFields,
    MBlockUploader
  },
  mixins: [images],
  props: {
    item: {
      type: null,
      default: null
    }
  },
  data () {
    return {
      name: null,
      files: [],
      loading: false,
      hideThumbnailLocally: false
    }
  },
  computed: {
    model () {
      return GoodsGroups
    },
    fields () {
      return [
        {
          model: 'name',
          component: 'v-text-field',
          provider: {
            vid: 'name',
            name: 'Group name',
            rules: 'required|max:150'
          },
          attrs: {
            type: 'text',
            label: 'Group name',
            outlined: true
          },
          fieldVal: () => {
            if (this.item) {
              return this.item.name
            }
          }
        }
      ]
    },
    goodsGroupThumbnail () {
      if (this.hideThumbnailLocally) {
        return this.previewImage || null
      }
      return this.item.image_url || this.previewImage
    }
  },
  methods: {
    deleteImageLocally () {
      this.hideThumbnailLocally = true
      this.removePreviewImage()
      this.files = []
    },
    checkStatus (id) {
      const resolveStatus = 'done'
      const request = new IntervalRequest(() => AccountingGoods.api().getImage(id), {
        interval: 1e3,
        maxDelay: 12e4 // ~2 minutes
      })
      this.request = request
      return request.startExponential(response => this._.get(response, 'response.data.status', null) === resolveStatus)
    },
    async updateImage () {
      try {
        const form = new FormData()
        form.append('file', this.imageBlob, this.files[0].name)

        const taskId = this._.get(await this.model.api().uploadImage(this.item.id, form), 'response.data.task_id', null)
        const resolved = this._.get(await this.checkStatus(taskId), 'response.data', null)

        if (resolved) {
          await this.model.api().read(this.item.id)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async handleThumbnail () {
      try {
        this.loading = true
        if (this.imageBlob) {
          await this.updateImage()
        }
        if (this.hideThumbnailLocally && !this.imageBlob) {
          await this.model.api().deleteImage(this.item.id)
          await this.model.api().read(this.item.id)
        }
        const group = this.model.query().whereId(this._.get(this.item, 'id')).first()
        this.$root.$emit('goodsGroupsChangeAction', { action: 'edit', group })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async submit () {
      const valid = await this.$refs.form.validate()
      const validImage = await this.$refs.uploader.validate()

      if (!valid || !validImage) {
        return
      }
      if (this.item.name === this.name) {
        await this.handleThumbnail()
        this.$attrs.closeModal()
        return
      }
      try {
        this.loading = true
        await this.model.api().update(this.item, { name: this.name })
        await this.handleThumbnail()
        this.$notification.success(this.$t('Group edited successfully'))
        this.$attrs.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.block-goods-edit {
  padding: 0 0 20px;

  &__subtitle {
    color: rgba(22, 27, 37, 0.50);
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 11px;
      margin-bottom: 12px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
