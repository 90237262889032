import processingReceipt from '~/modules/receipt/mixins/actions/processingReceipt'

const retailExpo = {
  mixins: [processingReceipt],
  data: () => ({
    allowedRetailExpoOrgs: [
      'c066123c-deff-4418-beb7-752c05ff0c07', // Bohdan stage
      '9962f238-42b9-43fc-aa0c-81ff148b19cc'
    ]
  }),
  computed: {
    isRetailExpoOrg () {
      return this.allowedRetailExpoOrgs.includes(this._.get(this.$Organization, 'id'))
    }
  },
  methods: {
    async printHtml (id) {
      const res = await this.createHtmlReceiptPreview(id)
      if (res) {
        const w = window.open('', 'PRINT', 'height=800,width=600')
        w.document.write(res)
        w.document.close() // necessary for IE >= 10
        w.focus() // necessary for IE >= 10*/
        w.print()
      }
    }
  }
}

export default retailExpo
