<template lang="pug">
  v-chip(:color="color") {{ $t(value) }}
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      default: null
    }
  },
  computed: {
    color () {
      return this.value ? this.colors[this.value] : null
    }
  }
}
</script>

<style lang="scss" scoped>
//
</style>
