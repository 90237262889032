<template lang="pug">
  div(class="block-banner" :style="bannerStyles")
    div(
      class="block-banner__content"
      :class="contentClasses"
    )
      h4(
        v-if="title"
        class="block-banner__title mb-3"
        :style="titleStyles"
      ) {{ $t(title) }}
      slot(name="text")
      slot(name="actions")
    div(v-if="!hideImage" class="block-banner__img" :class="imageWrapperClasses")
      img(
        v-if="!xsDevice || !imageMobile"
        :src="image"
      )
      img(
        v-else
        :src="imageMobile"
      )
    div(v-if="logoName" class="block-banner__logo")
      e-svg-icon(:name="logoName" size="bg")
</template>

<script>
import responsive from '~/mixins/pages/responsive'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
export default {
  name: 'MBlockBanner',

  components: {
    ESvgIcon
  },

  mixins: [responsive],

  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    title: {
      type: String,
      default: null
    },
    titleColor: {
      type: String,
      default: '#161B25'
    },
    image: {
      type: String,
      default: null
    },
    imageMobile: {
      type: String,
      default: null
    },
    backgroundColor: {
      type: String,
      default: null
    },
    backgroundImage: {
      type: String,
      default: null
    },
    backgroundImageMobile: {
      type: String,
      default: null
    },
    logoName: {
      type: String,
      default: null
    },
    hideImage: {
      type: Boolean,
      default: false
    },
    imageClasses: {
      type: Object,
      default: null
    },
    isImageCover: {
      type: Boolean,
      default: true
    },
    isContentWide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bannerStyles () {
      const stylesObj = {}
      if (this.backgroundImage) {
        if (this.xsDevice) {
          stylesObj.backgroundImage = `url(${this.backgroundImageMobile})`
        } else {
          stylesObj.backgroundImage = `url(${this.backgroundImage})`
        }
        stylesObj.backgroundSize = 'cover'
        stylesObj.backgroundPosition = 'center'
      } else {
        stylesObj.backgroundColor = this.backgroundColor || '#F7F7F7'
      }

      if (this.width !== '100%' || this.height !== '100%') {
        stylesObj.width = this.width
        stylesObj.height = this.height
      }

      return stylesObj
    },
    imageWrapperClasses () {
      return { ...this.imageClasses, 'pb-8': this.xsDevice && !this.isImageCover }
    },
    contentClasses () {
      const classes = []
      if (this.xsDevice) {
        if (this.hideImage) {
          classes.push('justify-end')
        }
        classes.push('block-banner__content--full')
      } else if (!this.xsDevice) {
        if (this.hideImage || this.isContentWide) {
          classes.push('block-banner__content--wide')
        }
      }
      return classes
    },
    titleStyles () {
      return { color: this.titleColor }
    }
  }
}
</script>

<style scoped lang="scss">
.block-banner {
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px 0;
  max-width: 1000px;
  position: relative;

  @media (min-width: map-get($breakpoints, 'sm')) {
    flex-direction: row;
    gap: 24px;
    margin: 24px 0 32px 0;
  }

  &__content {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (min-width: map-get($breakpoints, 'sm')) {
      padding: 30px 0 30px 40px;
      width: 50%;
    }

    &--wide {
      width: 75%;
    }
    &--full {
      width: 100%;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      line-height: 28px;
      font-size: 20px;
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: map-get($breakpoints, 'sm')) {
      width: 50%;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      @media (min-width: map-get($breakpoints, 'sm')) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  &__logo {
    position: absolute;
    bottom: -10px;
    right: 10px;
    z-index: 5;
  }
}
</style>
