<template lang="pug">
  div(class="monobank-acquiring-settings-form")
    div(v-if="!isEditing && monoAccountName" class="monobank-acquiring-settings-form__mono-wrapper")
      div
        div(class="monobank-acquiring-settings-form__mono-name") {{ $t('Monobank') }}
        div(class="monobank-acquiring-settings-form__mono-user") {{ monoAccountName }}
      v-btn(
        class="secondary-button"
        :class="{ 'w-100': xsDevice }"
        color="black"
        outlined
        :loading="revokeLoading"
        @click="revokeToken"
      ) {{ $t('Revoke') }}
    v-form
      ValidationObserver(ref="form" slim)
        t-orm-fields(v-bind.sync="form" :items="fields")
        div(v-if="showQrCodes")
          p(class="mb-1") {{$t('Select QR cash registers:')}}
          ValidationProvider(
            vid="defaultQr"
            :name="$t('QR cash register')"
            rules="required"
            v-slot="{ errors }"
          )
            v-radio-group(
              v-model="defaultQr"
              class="mt-0 payment-type__radio-wrap"
              :error-messages="errors"
            )
              v-radio(
                :key="qr.qrId"
                v-for="qr in qrCodesList"
                :label="qr.shortQrId + ' (' + qr.qrId + ')'"
                :value="qr.qrId"
                color="black"
                :ripple="false"
              )
    div(class="monobank-acquiring-settings-form__actions")
      v-btn(
        class="main-button"
        :class="{ 'w-100': xsDevice }"
        :disabled="disabledSubmit"
        :loading="loading"
        @click="saveToken"
      ) {{ $t('Save') }}
</template>
<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import CashRegisters from '~/models/directories/CashRegisters'
import CashRegistersSearch from '~/models/directories/search/CashRegistersSearch'
import AcquiringSettings from '~/modules/acquiring/models/AcquiringSettings'
import Acquiring from '~/modules/acquiring/models/Acquiring'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockMonobankAcquiringSettingsForm',
  components: {
    TOrmFields
  },
  mixins: [responsive],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: null,
      validator: (value) => {
        return ['qr', 'internet'].includes(value)
      }
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    terminals: {
      type: Array,
      default: null
    },
    monoAccountName: {
      type: String,
      default: null
    },
    tableRequest: {
      type: Function,
      default: () => {}
    },
    showTokenInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        cashRegister: null,
        terminal: null,
        token: null
      },
      loading: false,
      revokeLoading: false,
      showQrCodes: false,
      qrCodeSelected: false,
      defaultQr: null,
      disabledSubmit: false,
      manualQrCodes: null,
      debouncedCheckToken: null
    }
  },
  computed: {
    terminalsNames () {
      return this._.map(this.terminals, i => i?.name)
    },
    fields () {
      return [
        {
          model: 'token',
          component: 'v-text-field',
          provider: {
            vid: 'token',
            name: 'Monobank token',
            rules: 'required'
          },
          attrs: {
            outlined: true,
            label: 'Enter monobank token',
            visible: () => this.showTokenInput || (this.isEditing && this.item.token)
          }
        },
        new AutocompleteBuilder({
          model: 'cashRegister',
          label: 'Connected to cash register',
          rules: 'required',
          query: model => model.api().filter({ mode: CashRegisters.itemModes.checkbox.mode }),
          asyncDefault: (items) => {
            if (!this._.get(this.item, 'cashRegister', null) && items && items.length === 1) {
              return items[0]
            }
            const cashRegisterOnEdit = this._.get(this.item, 'cashRegister', null)
            if (cashRegisterOnEdit) {
              return cashRegisterOnEdit
            }
          },
          itemValue: item => this._.get(item, 'id', null)
        }, CashRegistersSearch).build(),
        {
          model: 'terminal',
          component: 'v-select',
          provider: {
            vid: 'terminal',
            name: 'Terminal',
            rules: 'required'
          },
          items: () => this.terminals,
          attrs: {
            outlined: true,
            label: 'Terminals',
            returnObject: true,
            itemValue: 'terminal',
            itemText: (i) => {
              const name = i?.name
              if (this.terminalsNames.indexOf(name) !== this.terminalsNames.lastIndexOf(name)) {
                return `${name} (${i?.terminal})`
              }
              return i?.name
            },
            visible: !this.isEditing && this.terminals
          }
        }
      ]
    },
    qrCodesList () {
      if (this.isEditing) {
        return this.item.qrCodes
      }
      if (this.manualQrCodes) {
        return this.manualQrCodes
      }
      return this._.map(this.form.terminal.qrList, item => ({ qrId: item.invoiceId, shortQrId: item.shortId }))
    }
  },
  watch: {
    'form.terminal' (terminal) {
      this.disabledSubmit = false
      if (this.type === 'qr') {
        if (terminal && terminal.qrList && terminal.qrList.length) {
          if (terminal.qrList.length > 1) {
            this.showQrCodes = true
            this.$notification.info(this.$t('Select qr code for cash register by default'))
          } else {
            this.defaultQr = terminal.qrList[0].invoiceId
          }
        } else {
          this.disabledSubmit = true
          this.$notification.error(this.$t('No available QR codes were found for the selected terminal. Contact the banks support'))
        }
      }
    },
    'form.token' (token) {
      if (token) {
        this.debouncedCheckToken(token)
      }
    }
  },
  created () {
    this.debouncedCheckToken = this._.debounce(this.checkToken, 500)
    if (this.isEditing) {
      if (this.item.qrCodes && this.item.qrCodes.length && this.item.qrCodes.length > 1) {
        this.showQrCodes = true
      }
      if (this.item.token) {
        this.form.token = this.item.token
      }
      const defaultQr = this._.find(this.item.qrCodes, qr => qr.default)
      this.defaultQr = this._.get(defaultQr, 'qrId')
    }
  },
  methods: {
    async checkToken (token) {
      try {
        this.disabledSubmit = false
        if (this.type === 'qr') {
          this.manualQrCodes = this._.get(await AcquiringSettings.api().checkMonoToken({ token }), 'response.data')
          if (this.manualQrCodes && this.manualQrCodes.length) {
            if (this.manualQrCodes.length > 1) {
              this.showQrCodes = true
              this.$notification.info(this.$t('Select qr code for cash register by default'))
            } else {
              this.defaultQr = this.manualQrCodes[0].qrId
            }
          } else {
            this.disabledSubmit = true
            this.$notification.error(this.$t('No available QR codes were found for the selected terminal. Contact the banks support'))
          }
        } else {
          await AcquiringSettings.api().checkMonoToken({ token })
        }
      } catch (e) {
        this.showQrCodes = false
        this.$handlers.error(e, this)
      }
    },
    async saveToken () {
      const valid = await this.$refs.form.validate()
      if (!valid) { return null }

      const payload = {
        cashRegister: `cash-registers/${this.form.cashRegister.id}`,
        qrCodes: this.type === 'qr' ? this.generateQrPayload() : null
      }
      const terminalName = this._.get(this.form, 'terminal.name', null)
      const token = this._.get(this.form, 'terminal.integrationDetails.xToken')
      if (!this.isEditing) {
        payload.type = this.type
        payload.token = token ?? this.form.token
        payload.name = terminalName
        payload.valid = true
      }

      try {
        this.loading = true
        if (this.isEditing) {
          await AcquiringSettings.api().update(this.item, payload)
        } else {
          await AcquiringSettings.api().create(payload)
        }
        if (this.isEditing) {
          this.$notification.success(this.type === 'qr' ? this.$t('QR acquiring settings updated') : this.$t('Internet acquiring settings updated'))
        }
        await this.tableRequest()
        this.closeModal({
          success: true
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    generateQrPayload () {
      if (this.isEditing) {
        const qrCodesLength = this._.get(this.item, 'qrCodes.length', 0)
        if (qrCodesLength) {
          return this.item.qrCodes.map(item => ({
            ...item,
            default: qrCodesLength === 1 ? true : item.qrId === this.defaultQr
          }))
        }
        return null
      }
      if (this.manualQrCodes) {
        const qrCodesLength = this._.get(this.manualQrCodes, 'length', 0)
        return this.manualQrCodes.map(item => ({
          ...item,
          default: qrCodesLength === 1 ? true : item.qrId === this.defaultQr
        }))
      }
      if (this.form.terminal && this.form.terminal.qrList) {
        return this.form.terminal.qrList.map(qr => ({
          qrId: qr.invoiceId,
          shortQrId: qr.shortId,
          pageUrl: `https://pay.mbnk.biz/${qr.invoiceId}`,
          amountType: 'merchant',
          default: this.defaultQr === qr.invoiceId
        }))
      }
      return null
    },
    async revokeToken () {
      try {
        this.revokeLoading = true
        await Acquiring.api().revokeToken()
        this.$notification.success('Token has been revoked successfully')
        this.$emit('changeMonoAccount')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.revokeLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.monobank-acquiring-settings-form {
  &__mono {
    &-wrapper {
      display: flex;
      flex-direction: column;

      background: #43CCB414;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 32px;

      @media (min-width: map-get($breakpoints, 'sm')) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    &-name {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: #161B25BF;
    }
    &-user {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #161B25;
      padding: 12px 0;
      @media (min-width: map-get($breakpoints, 'sm')) {
        padding: 0;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
