<template lang="pug">
  tr(class="v-data-table__mobile-row__tr")
    td(:colspan="itemHeaders.length")
      div(v-for="(field, colIndex) in model.ormCols(config.short, scopedItem.headers, isResponsive)" class="v-data-table__mobile-row")
        div(class="v-data-table__mobile-row__header") {{ itemHeaders[colIndex].text }}
        div(class="v-data-table__mobile-row__cell")
          t-orm-wrapper(
            :item="scopedItem.item"
            :field="field"
            :requestParams="requestParams"
          )
      div(v-if="isEnabledActions" class="pb-4")
        t-orm-menu-actions(:items="intersectedActions" :target="scopedItem.item" :mobile-activator-button="true")
</template>

<script>
import TOrmMenuActions from '~/components/templates/orm/t-orm-menu-actions'
import TOrmWrapper from '~/components/templates/orm/t-orm-wrapper'

export default {
  components: {
    TOrmMenuActions,
    TOrmWrapper
  },
  props: {
    scopedItem: {
      type: Object,
      required: true
    },
    model: {
      type: Function,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    requestParams: {
      type: Object,
      default: () => {
      }
    },
    actions: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    intersectedActions () {
      return this._.get(this.config, 'intersectedActions', [])
    },
    isEnabledActions () {
      if (Number.isFinite(+this.scopedItem.item.primaryVal)) {
        return this.scopedItem.item.primaryVal >= 0
      }

      return true
    },
    itemHeaders () {
      return this.scopedItem.headers.filter(item => item.value !== 'actions' && item.value !== 'data-table-select')
    },
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  }
}
</script>

<style scoped lang="scss">

</style>
