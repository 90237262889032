import ChainInheritance from '~/models/mixins/ChainInheritance'
import Directory from '~/models/abstracts/Directory'
import CashRegisters from '~/models/directories/CashRegisters'
import CashRegisterOrder from '~/models/directories/Orders/CashRegisterOrder'

export default class CashRegistersActivationLog extends ChainInheritance(Directory, []) {
    static entity = 'cashregistersactivationlog'
    static defaultSortParam = 'date'
    static ormLoadWithRelations = true
    static paginated = true

    static ormTrans = {
      single: 'Cash register activation log',
      multy: 'Cash register activation logs'
    }

    static fields () {
      return {
        dateTime: this.attr(null),
        fromMode: this.attr(null),
        toMode: this.attr(null),
        type: this.attr(null)
      }
    }

    get dateString () {
      return this.getDateTime(this.dateTime)
    }

    static ormHeaders = [
      { text: 'Date', value: 'dateString', sortable: true, filterable: false },
      { text: 'From mode', value: 'fromMode', sortable: false, filterable: false },
      { text: 'To mode', value: 'toMode', sortable: false, filterable: false }
    ]

  static statusComponent = {
    component: 'e-models-cols-map',
    attrs: {
      chips: true,
      template: '{text}',
      map: (item) => {
        if (!item) { return {} }
        const {
          checkbox,
          deactivated,
          alert,
          notActive,
          deactivatedByUser,
          onRemovalInSfs,
          hasRemovalOrderError,
          onModifyInSfs,
          hasModifyOrderError,
          test,
          reserve
        } = CashRegisters.itemModes

        return {
          id: item.id,
          model: CashRegisterOrder,
          [checkbox.mode]: {
            text: checkbox.text,
            type: checkbox.value
          },
          [deactivated.mode]: {
            text: deactivated.text,
            type: deactivated.value,
            tooltip: 'Cash register was deactivated in sfs'
          },
          [notActive.mode]: {
            text: notActive.text,
            type: notActive.value
          },
          [alert.mode]: {
            text: alert.text,
            type: alert.value,
            tooltip: 'It need to user interaction to use this cash register'
          },
          [deactivatedByUser.mode]: {
            text: deactivatedByUser.text,
            type: deactivatedByUser.value,
            tooltip: 'Cash register is deactivated, so it will be not added to invoice'
          },
          [onRemovalInSfs.value]: {
            text: onRemovalInSfs.text,
            type: onRemovalInSfs.value,
            tooltip: 'The cash register is on removal in the SFS'
          },
          [hasRemovalOrderError.value]: {
            text: hasRemovalOrderError.text,
            type: hasRemovalOrderError.value,
            error: {
              id: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.id'),
              taxErrorMessage: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.taxErrorMessage'),
              hasErrorFile: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.hasErrorFile')
            }
          },
          [onModifyInSfs.value]: {
            text: onModifyInSfs.text,
            type: onModifyInSfs.value,
            tooltip: 'The cash register is on modify in the SFS'
          },
          [hasModifyOrderError.value]: {
            text: hasModifyOrderError.text,
            type: hasModifyOrderError.value,
            error: {
              id: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.id'),
              taxErrorMessage: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.taxErrorMessage'),
              hasErrorFile: this._.get(CashRegisters.checkTaxOrdersStatus(item), 'lastTaxOrder.hasErrorFile')
            }
          },
          [test.value]: {
            text: test.text,
            type: test.value,
            tooltip: 'Use this cash register to test issuing checks',
            appearance: {
              color: '#ff6a00',
              textColor: '#fff'
            }
          },
          [reserve.value]: {
            text: reserve.text,
            type: reserve.value,
            appearance: {
              color: '#04aed5',
              textColor: '#fff'
            }
          }
        }
      }
    }
  }

    static ormColsComponents = {
      fromMode: this.statusComponent,
      toMode: this.statusComponent
    }
}
