<template lang="pug">
  div(
    class="hints-block-content"
    :class="{ 'hints-block-content--mobile': mobile }"
  )
    div(v-if="currentStep === steps.options")
      p {{ $t('To add a cashier, you need the EDS key of the employee or his ID, and the “key of the first document”') }}
      p
        b(class="mr-1") {{ $t('The key of the first document') }} –
        | {{ $t('this is the EDS key that was used to sign the first document (application, request, report, etc.) in the DPS') }}
      p
        | {{ $t('If you don\'t have a cashier\'s key, you can') }}
        b(class="mx-1") “{{ $t('Generate a key via DepositSign') }}” –
        | {{ $t('is a cloud-based service provider from DepositSign for Checkbox cashier registration and DepositSign check signing') }}
      e-link(
        class="mt-6"
        href="https://wiki.checkbox.ua/portal/cashiers"
      ) {{ $t('Detailed instructions') }} →
    div(v-else-if="currentStep === steps.selectEdsKey")
      p {{ $t('Select the EDS key that will be used for cash register operation and check signing') }}
    div(v-else-if="formIsShown")
      p
        span(class="mr-1") {{ $t('To register, you need to specify the name of the cashier, as well as come up with a login and password, which will be used to log in to the cashier in the mobile application or on') }}
        e-link(
          href="https://my.checkbox.ua/"
        ) my.checkbox.ua
      template(v-if="currentStep === steps.employeeFormEdsKey")
        p
          b(class="mr-1") {{ $t('CheckboxSignature') }} –
          | {{ $t('the key that is automatically used for fiscal operations (we recommend choosing "Yes")') }}
        p {{ $t('If you do not use CheckboxSignature on a secure cloud service, then you will need to run the key in the browser\'s temporary cache or use CheckboxSignature locally') }}
      template(v-if="currentStep === steps.existingDsKey")
        p
          b(class="mr-1") DepositSign –
          | {{ $t('the data must be taken from the DepositSign office') }}
      template(v-if="currentStep === steps.manualEmployeeForm")
        p
          span(class="mr-1") {{ $t('How to find the key id –') }}
          e-link(
            href="https://docs.google.com/document/d/1oARtTaDOeco2McGeeqNVIWxJeVzyjhyJgB4VDlHrdC8"
          ) {{ $t('Instructions') }} 🔗
      template(v-if="currentStep === steps.creatingDsKey")
        e-link(
          class="mt-6"
          href="https://wiki.checkbox.ua/portal/cashiers"
        ) {{ $t('Detailed instructions') }} →
    div(v-else-if="currentStep === steps.creatingDsKey")
      p
        span(class="mr-1") {{ $t('To register, you need to specify the name of the cashier, phone number, and also come up with a login and password, which will be used to log in to the cashier in the mobile application or on') }}
        e-link(
          href="https://my.checkbox.ua/"
        ) my.checkbox.ua
    div(v-else-if="currentStep === steps.sign")
      p {{ $t('This window from the DPS is used for superimposing the signature of the FOP/Director of the LLC, the owner of the Checkbox account') }}
      p {{ $t('To submit documents to the DPS, it is necessary to use the EDS "key of the first document"') }}
      p
        b(class="mr-1") {{ $t('The key of the first document') }} –
        | {{ $t('this is the EDS key that was used to sign the first document (application, request, report, etc.) in the DPS') }}
    div(v-else-if="currentStep === steps.keyReplacementOptions")
      p {{ $t('If you already have a new cashier key, choose to read the data via EDS key') }}
      p {{ $t('Or create a DepositSign cloud key - the key will be generated automatically, avoiding the need to separately obtain and store a file EDS on a personal PC') }}
      p {{ $t('Choose the method convenient for you and follow the instructions') }}
      e-link(
        class="mt-6"
        href="https://wiki.checkbox.ua/uk/portal/cashiers"
      ) {{ $t('Detailed instructions') }} →
    div(v-else-if="currentStep === steps.keyReplacementFormEdsKey")
      p {{ $t('Choose a new EDS key that will be used for the operation of the cash register and signing of checks') }}
      p
        b(class="mr-1") {{ $t('CheckboxSignature') }} –
        | {{ $t('the key that is automatically used for fiscal operations (we recommend choosing "Yes")') }}
      p {{ $t('If you do not use CheckboxSignature on a secure cloud service, then you will need to run the key in the browser\'s temporary cache or use CheckboxSignature locally') }}
    div(v-else-if="currentStep === steps.keyReplacementDsCreating")
      p {{ $t('It is important that you enter a current phone number to which you can receive SMS messages and continue registration!') }}
      e-link(
        class="mt-6"
        href="https://wiki.checkbox.ua/portal/cashiers"
      ) {{ $t('Detailed instructions') }} →
    div(v-else-if="currentStep === steps.keyReplacementDsExisting")
      p
        b(class="mr-1") DepositSign –
        | {{ $t('the data must be taken from the DepositSign office') }}
</template>

<script>
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'BlockEmployeeFormHintContent',
  components: {
    ELink
  },
  props: {
    steps: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formIsShown () {
      return this.currentStep === this.steps.employeeFormEdsKey ||
        this.currentStep === this.steps.existingDsKey ||
        this.currentStep === this.steps.manualEmployeeForm
    }
  }
}
</script>

<style scoped lang="scss">
</style>
