<template lang="pug">
  div(class="qr-acquiring-success")
    div(class="qr-acquiring-success__image")
      v-img(:src="require('~/assets/images/racoons/racoon-nice.svg')" :max-width="xsDevice ? 150 : 200" class="mx-auto")
    h2(v-if="title" class="qr-acquiring-success__title") {{ $t(title) }}
    p(v-if="text" class="qr-acquiring-success__text") {{ $t(text) }}
    div(class="qr-acquiring-success__actions")
      v-btn(
        class="secondary-button"
        @click="closeModal"
      ) {{ $t('Stay') }}
      v-btn(
        class="main-button"
        @click="$router.push('/dashboard/createreceipts')"
      ) {{ $t('Go') }}
</template>

<script>
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'BlockAcquiringSuccess',
  mixins: [responsive],
  props: {
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.qr-acquiring-success {
  padding: 18px 0 12px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    padding: 24px 0 16px;
  }

  &__image {
    margin-bottom: 16px;
  }

  &__title {
    color: #161B25;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__text {
    color: #161B25BF;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 12px;
    flex-wrap: wrap;

    .v-btn {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        max-width: 180px;
      }
    }
  }
}
</style>
