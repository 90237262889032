<template lang="pug">
  div(class="block-employee-information")
    v-img(
      class="block-employee-information__img"
      :src="require('~/assets/images/racoons/racoon-cash-register.svg')"
    )
    h3(
      v-if="title"
      class="block-employee-information__title"
    ) {{ $t(title) }}
    p(
      v-if="text"
      class="block-employee-information__text"
    ) {{ $t(text) }}
    div(class="block-employee-information__actions")
      v-btn(
        @click="closeModal"
        class="main-button"
      ) {{ $t('Continue') }}
</template>

<script>
export default {
  name: 'BlockEmployeeInformation',
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.block-employee-information {
  padding: 16px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    padding: 16px 32px 32px;
  }

  &__img {
    width: 100%;
    max-width: 150px;
    margin: 40px auto 24px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      max-width: 200px;
      margin: 40px auto 32px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $btn-secondary-color;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
    }
  }

  &__text {
    font-size: 12px;
    font-weight: 300;
    margin: 12px 0 0;
    color: rgba(22, 27, 37, 0.75);

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 14px;
      margin: 12px 0 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 14px;
      margin-top: 32px;
    }

    .main-button {
      width: 100%;

      @media (min-width: map-get($breakpoints, 'xs')) {
        max-width: 180px;
      }
    }
  }
}
</style>
