import Base64js from 'base64-js'
import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import i18n from '~/plugins/nuxt-i18n/i18n'

const storage = {}

class EdsIitCheckbox extends Model {
  static entity = '_edsiitcheckbox';

  static fields () {
    return {
      id: this.uid(),
      type: this.attr(null),
      qrUrl: this.attr(null),
      title: this.attr(null),
      helpers: this.attr(null)
    }
  }

  stopIntervalRequest () {
    if (storage.intervalRequest) {
      storage.intervalRequest.stopExponential()
    }
  }

  async signByDiia (dataToSign, options = {}) {
    const { endUser, title, keyCheck = true } = options
    const { axios, organization } = (this.helpers || {})
    let content = Base64js.fromByteArray(new TextEncoder().encode(String(dataToSign)))

    if (ArrayBuffer.isView(dataToSign)) {
      content = Base64js.fromByteArray(dataToSign)
    }

    const deepLinkData = await axios.post('/cloud-signature/diia/deeplink', {
      name: title || i18n.t('Signature of the request to the DPS'),
      content
    })
    const { id, deepLink } = get(deepLinkData, 'data', {})
    const pingSignUrl = '/cloud-signature/' + id + '/ping'
    const internalSignUrl = '/cloud-signature/' + id + '/internal-signature'
    await this.saveModelData({
      qrUrl: deepLink
    })
    storage.intervalRequest = new IntervalRequest(async () => {
      return await axios.get(pingSignUrl)
    }, {
      maxDelay: 3e5 // 5 minutes
    })
    const resolveCondition = res => get(res, 'data.status') === 'signed'
    const signed = get(await storage.intervalRequest.startExponential(resolveCondition), 'data.status')
    if (signed !== 'signed') {
      return null
    }
    const signedData = get(await axios.get(internalSignUrl), 'data.signature', null)
    const keyData = await endUser.GetSigner(Base64js.toByteArray(signedData), 0)
    const edsKeyData = new EdsKeyData([keyData])
    if (!keyData) {
      throw new Error('We could not read the key data. Please try again or choose a different key')
    } else if (keyCheck && edsKeyData.identity !== get(organization, 'edrpou')) {
      throw new Error('The USREOU codes do not match. Please choose another')
    }
    return signedData
  }

  async sign (dataToSign, options = {}) {
    const { onInterfaceActionError, endUser, isSmartId, isDiia } = (storage[this.type] || {})
    const { fallbackMethod, title } = options
    try {
      if (isSmartId || isDiia || title) {
        await this.saveModelData({
          title,
          qrUrl: null
        })
      }
      if (isDiia) {
        return await this.signByDiia(dataToSign, { ...options, endUser })
      }
      return await endUser.SignDataInternal(true, dataToSign, true)
    } catch (e) {
      if (e?.code === 12) {
        return
      }
      await this.delete()
      if (typeof onInterfaceActionError === 'function') {
        await onInterfaceActionError(e)
      }
      if (typeof fallbackMethod === 'function') {
        await fallbackMethod()
      }
    }
  }

  async envelopData (certs = [], dataToEnvelope) {
    const { onInterfaceActionError, endUser } = (storage[this.type] || {})
    try {
      return await endUser.EnvelopData(certs, dataToEnvelope, false, true, true, true)
    } catch (e) {
      if (e?.code === 12) {
        return
      }
      await this.delete()
      if (typeof onInterfaceActionError === 'function') {
        await onInterfaceActionError(e)
      }
    }
  }

  async saveModelData (data) {
    if (!this) {
      return
    }
    await this.$update(data)
  }

  saveData (data = {}) {
    const savedData = storage[this.type] || {}
    storage[this.type] = {
      ...savedData,
      ...data,
      sign: async (...params) => {
        return await this.sign(...params)
      },
      envelopData: async (...params) => {
        return await this.envelopData(...params)
      }
    }
  }

  async delete () {
    delete storage[this.type]
    await this.$delete()
  }

  getInterface () {
    return storage[this.type]
  }
}

export default EdsIitCheckbox
