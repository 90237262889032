import { render, staticRenderFns } from "./information-snackbar.vue?vue&type=template&id=64f2cab7&scoped=true&lang=pug&"
import script from "./information-snackbar.vue?vue&type=script&lang=js&"
export * from "./information-snackbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f2cab7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VSnackbar})
