import moment from 'moment-timezone'
import setAuthenticatedCashier from '~/modules/receipt/mixins/setters/setAuthenticatedCashier'
import informationDialog from '~/mixins/dialogs/informationDialog'
import Shifts from '~/models/directories/Shifts'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import processingAuth from '~/modules/receipt/mixins/actions/processingAuth'
import processingApiRequest from '~/modules/receipt/mixins/actions/processingApiRequest'
import PosTerminals from '~/modules/acquiring/models/PosTerminals'
import posTerminalProcesses from '~/modules/receipt/mixins/posTerminalProcesses'

const getDateTimeProcessing = (datetime) => {
  if (!datetime) { return null }
  // TODO Временное решение (таймзона)
  const date = moment(datetime).toDate()
  let offset = 0
  if (datetime.includes('+03:00')) {
    offset = 3
  } else if (datetime.includes('+02:00')) {
    offset = 2
  } else if (datetime.includes('+01:00')) {
    offset = 1
  }
  date.setHours(Math.round(date.getHours() + offset))
  return moment(date).toDate()
}

const processingShifts = {
  mixins: [setAuthenticatedCashier, informationDialog, processingAuth, processingApiRequest, posTerminalProcesses],
  methods: {
    async checkIfFirstWork () {
      const visitedFaq = this._.get(this.$localStorageClientData(), 'visitedMenu[dashboard-Faq]')
      const hasShifts = this._.get(this.$localStorageClientData(), 'hasShifts')
      if (visitedFaq || hasShifts) {
        return false
      }
      try {
        const shiftsCount = this._.get(await Shifts.api().filter({ limit: 1, offset: 1 }).all({ save: false }), 'response.data.meta.totalItems')
        const hasShifts = Boolean(parseInt(shiftsCount))
        this.$setLocalStorageClientData({
          hasShifts
        })
        return !hasShifts
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        return false
      }
    },

    // top level functions
    async processShiftForCashier (authenticatedCashier) {
      if (!await this.checkShiftForCashier(authenticatedCashier)) {
        await this.createShiftForCashier(authenticatedCashier)
      }
    },

    async checkShiftForCashier (authenticatedCashier) {
      if (!authenticatedCashier?.accessToken) {
        return
      }
      await authenticatedCashier?.maybeStartSignAgent()
      const currentShift = this._.get(await this.getExistedShift(authenticatedCashier?.accessToken), 'response.data', null)
      if (currentShift) {
        const shiftFn = this._.get(currentShift, 'cash_register.fiscal_number', null)
        const currentCashRegisterFn = this._.get(authenticatedCashier, 'cashRegister.fiscalNumber', false)
        if (shiftFn !== currentCashRegisterFn) {
          await this.informationDialog.open({
            text: this.$t('dialogs.information.youHaveShiftInOtherCashRegister', { shiftFn }),
            buttonText: 'Okay',
            textClass: 'text-center',
            width: '600px'
          })
          await this.logOut(authenticatedCashier)
          return false
        }
        if (currentShift.status === Shifts.processingStatuses.OPENED) {
          const currentDate = new Date()
          const shiftOpenedDate = new Date(getDateTimeProcessing(this._.get(currentShift, 'opened_at')))

          if (shiftOpenedDate.setHours(0, 0, 0, 0) - currentDate.setHours(0, 0, 0, 0) < 0) {
            await this.informationDialog.open({
              text: this.$t('dialogs.information.youHaveOpenedShiftInPast'),
              buttonText: 'Okay',
              textClass: 'text-center',
              width: '600px'
            })
          }

          if (await this.checkIfFirstWork()) {
            await this.informationDialog.open({
              hideCloseButton: true,
              width: '530px',
              text: 'Before starting work, we recommend that you read the "Rules of work with the cash register"',
              buttonText: 'Go',
              onConfirm: () => this.$router.push('/dashboard/faq')
            })
          }

          await this.setAuthenticatedCashier({ shift: currentShift }, authenticatedCashier)
          return true
        }
        if (currentShift.status === Shifts.processingStatuses.CREATED) {
          if (authenticatedCashier.signType === 'eds_key') {
            const intervalRequest = new IntervalRequest(() => this.getExistedShift(authenticatedCashier?.accessToken))
            const resolveCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.OPENED)
            const rejectCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.CLOSED)
            try {
              const signedShift = this._.get(await intervalRequest.startExponential(resolveCondition, rejectCondition), 'response.data')
              await this.setAuthenticatedCashier({ shift: signedShift }, authenticatedCashier)
              return true
            } catch (e) {
              throw new Error(this.$t('Unavailable to open shift. Please, contact with support'))
            }
          } else {
            throw new Error(this.$t('You already have created Shift which need to sign. Please run sign agent to do it'))
          }
        }
      } else {
        await this.setAuthenticatedCashier({ shift: null }, authenticatedCashier)
        return false
      }
    },

    async createShiftForCashier (authenticatedCashier) {
      if (!authenticatedCashier?.accessToken) {
        return
      }
      await authenticatedCashier?.maybeStartSignAgent()
      let openedShift, intervalRequest
      const resolveCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.OPENED || response.data.status === Shifts.processingStatuses.CLOSED)
      try {
        const shiftCreating = this._.get(await this.createShift(authenticatedCashier?.cashRegister?.id, authenticatedCashier?.accessToken), 'response.data')
        intervalRequest = new IntervalRequest(() => this.getShift(shiftCreating.id, authenticatedCashier?.accessToken))
      } catch (e) {
        const hasOcspError = this._.get(e, 'response.data.detail', null) === 'Касира заблоковано: Сертифікат не чинний (при перевірці за протоколом OCSP)'
        if (hasOcspError) {
          return await this.informationDialog.open({
            text: 'We are sorry, but the cashier\'s signature has been blocked',
            buttonText: 'Okay',
            width: '660px'
          })
        }
        throw new Error(this._.get(e, 'response.data.detail', e))
      }
      try {
        openedShift = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data')
        if (openedShift.initial_transaction.status === 'ERROR' && openedShift.initial_transaction.response_error_message) {
          throw new Error(openedShift.initial_transaction.response_error_message)
        }
      } catch (e) {
        throw new Error(this.$t('Error when open shift:') + ' ' + e.message)
      }
      if (openedShift.status === Shifts.processingStatuses.CLOSED) {
        throw new Error(this.$t('Shift already closed by other user. Try again.'))
      }
      await this.setAuthenticatedCashier({ shift: openedShift }, authenticatedCashier)
      return true
    },
    async closeShiftForCashier (authenticatedCashier, skipClientNameCheck) {
      if (!authenticatedCashier?.accessToken) {
        return
      }
      await authenticatedCashier?.maybeStartSignAgent()
      const resolveCondition = ({ response }) => (response.data.status === Shifts.processingStatuses.CLOSED)
      try {
        const closingShift = await this.closeShift(authenticatedCashier?.accessToken, skipClientNameCheck)
        const intervalRequest = new IntervalRequest(() => this.getShift(closingShift?.response?.data?.id, authenticatedCashier?.accessToken))
        const closedShift = this._.get(await intervalRequest.startExponential(resolveCondition), 'response.data', null)

        // Android paylink shift closing
        const posTerminalSettings = PosTerminals.query().where((item) => {
          return this._.get(item, 'cashRegister.id') === this._.get(authenticatedCashier, 'cashRegister.id')
        }).first()
        if (posTerminalSettings && posTerminalSettings?.platform === 'android') {
          await this.makePosTerminalRequest({
            posTerminalSettings,
            operationType: 'settlement',
            fetchMethod: 'GET',
            url: `${this._.get(posTerminalSettings, 'terminalName', null)}/settlement`
          })
        }

        if (closedShift.closing_transaction.status === 'ERROR' && closedShift.closing_transaction.response_error_message) {
          throw new Error(closedShift.closing_transaction.response_error_message)
        }
        await this.setAuthenticatedCashier({ shift: null }, authenticatedCashier)
        return closedShift
      } catch (e) {
        const errorDetail = this._.get(e, 'response.data.detail', null)
        const hasOcspError = errorDetail === 'Касира заблоковано: Сертифікат не чинний (при перевірці за протоколом OCSP)'
        if (hasOcspError) {
          return await this.informationDialog.open({
            text: 'We are sorry, but the cashier\'s signature has been blocked',
            buttonText: 'Okay',
            width: '660px'
          })
        }
        throw new Error(this.$t('Error when close shift:') + ' ' + errorDetail)
      }
    },

    // low level functions
    async getExistedShift (accessToken) {
      return await this.processingApiRequest({
        request: token => Shifts.api().processingReadCurrent(token || accessToken)
      })
    },
    async getShift (shiftId, accessToken) {
      return await this.processingApiRequest({
        request: token => Shifts.api().processingRead(shiftId, token || accessToken)
      })
    },
    async createShift (cashRegisterId, accessToken) {
      return await this.processingApiRequest({
        request: token => Shifts.api().processingCreateCurrent(cashRegisterId, token || accessToken)
      })
    },
    async closeShift (accessToken, skipClientNameCheck) {
      return await this.processingApiRequest({
        request: token => Shifts.api().processingCloseCurrent(token || accessToken, skipClientNameCheck)
      })
    }
  }
}

export default processingShifts
