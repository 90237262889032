<template lang="pug">
  div(class="h-100")
    v-expansion-panels(v-if="mobile")
      v-expansion-panel(class="hints-block-mobile")
        v-expansion-panel-header {{ title }}
        v-expansion-panel-content
          block-rozetkapay-hint-content(
            :steps="steps"
            :currentStep="currentStep"
            mobile
          )
    div(
      class="hints-block"
      v-else
    )
      block-rozetkapay-hint-content(
        :steps="steps"
        :currentStep="currentStep"
      )
</template>

<script>
import BlockRozetkapayHintContent from '~/modules/acquiring/views/rozetkapay/block-rozetkapay-hint-content'

export default {
  name: 'BlockRozetkapayHint',
  components: {
    BlockRozetkapayHintContent
  },
  props: {
    steps: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return `💡 ${this.$t('Hint')}`
    }
  }
}
</script>

<style scoped lang="scss">
</style>
