import EdsKeyData from '~/services/EdsKey/EdsKeyData'
import TaxAuthService from '~/services/Tax/Auth/TaxAuthService'
import { TaxReportService } from '~/services/Tax/Report/TaxReportService'
import EdsIitCheckboxModel from '~/modules/eds-iit-checkbox/models/EdsIitCheckbox'

const edsIitCheckboxHelper = {
  methods: {
    async edsIitCheckboxFallback (type) {
      const modelInstance = EdsIitCheckboxModel.query().where('type', type).first()
      if (modelInstance) {
        await modelInstance.delete()
      }
    },
    async generateSignInterfacePayloadAndErrorsCheck ({
      keyCheck = true,
      signInterface,
      fallbackMethod,
      checkAuthService = false,
      createReportService = false,
      errorFallback,
      type = 'edsIitCheckboxForOrganization'
    }) {
      const keyData = this._.get(signInterface, 'keyData', null)
      const sign = this._.get(signInterface, 'sign')
      const edsKeyData = new EdsKeyData(keyData)
      const isDiia = signInterface?.isDiia
      const isSmartId = signInterface?.isSmartId
      let error = false
      let noReturn = false
      let reportService = null

      if (keyCheck && !isDiia) {
        if (!signInterface) {
          if (typeof errorFallback === 'function') {
            await errorFallback()
          }
          error = true
          noReturn = true
        } else if (!keyData) {
          this.$handlers.error('We could not read the key data. Please try again or choose a different key', this)
          await this.edsIitCheckboxFallback(type)
          if (typeof errorFallback === 'function') {
            await errorFallback()
          }
          error = true
        } else if (edsKeyData.isSeal()) {
          this.$handlers.error('Please use the EDS key instead of seal', this)
          await this.edsIitCheckboxFallback(type)
          if (typeof errorFallback === 'function') {
            await errorFallback()
          }
          error = true
        } else if (edsKeyData.identity !== this._.get(this.$Organization, 'edrpou', null)) {
          this.$handlers.error('The USREOU codes do not match. Please choose another', this)
          await this.edsIitCheckboxFallback(type)
          if (typeof errorFallback === 'function') {
            await errorFallback()
          }
          error = true
        }
      }

      if (checkAuthService && keyData && sign && !error && !isSmartId && !isDiia) {
        try {
          const res = await TaxAuthService.sendAuthData(keyData, data => sign(data, async () => {
            await this.edsIitCheckboxFallback(type)
            if (typeof fallbackMethod === 'function') {
              await fallbackMethod()
            }
          }))
          if (!res) {
            error = true
            noReturn = true
          }
        } catch (e) {
          this.$handlers.error(e, this)
          error = true
          noReturn = true
        }
      }

      if (createReportService && signInterface && !error) {
        reportService = new TaxReportService(signInterface, async () => {
          await this.edsIitCheckboxFallback(type)
          if (typeof fallbackMethod === 'function') {
            await fallbackMethod()
          }
        })
      }

      return {
        sign,
        edsKeyData,
        error,
        noReturn,
        reportService,
        isDiia,
        fallbackMethod,
        dialogClosed: !signInterface
      }
    }
  }
}

export default edsIitCheckboxHelper
