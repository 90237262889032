import Directory from '~/models/abstracts/Directory'
import ChainInheritance from '~/models/mixins/ChainInheritance'
import Dialog from '~/models/system/Dialog'
import AppNotifications from '~/services/Notifications/AppNotifications'
import Organization from '~/models/directories/Organization'

export default class Acts extends ChainInheritance(Directory, []) {
  static entity = 'acts'
  static paginated = true
  static ormLoadWithRelations = true
  static defaultSortParam = 'startDate'

  static ormTrans = {
    single: 'Act',
    multy: 'Acts'
  }

  static fields () {
    return {
      id: this.attr(null),
      organization: this.attr(null),
      invoice: this.attr(null),
      startDate: this.attr(null),
      endDate: this.attr(null),
      date: this.attr(null),
      newCRPDetails: this.attr(null),
      overflowCRPDetails: this.attr(null),
      number: this.attr(null),
      userFullName: this.attr(null)
    }
  }

  get startDateString () {
    return this.getDateTime(this.startDate)
  }

  get endDateString () {
    return this.getDateTime(this.endDate)
  }

  get dateString () {
    return this.getDateTime(this.date)
  }

  static ormHeaders = [
    { text: 'User', value: 'userFullName', sortable: true, filterable: false },
    { text: 'Act number', value: 'number', sortable: true, filterable: false },
    { text: 'Start date', value: 'startDateString', sortable: true, filterable: false },
    { text: 'End date', value: 'endDateString', sortable: true, filterable: false },
    { text: 'Date of act', value: 'dateString', sortable: true, filterable: false },
    { text: 'Actions', align: 'center', value: 'actions', width: '72', sortable: false, filterable: false }
  ]

  static ormFilters = [
    {
      model: 'search',
      component: 'v-text-field',
      attrs: {
        outlined: true,
        'hide-details': true,
        placeholder: 'Search',
        'prepend-inner-icon': 'mdi-magnify'
      },
      classes: ['filled-input']
    }
  ]

  static ormFiltersConfig = {
    default: {
      grid: [
        {
          component: 'v-row',
          attrs: {
            justify: 'end'
          },
          nodes: [
            {
              component: 'v-col',
              attrs: {
                cols: 12
              },
              fields: [
                'search'
              ]
            }
          ]
        }
      ]
    }
  }

  static ormActions = [
    {
      name: 'downloadPdf',
      text: 'Download PDF',
      icon: {
        type: 'e-svg-icon',
        text: 'pdf'
      },
      call: async (item) => {
        if (item && item.id) {
          const res = await Acts.api().get(Acts.$routes[Acts.entity].getFile(item.id), {
            save: false,
            responseType: 'blob'
          })
          const blob = new Blob([this._.get(res, 'response.data', '')], { type: 'application/pdf' })
          window.open(URL.createObjectURL(blob), '_blank')
        }
      }
    },
    {
      name: 'changeActSignatory',
      text: 'Change act signatory',
      icon: {
        type: 'e-svg-icon',
        text: 'checkbox-signature'
      },
      visible: (item, user) => {
        return user?.isBillingManager
      },
      call: async (item) => {
        const org = await Organization.api().read(item.organization.id)
        const contentDialog = Dialog.query().where('type', 'content').first()
        await contentDialog.open({
          title: 'Change act signatory',
          width: '600px',
          component: 'm-form-block',
          componentProps: {
            buttonText: 'Save',
            fields: [
              {
                model: 'userFullName',
                component: 'v-text-field',
                provider: {
                  vid: 'userFullName',
                  name: 'Signatory',
                  rules: 'required'
                },
                attrs: {
                  outlined: true,
                  label: 'Signatory'
                },
                fieldVal: () => org?.response?.data?.actSignatoryFullName || ''
              },
              {
                model: 'signatoryPosition',
                component: 'v-text-field',
                provider: {
                  vid: 'signatoryPosition',
                  name: 'Signatory position',
                  rules: 'required'
                },
                attrs: {
                  outlined: true,
                  label: 'Signatory position'
                },
                fieldVal: () => org?.response?.data?.actSignatoryPosition || ''
              }
            ],
            onSubmit: async (data) => {
              await Acts.api().put(Acts.$routes[Acts.entity].changeActSignatory(item.id), data)
              AppNotifications.success('The signer has been successfully changed')
              await contentDialog.close()
            }
          }
        })
      }
    }
  ]
}
