import Dialog from '~/models/system/Dialog'

const contentDialogTop = {
  computed: {
    contentDialogTop () {
      return Dialog.query().where('type', 'content_top').first()
    }
  }
}
export default contentDialogTop
